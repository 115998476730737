import React from "react";
import { toast } from "react-toastify";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUpdateProjectNewsMutation } from "@tudigo-monorepo/web-tudigo-api-client";
import { Alert } from "@tudigo-monorepo/web-tudigo-components";

import { NewsInvestFormValues } from "../news-invest-form";

export const useUpdateNews = (onSuccessCallback: () => void) => {
  const { t } = useTranslation();
  const { mutate: updateProjectNewsMutation, isPending } =
    useUpdateProjectNewsMutation({
      onSuccess: () => {
        toast((content) => (
          <Alert
            icon="Info"
            close={content.closeToast}
            variant="success"
            size="S"
            title={t(
              "back_office.edit_project.project_news.update_news.success_message",
            )}
          />
        ));
        onSuccessCallback();
      },
    });

  const updateProjectNews = (values: NewsInvestFormValues) => {
    updateProjectNewsMutation({
      newsId: values.id ?? "",
      data: {
        title: values.title,
        description: values.description,
        type: values.type,
        document: values.document[0],
        id: "",
      },
    });
  };

  return { updateProjectNews, isPending };
};
