import { createColumnHelper } from "@tanstack/react-table";

import { ClubMember } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";
import { Chip, Typography } from "@tudigo-monorepo/web-tudigo-components";

export function useMyClubsColumnsHelper() {
  const columnHelper = createColumnHelper<ClubMember>();

  const { t } = useTranslation();

  return [
    columnHelper.accessor("club.name", {
      header: t("my_account.wep_app.clubs.table.column.name"),
      cell: (info) => (
        <Typography variant="body3-regular">
          {info.getValue() || "-"}
        </Typography>
      ),
    }),
    columnHelper.accessor("joinedAt", {
      header: t("my_account.wep_app.clubs.table.column.start_date"),
      cell: (info) => (
        <Typography variant="body3-regular">
          {info.getValue()
            ? new Date(info.getValue()).toLocaleDateString()
            : "-"}
        </Typography>
      ),
    }),
    columnHelper.accessor("subscription.currentPeriodEndDate", {
      header: t("my_account.wep_app.clubs.table.column.renewal_date"),
      cell: (info) => (
        <Typography variant="body3-regular">
          {info.getValue() && info.cell.row.original.joinedAt
            ? new Date(info.getValue()).toLocaleDateString()
            : "-"}
        </Typography>
      ),
    }),
    columnHelper.accessor("subscription.price.unitAmountInCents", {
      header: t("my_account.wep_app.clubs.table.column.price"),
      cell: (info) => {
        const amount = info.getValue();

        return (
          <Typography variant="body3-regular">
            {amount ? formatCurrency(amount) : "-"}
          </Typography>
        );
      },
    }),
    columnHelper.accessor("status", {
      header: t("my_account.wep_app.clubs.table.column.status"),
      cell: (info) => {
        const chipType = {
          draft: "warning",
          active: "success",
          deleted: "error",
        } as const;

        const chipLabel: Record<keyof typeof chipType, string> = {
          draft: t("investment_global_status_waiting_for_payment"),
          active: t("investment_global_status_validated"),
          deleted: t("investment_global_status_cancelled"),
        };

        const status = info.getValue() as keyof typeof chipType;

        return (
          <Chip
            type={chipType[status]}
            iconLeft="DotChip"
            label={chipLabel[status]}
          />
        );
      },
    }),
  ];
}
