import { useLocation, useParams } from "react-router-dom";

import {
  OrganizationKYCPageHead,
  OrganizationKYCViewer,
  organizationRequestFields,
} from "@tudigo-monorepo/web-feature-organizations";
import { useGetOrganizationByIdQuery } from "@tudigo-monorepo/web-tudigo-api-client";

export function MyProfileOrganizationDetailsPage() {
  const location = useLocation();
  const { organizationId } = useParams();

  const { data: organization } = useGetOrganizationByIdQuery(
    organizationId || "",
    {
      fields: organizationRequestFields,
    },
  );

  if (!organization) return null;

  return (
    <>
      <OrganizationKYCPageHead
        organization={organization}
        goBackIcon="Profile"
      />
      <OrganizationKYCViewer />
    </>
  );
}
