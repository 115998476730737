import { ArrowRight } from "lucide-react";
import { Link } from "react-router-dom";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  ButtonRebrand,
  PresentationCard,
  PromotionalBlocks,
} from "@tudigo-monorepo/web-tudigo-components";

import { ClubPremiumAdvantageList } from "../../../shared-internal/components/club-premium-advantage-list";
import { useGetClubV2UsingIdPathParams } from "../../../shared-internal/hooks/use-get-club-v2-using-id-path-params";

export function SubscribeClubStep4SuccessPage() {
  const club = useGetClubV2UsingIdPathParams();
  const { t } = useTranslation();

  return (
    <section className="flex flex-col gap-y-6">
      <PromotionalBlocks className="bg-[#FAFAFA] py-4 lg:py-10">
        <PresentationCard
          title={t(
            "subscribe_club_page.step_4_success.presentation_card.title",
          )}
          coverImageUrl={
            club?.content?.cover?.url ?? "https://picsum.photos/1000/900"
          }
          listComponent={<ClubPremiumAdvantageList />}
          buttonComponent={
            <Link to={`/clubs-v2/${club?.id}`}>
              <ButtonRebrand className="flex items-center gap-x-2">
                Le cercle {club?.name}
                <ArrowRight className="h-5 w-5" />
              </ButtonRebrand>
            </Link>
          }
        />
      </PromotionalBlocks>
    </section>
  );
}
