import { useIntl } from "react-intl";
import { Outlet } from "react-router-dom";

import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { useGetUserInvestments } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  PageHead,
  TabElement,
  Tabs,
  Title,
} from "@tudigo-monorepo/web-tudigo-components";

import {
  InvestStatus,
  useInvestStatuses,
} from "../../shared/hooks/use-invest-statuses";

const requestProjectFields =
  "mechanic,content.fields(name),holder_organization.fields(id,name,internal_kyc_status)";
const requestFields =
  "id," +
  "total_amount_in_cents," +
  "investor_organization.fields(id,name)," +
  "payment_method_type," +
  "created_at," +
  "status," +
  "global_status," +
  "project.fields(" +
  requestProjectFields +
  ")";

export function UserInvestmentsPage() {
  const intl = useIntl();
  const investStatuses: InvestStatus[] = useInvestStatuses();

  const { user } = useUserBusiness();
  const userId = user?.id as string;

  const { data: investments } = useGetUserInvestments({
    pathParams: { userId },
    fields: requestFields,
  });

  const tabs: TabElement[] = investStatuses.map(({ urlParamValue, label }) => ({
    name: label,
    redirectTo: `${urlParamValue}`,
  }));

  return (
    <div id="my-investments-page" className="w-full">
      <PageHead
        title={intl.formatMessage({ id: "investments.my_investments" })}
        subtitle={intl.formatMessage({
          id: "investments.find_all_your_investments_information",
        })}
      />
      <div className="border-light-2 mx-6 border-b" />
      {investments?.data && investments?.data?.length > 0 && (
        <Tabs tabs={tabs} className="px-6" />
      )}

      <div className="m-6">
        <Title h3Brand="brand-2" level="h3" className="text-primary mb-6">
          {intl.formatMessage({ id: "investments.my_investments" })}
        </Title>

        <Outlet />
      </div>
    </div>
  );
}
