import { CompanyInformation } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  FormContextProvider,
  getSmartFormComponents,
} from "@tudigo-monorepo/web-feature-form";
import {
  AddressSelector,
  LegalCompanySelector,
  TextField,
} from "@tudigo-monorepo/web-tudigo-components";

import { useKYCContext } from "../../../kyc-page";

type CompanyInformationFormPropsType = {
  companyInformationFormData: CompanyInformation;
  setCompanyInformationFormData: (
    companyInformation: CompanyInformation,
  ) => void;
};

export function CompanyInformationForm(props: CompanyInformationFormPropsType) {
  const { companyInformationFormData, setCompanyInformationFormData } = props;
  const { t } = useTranslation();
  const { organization, setOrganization } = useKYCContext();

  const { SmartTextField, SmartCountryField } =
    getSmartFormComponents<CompanyInformation>();

  return (
    <FormContextProvider
      formData={companyInformationFormData}
      setFormData={setCompanyInformationFormData}
      error={null}
    >
      <div className="my-4 rounded-lg border px-10 py-8">
        <div className="flex w-full flex-col gap-4 md:w-[350px]">
          <LegalCompanySelector
            label={t("kyc_funnel.company_information_form.label.company_name")}
            onCompanySelected={(company) => {
              setCompanyInformationFormData(company);
              company.legalName &&
                setOrganization({
                  ...organization,
                  name: company.legalName,
                });
            }}
          />
          <TextField
            name="organization.name"
            value={organization.name}
            onChange={(e) => {
              setOrganization({ ...organization, name: e.target.value });
            }}
            label={t("kyc_funnel.company_information_form.label.legal_name")}
            required
          />
          <SmartTextField
            name="registrationNumbers.siret"
            label={t(
              "kyc_funnel.company_information_form.label.registration_numbers.siret",
            )}
            required
          />
          <SmartTextField
            name="legalStatus"
            label={t(
              "kyc_funnel.company_information_form.label.registration_numbers.legal_status",
            )}
            required
          />
        </div>
      </div>
      <div className="my-4 rounded-lg border px-10 py-8">
        <div className="flex w-full flex-col gap-4 md:w-[350px]">
          <SmartCountryField
            name="registrationLocation.country"
            label={t(
              "kyc_funnel.company_information_form.label.registration_location.country",
            )}
            required
          />
          <AddressSelector
            label={t(
              "kyc_funnel.company_information_form.label.registration_location.address",
            )}
            country={companyInformationFormData.registrationLocation?.country}
            onAddressSelected={(address) => {
              setCompanyInformationFormData({
                ...companyInformationFormData,
                registrationLocation: address,
                headOfficeAddress: address,
              });
            }}
          />
          <SmartTextField
            name="registrationLocation.street1"
            label={t(
              "kyc_funnel.company_information_form.label.registration_location.street1",
            )}
            required
          />
          <SmartTextField
            required
            name="registrationLocation.postalCode"
            label={t(
              "kyc_funnel.company_information_form.label.registration_location.postal_code",
            )}
          />
          <SmartTextField
            required
            name="registrationLocation.city"
            label={t(
              "kyc_funnel.company_information_form.label.registration_location.city",
            )}
          />
        </div>
      </div>
    </FormContextProvider>
  );
}
