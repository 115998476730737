import { useState } from "react";

import {
  Organization,
  OrganizationPerson,
  OrganizationPersonDocument,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { ORGANIZATIONS, PMS_FILES } from "@tudigo-monorepo/core-tudigo-utils";
import {
  FormContextProvider,
  getSmartFormComponents,
} from "@tudigo-monorepo/web-feature-form";
import { Toggle, Typography } from "@tudigo-monorepo/web-tudigo-components";

import { KycCollapseFormGroup } from "../../../shared/components/kyc-collapse-form-group";
import { KycErrorChip } from "../../../shared/components/kyc-error-chip";
import { checkIfOrganizationPersonIdentityDocumentsRequirementAreMet } from "../../../utils/kyc-documents-utils";
import {
  getDefaultOrganizationPersonIdentityDocumentType,
  getOrganizationPersonDocumentsData,
  OrganizationPersonDocumentsData,
} from "../../../utils/kyc-utils";

interface UboKycDocumentsCollapseListItemProps {
  beneficialOwner: OrganizationPerson;
  organizationFormData: Organization;
  setOrganizationFormData: (organization: Organization) => void;
}

export function UboKycDocumentsCollapsListItem(
  props: UboKycDocumentsCollapseListItemProps,
) {
  const { beneficialOwner, organizationFormData, setOrganizationFormData } =
    props;

  const { SmartMultiTypeUploaderField } =
    getSmartFormComponents<OrganizationPersonDocumentsData>();
  const { t } = useTranslation();

  const organizationPersonDocumentsData =
    getOrganizationPersonDocumentsData(beneficialOwner);

  const { mergedPassport, mergedIdentityCard } =
    organizationPersonDocumentsData;

  const initialDocumentType = getDefaultOrganizationPersonIdentityDocumentType(
    organizationPersonDocumentsData,
  );

  const [documentType, setDocumentType] = useState<
    "identity_card" | "european_passport"
  >(initialDocumentType);

  const documentTypeChangeHandler = (isPassport: boolean) => {
    setDocumentType(isPassport ? "european_passport" : "identity_card");
  };

  const mergedPassportIsRejected =
    mergedPassport.pmsFile?.status === PMS_FILES.STATUS.REJECTED &&
    organizationPersonDocumentsData.passportFrontSide.document.base64 ===
      undefined;

  const mergedIdentityCardIsRejected =
    mergedIdentityCard.pmsFile?.status === PMS_FILES.STATUS.REJECTED &&
    organizationPersonDocumentsData.identityCardFrontSide.document.base64 ===
      undefined &&
    organizationPersonDocumentsData.identityCardBackSide.document.base64 ===
      undefined;

  const isError =
    (mergedIdentityCardIsRejected || mergedPassportIsRejected) &&
    !checkIfOrganizationPersonIdentityDocumentsRequirementAreMet(
      beneficialOwner,
      organizationFormData.type === ORGANIZATIONS.TYPE.INDIVIDUAL,
    );

  const handleOrganizationPersonDocumentsFormDataChange = (
    newFormData: OrganizationPersonDocumentsData,
  ) => {
    const formDocuments: OrganizationPersonDocument[] = [];
    formDocuments.push({
      organizationPerson: { id: beneficialOwner.id },
      ...newFormData.identityCardFrontSide,
    });
    formDocuments.push({
      organizationPerson: { id: beneficialOwner.id },
      ...newFormData.mergedPassport,
    });
    formDocuments.push({
      organizationPerson: { id: beneficialOwner.id },
      ...newFormData.mergedIdentityCard,
    });
    formDocuments.push({
      organizationPerson: { id: beneficialOwner.id },
      ...newFormData.identityCardBackSide,
    });
    formDocuments.push({
      organizationPerson: { id: beneficialOwner.id },
      ...newFormData.passportFrontSide,
    });
    if (newFormData.proofOfAddress) {
      formDocuments.push({
        organizationPerson: { id: beneficialOwner.id },
        ...newFormData.proofOfAddress,
      });
    }

    setOrganizationFormData({
      ...organizationFormData,
      ultimateBeneficialOwners:
        organizationFormData.ultimateBeneficialOwners.map((owner) =>
          owner.id === beneficialOwner.id
            ? {
                ...owner,
                documents: formDocuments,
              }
            : owner,
        ),
    });
  };

  return (
    <div className="w-full">
      <KycCollapseFormGroup
        expanded
        content={
          <div className="flex flex-col gap-4">
            <Toggle
              checked={documentType === "european_passport"}
              leftText={t(
                "kyc_funnel.step_documents.form.organization_person_document.label.identity_card",
              )}
              name="type"
              rightText={t(
                "kyc_funnel.step_documents.form.organization_person_document.label.passport",
              )}
              onChange={(e) => documentTypeChangeHandler(e)}
              blueStyle
            />
            <Typography variant="body1">
              {t(
                "kyc_funnel.step_documents.form.organization_person_document.document_requirements",
              )}
            </Typography>

            <div className="border-light-2 w-full border-b border-dashed" />

            <FormContextProvider
              error={null}
              formData={organizationPersonDocumentsData}
              setFormData={handleOrganizationPersonDocumentsFormDataChange}
            >
              <div
                className={cn("flex flex-col gap-6", {
                  hidden: documentType === "european_passport",
                })}
              >
                <SmartMultiTypeUploaderField
                  name="identityCardFrontSide.document"
                  label={t(
                    "kyc_funnel.step_documents.form.organization_person_document.label.identity_card_front_side",
                  )}
                  maxSizeInMB={5}
                  required
                  disabled={
                    mergedIdentityCard.pmsFile?.status ===
                    PMS_FILES.STATUS.WAITING_APPROVAL
                  }
                  errors={
                    mergedIdentityCardIsRejected
                      ? [t("kyc_funnel.document_status.rejected")]
                      : undefined
                  }
                />
                <SmartMultiTypeUploaderField
                  name="identityCardBackSide.document"
                  label={t(
                    "kyc_funnel.step_documents.form.organization_person_document.label.identity_card_back_side",
                  )}
                  required
                  maxSizeInMB={5}
                  disabled={
                    mergedIdentityCard.pmsFile?.status ===
                    PMS_FILES.STATUS.WAITING_APPROVAL
                  }
                  errors={
                    mergedIdentityCardIsRejected
                      ? [t("kyc_funnel.document_status.rejected")]
                      : undefined
                  }
                />
              </div>

              <div
                className={cn("flex flex-col gap-6", {
                  hidden: documentType === "identity_card",
                })}
              >
                <SmartMultiTypeUploaderField
                  name="passportFrontSide.document"
                  label={t(
                    "kyc_funnel.step_documents.form.organization_person_document.label.passport_front_side",
                  )}
                  maxSizeInMB={5}
                  required
                  disabled={
                    mergedPassport.pmsFile?.status ===
                    PMS_FILES.STATUS.WAITING_APPROVAL
                  }
                  errors={
                    mergedPassportIsRejected
                      ? [t("kyc_funnel.document_status.rejected")]
                      : undefined
                  }
                />
              </div>
            </FormContextProvider>
          </div>
        }
        title={`${t(
          "kyc_funnel.step_company_information.collapse_beneficial_owner.title",
        )} ${beneficialOwner.sharesCount}%`}
        subtitle={`${
          beneficialOwner.firstName
        } ${beneficialOwner.lastName.toUpperCase()}`}
        chipChildren={isError ? <KycErrorChip /> : null}
      />
    </div>
  );
}
