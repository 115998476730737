import { useIntl } from "react-intl";

import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";
import { Title, Typography } from "@tudigo-monorepo/web-tudigo-components";

export type InvestmentCapacity = {
  annualIncome: number;
  debt: number;
  liquidAssets: number;
  monthlyIncome: number;
  netAssets: number;
  wealth: number;
};

type InvestmentCapacityCardsContentProps = {
  investmentCapacity: InvestmentCapacity;
  currency?: string;
};

export function InvestmentCapacityCardsContent(
  props: InvestmentCapacityCardsContentProps,
) {
  const { investmentCapacity, currency = "€" } = props;

  const { annualIncome, debt, liquidAssets, monthlyIncome, netAssets, wealth } =
    investmentCapacity;

  const intl = useIntl();

  return (
    <div className="flex flex-col items-center gap-x-6 gap-y-8 sm:flex-row sm:flex-wrap">
      <article className="flex min-w-[220px] flex-1 flex-col items-center justify-between gap-y-4 self-stretch rounded-lg border bg-white p-4">
        <div className="flex w-full flex-col items-center bg-white text-center">
          <Title level="h4">
            {intl.formatMessage({
              id: "qualification.summary_page.investment_capacity.card.liquid-assets.label",
            })}
          </Title>
          <Title level="h2">{formatCurrency(liquidAssets * 100)}</Title>
        </div>
        <div className="bg-light-3 flex min-h-[116px] w-full flex-col items-center justify-center rounded-lg px-3 py-2">
          <Typography variant="caption1-semibold">
            {intl.formatMessage({
              id: "qualification.summary_page.investment_capacity.card.annual-income.label",
            })}
          </Typography>
          <Title level="h4">{formatCurrency(annualIncome * 100)}</Title>
          <Typography variant="caption1-medium">
            {intl.formatMessage(
              {
                id: "qualification.summary_page.investment_capacity.card.monthly-income.label",
              },
              { amount: formatCurrency(monthlyIncome * 100) },
            )}
          </Typography>
        </div>
      </article>

      <article className="flex min-w-[220px] flex-1 flex-col items-center justify-between gap-y-4 self-stretch rounded-lg border bg-white p-4">
        <div className="flex w-full flex-col items-center bg-white text-center">
          <Title level="h4">
            {intl.formatMessage({
              id: "qualification.summary_page.investment_capacity.card.net-assets.label",
            })}
          </Title>
          <Title level="h2">{formatCurrency(netAssets * 100)}</Title>
        </div>
        <div className="bg-light-3 flex min-h-[116px] w-full flex-col items-center justify-center rounded-lg px-3 py-2">
          <Typography variant="caption1-semibold">
            {intl.formatMessage({
              id: "qualification.summary_page.investment_capacity.card.real-estate.label",
            })}
          </Typography>
          <Title level="h4">{formatCurrency(wealth * 100)}</Title>
          <Typography variant="caption1-semibold">
            {intl.formatMessage({
              id: "qualification.summary_page.investment_capacity.card.debt.label",
            })}
          </Typography>
          <Title level="h4">{formatCurrency(debt * 100)}</Title>
        </div>
      </article>
    </div>
  );
}
