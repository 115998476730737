import React, { useEffect, useState } from "react";
import { CommandLoading } from "cmdk";

import { Investment } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";
import {
  useGetInvestmentById,
  useGetUserInvestments,
} from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
  Icon,
  InputGroup,
  InputGroupProps,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

type InvestmentSelectorProps = {
  currentItem?: Investment;
  currentItemId?: string;
  handleSelectItem: (item?: Investment) => void;
  filters?: any;
  userId: string;
} & InputGroupProps;

export const InvestmentSelector = (props: InvestmentSelectorProps) => {
  const {
    userId,
    label,
    className,
    disabled = false,
    required = false,
    errors,
    groupRef,
    currentItemId,
    currentItem,
    handleSelectItem,
    filters,
  } = props;

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedInvestment, setSelectedInvestment] = useState<
    Investment | undefined
  >(currentItem);

  const { data: fetchedInvestment } = useGetInvestmentById(
    {
      investmentId: currentItemId!,
      query: {
        fields:
          "id,total_amount_in_cents,project.fields(id,mechanic,content.fields(name)),investor_organization.fields(id,name)",
      },
    },
    {
      enabled: !!currentItemId && !currentItem,
    },
  );

  useEffect(() => {
    if (fetchedInvestment) {
      setSelectedInvestment(fetchedInvestment);
    }
  }, [fetchedInvestment]);

  const { data, isLoading } = useGetUserInvestments({
    filters: {
      ...filters,
      term: search,
    },
    fields:
      "id,total_amount_in_cents,project.fields(id,mechanic,content.fields(name)),investor_organization.fields(id,name)",
    pathParams: {
      userId: userId,
    },
  });

  const filtered = filters.mechanic
    ? data?.data?.filter((item) => item.project?.mechanic === filters.mechanic)
    : data?.data;

  const handleValueChange = (currentValue: string) => {
    setSearch(currentValue);
  };

  const handleOrgChange = (item: Investment) => {
    setSelectedInvestment(item);
    handleSelectItem(item);
    setOpen(false);
  };

  const getLabel = (item: Investment) => {
    return `(${item.project?.content?.name}) Investissement de ${formatCurrency(
      item.totalAmountInCents,
    )} en tant que ${item.investorOrganization?.name}`;
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger className="flex min-h-[38px] min-w-[250px] flex-row items-stretch outline-0">
        <InputGroup
          groupRef={groupRef}
          className="w-full"
          disabled={disabled}
          label={t("investment")}
          required={required}
          errors={errors}
          hasValue={!!selectedInvestment}
          labelPosition="inside"
          canBeReset={!!selectedInvestment}
          reset={() => {
            setSelectedInvestment(undefined);
            handleSelectItem(undefined);
          }}
          iconRight={
            <Icon
              name="ChevronDown"
              size="S"
              primaryColor={
                open ? themeColors["accent"] : themeColors["dark-2"]
              }
              className={cn("rotate-0 transition-all", {
                "rotate-180": open,
              })}
            />
          }
          renderInput={() => (
            <Typography variant="body2" className="w-full text-start">
              {selectedInvestment
                ? getLabel(selectedInvestment)
                : "Sélectionnez un investissement"}
            </Typography>
          )}
        />
      </PopoverTrigger>
      <PopoverContent className="w-[250px] p-0">
        <Command shouldFilter={false}>
          <CommandInput
            value={search}
            onValueChange={handleValueChange}
            placeholder="Rechercher par nom"
          />

          {isLoading && (
            <CommandList>
              <CommandLoading className="flex justify-center p-4">
                Chargement...
              </CommandLoading>
            </CommandList>
          )}

          {!isLoading && (
            <CommandList>
              <CommandEmpty>Aucune société trouvé</CommandEmpty>
              {filtered?.map((item) => (
                <CommandItem
                  key={item.id}
                  value={item.id}
                  onSelect={() => handleOrgChange(item)}
                >
                  {getLabel(item)}
                </CommandItem>
              ))}
            </CommandList>
          )}
        </Command>
      </PopoverContent>
    </Popover>
  );
};
