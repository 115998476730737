import { useIntl } from "react-intl";
import { toast } from "react-toastify";

import { AssemblyParticipant } from "@tudigo-monorepo/core-tudigo-api-models";
import {
  FormContextProvider,
  getSmartFormComponents,
} from "@tudigo-monorepo/web-feature-form";
import { useChangeAssemblyParticipationMutation } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  Button,
  Form,
  Title,
} from "@tudigo-monorepo/web-tudigo-components";

import { useMyInvestmentAssemblyPageContext } from "../../investment-assembly-page/my-investment-assembly-page";
import { useAssemblyParticipationFormItems } from "../hooks/use-assembly-participation-form-items";

type PickParticipationChannelFormKey = Pick<
  AssemblyParticipant,
  "participationChannel"
>;

type AssemblyParticipationFormProps = {
  setParticipationFormIsOpen: (value: boolean) => void;
};

export function AssemblyParticipationForm(
  props: AssemblyParticipationFormProps,
) {
  const intl = useIntl();
  const { setParticipationFormIsOpen } = props;

  const { SmartRadioGroupField } =
    getSmartFormComponents<PickParticipationChannelFormKey>();

  const {
    participationFormData,
    setParticipationFormData,
    assembly,
    assemblyParticipant,
  } = useMyInvestmentAssemblyPageContext();

  const {
    mutate: changeAssemblyParticipation,
    isPending: isParticipationFormLoading,
  } = useChangeAssemblyParticipationMutation();

  const formItems = useAssemblyParticipationFormItems(
    assembly.configuration.channel,
    assembly.configuration.proxyAllowed,
  );

  const onParticipationFormSubmit = () => {
    changeAssemblyParticipation(
      {
        assemblyId: assembly.id,
        participantId: assemblyParticipant.id,
        data: {
          participationChannel: participationFormData?.participationChannel,
        },
      },
      {
        onSuccess() {
          toast((content) => (
            <Alert
              icon="Info"
              close={content.closeToast}
              variant="success"
              size="S"
              title="Votre participation a bien été enregistrée"
              description={
                "Vous pouvez modifier votre participation jusqu'à 24h avant l'assemblée"
              }
            />
          ));
          setParticipationFormIsOpen(false);
        },
        onError(error) {
          console.log(error);
        },
      },
    );
  };

  return (
    <FormContextProvider
      formData={participationFormData}
      setFormData={setParticipationFormData}
      error={null}
    >
      <div className="rounded-lg border px-10 py-8">
        <Title h3Brand="brand-2" level="h5" className="text-primary mb-6">
          {intl.formatMessage({
            id: "assembly.my_participation.participation_form.title",
          })}
        </Title>
        <div className="w-[251px]">
          <Form className="flex flex-col gap-4">
            <SmartRadioGroupField
              label="participationChannel"
              name="participationChannel"
              selectedValue={
                participationFormData?.participationChannel as string
              }
              items={formItems}
            />
          </Form>
          <Button
            isLoading={isParticipationFormLoading}
            className="mt-6"
            variant="primary"
            disabled={
              isParticipationFormLoading ||
              !participationFormData?.participationChannel
            }
            label={intl.formatMessage({
              id: "assembly.my_participation.participation_form.submit_btn",
            })}
            onClick={onParticipationFormSubmit}
          />
        </div>
      </div>
    </FormContextProvider>
  );
}
