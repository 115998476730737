import { Chip } from "@tudigo-monorepo/web-tudigo-components";

import {
  KycSectionType,
  useKycSectionChipStatusProps,
} from "../hooks/use-kyc-section-chip-status-props";

export interface KycSectionChipStatusProps {
  section: KycSectionType;
}

export function KycSectionChipStatus(props: KycSectionChipStatusProps) {
  const chipProps = useKycSectionChipStatusProps(props.section);

  return <Chip label="" {...chipProps} className="h-7 w-7 rounded-full" />;
}
