import React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { PageEnum, useSpaceBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  Button,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { useKYCContext } from "../../../kyc-page";
import { KycSectionChipStatus } from "../../../shared/components/kyc-section-chip-status";

export function PersonalInformationSummarySection() {
  const { organization } = useKYCContext();
  const representative = organization.organizationRepresentative;
  const intl = useIntl();
  const { t } = useTranslation();

  const { currentPagePaths } = useSpaceBusiness();
  const kycPagePath = currentPagePaths[
    PageEnum.ORGANIZATION_KYC_FUNNEL
  ].replace(":organizationId", organization.id);

  const editPagePath = `${kycPagePath}/personal-information`;

  const getGender = () => {
    if (!representative) {
      return "non renseigné";
    }

    return representative.gender === "female"
      ? t("kyc_funnel.organization_person_form.label.gender.f")
      : t("kyc_funnel.organization_person_form.label.gender.m");
  };

  const getAddress = () => {
    if (!representative?.address) {
      return "non renseigné";
    }

    return `${representative.address.street1}, ${representative.address.postalCode} ${representative.address.city} ${representative.address.country}`;
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex gap-2">
          <Title level="h4">
            {t("kyc_funnel.final_summary.personal_information.title")}
          </Title>
          <KycSectionChipStatus section="personal-informations" />
        </div>
        <Link to={editPagePath}>
          <Button
            label={t("kyc_funnel.final_summary.edit_btn.label")}
            iconLeft="Pen"
            variant="tertiary"
          />
        </Link>
      </div>
      <div className="border-light-2 my-4 border-b" />
      <div className="flex flex-col gap-2">
        <div className="flex justify-between">
          <Typography variant="caption1-regular">
            {t("kyc_funnel.organization_person_form.label.civility")}
          </Typography>
          <Typography variant="caption1-semibold">{getGender()}</Typography>
        </div>
        <div className="flex justify-between">
          <Typography variant="caption1-regular">
            {t("kyc_funnel.organization_person_form.label.firstname")}
          </Typography>
          <Typography variant="caption1-semibold">
            {representative?.firstName}
          </Typography>
        </div>
        <div className="flex justify-between">
          <Typography variant="caption1-regular">
            {t("kyc_funnel.organization_person_form.label.lastname")}
          </Typography>
          <Typography variant="caption1-semibold">
            {representative?.lastName}
          </Typography>
        </div>
        <div className="flex justify-between">
          <Typography variant="caption1-regular">
            {t("kyc_funnel.organization_person_form.label.birth_detail")}
          </Typography>
          <Typography variant="caption1-semibold">
            {(representative?.birthDate && representative?.birthLocation && (
              <>
                {new Date(representative.birthDate).toLocaleDateString(
                  intl.locale,
                )}{" "}
                à {representative.birthLocation.city},{" "}
                {representative.birthLocation.country}
              </>
            )) ||
              "non renseigné"}
          </Typography>
        </div>
        <div className="flex justify-between">
          <Typography variant="caption1-regular">
            {t("kyc_funnel.organization_person_form.label.nationality")}
          </Typography>
          <Typography variant="caption1-semibold">
            {representative?.nationality}
          </Typography>
        </div>
        <div className="flex justify-between">
          <Typography variant="caption1-regular">
            {t("kyc_funnel.organization_person_form.label.phone")}
          </Typography>
          <Typography variant="caption1-semibold">
            {representative?.phone}
          </Typography>
        </div>
        <div className="flex justify-between">
          <Typography variant="caption1-regular">
            {t("kyc_funnel.organization_person_form.label.legal_address")}
          </Typography>
          <Typography variant="caption1-semibold">{getAddress()}</Typography>
        </div>
      </div>
    </div>
  );
}
