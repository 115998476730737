import { useState } from "react";

import { ClubV2, TrackRecord } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Button } from "@tudigo-monorepo/web-tudigo-components";

import { ClubCardBlock } from "../../../shared-internal/components/club-card-block";
import { useGetClubV2UsingIdPathParams } from "../../../shared-internal/hooks/use-get-club-v2-using-id-path-params";
import { getClubComponentByCMSBlock } from "../../../shared-internal/utils/get-club-component-by-cms-block";

const DEFAULT_DISPLAY_COUNT = 9;

export const ClubTrackRecordContent = () => {
  const club = useGetClubV2UsingIdPathParams();

  const { t } = useTranslation();

  const [displayCount, setDisplayCount] = useState(DEFAULT_DISPLAY_COUNT);
  const [showMore, setShowMore] = useState(false);

  if (!club || !club?.trackRecords) {
    return;
  }

  const handleShowMore = () => {
    setDisplayCount(club.trackRecords.length);
    setShowMore(true);
  };

  const displayedItems = club.trackRecords?.slice(0, displayCount);

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <h2 className="font-obviously text-dark-1 text-lg font-[570]">
          {t("page_club.tab_track_record.track_record.title")}
        </h2>
        <TrackRecordGrid trackRecords={displayedItems} />
        {!showMore && club.trackRecords.length > 6 && (
          <div className="flex w-full justify-center">
            <Button
              label={t(
                "page_club.tab_track_record.track_record.see_all_button.label",
              )}
              onClick={handleShowMore}
              className="w-fit"
              variant="tertiary"
            />
          </div>
        )}
      </div>
      <div className="flex flex-col gap-6">
        <ExitCmsSection club={club} />
        <LegalDisclaimer />
      </div>
    </div>
  );
};

const LegalDisclaimer = () => {
  const { t } = useTranslation();

  return (
    <div className="border-light-1 text-dark-1 flex flex-col gap-6 border-t pt-6">
      <div>{t("page_club.tab_track_record.legal_disclaimer.row_one")}</div>
      <div>
        {t("page_club.tab_track_record.legal_disclaimer.row_two")}
        <br />
        {t("page_club.tab_track_record.legal_disclaimer.row_three")}
      </div>
    </div>
  );
};

const TrackRecordGrid = ({ trackRecords }: { trackRecords: TrackRecord[] }) => {
  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
      {(trackRecords ?? []).map((trackRecord) => (
        <ClubCardBlock key={trackRecord.id} trackRecord={trackRecord} />
      ))}
    </div>
  );
};

const ExitCmsSection = ({ club }: { club: ClubV2 }) => {
  return (
    <>
      <h2 className="font-obviously text-dark-1 text-lg font-[570]">Exit</h2>
      <div className="flex flex-col gap-4">
        {(club.content?.exitDescription ?? [])
          .map((block) => getClubComponentByCMSBlock(block, club))
          .map((Block, index) => (
            <Block key={index} />
          ))}
      </div>
    </>
  );
};
