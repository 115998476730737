import {
  isSharesProject,
  Project,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";

import { InfoCardItem } from "../../../../../shared/components/info-card-item";

export function CommonProjectModalities(props: { project: Project }) {
  const { project } = props;

  const entryFees = isSharesProject(project)
    ? project.sharesMechanicSettings.entryFeesNoDecimalRate / 100
    : project.bondsMechanicSettings.entryFeesNoDecimalRate / 100;

  const managementFees = isSharesProject(project)
    ? project.sharesMechanicSettings.managementFeesNoDecimalRate / 100
    : project.bondsMechanicSettings.managementFeesNoDecimalRate / 100;

  const carried = isSharesProject(project)
    ? project.sharesMechanicSettings.carriedNoDecimalRate / 100
    : 0;

  return (
    <>
      <InfoCardItem
        label="Montant plancher"
        value={
          project.goal ? formatCurrency(project.goal?.minInCents) : "Non défini"
        }
      />
      <InfoCardItem
        label="Montant maximum recherché"
        value={
          project.goal ? formatCurrency(project.goal?.maxInCents) : "Non défini"
        }
      />
      <InfoCardItem
        label="Stades de maturité"
        value={project.maturity?.name ?? "Non défini"}
      />
      {entryFees ? (
        <InfoCardItem label="Frais d'entrée" value={`${entryFees} %`} />
      ) : null}
      {managementFees ? (
        <InfoCardItem label="Frais de gestion" value={`${managementFees} %`} />
      ) : null}
      {carried ? <InfoCardItem label="Carried" value={`${carried} %`} /> : null}
    </>
  );
}
