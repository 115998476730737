import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { Chip, ChipSize, ChipType } from "../chip";
import { Title } from "../title";
import { Typography } from "../typography";

export type SummaryCardChipProps = {
  label: string | number;
  type?: ChipType;
  size?: ChipSize;
};

export type SummaryCardVariant = "default" | "success";

type SummaryCardProps = {
  content: string;
  title: string;
  icon?: string;
  chips?: SummaryCardChipProps[];
  subtitle?: string;
  variant?: SummaryCardVariant;
  onClick?: () => void;
};

export function SummaryCard(props: SummaryCardProps) {
  const {
    content,
    title,
    chips,
    icon,
    subtitle,
    variant = "default",
    onClick,
  } = props;

  return (
    <article
      className={cn(
        "summary-card flex w-full min-w-[288px] flex-col items-center gap-y-3 rounded-lg border bg-white p-4 text-center",
        { "bg-success-light": variant === "success" },
      )}
      onClick={onClick}
    >
      {icon ? (
        <span role="img" className="text-base">
          {icon}
        </span>
      ) : null}
      {title ? (
        <Title
          level="h3"
          className={cn({ "text-success": variant === "success" })}
        >
          {title}
        </Title>
      ) : null}
      {subtitle ? <Title level="h2">{subtitle}</Title> : null}
      {chips ? (
        <ul className="flex flex-wrap items-center gap-x-2.5">
          {chips.map((chip, index) => (
            <Chip key={index} {...chip} type={chip.type || "info"} />
          ))}
        </ul>
      ) : null}
      <Typography variant="body2">{content}</Typography>
    </article>
  );
}
