import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Typography } from "@tudigo-monorepo/web-tudigo-components";

import { getIRAvailabilityDateLimit, getNextFullYear } from "../utils/dates";

export function ModalTaxExemptionIR() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4">
      <div>
        <Typography variant="body2-semibold">
          {t("my-profile-exemptions.tax-document-modal-PFU.content-1")}
        </Typography>
        <ul className="list-disc px-5">
          <li>
            <Typography variant="body2">
              {t("my-profile-exemptions.tax-document-modal-PFU.content-2")}
            </Typography>
          </li>
          <li>
            <Typography variant="body2">
              {t("my-profile-exemptions.tax-document-modal-PFU.content-3")}
            </Typography>
          </li>
          <li>
            <Typography variant="body2">
              {t("my-profile-exemptions.tax-document-modal-PFU.content-4", {
                dateLimit: getIRAvailabilityDateLimit().toLocaleDateString(),
                nextYear: getNextFullYear(),
              })}
            </Typography>
          </li>
        </ul>
      </div>
      <Typography variant="body2">
        {t("my-profile-exemptions.tax-document-modal-PFU.responsibility", {
          link: (
            <a
              className="underline"
              href={t(
                "my-profile-exemptions.tax-document-modal-PFU.responsibility-link-URL-gouvernement",
              )}
            >
              {t(
                "my-profile-exemptions.tax-document-modal-PFU.responsibility-link-text-gouvernement",
              )}
            </a>
          ),
        })}
      </Typography>
      <div className="px-5 py-4"></div>
    </div>
  );
}
