import React from "react";

type ClubCoverProps = {
  coverUrl?: string | null;
};

export const ClubCover = ({ coverUrl }: ClubCoverProps) => {
  return (
    <div className="z-1 relative h-[273px] w-full">
      <img
        src={coverUrl ?? undefined}
        alt="club cover"
        className="absolute inset-0 h-full w-full object-cover object-center"
      />
    </div>
  );
};
