import * as React from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

type SidebarTheme = "primary" | "secondary" | "tertiary";

const themes = {
  default: "bg-white text-primary",
  primary: "bg-primary text-white",
  secondary: "bg-secondary text-primary",
  tertiary: "bg-accent text-white",
};

type SidebarProps = {
  children: React.ReactNode;
  className?: string;
  expanded?: boolean;
  theme?: SidebarTheme;
};

export function Sidebar(props: SidebarProps) {
  const { children, className, expanded = false, theme } = props;

  const colors = theme
    ? themes[theme]
    : `${themes.default} border-r border-[#CCCED8]`;

  return (
    <nav
      id="sidebar"
      className={cn(
        "sticky bottom-0 left-0 top-0 hidden flex-col overflow-y-auto overflow-x-hidden py-8 duration-200 ease-in-out lg:flex",
        {
          "lg:w-[264px] lg:min-w-[264px]": expanded,
          "w-[75px] min-w-[75px] items-center": !expanded,
        },
        colors,
        className,
      )}
    >
      {children}
    </nav>
  );
}
