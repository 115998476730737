import { Organization } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

import { useOrganizationKycDocumentsStatusChipProps } from "../../../hooks/use-organization-kyc-documents-status-chip-props";
import { getOrganizationDocuments } from "../../../utils/organization-documents-utils";
import {
  isOrganizationKycApproved,
  isRejectedOrganizationKycDocument,
} from "../../../utils/organization-kyc-utils";
import {
  OrganizationCollapse,
  OrganizationCollapseItem,
} from "../../organization-collapse/organization-collapse";
import { OrganizationCollapseHeader } from "../../organization-collapse/organization-collapse-header";
import { getDocumentName } from "./documents-name-helper";

type DocumentsSectionProps = {
  organization: Organization;
};

export function DocumentsSection({ organization }: DocumentsSectionProps) {
  const { t } = useTranslation();

  const isKycApproved = isOrganizationKycApproved(organization);
  const sectionChipStatus =
    useOrganizationKycDocumentsStatusChipProps(organization);
  const displayedDocuments = getOrganizationDocuments(organization);

  const collapseItems = displayedDocuments.map((doc) => {
    const isRejectedDoc = isRejectedOrganizationKycDocument(doc);

    if (isRejectedDoc && isKycApproved) {
      return {
        label: t(
          `organizations.back_office.organization_page.collapse.documents.${doc.type}`,
        ),
        value: doc.document.originalFilename ?? "fichier.pdf",
        chipProps: {
          label: t(
            "organizations.back_office.organization_page.chip_status.not_required",
          ),
          type: "info",
          iconLeft: "DotChip",
          size: "M",
        },
        onClick: () => window.open(doc.document.signedUrl, "_blank"),
      };
    }

    if (isRejectedDoc && !isKycApproved) {
      return {
        label: t(
          `organizations.back_office.organization_page.collapse.documents.${doc.type}`,
        ),
        value: doc.document.originalFilename ?? "fichier.pdf",
        chipProps: {
          label: t(
            `organizations.back_office.organization_page.chip_status.${
              doc.pmsFile?.status === "rejected" ? "error" : "submitted"
            }`,
          ),
          type: doc.pmsFile?.status === "rejected" ? "error" : "warning",
          iconLeft: "DotChip",
          size: "M",
        },
        onClick: () => window.open(doc.document.signedUrl, "_blank"),
      };
    }

    return {
      label: t(
        `organizations.back_office.organization_page.collapse.documents.${doc.type}`,
      ),
      value: getDocumentName(doc),
      onClick: () => window.open(doc.document.signedUrl, "_blank"),
    };
  });

  return (
    <OrganizationCollapse
      defaultExpanded
      items={collapseItems as OrganizationCollapseItem[]}
    >
      <OrganizationCollapseHeader
        label={t(
          "organizations.back_office.organization_page.collapse.documents.title",
        )}
        chipProps={sectionChipStatus}
      />
    </OrganizationCollapse>
  );
}
