import { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import {
  InternalKycStatus,
  Investment,
} from "@tudigo-monorepo/core-tudigo-api-models";
import {
  LEMONWAY,
  PAYMENT_MANAGEMEN_SYSTEM,
} from "@tudigo-monorepo/core-tudigo-utils";
import { OrganizationKycListElement } from "@tudigo-monorepo/web-feature-organizations";
import { useUpdateInvestmentMutation } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  Button,
  ListElement,
  PageHead,
} from "@tudigo-monorepo/web-tudigo-components";

import {
  investmentFunnelFields,
  useInvestmentFunnelPageContext,
} from "../../../pages/investment-funnel-page";
import { ExpandedInvestmentDetailCard } from "../../shared/expanded-investment-detail-card";

type investmentPaymentMethodFormData = {
  paymentMethod: Investment["paymentMethodType"] | null;
};

export function InvestmentFunnelSelectPaymentMethodContent() {
  const { ongoingInvestment, project } = useInvestmentFunnelPageContext();
  const navigate = useNavigate();

  const location = useLocation();
  const [queryParams] = useSearchParams();
  const status = queryParams.get("status");

  const [investmentPaymentMethodFormData, setInvestmentPaymentMethodFormData] =
    useState<investmentPaymentMethodFormData>({
      paymentMethod: ongoingInvestment?.paymentMethodType ?? null,
    });

  const {
    mutateAsync: updateInvestmentIntentMethod,
    isPending: isUpdateInvestmentIntentLoading,
  } = useUpdateInvestmentMutation();

  const [loaderActive, setLoaderActive] = useState(false);

  const handleSubmit = () => {
    if (
      investmentPaymentMethodFormData.paymentMethod === null ||
      !ongoingInvestment
    )
      return;
    const investmentId = ongoingInvestment.id;
    const paymentMethodType = investmentPaymentMethodFormData.paymentMethod;

    updateInvestmentIntentMethod(
      {
        investmentId,
        query: {
          fields: investmentFunnelFields,
        },
        data: {
          paymentMethodType,
          paymentConfirm: true,
        },
      },
      {
        onSuccess: async ({ data: investment }) => {
          setLoaderActive(true);
          if (paymentMethodType === "card") {
            return window.open(
              investment.latestPaymentIntent?.paymentUrl,
              "_self",
            );
          }
          setLoaderActive(false);
          navigate(`/projects/${project.id}/invest/${ongoingInvestment.id}`);
        },
      },
    );
  };

  const handleClickKyc = () => {
    localStorage.setItem("target-url", location.pathname);
  };

  const isKycApproved =
    ongoingInvestment?.investorOrganization.internalKycStatus ===
    InternalKycStatus.APPROVED;

  const isAmountGreaterThanLimit =
    ongoingInvestment?.totalAmountInCents !== undefined &&
    ongoingInvestment.totalAmountInCents >
      LEMONWAY.MAX_ALLOWED_AMOUNT_IN_CENTS.CARD;

  if (!ongoingInvestment) return;

  return (
    <div className="step-select-organization w-full">
      <ExpandedInvestmentDetailCard />
      {(status === "failed" || status === "cancelled") && (
        <Alert
          className="mt-6"
          icon="Notification"
          variant="error"
          size="M"
          description="Une erreur est survenue durant le paiement par carte bancaire. Veuillez réessayer ou choisir un nouveau mode de paiement. Si le problème persiste, contactez support@tudigo.co"
        />
      )}
      <PageHead title="Choisir un mode de paiement" className="py-4" />
      <div className="border-light-2 border-b" />
      <div className="my-6 flex flex-col gap-y-6">
        {!isKycApproved && (
          <Alert
            icon="Profile"
            variant="warning"
            description="Pour pouvoir payer et finaliser votre investissement veuillez complétez votre profil investisseur"
            size="M"
          />
        )}

        {!isKycApproved && (
          <div onClick={handleClickKyc}>
            <OrganizationKycListElement
              organization={ongoingInvestment!.investorOrganization}
              navigateTo={`/organization/${
                ongoingInvestment!.investorOrganization.id
              }/kyc/`}
            />
          </div>
        )}
        <ListElement
          disabled={!isKycApproved}
          toggleActiveOnClick={false}
          active={
            isKycApproved &&
            investmentPaymentMethodFormData.paymentMethod ===
              PAYMENT_MANAGEMEN_SYSTEM.PAYMENT_METHOD_TYPE.WIRE
          }
          startElement={{
            upperText: "Virement bancaire",
            lowerText:
              "Pour effectuer votre paiement par virement, Tudigo et Lemonway vous fournissent un IBAN sur lequel verser les fonds.",
            useProfileCard: false,
          }}
          onClick={() => {
            setInvestmentPaymentMethodFormData({
              paymentMethod: "wire",
            });
          }}
        />
        {!isAmountGreaterThanLimit && (
          <ListElement
            disabled={!isKycApproved}
            toggleActiveOnClick={false}
            active={
              isKycApproved &&
              investmentPaymentMethodFormData.paymentMethod ===
                PAYMENT_MANAGEMEN_SYSTEM.PAYMENT_METHOD_TYPE.CARD
            }
            startElement={{
              upperText: "Carte Bancaire",
              lowerText:
                "Utilisez votre carte bancaire pour investir dans ce projet.",
              useProfileCard: false,
            }}
            onClick={() => {
              setInvestmentPaymentMethodFormData({
                paymentMethod: "card",
              });
            }}
          />
        )}
      </div>
      <div className="flex w-full justify-center">
        <Button
          isLoading={isUpdateInvestmentIntentLoading || loaderActive}
          disabled={
            investmentPaymentMethodFormData.paymentMethod === null ||
            isUpdateInvestmentIntentLoading ||
            loaderActive
          }
          className="my-6 w-fit px-12"
          variant="green"
          label="Continuer"
          iconRight="ArrowRight"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}
