import { CMSHighlightBlock } from "@tudigo-monorepo/core-tudigo-api-models";

type HighlightBlockProps = {
  block: CMSHighlightBlock;
};
export const HighlightBlock = ({ block }: HighlightBlockProps) => {
  return (
    <div>
      <div className="flex flex-col gap-4">
        {block.data.elements.map((element, index) => (
          <div key={index} className="flex items-center gap-2">
            <div className="bg-light-2 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full">
              <span className="text-primary text-base font-semibold">
                {index + 1}
              </span>
            </div>

            <p
              className="rte-content text-dark-2 flex-grow"
              dangerouslySetInnerHTML={{ __html: element.content }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
