import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { PageEnum, useSpaceBusiness } from "@tudigo-monorepo/tudigo-session";
import { Button } from "@tudigo-monorepo/web-tudigo-components";

import { EditOrganizationConfirmationModal } from "../edit-organization-confirmation-modal/edit-organization-confirmation-modal";

type EditOrganizationLinkProps = {
  organizationId?: string;
  isBankAccountUpdate?: boolean;
  label?: string;
};

export function EditOrganizationLink({
  organizationId,
  isBankAccountUpdate = false,
  label,
}: EditOrganizationLinkProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { currentPagePaths } = useSpaceBusiness();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const handleClick = () => {
    setIsConfirmationModalOpen(true);
  };

  const handleConfirm = () => {
    let kycRoute = currentPagePaths[PageEnum.ORGANIZATION_KYC_FUNNEL].replace(
      ":organizationId",
      organizationId || "",
    );
    if (isBankAccountUpdate) {
      kycRoute += "/bank-account-information";
    }
    navigate(kycRoute);
  };

  return (
    <>
      <Button
        className="w-full sm:w-fit"
        onClick={handleClick}
        label={
          label ||
          t("organizations.back_office.update_informations.button.label")
        }
      />
      <EditOrganizationConfirmationModal
        open={isConfirmationModalOpen}
        isBankAccountUpdate={isBankAccountUpdate}
        onCancel={setIsConfirmationModalOpen}
        onConfirm={handleConfirm}
      />
    </>
  );
}
