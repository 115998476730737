import {
  Organization,
  OrganizationDocument,
} from "@tudigo-monorepo/core-tudigo-api-models";

export function findDocumentOrDefaultOrganizationDocument(
  organization: Organization,
  type: string,
) {
  return (
    organization.documents.find((doc) => doc.type === type) ??
    ({
      type: type,
      document: {},
      organization: { id: organization.id },
    } as OrganizationDocument)
  );
}

export type OrganizationKycDocumentsFormData = {
  companyRegistration: OrganizationDocument;
  status: OrganizationDocument;
  uboRegister: OrganizationDocument;
};
