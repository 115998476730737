import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

import { useOrganizationKycBankStatusChipProps } from "../../../../hooks/use-organization-kyc-bank-status-chip-props";
import { isValidEquitySavingPlan } from "../../../../utils/equity-saving-plans-utils";
import { EditOrganizationLink } from "../../../edit-organization-link/edit-organization-link";
import {
  OrganizationCollapse,
  OrganizationCollapseItem,
} from "../../../organization-collapse/organization-collapse";
import { OrganizationCollapseHeader } from "../../../organization-collapse/organization-collapse-header";
import { useInvestmentAccountContext } from "../../investment-account-context";

export function BankAccountSection() {
  const { t } = useTranslation();

  const { equitySavingPlan, organization } = useInvestmentAccountContext();
  const { iban, bic } = equitySavingPlan;

  const chipStatusProps = useOrganizationKycBankStatusChipProps(
    organization || undefined,
  );

  const collapseItems: OrganizationCollapseItem[] = [
    {
      label: t(
        "organizations.back_office.organization_page.collapse.bank_account.iban",
      ),
      value: iban,
    },
    {
      label: t(
        "organizations.back_office.organization_page.collapse.bank_account.bic",
      ),
      value: bic,
    },
  ];

  if (isValidEquitySavingPlan(equitySavingPlan) && organization !== null) {
    collapseItems.push({
      render: () => (
        <EditOrganizationLink
          isBankAccountUpdate
          organizationId={organization?.id}
          label={t(
            "organizations.back_office.organization_page.collapse.bank_account.update_informations.button.label",
          )}
        />
      ),
    });
  }

  return (
    <div className="col-span-4 flex flex-col gap-y-6">
      <OrganizationCollapse items={collapseItems}>
        <OrganizationCollapseHeader
          label={t(
            "organizations.back_office.organization_page.collapse.bank_account.title",
          )}
          chipProps={chipStatusProps}
        />
      </OrganizationCollapse>
    </div>
  );
}
