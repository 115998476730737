import { PropsWithChildren, ReactNode } from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import {
  Chip,
  ChipProps,
  Collapse,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

export type OrganizationCollapseItem = {
  label?: string;
  value?: string | null;
  chipProps?: ChipProps;
  onClick?: () => void;
  render?: () => ReactNode;
};

type OrganizationCollapseProps = PropsWithChildren<{
  items: OrganizationCollapseItem[];
  disableCollapse?: boolean;
  defaultExpanded?: boolean;
}>;

export function OrganizationCollapse({
  children,
  items,
  disableCollapse = true,
  defaultExpanded = true,
}: OrganizationCollapseProps) {
  return (
    <Collapse
      defaultExpanded={defaultExpanded}
      disableCollapse={disableCollapse || items.length === 0}
      clickOutsideEnabled={false}
      headerClassName="py-5"
      items={items.map(
        (item, index) =>
          item.render?.() || (
            <div
              key={index}
              onClick={item.onClick}
              className={cn(
                "flex w-full flex-wrap items-center justify-between",
                { "cursor-pointer": !!item.onClick },
              )}
            >
              <Typography variant="body3-regular" className="text-primary">
                {item.label}
              </Typography>
              <div className="flex items-center gap-x-1.5">
                {item.value && (
                  <Typography variant="body3-semibold" className="text-primary">
                    {item.value}
                  </Typography>
                )}
                {item.chipProps && <Chip {...item.chipProps} />}
              </div>
            </div>
          ),
      )}
    >
      {children}
    </Collapse>
  );
}
