import { MouseEvent, useState } from "react";
import { toast } from "react-toastify";

import { TudigoError } from "@tudigo-monorepo/core-tudigo-api";
import { imageShape, User } from "@tudigo-monorepo/core-tudigo-api-models";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  FormContextProvider,
  getSmartFormComponents,
} from "@tudigo-monorepo/web-feature-form";
import {
  useGetUserByIdQuery,
  useUpdateUserMutation,
} from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  Button,
  DotsLoader,
  Form,
  Title,
} from "@tudigo-monorepo/web-tudigo-components";

export function MySettingProfilePictureContent() {
  const { user: connectedUser } = useUserBusiness();
  const { SmartImageField } = getSmartFormComponents<User>();

  const { data: user, isLoading: isLoadingUser } = useGetUserByIdQuery({
    userId: connectedUser?.id ?? "me",
  });

  const { mutate: updateUser, isPending: isLoading } = useUpdateUserMutation({
    onSuccess: () => {
      toast((content) => (
        <Alert
          icon="Upload"
          close={content.closeToast}
          variant="success"
          size="S"
          title="MAJ"
          description="Votre photo a bien été mise à jour."
        />
      ));
    },
    onError: (err) => {
      setUpdateError(err);
      toast((content) => (
        <Alert
          icon="Error"
          close={content.closeToast}
          variant="error"
          size="S"
          title="MAJ"
          description="Une erreur est survenue lors de la mise a jour de votre photo."
        />
      ));
    },
  });

  const [updateError, setUpdateError] = useState<TudigoError | null>(null);

  const [userFormData, setUserFormData] = useState<{
    profileImage: User["profileImage"];
  }>({
    profileImage: connectedUser?.profileImage ?? imageShape,
  });

  const onSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (userFormData) {
      setUpdateError(null);
      updateUser({
        userId: connectedUser?.id ?? "me",
        data: {
          profileImage: userFormData.profileImage,
        },
      });
    }
  };

  if (isLoadingUser) {
    return <DotsLoader className="my-6 w-full justify-center" />;
  }

  if (!user) {
    return <p>Erreur lors du chargement de votre profil</p>;
  }

  return (
    <div className="m-6">
      <Title h3Brand="brand-2" level="h3" className="text-primary mb-6">
        Ma photo de profil
      </Title>
      <div className="rounded-lg border px-10 py-8">
        <FormContextProvider
          formData={userFormData}
          setFormData={setUserFormData}
          error={updateError}
        >
          <Form>
            <SmartImageField name="profileImage" />
            <Button
              isLoading={isLoading}
              className="mt-6"
              variant="primary"
              label="Valider"
              onClick={onSubmit}
            />
          </Form>
        </FormContextProvider>
      </div>
    </div>
  );
}
