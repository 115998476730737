import React from "react";
import {
  Link,
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";

import { WMACustomer } from "@tudigo-monorepo/core-tudigo-api-models";
import { wmaCustomersFields } from "@tudigo-monorepo/web-feature-wma";
import { useGetWMACustomerByIdQuery } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Button,
  IconName,
  PageHead,
  Tabs,
} from "@tudigo-monorepo/web-tudigo-components";

import { useWmaCustomerTabs } from "./hooks/use-wma-customer-tabs";

export type EditWmaCustomerPageContext = {
  customer: WMACustomer;
};

export function EditWmaCustomerPage() {
  const navigate = useNavigate();
  const customerId = useParams().customerId as string;
  const page = useParams()["*"];

  const { data: response } = useGetWMACustomerByIdQuery({
    pathParams: {
      wmaCustomerId: customerId,
    },
    fields: wmaCustomersFields,
  });

  const customer = response?.data;
  const tabs = useWmaCustomerTabs(customer);

  if (!customer) {
    return null;
  }

  return (
    <div id="organizations-list-manager-page">
      <PageHead
        title="Édition d'un client"
        withEndLine
        levels={[
          {
            iconName: "Group" as IconName,
            callback: () => {
              navigate("list/all");
            },
          },
        ]}
      >
        {page === "investments" && (
          <div className="flex w-full flex-col items-center justify-between gap-4 sm:flex-row">
            <div></div>
            <Link to="/wma/investments/create" className="w-full sm:w-fit">
              <Button
                label="Ajouter une souscription"
                variant="tertiary"
                iconRight="Plus"
                className="w-full"
              />
            </Link>
          </div>
        )}
      </PageHead>

      <Tabs tabs={tabs} className="px-6" />

      <Outlet
        context={
          {
            customer: customer,
          } satisfies EditWmaCustomerPageContext
        }
      />
    </div>
  );
}

export function useEditWmaCustomerPageContext() {
  return useOutletContext<EditWmaCustomerPageContext>();
}
