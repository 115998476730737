import {
  isBondsProject,
  isSharesProject,
  TaxExemptionMechanicsEnum,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

import { InfoCardItem } from "../../../../../shared/components/info-card-item";
import { useProjectContext } from "../../../project.context";
import { BondsProjectModalities } from "./bonds-project-modalities";
import { CommonProjectModalities } from "./common-project-modalities";
import { SharesProjectModalities } from "./shares-project-modalities";

export function ModalitiesSummaryCard() {
  const project = useProjectContext();

  const { t } = useTranslation();

  if (!project) {
    return null;
  }

  const getPaymentMethods = () => {
    const { taxExemptionMechanics } = project;
    let methods = "Carte bancaire, virement";

    if (taxExemptionMechanics?.includes(TaxExemptionMechanicsEnum.PEA)) {
      methods += ", virement PEA";
    }
    if (taxExemptionMechanics?.includes(TaxExemptionMechanicsEnum.PEA_PME)) {
      methods += ", virement PEA-PME";
    }

    return methods;
  };

  return (
    <article className="border-light-1 rounded-md border p-6">
      <h4 className="font-montserrat text-primary mb-4 font-bold">
        Modalités d’investissement
      </h4>

      <ul className="flex flex-col gap-y-3">
        <InfoCardItem
          label="Instrument financier"
          value={t(project.mechanic)}
        />

        {isSharesProject(project) && (
          <SharesProjectModalities project={project} />
        )}

        {isBondsProject(project) && (
          <BondsProjectModalities project={project} />
        )}

        <CommonProjectModalities project={project} />

        <InfoCardItem label="Moyen de paiement :" value={getPaymentMethods()} />
      </ul>
    </article>
  );
}
