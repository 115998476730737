import { Organization } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

import { useOrganizationKycBankStatusChipProps } from "../../../hooks/use-organization-kyc-bank-status-chip-props";
import { EditOrganizationLink } from "../../edit-organization-link/edit-organization-link";
import { OrganizationCollapse } from "../../organization-collapse/organization-collapse";
import { OrganizationCollapseHeader } from "../../organization-collapse/organization-collapse-header";

type BankAccountSectionProps = {
  organization: Organization;
};

export function BankAccountSection({ organization }: BankAccountSectionProps) {
  const { t } = useTranslation();

  const { organizationBankAccounts } = organization;

  const bankAccount = organizationBankAccounts[0];

  const chipStatusProps = useOrganizationKycBankStatusChipProps(organization);

  const collapseItems = [
    {
      label: t(
        "organizations.back_office.organization_page.collapse.bank_account.iban",
      ),
      value: bankAccount?.bankAccountNumber,
      chipProps: !bankAccount?.bankAccountNumber ? chipStatusProps : undefined,
    },
    {
      render: () => (
        <EditOrganizationLink
          isBankAccountUpdate
          organizationId={organization.id}
          label={t(
            "organizations.back_office.organization_page.collapse.bank_account.update_informations.button.label",
          )}
        />
      ),
    },
  ];

  return (
    <div className="col-span-4 flex flex-col gap-y-6">
      <OrganizationCollapse items={collapseItems}>
        <OrganizationCollapseHeader
          label={t(
            "organizations.back_office.organization_page.collapse.bank_account.title",
          )}
          chipProps={chipStatusProps}
        />
      </OrganizationCollapse>
    </div>
  );
}
