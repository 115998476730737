import React from "react";
import { createColumnHelper } from "@tanstack/react-table";

import { WMACustomer } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  formatCurrency,
  getImageUrl,
  ORGANIZATIONS,
} from "@tudigo-monorepo/core-tudigo-utils";
import {
  AccountCard,
  Avatar,
  Chip,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

export const useWmaCustomersColumns = () => {
  const columnHelper = createColumnHelper<WMACustomer>();
  const { t } = useTranslation();

  return [
    columnHelper.display({
      header: "Client",
      cell: (info) => (
        <AccountCard
          avatar={
            info.row.original.customerUser?.profileImage?.url ? (
              <Avatar
                url={getImageUrl(
                  info.row.original.customerUser?.profileImage,
                  32,
                  32,
                  "cropped",
                  "",
                )}
              />
            ) : (
              <Avatar>
                {info.row.original.customerUser?.email?.charAt(0) ?? "🦊"}
              </Avatar>
            )
          }
          onClick={() => {
            // if (info.row.original.investorOrganization?.id) {
            //   navigate(
            //     `/organizations/${info.row.original.investorOrganization?.id}`,
            //   );
            // } else {
            //   navigate(`/users/${info.row.original.user?.id}/edit/generals`);
            // }
          }}
          upperText={
            info.row.original.customerUser?.firstName
              ? `${info.row.original.customerUser?.firstName} ${info.row.original.customerUser?.lastName}`
              : undefined
          }
          lowerText={info.row.original.customerUser?.email}
          lowerTextAsChip
        />
      ),
    }),
    columnHelper.accessor("investedAmount", {
      header: "Montant investi",
      cell: (info) => {
        const investedAmount =
          info.row.original.customerUser?.investorProfile?.statistics
            ?.totalInvestmentsAmountInCents ?? 0;

        return (
          <Typography variant="body3-regular">
            {formatCurrency(investedAmount)}
          </Typography>
        );
      },
    }),
    columnHelper.accessor("countInvestments", {
      header: "Investissements",
      cell: (info) => {
        const countInvestments =
          info.row.original.customerUser?.investorProfile?.statistics
            ?.totalInvestmentsCount ?? 0;

        return (
          <Typography variant="body3-regular">{countInvestments}</Typography>
        );
      },
    }),
    columnHelper.accessor("status", {
      header: "Statut invitation",
      cell: (info) => (
        <Chip
          label={t(
            `wma.customers_list_page.customer.status_invitation.label.${info.row.original.status}`,
          )}
          iconLeft="DotChip"
          type={(() => {
            switch (info.row.original.status) {
              case "invited":
                return "waiting";
              case "accepted":
                return "success";
              case "rejected":
                return "error";
              case "canceled":
                return "info";
              default:
                return "warning";
            }
          })()}
        />
      ),
    }),
    columnHelper.accessor("status", {
      header: "Statut qualification",
      cell: (info) => {
        if (info.row.original.status !== "accepted") {
          return;
        }

        const { isQualificationExpired } = info.row.original.customerUser
          ?.investorProfile ?? { isQualificationExpired: true };

        return (
          <Chip
            label={
              isQualificationExpired
                ? t(
                    "wma.customers_list_page.customer.status_qualification.label.to_complete",
                  )
                : t(
                    "wma.customers_list_page.customer.status_qualification.label.completed",
                  )
            }
            iconLeft="DotChip"
            type={isQualificationExpired ? "warning" : "success"}
          />
        );
      },
    }),
    columnHelper.accessor("status", {
      header: "Statut KYC",
      cell: (info) => {
        if (info.row.original.status !== "accepted") {
          return;
        }

        const isAnOrganizationKycStatusNotValid =
          info.row.original.customerUser?.organizationsOwned?.some(
            (organization) =>
              organization.internalKycStatus !==
              ORGANIZATIONS.INTERNAL_KYC_STATUS.APPROVED,
          );

        return (
          <Chip
            label={
              isAnOrganizationKycStatusNotValid ? "À compléter" : "Complet"
            }
            iconLeft="DotChip"
            type={isAnOrganizationKycStatusNotValid ? "warning" : "success"}
          />
        );
      },
    }),
  ];
};
