import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

const badgeVariants = cva("rounded text-xs", {
  variants: {
    variant: {
      default:
        "border-transparent bg-primary text-primary-foreground shadow hover:bg-primary/80",
      primary:
        "bg-accent-super-light-rebrand border border-accent-rebrand py-1 px-2 text-primary-rebrand",
      secondary:
        "bg-black-800 border border-black-500 text-black-900 py-1 px-2",
      tertiary: "bg-white border border-black-500 text-black-900 py-1 px-2",
      destructive:
        "border-transparent bg-destructive text-destructive-foreground shadow hover:bg-destructive/80",
      outline: "text-foreground",
      success: "bg-success-light py-2 px-2 text-success-medium-rebrand",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function BadgeRebrand({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { BadgeRebrand, badgeVariants };
