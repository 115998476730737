import { useCallback, useEffect, useState } from "react";
import queryString from "query-string";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { equitySavingPlanShape } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  useCreateOrganizationEquitySavingPlanMutation,
  useCreateOrganizationMutation,
  useGetOrganizationByIdQuery,
  useInvalidateConnectedUser,
} from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  Modal,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { checkIfOrganizationEquitySavingPlanAlreadyExists } from "../../../utils/equity-saving-plans-utils";
import { organizationRequestFields } from "../../../utils/organization-request-fields-utils";
import { OrganizationTypeSelector } from "../../organization-type-selector/organization-type-selector";
import { EquitySavingPlanForm } from "../equity-saving-plan-form";
import {
  AvailableAccountType,
  useInvestmentAccountContext,
} from "../investment-account-context";
import { ReplaceInvestmentAccountConfirmationModal } from "../replace-investment-account-confirmation-modal";

const accountTypeTranslateKeys: Record<AvailableAccountType, string> = {
  company: "Personne morale",
  pea: "PEA",
  pea_pme: "PEA-PME",
};

export interface CreateInvestmentAccountModalProps {
  investProjectUrl?: string;
  accountTypes?: AvailableAccountType[];
}

export function CreateInvestmentAccountModal(
  props: CreateInvestmentAccountModalProps,
) {
  const { investProjectUrl, accountTypes = ["pea", "pea_pme", "company"] } =
    props;

  const { user } = useUserBusiness();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const invalidateUser = useInvalidateConnectedUser();

  const {
    equitySavingPlan,
    isModalOpen,
    selectedAccountType,
    onModalOpenChange,
    setIsModalOpen,
    setEquitySavingPlan,
    setError,
    setSelectedAccountType,
  } = useInvestmentAccountContext();

  const [isReplaceAccountModalOpen, setIsReplaceAccountModalOpen] =
    useState(false);

  useEffect(() => {
    const isModalOpenFromQuery = searchParams.get("accountModal");

    if (isModalOpenFromQuery === "open") {
      setIsModalOpen(true);
    }
  }, [searchParams, setIsModalOpen]);

  const toastError = () => {
    toast((content) => (
      <Alert
        close={content.closeToast}
        icon="Error"
        variant="error"
        title={t("organizations.create_organization.alert.error", {
          accountType:
            accountTypeTranslateKeys[
              selectedAccountType as AvailableAccountType
            ],
        })}
      />
    ));
  };

  const { data: individualOrganization } = useGetOrganizationByIdQuery(
    user?.individualOrganization?.id as string,
    {
      fields: organizationRequestFields,
    },
  );

  const { mutate: createOrganization, isPending: isCreateOrgLoading } =
    useCreateOrganizationMutation({
      onSuccess: (orgRes) => {
        if (orgRes.data.type === "company") {
          const redirectUrl = `/organization/${
            orgRes.data.id
          }/kyc/personal-information?${queryString.stringify({
            investProjectUrl,
          })}`;

          navigate(redirectUrl);
        }
      },
      onError: toastError,
    });

  const {
    mutate: createEquitySavingPlan,
    isPending: isCreateEquitySavingPlanLoading,
  } = useCreateOrganizationEquitySavingPlanMutation({
    onSuccess: () => {
      onModalOpenChange(false);
      invalidateUser?.();
      toast((content) => (
        <Alert
          close={content.closeToast}
          icon="Checked"
          variant="success"
          title={t("organizations.create_organization.alert.success", {
            accountType:
              accountTypeTranslateKeys[
                selectedAccountType as AvailableAccountType
              ],
          })}
        />
      ));
      const targetUrl = localStorage.getItem("target-url") ?? "";
      if (targetUrl !== "") {
        return navigate("/dispatch");
      }
    },
    onError: (err) => {
      setError(err);
      toastError();
    },
  });

  const handleSelectedAccountTypeChange = useCallback(
    (type: AvailableAccountType) => {
      setSelectedAccountType(type);

      if (type !== "company") {
        setEquitySavingPlan({ ...equitySavingPlan, type });

        const hasAlreadySameEquitySavingPlan =
          checkIfOrganizationEquitySavingPlanAlreadyExists(
            individualOrganization,
            type,
          );

        if (hasAlreadySameEquitySavingPlan) {
          setIsReplaceAccountModalOpen(true);
        }
      }
    },
    [
      setSelectedAccountType,
      setEquitySavingPlan,
      equitySavingPlan,
      individualOrganization,
    ],
  );

  const handleCreateAccountConfirmation = useCallback(() => {
    if (selectedAccountType === "company") {
      return createOrganization({ type: "company" });
    }

    if (individualOrganization) {
      createEquitySavingPlan({
        organizationId: individualOrganization.id,
        fields: organizationRequestFields,
        data: {
          ...equitySavingPlan,
          accountNumber: equitySavingPlan.accountNumber.toString(),
        },
      });
    }
  }, [
    selectedAccountType,
    individualOrganization,
    equitySavingPlan,
    createOrganization,
    createEquitySavingPlan,
  ]);

  if (isReplaceAccountModalOpen && selectedAccountType !== "company") {
    return (
      <ReplaceInvestmentAccountConfirmationModal
        open
        accountType={selectedAccountType}
        onConfirm={() => setIsReplaceAccountModalOpen(false)}
        onCancel={() => {
          setIsReplaceAccountModalOpen(false);
          setSelectedAccountType(undefined);
          setEquitySavingPlan(equitySavingPlanShape);
        }}
      />
    );
  }

  return (
    <Modal
      title={t("organizations.create_organization.modal.title")}
      confirmLabel={t("organizations.create_organization.modal.confirm_label")}
      isOpen={isModalOpen}
      setIsOpen={onModalOpenChange}
      confirmAction={handleCreateAccountConfirmation}
      confirmButtonProps={{
        isLoading: isCreateEquitySavingPlanLoading || isCreateOrgLoading,
        disabled: !selectedAccountType,
      }}
      className="min-h-[605px] w-[calc(100%-32px)] sm:max-w-[654px]"
    >
      <div className="flex flex-col gap-y-4 p-4">
        <Typography
          variant="body3-regular"
          className="border-b border-dashed pb-4"
        >
          {t("organizations.create_organization.modal.desc")}
        </Typography>
        <div className="w-full sm:w-[251px]">
          <OrganizationTypeSelector
            options={accountTypes}
            selectedValue={selectedAccountType}
            onSelect={(type) =>
              handleSelectedAccountTypeChange(type as AvailableAccountType)
            }
          />
        </div>
        {individualOrganization && selectedAccountType?.includes("pea") && (
          <EquitySavingPlanForm />
        )}
      </div>
    </Modal>
  );
}
