import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { UserRole } from "@tudigo-monorepo/core-tudigo-api-models";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  useGetUserByIdQuery,
  useMarkIntroHasDone,
} from "@tudigo-monorepo/web-tudigo-api-client";
import { Button, Toggle } from "@tudigo-monorepo/web-tudigo-components";
import { gtmTrackEvent, useTrack } from "@tudigo-monorepo/web-tudigo-tracking";

import { ExitConfirmationModal } from "../qualification-page/modals/exit-confirmation-modal";
import { InvestorIntro } from "./content/investor-intro";
import { InvestorIntroActions } from "./content/investor-intro-actions";
import { ProjectHolderIntro } from "./content/project-holder-intro";
import { ProjectHolderIntroActions } from "./content/project-holder-intro-actions";
import { ProjectHolderDepositForm } from "./content/projet-holder-deposit-form";

export function QualificationIntroPage() {
  const navigate = useNavigate();
  const { user } = useUserBusiness();
  const userId = user?.id ?? "me";

  const { refetch: refetchUser } = useGetUserByIdQuery({ userId });
  const { mutate: markIntroDone } = useMarkIntroHasDone({
    onSuccess: async () => {
      await refetchUser();
      navigate("/dispatch");
    },
  });

  useTrack(() => {
    gtmTrackEvent("ui.sign_up_completed.validated", {
      email: user?.email,
    });

    return true;
  }, []);

  const handleExitQualification = () => markIntroDone({ userId });

  const [selectedProfile, setSelectedProfile] = useState<UserRole>(
    UserRole.INVESTOR,
  );
  const [showExitDialog, setShowExitDialog] = useState(false);
  const [showProjectHolderDepositForm, setShowProjectHolderDepositForm] =
    useState(false);

  return (
    <div className="relative min-h-screen">
      <div className="relative flex flex-col items-center justify-center gap-y-3 px-4 py-8 sm:flex-row sm:px-6">
        <Toggle
          name="profile"
          leftText="Investisseur"
          rightText="Porteur de projet"
          checked={selectedProfile === UserRole.PROJECT_HOLDER}
          onChange={(checked) => {
            const profile = checked
              ? UserRole.PROJECT_HOLDER
              : UserRole.INVESTOR;
            setSelectedProfile(profile);
          }}
          className="w-full justify-center sm:w-auto"
          blueStyle
        />
        <Button
          onClick={() => setShowExitDialog(true)}
          variant="tertiary"
          singleIcon="Cross"
          className="z-50 flex w-fit self-end sm:hidden"
        />
        <Button
          onClick={() => setShowExitDialog(true)}
          label="Quitter"
          variant="tertiary"
          iconRight="Cross"
          className="absolute right-6 z-50 !hidden h-10 w-fit sm:!flex"
        />
      </div>

      <hr />

      <div className="grid grid-cols-4 overflow-y-auto px-4 pb-[160px] pt-4 sm:grid-cols-12 sm:px-0 sm:pb-[97px] sm:pt-8">
        <div className="col-span-4 flex flex-col gap-8 sm:col-span-6 sm:col-start-4">
          {selectedProfile === UserRole.INVESTOR && <InvestorIntro />}

          {selectedProfile === UserRole.PROJECT_HOLDER &&
            (showProjectHolderDepositForm ? (
              <ProjectHolderDepositForm />
            ) : (
              <ProjectHolderIntro />
            ))}
        </div>
      </div>

      <div className="border-light-1 fixed bottom-0 left-0 z-20 flex w-full flex-col items-center justify-center gap-4 border-t bg-white px-6 py-5 sm:flex-row sm:py-8">
        {selectedProfile === UserRole.INVESTOR && (
          <InvestorIntroActions setShowExitDialog={setShowExitDialog} />
        )}

        {selectedProfile === UserRole.PROJECT_HOLDER &&
          !showProjectHolderDepositForm && (
            <ProjectHolderIntroActions
              setShowExitDialog={setShowExitDialog}
              onCreateProfile={() => {
                setShowProjectHolderDepositForm(true);
              }}
            />
          )}
      </div>

      <ExitConfirmationModal
        showExitDialog={showExitDialog}
        setShowExitDialog={setShowExitDialog}
        onExit={handleExitQualification}
      />
    </div>
  );
}
