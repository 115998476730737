import {
  isSharesProject,
  Project,
  ProjectDocumentEnum,
  TaxExemptionMechanicsEnum,
} from "@tudigo-monorepo/core-tudigo-api-models";

export const getShareProjectRequiredDocumentTypes = (
  project: Project | null,
) => {
  if (!project || !isSharesProject(project)) {
    return [];
  }

  return [
    ProjectDocumentEnum.KEY_INFORMATION_DOCUMENT,
    ProjectDocumentEnum.INVESTMENT_NOTE,
    ProjectDocumentEnum.SHARE_HOLDER_AGREEMENT,
  ];
};

export const getEquitySavingPlanRequiredDocumentTypes = (
  project: Project | null,
) => {
  if (
    !project ||
    !isSharesProject(project) ||
    !project.taxExemptionMechanics?.includes(TaxExemptionMechanicsEnum.PEA)
  ) {
    return [];
  }

  return [
    ProjectDocumentEnum.KBIS,
    ProjectDocumentEnum.ASSEMBLY_REPORT_CAPITAL_RAISING,
    ProjectDocumentEnum.STATUTES,
    ProjectDocumentEnum.WAIVER_PREFERENTIAL_SUBSCRIPTION_RIGHTS,
    ProjectDocumentEnum.SIGNATURE_PROOF_ON_PV_AND_STATUTES,
  ];
};

export const getBondProjectRequiredDocumentTypes = (
  project: Project | null,
) => {
  if (!project || isSharesProject(project)) {
    return [];
  }

  return [
    ProjectDocumentEnum.KEY_INFORMATION_DOCUMENT,
    ProjectDocumentEnum.INVESTMENT_NOTE,
    ProjectDocumentEnum.BOND_PACT,
  ];
};

export const getProjectRequiredDocumentTypes = (project: Project | null) => {
  if (!project || !project.projectDocuments) {
    return [];
  }

  if (isSharesProject(project)) {
    return getShareProjectRequiredDocumentTypes(project);
  } else {
    return getBondProjectRequiredDocumentTypes(project);
  }
};

export const getProjectOptionalDocumentTypes = (project: Project | null) => {
  const requiredDocument = getProjectRequiredDocumentTypes(project);
  const espDocument = getEquitySavingPlanRequiredDocumentTypes(project);

  requiredDocument.push(...espDocument);

  const docs = project?.projectDocuments
    ?.filter(
      (doc) => !requiredDocument.includes(doc.type as ProjectDocumentEnum),
    )
    .map((doc) => doc.type as ProjectDocumentEnum);

  return docs ?? [];
};
