import { Navigate, Route, Routes } from "react-router-dom";

import { CreateWmaCustomerPage } from "../../pages/create-wma-customer-page/create-wma-customer-page";
import { EditWmaCustomerInvestmentsContent } from "../../pages/edit-wma-customer-page/content/edit-wma-customer-investments-content";
import { EditWmaCustomerKycContent } from "../../pages/edit-wma-customer-page/content/edit-wma-customer-kyc-content";
import { EditWmaCustomerQualificationContent } from "../../pages/edit-wma-customer-page/content/edit-wma-customer-qualification-content";
import { EditWmaCustomerSelectUserContent } from "../../pages/edit-wma-customer-page/content/edit-wma-customer-select-user-content";
import { EditWmaCustomerPage } from "../../pages/edit-wma-customer-page/edit-wma-customer-page";
import { ListWmaCustomersPage } from "../../pages/list-wma-customers-page/list-wma-customers-page";

export function WmaCustomersRoutesWebApp() {
  return (
    <Routes>
      <Route path="/create" element={<CreateWmaCustomerPage />} />
      <Route path="/list/:state" element={<ListWmaCustomersPage />} />
      <Route path={"/edit/:customerId/*"} element={<EditWmaCustomerPage />}>
        <Route
          path="select-user"
          element={<EditWmaCustomerSelectUserContent />}
        />
        <Route
          path="qualification"
          element={<EditWmaCustomerQualificationContent />}
        />
        <Route path="kyc" element={<EditWmaCustomerKycContent />} />
        <Route
          path="investments"
          element={<EditWmaCustomerInvestmentsContent />}
        />
        <Route path="*" element={<Navigate to="select-user" />} />
      </Route>
      <Route path="*" element={<Navigate to="list/all" replace />} />
    </Routes>
  );
}
