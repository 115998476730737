import * as React from "react";
import { useEffect } from "react";
import PrimitiveCurrencyInput, {
  CurrencyInputProps as PrimitiveCurrencyInputProps,
} from "react-currency-input-field";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { InputGroup, InputGroupProps } from "../../input-group";

export interface CurrencyInputProps
  extends InputGroupProps,
    Omit<PrimitiveCurrencyInputProps, "onChange">,
    Omit<
      React.InputHTMLAttributes<HTMLInputElement>,
      "onChange" | "defaultValue" | "step"
    > {
  onChange?: (value: number) => void;
  hundredth?: boolean;
}

export const CurrencyInput = React.forwardRef<
  React.ElementRef<typeof PrimitiveCurrencyInput>,
  CurrencyInputProps
>((props, ref) => {
  const {
    id,
    label,
    className,
    value = "",
    placeholder = "Enter a number",
    intlConfig = { locale: "fr-FR", currency: "EUR" },
    decimalsLimit = 2,
    disabled = false,
    required = false,
    valid,
    errors,
    groupRef,
    iconRight,
    onChange,
  } = props;

  const { hundredth = true, ...rest } = props;

  const [inputValue, setInputValue] = React.useState<string>(
    (Number(value) / (hundredth ? 100 : 1)).toString(),
  );

  useEffect(() => {
    setInputValue((Number(value) / (hundredth ? 100 : 1)).toString());
  }, [hundredth, value]);

  let inputId = React.useId();
  inputId = id || inputId;

  return (
    <InputGroup
      ref={ref}
      groupRef={groupRef}
      id={inputId}
      className={className}
      disabled={disabled}
      required={required}
      valid={valid}
      label={label}
      labelPosition="top"
      errors={errors}
      hasValue={!!value}
      iconRight={iconRight}
      renderInput={({ className, ref }) => (
        <PrimitiveCurrencyInput
          {...rest}
          value={inputValue}
          ref={ref}
          className={cn("h-[38px] w-full outline-none", className)}
          intlConfig={intlConfig}
          decimalsLimit={decimalsLimit}
          placeholder={placeholder}
          onChange={undefined}
          onValueChange={(value, name, values) => {
            setInputValue(value || "");
            if (onChange) {
              onChange(
                Math.round((values?.float ?? 0) * (hundredth ? 100 : 1)),
              );
            }
          }}
        />
      )}
    />
  );
});
