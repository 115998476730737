import {
  Project,
  ProjectDocument,
  ProjectDocumentEnum,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";

const initialValue = {
  label: "",
  hidden: true,
  disabled: false,
};

export const useProjectRequiredDocumentProps = (
  project: Project | null,
  doc: ProjectDocument,
) => {
  const { isQualifiedOrWma } = useUserBusiness();
  const { t } = useTranslation();

  if (!project) {
    return initialValue;
  }

  switch (doc.type) {
    case ProjectDocumentEnum.KEY_INFORMATION_DOCUMENT:
      return {
        label: t(
          "projects.back_office.edit_project_page.documents.document_type.key_information_document",
        ),
        hidden: false,
        disabled: !isQualifiedOrWma,
      };
    case ProjectDocumentEnum.INVESTMENT_NOTE:
      return {
        label: t(
          "projects.back_office.edit_project_page.documents.document_type.investment_note",
        ),
        hidden: ["prefinished", "finished"].includes(project.status),
        disabled: !isQualifiedOrWma,
      };
    default:
      return initialValue;
  }
};
