import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { User } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient } from "../../../";

type MutationOptions = UseMutationOptions<
  TudigoResponse<void>,
  TudigoError,
  User["id"] | "me"
>;

export const deleteUser = async (userId: User["id"] | "me") => {
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.users.deleteUser(userId);

  return response.data;
};

export const useDeleteUserMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (userId: User["id"] | "me") => deleteUser(userId),
    onSuccess: async (...props) => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.USERS, "list"],
        predicate: ({ queryKey }) => {
          return queryKey[0] === QUERY_KEYS.USERS && queryKey[1] === "list";
        },
      });
      options?.onSuccess?.(...props);
    },
  });
};
