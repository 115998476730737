import React from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { PageEnum, useSpaceBusiness } from "@tudigo-monorepo/tudigo-session";
import { Button, Title } from "@tudigo-monorepo/web-tudigo-components";

import { useKYCContext } from "../../../kyc-page";
import { KycSectionChipStatus } from "../../../shared/components/kyc-section-chip-status";
import { UboSummaryCollapseListElement } from "../components/ubo-summary-collapse-list-element";

export function UboSummarySection() {
  const { organization } = useKYCContext();
  const ultimateBeneficialOwners = organization.ultimateBeneficialOwners;

  const { currentPagePaths } = useSpaceBusiness();
  const kycPagePath = currentPagePaths[
    PageEnum.ORGANIZATION_KYC_FUNNEL
  ].replace(":organizationId", organization.id);

  const editPagePath = `${kycPagePath}/legal-information`;
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex gap-2">
          <Title level="h4">
            {t(
              "kyc_funnel.step_company_information.section.ultimate_beneficial_owners.title",
            )}
          </Title>
          <KycSectionChipStatus section="ubo-informations" />
        </div>
        <Link to={editPagePath}>
          <Button
            label={t("kyc_funnel.final_summary.edit_btn.label")}
            iconLeft="Pen"
            variant="tertiary"
          />
        </Link>
      </div>
      <div className="border-light-2 my-4 border-b" />
      <div className="flex flex-col gap-2">
        {ultimateBeneficialOwners.map((ubo, index) => (
          <UboSummaryCollapseListElement beneficialOwner={ubo} key={index} />
        ))}
      </div>
    </div>
  );
}
