import { FC, ReactElement, useState } from "react";
import { NavLink } from "react-router-dom";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Icon } from "../../icons/icon";
import { Title } from "../../title";
import { Typography } from "../../typography";
import { TabElement } from "../tabs";

type MobileTabsProps = {
  tabs: TabElement[];
  handleTabChange: (tabName: string) => void;
  selectedTab: string;
  className?: string;
};

type TabTextProps = {
  name: TabElement["name"];
};

export function MobileTabs(props: MobileTabsProps): ReactElement {
  const { className = "", tabs, handleTabChange, selectedTab } = props;
  const [mobileInputSelectIsClosed, setMobileInputSelectIsClosed] =
    useState(true);

  const TabText: FC<TabTextProps> = ({ name }) => (
    <div
      onClick={() => {
        setMobileInputSelectIsClosed(true);
        handleTabChange(name);
      }}
      className="cursor-pointer"
    >
      <Typography
        variant="body3-regular"
        className={cn({ "font-semibold": name === selectedTab })}
      >
        {name}
      </Typography>
    </div>
  );

  return (
    <div className={className}>
      <div
        className={cn(
          "relative block",
          "w-full",
          "p-3",
          "flex items-center justify-between",
          "cursor-pointer",
          "border-dark-2 border-0 border-b-2",
          "bg-white",
          {
            hidden: tabs.length === 0,
          },
        )}
        onClick={() => setMobileInputSelectIsClosed(!mobileInputSelectIsClosed)}
      >
        <Title level="h5">{selectedTab}</Title>
        <div
          className={cn("transition", {
            "rotate-180": !mobileInputSelectIsClosed,
          })}
        >
          <Icon name="ChevronDown" primaryColor={themeColors["dark-2"]} />
        </div>
      </div>

      <div
        className={cn(
          "border",
          "rounded-lg",
          "shadow-[0_0_20px_0_rgba(0,0,0,0.1)]",
          "mt-2 px-8 py-7",
          "flex flex-col gap-4",
          "transition",
          {
            hidden: mobileInputSelectIsClosed,
          },
        )}
      >
        {tabs.map(({ name, redirectTo }, index) => {
          if (!redirectTo) return <TabText key={index} name={name} />;

          return (
            <NavLink key={index} to={redirectTo}>
              <TabText name={name} />
            </NavLink>
          );
        })}
      </div>
    </div>
  );
}
