import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { ClubMember } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { CollectionRequestParams, getApiClient } from "../../..";
import { getQueryListKey } from "../../query-key-factory";

type ClubMembersQuery = {
  fields?: string;
  limit?: number;
  offset?: number;
  sort_direction?: "ASC" | "DESC";
};

const defaultFields =
  "id,role,contract,user,status,joined_at,selected_price,created_at,updated_at,subscription,club,subscription";

export const getClubMembers = async ({
  clubId,
  query,
}: {
  clubId: string;
  query: ClubMembersQuery;
}) => {
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.clubs.getClubMembers(clubId, query);

  return response.data;
};

export const useGetClubMembers = (
  params: CollectionRequestParams<{ clubId: string }, ClubMembersQuery>,
  options?: Partial<UseQueryOptions<TudigoResponse<ClubMember[]>, TudigoError>>,
) => {
  const { pagination, fields = defaultFields, filters, pathParams } = params;

  return useQuery({
    queryKey: getQueryListKey({
      key: QUERY_KEYS.MY_CLUB_MEMBERS,
      filters,
    }),
    queryFn: () =>
      getClubMembers({
        clubId: pathParams.clubId,
        query: {
          ...filters,
          fields,
          offset: pagination?.offset,
          limit: pagination?.limit,
        },
      }),
    ...options,
  });
};
