import React from "react";
import { Link } from "react-router-dom";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Chip, ChipSize, ChipType } from "../chip";
import { ChipCount } from "../chip-count";
import { Icon } from "../icons/icon";
import { IconName } from "../icons/icon-name.type";
import { MetricsHeading } from "../metrics-heading";

export type MetricsChipProps = {
  label: string | number;
  type?: ChipType;
  size?: ChipSize;
  iconLeft?: IconName;
  count?: boolean;
  onClick?: () => void;
};

export type MetricsCardProps = {
  title: string;
  navigateTo?: string;
  value?: string | number;
  contextualMenu?: React.ReactNode;
  heading?: {
    firstChip?: MetricsChipProps;
    secondChip?: MetricsChipProps;
    subtitle?: string;
  };
  content?: {
    chips?: MetricsChipProps[];
    sideChip?: MetricsChipProps & {
      icon?: IconName;
    };
    sideText?: string;
  };
  footer?: {
    text?: string;
    className?: string;
    cta?: {
      text: string;
      onClick?: () => void;
    };
  };
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
};

export function MetricsCard(props: React.PropsWithChildren<MetricsCardProps>) {
  const {
    title,
    value,
    contextualMenu,
    heading,
    content,
    children,
    className,
    footer,
    navigateTo,
  } = props;

  return (
    <article
      className={cn(
        "h-auto min-h-[310px] min-w-72 md:min-h-[175px] md:min-w-full",
        "flex flex-col justify-between p-4",
        "rounded-lg bg-white shadow-[0_0_20px_0_rgba(0,0,0,0.1)]",
        className,
      )}
    >
      {contextualMenu && (
        <div className="flex w-full flex-col items-center justify-between gap-4 sm:flex-row">
          {contextualMenu}
        </div>
      )}

      <div className="flex flex-col gap-y-2.5">
        <MetricsHeading
          title={title}
          subtitle={heading?.subtitle}
          firstChip={heading?.firstChip}
          secondChip={heading?.secondChip}
        />
        <div className="flex flex-col gap-y-1">
          <div className="flex flex-col gap-6 gap-y-2 min-[400px]:flex-row min-[400px]:items-center">
            <h1 className="text-primary font-brand text-[27px]">{value}</h1>

            <div className="flex items-center gap-x-2 pb-2 min-[400px]:pb-0">
              {content?.sideChip ? (
                content?.sideChip?.count ? (
                  <ChipCount {...content.sideChip} />
                ) : (
                  <Chip
                    {...content.sideChip}
                    iconLeft={content?.sideChip?.icon}
                  />
                )
              ) : null}
              {content?.sideText ? (
                <p className="text-dark-2 font-sans text-sm">
                  {content.sideText}
                </p>
              ) : null}
            </div>
          </div>
          <div className="flex flex-wrap items-center gap-1">
            {content?.chips?.map((chip, index) =>
              chip.count ? (
                <ChipCount key={index} {...chip} />
              ) : (
                <Chip key={index} {...chip} />
              ),
            )}
          </div>
          {children}
        </div>

        <div
          className={cn("flex items-center justify-between", footer?.className)}
        >
          {!!footer?.text && (
            <p className="text-dark-2 w-1/2 font-sans text-sm sm:w-fit">
              {footer.text}
            </p>
          )}
          {!!(navigateTo || footer?.cta?.onClick) && (
            <Link
              to={navigateTo ?? ""}
              onClick={footer?.cta?.onClick}
              className="hover:border-accent-medium ml-auto flex items-center gap-x-2 rounded-3xl border border-transparent px-4 py-[7px] transition-colors"
            >
              {!!navigateTo && (
                <Icon name="ArrowRight" primaryColor={themeColors["dark-2"]} />
              )}
              {!!footer?.cta?.text && (
                <p className="text-dark-2 font-sans text-sm font-medium">
                  {footer.cta.text}
                </p>
              )}
            </Link>
          )}
        </div>
      </div>
    </article>
  );
}
