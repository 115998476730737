import React from "react";

import { CMSBlock, FormPage } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { IconName } from "@tudigo-monorepo/web-tudigo-components";

import { AlertBlock } from "./blocks/alert-block";
import { ExpandedBlock } from "./blocks/expanded-block";
import { HeadingBlock } from "./blocks/heading-block";
import { InputsBlock } from "./blocks/inputs-block";
import { ParagraphBlock } from "./blocks/paragraph-block";
import { isFormPage } from "./utils/form-page";

type DynamicFormPageProps = {
  page: FormPage;
  className?: string;
};

export function DynamicFormPage(props: DynamicFormPageProps) {
  const { page, className } = props;

  const renderBlock = (block: CMSBlock) => {
    switch (block.type) {
      case "alert":
        return (
          <AlertBlock {...block.data} icon={block.data.icon as IconName} />
        );
      case "expended-paragraph":
        return (
          <ExpandedBlock
            {...block.data}
            title={block.data.title}
            content={block.data.content}
          />
        );
      case "react":
        return block.children;
      case "heading":
        return <HeadingBlock {...block.data} />;
      case "paragraph":
        return <ParagraphBlock {...block} />;
      case "inputs":
        return <InputsBlock inputs={page.inputs} />;
    }
  };

  React.useEffect(() => {
    if (isFormPage(page) && page.title) {
      document.title = page.title;
    }
  }, [page]);

  return (
    <section
      className={cn("flex min-h-full w-full flex-col gap-y-4", className)}
    >
      {isFormPage(page)
        ? page.rendering?.map((component, index) => (
            <React.Fragment key={index}>
              {renderBlock(component)}
            </React.Fragment>
          ))
        : null}
    </section>
  );
}
