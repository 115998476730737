import { Link } from "react-router-dom";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  PageEnum,
  useSpaceBusiness,
  useUserBusiness,
} from "@tudigo-monorepo/tudigo-session";

import { ButtonRebrand } from "../../ui/button-rebrand";
import { LoginOrSignupRebrand } from "../login-or-signup-rebrand";
import { UserAccountLink } from "../user-account-link";

export const NavbarUserBlock = () => {
  const { user } = useUserBusiness();
  const { currentPagePaths } = useSpaceBusiness();
  const { t } = useTranslation();

  if (!user) {
    return <LoginOrSignupRebrand className="md:justify-end" />;
  }

  return (
    <Link to={currentPagePaths[PageEnum.DASHBOARD]}>
      <ButtonRebrand
        size="lg"
        variant="accent-light"
        className="font-roobert gap-x-2 lg:hidden"
      >
        {t("my_account")}
      </ButtonRebrand>
      <UserAccountLink className="hidden lg:ml-auto lg:flex" />
    </Link>
  );
};
