import { createColumnHelper } from "@tanstack/react-table";

import { ClubMember } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Chip, Typography } from "@tudigo-monorepo/web-tudigo-components";

export function useGetMyCirclesColumns() {
  const columnHelper = createColumnHelper<ClubMember>();

  const { t } = useTranslation();

  return [
    columnHelper.accessor("club.content.name", {
      header: t("space_investor.my_circles.list.columns.name"),
      cell: (info) => (
        <Typography variant="body3-regular">
          {info.getValue() || "-"}
        </Typography>
      ),
    }),
    columnHelper.accessor("status", {
      header: t("space_investor.my_circles.list.columns.type"),
      cell: (info) => {
        switch (info.getValue()) {
          case "active":
          case "draft":
            return (
              <Chip
                type="secondary"
                label={t("space_investor.my_circles.list.columns.type.premium")}
              />
            );
          case "following":
            return (
              <Chip
                type="secondary"
                label={t(
                  "space_investor.my_circles.list.columns.type.following",
                )}
              />
            );
          default:
            return (
              <Chip
                type="secondary"
                label={t("space_investor.my_circles.list.columns.type.other")}
              />
            );
        }
      },
    }),
    columnHelper.accessor("status", {
      header: t("space_investor.my_circles.list.columns.premium_status"),
      cell: (info) => {
        if (info.getValue() === "following") {
          return;
        }

        const chipType = {
          draft: "warning",
          active: "success",
          deleted: "error",
        } as const;

        const chipLabel: Record<keyof typeof chipType, string> = {
          draft: t(
            "space_investor.my_circles.list.columns.premium_status.draft",
          ),
          active: t(
            "space_investor.my_circles.list.columns.premium_status.active",
          ),
          deleted: t(
            "space_investor.my_circles.list.columns.premium_status.deleted",
          ),
        };

        const status = info.getValue() as keyof typeof chipType;

        return (
          <Chip
            type={chipType[status]}
            iconLeft="DotChip"
            label={chipLabel[status]}
          />
        );
      },
    }),
  ];
}
