import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import queryString from "query-string";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { ClubMember } from "@tudigo-monorepo/core-tudigo-api-models";
import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  QUERY_KEYS,
  SIGNATURE_REQUESTS,
} from "@tudigo-monorepo/core-tudigo-utils";
import {
  getQueryListKey,
  getSignatureRequestSyncById,
} from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Icon,
  SignatureIframe,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

export const FunnelClubMembershipForm = () => {
  const { t } = useTranslation();
  const { clubId } = useParams() as Record<"step" | "clubId", string>;
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const signLink =
    (queryString.parse(searchParams.toString())?.signLink as string) ?? "";
  const signId =
    (queryString.parse(searchParams.toString())?.signId as string) ?? "";

  useEffect(() => {
    if (!signLink) {
      navigate(`/clubs/join/${clubId}/description`);
    }
  }, [clubId, navigate, signLink]);

  const checkIfBEIsSync = async () => {
    const clubMembers = (await queryClient.fetchQuery({
      queryKey: getQueryListKey({ key: QUERY_KEYS.MY_CLUB_MEMBERS }),
    })) as TudigoResponse<ClubMember[]>;

    const currentClubMember = clubMembers?.data?.find(
      (clubMember) => clubMember.club.id === clubId,
    );

    const currentClubHasContractSigned =
      currentClubMember?.contract?.signatureRequest?.status ===
      SIGNATURE_REQUESTS.STATUS.DONE;

    const hasSignedAndIntentToken =
      currentClubHasContractSigned &&
      currentClubMember?.subscription?.latestPaymentIntent?.token;

    return hasSignedAndIntentToken;
  };

  const handleSucessYouSign = async (loop = 0) => {
    await getSignatureRequestSyncById(signId);

    await queryClient.invalidateQueries({
      queryKey: getQueryListKey({
        key: QUERY_KEYS.MY_CLUB_MEMBERS,
      }),
    });

    const isSync = await checkIfBEIsSync();

    if (loop > 3) {
      return;
    }

    if (isSync) {
      return navigate(`/clubs/join/${clubId}/payment`);
    }

    handleSucessYouSign(loop + 1);
  };

  return (
    <>
      <div className="border-b pb-5">
        <Title>{t("my_account.wep_app.clubs.membership_form.title")}</Title>
        <Typography className="text-dark-2" variant="body2">
          {t("my_account.wep_app.clubs.membership_form.description")}
        </Typography>
      </div>

      <div className="border-primary flex gap-4 rounded-lg border p-4">
        <div className="bg-light-2 flex h-8 w-8 items-center justify-center rounded">
          <Icon name="Info" primaryColor={themeColors["primary"]} />
        </div>
        <Typography className="my-auto" variant="body2">
          {t("my_account.wep_app.clubs.membership_form.yousign")}
        </Typography>
      </div>

      {signLink && (
        <div className="signature-iframe h-screen">
          <SignatureIframe
            signatureLink={signLink}
            onSuccess={() => handleSucessYouSign()}
          />
        </div>
      )}
    </>
  );
};
