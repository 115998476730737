import { useState } from "react";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { Icon } from "@tudigo-monorepo/web-tudigo-components";

import { useDynamicFormContext } from "./store/dynamic-form-context";

export function DynamicFormDebugger() {
  const { answers, currentPageIndex } = useDynamicFormContext();

  const [visible, setVisible] = useState(true);

  return (
    <div className={cn("fixed bottom-0 right-0 z-50 bg-black ")}>
      <div className="flex justify-end" onClick={() => setVisible(!visible)}>
        <Icon
          className={cn({
            "mb-[100px] rotate-180": !visible,
          })}
          primaryColor={themeColors["white"]}
          name={visible ? "Cross" : "ChevronDown"}
        />
      </div>
      <div
        className={cn(
          "max-h-[300px] flex-col gap-y-4 overflow-y-auto p-6 text-white",
          {
            hidden: !visible,
            flex: visible,
          },
        )}
      >
        <pre>currentPageIndex: {JSON.stringify(currentPageIndex, null, 2)}</pre>
        <pre>answers: {JSON.stringify(answers, null, 2)}</pre>
      </div>
    </div>
  );
}
