import React from "react";

import {
  isBondsProject,
  isSharesProject,
} from "@tudigo-monorepo/core-tudigo-api-models";

import { useInvestmentFunnelPageContext } from "../../../pages/investment-funnel-page";
import { BondsFaqBlock } from "./parts/bonds_parts/bonds-faq-block";
import { BondsHeadProjectDetailContent } from "./parts/bonds_parts/bonds-head-project-detail-content";
import { InvestmentAmountForm } from "./parts/investment-amount-form";
import { SharesFaqBlock } from "./parts/shares_parts/shares-faq-block";
import { SharesHeadProjectDetailContent } from "./parts/shares_parts/shares-head-project-detail-content";
import { TaxExemptionEligibilitySection } from "./parts/shares_parts/tax-exemption-eligibility-section";

export function InvestmentFunnelSelectInvestmentAmountContent() {
  const { project } = useInvestmentFunnelPageContext();

  if (isSharesProject(project)) {
    return (
      <div className="investment-funnel-step-select-amount">
        <SharesHeadProjectDetailContent project={project} />
        <InvestmentAmountForm />
        {project?.taxExemptionMechanics &&
          project?.taxExemptionMechanics?.length > 0 && (
            <TaxExemptionEligibilitySection />
          )}
        <SharesFaqBlock />
      </div>
    );
  }

  if (isBondsProject(project)) {
    return (
      <div className="investment-funnel-step-select-amount">
        <BondsHeadProjectDetailContent project={project} />
        <InvestmentAmountForm />
        <BondsFaqBlock />
      </div>
    );
  }

  return null;
}
