import React from "react";

import { ClubMember } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";
import {
  NTable,
  NTableBody,
  NTableCell,
  NTableHead,
  NTableHeader,
  NTableRow,
} from "@tudigo-monorepo/web-tudigo-components";

export const ClubLeaderMemberDetailSubscriptionPeriodsTable = (props: {
  clubMember: ClubMember;
}) => {
  const clubMember = props.clubMember;
  const subscriptionPeriods = clubMember?.subscriptionPeriods ?? [];
  const { t } = useTranslation();

  return (
    <div className="mx-6">
      <NTable>
        <NTableHeader>
          <NTableRow>
            <NTableHead>
              {t(
                "club_leader.page_detail_member.subscriptions_periods_table.column.start_date",
              )}
            </NTableHead>
            <NTableHead>
              {t(
                "club_leader.page_detail_member.subscriptions_periods_table.column.end_date",
              )}
            </NTableHead>
            <NTableHead>
              {t(
                "club_leader.page_detail_member.subscriptions_periods_table.column.cancellation_date",
              )}
            </NTableHead>
            <NTableHead>
              {t(
                "club_leader.page_detail_member.subscriptions_periods_table.column.amount",
              )}
            </NTableHead>
          </NTableRow>
        </NTableHeader>
        <NTableBody>
          {(subscriptionPeriods.length > 0 &&
            subscriptionPeriods.map((row, index) => (
              <NTableRow key={index}>
                <NTableCell>
                  {new Date(row.currentPeriodStartDate).toLocaleDateString()}
                </NTableCell>
                <NTableCell>
                  {new Date(row.currentPeriodEndDate).toLocaleDateString()}
                </NTableCell>
                <NTableCell>
                  {row.cancellationDate
                    ? new Date(row.cancellationDate).toLocaleDateString()
                    : ""}
                </NTableCell>
                <NTableCell>
                  {formatCurrency(row?.subscription.price.unitAmountInCents)}
                </NTableCell>
              </NTableRow>
            ))) || (
            <NTableRow>
              <NTableCell colSpan={4}>
                {t(
                  "club_leader.page_detail_member.subscriptions_periods_table.no_result",
                )}
              </NTableCell>
            </NTableRow>
          )}
        </NTableBody>
      </NTable>
    </div>
  );
};
