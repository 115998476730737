import React from "react";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  ButtonRebrand,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@tudigo-monorepo/web-tudigo-components";

import { useNewsItem } from "../context/news-item-provider";
import { NewsInvestForm, NewsInvestFormValues } from "../news-invest-form";
import { useUpdateNews } from "./useUpdateNews";

export function NewsUpdateModal({
  isDialogOpen,
  setEditDialogOpen,
}: {
  isDialogOpen: boolean;
  setEditDialogOpen: (isOpen: boolean) => void;
}) {
  const { t } = useTranslation();
  const { updateProjectNews, isPending } = useUpdateNews(() =>
    setEditDialogOpen(false),
  );

  const news = useNewsItem();

  const defaultNews = {
    ...news,
    document: news.document ? [news.document] : [],
  } as NewsInvestFormValues;

  return (
    <Dialog open={isDialogOpen} onOpenChange={setEditDialogOpen}>
      <DialogContent>
        <DialogTitle>
          {t("back_office.edit_project.project_news.update_news.title")}
        </DialogTitle>
        <NewsInvestForm
          onSubmit={updateProjectNews}
          defaultValues={defaultNews}
        >
          <ButtonRebrand type="submit" disabled={isPending}>
            {t(
              "back_office.edit_project.project_news.update_news.submit_button",
            )}
          </ButtonRebrand>
        </NewsInvestForm>
      </DialogContent>
    </Dialog>
  );
}
