import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
} from "react-router-dom";

import { FunnelClubPage } from "@tudigo-monorepo/feature-clubs";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { DispatchPage } from "@tudigo-monorepo/web-feature-login";
import { useImpersonateId } from "@tudigo-monorepo/web-tudigo-auth-store";
import { LeaveImpersonateButton } from "@tudigo-monorepo/web-tudigo-components";
import {
  AppLayout,
  FunnelClubLayout,
} from "@tudigo-monorepo/web-tudigo-layout";

import { useConnectedRoutes } from "./connected-routes";
import { useNotConnectedRoutes } from "./not-connected-routes";

const RouterJSX = () => {
  const { user: connectedUser } = useUserBusiness();
  const impersonateId = useImpersonateId();
  const connectedRoute = useConnectedRoutes();
  const notConnectedRoute = useNotConnectedRoutes();

  return (
    <Route>
      <Route
        element={
          <>
            {impersonateId && <LeaveImpersonateButton user={connectedUser} />}
            <Outlet />
          </>
        }
      >
        <Route path="/dispatch" element={<AppLayout />}>
          <Route index element={<DispatchPage />} />
        </Route>
        <Route path="/clubs/join/*" element={<FunnelClubLayout />}>
          <Route path=":clubId/:step" element={<FunnelClubPage />} />
        </Route>

        {connectedUser ? connectedRoute : notConnectedRoute}
      </Route>
    </Route>
  );
};

export default function createRouter() {
  const routesElements = RouterJSX();
  const routes = createRoutesFromElements(routesElements);

  return createBrowserRouter(routes);
}
