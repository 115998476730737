import React, { useMemo } from "react";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { RepaymentSchedule } from "@tudigo-monorepo/web-feature-repayments";
import { useGetInvestmentById } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  createFilterHelper,
  TudigoFilters,
} from "@tudigo-monorepo/web-tudigo-collection";
import { Title } from "@tudigo-monorepo/web-tudigo-components";

import { useMyInvestmentPageContext } from "../hooks/use-my-investment-page-context";

export function InvestmentRepaymentsContent() {
  const { project, userId, investments } = useMyInvestmentPageContext();

  const { t } = useTranslation();
  const investmentId = investments.length > 0 ? investments[0].id : undefined;

  const [filters, setFilters] = React.useState<{ investmentId?: string }>({
    investmentId: investmentId,
  });

  const { data: selectedInvestment } = useGetInvestmentById(
    {
      investmentId: filters.investmentId,
      query: {
        fields: "id,project.fields(id,mechanic,bonds_mechanic_settings)",
      },
    },
    {
      enabled: !!filters.investmentId,
    },
  );

  const filterHelper = createFilterHelper<{ investmentId: string }>();

  const filterConfiguration = useMemo(
    () => [
      filterHelper.investmentSelect("investmentId", t("project"), userId, {
        globalStatus: "validated",
        mechanic: "bonds",
        projectId: project.id,
      }),
    ],
    [filterHelper, project.id, t, userId],
  );

  return (
    <div className="m-6">
      <Title h3Brand="brand-2" className="text-primary mb-6" level="h3">
        {t("my-investments.my_investment_page.repayments.all_repayments.title")}
      </Title>
      <TudigoFilters
        useLocationAsState
        className="mb-6 mt-4"
        filters={filters}
        setFilters={setFilters}
        filtersConfiguration={filterConfiguration}
      />
      {selectedInvestment && (
        <RepaymentSchedule investment={selectedInvestment} />
      )}
    </div>
  );
}
