import React from "react";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Button, Title } from "@tudigo-monorepo/web-tudigo-components";

import { ArticleCard } from "./article-card";

export function LastNewsBlock() {
  const { t } = useTranslation();

  return (
    <div className="bg-light-3 m-6 flex justify-between gap-6 p-6">
      <div className="border-light-2 m-2 flex w-full flex-col items-center gap-6 rounded-lg border bg-white p-6">
        <Title level="h2" className="mb-2">
          {t("investment_funnel.final_step.last_news_block.title")}
        </Title>
        <div className="mx-2 flex flex-wrap justify-between gap-6 lg:mx-12">
          <ArticleCard
            articleUrl="https://www.tudigo.co/media/ressources/investissement-non-cote"
            articleImageUrl="https://assets-global.website-files.com/639497ff8a7e8d309f40978d/6613e393a8e3db7e75c4ab81_18072 (1).webp"
            articleTitle="Le guide ultime pour investir en non coté !"
            articleDate="29 Mar 2024"
            articleTags={[
              { type: "outlined", label: "Comment investir" },
              { type: "outlined", label: "Investisseur débutant" },
            ]}
          />
          <ArticleCard
            articleUrl="https://www.tudigo.co/media/analyses/deduction-impot"
            articleImageUrl="https://assets-global.website-files.com/639497ff8a7e8d309f40978d/6617dbea9bd50e737344c7ab_deduc 1.webp"
            articleTitle="Déduction d’impôt : comment exploiter ce mécanisme fiscal ?"
            articleDate="30 Jan 2024"
            articleTags={[
              { type: "outlined", label: "Défiscalisation" },
              { type: "outlined", label: "Investisseur débutant" },
            ]}
          />
        </div>
        <Button
          variant="tertiary"
          label={t("investment_funnel.final_step.last_news_block.button_label")}
          className="w-fit"
          onClick={() => {
            window.open(
              `https://www.tudigo.co/media/defiscalisation`,
              "_blank",
            );
          }}
        />
      </div>
    </div>
  );
}
