import { useNavigate } from "react-router-dom";

import { ClubV2 } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Button } from "@tudigo-monorepo/web-tudigo-components";

import { ClubCardBlock } from "../../club-card-block";

export const CircleTrackRecordsBlock = ({ club }: { club: ClubV2 }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (!club) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col justify-between gap-4 sm:flex-row">
        {club.trackRecords
          ?.slice(0, 3)
          .map((trackRecord) => (
            <ClubCardBlock key={trackRecord.id} trackRecord={trackRecord} />
          ))}
      </div>
      <div className="flex w-full justify-end">
        <Button
          variant="tertiary"
          onClick={() => navigate(`/clubs-v2/${club.id}/track-record`)}
          label={t(
            "page_club.tab_introduction.block_track_records.see_more_button.label",
          )}
        />
      </div>
    </div>
  );
};
