import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { BSRegenerationRequest } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient, getQueryDetailKey, getQueryListKey } from "../../..";

type CreateBSRegenerationRequestParams = {
  data: BSRegenerationRequest;
  investmentId: string;
  query?: {
    fields?: string;
    filters?: { [key: string]: string };
  };
};

export const createBSRegenerationRequest = async (
  params: CreateBSRegenerationRequestParams,
) => {
  const apiClient = getApiClient();

  const response =
    await apiClient.apiV1.investments.createBSRegenerationRequest(
      params.investmentId,
      params.data,
    );

  return response.data;
};

export const useCreateBSRegenerationRequestMutation = (
  options?: UseMutationOptions<
    TudigoResponse<void>,
    TudigoError,
    CreateBSRegenerationRequestParams
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (params: CreateBSRegenerationRequestParams) =>
      createBSRegenerationRequest(params),
    onSuccess: (...props) => {
      const [, params] = props;

      const investmentChangesQueryKey = getQueryListKey({
        key: `${QUERY_KEYS.INVESTMENT_STATUS_CHANGES}_${params.investmentId}`,
      });

      const investmentQueryKey = getQueryDetailKey({
        key: `${QUERY_KEYS.INVESTMENTS}`,
        id: params.investmentId,
        fields: params.query?.fields,
      });

      const userInvestmentsQueryKey = getQueryListKey({
        key: QUERY_KEYS.INVESTMENTS,
        fields: params.query?.fields,
        filters: params.query?.filters,
      });

      queryClient.invalidateQueries({
        queryKey: investmentQueryKey,
      });

      queryClient.invalidateQueries({
        queryKey: investmentChangesQueryKey,
      });

      queryClient.invalidateQueries({
        queryKey: userInvestmentsQueryKey,
      });

      options?.onSuccess?.(...props);
    },
  });
};
