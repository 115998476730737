import React from "react";
import { Outlet } from "react-router-dom";

import { mockProjects } from "@tudigo-monorepo/core-tudigo-api-models";

import { ClubLeaderPageProjectHead } from "./components/club-leader-page-project-head";

export const ClubLeaderProjectDetailPage = () => {
  const project = mockProjects[0];

  return (
    <div className="w-full">
      <ClubLeaderPageProjectHead project={project} />
      <Outlet />
    </div>
  );
};
