import { useNavigate } from "react-router-dom";

import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { Modal } from "@tudigo-monorepo/web-tudigo-components";

type EndQualificationModalProps = {
  showEndDialog: boolean;
  setShowEndDialog: (show: boolean) => void;
};
export const EndQualificationModal = (props: EndQualificationModalProps) => {
  const { showEndDialog, setShowEndDialog } = props;

  const { user } = useUserBusiness();
  const organizationId = user?.individualOrganization?.id;

  const navigate = useNavigate();

  const resumeNavigation = () => navigate("/dispatch");
  const goToKyc = () =>
    navigate(`/organization/${organizationId}/kyc/personal-information`, {
      state: { from: "qualification" },
    });

  const handleConfirm = () => {
    setShowEndDialog(false);
    goToKyc();
  };

  return (
    <Modal
      title="Confirmation"
      isOpen={showEndDialog}
      setIsOpen={setShowEndDialog}
      negativeLabel="Reprendre ma navigation"
      negativeAction={resumeNavigation}
      negativeButtonProps={{ variant: "ghost" }}
      confirmAction={handleConfirm}
      confirmLabel="Valider votre identité"
      className="w-[calc(100%-32px)] sm:max-w-[654px]"
    >
      <div className="flex flex-col gap-y-6 px-6 py-4">
        <p className="font-montserrat text-primary text-xs">
          Pour investir vous devez valider votre identité.
        </p>
        <p className="font-montserrat text-primary text-xs font-bold">
          Vous pouvez choisir de le faire dès à présent ou retourner aux
          opportunités proposées par Tudigo et finaliser ce parcours plus tard
        </p>
      </div>
    </Modal>
  );
};
