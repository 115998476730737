import { useQuery } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { Todo } from "@tudigo-monorepo/core-tudigo-api-models";

import { CollectionRequestParams, getApiClient } from "..";
import queryKeyFactory from "./query-key-factory";

const userTodosKeys = queryKeyFactory("user-todos");

export const useGetMyTodosQuery = (
  params: CollectionRequestParams<{ userId: string }, { status: string }>,
) => {
  const { pagination, filters, fields } = params;
  const apiClient = getApiClient();

  return useQuery<TudigoResponse<Todo[]>, TudigoError>({
    refetchOnMount: false,
    queryKey: userTodosKeys.list(fields, filters),
    queryFn: async () => {
      const response = await apiClient.apiV1.userTodosEndpoints.getUserTodos({
        ...filters,
        fields,
        offset: pagination?.offset,
        limit: pagination?.limit,
      });

      return response.data;
    },
  });
};
