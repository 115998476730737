import React from "react";
import { useIntl } from "react-intl";

import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { useGetInvestmentById } from "@tudigo-monorepo/web-tudigo-api-client";
import { TudigoFilters } from "@tudigo-monorepo/web-tudigo-collection";
import {
  PageHead,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { RepaymentSchedule } from "../../shared/components/repayment-schedule/repayment-schedule";
import { useCreditorFilters } from "./hooks/use-creditor-repayment-filters";

export function UserRepaymentsPage() {
  const { user } = useUserBusiness();
  const userId = user?.id as string;

  const intl = useIntl();

  const [filters, setFilters] = React.useState<{ investmentId?: string }>({
    investmentId: undefined,
  });

  const filterConfiguration = useCreditorFilters(userId);

  const { data: selectedInvestment } = useGetInvestmentById(
    {
      investmentId: filters.investmentId,
      query: {
        fields: "id,project.fields(id,mechanic,bonds_mechanic_settings)",
      },
    },
    {
      enabled: !!filters.investmentId,
    },
  );

  return (
    <div id="my-repayments-page" className="w-full">
      <PageHead
        title={intl.formatMessage({ id: "my_repayments_page.title" })}
      />

      <div className="border-light-2 mx-6 border-b" />

      <div className="m-6">
        <Title h3Brand="brand-2" level="h3" className="text-primary mb-1.5">
          {intl.formatMessage({ id: "my_repayments_page.table.title" })}
        </Title>
        <Typography variant="body1">
          {intl.formatMessage({
            id: "my_repayments_page.table.description",
          })}
        </Typography>
        <TudigoFilters
          className="mb-6 mt-4"
          useLocationAsState
          filters={filters}
          setFilters={setFilters}
          filtersConfiguration={filterConfiguration}
        />
        {selectedInvestment && (
          <RepaymentSchedule investment={selectedInvestment} />
        )}
      </div>
    </div>
  );
}
