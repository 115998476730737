import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  Organization,
  PaymentMethodType,
  TaxExemptionMechanicsEnum,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { ORGANIZATIONS } from "@tudigo-monorepo/core-tudigo-utils";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  useGetUserOrganizationsQuery,
  useUpdateInvestmentMutation,
} from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  Button,
  DotsLoader,
  FaqBlock,
  PageHead,
} from "@tudigo-monorepo/web-tudigo-components";

import {
  investmentFunnelFields,
  useInvestmentFunnelPageContext,
} from "../../../pages/investment-funnel-page";
import { ExpandedInvestmentDetailCard } from "../../shared/expanded-investment-detail-card";
import { InvestmentFunnelAddAccountsSection } from "./parts/investment-funnel-add-accounts-section";
import { KycRedirectModal } from "./parts/kyc-redirect-modal";
import { OrganizationSelectorElement } from "./parts/organization-selector-element";

type investmentOrganizationFormData = {
  organizationId: string | null;
  paymentMethodType: string | undefined;
};

const MINIMUM_INVESTMENT_AMOUNT_PEA_PME_IN_CENTS = 100000;

export function InvestmentFunnelSelectOrganizationContent() {
  const { ongoingInvestment, project, invalidateOngoingInvestment } =
    useInvestmentFunnelPageContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useUserBusiness();

  const [modalKycOpen, setModalKycOpen] = useState(false);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<
    string | null
  >(null);

  const [investmentOrganizationFormData, setInvestmentOrganizationFormData] =
    useState<investmentOrganizationFormData>({
      organizationId: ongoingInvestment?.investorOrganization?.id ?? null,
      paymentMethodType: ongoingInvestment?.paymentMethodType ?? undefined,
    });

  const {
    data: organizations,
    isLoading,
    refetch: refetechOrganizations,
  } = useGetUserOrganizationsQuery({
    pathParams: { userId: user?.id ?? "me" },
    fields:
      "id,name,type,is_kyc_verified,pms_account,equity_saving_plan,small_business_equity_saving_plan,internal_kyc_status,ready_to_subscribe",
    pagination: { limit: 1000 },
  });

  useEffect(() => {
    refetechOrganizations();
  }, []);

  const {
    mutateAsync: updateInvestmentIntent,
    isPending: isUpdateInvestmentIntentLoading,
  } = useUpdateInvestmentMutation({
    onSuccess: async () => {
      navigate(`/projects/${project.id}/invest/${ongoingInvestment?.id}`);
    },
    onError: (err) => {
      toast((content) => (
        <Alert
          close={content.closeToast}
          icon="Error"
          variant="error"
          title={t(
            "investment_funnel.select_organization.alert_on_error.title",
          )}
        />
      ));
    },
  });

  if (isLoading || !organizations) {
    return <DotsLoader />;
  }

  const organizationsList = organizations.organizations.sort((a, b) => {
    return (
      (b.internalKycStatus === ORGANIZATIONS.INTERNAL_KYC_STATUS.APPROVED
        ? 1
        : 0) -
      (a.internalKycStatus === ORGANIZATIONS.INTERNAL_KYC_STATUS.APPROVED
        ? 1
        : 0)
    );
  });

  const submitButtonIsDisabled = () => {
    return investmentOrganizationFormData.organizationId === null;
  };

  const handleSubmit = () => {
    if (
      investmentOrganizationFormData.organizationId === null ||
      !ongoingInvestment
    )
      return;

    updateInvestmentIntent({
      investmentId: ongoingInvestment.id,
      query: {
        fields: investmentFunnelFields,
      },
      data: {
        investorOrganization: {
          id: investmentOrganizationFormData.organizationId,
        },
        paymentMethodType:
          (investmentOrganizationFormData.paymentMethodType as PaymentMethodType) ??
          undefined,
      },
    });
  };

  const projectIsEligibleForPea = project?.taxExemptionMechanics?.includes(
    TaxExemptionMechanicsEnum.PEA,
  );

  const projectIsEligibleForPeaPme = project?.taxExemptionMechanics?.includes(
    TaxExemptionMechanicsEnum.PEA_PME,
  );

  const onGoingInvestmentAmout = ongoingInvestment?.totalAmountInCents ?? 0;

  return (
    <div className="step-select-organization w-full">
      <ExpandedInvestmentDetailCard />
      <PageHead
        title="Vous investissez en tant que ..."
        className="py-4"
        withEndLine
      />

      <div className="flex flex-col gap-y-6">
        {organizationsList.map((organization: Organization) => (
          <div key={organization.id} className="flex flex-col gap-y-6">
            <OrganizationSelectorElement
              key={organization.id}
              organization={organization}
              active={
                investmentOrganizationFormData.organizationId ===
                  organization.id &&
                !investmentOrganizationFormData.paymentMethodType
              }
              setInvestmentOrganizationFormData={
                setInvestmentOrganizationFormData
              }
              setKycModalOpen={setModalKycOpen}
              setSelectedOrganizationId={setSelectedOrganizationId}
            />

            {MINIMUM_INVESTMENT_AMOUNT_PEA_PME_IN_CENTS <=
              onGoingInvestmentAmout && (
              <>
                {project.mechanic === "shares" &&
                  projectIsEligibleForPea &&
                  organization.equitySavingPlan && (
                    <OrganizationSelectorElement
                      organization={organization}
                      active={
                        investmentOrganizationFormData.organizationId ===
                          organization.id &&
                        investmentOrganizationFormData.paymentMethodType ===
                          PaymentMethodType.PEA
                      }
                      setInvestmentOrganizationFormData={
                        setInvestmentOrganizationFormData
                      }
                      isPeaAccount
                      setKycModalOpen={setModalKycOpen}
                      setSelectedOrganizationId={setSelectedOrganizationId}
                    />
                  )}

                {project.mechanic === "shares" &&
                  projectIsEligibleForPeaPme &&
                  organization.smallBusinessEquitySavingPlan && (
                    <OrganizationSelectorElement
                      organization={organization}
                      active={
                        investmentOrganizationFormData.organizationId ===
                          organization.id &&
                        investmentOrganizationFormData.paymentMethodType ===
                          PaymentMethodType.PEA_PME
                      }
                      setInvestmentOrganizationFormData={
                        setInvestmentOrganizationFormData
                      }
                      isPeaPmeAccount
                      setKycModalOpen={setModalKycOpen}
                      setSelectedOrganizationId={setSelectedOrganizationId}
                    />
                  )}
              </>
            )}
          </div>
        ))}

        {MINIMUM_INVESTMENT_AMOUNT_PEA_PME_IN_CENTS >
          onGoingInvestmentAmout && (
          <Alert
            icon="Info"
            size="F"
            title={t(
              "investment_funnel.select_organization.alert_pea_pme_minimum_amount.title",
            )}
          />
        )}
      </div>

      <div className="my-6 flex w-full justify-center">
        <Button
          isLoading={isUpdateInvestmentIntentLoading}
          disabled={submitButtonIsDisabled() || isUpdateInvestmentIntentLoading}
          className="w-full px-12 md:w-[247px]"
          variant="green"
          label="Valider"
          onClick={handleSubmit}
        />
      </div>

      <InvestmentFunnelAddAccountsSection />

      {(projectIsEligibleForPea || projectIsEligibleForPeaPme) && (
        <FaqBlock
          className="border-light-1 rounded-lg border p-6"
          items={[
            {
              question: "Comment investir via son PEA ou PEA-PME?",
              answer:
                "Vérifiez que l'entreprise est éligible au dispositif PEA ou PEA-PME sur la page de la levée de fonds. Cliquez sur le bouton Investir. Sélectionnez le compte PEA ou PEA-PME avec lequel vous souhaitez investir ou ajoutez un nouveau compte via le formulaire dédié. Signez électroniquement votre bulletin de souscription via notre interface. Et après ? Tudigo vous fournit : les coordonnées bancaires du compte sur lequel effectuer le virement, et génère automatiquement les documents nécessaires à transmettre au gestionnaire de votre compte PEA ou PEA-PME. Retrouvez tous ces documents sont disponibles dans votre espace investisseur. Pour toute précision sur ce sujet, rendez-vous sur la FAQ du site Tudigo.",
            },
          ]}
        />
      )}

      <KycRedirectModal
        modalKycOpen={modalKycOpen}
        setModalKycOpen={setModalKycOpen}
        selectedOrganizationId={selectedOrganizationId}
        projectId={project.id}
        ongoingInvestmentId={ongoingInvestment?.id}
      />
    </div>
  );
}
