import React from "react";

import { ListElement, Title } from "@tudigo-monorepo/web-tudigo-components";

import { useEditWmaInvestmentPageContext } from "../../../../edit-wma-investment-page";

export function EditWmaInvestmentPaymentTabWireReceivedSection() {
  const { investment } = useEditWmaInvestmentPageContext();

  const paymentDate = new Date(
    investment.paymentDate as string,
  ).toLocaleDateString();

  return (
    <div className="m-6">
      <Title level="h4">Paiment : Virement</Title>
      <ListElement
        className="mt-2"
        key={investment.id}
        toggleActiveOnClick={false}
        startElement={{
          upperText: "Paiement",
          useProfileCard: true,
          profileCardClassName: "min-w-[280px] flex justify-start px-3 py-2",
          img: {
            icon: {
              name: "Invest",
            },
          },
        }}
        middleElement={{
          upperText: `Réalisé le ${paymentDate}`,
          chips: [
            {
              type: "secondary",
              label: "Virement",
            },
          ],
        }}
        endElement={{
          info: {
            chip: {
              type: "success",
              label: "Validé",
              iconLeft: "DotChip",
            },
          },
        }}
      />
    </div>
  );
}
