import React from "react";

import { useGetCreditorsRepaymentsPeriodsForInvestment } from "@tudigo-monorepo/web-tudigo-api-client";
import { TudigoCollection } from "@tudigo-monorepo/web-tudigo-collection";
import { Table, TableCardList } from "@tudigo-monorepo/web-tudigo-components";

import { useRepaymentsGrossOnlyTableColumns } from "./hooks/use-repayments-gross-only-table-columns";

const myRepaymentFields =
  "id,period_index,due_date,project.fields(id,content.fields(name)),organization,investment.fields(id),debtor_repayment,capital_amount_in_cents,interest_amount_in_cents,penalty_amount_in_cents,tax_result,net_total_amount_in_cents,gross_total_amount_in_cents,status,transaction_id,transfer_date,created_at,updated_at";

export type GrossOnlyRepaymentScheduleProps = {
  investmentId: string;
};

export const GrossOnlyRepaymentSchedule = (
  props: GrossOnlyRepaymentScheduleProps,
) => {
  const { investmentId } = props;

  return (
    <TudigoCollection
      columns={useRepaymentsGrossOnlyTableColumns()}
      pagination={{
        pageSize: 100,
        pageIndex: 0,
      }}
      fields={myRepaymentFields}
      useQueryFn={useGetCreditorsRepaymentsPeriodsForInvestment}
      pathParams={{
        investmentId: investmentId,
      }}
      render={(table, props) => {
        return (
          <>
            <Table
              table={table}
              actions={props.actions}
              className="hidden sm:table"
            />
            <TableCardList table={table} className="sm:hidden" />
          </>
        );
      }}
    />
  );
};
