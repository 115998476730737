import { MouseEvent, useContext, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { TudigoError } from "@tudigo-monorepo/core-tudigo-api";
import { Locale, User } from "@tudigo-monorepo/core-tudigo-api-models";
import {
  ContextIntl,
  useTranslation,
} from "@tudigo-monorepo/core-tudigo-translations";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  FormContextProvider,
  getSmartFormComponents,
  SmartSelectField,
} from "@tudigo-monorepo/web-feature-form";
import {
  useGetUserByIdQuery,
  useUpdateUserMutation,
} from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  Button,
  Form,
  Title,
} from "@tudigo-monorepo/web-tudigo-components";

export function MySettingPreferencesContent() {
  const { user: connectedUser } = useUserBusiness();
  const { selectLanguage, locale } = useContext(ContextIntl);

  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { data: user } = useGetUserByIdQuery({
    userId: connectedUser?.id ?? "me",
  });

  const hasSignatureVerificationChannel =
    user?.notificationSettings?.signatureVerificationChannel === "sms";
  const hasNewsletterOptIn = user?.notificationSettings?.newsletterOptIn;
  const hasNotificationChannels =
    user?.notificationSettings?.notificationChannels.includes("email") ?? false;

  const [userFormData, setUserFormData] = useState({
    locale,
    signatureVerificationChannel: hasSignatureVerificationChannel,
    newsletterOptIn: hasNewsletterOptIn,
    notificationChannels: hasNotificationChannels,
  });

  const [updateError, setUpdateError] = useState<TudigoError | null>(null);

  const { mutate: updateUser, isPending: isLoading } = useUpdateUserMutation({
    onSuccess: (data) => {
      selectLanguage(data.data.locale as string);
      queryClient.invalidateQueries();
      toast((content) => (
        <Alert
          icon="Upload"
          close={content.closeToast}
          variant="success"
          size="S"
          title="MAJ"
          description={t(
            "my-settings.my_setting_preferences_content.success_toast",
          )}
        />
      ));
    },
    onError: (err) => {
      setUpdateError(err);

      toast((content) => (
        <Alert
          icon="Error"
          close={content.closeToast}
          variant="error"
          size="S"
          title="MAJ"
          description={t(
            "my-settings.my_setting_preferences_content.error_toast",
          )}
        />
      ));
    },
  });

  const onSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (userFormData) {
      setUpdateError(null);
      updateUser({
        userId: connectedUser?.id ?? "me",
        data: {
          locale: userFormData.locale,
          notificationSettings: {
            signatureVerificationChannel:
              userFormData.signatureVerificationChannel ? "sms" : "email",
            newsletterOptIn: userFormData.newsletterOptIn ?? false,
            notificationChannels: userFormData.notificationChannels
              ? ["email"]
              : [],
          },
        },
      });
    }
  };

  const { SmartToggleField } = getSmartFormComponents<typeof userFormData>();

  if (!user) {
    return <p>Erreur lors du chargement de votre profil</p>;
  }

  return (
    <FormContextProvider
      formData={userFormData}
      setFormData={setUserFormData}
      error={updateError}
    >
      <Form>
        {userFormData && (
          <div className="m-6 space-y-6">
            <Title h3Brand="brand-2" level="h3" className="text-primary">
              {t("my-settings.my_setting_preferences_content.sign_documents")}
            </Title>
            <div className="rounded-lg border px-10 py-8">
              <SmartToggleField
                name="signatureVerificationChannel"
                label={t(
                  "my-settings.my_setting_preferences_content.sign_documents.switch",
                )}
                leftText="Email"
                rightText="SMS"
                blueStyle
                required
              />
            </div>

            <Title h3Brand="brand-2" level="h3" className="text-primary">
              {t("my-settings.my_setting_preferences_content.interface")}
            </Title>
            <div className="rounded-lg border px-10 py-8">
              <div className="w-[251px]">
                <SmartSelectField<Locale, string, User>
                  name="locale"
                  label={t(
                    "my-settings.my_setting_preferences_content.language",
                  )}
                  items={[
                    {
                      id: "en",
                      label: t(
                        "my-settings.my_setting_preferences_content.interface.english",
                      ),
                    },
                    {
                      id: "fr",
                      label: t(
                        "my-settings.my_setting_preferences_content.interface.french",
                      ),
                    },
                  ]}
                  canBeReset={false}
                  labelFunction={(item) => item.label}
                  getValueFromItem={(item) => item.id}
                  comparisonFunction={(item, selection) =>
                    item.id === selection
                  }
                />
              </div>
            </div>
            <Title h3Brand="brand-2" level="h3" className="text-primary">
              {t("my-settings.my_setting_preferences_content.communications")}
            </Title>
            <div className="space-y-8 rounded-lg border px-10 py-8">
              <SmartToggleField
                name="newsletterOptIn"
                label={t(
                  "my-settings.my_setting_preferences_content.communications.news_letter_subscription",
                )}
                leftText={t("no")}
                rightText={t("yes")}
                required
              />
              <SmartToggleField
                name="notificationChannels"
                label={t(
                  "my-settings.my_setting_preferences_content.communications.email_notifications_subscription",
                )}
                leftText={t("no")}
                rightText={t("yes")}
                required
              />
            </div>
            <Button
              isLoading={isLoading}
              className="mt-6"
              variant="primary"
              label={t("my-settings.my_setting_preferences_content.save")}
              onClick={onSubmit}
            />
          </div>
        )}
      </Form>
    </FormContextProvider>
  );
}
