import {
  EquitySavingPlan,
  EquitySavingPlanType,
  Organization,
} from "@tudigo-monorepo/core-tudigo-api-models";

export function checkIfOrganizationEquitySavingPlanAlreadyExists(
  organization?: Organization,
  selectedAccountType?: EquitySavingPlanType,
) {
  return Boolean(
    (selectedAccountType === "pea" &&
      organization?.equitySavingPlan !== null) ||
      (selectedAccountType === "pea_pme" &&
        organization?.smallBusinessEquitySavingPlan !== null),
  );
}

export function checkIfEquitySavingPlanBankIsOffline(
  equitySavingPlan: EquitySavingPlan,
) {
  return (
    equitySavingPlan.bank !== null && equitySavingPlan.bank.online === false
  );
}

export function isValidEquitySavingPlan(equitySavingPlan: EquitySavingPlan) {
  return equitySavingPlan.invalidatedAt === null;
}
