import { CMSIAudioBlock } from "@tudigo-monorepo/core-tudigo-api-models";

import { IFrameBlock } from "./iframe-block";

type AudioBlockProps = {
  block: CMSIAudioBlock;
  editMode?: boolean;
};

export function AudioBlock(props: AudioBlockProps) {
  return <IFrameBlock {...props} />;
}
