import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import {
  getResourceFromTudigoResponse,
  TudigoError,
} from "@tudigo-monorepo/core-tudigo-api";
import { InvestorProfile } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient } from "../../../";
import { getQueryDetailKey } from "../../query-key-factory";

type GetCurrentInvestorProfileById = {
  userId: string;
  query?: { fields?: string };
};

export const getCurrentInvestorProfileByUserId = async (
  params: GetCurrentInvestorProfileById,
) => {
  const apiClient = getApiClient();

  const response =
    await apiClient.apiV1.investorProfiles.getCurrentInvestorProfileByUserId(
      params?.userId,
      params?.query,
    );

  return getResourceFromTudigoResponse(response.data);
};

export const useGetCurrentInvestorProfileByUserId = (
  params: GetCurrentInvestorProfileById,
  options?: UseQueryOptions<InvestorProfile, TudigoError>,
) =>
  useQuery({
    queryKey: getQueryDetailKey({
      key: QUERY_KEYS.INVESTOR_PROFILE,
      id: `${params.userId}:current`,
      fields: params?.query?.fields,
    }),
    queryFn: () => getCurrentInvestorProfileByUserId(params),
    ...options,
  });
