import { CMSAnalyzeBlock } from "@tudigo-monorepo/core-tudigo-api-models";
import { getImgSrc } from "@tudigo-monorepo/core-tudigo-utils";

type AnalyzeBlockProps = {
  block: CMSAnalyzeBlock;
  editMode?: boolean;
};

export function AnalyzeBlock(props: AnalyzeBlockProps) {
  return (
    <article className="border-light-1 flex flex-col gap-4 rounded-lg border p-4">
      <header className="flex items-center gap-2">
        <img
          src={getImgSrc(props.block.data.image)}
          alt=""
          className="h-16 w-16 rounded-full"
        />
        <div>
          <p className="text-dark-1 text-sm">{props.block.data.author}</p>
          <p className="text-dark-1 text-sm font-semibold">
            {props.block.data.subtitle}
          </p>
        </div>
      </header>
      <p
        className="rte-content text-dark-2 text-sm"
        dangerouslySetInnerHTML={{ __html: props.block.data.content }}
      />
    </article>
  );
}
