import React, { useState } from "react";
import { toast } from "react-toastify";

import { TudigoError } from "@tudigo-monorepo/core-tudigo-api";
import {
  ChangeInvestmentStatusRequest,
  Investment,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  FormContextProvider,
  getSmartFormComponents,
} from "@tudigo-monorepo/web-feature-form";
import { Alert, Modal } from "@tudigo-monorepo/web-tudigo-components";

import { useChangeInvestmentStatusRequestMutation } from "../../../../../../tudigo-api-client/src/lib/mutations/investments/change-investment-status-request";
import { managerInvestmentQueryFields } from "../investment-manager-page";

type ChangeInvestmentStatusModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
  investmentId: Investment["id"];
  newPaymentStatus: Investment["paymentStatus"];
};

export function ChangeInvestmentStatusModal(
  props: ChangeInvestmentStatusModalProps,
) {
  const { investmentId, isModalOpen, setIsModalOpen, newPaymentStatus } = props;
  const { t } = useTranslation();
  const [error, setError] = useState<TudigoError | null>(null);
  const [
    changeInvestmentStatusRequestFormData,
    setChangeInvestmentStatusRequestFormData,
  ] = useState<ChangeInvestmentStatusRequest>({
    paymentStatus: newPaymentStatus,
    comment: undefined,
  });

  const { SmartTextAreaField } =
    getSmartFormComponents<ChangeInvestmentStatusRequest>();

  const { mutate: changeInvestmentStatusRequest, isPending: isLoading } =
    useChangeInvestmentStatusRequestMutation({
      onSuccess: () => {
        setIsModalOpen(false);
        toast((content) => (
          <Alert
            close={content.closeToast}
            icon="Checked"
            variant="success"
            title={t(
              "investments.back_office.investment_detail.change_investment_status_modal.toast.success",
            )}
          />
        ));
      },
      onError: (err) => {
        console.log("ChangeInvestmentStatusModal -> err", err);

        setError(err);
        toast((content) => (
          <Alert
            close={content.closeToast}
            icon="Error"
            variant="error"
            title={t(
              "investments.back_office.investment_detail.change_investment_status_modal.toast.error",
            )}
          />
        ));
      },
    });

  const handleSubmit = () => {
    changeInvestmentStatusRequest({
      investmentId: investmentId,
      investmentFields: managerInvestmentQueryFields,
      data: {
        ...changeInvestmentStatusRequestFormData,
      },
    });
  };

  return (
    <Modal
      title={t(
        "investments.back_office.investment_detail.change_investment_status_modal.title",
      )}
      confirmLabel={t(
        "investments.back_office.investment_detail.change_investment_status_modal.confirm_button.label",
      )}
      isOpen={isModalOpen}
      setIsOpen={() => setIsModalOpen(false)}
      confirmAction={handleSubmit}
      confirmButtonProps={{
        isLoading: isLoading,
        variant: "primary",
      }}
      className="min-h-[605px] w-[calc(100%-32px)] sm:max-w-[654px]"
    >
      <div className="flex flex-col gap-y-4 p-4">
        <div>
          <Alert
            icon="Info"
            title={t(
              "investments.back_office.investment_detail.change_investment_status_modal.content.alert.title",
            )}
            size="F"
            description={t(
              "investments.back_office.investment_detail.change_investment_status_modal.content.alert.description",
              {
                status:
                  newPaymentStatus === "payed"
                    ? t(
                        "investments.back_office.investment_detail.change_investment_status_modal.content.alert.description.status.payed",
                      )
                    : t(
                        "investments.back_office.investment_detail.change_investment_status_modal.content.alert.description.status.intent",
                      ),
              },
            )}
            variant="warning"
            className="mb-4"
          />
        </div>
        <div className="border-light-1 rounded-lg border p-4">
          <FormContextProvider
            error={error}
            formData={changeInvestmentStatusRequestFormData}
            setFormData={setChangeInvestmentStatusRequestFormData}
          >
            <div className="flex flex-col gap-4">
              <SmartTextAreaField
                name="comment"
                label={t(
                  "investments.back_office.investment_detail.change_investment_status_modal.content.form.comment.label",
                )}
              />
            </div>
          </FormContextProvider>
        </div>
      </div>
    </Modal>
  );
}
