import { Project } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { ChipProps } from "@tudigo-monorepo/web-tudigo-components";

const TRANSLATION_BASE_KEY = "projects.back_office.project_chip_status";

const commonProps: Partial<ChipProps> = {
  iconLeft: "DotChip",
  size: "M",
};

const waitingProps: typeof commonProps = {
  ...commonProps,
  type: "waiting",
};

const infoProps: typeof commonProps = {
  ...commonProps,
  type: "info",
};

export function useProjectStatusChipProps(project?: Project): ChipProps {
  const { t } = useTranslation();

  switch (project?.status) {
    case "draft":
      return {
        ...waitingProps,
        label: t(`${TRANSLATION_BASE_KEY}.draft`),
      };
    case "scheduled":
      return {
        ...waitingProps,
        label: t(`${TRANSLATION_BASE_KEY}.scheduled`),
      };
    case "started":
    case "prefinished":
      return {
        ...infoProps,
        label: t(`${TRANSLATION_BASE_KEY}.ongoing`),
      };
    case "finished":
      return {
        ...infoProps,
        label: t(`${TRANSLATION_BASE_KEY}.finished`),
      };
    default:
      return {
        ...waitingProps,
        label: t(`${TRANSLATION_BASE_KEY}.draft`),
      };
  }
}
