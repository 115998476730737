import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

import { OrganizationCollapse } from "../../../organization-collapse/organization-collapse";
import { OrganizationCollapseHeader } from "../../../organization-collapse/organization-collapse-header";
import { useInvestmentAccountContext } from "../../investment-account-context";

export function BankAdvisorSection() {
  const { t } = useTranslation();

  const {
    equitySavingPlan: { bankContactInformation },
  } = useInvestmentAccountContext();

  const collapseItems = [
    {
      label: t(
        "organizations.back_office.organization_page.collapse.bank_advisor.first_name",
      ),
      value: bankContactInformation?.firstName,
    },
    {
      label: t(
        "organizations.back_office.organization_page.collapse.bank_advisor.last_name",
      ),
      value: bankContactInformation?.lastName,
    },
    {
      label: t(
        "organizations.back_office.organization_page.collapse.bank_advisor.email",
      ),
      value: bankContactInformation?.email,
    },
    {
      label: t(
        "organizations.back_office.organization_page.collapse.bank_advisor.phone",
      ),
      value: bankContactInformation?.phone,
    },
  ];

  return (
    <OrganizationCollapse items={collapseItems}>
      <OrganizationCollapseHeader
        label={t(
          "organizations.back_office.organization_page.collapse.bank_advisor.title",
        )}
      />
    </OrganizationCollapse>
  );
}
