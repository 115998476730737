import { MultiSelectInput } from "@tudigo-monorepo/core-tudigo-api-models";

import { MultiSelectInputComponent } from "../components/inputs/multi-select-input";
import { safeEvaluate } from "../safe-evaluator";
import { FormInputPlugin } from "./form-input-plugin";

export const FormMultiSelectInputPlugin: FormInputPlugin<
  "multi_select",
  MultiSelectInput
> = {
  type: "multi_select",
  getDefaultValue: () => {
    return null;
  },
  renderingBlock: (input, context) => {
    const { sectionKey, answers, setAnswer } = context;
    if (!sectionKey) return;

    const currentValues = answers[sectionKey][input.name as string] as Array<
      string | number
    > | null;

    const disabledValues = input.parameters?.choices
      ?.filter((choice) =>
        choice.disableCondition &&
        safeEvaluate(choice.disableCondition, answers)
          ? choice.value
          : null,
      )
      .map((choice) => choice.value);

    return (
      <MultiSelectInputComponent
        input={input}
        selectedValues={currentValues}
        onChange={(values) => {
          setAnswer(sectionKey, input, values);
        }}
        disabledValues={disabledValues}
      />
    );
  },
  canAutoSubmit: false,
  defaultValueIfNull: false,
};
