import React from "react";
import { useNavigate } from "react-router-dom";

import { Investment } from "@tudigo-monorepo/core-tudigo-api-models";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { getSignatureRequestSyncById } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  PageHead,
  SignatureIframe,
} from "@tudigo-monorepo/web-tudigo-components";
import { gtmTrackEvent } from "@tudigo-monorepo/web-tudigo-tracking";

import { useInvestmentFunnelPageContext } from "../../../pages/investment-funnel-page";
import { ExpandedInvestmentDetailCard } from "../../shared/expanded-investment-detail-card";

function requirementsAreFulfilled(ongoingInvestment: Investment) {
  return (
    ongoingInvestment.subscriptionFormDocument &&
    ongoingInvestment.subscriptionFormDocument.signatureRequest &&
    ongoingInvestment.subscriptionFormDocument.signatureRequest.mySignatureLink
  );
}

export function InvestmentFunnelSignSubscriptionFormContent() {
  const { ongoingInvestment, project, invalidateOngoingInvestment } =
    useInvestmentFunnelPageContext();
  const navigate = useNavigate();

  const { user } = useUserBusiness();

  if (!ongoingInvestment || !requirementsAreFulfilled(ongoingInvestment))
    return null;

  const onSignatureSuccess = async () => {
    gtmTrackEvent("ui.investment_subscription_form.signed", {
      amount: ongoingInvestment.totalAmountInCents / 100,
      investment_id: ongoingInvestment.id,
      email: user?.email,
    });

    await getSignatureRequestSyncById(
      ongoingInvestment.subscriptionFormDocument!.signatureRequest!.id,
    );
    await invalidateOngoingInvestment();
    navigate(`/projects/${project.id}/invest/${ongoingInvestment.id}`);
  };

  return (
    <>
      <ExpandedInvestmentDetailCard />
      <PageHead
        title="Mon bulletin de souscription"
        subtitle={`Ce document formalise votre intention d’investissement dans ${
          project.content?.name ?? "le projet"
        }. En le signant, vous acceptez les termes de l’opération et vous serez désormais lié à Tudigo et au(x) porteur(s) du projet que vous souhaitez soutenir.`}
        className="py-4"
      />
      <div className="border-light-2 mb-6 border-b" />

      <div className="signature-iframe h-[calc(100vh-130px)]">
        <SignatureIframe
          signatureLink={
            ongoingInvestment.subscriptionFormDocument?.signatureRequest
              ?.mySignatureLink as string
          }
          onSuccess={onSignatureSuccess}
        />
      </div>
    </>
  );
}
