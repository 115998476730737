import { ClubV2 } from "../club-v2/models";
import { Document } from "../documents";
import { Image } from "../images";
import { Investment } from "../investments";
import { SignatureRequest } from "../signatures";
import { User } from "../users";

export enum DateInterval {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
}

export type DateIntervalType = `${DateInterval}`;
export type SubscriptionPrice = {
  unitAmountInCents: number;
  renewalInterval: DateIntervalType;
  renewalIntervalCount: number;
  currency: string;
  active: boolean;
};

export type LatestPaymentIntent = {
  amount: number;
  createdAt: string;
  id: string;
  paymentUrl: string | null;
  status: string;
  token: string;
};

export type Subscription = {
  currentPeriodEndDate: Date;
  currentPeriodStartDate: Date;
  id: string;
  price: SubscriptionPrice;
  latestPaymentIntent: LatestPaymentIntent;
};

export enum ClubMemberStatusEnum {
  DRAFT = "draft",
  ACTIVE = "active",
  DELETED = "deleted",
  FOLLOWING = "following",
  CANCELLED = "cancelled",
  BANNED = "banned",
}

export type ClubMemberStatusType = `${ClubMemberStatusEnum}`;

export type ClubMember = {
  id: string;
  role: string;
  contract: {
    document: Document;
    signatureRequest: SignatureRequest;
  };
  user: User;
  status: ClubMemberStatusType;
  joinedAt: Date;
  createdAt: Date;
  updatedAt: Date;
  subscription: Subscription;
  investments?: Investment[];
  subscriptionPeriods?: SubscriptionPeriod[];
  club: Club | ClubV2;
  statistics?: {
    totalInvestmentsAmountInCents: number | null;
    totalInvestmentsCount: number | null;
  };
};

export type AdvertisedProjectTags = {
  name: string;
  image: string | null;
  status: string;
  code: string;
};

export type ClubDescription = {
  data: { phrase: string; description: string };
  id: string;
  content: string;
};

export enum ClubStatus {
  DRAFT = "draft",
  PUBLISHED = "published",
  CLOSED = "closed",
}

export type ClubConfiguration = {
  introductorySubscriptionPrice: {
    currency: string;
    unitAmountInCents: number;
  };
  maxIntroductorySubscriptions: number;
  maxSubscriptions: number;
  subscriptionPrice: {
    currency: string;
    unitAmountInCents: number;
  };
  subscriptionsClosingDate: string;
  subscriptionsOpeningDate: string;
};

export type Club = {
  id: string;
  name: string;
  shortDescription: string;
  image: Image;
  clubLeader: string;
  status: `${ClubStatus}`;
  description: ClubDescription[];
  createdAt: string;
  updatedAt: string;
  configuration: ClubConfiguration;
  socialLinks: Record<string, string> | null;
  advertisedProjectTags: AdvertisedProjectTags[];
  isIntroductoryPriceAvailable: boolean;
  isOpenedToSubscriptions: boolean;
};

export type SubscriptionPeriod = {
  id: string;
  subscription: Subscription;
  currentPeriodEndDate: Date;
  currentPeriodStartDate: Date;
  cancellationDate: Date;
  price: SubscriptionPrice;
  club: Club;
  clubMember: ClubMember;
};
