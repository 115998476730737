import { Route, Routes } from "react-router-dom";

import { MyClubsPage } from "../pages/clubs-page/clubs-page";

export function MyClubsRoutes() {
  return (
    <Routes>
      <Route path="/" element={<MyClubsPage />} />
    </Routes>
  );
}
