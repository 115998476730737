import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import {
  CreateWmaCustomerInvestmentRequestBody,
  TudigoError,
  TudigoResponse,
} from "@tudigo-monorepo/core-tudigo-api";
import { WMACustomer } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient } from "../../..";

export const createWMACustomerInvestment = async (
  params: CreateWmaCustomerInvestmentRequestBody,
) => {
  const apiClient = getApiClient();

  const response =
    await apiClient.apiV1.wmaInvestments.createWMACustomerInvestment(params);

  return response.data;
};

export const useCreateWMACustomerInvestmentMutation = (
  options?: UseMutationOptions<
    TudigoResponse<WMACustomer>,
    TudigoError,
    CreateWmaCustomerInvestmentRequestBody
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (params: CreateWmaCustomerInvestmentRequestBody) =>
      createWMACustomerInvestment(params),
    onSuccess: (...props) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.WMA_INVESTMENTS] });
      options?.onSuccess?.(...props);
    },
  });
};
