import { Navigate, Route, Routes } from "react-router-dom";

import { useProjectPageRoutes } from "../../../pages/project-list-manager-page/hooks/use-project-page-routes";

export function ProjectPageRoutesWebApp() {
  const projectRoutes = useProjectPageRoutes();

  return (
    <Routes>
      {projectRoutes.map((route) => (
        <Route
          key={route.label}
          path={route.path}
          element={<route.component />}
        />
      ))}
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
}
