import React, { ReactNode } from "react";
import { toast } from "react-toastify";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Alert } from "../old/alert";
import { Icon } from "../old/icons/icon";
import { Popover, PopoverContent, PopoverTrigger } from "./popover/popover";

function SocialButton({
  children,
  onClick,
}: {
  children: ReactNode;
  onClick?: () => void;
}) {
  return (
    <button
      onClick={onClick}
      className="hover:bg-accent-super-light text-dark-1 flex w-full items-center gap-3 px-4 py-3 text-sm"
    >
      {children}
    </button>
  );
}

export function ShareButton(props: { className?: string }) {
  const currentUrl = window.location.href;

  const shareOnFacebook = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`,
      "_blank",
    );
  };

  const shareOnTwitter = () => {
    window.open(`https://x.com/intent/tweet?url=${currentUrl}`, "_blank");
  };

  const shareOnLinkedin = () => {
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${currentUrl}`,
      "_blank",
    );
  };

  const copyLink = () => {
    navigator.clipboard.writeText(currentUrl);
    toast(<Alert icon="Checked" title="Lien copié" variant="success" />);
  };

  return (
    <div>
      <Popover>
        <PopoverTrigger
          className={cn("block rounded-full p-4", props.className)}
        >
          <Icon name="Share" primaryColor={themeColors["dark-1"]} />
        </PopoverTrigger>
        <PopoverContent className="max-w-[192px] p-0">
          <SocialButton onClick={shareOnFacebook}>
            <Icon name="SocialFacebook" primaryColor={themeColors["dark-1"]} />
            Facebook
          </SocialButton>
          <SocialButton onClick={shareOnTwitter}>
            <Icon name="SocialTwitter" primaryColor={themeColors["dark-1"]} />X
          </SocialButton>
          <SocialButton onClick={shareOnLinkedin}>
            <Icon name="SocialLinkedin" primaryColor={themeColors["dark-1"]} />
            Linkedin
          </SocialButton>

          <SocialButton onClick={copyLink}>
            <Icon name="Link" primaryColor={themeColors["dark-2"]} />
            Copier le lien
          </SocialButton>
        </PopoverContent>
      </Popover>
    </div>
  );
}
