import { QueryClient, QueryKey } from "@tanstack/react-query";
import { redirect } from "react-router-dom";

type Modules = "AUTHORIZATION";

type ModulesType = {
  [key in Modules]: () => boolean;
};

// AUTH is an exemple the ProtectedRoute component takes care of that
// Add a modules when you need to check something before rendering the route
export const MODULES: ModulesType = {
  AUTHORIZATION: () => {
    const { state } = JSON.parse(localStorage.getItem("auth-store") as string);

    return Boolean(state.accessToken);
  },
};

type Queries = {
  queryKey: QueryKey;
  queryFn: () => Promise<any>;
};

type RouterLoader = {
  modules?: (keyof ModulesType)[];
  queryClient?: QueryClient;
  queries?: Queries[];
};

export const routeLoader = async ({
  modules = [],
  queryClient,
  queries = [],
}: RouterLoader) => {
  try {
    const checkedModules = modules?.map((module) => ({
      [module]: MODULES[module]() ? "DONE" : "FAILED",
    }));

    const failedModules = checkedModules?.filter(
      (module) => Object.values(module)[0] === "FAILED",
    );

    if (failedModules.length) {
      return redirect("/retry");
    }

    const promises = await Promise.all(
      queries?.map(({ queryKey, queryFn }) => {
        if (queryClient?.getQueryData(queryKey)) {
          return Promise.resolve(queryClient.getQueryData(queryKey));
        }

        return queryClient?.fetchQuery({
          queryKey,
          queryFn,
        });
      }),
    );

    return { promises };
  } catch (error) {
    return redirect("/retry");
  }
};
