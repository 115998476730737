import React from "react";
import { toast } from "react-toastify";

import { PaymentMethodType } from "@tudigo-monorepo/core-tudigo-api-models";
import { ORGANIZATIONS } from "@tudigo-monorepo/core-tudigo-utils";
import {
  useSendInvestmentDocumentSignatureLink,
  useSendWmaInvestmentFolderEmailMutation,
} from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  Button,
  ListElement,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { ProjectMetricsCard } from "../../../../components/project-metric-card";
import { useEditWmaInvestmentPageContext } from "../../../../edit-wma-investment-page";

export function EditWmaInvestmentPaymentTabPeaWaitingPaymentSection() {
  const { investment, wmaInvestment } = useEditWmaInvestmentPageContext();
  const project = investment.project;

  const signedCommitmentLetterDocument =
    investment.signedCommitmentLetterDocument;

  const commitmentLetterSignatureLink =
    investment.commitmentLetterDocument?.signatureRequest?.signers?.find(
      (signer) => signer.userId === investment.user?.id,
    )?.signatureLink;

  const archiveEspDocument = investment.archiveEspDocument;

  const [isLinkCopyLoading, setIsLinkCopyLoading] = React.useState(false);
  const [isSendMailActive, setIsSendMailActive] = React.useState(true);

  const { mutate: sendWmaInvestmentFolderEmail } =
    useSendWmaInvestmentFolderEmailMutation({
      onSuccess: () => {
        toast((content) => {
          return (
            <Alert
              icon="Checked"
              variant="success"
              size="S"
              close={content.closeToast}
              title="Email envoyé"
            />
          );
        });
      },
      onError: () => {
        toast((content) => {
          return (
            <Alert
              icon="Error"
              variant="error"
              size="S"
              close={content.closeToast}
              title="Une erreur est survenue"
            />
          );
        });
      },
    });

  const { mutateAsync: sendCommitmentLetterSignatureLink, isPending } =
    useSendInvestmentDocumentSignatureLink({
      onSuccess: () => {
        toast((content) => {
          return (
            <Alert
              icon="Checked"
              variant="success"
              size="S"
              close={content.closeToast}
              title="Email envoyé"
            />
          );
        });
        setIsSendMailActive(false);
      },
      onError: () => {
        toast((content) => {
          return (
            <Alert
              icon="Error"
              variant="error"
              size="S"
              close={content.closeToast}
              title="Une erreur est survenue"
            />
          );
        });
        setIsSendMailActive(true);
      },
    });

  const handleSendCommitmentLetterSignatureMail = async () => {
    await sendCommitmentLetterSignatureLink({
      investmentDocumentId: investment.commitmentLetterDocument?.id as string,
      userId: investment.user?.id as string,
    });
  };

  const copyLinkCommitmentLetterSignature = () => {
    setIsLinkCopyLoading(true);
    navigator.clipboard
      .writeText(`${commitmentLetterSignatureLink}`)
      .then(() => {
        toast((content) => {
          return (
            <Alert
              icon="Checked"
              variant="success"
              size="S"
              close={content.closeToast}
              title="Le lien de signature a été copié dans le presse-papier"
            />
          );
        });
        setTimeout(() => {
          setIsLinkCopyLoading(false);
        }, 500);
      });
  };

  return (
    <div className="m-6 flex flex-col gap-6 ">
      <div className="flex flex-col gap-2">
        <Title level="h4">Lettre d&apos;engagement</Title>
        {signedCommitmentLetterDocument ? (
          <ListElement
            key={investment.id}
            toggleActiveOnClick={false}
            startElement={{
              upperText: "Lettre d'engagement",
              lowerText: investment.investorOrganization.name,
              useProfileCard: true,
              profileCardClassName:
                "min-w-[280px] flex justify-start px-3 py-2",
              img: {
                icon: {
                  name: "Document",
                },
              },
            }}
            middleElement={{
              upperText: "Lettre d'engagement signée",
              chips: [
                {
                  type: "secondary",
                  label:
                    investment.investorOrganization.type ===
                    ORGANIZATIONS.TYPE.COMPANY
                      ? "Personne morale"
                      : "Personne physique",
                },
              ],
            }}
            endElement={{
              icon: "Download",
              info: {
                text: "PDF",
              },
            }}
            onClick={() => {
              window.open(
                `${signedCommitmentLetterDocument?.document?.signedUrl}`,
                "_blank",
              );
            }}
          />
        ) : (
          <div className="flex gap-4">
            <Button
              label="Envoyer le lien par email"
              onClick={() => handleSendCommitmentLetterSignatureMail()}
              disabled={!isSendMailActive}
              isLoading={isPending}
              iconRight="Mail"
            />
            <Button
              label="Copier le lien"
              isLoading={isLinkCopyLoading}
              onClick={() => copyLinkCommitmentLetterSignature()}
              iconRight="Duplicate"
            />
          </div>
        )}
      </div>
      <div className="flex flex-col gap-2">
        <Title level="h4">
          Procéder au paiement :{" "}
          {investment.paymentMethodType === PaymentMethodType.PEA
            ? "PEA"
            : "PEA-PME"}
        </Title>
        {signedCommitmentLetterDocument ? (
          <Typography variant="body2">
            Retrouvez ci-dessous le dossier à transmettre à la banque
          </Typography>
        ) : (
          <Typography variant="body2">
            Les documents à transmettre seront disponible dès signature de la
            lettre d&apos;engagement par votre client.
          </Typography>
        )}
        <ProjectMetricsCard project={project} />
        <div className="mt-2 flex gap-4">
          <Button
            label="Envoyer le dossier par email"
            iconRight="Mail"
            isLoading={isPending}
            onClick={() => {
              if (!wmaInvestment?.id) return;

              sendWmaInvestmentFolderEmail({
                wmaInvestmentId: wmaInvestment?.id,
              });
            }}
            disabled={!signedCommitmentLetterDocument}
          />
          <Button
            label="Télécharger le dossier"
            iconRight="Download"
            onClick={() => {
              window.open(
                `${archiveEspDocument?.document?.signedUrl}`,
                "_blank",
              );
            }}
            disabled={!signedCommitmentLetterDocument}
          />
        </div>
      </div>
    </div>
  );
}
