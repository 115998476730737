import React from "react";
import { useIntl } from "react-intl";

import { MetricsTableCard } from "@tudigo-monorepo/web-tudigo-components";

import { useMyInvestmentAssemblyPageContext } from "../../investment-assembly-page/my-investment-assembly-page";

type AssemblyMetricTabCardProps = {
  participationFormIsOpen: boolean;
  setParticipationFormIsOpen: (value: boolean) => void;
};

export function AssemblyParticipationDetailCard(
  props: AssemblyMetricTabCardProps,
) {
  const intl = useIntl();
  const { setParticipationFormIsOpen, participationFormIsOpen } = props;

  const {
    assembly,
    project,
    assemblyParticipant,
    isAssemblyOpenForParticipationUpdate,
  } = useMyInvestmentAssemblyPageContext();

  const assemblyScheduledStartDate: Date | null = new Date(
    assembly.configuration?.scheduledStartDate ?? "",
  );

  const assemblyScheduledEndDate: Date | null = new Date(
    assembly.configuration?.scheduledEndDate ?? "",
  );

  const assemblyDetailsRows = [
    {
      label: intl.formatMessage({
        id: "assembly_detail_page.metric_tab_card.label.start_date",
      }),
      value: `${
        assemblyScheduledStartDate.toLocaleDateString(intl.locale) ?? ""
      } ${assemblyScheduledStartDate.toLocaleTimeString(intl.locale) ?? ""}`,
    },
    {
      label: intl.formatMessage({
        id: "assembly_detail_page.metric_tab_card.label.end_date",
      }),
      value: `${
        assemblyScheduledEndDate.toLocaleDateString(intl.locale) ?? ""
      } ${assemblyScheduledEndDate.toLocaleTimeString(intl.locale) ?? ""}`,
    },

    {
      label: intl.formatMessage({
        id: "assembly_detail_page.metric_tab_card.label.channel",
      }),
      value:
        intl.formatMessage({
          id: `assembly.configuration.channel.${assembly.configuration?.channel}`,
        }) ?? "",
    },
    {
      label: intl.formatMessage({
        id: "assembly_detail_page.metric_tab_card.label.proxy_allowed",
      }),
      value: assembly.configuration?.proxyAllowed
        ? intl.formatMessage({ id: "yes" })
        : intl.formatMessage({ id: "no" }),
    },
    {
      label: intl.formatMessage({
        id: "assembly_detail_page.metric_tab_card.label.type",
      }),
      value:
        intl.formatMessage({
          id: `assembly.configuration.type.${assembly.configuration?.type}`,
        }) ?? "",
    },
    assemblyParticipant.presenceConfirmation === null
      ? {
          label: intl.formatMessage({
            id: "assembly_detail_page.metric_tab_card.label.presence_confirmation",
          }),
          value: intl.formatMessage({
            id: "assembly_detail_page.metric_tab_card.label.presence_confirmation.status.waiting_confirmation",
          }),
        }
      : {
          label: intl.formatMessage({
            id: "assembly_detail_page.metric_tab_card.label.presence_confirmation",
          }),
          value: assemblyParticipant.presenceConfirmation
            ? `${intl.formatMessage({ id: "yes" })} - ${intl.formatMessage({
                id: `assembly_detail_page.metric_tab_card.value.presence_confirmation.${assemblyParticipant.participationChannel}`,
              })}`
            : intl.formatMessage({ id: "no" }),
        },
  ];

  if (
    assemblyParticipant.participationChannel === "voting_by_proxy" &&
    assemblyParticipant.proxyStatus &&
    assemblyParticipant.proxyType
  ) {
    assemblyDetailsRows.push({
      label: intl.formatMessage({
        id: "assembly_detail_page.metric_tab_card.label.proxy_type",
      }),
      value:
        assemblyParticipant.proxyType === "organization_representative"
          ? "Représentant de la société"
          : "Tudigo",
    });
    assemblyDetailsRows.push({
      label: intl.formatMessage({
        id: "assembly_detail_page.metric_tab_card.label.proxy_status",
      }),
      value:
        assemblyParticipant.proxyStatus === "signed"
          ? "Signé"
          : "En attente de signature",
    });
  }

  return (
    <MetricsTableCard
      title={`${assembly.name} (${project?.content?.name})`}
      rows={assemblyDetailsRows}
      footer={
        isAssemblyOpenForParticipationUpdate(assembly, assemblyParticipant) &&
        !participationFormIsOpen &&
        assemblyParticipant.presenceConfirmation !== null
          ? {
              btn: {
                label: "Modifier votre participation",
                variant: "tertiary",
                onClick: () => setParticipationFormIsOpen(true),
              },
            }
          : {}
      }
    />
  );
}
