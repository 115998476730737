import React from "react";
import { useIntl } from "react-intl";

import {
  InvestmentFeed,
  Project,
} from "@tudigo-monorepo/core-tudigo-api-models";
import {
  useGetUserInvestmentFeedQuery,
  UserInvestmentFeedFilters,
} from "@tudigo-monorepo/web-tudigo-api-client";
import { TudigoCollection } from "@tudigo-monorepo/web-tudigo-collection";

import { InvestmentFeedListElement } from "./investment-feed-list-element";

type InvestmentFeedListProps = {
  project: Project;
  userId: string;
  filter: "all" | "all-documents-only" | "news" | "investment_documents";
  requestFilters?: UserInvestmentFeedFilters;
  pagination?: {
    pageIndex: number;
    pageSize: number;
  };
};
export function InvestmentFeedList(props: InvestmentFeedListProps) {
  const { project, userId, filter, requestFilters, pagination } = props;
  const intl = useIntl();

  return (
    <TudigoCollection
      useLocationAsState={false}
      useQueryFn={useGetUserInvestmentFeedQuery}
      pathParams={{
        userId: userId,
        projectId: project.id,
        filter: filter,
        type: "document",
      }}
      fields="ref_type,ref_id,project_document.fields(id,document,type,created_at),investment_document.fields(id,document,type,created_at,investment.fields(investor_organization.fields(name))),news,creation_date"
      filters={requestFilters}
      pagination={pagination}
      columns={[]}
      actions={[]}
      render={(investmentFeed) => {
        const elementCount = investmentFeed.getRowModel().rows?.length ?? 0;

        if (elementCount === 0) {
          return (
            <p>
              {intl.formatMessage({
                id: "collection.no_result",
              })}
            </p>
          );
        }

        return (
          <div className="flex flex-col">
            {investmentFeed
              .getRowModel()
              .rows?.map((item: any, index: number) => {
                const feedItem = item.original as InvestmentFeed;

                return (
                  <div className="mb-6 flex-row" key={index}>
                    <InvestmentFeedListElement investmentFeedItem={feedItem} />
                  </div>
                );
              })}
          </div>
        );
      }}
    />
  );
}
