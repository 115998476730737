import React, { ReactNode } from "react";

import { MembershipStatus } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  ClubProfilePicture,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@tudigo-monorepo/web-tudigo-components";

import { useGetClubV2UsingIdPathParams } from "../../../../shared-internal/hooks/use-get-club-v2-using-id-path-params";
import { ClubMembershipModalFollowContent } from "./club-membership-modal-follow-content";
import { ClubMembershipModalPremiumContent } from "./club-membership-modal-premium-content";

export function ClubMembershipModalFollow({
  children,
  myMembership,
}: {
  children: ReactNode;
  myMembership?: MembershipStatus;
}) {
  const { t } = useTranslation();
  const club = useGetClubV2UsingIdPathParams();

  if (!club) {
    return;
  }

  return (
    <Dialog>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="gap-2">
            <ClubProfilePicture club={club} size="XS" />
            {t("page_club.membership_modal.title")}{" "}
            {club?.content?.name ?? club.name}
          </DialogTitle>
        </DialogHeader>

        {![MembershipStatus.ACTIVE, MembershipStatus.FOLLOWING].includes(
          myMembership!,
        ) && <ClubMembershipModalFollowContent />}

        {club.configuration.isPremiumAvailable && (
          <ClubMembershipModalPremiumContent />
        )}
      </DialogContent>
    </Dialog>
  );
}
