import { Route, Routes } from "react-router-dom";

import { ClubLeaderMemberDetailPage } from "../club-leader-member-detail-page";

export function ClubLeaderMemberDetailRouteWebapp() {
  return (
    <Routes>
      <Route path="*" element={<ClubLeaderMemberDetailPage />} />
    </Routes>
  );
}
