import { ClubV2, CMSBlock } from "@tudigo-monorepo/core-tudigo-api-models";
import {
  CardEventBlock,
  CardPressCoverageBlock,
  CardsBlock,
  FrequentlyAskedQuestionBlock,
  HeadingBlock,
  MosaicGalleryBlock,
  ParagraphBlock,
  PodcastCarouselBlock,
  QuotesBlock,
  VideoBlock,
} from "@tudigo-monorepo/web-tudigo-editor";

import { CircleLeadersListBlock } from "../components/cms/circle-cms-blocks/circle-leader-list-block";
import { CircleMediasCarouselBlock } from "../components/cms/circle-cms-blocks/circle-medias-carousel-block";
import { CircleModalitiesCardBlock } from "../components/cms/circle-cms-blocks/circle-modalities-card-block";
import { CircleOrganizationCardBlock } from "../components/cms/circle-cms-blocks/circle-organization-card-block";
import { CirclePresentationProjectsBlock } from "../components/cms/circle-cms-blocks/circle-presentation-projects-block";
import { CircleTeamMembersBlock } from "../components/cms/circle-cms-blocks/circle-team-members-block";
import { CircleTrackRecordsBlock } from "../components/cms/circle-cms-blocks/circle-track-records-block";

export type ClubEditorPluginContext = {
  club: ClubV2;
};

export const getClubComponentByCMSBlock = (block: CMSBlock, club: ClubV2) => {
  switch (block.type) {
    case "heading":
      return () => <HeadingBlock block={block} />;
    case "paragraph":
      return () => <ParagraphBlock block={block} />;
    case "cards-quote":
      return () => <QuotesBlock block={block} />;
    case "cards-text-with-image-and-chip":
      return () => <CardsBlock block={block} />;
    case "club-team-leaders":
      return () => <CircleTeamMembersBlock club={club} />;
    case "club-projects":
      return () => <CirclePresentationProjectsBlock club={club} />;
    case "club-medias-carousel":
      return () => <CircleMediasCarouselBlock club={club} />;
    case "club-track-record":
      return () => <CircleTrackRecordsBlock club={club} />;
    case "club-leader-list":
      return () => <CircleLeadersListBlock club={club} />;
    case "club-organization-card":
      return () => <CircleOrganizationCardBlock club={club} />;
    case "club-modalities-card":
      return () => <CircleModalitiesCardBlock club={club} />;
    case "card-event":
      return () => <CardEventBlock block={block} />;
    case "card-press-coverage":
      return () => <CardPressCoverageBlock block={block} />;
    case "podcast-carousel":
      return () => <PodcastCarouselBlock block={block} />;
    case "mosaic-gallery":
      return () => <MosaicGalleryBlock block={block} />;
    case "video":
      return () => <VideoBlock block={block} />;
    case "faq-block":
      return () => <FrequentlyAskedQuestionBlock block={block} />;
    default:
      console.error(
        `We could not find the component for the block type ${block.type}`,
        block,
      );

      return () => null;
  }
};
