import { useClearRefinements } from "react-instantsearch";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Icon, Typography } from "@tudigo-monorepo/web-tudigo-components";

export const ResetFilters = (props: {
  visible: boolean;
  className?: string;
}) => {
  const { visible, className } = props;

  const { t } = useTranslation();
  const { refine: clearFilters } = useClearRefinements();

  if (!visible) return null;

  return (
    <button
      onClick={clearFilters}
      className={cn("flex items-center justify-center gap-x-2", className)}
    >
      <Icon name="ReloadIcon" primaryColor={themeColors.accent} />
      <Typography variant="body2" className="text-accent">
        {t(`projects.web_app.refinements.reset.cta.label`)}
      </Typography>
    </button>
  );
};
