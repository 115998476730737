import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { SignatureRequestStatusEnum } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";
import {
  getQueryListKey,
  getSignatureRequestSyncById,
} from "@tudigo-monorepo/web-tudigo-api-client";
import { Alert, SignatureIframe } from "@tudigo-monorepo/web-tudigo-components";

import { useGetClubV2UsingIdPathParams } from "../../../shared-internal/hooks/use-get-club-v2-using-id-path-params";
import { useGetCurrentClubMember } from "../../hooks/use-get-current-club-member";
import { useNavigateToSubscribeClubStep } from "../../hooks/use-navigate-to-subscribe-club-step";
import { SubscribeClubStepPage } from "../../models/subscribe-club-step";

export function SubscribeClubStep2SignPage() {
  const club = useGetClubV2UsingIdPathParams();
  const { clubMember } = useGetCurrentClubMember(club?.id);
  const queryClient = useQueryClient();

  const { t } = useTranslation();
  const navigateToStep = useNavigateToSubscribeClubStep();

  const signatureLink =
    clubMember?.contract?.signatureRequest?.mySignatureLink ?? "";
  const signatureRequestId = clubMember?.contract?.signatureRequest?.id ?? "";

  const handleSyncSignatureSuccess = async (loop = 0) => {
    try {
      await getSignatureRequestSyncById(signatureRequestId);

      await queryClient.invalidateQueries({
        queryKey: getQueryListKey({
          key: QUERY_KEYS.MY_CLUB_MEMBERS,
        }),
      });

      const isSync = Boolean(
        clubMember?.contract?.signatureRequest?.status ===
          SignatureRequestStatusEnum.DONE.toString() &&
          clubMember?.subscription?.latestPaymentIntent?.token,
      );

      if (loop > 3) {
        return;
      }

      if (isSync) {
        toast((content) => (
          <Alert
            icon="Checked"
            close={content.closeToast}
            variant="success"
            size="S"
            title={t("subscribe_club_page.step_2_sign.alert.success")}
          />
        ));

        return navigateToStep(SubscribeClubStepPage.STEP_3_PAYMENT, {
          wait: 3000,
        });
      }

      handleSyncSignatureSuccess(loop + 1);
    } catch (error) {
      console.error("requestSubscriptionSync -> error", error);

      toast((content) => (
        <Alert
          icon="Error"
          close={content.closeToast}
          variant="error"
          size="S"
          title={t("subscribe_club_page.step_2_sign.alert.error")}
        />
      ));
    }
  };

  if (!club) {
    return null;
  }

  return (
    <section className="container mx-auto flex flex-col gap-y-6 p-4 lg:py-10">
      <header className="mb-12 flex flex-col gap-y-6">
        <div className="border-light-2 flex flex-col gap-y-1.5 border-b pb-4">
          <h1 className="text-primary font-brand text-[27px] font-medium">
            {t("subscribe_club_page.step_2_sign.header.title")}
          </h1>
          <p className="text-base text-gray-500">
            {t("subscribe_club_page.step_2_sign.header.description")}
          </p>
        </div>
        <Alert
          icon="Info"
          variant="info"
          size="F"
          description={t("subscribe_club_page.step_2_sign.alert.message")}
        />
      </header>

      {signatureLink && (
        <div className="signature-iframe h-screen">
          <SignatureIframe
            signatureLink={signatureLink}
            onSuccess={() => handleSyncSignatureSuccess()}
          />
        </div>
      )}
    </section>
  );
}
