import React from "react";

import { SharesProject } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  Chip,
  Icon,
  PageHead,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

export function SharesHeadProjectDetailContent(props: {
  project: SharesProject;
}) {
  const { project } = props;
  const { t } = useTranslation();

  return (
    <>
      <PageHead
        title={project?.content?.name ?? "Investissement"}
        className="pb-4"
      />
      <div className="border-light-2 border-b" />

      <div className="my-6 flex w-full items-stretch gap-4">
        <div className="relative w-1/2 overflow-hidden rounded-lg">
          <img
            src={project.content?.cover?.url ?? ""}
            alt="Cover"
            className="absolute inset-0 h-full w-full object-cover object-center"
          />
        </div>
        <div className="border-light-1 flex w-1/2 flex-col gap-2 rounded-lg border p-4">
          <div className="flex items-center gap-2">
            <Typography variant="body2">Instrument financier</Typography>
            <Icon name="Info" size="XS" />
          </div>
          <Title level="h5">Actions</Title>
          <div className="flex items-center gap-2">
            <Typography variant="body2">Rendement cible</Typography>
            <Icon name="Info" size="XS" />
          </div>
          <Title level="h5">
            {project?.sharesMechanicSettings?.targetedYield
              ? `x${
                  project.sharesMechanicSettings.targetedYield.minInPercent /
                  100
                } - x${
                  project.sharesMechanicSettings.targetedYield.maxInPercent /
                  100
                }`
              : "Non défini"}
          </Title>
          {project.taxExemptionMechanics &&
            project.taxExemptionMechanics?.length > 0 && (
              <>
                <div className="flex items-center gap-2">
                  <Typography variant="body2">Défiscalisation</Typography>
                  <Icon name="Info" size="XS" />
                </div>
                <div className="flex gap-2">
                  {project?.taxExemptionMechanics?.map((tax, index) => {
                    return (
                      <Chip
                        type="secondary"
                        key={index}
                        label={t(
                          `component.project_card.list.item.tax_exemption.${tax}`,
                        )}
                      />
                    );
                  })}
                </div>
              </>
            )}
        </div>
      </div>
    </>
  );
}
