import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import {
  CreateInvestmentIntentRequestBody,
  TudigoError,
  TudigoResponse,
} from "@tudigo-monorepo/core-tudigo-api";
import { Investment } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient, getQueryDetailKey } from "../../..";

type CreateInvestmentRequestParams = {
  data: CreateInvestmentIntentRequestBody;
  query?: { fields?: string };
};

export const createInvestment = async (
  params: CreateInvestmentRequestParams,
) => {
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.investments.createInvestmentIntent(
    params.data,
  );

  return response.data;
};

export const useCreateInvestmentMutation = (
  options?: UseMutationOptions<
    TudigoResponse<Investment>,
    TudigoError,
    CreateInvestmentRequestParams
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (params: CreateInvestmentRequestParams) =>
      createInvestment(params),
    onSuccess: (...props) => {
      const [response, params] = props;
      const investmentQueryKey = getQueryDetailKey({
        key: QUERY_KEYS.INVESTMENTS,
        id: response.data.id!,
        fields: params?.query?.fields,
      });

      queryClient.setQueryData(investmentQueryKey, response.data);

      options?.onSuccess?.(...props);
    },
  });
};
