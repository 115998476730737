import { ClubV2 } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Chip } from "@tudigo-monorepo/web-tudigo-components";

type ClubBasicIdentityCardProps = {
  club: ClubV2;
};

export const ClubBasicIdentityCard = ({ club }: ClubBasicIdentityCardProps) => {
  const { t } = useTranslation();

  return (
    <div className="card border-light-1 relative flex w-full flex-col gap-4 rounded-lg border bg-white p-6">
      <div>
        <p className="font-montserrat text-dark-1 mb-2 text-sm font-medium leading-none">
          {t("page_club.club_basic_identity_card.industries.label")}
        </p>
        <div className="flex flex-wrap gap-2">
          {club.industries?.map((industry) => (
            <Chip key={industry.code} label={industry.name} type="secondary" />
          ))}
        </div>
      </div>
      <div>
        <p className="font-montserrat text-dark-1 mb-2 text-sm font-medium leading-none">
          {t("page_club.club_basic_identity_card.maturities.label")}
        </p>
        <div className="flex flex-wrap gap-2">
          {club.maturities?.map((maturity) => (
            <Chip key={maturity.code} label={maturity.name} type="secondary" />
          ))}
        </div>
      </div>
      <div>
        <p className="font-montserrat text-dark-1 mb-2 text-sm font-medium leading-none">
          {t("page_club.club_basic_identity_card.territories.label")}
        </p>
        <div className="flex flex-wrap gap-2">
          {club.territories?.map((territory) => (
            <Chip
              key={territory.code}
              label={territory.name}
              type="secondary"
            />
          ))}
        </div>
      </div>
      <div>
        <p className="font-montserrat text-dark-1 mb-2 text-sm font-medium leading-none">
          {t("page_club.club_basic_identity_card.mentorings.label")}
        </p>
        <div className="flex flex-wrap gap-2">
          {club.mentorings?.map((mentoring) => (
            <Chip
              key={mentoring.code}
              label={mentoring.name}
              type="secondary"
            />
          ))}
        </div>
      </div>
    </div>
  );
};
