import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useGetProjectsQuery } from "@tudigo-monorepo/web-tudigo-api-client";
import { TudigoCollection } from "@tudigo-monorepo/web-tudigo-collection";
import { ProjectCardRebrand } from "@tudigo-monorepo/web-tudigo-components";

import { useGetClubV2UsingIdPathParams } from "../../../shared-internal/hooks/use-get-club-v2-using-id-path-params";

//TODO: filter on club id when API is ready
export const ClubProjectsContent = () => {
  const club = useGetClubV2UsingIdPathParams();

  const { t } = useTranslation();

  if (!club) {
    return;
  }

  return (
    <div className="flex flex-col gap-6">
      <div>
        <h2 className="font-brand text-primary text-lg font-semibold">
          {t("projects.stage.label.started")}
        </h2>
        <div className="my-6">
          <TudigoCollection
            useQueryFn={useGetProjectsQuery}
            columns={[]}
            actions={[]}
            pagination={{
              pageSize: 2,
              pageIndex: 0,
            }}
            pathParams={undefined}
            filters={{
              status: "started",
            }}
            render={(table) => (
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 ">
                {table.getRowModel().rows?.map(({ original: project }) => {
                  return (
                    <ProjectCardRebrand
                      key={project.id}
                      project={project}
                      navigateTo={`/projects/${project.content?.slug}`}
                    />
                  );
                })}
              </div>
            )}
          />
        </div>
      </div>

      <div>
        <h2 className="font-brand text-primary text-lg font-semibold">
          {t("projects.stage.label.finished")}
        </h2>
        <div className="my-6">
          <TudigoCollection
            useQueryFn={useGetProjectsQuery}
            columns={[]}
            actions={[]}
            pagination={{
              pageSize: 4,
              pageIndex: 0,
            }}
            pathParams={undefined}
            filters={{
              status: "finished",
            }}
            render={(table) => (
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 ">
                {table.getRowModel().rows?.map(({ original: project }) => {
                  return (
                    <ProjectCardRebrand
                      key={project.id}
                      project={project}
                      navigateTo={`/projects/${project.content?.slug}`}
                    />
                  );
                })}
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};
