import { ReactNode, useId } from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import {
  getAlignItems,
  getFlexDirection,
  getJustifyContent,
  getSpacing,
} from "./utils";

export type StackDirection = "row" | "row-reverse" | "col" | "col-reverse";
export type StackSpacing = number | string;
export type StackJustifyContent =
  | "start"
  | "center"
  | "end"
  | "between"
  | "around"
  | "evenly"
  | "stretch";
export type StackAlignItems =
  | "start"
  | "center"
  | "end"
  | "stretch"
  | "baseline";

type StackProps = {
  children: ReactNode;
  align?: StackAlignItems;
  className?: string;
  direction?: StackDirection;
  fullHeight?: boolean;
  fullWidth?: boolean;
  justify?: StackJustifyContent;
  name?: string;
  scrollable?: boolean;
  spacing?: StackSpacing;
  wrapItems?: boolean;
};

export function Stack(props: StackProps) {
  const {
    children,
    align = "start",
    className = "",
    direction = "col",
    fullHeight = false,
    fullWidth = true,
    justify = "start",
    name = undefined,
    scrollable = false,
    spacing = 4,
    wrapItems = false,
  } = props;

  const key = useId();
  const stackName = name ? name + "-stack" : "stack";

  return (
    <div
      key={key}
      className={cn(
        stackName,
        "flex",
        getFlexDirection(direction),
        getAlignItems(align),
        getJustifyContent(justify),
        getSpacing(spacing),
        className,
        {
          "w-full": fullWidth,
          "h-full": fullHeight,
          "flex-wrap": wrapItems,
          "overflow-y-auto ": scrollable,
        },
      )}
    >
      {children}
    </div>
  );
}
