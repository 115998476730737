import { useState } from "react";

import { Investment } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import {
  buttonCommonStyle,
  buttonStyles,
  ContextualMenu,
  ContextualMenuItem,
} from "@tudigo-monorepo/web-tudigo-components";

import {
  InvestmentQuickAction,
  useInvestmentQuickActions,
} from "../hooks/use-investment-quick-actions";
import { InvestmentQuickActionsModals } from "./investment-quick-actions-modals";

export interface InvestmentQuickActionsMenuProps {
  investment: Investment;
  label?: string;
}

export function InvestmentQuickActionsMenu(
  props: InvestmentQuickActionsMenuProps,
) {
  const { investment, label } = props;

  const [investmentQuickAction, setInvestmentQuickAction] =
    useState<InvestmentQuickAction | null>(null);

  const quickActions = useInvestmentQuickActions(investment).map((item) => ({
    ...item,
    action: () => setInvestmentQuickAction(item.action),
  })) as ContextualMenuItem[];

  return (
    <>
      <ContextualMenu
        side="top"
        align="end"
        items={quickActions}
        label={label}
        menuClassName="min-w-[250px]"
        openerClassName={cn(
          label ? `${buttonCommonStyle} ${buttonStyles.tertiary}` : "",
          "w-full justify-center py-[7px] pl-[11px] md:ml-auto md:w-fit",
        )}
      />
      <InvestmentQuickActionsModals
        action={investmentQuickAction}
        investment={investment}
        onClose={setInvestmentQuickAction}
      />
    </>
  );
}
