import { Link, Outlet, useNavigate } from "react-router-dom";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  ButtonRebrand,
  Icon,
  Tabs,
} from "@tudigo-monorepo/web-tudigo-components";

export const InvestorMyCirclesPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="w-full">
      <div className="border-light-1 mx-6 flex flex-col gap-6 border-b py-4">
        <h1 className="text-primary font-obviously text-[27px] font-[570]">
          {t("space_investor.my_circles.title")}
        </h1>
      </div>

      <Tabs
        tabs={[
          {
            name: t("space_investor.my_circles.tabs.all"),
            redirectTo: "all",
          },
          {
            name: t("space_investor.my_circles.tabs.follow"),
            redirectTo: "follow",
          },
          {
            name: t("space_investor.my_circles.tabs.premium"),
            redirectTo: "premium",
          },
        ]}
        className="px-6"
      />

      <Outlet />

      <div className="m-6 flex flex-col gap-2">
        <p className="text-dark-1 text-lg font-bold">
          {t("space_investor.my_circles.cta.circles_list.title")}
        </p>
        <ButtonRebrand
          asChild
          size="lg"
          variant="outline"
          className="font-roober w-fit rounded-full text-sm font-light"
        >
          <Link to="/clubs-v2/list/all">
            {t("space_investor.my_circles.cta.circles_list.label")}
            <Icon name="ChevronRight" />
          </Link>
        </ButtonRebrand>
      </div>
    </div>
  );
};
