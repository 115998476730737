import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { Typography } from "../typography";

type ChipCountProps = {
  label: string | number;
  className?: string;
  variant?: "light" | "secondary" | "accent";
  size?: "M" | "S";
};

export function ChipCount(props: ChipCountProps) {
  const { variant = "light", size = "M", label, className } = props;

  const styles = {
    light: "bg-light-3 text-primary",
    secondary: "bg-secondary-super-light text-waiting",
    accent: "bg-accent-super-light text-accent",
  };

  return (
    <Typography
      className={cn(
        "flex items-center justify-center",
        "w-fit px-2",
        "rounded-lg",
        styles[variant],
        size === "M" ? "h-6 py-3" : "h-[19px] py-2",
        className,
      )}
      variant={size === "M" ? "caption1-semibold" : "caption1-medium"}
    >
      {label}
    </Typography>
  );
}
