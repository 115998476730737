import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { User } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import {
  CollectionRequestParams,
  getApiClient,
  getQueryListKey,
  UsersRequestFilters,
} from "../../../";

type GetUsersParams = CollectionRequestParams<
  { userId: string },
  UsersRequestFilters
>;

export const getUsers = async (params: GetUsersParams) => {
  const { pagination, fields, filters } = params;

  const apiClient = getApiClient();

  const response = await apiClient.apiV1.users.getUsers({
    ...filters,
    offset: pagination?.offset,
    limit: pagination?.limit,
    fields,
  });

  return response.data;
};

export const useGetUsersQuery = (
  params: GetUsersParams,
  options?: UseQueryOptions<TudigoResponse<User[]>, TudigoError>,
) => {
  const getUsersQueryKey = getQueryListKey({
    key: QUERY_KEYS.USERS,
    filters: { ...params.filters, ...params.pagination },
    fields: params.fields,
  });

  return useQuery<TudigoResponse<User[]>, TudigoError>({
    ...options,
    queryKey: getUsersQueryKey,
    queryFn: () => getUsers(params),
  });
};
