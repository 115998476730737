import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { ClubMember } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient } from "../../..";
import { getQueryDetailKey } from "../../query-key-factory";

type GetClubMember = {
  clubId: string;
  clubMemberId: string;
  query?: {
    fields?: string;
  };
};

export const getClubMember = async (params: GetClubMember) => {
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.clubs.getClubMember(params);

  return response.data;
};

export const useGetClubMember = (
  params: GetClubMember,
  options?: UseQueryOptions<TudigoResponse<ClubMember>, TudigoError>,
) => {
  const { clubId, clubMemberId } = params;

  return useQuery({
    queryKey: getQueryDetailKey({
      key: QUERY_KEYS.CLUB_MEMBER,
      id: `${clubId}-${clubMemberId}`,
    }),
    queryFn: () => getClubMember(params),
    ...options,
  });
};
