import React from "react";

import { Project } from "@tudigo-monorepo/core-tudigo-api-models";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";
import { ChipProps, MetricsCard } from "@tudigo-monorepo/web-tudigo-components";

import { PROJECTS } from "../../../../../../../core/tudigo-utils/src/lib/constant/projects";

export function ProjectHolderProjectDetailCard(props: { project: Project }) {
  const { project } = props;

  const fundraisingProgress =
    project?.goal?.minInCents &&
    project.totalAmountCollectedInCents > project.goal.minInCents
      ? project.fundraisingProgressMaxGoal
      : project.fundraisingProgressMinGoal;

  const projectStatus = (): ChipProps => {
    switch (project.status) {
      case PROJECTS.STATUS.PREFINISHED:
      case PROJECTS.STATUS.FINISHED:
        return {
          label: "Terminée",
          iconLeft: "DotChip",
          type: "info",
        };
      default:
        return {
          label: "En cours",
          iconLeft: "DotChip",
          type: "info",
        };
    }
  };

  const projectFundraisingProgressStatus = (): ChipProps | null => {
    if (fundraisingProgress >= 100) {
      return {
        label: "Objectif atteint",
        iconLeft: "DotChip",
        type: "success",
      };
    }

    return null;
  };

  return (
    <MetricsCard
      className="snap-center"
      value={`${formatCurrency(project?.totalAmountCollectedInCents ?? 0)}`}
      heading={{
        firstChip: {
          ...projectStatus(),
        },
        secondChip: projectFundraisingProgressStatus() ?? undefined,
      }}
      title={`${project.content?.name}`}
      content={{
        sideChip: {
          label: `${fundraisingProgress}%`,
          type: fundraisingProgress >= 100 ? "success" : "waiting",
        },
        sideText: "de l'objectif",
        chips: [
          {
            label: project.mechanic,
            type: "secondary",
          },
        ],
      }}
    />
  );
}
