import { Outlet } from "react-router-dom";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { PageHead, Tabs } from "@tudigo-monorepo/web-tudigo-components";

export function InvestorProfilePage() {
  const { t } = useTranslation();

  return (
    <div>
      <PageHead
        title={t(
          "my_account.web_app.my_profile_page.profile_and_accounts.title",
        )}
        withEndLine
      />

      <Tabs
        tabs={[
          {
            name: t(
              "my_account.web_app.my_profile_page.tab-navigation.item.investor_profile",
            ),
            redirectTo: "informations",
          },
          {
            name: t(
              "my_account.web_app.my_profile_page.tab-navigation.item.accounts",
            ),
            redirectTo: "organizations",
          },
          {
            name: t(
              "my_account.web_app.my_profile_page.tab-navigation.item.tax_exemption",
            ),
            redirectTo: "tax-exemptions",
          },
        ]}
        className="px-6"
      />

      <Outlet />
    </div>
  );
}
