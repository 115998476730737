import { ReactElement } from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { Tab } from "../../tab";
import { TabElement } from "../tabs";

type DesktopTabsProps = {
  className?: string;
  tabs: TabElement[];
  handleTabChange: (tabName: string) => void;
  selectedTab: string;
  desktopTabsStartCol?: 2;
};

export function DesktopTabs(props: DesktopTabsProps): ReactElement {
  const { className, tabs, handleTabChange, selectedTab, desktopTabsStartCol } =
    props;

  const sections = tabs
    .map(({ section }) => section)
    .filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

  return (
    <div
      className={cn("border-light-2 h-[52px] w-full border-b", {
        "grid grid-cols-12": !!desktopTabsStartCol,
        hidden: tabs.length === 0,
      })}
    >
      <div
        className={cn(
          "flex-row gap-6",
          { "col-span-10 col-start-2": desktopTabsStartCol === 2 },
          className,
        )}
      >
        {sections.map((section, index) => {
          return (
            <div className="flex flex-row gap-6" key={index}>
              {tabs
                .filter((tab) => tab.section === section)
                .map(
                  (
                    { name, isSearch, badge, badgeOnly, redirectTo },
                    index2,
                  ) => (
                    <Tab
                      key={`${index}-${index2}`}
                      onClick={() => handleTabChange(name)}
                      search={isSearch}
                      badge={badge}
                      badgeOnly={badgeOnly}
                      selected={selectedTab === name}
                      redirectTo={redirectTo}
                    >
                      {name}
                    </Tab>
                  ),
                )}
              {index !== sections.length - 1 && (
                <div
                  key={`sep-${index}`}
                  className="border-light-1 h-[52px] w-[1px] border-r border-dashed"
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
