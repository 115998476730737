import { Outlet } from "react-router-dom";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Tabs } from "@tudigo-monorepo/web-tudigo-components";

export const ClubLeaderMembersPage = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full">
      <div className="border-light-1 mx-6 flex flex-col gap-6 border-b py-4">
        <h1 className="text-primary font-obviously text-[27px] font-[570]">
          {t("club_leader.page_members.memnbers_tab.title")}
        </h1>
      </div>

      <Tabs
        tabs={[
          {
            name: t("club_leader.page_members.tabs.members"),
            redirectTo: "members",
          },
          {
            name: t("club_leader.page_members.tabs.premium"),
            redirectTo: "premium-members",
          },
        ]}
        className="px-6"
      />

      <Outlet />
    </div>
  );
};
