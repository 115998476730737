import { useState } from "react";

import {
  SortDirection,
  SortDirectionItem,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useGetCommentsByClubIdQuery } from "@tudigo-monorepo/web-tudigo-api-client";
import { TudigoCollection } from "@tudigo-monorepo/web-tudigo-collection";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@tudigo-monorepo/web-tudigo-components";

import { useGetClubV2UsingIdPathParams } from "../../../shared-internal/hooks/use-get-club-v2-using-id-path-params";
import { ClubCommentList } from "../club-comment-list/club-comment-list";
import { ClubNewCommentSection } from "../club-new-comment-section/club-new-comment-section";

export const ClubQaContent = () => {
  const club = useGetClubV2UsingIdPathParams();
  const clubId = club?.id as string;

  const [sortDirection, setSortDirection] = useState<SortDirection>("DESC");
  const { t } = useTranslation();

  if (!club) {
    return;
  }

  const sortDirections: SortDirectionItem[] = [
    {
      value: "ASC",
      label: t("page_club.qa_content.filter.sort_older.label"),
    },
    {
      value: "DESC",
      label: t("page_club.qa_content.filter.sort_newer.label"),
    },
  ];

  return (
    <div className="col-start-0 col-span-12 flex flex-col gap-8 px-4 md:col-span-6 md:col-start-2 md:px-0">
      <ClubNewCommentSection club={club} />
      <div className="bg-light-3 flex justify-end rounded-md p-5">
        <div className="w-1/2">
          <Select
            onValueChange={(newValue) =>
              setSortDirection(newValue as "DESC" | "ASC")
            }
          >
            <SelectTrigger className="border-dark-1 rounded-lg bg-white">
              <SelectValue
                placeholder={`${t("page_club.qa_content.filter_label")}:`}
              />
            </SelectTrigger>
            <SelectContent
              className="bg-white"
              style={{ width: "calc(100% - 1.5rem)" }}
            >
              <SelectGroup>
                <SelectLabel>
                  {t("page_club.qa_content.filter_label")}
                </SelectLabel>
                {sortDirections.map((item) => (
                  <SelectItem key={item.value} value={item.value}>
                    {item.label}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </div>
      <TudigoCollection
        columns={[]}
        useQueryFn={useGetCommentsByClubIdQuery}
        pathParams={{ clubId: clubId }}
        fields="id,author,value,created_at,replies"
        filters={{ sort_direction: sortDirection ?? "DESC" }}
        pagination={{ pageIndex: 0, pageSize: 5 }}
        render={(table, collection, comments) => (
          <ClubCommentList comments={comments?.data} clubId={clubId} />
        )}
      />
    </div>
  );
};
