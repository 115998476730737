import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { ORGANIZATIONS } from "@tudigo-monorepo/core-tudigo-utils";
import { PageEnum, useSpaceBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  Alert,
  Button,
  Chip,
  PageHead,
} from "@tudigo-monorepo/web-tudigo-components";

import { useKYCContext } from "../kyc-page";

export function KycApproved() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { organization, refetchOrganization } = useKYCContext();

  const { currentPagePaths } = useSpaceBusiness();
  const kycPagePath = currentPagePaths[
    PageEnum.ORGANIZATION_KYC_FUNNEL
  ].replace(":organizationId", organization.id);

  useEffect(() => {
    if (
      organization?.internalKycStatus !==
      ORGANIZATIONS.INTERNAL_KYC_STATUS.APPROVED
    ) {
      navigate(`${kycPagePath}/summary`, {
        replace: true,
      });
    }
  }, [navigate, organization.id, organization?.internalKycStatus]);

  const handleNextButtonClick = () => {
    refetchOrganization();
    navigate("/dispatch");
  };

  return (
    <>
      <div className="mx-6">
        {organization.type === ORGANIZATIONS.TYPE.INDIVIDUAL && (
          <Chip
            label={t(
              "kyc_funnel.step_bank_account_information.page.chip.label.individual",
            )}
            type="secondary"
          />
        )}
        {organization.type === ORGANIZATIONS.TYPE.COMPANY && (
          <div className="flex gap-2">
            <Chip
              label={t(
                "kyc_funnel.step_bank_account_information.page.chip.label.company",
              )}
              type="secondary"
            />
            <Chip label={organization.name} type="info" />
          </div>
        )}
      </div>
      <PageHead
        title={t("kyc_funnel.approved_page.title")}
        subtitle={t("kyc_funnel.approved_page.subtitle")}
        withEndLine
      />
      <div className="mx-6">
        <Alert
          size="F"
          title={t("kyc_funnel.approved_page.alert.title")}
          description={t("kyc_funnel.approved_page.alert.description")}
          variant="success"
          icon="Checked"
        />
      </div>
      <div className="border-light-1 fixed bottom-0 left-0 z-20 flex h-[80px] w-full items-center justify-center gap-x-4 border-t bg-white px-6 sm:h-[104px]">
        <Button
          className="w-fit px-12"
          variant="primary"
          iconRight="ArrowRight"
          label={t("kyc_funnel.form.button.next.label")}
          onClick={handleNextButtonClick}
        />
      </div>
    </>
  );
}
