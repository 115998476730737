import {
  WMACustomer,
  WMACustomerStatusEnum,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { ORGANIZATIONS } from "@tudigo-monorepo/core-tudigo-utils";
import { TabElement } from "@tudigo-monorepo/web-tudigo-components";

export const useWmaCustomerTabs = (customer?: WMACustomer) => {
  if (!customer) {
    return [];
  }
  const isQualificationDone =
    customer.customerUser?.investorProfile?.isQualificationExpired === false &&
    customer.customerUser?.investorProfile?.confirmed;

  const organizationsOwned = customer.customerUser?.organizationsOwned;

  const isAnOrganizationKycStatusNotValid = organizationsOwned?.some(
    (organization) =>
      organization.internalKycStatus !==
      ORGANIZATIONS.INTERNAL_KYC_STATUS.APPROVED,
  );

  const tabs: TabElement[] = [
    {
      name: "Sélectionner l'utilisateur",
      redirectTo: "select-user",
      badges: {
        invited: {
          icon: "Timer",
          asChip: "small",
          variant: "waiting",
        },
        accepted: {
          icon: "Checked",
          asChip: "small",
          variant: "success",
        },
        rejected: {
          icon: "Cross",
          asChip: "small",
          variant: "destructive",
        },
      },
    },
    {
      name: "Profil investisseur",
      redirectTo: "qualification",
      badges: {
        invited: {
          icon: "Cadenas",
          asChip: "small",
          variant: "warning",
        },
        accepted: isQualificationDone
          ? {
              icon: "Checked",
              asChip: "small",
              variant: "success",
            }
          : undefined,
        rejected: {
          icon: "Cross",
          asChip: "small",
          variant: "destructive",
        },
      },
    },
    {
      name: "Validation d'identité et comptes",
      redirectTo: "kyc",
      badges: {
        invited: {
          icon: "Cadenas",
          asChip: "small",
          variant: "warning",
        },
        accepted: !isAnOrganizationKycStatusNotValid
          ? {
              icon: "Checked",
              asChip: "small",
              variant: "success",
            }
          : undefined,
        rejected: {
          icon: "Cross",
          asChip: "small",
          variant: "destructive",
        },
      },
    },
  ];

  if (
    customer.status === WMACustomerStatusEnum.ACCEPTED &&
    isQualificationDone &&
    !isAnOrganizationKycStatusNotValid
  ) {
    tabs.push({
      name: "Investissements",
      redirectTo: "investments",
      section: "ready",
    });
  }

  return tabs.map((tab) => {
    const badge = tab.badges?.[customer.status as keyof typeof tab.badges];

    return {
      ...tab,
      badge: badge ? badge : undefined,
    };
  }) as TabElement[];
};
