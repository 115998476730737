import React from "react";
import { useNavigate } from "react-router-dom";

import { Investment } from "@tudigo-monorepo/core-tudigo-api-models";
import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { formatCurrency, LEMONWAY } from "@tudigo-monorepo/core-tudigo-utils";
import { useUpdateInvestmentMutation } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Avatar,
  Button,
  Icon,
  PageHead,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import {
  investmentFunnelFields,
  useInvestmentFunnelPageContext,
} from "../../../../../pages/investment-funnel-page";
import { FinalPendingTasksBlock } from "../shared/final-pending-tasks-block";
import { LastNewsBlock } from "../shared/last-news-block";

function requirementsAreFulfilled(ongoingInvestment: Investment) {
  return (
    ongoingInvestment.investorOrganization &&
    ongoingInvestment.investorOrganization.pmsAccount &&
    ongoingInvestment.investorOrganization.pmsAccount.virtualIban
  );
}

export function FinalStepWaitingWirePaymentContent() {
  const { ongoingInvestment, project } = useInvestmentFunnelPageContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const virtualIban =
    ongoingInvestment?.investorOrganization?.pmsAccount?.virtualIban;

  const {
    mutateAsync: updateInvestmentIntentMethod,
    isPending: isUpdateInvestmentIntentLoading,
  } = useUpdateInvestmentMutation();

  if (!ongoingInvestment || !requirementsAreFulfilled(ongoingInvestment)) {
    return;
  }

  const resetPaymentMethod = () => {
    updateInvestmentIntentMethod(
      {
        investmentId: ongoingInvestment.id,
        query: {
          fields: investmentFunnelFields,
        },
        data: {
          paymentMethodType: null,
        },
      },
      {
        onSuccess: () => {
          navigate(`/projects/${project.id}/invest/${ongoingInvestment.id}`);
        },
      },
    );
  };

  const isAmountGreaterThanLimit =
    ongoingInvestment?.totalAmountInCents >
    LEMONWAY.MAX_ALLOWED_AMOUNT_IN_CENTS.CARD;

  return (
    <>
      <PageHead
        title={t("investment_funnel.final_page.waiting_wire.page_head.title")}
        className="py-4"
        withEndLine
      />

      <div className="mx-6">
        <Title level="h4">
          {t("investment_funnel.final_page.waiting_wire.title")}
        </Title>

        <Typography variant="body1">
          {t("investment_funnel.final_page.waiting_wire.description")}
        </Typography>

        <div className="wire-detail border-light-1 my-6 rounded-lg border">
          <div className="head p-6">
            <Typography variant="body2">
              {t(
                "investment_funnel.final_page.waiting_wire.instructions.label.rib",
              )}
            </Typography>
          </div>
          <div className="flex flex-col">
            <div className="title border-light-1 w-full border-b"></div>
            <div className="flex flex-col gap-2 p-6">
              <div className="row flex justify-between">
                <Typography variant="body3-regular">
                  {t(
                    "investment_funnel.final_page.waiting_wire.instructions.label.project",
                  )}
                </Typography>
                <Typography variant="body3-semibold">
                  {project.content?.name}
                </Typography>
              </div>
              <div className="row flex justify-between">
                <Typography variant="body3-regular">
                  {t(
                    "investment_funnel.final_page.waiting_wire.instructions.label.company_name",
                  )}
                </Typography>
                <Typography variant="body3-semibold">
                  {project.vehicleOrganization?.name}
                </Typography>
              </div>
              <div className="row flex justify-between">
                <Typography variant="body3-regular">
                  {t(
                    "investment_funnel.final_page.waiting_wire.instructions.label.payment_method",
                  )}
                </Typography>
                <Typography variant="body3-semibold">Virement</Typography>
              </div>
              <div className="row flex justify-between">
                <Typography variant="body3-regular">
                  {t(
                    "investment_funnel.final_page.waiting_wire.instructions.label.amount",
                  )}
                </Typography>
                <Typography variant="body3-semibold">
                  {formatCurrency(ongoingInvestment.totalAmountInCents)}
                </Typography>
              </div>
              <div className="row flex justify-between">
                <Typography variant="body3-regular">
                  {t(
                    "investment_funnel.final_page.waiting_wire.instructions.label.iban",
                  )}
                </Typography>
                <Typography variant="body3-semibold">
                  {virtualIban?.iban}
                </Typography>
              </div>
              <div className="row flex justify-between">
                <Typography variant="body3-regular">
                  {t(
                    "investment_funnel.final_page.waiting_wire.instructions.label.bic",
                  )}
                </Typography>
                <Typography variant="body3-semibold">
                  {virtualIban?.bic}
                </Typography>
              </div>
            </div>

            {virtualIban?.pdfDocument.signedUrl && (
              <Button
                label={t(
                  "investment_funnel.final_page.waiting_wire.instructions.label.download_rib_button",
                )}
                variant="primary"
                iconRight="Download"
                className="mx-6 mb-6 w-fit"
                onClick={() =>
                  window.open(virtualIban.pdfDocument.signedUrl as string)
                }
              />
            )}

            {!isAmountGreaterThanLimit && (
              <Button
                label="Changer de méthode de paiement"
                variant="tertiary"
                iconLeft="ArrowLeft"
                className="mx-6 mb-6 w-fit"
                isLoading={isUpdateInvestmentIntentLoading}
                onClick={resetPaymentMethod}
              />
            )}
          </div>
        </div>
      </div>
      <FinalPendingTasksBlock
        project={project}
        tasks={[
          {
            avatar: (
              <Avatar variant="green">
                <Icon
                  name="Checked"
                  size="S"
                  primaryColor={themeColors.success}
                  secondaryColor={themeColors.success}
                />
              </Avatar>
            ),
            title: t(
              "investment_funnel.final_page.waiting_wire.final_pending_tasks_block.step_1.title",
            ),
          },
          {
            avatar: (
              <Avatar variant="blue">
                <Typography variant="body2-semibold" className="text-accent">
                  2
                </Typography>
              </Avatar>
            ),
            title: t(
              "investment_funnel.final_page.waiting_wire.final_pending_tasks_block.step_2.title",
            ),
            description: t(
              "investment_funnel.final_page.waiting_wire.final_pending_tasks_block.step_2.description",
            ),
          },
          {
            avatar: (
              <Avatar variant="blue">
                <Typography variant="body2-semibold" className="text-accent">
                  3
                </Typography>
              </Avatar>
            ),
            title: t(
              "investment_funnel.final_page.waiting_wire.final_pending_tasks_block.step_3.title",
            ),
            description: t(
              "investment_funnel.final_page.waiting_wire.final_pending_tasks_block.step_3.description",
            ),
          },
          {
            avatar: (
              <Avatar variant="blue">
                <Typography variant="body2-semibold" className="text-accent">
                  4
                </Typography>
              </Avatar>
            ),
            title: t(
              "investment_funnel.final_page.waiting_wire.final_pending_tasks_block.step_4.title",
            ),
            description: t(
              "investment_funnel.final_page.waiting_wire.final_pending_tasks_block.step_4.description",
            ),
          },
          {
            avatar: (
              <Avatar variant="blue">
                <Typography variant="body2-semibold" className="text-accent">
                  5
                </Typography>
              </Avatar>
            ),
            title: t(
              "investment_funnel.final_page.waiting_wire.final_pending_tasks_block.step_5.title",
            ),
            description: t(
              "investment_funnel.final_page.waiting_wire.final_pending_tasks_block.step_5.description",
            ),
          },
        ]}
      />
      <LastNewsBlock />
    </>
  );
}
