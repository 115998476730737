import React from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { PageEnum, useSpaceBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  Button,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { useKYCContext } from "../../../kyc-page";
import { KycSectionChipStatus } from "../../../shared/components/kyc-section-chip-status";

export function OrganizationDetailSummarySection() {
  const { organization } = useKYCContext();
  const companyInformation = organization.companyInformation;

  const { currentPagePaths } = useSpaceBusiness();
  const kycPagePath = currentPagePaths[
    PageEnum.ORGANIZATION_KYC_FUNNEL
  ].replace(":organizationId", organization.id);

  const editPagePath = `${kycPagePath}/legal-information`;
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex gap-2">
          <Title level="h4">
            {t("kyc_funnel.final_summary.organization_detail_section.title")}
          </Title>
          <KycSectionChipStatus section="company-informations" />
        </div>
        <Link to={editPagePath}>
          <Button
            label={t("kyc_funnel.final_summary.edit_btn.label")}
            iconLeft="Pen"
            variant="tertiary"
          />
        </Link>
      </div>
      <div className="border-light-2 my-4 border-b" />
      <div className="flex flex-col gap-2">
        <div className="flex justify-between">
          <Typography variant="caption1-regular">
            {t("kyc_funnel.company_information_form.label.company_name")}
          </Typography>
          <Typography variant="caption1-semibold">
            {organization.name}
          </Typography>
        </div>
        <div className="flex justify-between">
          <Typography variant="caption1-regular">
            {t(
              "kyc_funnel.company_information_form.label.registration_numbers.siret",
            )}
          </Typography>
          <Typography variant="caption1-semibold">
            {companyInformation?.registrationNumbers?.siret ??
              t("kyc_funnel.final_summary.input_empty")}
          </Typography>
        </div>
        <div className="flex justify-between">
          <Typography variant="caption1-regular">
            {t(
              "kyc_funnel.company_information_form.label.registration_numbers.legal_status",
            )}
          </Typography>
          <Typography variant="caption1-semibold">
            {companyInformation?.legalStatus ??
              t("kyc_funnel.final_summary.input_empty")}
          </Typography>
        </div>
        <div className="flex justify-between">
          <Typography variant="caption1-regular">
            {t(
              "kyc_funnel.company_information_form.label.registration_location.address",
            )}
          </Typography>
          <Typography variant="caption1-semibold">
            {companyInformation?.registrationLocation?.street1 ??
              t("kyc_funnel.final_summary.input_empty")}
          </Typography>
        </div>
      </div>
    </div>
  );
}
