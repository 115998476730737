import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useGetWMACustomersInvitationsQuery } from "@tudigo-monorepo/web-tudigo-api-client";
import { TudigoCollection } from "@tudigo-monorepo/web-tudigo-collection";
import { PageHead } from "@tudigo-monorepo/web-tudigo-components";

import { WMACustomerInvitationListElement } from "../../shared/components/wma-customer-invitation-list-element";
import { wmaCustomersInvitationsFields } from "../../shared/utils/wma-requests-fields-utils";

export function WMACustomersInvitationListPage() {
  const { t } = useTranslation();

  return (
    <div>
      <PageHead
        title={t("wma.web_app.wma_customers_invitation_list_page.title")}
        withEndLine
      />

      <div className="m-6">
        <TudigoCollection
          useQueryFn={useGetWMACustomersInvitationsQuery}
          fields={wmaCustomersInvitationsFields}
          columns={[]}
          renderEmpty={() => (
            <p className="font-montserrat">
              {t("wma.web_app.wma_customers_invitation_list_page.empty_list")}
            </p>
          )}
          render={(_, __, res) => {
            const wmaCustomersInvitations = res?.data;

            return (
              <div className="flex flex-col gap-y-6">
                <h3 className="font-montserrat text-lg font-bold">
                  {t("wma.web_app.wma_customers_invitation_list_page.subtitle")}
                </h3>
                {wmaCustomersInvitations?.map((wmaCustomer) => (
                  <WMACustomerInvitationListElement
                    key={wmaCustomer.id}
                    wmaCustomer={wmaCustomer}
                    navigateTo={`/my-account/wma/invitations/${wmaCustomer.id}`}
                  />
                ))}
              </div>
            );
          }}
        />
      </div>
    </div>
  );
}
