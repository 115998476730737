import { SwitchInput } from "@tudigo-monorepo/core-tudigo-api-models";

import { SwitchInputComponent } from "../components/inputs/switch-input";
import { FormInputPlugin } from "./form-input-plugin";

const getDefaultValue = () => {
  return false;
};
export const FormSwitchInputPlugin: FormInputPlugin<"switch", SwitchInput> = {
  type: "switch",
  getDefaultValue: () => {
    return getDefaultValue();
  },
  renderingBlock: (input, context) => {
    const { sectionKey, answers, setAnswer } = context;
    if (!sectionKey) return;
    const selectedValue = answers[sectionKey][input.name as string] as boolean;

    return (
      <SwitchInputComponent
        input={input}
        selectedValue={selectedValue ?? getDefaultValue()}
        onChange={(value) => {
          setAnswer(sectionKey, input, value);
        }}
      />
    );
  },
  canAutoSubmit: true,
  defaultValueIfNull: true,
};
