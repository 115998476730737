import React from "react";

import {
  Investment,
  isBondsProject,
  RepaymentScheduleVisibilityEnum,
} from "@tudigo-monorepo/core-tudigo-api-models";

import { GrossAndNetRepaymentSchedule } from "./gross-and-net-repayment-schedule";
import { GrossOnlyRepaymentSchedule } from "./gross-only-repayment-schedule";
import { NotReadyRepaymentSchedule } from "./not-ready-repayment-schedule";

type RepaymentScheduleProps = {
  investment: Investment;
};

export const RepaymentSchedule = (props: RepaymentScheduleProps) => {
  const { investment } = props;

  return (
    <>
      {investment &&
        isBondsProject(investment.project) &&
        investment?.project.bondsMechanicSettings
          .repaymentScheduleVisibility ===
          RepaymentScheduleVisibilityEnum.HIDDEN.toString() && (
          <NotReadyRepaymentSchedule />
        )}
      {investment &&
        isBondsProject(investment.project) &&
        investment?.project.bondsMechanicSettings
          .repaymentScheduleVisibility ===
          RepaymentScheduleVisibilityEnum.GROSS.toString() && (
          <GrossOnlyRepaymentSchedule investmentId={investment.id} />
        )}
      {investment &&
        isBondsProject(investment.project) &&
        investment?.project.bondsMechanicSettings
          .repaymentScheduleVisibility ===
          RepaymentScheduleVisibilityEnum.GROSS_AND_NET.toString() && (
          <GrossAndNetRepaymentSchedule investmentId={investment.id} />
        )}
    </>
  );
};
