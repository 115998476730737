import { Navigate, Route, Routes } from "react-router-dom";

import { EditWMACustomerInvitationPage } from "../../pages/edit-wma-customer-invitation-page/edit-wma-customer-invitation-page";
import { WMACustomersInvitationListPage } from "../../pages/wma-customers-invitation-list-page/wma-customers-invitation-list-page";

export function WMARoutesWebapp() {
  return (
    <Routes>
      <Route path="/invitations" element={<WMACustomersInvitationListPage />} />
      <Route
        path="/invitations/:wmaCustomerId"
        element={<EditWMACustomerInvitationPage />}
      />
      <Route path="*" element={<Navigate to="invitations" replace />} />
    </Routes>
  );
}
