import { useSearchParams } from "react-router-dom";

import { User } from "@tudigo-monorepo/core-tudigo-api-models";

export const dispatchImpersonateUser = (params: { user: User }) => {
  const pageUrl = new URL("/dispatch", import.meta.env["VITE_APP_URL"]);
  pageUrl.searchParams.set("impersonate_id", params.user.id);
  window.open(pageUrl, "_blank");
};

export const useImpersonateSearchParams = () => {
  const [searchParams] = useSearchParams();

  const impersonateId = searchParams.get("impersonate_id");

  return { impersonateId };
};
