import { PropsWithChildren } from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

import { BadgeRebrand } from "../../ui/badge-rebrand/badge-rebrand";
import { ProgressBarRebrand } from "../../ui/progress-bar-rebrand";

type ProjectProgressBarProps = PropsWithChildren<{
  progressMaxGoal: number;
  progressMinGoal: number;
  className?: string;
}>;

export function ProjectProgressBarRebrand({
  progressMaxGoal,
  progressMinGoal,
  className,
  children,
}: ProjectProgressBarProps) {
  const { t } = useTranslation();

  const fundraisingProgress =
    progressMinGoal > 100 ? progressMaxGoal : progressMinGoal;

  return (
    <div
      className={cn("amounts flex flex-col gap-2 overflow-hidden ", className)}
    >
      <div className="flex items-center justify-between">
        {children}
        <div className="percentage-collected-area flex items-center gap-2">
          {progressMinGoal >= 100 ? (
            <BadgeRebrand variant="success" className="leading-none">
              {t("component.project_card.footer.goal_reached")}
            </BadgeRebrand>
          ) : null}
          <p
            className={`text-base font-semibold ${
              progressMinGoal >= 100 ? "text-success-medium-rebrand" : ""
            }`}
          >
            {progressMinGoal}%
          </p>
        </div>
      </div>
      <ProgressBarRebrand
        progress={fundraisingProgress}
        variant={progressMinGoal >= 100 ? "success" : "secondary"}
      />
    </div>
  );
}
