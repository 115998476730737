import { FC, ReactNode } from "react";

import { BadgeProps } from "../badge/badge";
import { RedirectWrapper } from "./parts/redirect-wrapper";
import { TabCommonWrapper } from "./parts/tab-common-wrapper";
import { TabText } from "./parts/tab-text";

export type TabProps = {
  children: ReactNode;
  onClick?: () => void;
  selected?: boolean;
  deactivated?: boolean;
  badge?: BadgeProps;
  badgeOnly?: boolean;
  search?: boolean;
  redirectTo?: string;
};

export function Tab(props: TabProps) {
  const {
    children,
    onClick,
    selected,
    deactivated,
    badge,
    badgeOnly,
    search,
    redirectTo,
  } = props;

  const Text: FC = () => (
    <TabCommonWrapper
      onClick={onClick}
      search={search}
      selected={selected}
      deactivated={deactivated}
    >
      <TabText
        search={search}
        selected={selected}
        deactivated={deactivated}
        badge={badge}
        badgeOnly={badgeOnly}
      >
        {children}
      </TabText>
    </TabCommonWrapper>
  );

  if (!redirectTo) return <Text />;

  return (
    <RedirectWrapper redirectTo={redirectTo}>
      <Text />
    </RedirectWrapper>
  );
}
