import { Link } from "react-router-dom";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Button, ButtonProps } from "../button";
import type { ChipSize, ChipType } from "../chip";
import { Icon } from "../icons/icon";
import { MetricsHeading } from "../metrics-heading";

type ChipProps = {
  label: string | number;
  type?: ChipType;
  size?: ChipSize;
};

type MetricsTableCardProps = {
  title: string;
  className?: string;
  navigateTo?: string;
  value?: string | number;
  heading?: {
    firstChip?: ChipProps;
    secondChip?: ChipProps;
    subtitle?: string;
  };
  footer?: {
    title?: string;
    description?: string;
    ctaText?: string;
    btn?: ButtonProps;
    btns?: ButtonProps[];
  };
  rows?: {
    label: string;
    value: string | number;
    valueCustomClassName?: string;
  }[];
  onClick?: () => void;
};

export function MetricsTableCard(props: MetricsTableCardProps) {
  const { title, heading, footer, navigateTo, rows, className, onClick } =
    props;

  return (
    <article
      onClick={onClick}
      className={cn(
        "h-auto min-h-[175px] w-full",
        "flex flex-col justify-between gap-y-2.5 p-4",
        "rounded-lg bg-white shadow-[0_0_20px_0_rgba(0,0,0,0.1)]",
        className,
      )}
    >
      <div className="flex flex-col gap-y-2.5">
        <MetricsHeading
          title={title}
          subtitle={heading?.subtitle}
          firstChip={heading?.firstChip}
          secondChip={heading?.secondChip}
        />
        <ul
          data-metric-type="table"
          className="flex w-full flex-col gap-y-1.5 border-t pt-4"
        >
          {rows?.map((row, index) => (
            <li
              key={index}
              className="bg-light-3 flex h-[31px] w-full items-center justify-between rounded-lg px-3 py-0.5"
            >
              <span className="font-montserrat text-dark-2 text-sm">
                {row.label}
              </span>
              <span
                className={cn(
                  "font-montserrat",
                  "font-bold",
                  "text-dark-1",
                  row.valueCustomClassName,
                )}
              >
                {row.value}
              </span>
            </li>
          ))}
        </ul>
      </div>

      <div className="flex flex-col gap-y-4 px-3 py-2.5">
        {!!footer?.title && (
          <p className="text-dark-1 font-sans text-sm font-bold">
            {footer.title}
          </p>
        )}
        {!!footer?.description && (
          <p className="text-dark-2 truncate font-sans text-sm font-normal">
            {footer.description}
          </p>
        )}
        {!!footer?.btn && (
          <Button
            className="w-1/2"
            onClick={footer.btn?.onClick}
            label={footer.btn?.label}
            variant={footer.btn?.variant}
            singleIcon={footer.btn?.singleIcon}
            iconRight={footer.btn?.iconRight}
          />
        )}
        <div
          className={cn("flex flex-wrap gap-2", {
            hidden: !footer?.btns,
          })}
        >
          {footer?.btns?.map((btn, index) => (
            <Button
              key={index}
              className="w-1/2"
              onClick={btn?.onClick}
              label={btn?.label}
              variant={btn?.variant}
              singleIcon={btn?.singleIcon}
              iconRight={btn?.iconRight}
            />
          ))}
        </div>

        {!!navigateTo && (
          <Link
            to={navigateTo}
            className="hover:border-accent-medium ml-auto flex items-center gap-x-2 rounded-3xl border border-transparent px-4 py-[7px] transition-colors"
          >
            <Icon name="ArrowRight" primaryColor={themeColors["dark-2"]} />
            {!!footer?.ctaText && (
              <p className="text-dark-2 font-sans text-sm font-medium">
                {footer.ctaText}
              </p>
            )}
          </Link>
        )}
      </div>
    </article>
  );
}
