import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Icon } from "../icons/icon";
import { IconName } from "../icons/icon-name.type";
import { Linkable } from "../linkable";
import { Typography } from "../typography";
import { StepObject } from "./stepper";
import { isStepObject } from "./utils";

type CustomStyle = {
  icon: IconName;
  backgroundClassName: string;
  iconColor: string;
};

type StepProps = {
  active: boolean;
  completed: boolean;
  index: number;
  selfManaged: boolean;
  step: string | StepObject;
  visible: boolean;
  onClick: (stepIndex: number) => void;
  customStyle?: CustomStyle;
};

export function Step(props: StepProps) {
  const { active, completed, index, visible, selfManaged, step, onClick } =
    props;

  const elementAttributes: Record<string, unknown> = {
    "data-step": index + 1,
    "data-step-active": active,
    "data-step-completed": completed,
    "data-step-visible": visible,
    role: selfManaged ? "button" : undefined,
  };

  const isObject = isStepObject(step);

  return (
    <Linkable
      {...elementAttributes}
      navigateTo={isObject ? step.navigateTo : undefined}
      onClick={() => onClick(index)}
      className={cn(
        "step bg-light-2 flex h-[30px] w-[30px] min-w-[30px] items-center justify-center rounded-full transition-colors sm:flex",
        {
          "bg-accent-super-light sm:w-fit sm:rounded-lg sm:px-[11px] sm:py-[5px]":
            active,
          "bg-success-light": completed,
          [`${isObject ? step.customStyle?.backgroundClassName : " "}`]:
            isObject ? step.customStyle?.backgroundClassName : undefined,
          hidden: !visible,
          "pointer-events-none sm:pointer-events-auto sm:cursor-pointer":
            selfManaged,
          "pointer-events-none cursor-default opacity-20":
            isObject && step.isAvailable === false,
        },
      )}
    >
      {completed ? (
        <Icon
          name={
            isObject && step.customStyle?.icon
              ? step.customStyle?.icon
              : "Checked"
          }
          primaryColor={
            isObject && step.customStyle?.iconColor
              ? themeColors[
                  step.customStyle.iconColor as keyof typeof themeColors
                ]
              : themeColors.success
          }
        />
      ) : (
        <Typography
          variant="caption1-semibold"
          className={cn("text-center leading-none", { "text-accent": active })}
        >
          {active ? (
            <>
              <span className="hidden sm:block">
                {isObject ? step.label : step}
              </span>
              <span className="block sm:hidden">{index + 1}</span>
            </>
          ) : (
            <span>{index + 1}</span>
          )}
        </Typography>
      )}
    </Linkable>
  );
}
