import { ReactNode } from "react";

import {
  getProjectMechanicLabel,
  isBondsProject,
  isSharesProject,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Icon, Tooltip } from "@tudigo-monorepo/web-tudigo-components";

import { useProjectContext } from "../../../project.context";
import { SidebarProjectFinancialStatsBonds } from "./sidebar-project-financial-stats-bonds";
import { SidebarProjectFinancialStatsShare } from "./sidebar-project-financial-stats-share";

export const SideFinStatsLabel = ({
  children,
  tooltipDesc,
}: {
  children: ReactNode;
  tooltipDesc?: string;
}) => (
  <div className="mb-0.5 flex items-center gap-2">
    <h3 className="text-dark-1 text-sm">{children}</h3>
    <Tooltip description={tooltipDesc}>
      <Icon name="Info" size="XS" />
    </Tooltip>
  </div>
);

export const SideFinStatsValue = ({ children }: { children: ReactNode }) => (
  <p className="text-primary pt-1 text-sm font-semibold">{children}</p>
);

export function SidebarProjectFinancialStats() {
  const project = useProjectContext();
  const { t } = useTranslation();

  if (!project) return null;

  function getTooltipDesc() {
    if (project && isSharesProject(project)) {
      return "Une action est un titre de propriété d’une entreprise qui confère le droit de participer aux décisions de l’entreprise.";
    }

    if (project && isBondsProject(project)) {
      if (project.bondsMechanicSettings?.isConvertible) {
        return "Une obligation convertible en actions est une obligation classique, à la différence que le capital est remboursé en actions en cas de réalisation d'un évènement déclencheur de type augmentation de capital.";
      } else {
        return "Une créance prise par une entreprise auprès d’investisseurs.";
      }
    }
  }

  return (
    <>
      <article>
        <SideFinStatsLabel tooltipDesc={getTooltipDesc()}>
          Instrument financier
        </SideFinStatsLabel>
        <SideFinStatsValue>
          {t(getProjectMechanicLabel(project))}
        </SideFinStatsValue>
      </article>

      {isSharesProject(project) && <SidebarProjectFinancialStatsShare />}
      {isBondsProject(project) && <SidebarProjectFinancialStatsBonds />}
    </>
  );
}
