import {
  CMSCardTextWithImageAndChipBlock,
  TrackRecord,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { getImgSrc } from "@tudigo-monorepo/core-tudigo-utils";
import { Chip, ChipType } from "@tudigo-monorepo/web-tudigo-components";

export type ClubCardBlockProps = {
  blockData?: CMSCardTextWithImageAndChipBlock["data"];
  trackRecord?: TrackRecord;
};

export const ClubCardBlock = (props: ClubCardBlockProps) => {
  const { blockData, trackRecord } = props;

  const imgUrl = getImgSrc(blockData?.image) || trackRecord?.logo?.url;
  const title = blockData?.title || trackRecord?.title;
  const description = blockData?.text || trackRecord?.description;
  const chipLabel = blockData?.chip?.text || trackRecord?.industry?.name;

  return (
    <div className="border-light-1 sm:max-w-1/3 flex w-full flex-col items-center justify-center gap-2 rounded-lg border bg-white p-6">
      {imgUrl && (
        <img alt="track record logo" className="max-h-24" src={imgUrl} />
      )}
      {title && (
        <p className="font-montserrat text-dark-1 font-semibold">{title}</p>
      )}
      {description && (
        <p className="font-montserrat text-dark-1 text-center text-xs font-normal">
          {description}
        </p>
      )}
      {chipLabel && (
        <Chip
          type={(blockData?.chip?.variant as ChipType) || "secondary"}
          label={chipLabel}
        />
      )}
    </div>
  );
};
