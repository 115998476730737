import {
  CMSCardPress,
  CMSCardPressCoverageBlock,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { getImgSrc } from "@tudigo-monorepo/core-tudigo-utils";
import { Carousel } from "@tudigo-monorepo/web-tudigo-components";

type CardPressCoverageBlockProps = {
  block: CMSCardPressCoverageBlock;
};

export const CardPressCoverageBlock = ({
  block,
}: CardPressCoverageBlockProps) => {
  return (
    <Carousel
      className="mb-6"
      slidesToShow={1}
      slidesToScroll={1}
      arrowsPlacement="above"
      autoplay={false}
      infinite
    >
      {block.data.cards.map((card, index) => (
        <CardPressCoverage
          key={index}
          image={card.image}
          content={card.content}
          date={card.date}
        />
      ))}
    </Carousel>
  );
};

const CardPressCoverage = (props: CMSCardPress) => {
  const { image, content, date } = props;

  return (
    <div className="border-light-1 flex w-full flex-col items-center gap-4 rounded-lg border bg-white p-6 text-center">
      <div className="flex flex-col items-center gap-4">
        <img src={getImgSrc(image)} alt="press" className="max-h-14" />
        <div>
          <p dangerouslySetInnerHTML={{ __html: content }} />

          <p className="text-dark-4 mt-1 text-sm">
            le {new Date(date).toLocaleDateString()}
          </p>
        </div>
      </div>
    </div>
  );
};
