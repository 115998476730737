import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

const badgeVariants = cva(
  `
  inline-flex items-center rounded-lg border px-2 py-1
  text-xs font-semibold transition-colors
  focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2`,
  {
    variants: {
      variant: {
        default: "border-dark-2 bg-transparent text-dark-2",
        info: "border-transparent bg-light-2 text-primary",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

export interface RebrandBadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: RebrandBadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge as RebrandBadge, badgeVariants as rebrandBadgeVariants };
