import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { ClubMember } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient, getQueryDetailKey } from "../../..";

type CreateCircleMemberParam = {
  clubId: string;
  body: {
    status: ClubMember["status"];
  };
  query?: {
    fields?: string;
  };
};

export const createCircleMember = async (params: CreateCircleMemberParam) => {
  const { clubId, body, query } = params;
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.clubs.createCircleMember(
    clubId,
    body,
    query,
  );

  return response.data;
};

export const useCreateCircleMember = (
  options?: UseMutationOptions<
    TudigoResponse<ClubMember>,
    TudigoError,
    CreateCircleMemberParam
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (params) => createCircleMember(params),
    onSuccess: (...props) => {
      const [, params] = props;

      const queryKey = getQueryDetailKey({
        key: QUERY_KEYS.CLUBS,
        id: params.clubId,
        fields: params.query?.fields,
      });
      queryClient.invalidateQueries({ queryKey });
      options?.onSuccess?.(...props);
    },
  });
};
