import { Navigate, Route, Routes } from "react-router-dom";

import { AssemblyDocumentsContent } from "../../pages/investment-assembly-detail-page/content/assembly-documents-content";
import { AssemblyVoteContent } from "../../pages/investment-assembly-detail-page/content/assembly-vote-content";
import { MyInvestmentAssemblyDetailPage } from "../../pages/investment-assembly-detail-page/my-investment-assembly-detail-page";
import { MyInvestmentAssemblyPage } from "../../pages/investment-assembly-page/my-investment-assembly-page";
import { MyInvestmentAssemblyVoteFormPage } from "../../pages/investment-assembly-vote-form-page/my-investment-assembly-vote-form-page";
import { MyInvestmentAssemblyVoteSignaturePage } from "../../pages/investment-assembly-vote-signature-page/my-investment-assembly-vote-signature-page";
import { InvestmentAssembliesContent } from "../../pages/investment-page/content/investment-assemblies-content";
import { InvestmentDocumentsContent } from "../../pages/investment-page/content/investment-documents-content";
import { InvestmentInformationContent } from "../../pages/investment-page/content/investment-information-content";
import { InvestmentNewsContent } from "../../pages/investment-page/content/investment-news-content";
import { InvestmentRepaymentsContent } from "../../pages/investment-page/content/investment-repayments-content";
import { InvestmentStatisticsContent } from "../../pages/investment-page/content/investment-statistics-content";
import { InvestmentPage } from "../../pages/investment-page/investment-page";
import { InvestmentsListContent } from "../../pages/user-investments-page/content/investments-list-content";
import { UserInvestmentsPage } from "../../pages/user-investments-page/user-investments-page";

export function InvestmentRouteWebapp() {
  return (
    <Routes>
      <Route path={"/list/*"} element={<UserInvestmentsPage />}>
        <Route path=":status" element={<InvestmentsListContent />} />
      </Route>
      <Route
        path="/projects/:projectId/assemblies/:assemblyId/assembly-participant/:assemblyParticipantId/*"
        element={<MyInvestmentAssemblyPage />}
      >
        <Route path="detail/*" element={<MyInvestmentAssemblyDetailPage />}>
          <Route path="vote" element={<AssemblyVoteContent />} />
          <Route path="documents" element={<AssemblyDocumentsContent />} />
          <Route path="*" element={<Navigate to="vote" />} />
        </Route>
        <Route
          path="vote-form"
          element={<MyInvestmentAssemblyVoteFormPage />}
        />
        <Route
          path="vote-signature"
          element={<MyInvestmentAssemblyVoteSignaturePage />}
        />
        <Route path="*" element={<Navigate to="detail" replace />} />
      </Route>
      <Route path="/projects/:projectId/*" element={<InvestmentPage />}>
        <Route path="information" element={<InvestmentInformationContent />} />
        <Route path="news" element={<InvestmentNewsContent />} />
        <Route
          path="documents/:filter"
          element={<InvestmentDocumentsContent />}
        />
        <Route path="assemblies" element={<InvestmentAssembliesContent />} />
        <Route path="statistics" element={<InvestmentStatisticsContent />} />
        <Route path="repayments" element={<InvestmentRepaymentsContent />} />
        <Route path="*" element={<Navigate to="information" replace />} />
      </Route>
    </Routes>
  );
}
