import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  ProjectsFilters,
  useGetProjectsQuery,
} from "@tudigo-monorepo/web-tudigo-api-client";
import {
  FilterConfiguration,
  TudigoCollection,
  TudigoFilters,
} from "@tudigo-monorepo/web-tudigo-collection";
import {
  Icon,
  Table,
  TableCardList,
} from "@tudigo-monorepo/web-tudigo-components";

import { useClubProjectsTableColumns } from "./hooks/use-club-projects-table-columns";

export const ClubLeaderProjectsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const clubId = useParams().clubId;

  const columns = useClubProjectsTableColumns();
  const [filters, setFilters] = React.useState<ProjectsFilters>({
    status: undefined,
    mechanic: undefined,
  });

  const filterConfiguration: FilterConfiguration<ProjectsFilters>[] = [
    {
      label: "Instrument",
      name: "mechanic",
      type: "select",
      placeholder: "Tout",
      options: [
        {
          value: "bonds",
          name: "Obligation",
        },
        {
          value: "shares",
          name: "Action",
        },
      ],
    },
    {
      label: "Statut",
      name: "status",
      type: "select",
      placeholder: "Tout",
      options: [
        {
          value: "started",
          name: t("projects.stage.label.started"),
        },
        {
          value: "scheduled",
          name: t("projects.stage.label.scheduled"),
        },
        {
          value: "prefinished",
          name: t("projects.stage.label.prefinished"),
        },
        {
          value: "finished",
          name: t("projects.stage.label.finished"),
        },
      ],
    },
  ];

  return (
    <div className="w-full">
      <div className="border-light-1 mx-6 border-b py-4">
        <h1 className="text-primary font-obviously text-[27px] font-[570] leading-[45.9px]">
          Levées
        </h1>
      </div>
      <div className="m-6">
        <TudigoFilters
          className="mb-6 mt-4"
          useLocationAsState
          filters={filters}
          setFilters={setFilters}
          filtersConfiguration={filterConfiguration}
        />
        <TudigoCollection
          useQueryFn={useGetProjectsQuery}
          columns={columns}
          filters={{
            ...filters,
            clubId: clubId,
          }}
          actions={[
            {
              render: () => <Icon name="ChevronRight" />,
              isAvailable: () => true,
              onClick: (project) => {
                navigate(`/club-leader/${clubId}/projects/${project.id}`);
              },
            },
          ]}
          pathParams={undefined}
          render={(table, props) => {
            return (
              <>
                <Table
                  table={table}
                  actions={props.actions}
                  className="hidden sm:table"
                  onRowClick={(project) => {
                    navigate(`/club-leader/${clubId}/projects/${project.id}`);
                  }}
                />
                <TableCardList
                  table={table}
                  className="sm:hidden"
                  onCardClick={(project) => {
                    navigate(`/club-leader/${clubId}/projects/${project.id}`);
                  }}
                />
              </>
            );
          }}
        />
      </div>
    </div>
  );
};
