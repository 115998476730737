import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { WMACustomer } from "@tudigo-monorepo/core-tudigo-api-models";
import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { wmaCustomersFields } from "@tudigo-monorepo/web-feature-wma";
import {
  useGetWmaCustomersByWmaUserId,
  WmaCustomersFilters,
} from "@tudigo-monorepo/web-tudigo-api-client";
import {
  FilterConfiguration,
  TudigoCollection,
  TudigoFilters,
} from "@tudigo-monorepo/web-tudigo-collection";
import {
  Button,
  Icon,
  PageHead,
  Table,
  TableCardList,
} from "@tudigo-monorepo/web-tudigo-components";

import { useWmaCustomersColumns } from "./hooks/use-wma-customers-columns";

export function ListWmaCustomersPage() {
  const { user } = useUserBusiness();
  const userId = user?.id as string;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const filterConfiguration: FilterConfiguration<WmaCustomersFilters>[] = [
    {
      label: "Statut de l'invitation",
      name: "status",
      type: "select",
      placeholder: "Tous",
      options: [
        {
          value: "invited",
          name: t(
            `wma.customers_list_page.customer.status_invitation.label.invited`,
          ),
        },
        {
          value: "accepted",
          name: t(
            `wma.customers_list_page.customer.status_invitation.label.accepted`,
          ),
        },
        {
          value: "rejected",
          name: t(
            `wma.customers_list_page.customer.status_invitation.label.rejected`,
          ),
        },
        {
          value: "canceled",
          name: t(
            `wma.customers_list_page.customer.status_invitation.label.canceled`,
          ),
        },
      ],
    },
  ];

  const [filters, setFilters] = React.useState<WmaCustomersFilters>({
    status: undefined,
  });

  return (
    <div id="organizations-list-manager-page">
      <PageHead title="Clients" withEndLine>
        <div className=" flex w-full flex-col items-center justify-between gap-4 sm:flex-row">
          <div></div>
          <Link to="/wma/customers/create" className="w-full sm:w-fit">
            <Button
              label="Ajouter un client"
              variant="tertiary"
              iconRight="Plus"
              className="w-full"
            />
          </Link>
        </div>
      </PageHead>
      <TudigoFilters
        className="mx-6 mb-6 mt-4"
        useLocationAsState
        filters={filters}
        setFilters={setFilters}
        filtersConfiguration={filterConfiguration}
      />
      <div className="m-6">
        <TudigoCollection
          useLocationAsState
          columns={useWmaCustomersColumns()}
          useQueryFn={useGetWmaCustomersByWmaUserId}
          pathParams={{ wmaUserId: userId }}
          fields={wmaCustomersFields}
          filters={filters}
          actions={[
            {
              render: (customer: WMACustomer) => (
                <Icon
                  name="ChevronRight"
                  primaryColor={themeColors["dark-2"]}
                />
              ),
            },
          ]}
          render={(table, props) => {
            return (
              <>
                <Table
                  table={table}
                  actions={props.actions}
                  className="hidden sm:table"
                  onRowClick={(customer) => {
                    navigate(`/wma/customers/edit/${customer.id}/select-user`);
                  }}
                />
                <TableCardList
                  table={table}
                  className="sm:hidden"
                  onCardClick={(customer) => {
                    navigate(`/wma/customers/edit/${customer.id}/select-user`);
                  }}
                />
              </>
            );
          }}
        />
      </div>
    </div>
  );
}
