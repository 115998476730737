import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { TrackRecord } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Button } from "@tudigo-monorepo/web-tudigo-components";

const TRACK_RECORD_PER_CARD = 7;

type ClubTrackRecordCardProps = {
  trackRecords: TrackRecord[];
};

export const ClubTrackRecordCard = ({
  trackRecords,
}: ClubTrackRecordCardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const pathParams = useParams();

  return (
    <div
      className={cn(
        "card border-light-1 relative hidden w-full rounded-lg border bg-white p-6 ",
        {
          "lg:block": pathParams["*"] && pathParams["*"] !== "track-record",
        },
      )}
    >
      <h3 className="font-obviously text-dark-1 mb-1 font-[570] leading-none">
        {t("page_club.club_track_record_card.title")}
      </h3>
      <div className="my-4 grid w-full grid-cols-4 place-content-center gap-4">
        {trackRecords
          ?.slice(0, TRACK_RECORD_PER_CARD)
          .map((trackRecord, index) => (
            <TrackRecordIcon
              key={index}
              iconUrl={trackRecord.logo?.url ?? undefined}
            />
          ))}
        {trackRecords.length > TRACK_RECORD_PER_CARD && (
          <LastTrackRecordIcon countTrackRecord={trackRecords.length} />
        )}
      </div>
      <div className="flex w-full justify-center">
        <Button
          variant="tertiary"
          label={t("page_club.club_track_record_card.button.see_more.label")}
          className="w-1/2"
          onClick={() => navigate(`track-record`)}
        />
      </div>
    </div>
  );
};

const TrackRecordIcon = ({ iconUrl }: { iconUrl?: string }) => (
  <div className="flex w-full justify-center">
    <img
      src={iconUrl}
      alt="Track Record Icon"
      className="h-16 w-16 rounded-lg object-cover"
    />
  </div>
);

const LastTrackRecordIcon = ({
  countTrackRecord,
}: {
  countTrackRecord: number;
}) => (
  <div className="flex w-full justify-center">
    <div className="border-medium-2 bg-light-2 flex h-16 w-16 items-center justify-center rounded-lg border-2">
      <p className="text-lg font-semibold">{`+${
        countTrackRecord - TRACK_RECORD_PER_CARD
      }`}</p>
    </div>
  </div>
);
