import { useNavigate } from "react-router-dom";

import { ClubLeader, ClubV2 } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Button, Icon } from "@tudigo-monorepo/web-tudigo-components";

type TeamMemberBlockProps = {
  leader: ClubLeader;
};
const TeamMemberBlock = (props: TeamMemberBlockProps) => {
  const { leader } = props;

  return (
    <div className="bg-dark-4 sm:max-w-1/3 relative flex max-h-[300px] w-full justify-center rounded-lg">
      <img
        src={leader.profileImage.url ?? ""}
        alt="avatar"
        className="w-full rounded-lg object-cover "
      />
      <div className="absolute bottom-7 flex w-[90%] items-center justify-between rounded-lg bg-white p-2">
        <div className="flex flex-col gap-1">
          <p className="font-montserrat text-dark-1 text-sm font-semibold">
            {`${leader.firstName} ${leader.lastName}`}
          </p>
          <p className="font-montserrat text-dark-1 text-xs font-normal">
            {leader.shortDescription}
          </p>
          <p className="font-montserrat text-dark-1 text-xs font-normal">
            {leader.title}
          </p>
        </div>
        {leader.socialLinks?.linkedin && (
          <a
            href={leader.socialLinks.linkedin}
            target="_blank"
            rel="noreferrer"
          >
            <Icon name="SocialLinkedin2" />
          </a>
        )}
      </div>
    </div>
  );
};

export const CircleTeamMembersBlock = ({ club }: { club: ClubV2 }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  if (!club) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col justify-between gap-4 sm:flex-row">
        {club.leaders
          ?.slice(0, 3)
          .map((leader) => <TeamMemberBlock leader={leader} key={leader.id} />)}
      </div>
      <div className="flex w-full justify-end">
        <Button
          variant="tertiary"
          onClick={() => navigate(`/clubs-v2/${club.id}/team`)}
          label={t(
            "page_club.tab_introduction.block_team_members.see_more_button.label",
          )}
        />
      </div>
    </div>
  );
};
