import { Collapse } from "@tudigo-monorepo/web-tudigo-components";

type AlertBlockProps = {
  title: string | null;
  content: string | null;
};

export function ExpandedBlock(props: AlertBlockProps) {
  const { title, content } = props;

  if (!content) return null;

  return (
    <div className="dynamic-form-alert-block flex w-full items-center justify-center gap-x-1.5 rounded py-0.5">
      <Collapse items={[content]} clickOutsideEnabled={false} defaultExpanded>
        {title}
      </Collapse>
    </div>
  );
}
