import { Navigate, Route, Routes } from "react-router-dom";

import { InvestorsListContent } from "../../../pages/project-holder-investors-page/content/investors-list-content";
import { ProjectHolderInvestorsPage } from "../../../pages/project-holder-investors-page/project-holder-investors-page";

export function ProjectHolderInvestorsRouteWebapp() {
  return (
    <Routes>
      <Route path={"/*"} element={<ProjectHolderInvestorsPage />}>
        <Route path=":status" element={<InvestorsListContent />} />
        <Route
          path="*"
          element={<Navigate to="/project-holder/investors/all" />}
        />
      </Route>
    </Routes>
  );
}
