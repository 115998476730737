import React from "react";
import { useParams } from "react-router-dom";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useGetCircleSubscriptionPeriods } from "@tudigo-monorepo/web-tudigo-api-client";
import { TudigoCollection } from "@tudigo-monorepo/web-tudigo-collection";
import { Table, TableCardList } from "@tudigo-monorepo/web-tudigo-components";

import { useClubSubscriptionPeriodsColumn } from "../hooks/use-club-subscription-periods-column";

export const ClubLeaderMembersPremiumContent = () => {
  const columns = useClubSubscriptionPeriodsColumn();
  const circleId = useParams().clubId;
  const { t } = useTranslation();

  if (!circleId) {
    return null;
  }

  return (
    <div className="p-6">
      <p className="text-primary font-montserrat text-lg font-bold">
        {t("club_leader.page_members.premium_tab.title")}
      </p>
      <TudigoCollection
        useLocationAsState
        columns={columns}
        useQueryFn={useGetCircleSubscriptionPeriods}
        pathParams={{ circleId }}
        fields="id,role,contract,statistics,user,status,joined_at,selected_price,created_at,updated_at,subscription,club,subscription"
        renderEmpty={() => (
          <p className="font-montserrat text-[13px] font-normal leading-[22px]">
            {t(
              "club_leader.page_circle_subscription_periods.collection.no_result",
            )}
          </p>
        )}
        render={(table, props) => {
          return (
            <>
              <Table
                table={table}
                actions={props.actions}
                className="hidden sm:table"
              />
              <TableCardList table={table} className="sm:hidden" />
            </>
          );
        }}
      />
    </div>
  );
};
