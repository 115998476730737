import React, { useEffect } from "react";

import { useMyInvestmentAssemblyPageContext } from "../../investment-assembly-page/my-investment-assembly-page";
import { AssemblyParticipationDetailCard } from "../components/assembly-participation-detail-card";
import { AssemblyParticipationForm } from "../components/assembly-participation-form";
import { AssemblyResultSummaryCard } from "../components/assembly-result-summary-card";
import { AssemblyVoteCard } from "../components/assembly-vote-card";
import { ProxyRepresentativeDetailCard } from "../components/proxy-representative-detail-card";
import { ProxyTypeForm } from "../components/proxy-type-form";

export function AssemblyVoteContent() {
  const {
    assembly,
    assemblyParticipant,
    participationFormData,
    isAssemblyOpenForParticipationUpdate,
  } = useMyInvestmentAssemblyPageContext();

  const [participationFormIsOpen, setParticipationFormIsOpen] =
    React.useState<boolean>(
      (isAssemblyOpenForParticipationUpdate(assembly, assemblyParticipant) &&
        assemblyParticipant.presenceConfirmation === null) ??
        false,
    );
  const [proxyTypeFormIsOpen, setProxyTypeFormIsOpen] =
    React.useState<boolean>(false);

  useEffect(() => {
    if (assemblyParticipant.presenceConfirmation === null) {
      setParticipationFormIsOpen(
        (isAssemblyOpenForParticipationUpdate(assembly, assemblyParticipant) &&
          true) ??
          false,
      );
    }
  }, [assembly, assemblyParticipant, isAssemblyOpenForParticipationUpdate]);

  return (
    <>
      <div className="flex w-full gap-4 px-6">
        <div className="w-full md:w-1/2">
          <AssemblyParticipationDetailCard
            participationFormIsOpen={participationFormIsOpen}
            setParticipationFormIsOpen={setParticipationFormIsOpen}
          />
        </div>

        {participationFormIsOpen && participationFormData && (
          <div className="w-full md:w-1/2">
            <div className="flex flex-col gap-y-2.5">
              <AssemblyParticipationForm
                setParticipationFormIsOpen={setParticipationFormIsOpen}
              />
            </div>
          </div>
        )}

        {((assemblyParticipant.presenceConfirmation &&
          assemblyParticipant.participationChannel === "voting_by_proxy" &&
          assemblyParticipant.proxyStatus === null &&
          !participationFormIsOpen) ||
          proxyTypeFormIsOpen) && (
          <div className="w-full md:w-1/2">
            <ProxyTypeForm setProxyTypeFormIsOpen={setProxyTypeFormIsOpen} />
          </div>
        )}

        {assemblyParticipant.presenceConfirmation &&
          assemblyParticipant.participationChannel === "voting_by_proxy" &&
          assemblyParticipant.proxyStatus !== null &&
          !participationFormIsOpen &&
          !proxyTypeFormIsOpen && (
            <div className="w-full md:w-1/2">
              <ProxyRepresentativeDetailCard
                setProxyTypeFormIsOpen={setProxyTypeFormIsOpen}
              />
            </div>
          )}

        {assemblyParticipant.presenceConfirmation &&
          assemblyParticipant.participationChannel !== "voting_by_proxy" &&
          !participationFormIsOpen && (
            <div className="w-full md:w-1/2">
              <AssemblyVoteCard />
            </div>
          )}
      </div>

      {assembly.status === "closed" && (
        <div className="w-full p-6 md:w-full">
          <AssemblyResultSummaryCard />
        </div>
      )}
    </>
  );
}
