import { ReactNode, useState } from "react";
import { Placement as PopperPlacement } from "@popperjs/core";
import { PopoverContentProps } from "@radix-ui/react-popover";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../ui/popover/popover";
import { Icon } from "../icons/icon";
import { IconName } from "../icons/icon-name.type";
import { Linkable } from "../linkable";
import { Tooltip } from "../tooltip";
import { Typography } from "../typography";

export type ContextualMenuItem = {
  iconProps?: {
    name: IconName;
    color?: keyof typeof themeColors;
  };
  label?: string;
  navigateTo?: string;
  disabled?: boolean;
  tooltip?: string;
  action?: () => void;
  render?: () => ReactNode;
};

export type ContextualMenuProps = {
  items: ContextualMenuItem[];
  label?: string;
  openerClassName?: string;
  menuClassName?: string;
  placement?: PopperPlacement;
  openOnHover?: boolean;
  side?: PopoverContentProps["side"];
  align?: PopoverContentProps["align"];
};

export function ContextualMenu(props: ContextualMenuProps) {
  const {
    items,
    label = null,
    openerClassName,
    menuClassName,
    openOnHover = false,
    side,
    align,
  } = props;

  const [open, setOpen] = useState(false);

  const handleItemAction = (item: ContextualMenuItem) => {
    if (!item.disabled && item.action) {
      item.action();
      setOpen(false);
    }
  };

  const handleMouseEnter = () => {
    if (openOnHover) {
      setOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (openOnHover) {
      setOpen(false);
    }
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={cn("flex items-center gap-x-2", openerClassName)}
      >
        {label}
        <Icon name="Dots" primaryColor={themeColors["dark-2"]} />
      </PopoverTrigger>

      <PopoverContent
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        side={side}
        align={align}
        className={cn("flex flex-col gap-y-3", menuClassName)}
      >
        {items.map((item, index) => {
          if (item.render) {
            return item.render();
          }

          const iconColor = item.iconProps?.color
            ? themeColors[item.iconProps.color]
            : themeColors["dark-2"];

          return (
            <Tooltip
              key={index}
              description={item.tooltip}
              placement="top"
              childrenClassName="flex"
              tooltipClassname="min-w-[200%]"
            >
              <Linkable
                navigateTo={item.navigateTo}
                onClick={() => handleItemAction(item)}
                className={cn("flex items-center gap-x-3", {
                  "cursor-pointer": !item.disabled,
                  "cursor-not-allowed": item.disabled,
                })}
              >
                {item.iconProps && (
                  <div className="flex min-w-6 items-center justify-center">
                    <Icon
                      name={item.iconProps.name}
                      primaryColor={
                        item.disabled ? themeColors["dark-4"] : iconColor
                      }
                    />
                  </div>
                )}
                {item.label && (
                  <Typography
                    variant="body2"
                    className={cn({ "text-dark-4": item.disabled })}
                  >
                    {item.label}
                  </Typography>
                )}
              </Linkable>
            </Tooltip>
          );
        })}
      </PopoverContent>
    </Popover>
  );
}
