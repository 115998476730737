import {
  getProjectMechanicSettings,
  Project,
} from "@tudigo-monorepo/core-tudigo-api-models";

export enum InvestmentAmountErrorEnum {
  SHOULD_NOT_BE_NULL,
  SHOULD_BE_MULTIPLE,
  SHOULD_BE_GREATER_THAN_MINIMUM,
}

export function getInvestmentAmountError(
  amount: number | null,
  project: Project,
): InvestmentAmountErrorEnum | null {
  const projectMechanicSettings = getProjectMechanicSettings(project);
  const { unitPriceInCents, minimumTicketAmountInCents } =
    projectMechanicSettings;

  if (!amount) {
    return InvestmentAmountErrorEnum.SHOULD_NOT_BE_NULL;
  }

  if (amount < minimumTicketAmountInCents) {
    return InvestmentAmountErrorEnum.SHOULD_BE_GREATER_THAN_MINIMUM;
  }

  if (amount % unitPriceInCents !== 0) {
    return InvestmentAmountErrorEnum.SHOULD_BE_MULTIPLE;
  }

  return null;
}
