import React, { ReactNode } from "react";

import {
  Avatar,
  Button,
  ButtonProps,
  ChipSize,
  ChipType,
  Collapse,
  IconName,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

export type MainChipProps = {
  label: string | number;
  type?: ChipType;
  iconLeft?: IconName;
  size?: ChipSize;
};

type KycCollapseFormGroupProps = {
  // eslint-disable-next-line no-undef
  content: JSX.Element;
  title: string;
  subtitle: string;
  // mainChip?: MainChipProps;
  chipChildren?: ReactNode;
  iconBtn?: ButtonProps;
  expanded?: boolean;
};

export function KycCollapseFormGroup(props: KycCollapseFormGroupProps) {
  const {
    content,
    title,
    subtitle,
    chipChildren = null,
    iconBtn,
    expanded = false,
  } = props;

  return (
    <div className="expanded-investment-detail-card">
      <Collapse
        items={[content]}
        clickOutsideEnabled={false}
        defaultExpanded={expanded}
      >
        <div className="flex w-full flex-wrap-reverse items-center justify-between gap-4">
          <div className="flex items-center justify-items-start gap-4 p-1">
            <div className="flex flex-col items-center gap-4">
              <Avatar
                icon={{
                  name: "Profile",
                }}
              />
            </div>
            <div className="flex flex-col gap-1">
              <Typography variant="body3-semibold">{title}</Typography>
              <Typography variant="body3-regular">{subtitle}</Typography>
            </div>
          </div>
          <div className="mr-4 flex items-center gap-6">
            {/* <div>
              {mainChip && (
                <Chip
                  label={mainChip.label}
                  type={mainChip.type}
                  iconLeft={mainChip.iconLeft}
                  size={mainChip.size}
                />
              )}
            </div> */}
            {chipChildren}

            {iconBtn && (
              <Button
                singleIcon={iconBtn.singleIcon}
                variant={iconBtn.variant}
                onClick={iconBtn.onClick}
              />
            )}
          </div>
        </div>
      </Collapse>
    </div>
  );
}
