import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { useGetUserTaxExemptionsQuery } from "@tudigo-monorepo/web-tudigo-api-client";

import { investorTaxExemptionsQueryFields } from "../../../../shared/utils/investor-tax-exemptions-fields";
import { getLatestTaxExemptionByEndDate } from "../utils/tax-exemptions-utils";

export const useInvestorTaxExemptions = () => {
  const { user } = useUserBusiness();

  const {
    data: userTaxExemptions,
    isLoading,
    isFetching,
    refetch: refetchUserTaxExemptions,
  } = useGetUserTaxExemptionsQuery({
    fields: investorTaxExemptionsQueryFields,
    pathParams: { userId: user?.id ?? "me" },
    filters: { organizationId: user?.individualOrganization?.id },
  });

  const signedTaxExemptions =
    userTaxExemptions?.data.filter((item) => item.status === "signed") ?? [];
  const pendingTaxExemptions =
    userTaxExemptions?.data.filter((item) => item.status === "draft") ?? [];

  const pendingTaxExemptionCSG =
    pendingTaxExemptions.find((item) => item.taxName === "CSG") ?? null;
  const pendingTaxExemptionIR =
    pendingTaxExemptions.find((item) => item.taxName === "IR") ?? null;

  const lastSignedTaxExemptionIR = getLatestTaxExemptionByEndDate(
    signedTaxExemptions.filter((item) => item.taxName === "IR"),
  );

  return {
    taxExemptions: userTaxExemptions?.data ?? [],
    signedTaxExemptions,
    lastSignedTaxExemptionIR,
    pendingTaxExemptionIR,
    pendingTaxExemptionCSG,
    isLoadingTaxExemptions: isLoading || isFetching,
    refetchUserTaxExemptions,
  };
};
