import React from "react";

import {
  Organization,
  OrganizationDocument,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { PMS_FILES } from "@tudigo-monorepo/core-tudigo-utils";
import {
  FormContextProvider,
  getSmartFormComponents,
} from "@tudigo-monorepo/web-feature-form";
import { Title } from "@tudigo-monorepo/web-tudigo-components";

import { useKYCContext } from "../../../kyc-page";
import { getOrganizationCompanyDocumentsData } from "../../../utils/kyc-utils";
import { OrganizationKycDocumentsFormData } from "../utils/organization-documents-utils";

type CompanyKycDocumentsFormGroupProps = {
  organizationFormData: Organization;
  setOrganizationFormData: (organization: Organization) => void;
};

type CompanyKycDocumentBlockProps = {
  organizationDocument: OrganizationDocument;
  formName:
    | "companyRegistration.document"
    | "status.document"
    | "uboRegister.document";
};

function CompanyKycDocumentBlock(props: CompanyKycDocumentBlockProps) {
  const { organizationDocument, formName } = props;
  const { t } = useTranslation();
  const { SmartPdfField } =
    getSmartFormComponents<OrganizationKycDocumentsFormData>();

  const getLabel = () => {
    switch (formName) {
      case "companyRegistration.document":
        return t(
          "kyc_funnel.step_documents.form.organization_document.label.company_registration",
        );
      case "status.document":
        return t(
          "kyc_funnel.step_documents.form.organization_document.label.legal_status",
        );
      case "uboRegister.document":
        return t(
          "kyc_funnel.step_documents.form.organization_document.label.ubo_register",
        );
      default:
        return "";
    }
  };

  const isRejectedDocument =
    organizationDocument.pmsFile?.status === PMS_FILES.STATUS.REJECTED &&
    organizationDocument.document.base64 === undefined;

  return (
    <SmartPdfField
      name={formName}
      label={getLabel()}
      required
      disabled={
        organizationDocument.pmsFile?.status ===
        PMS_FILES.STATUS.WAITING_APPROVAL
      }
      errors={
        isRejectedDocument
          ? [t("kyc_funnel.document_status.rejected")]
          : undefined
      }
    />
  );
}

export function CompanyKycDocumentsFormGroup(
  props: CompanyKycDocumentsFormGroupProps,
) {
  const { organizationFormData, setOrganizationFormData } = props;
  const { organization } = useKYCContext();
  const { t } = useTranslation();

  const organizationDocumentsFormData =
    getOrganizationCompanyDocumentsData(organization);

  const handleFormChange = (newFormData: OrganizationKycDocumentsFormData) => {
    const formDocuments: OrganizationDocument[] = [];
    formDocuments.push({
      organization: { id: organization.id },
      ...newFormData.companyRegistration,
    });
    formDocuments.push({
      organization: { id: organization.id },
      ...newFormData.status,
    });
    formDocuments.push({
      organization: { id: organization.id },
      ...newFormData.uboRegister,
    });
    setOrganizationFormData({
      ...organizationFormData,
      documents: formDocuments,
    });
  };

  return (
    <div className="company-documents">
      <Title h3Brand="brand-2" level="h3" className="text-primary">
        {t("kyc_funnel.step_documents.form.organization_document.title")} -{" "}
        {organization.name}
      </Title>

      <div className="my-6 rounded-lg border px-10 py-8">
        <FormContextProvider
          error={null}
          formData={organizationDocumentsFormData}
          setFormData={handleFormChange}
        >
          <div className="flex flex-col gap-4">
            <CompanyKycDocumentBlock
              organizationDocument={
                organizationDocumentsFormData.companyRegistration
              }
              formName="companyRegistration.document"
            />
            <CompanyKycDocumentBlock
              organizationDocument={organizationDocumentsFormData.status}
              formName="status.document"
            />

            <CompanyKycDocumentBlock
              organizationDocument={organizationDocumentsFormData.uboRegister}
              formName="uboRegister.document"
            />
          </div>
        </FormContextProvider>
      </div>
    </div>
  );
}
