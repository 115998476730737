import {
  ClubProfilePicture,
  TudigoLogoRebrand,
} from "@tudigo-monorepo/web-tudigo-components";

import { useGetClubV2UsingIdPathParams } from "../../../shared-internal/hooks/use-get-club-v2-using-id-path-params";
import { SubscribeClubNavigationStepper } from "./subscribe-club-navigation-stepper/subscribe-club-navigation-stepper";

export function SubscribeClubNavigation() {
  const club = useGetClubV2UsingIdPathParams();

  if (!club) {
    return null;
  }

  return (
    <header className="border-light-2 sticky top-0 z-[1000] max-h-[186px] w-screen border-b bg-white px-4 py-8 lg:max-h-[174px] lg:px-6">
      <div className="flex flex-col items-center gap-y-4">
        <div className="flex flex-col items-center gap-2.5 sm:flex-row">
          <div className="inline-flex items-center gap-x-2.5">
            <ClubProfilePicture
              club={club}
              size="XS"
              className="block sm:hidden"
            />
            <ClubProfilePicture
              club={club}
              size="S"
              className="hidden sm:block"
            />
            <h3 className="text-primary font-sans text-lg font-bold">by</h3>
          </div>
          <TudigoLogoRebrand />
        </div>

        <SubscribeClubNavigationStepper clubId={club.id} />
      </div>
    </header>
  );
}
