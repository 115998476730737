import React from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  FileUploadConnected,
  InputConnected,
  InputTextareaConnected,
  MBToBytes,
  pdfMimesTypes,
} from "@tudigo-monorepo/web-tudigo-components";

const formSchema = z.object({
  id: z.string(),
  title: z.string().nonempty("Ce champ est obligatoire"),
  description: z.string(),
  document: z.array(z.any()),
  type: z.union([z.literal("information"), z.literal("document")]),
});

type NewsTypeInformation = "information";
export const newsInvestDefaultValues = {
  id: "",
  title: "",
  description: "",
  document: undefined,
  type: "document" as NewsTypeInformation,
};

export type NewsInvestFormValues = z.infer<typeof formSchema>;

export function NewsInvestForm({
  onSubmit,
  defaultValues,
  children,
}: {
  onSubmit: (values: any) => void;
  noDocument?: boolean;
  defaultValues?: NewsInvestFormValues;
  isEdit?: boolean;
  children?: React.ReactNode;
}) {
  const { t } = useTranslation();

  const form = useForm<NewsInvestFormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues || newsInvestDefaultValues,
  });

  const newsType = form.getValues("type");

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-y-6"
      >
        <InputConnected
          name="title"
          label={t(
            "back_office.edit_project.project_news.add_news.form.title.label",
          )}
          required
        />
        <InputTextareaConnected
          name="description"
          label={t(
            "back_office.edit_project.project_news.add_news.form.description.label",
          )}
        />
        {newsType === "document" && (
          <FileUploadConnected
            name="document"
            label={t(
              "back_office.edit_project.project_news.add_news.form.document.label",
            )}
            options={{
              accept: pdfMimesTypes,
              maxSize: MBToBytes(10),
            }}
          />
        )}
        {children}
      </form>
    </FormProvider>
  );
}
