import React from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { useIntl } from "react-intl";

import {
  Investment,
  WMAInvestment,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";
import { getInvestmentPaymentMethodTypeTranslateKey } from "@tudigo-monorepo/web-feature-investments";
import {
  AccountCard,
  Avatar,
  Chip,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

export const useWmaCustomersInvestmentColumns = () => {
  const columnHelper = createColumnHelper<WMAInvestment>();
  const intl = useIntl();
  const { t } = useTranslation();

  return [
    columnHelper.display({
      header: intl.formatMessage({ id: "investments.table.columns.investor" }),
      cell: (info) => (
        <AccountCard
          avatar={
            info.row.original.investment?.investorOrganization?.logo?.url ? (
              <Avatar
                url={
                  info.row.original.investment?.investorOrganization?.logo.url
                }
              />
            ) : (
              <Avatar>
                <span>🦊</span>
              </Avatar>
            )
          }
          onClick={() => {}}
          upperText={
            info.row.original.investment?.investorOrganization?.name ??
            `${info.row.original.wmaCustomer?.customerUser?.firstName} ${info.row.original.wmaCustomer?.customerUser?.lastName}`
          }
          lowerText={info.row.original?.investment?.investorOrganization?.type}
          lowerTextAsChip
        />
      ),
    }),
    columnHelper.accessor("investment.project.content.name", {
      header: intl.formatMessage({ id: "investments.table.columns.name" }),
      cell: (info) => (
        <Typography variant="body3-regular">
          {info.row.original?.investment?.project?.content?.name}
        </Typography>
      ),
    }),
    columnHelper.accessor("investment.totalAmountInCents", {
      header: intl.formatMessage({ id: "investments.table.columns.amount" }),
      cell: (info) => (
        <Typography variant="body3-regular">
          {formatCurrency(info.row.original?.investment?.totalAmountInCents)}
        </Typography>
      ),
    }),
    columnHelper.accessor("investment.createdAt", {
      header: intl.formatMessage({ id: "investments.table.columns.date" }),
      cell: (info) => (
        <Typography variant="body3-regular">
          {intl.formatDate(info.row.original?.investment?.createdAt)}
        </Typography>
      ),
    }),
    columnHelper.accessor("investment.project.mechanic", {
      header: intl.formatMessage({
        id: "investments.table.columns.instrument",
      }),
      cell: (info) => (
        <Chip
          type="primary"
          label={t(
            `projects.mechanic.label.${info.row.original?.investment?.project?.mechanic}`,
          )}
          size="M"
        />
      ),
    }),
    columnHelper.accessor("investment.paymentMethodType", {
      header: intl.formatMessage({
        id: "investments.table.columns.payment_method",
      }),
      cell: (info) => (
        <Typography variant="body3-regular">
          {intl.formatMessage({
            id: getInvestmentPaymentMethodTypeTranslateKey(
              info.row.original.investment as Investment,
            ),
          })}
        </Typography>
      ),
    }),
    columnHelper.accessor("investment.globalStatus", {
      header: intl.formatMessage({
        id: "investments.table.columns.global_status",
      }),
      cell: (info) => (
        <Chip
          label={t(
            `investment.global_status.${info.row.original?.investment?.globalStatus}`,
          )}
          iconLeft="DotChip"
          type={(() => {
            switch (info.row.original?.investment?.globalStatus) {
              case "validated":
                return "success";
              case "transfer_funds_failed":
                return "error";
              default:
                return "warning";
            }
          })()}
        />
      ),
    }),
  ];
};
