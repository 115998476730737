import { Outlet } from "react-router-dom";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { PageHead } from "@tudigo-monorepo/web-tudigo-components";

export function WmaProfilePage() {
  const { t } = useTranslation();

  return (
    <div>
      <PageHead
        title={t("my_account.web_app.my_profile_page.accounts.title")}
        withEndLine
      />

      <Outlet />
    </div>
  );
}
