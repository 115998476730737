import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Icon } from "../icons/icon";
import { IconName } from "../icons/icon-name.type";

const badgeVariants = cva(
  "inline-flex px-4 py-1.5 items-center rounded-md border text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default: "bg-white border-transparent text-primary",
        accent: "bg-accent border-transparent text-white",
        "accent-super-light": "bg-accent-super-light text-accent border-accent",
        success: "bg-success border-transparent text-white",
        "success-light": "bg-success-light border-transparent text-success",
        warning: "bg-warning-super-light border-transparent text-warning",
        waiting: "bg-waiting-super-light border-transparent text-waiting",
        destructive: "bg-error-light border-transparent text-error",
        outline: "bg-transparent border-primary text-primary",
      },
      compact: {
        true: "px-2 py-1",
      },
      asFlag: {
        true: "rounded-l-none rounded-r-xs",
      },
      asChip: {
        none: "",
        normal:
          "!p-0 rounded-full w-[27px] h-[26px] flex justify-center items-center",
        small:
          "!p-0 rounded-full w-[19px] h-[19px] flex justify-center items-center",
      },
      shadow: {
        true: "shadow-tag",
      },
    },
  },
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  icon?: IconName;
}

function Badge({
  className,
  variant = "default",
  asFlag = false,
  asChip = "none",
  shadow = asFlag,
  compact = false,
  icon,
  children,
  ...props
}: BadgeProps) {
  return (
    <div
      className={cn(
        badgeVariants({ compact, variant, asFlag, asChip, shadow }),
        className,
      )}
      {...props}
    >
      {children}
      {icon && (
        <Icon
          name={icon}
          width={asChip === "small" ? 11.3 : 24}
          height={asChip === "small" ? 11.3 : 24}
          primaryColor={(() => {
            switch (variant) {
              case "success":
                return themeColors["white"];
              case "warning":
                return themeColors["warning"];
              case "waiting":
                return themeColors["waiting"];
              case "destructive":
                return themeColors["error"];
              case "default":
                return themeColors["primary"];
              default:
                return themeColors["success"];
            }
          })()}
        />
      )}
    </div>
  );
}

export { Badge, badgeVariants };
