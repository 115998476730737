import * as React from "react";
import { useState } from "react";

import { Icon } from "../icons/icon";
import { Typography } from "../typography";

type FaqBlockProps = {
  items: FaqItem[];
  className?: string;
};

type FaqItemsProps = {
  question: string;
  answer: string;
  isOpen: boolean;
  toggle: () => void;
};

type FaqItem = {
  question: string;
  answer: string;
};

const FaqItem = ({ question, answer, isOpen, toggle }: FaqItemsProps) => {
  return (
    <div className="faq-item hover:color-primary w-full cursor-pointer">
      <div className="faq-question flex justify-between py-4" onClick={toggle}>
        <Typography className="text-primary pt-1" variant="caption1-semibold">
          {question}
        </Typography>
        <Icon name={isOpen ? "Minus" : "Plus"} />
      </div>
      {isOpen && (
        <div className="faq-answer pb-4">
          <Typography
            className="text-primary"
            variant="caption1-medium"
            dangerouslySetInnerHTML
          >
            {answer}
          </Typography>
        </div>
      )}
    </div>
  );
};

export function FaqBlock({ items, className }: FaqBlockProps) {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFaqItem = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className={`faq-block flex flex-col items-center ${className ?? ""}`}>
      <h2 className="text-dark-1 font-obviously text-lg font-[570]">FAQ</h2>
      {items.map((item, index) => (
        <FaqItem
          key={index}
          question={item.question}
          answer={item.answer}
          isOpen={index === openIndex}
          toggle={() => toggleFaqItem(index)}
        />
      ))}
    </div>
  );
}
