import { useGetClubV2UsingIdPathParams } from "../../../shared-internal/hooks/use-get-club-v2-using-id-path-params";
import { getClubComponentByCMSBlock } from "../../../shared-internal/utils/get-club-component-by-cms-block";

export const ClubThesisContent = () => {
  const club = useGetClubV2UsingIdPathParams();

  if (!club || !club?.content?.thesis) {
    return;
  }

  return (
    <div className="@container flex flex-col gap-4">
      {club.content.thesis
        .map((block) => getClubComponentByCMSBlock(block, club))
        .map((Block, index) => (
          <Block key={index} />
        ))}
    </div>
  );
};
