import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Button, IconName } from "@tudigo-monorepo/web-tudigo-components";

type FunnelInvestmentNavigationButtonProps = {
  type: "back" | "exit";
  className?: string;
  onClick: () => void;
};

type NavigationItem = Record<
  string,
  {
    label: string;
    singleIcon: IconName;
    iconLeft?: IconName;
    iconRight?: IconName;
  }
>;

const navigationItem: NavigationItem = {
  back: {
    label: "investment_funnel.navigation.button.back",
    singleIcon: "ArrowLeft",
    iconLeft: "ArrowLeft",
  },
  exit: {
    label: "investment_funnel.navigation.button.exit",
    singleIcon: "Cross",
    iconRight: "Cross",
  },
};

export function InvestmentFunnelNavigationButton(
  props: FunnelInvestmentNavigationButtonProps,
) {
  const { type, className, onClick } = props;
  const { t } = useTranslation();

  return (
    <div
      role="link"
      className={cn(
        "stepper-navigation-item flex items-center sm:mb-[calc((104px/2)-(43px/2))] sm:w-[236px]",
        { "justify-end": type === "exit" },
        className,
      )}
    >
      <Button
        onClick={onClick}
        label={t(navigationItem[type].label)}
        variant="tertiary"
        iconLeft={navigationItem[type].iconLeft}
        iconRight={navigationItem[type].iconRight}
        className="!hidden sm:!flex"
      />
      <Button
        onClick={onClick}
        variant="tertiary"
        singleIcon={navigationItem[type].singleIcon}
        className="flex sm:!hidden"
      />
    </div>
  );
}
