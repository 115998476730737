import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";

import { useInvestorTaxExemptions } from "../hooks/use-investor-tax-exemptions";
import { TaxExemptionDocument } from "./tax-exemption-document";

export function SignedTaxExemptionList() {
  const { user } = useUserBusiness();
  const { signedTaxExemptions } = useInvestorTaxExemptions();
  const { t } = useTranslation();

  if (signedTaxExemptions.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col gap-y-6">
      <h3 className="font-montserrat text-primary text-lg font-bold">
        {t("my-profile-exemptions.tax-document-signed.title")}
      </h3>
      {signedTaxExemptions.map((item) => (
        <TaxExemptionDocument key={item.id} user={user} taxExemption={item} />
      ))}
    </div>
  );
}
