import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import {
  Document,
  Organization,
  OrganizationBankAccount,
} from "@tudigo-monorepo/core-tudigo-api-models";

import { getApiClient } from "../../..";

type CreateOrganizationBankAccountParams = {
  organization: {
    id: Organization["id"];
  };
  bankAccountNumber: string;
  holderName: string;
  document: Document;
};

export const createOrganizationBankAccount = async (
  params: CreateOrganizationBankAccountParams,
) => {
  const apiClient = getApiClient();

  const response =
    await apiClient.apiV1.organizationBankAccounts.createOrganizationBankAccount(
      params,
    );

  return response.data;
};

export const useCreateOrganizationBankAccountMutation = (
  options?: UseMutationOptions<
    TudigoResponse<OrganizationBankAccount>,
    TudigoError,
    CreateOrganizationBankAccountParams
  >,
) =>
  useMutation({
    mutationFn: (params: CreateOrganizationBankAccountParams) =>
      createOrganizationBankAccount(params),
    ...options,
  });
