import React from "react";

export function NewsletterSignup() {
  return (
    <div className="flex w-full min-w-72 max-w-full flex-col gap-3 rounded-xl bg-white px-2.5 py-2.5">
      <div className="flex flex-col gap-2 px-8 pt-9">
        <p>{`S'inscrire à la Newsletter`}</p>
        <p className="text-black-900 text-sm">
          {`Des anecdotes et astuces pour ne rien louper sur le monde de
          l'investissement`}
        </p>
      </div>
      <div>
        <iframe
          className="h-[140px] w-full overflow-hidden border-0"
          src="https://gonewsletter.substack.com/embed"
        />
      </div>
    </div>
  );
}
