import React from "react";
import { kebabCase } from "lodash";

import { CMSHeadingBlock } from "@tudigo-monorepo/core-tudigo-api-models";
import {
  Collapse,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
  ThinLayout,
} from "@tudigo-monorepo/web-tudigo-components";

import { useProjectContext } from "../../project.context";

export function ProjectPresentationSummary() {
  const project = useProjectContext();

  if (!project) return null;

  const headingsBlocks = project?.content?.description.filter(
    (i) => i.type === "heading" && i.data.level === 2,
  ) as CMSHeadingBlock[];

  if (!headingsBlocks || !headingsBlocks.length) return null;

  const headings = headingsBlocks.map((item) => ({
    ...item.data,
    id: kebabCase(item?.data?.title as string),
  }));

  const handleChange = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      const rect = element.getBoundingClientRect();
      const offset = 80;
      window.scrollTo({
        top: rect.top + window.pageYOffset - offset,
        behavior: "smooth",
      });
    }
  };

  return (
    <ThinLayout>
      <MobileSummary headings={headings} handleChange={handleChange} />
      <DesktopSummary headings={headings} handleChange={handleChange} />
    </ThinLayout>
  );
}

type SummaryProps = {
  headings: {
    id: string;
    title: string | null;
    level: number | null;
    caption: string | null;
  }[];
  handleChange: (id: string) => void;
};

const MobileSummary = (props: SummaryProps) => {
  const { headings, handleChange } = props;

  return (
    <div className="block sm:hidden">
      <Select onValueChange={(newValue) => handleChange(newValue)}>
        <SelectTrigger className="border-dark-1 rounded-lg bg-white">
          <SelectValue placeholder="SOMMAIRE" />
        </SelectTrigger>
        <SelectContent
          className="bg-white"
          style={{ width: "calc(100% - 1.5rem)" }}
        >
          <SelectGroup>
            <SelectLabel>SOMMAIRE</SelectLabel>
            {headings.map((item) => (
              <SelectItem key={item.id} value={item.id}>
                {item.title}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
};

const DesktopSummary = (props: SummaryProps) => {
  const { headings, handleChange } = props;

  return (
    <div className="hidden sm:block">
      <Collapse
        panelClassName="flex flex-col align-start gap-1"
        headerClassName="py-5"
        items={headings.map((heading) => (
          <button
            className="text-dark-1 w-fit text-sm font-semibold"
            onClick={() => handleChange(heading.id)}
            key={heading.id}
            value={heading.id}
          >
            {heading.title}
          </button>
        ))}
      >
        SOMMAIRE
      </Collapse>
    </div>
  );
};
