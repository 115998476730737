import { Navigate, Route, Routes } from "react-router-dom";

import { SubscribeClubStep1OfferPage } from "../components/subscribe-club-step-1-offer-page/subscribe-club-step-1-offer-page";
import { SubscribeClubStep2SignPage } from "../components/subscribe-club-step-2-sign-page/subscribe-club-step-2-sign-page";
import { SubscribeClubStep3PaymentPage } from "../components/subscribe-club-step-3-payment-page/subscribe-club-step-3-payment-page";
import { SubscribeClubStep4SuccessPage } from "../components/subscribe-club-step-4-success-page/subscribe-club-step-4-success-page";
import { SubscribeClubPage } from "../subscribe-club-page";

export function SubscribeClubRoutesWebapp() {
  return (
    <Routes>
      <Route path="/*" element={<SubscribeClubPage />}>
        <Route path="members/:memberId/steps/*">
          <Route path="offer" element={<SubscribeClubStep1OfferPage />} />
          <Route path="sign" element={<SubscribeClubStep2SignPage />} />
          <Route path="payment" element={<SubscribeClubStep3PaymentPage />} />
          <Route path="success" element={<SubscribeClubStep4SuccessPage />} />
          <Route path="*" element={<Navigate to="offer" replace />} />
        </Route>
      </Route>
    </Routes>
  );
}
