import { UserRole } from "@tudigo-monorepo/core-tudigo-api-models";
import {
  PageEnum,
  SpaceEnum,
  useSpaceBusiness,
} from "@tudigo-monorepo/tudigo-session";

import { ConnectedNavigation } from "./use-connected-navigation";

export const useWmaNavigation = (...args: any[]): ConnectedNavigation => {
  const { currentPagePaths, pagePaths } = useSpaceBusiness();

  return {
    type: UserRole.WMA,
    items: [
      { type: "section", label: "Gerer" },
      {
        type: "link",
        label: "Tableau de bord",
        path: "/wma/dashboard",
        icon: "Dashboard",
      },
      {
        type: "link",
        label: "Clients",
        path: "/wma/customers",
        icon: "Group",
      },
      {
        type: "link",
        label: "Investissements",
        path: "/wma/investments",
        icon: "Stats",
      },
      { type: "section", label: "Investir" },
      {
        type: "link",
        label: "Opportunités",
        path: pagePaths[PageEnum.PROJECTS][SpaceEnum.WMA],
        icon: "List",
        end: true,
      },
      { type: "section", label: "Support" },
      {
        type: "link",
        label: "FAQ",
        path: "questions-answers",
        icon: "Faq",
        target: "_blank",
      },
    ],
  };
};
