import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Title, Toggle } from "@tudigo-monorepo/web-tudigo-components";

const kycSentences = [
  "kyc_funnel.final_summary.consents_section.first_sentence",
  "kyc_funnel.final_summary.consents_section.second_sentence",
  "kyc_funnel.final_summary.consents_section.third_sentence",
];

export interface LegalConsentsSectionProps {
  consented: boolean;
  setConsented: (consented: boolean) => void;
}

export function LegalConsentsSection(props: LegalConsentsSectionProps) {
  const { consented, setConsented } = props;
  const { t } = useTranslation();

  const toggleConsent = () => setConsented(!consented);

  return (
    <div>
      <Title level="h4">
        {t("kyc_funnel.final_summary.consents_section.title")}
      </Title>

      <div className="border-light-2 my-4 border-b" />

      <div className="flex flex-col gap-6">
        <button
          className="flex flex-wrap items-center gap-4"
          onClick={toggleConsent}
        >
          <p className="text-dark-1 w-full text-left font-sans md:w-3/4">
            {t("kyc_funnel.final_summary.consents_section.subtitle")}
          </p>
          <Toggle
            checked={consented}
            name="consent"
            stopClickPropagation={false}
            className="mx-auto"
          />
        </button>

        <ul className="flex flex-col gap-6">
          {kycSentences.map((sentence, index) => (
            <li key={index} className="text-dark-1 font-sans text-sm">
              {t(sentence)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
