import { SelectInput } from "@tudigo-monorepo/core-tudigo-api-models";

import { SelectInputComponent } from "../components/inputs/select-input";
import { FormInputPlugin } from "./form-input-plugin";

export const FormSelectInputPlugin: FormInputPlugin<"select", SelectInput> = {
  type: "select",

  getDefaultValue: () => {
    return null;
  },
  renderingBlock: (input, context) => {
    const { sectionKey, answers, setAnswer, submit, currentPage } = context;
    if (!sectionKey) return;
    const selectedValue = answers[sectionKey][input.name as string] as
      | string
      | number
      | null;

    return (
      <SelectInputComponent
        input={input}
        selectedValue={selectedValue}
        onChange={(value) => {
          const answers = setAnswer(sectionKey, input, value);
          if (currentPage.inputs?.length === 1) {
            submit(answers);
          }
        }}
      />
    );
  },
  canAutoSubmit: true,
  defaultValueIfNull: false,
};
