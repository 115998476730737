import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";

import { fromBlockObjectGetComponent } from "../../../../shared/cms/from-block-object-get-component";
import { LimitedAccess } from "../../../../shared/components/limited-access";
import { useProjectContext } from "../../project.context";
import { ProjectPresentationLegalInfo } from "./project-presentation-legal-info";
import { ProjectPresentationSummary } from "./project-presentation-summary";

export function ProjectPresentation() {
  const project = useProjectContext();
  const { isQualifiedOrWma } = useUserBusiness();

  if (!project) return null;

  if (!isQualifiedOrWma) {
    return (
      <div className="flex flex-col gap-4">
        {project.content?.shortPresentation
          .map((block) =>
            fromBlockObjectGetComponent({
              ...block,
            }),
          )
          .map((Block, index) => <Block key={index} />)}
        <LimitedAccess hideGradient />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <ProjectPresentationSummary />
      {project.content?.description
        .map((block) =>
          fromBlockObjectGetComponent({
            ...block,
          }),
        )
        .map((Block, index) => <Block key={index} />)}
      <ProjectPresentationLegalInfo />
    </div>
  );
}
