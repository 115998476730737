import React from "react";

import {
  Modal,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

type ConfirmationModalProps = {
  showExitDialog: boolean;
  setShowExitDialog: (show: boolean) => void;
  onExit?: () => void;
};
export const ExitConfirmationModal = (props: ConfirmationModalProps) => {
  const { showExitDialog, setShowExitDialog, onExit } = props;

  return (
    <Modal
      title="Confirmation"
      content={
        <div>
          <Title level="h6" h3Brand="brand-2">
            Dernière ligne droite !
          </Title>
          <Typography variant="caption1-regular">
            Vous pourrez compléter votre profil plus tard depuis votre espace
            “mon compte” mais vous devez impérativement valider votre profil
            investisseur pour :
            <ul className="mt-3 list-inside list-disc">
              <li>
                accès aux informations complètes concernant les opportunités
                d’investissement
              </li>
              <li>investir sur un projet</li>
            </ul>
          </Typography>
        </div>
      }
      negativeLabel="Je souhaite quitter tout de même"
      negativeAction={() => {
        onExit && onExit();
      }}
      negativeButtonProps={{
        variant: "ghost",
      }}
      confirmAction={() => {
        setShowExitDialog(false);
      }}
      confirmLabel="Annuler et continuer"
      isOpen={showExitDialog}
      setIsOpen={setShowExitDialog}
      hideCrossButton
    />
  );
};
