import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import queryString from "query-string";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { TudigoError } from "@tudigo-monorepo/core-tudigo-api";
import { OrganizationPerson } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { ORGANIZATIONS } from "@tudigo-monorepo/core-tudigo-utils";
import {
  PageEnum,
  useSpaceBusiness,
  useUserBusiness,
} from "@tudigo-monorepo/tudigo-session";
import { FormContextProvider } from "@tudigo-monorepo/web-feature-form";
import {
  useCreateOrganizationPersonMutation,
  useUpdateOrganizationPerson,
} from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  Button,
  PageHead,
} from "@tudigo-monorepo/web-tudigo-components";

import { useKYCContext } from "../kyc-page";
import { OrganizationPersonFormContent } from "../shared/organization-person-form-content";
import {
  checkIfOrganizationRepresentativeRequirementsAreMet,
  getDefaultOrganizationPersonModel,
} from "../utils/kyc-utils";

export function KycPersonalInformation() {
  const { organization, setOrganization, organizationQueryKey } =
    useKYCContext();
  const { user } = useUserBusiness();
  const navigate = useNavigate();

  const { currentPagePaths } = useSpaceBusiness();
  const kycPagePath = currentPagePaths[
    PageEnum.ORGANIZATION_KYC_FUNNEL
  ].replace(":organizationId", organization.id);

  const [searchParams] = useSearchParams();
  const investProjectUrl =
    (queryString.parse(searchParams.toString())?.investProjectUrl as string) ??
    "";

  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const nextPage =
    organization.type === ORGANIZATIONS.TYPE.COMPANY
      ? "legal-information"
      : "bank-account-information";

  const [organizationRepresentative, setOrganizationRepresentative] =
    useState<OrganizationPerson>(
      organization.organizationRepresentative
        ? {
            ...organization.organizationRepresentative,
            isOrganizationRepresentative: true,
            isUltimateBeneficialOwner: true,
            gender: organization.organizationRepresentative.gender ?? "male",
          }
        : getDefaultOrganizationPersonModel(true, true),
    );

  const [error, setError] = useState<TudigoError | null>(null);
  const [isPhoneNumberError, setIsPhoneNumberError] = useState(false);

  const onMutationSuccess = async () => {
    setError(null);
    setOrganization({
      ...organization,
      organizationRepresentative,
    });

    await queryClient.invalidateQueries({
      queryKey: organizationQueryKey,
    });
    toast((content) => {
      return (
        <Alert
          icon="Checked"
          variant="success"
          size="S"
          close={content.closeToast}
          title={t(
            "kyc_funnel.step_personal_information.alert_on_success.title",
          )}
        />
      );
    });

    if (organization.type === ORGANIZATIONS.TYPE.COMPANY) {
      return navigate(
        `${kycPagePath}/legal-information?${queryString.stringify({
          investProjectUrl,
        })}`,
        { replace: true },
      );
    }

    if (investProjectUrl) {
      return navigate(investProjectUrl);
    }

    navigate(`${kycPagePath}/bank-account-information`, {
      replace: true,
    });
  };

  const { mutateAsync: createOrganizationPerson, isPending: createIsPending } =
    useCreateOrganizationPersonMutation({
      onSuccess: () => {
        onMutationSuccess();
      },
      onError: (err) => {
        setError(err);
      },
    });

  const { mutateAsync: updateOrganizationPerson, isPending: updateIsPending } =
    useUpdateOrganizationPerson({
      onSuccess: () => {
        onMutationSuccess();
      },
      onError: (err) => {
        toast(
          <Alert
            variant="error"
            title="Une erreur est survenue"
            description={
              err?.message ??
              "Une erreur est survenue lors de la mise à jour des informations. Veuillez réessayer."
            }
          />,
        );
        setError(err);
      },
    });

  const updateOrganizationRepresentative = () => {
    if (
      organizationRepresentative !== organization.organizationRepresentative
    ) {
      updateOrganizationPerson({
        organizationPersonId: organizationRepresentative.id,
        data: organizationRepresentative,
      });
    }
  };

  const createOrganizationRepresentative = () => {
    if (!user) {
      return;
    }
    createOrganizationPerson({
      data: {
        organization: {
          id: organization.id,
        },
        ...organizationRepresentative,
        user: {
          id: user.id,
        },
      },
    });
  };

  const handleSubmit = () => {
    if (
      organizationRepresentative === organization.organizationRepresentative
    ) {
      navigate(`${kycPagePath}/${nextPage}`, {
        replace: true,
      });

      return;
    }
    if (organization.organizationRepresentative?.id) {
      updateOrganizationRepresentative();
    } else {
      createOrganizationRepresentative();
    }
  };

  return (
    <>
      {organization.type === ORGANIZATIONS.TYPE.INDIVIDUAL && (
        <PageHead
          chipsTop={[
            {
              label: t("kyc_funnel.step_documents.page.chip.label.individual"),
            },
          ]}
          title={t("kyc_funnel.step_personal_information.page.title")}
          className="py-4"
          withEndLine
        />
      )}
      {organization.type === ORGANIZATIONS.TYPE.COMPANY && (
        <PageHead
          chipsTop={[
            {
              label: t("kyc_funnel.step_documents.page.chip.label.company"),
            },
          ]}
          title={t("kyc_funnel.step_personal_information.page.title")}
          subtitle={t("kyc_funnel.step_personal_information.page.subtitle")}
          className="py-4"
          withEndLine
        />
      )}

      <FormContextProvider
        formData={organizationRepresentative}
        setFormData={setOrganizationRepresentative}
        error={error}
      >
        <div className="flex flex-col gap-6 py-6 lg:mx-6">
          <OrganizationPersonFormContent
            organizationPerson={organizationRepresentative}
            updateOrganizationPerson={setOrganizationRepresentative}
            splitSections
            onPhoneNumberErrorChange={setIsPhoneNumberError}
          />
          <div className="border-light-1 fixed bottom-0 left-0 z-20 flex h-[80px] w-full items-center justify-center gap-x-4 border-t bg-white px-6 sm:h-[104px]">
            <Button
              isLoading={createIsPending || updateIsPending}
              disabled={
                isPhoneNumberError ||
                !checkIfOrganizationRepresentativeRequirementsAreMet(
                  organizationRepresentative,
                )
              }
              className="w-fit px-12"
              variant="primary"
              iconRight="ArrowRight"
              label={t("kyc_funnel.form.button.next.label")}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </FormContextProvider>
    </>
  );
}
