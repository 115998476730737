import { useIntl } from "react-intl";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Button } from "../button";
import { Chip, ChipSize, ChipType } from "../chip";
import { Icon } from "../icons/icon";
import { IconName } from "../icons/icon-name.type";
import { Linkable } from "../linkable";
import { Title } from "../title";
import { Typography } from "../typography";

export type ClubCardTags = {
  label: string;
  type?: ChipType;
  size?: ChipSize;
  iconLeft?: IconName;
};

export type ClubCardProps = {
  description?: string;
  imageUrl?: string;
  tags?: ClubCardTags[];
  title?: string;
  subtitle?: string;
  className?: string;
  locked?: boolean;
  navigateTo?: string;
  onClick?: () => void;
};

export function ClubCard(props: ClubCardProps) {
  const {
    description,
    imageUrl,
    tags,
    title,
    subtitle,
    className,
    locked,
    navigateTo,
    onClick,
  } = props;

  const intl = useIntl();

  const handleClick = () => {
    if (!locked) {
      onClick?.();
    }
  };

  return (
    <Linkable
      navigateTo={navigateTo}
      onClick={handleClick}
      className={cn(
        "club-card flex h-[521px] w-full min-w-[294px] flex-col overflow-hidden rounded-3xl shadow-[0_4px_16px_0_rgba(0,4,32,0.10)]",
        className,
      )}
    >
      <img
        src={imageUrl}
        alt={title}
        loading="lazy"
        className={cn(
          "h-[228px] w-full rounded-tl-3xl rounded-tr-3xl object-cover",
          { "blur-sm": locked },
        )}
      />

      <div className="flex h-full flex-col justify-between p-4">
        {locked ? (
          <div className="flex h-full w-full flex-col items-center justify-center gap-y-4">
            <Title className="text-primary text-[18px]">
              {intl.formatMessage({ id: "component.club_card.locked.label" })}
            </Title>
            <Icon name="Cadenas" primaryColor={themeColors["dark-2"]} />
          </div>
        ) : (
          <div className="flex h-[201px] flex-col gap-y-4">
            <div className="flex flex-wrap items-center gap-1.5">
              {tags?.map((tag, index) => <Chip key={index} {...tag} />)}
            </div>
            <div className="flex flex-col gap-y-1">
              <Title className="text-primary h-[23px] text-[18px] leading-none">
                {title}
              </Title>
              <Typography variant="caption1-semibold">{subtitle}</Typography>
            </div>
            <Typography
              variant="caption1-medium"
              className="border-light-1 min-h-[76px] overflow-hidden border-b pb-4 text-[15px] leading-[18.93px]"
            >
              {description}
            </Typography>
          </div>
        )}

        <div className="mx-auto">
          <Button
            label={intl.formatMessage({
              id: "component.club_card.discover_club_button.label",
            })}
            variant="secondary"
            disabled={locked}
            iconRight="ChevronRight"
            onClick={onClick}
          />
        </div>
      </div>
    </Linkable>
  );
}
