import { Investment } from "@tudigo-monorepo/core-tudigo-api-models";

import { ChangeInvestmentPaymentMethodModal } from "../../pages/investment-manager-page/components/change-investment-payment-method-modal";
import { ChangeInvestmentStatusModal } from "../../pages/investment-manager-page/components/change-investment-status-modal";
import { InvestmentQuickAction } from "../hooks/use-investment-quick-actions";
import { BSRegenerationModal } from "./bs-regeneration-modal";
import { CancelInvestmentModal } from "./cancel-investment-modal";

interface InvestmentQuickActionsModalsProps {
  action: InvestmentQuickAction | null;
  investment: Investment;
  onClose: (action: null) => void;
}

export function InvestmentQuickActionsModals(
  props: InvestmentQuickActionsModalsProps,
) {
  const { action, investment, onClose } = props;

  const handleCloseModal = () => onClose(null);

  if (!action) {
    return null;
  }

  return (
    <>
      <CancelInvestmentModal
        investment={investment}
        isModalOpen={action === InvestmentQuickAction.CANCEL_INVESTMENT}
        setIsModalOpen={handleCloseModal}
      />
      <ChangeInvestmentStatusModal
        investmentId={investment.id}
        isModalOpen={action === InvestmentQuickAction.MARK_AS_PAID}
        setIsModalOpen={handleCloseModal}
        newPaymentStatus="payed"
      />
      <ChangeInvestmentStatusModal
        investmentId={investment.id}
        isModalOpen={action === InvestmentQuickAction.MARK_AS_UNPAID}
        setIsModalOpen={handleCloseModal}
        newPaymentStatus="intent"
      />
      <ChangeInvestmentPaymentMethodModal
        investment={investment}
        isModalOpen={action === InvestmentQuickAction.CHANGE_PAYMENT_METHOD}
        setIsModalOpen={handleCloseModal}
      />
      <BSRegenerationModal
        investment={investment}
        isModalOpen={
          action === InvestmentQuickAction.REGENERATE_SUBSCRIPTION_FORM
        }
        setIsModalOpen={handleCloseModal}
      />
    </>
  );
}
