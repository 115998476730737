import { Navigate, Route } from "react-router-dom";

import { LogoutPage } from "@tudigo-monorepo/web-feature-login";
import { QualificationRoute } from "@tudigo-monorepo/web-feature-qualification";
import {
  ProtectedRoute,
  RetryPage,
} from "@tudigo-monorepo/web-tudigo-security";

import { ClubLeaderRoutes } from "./spaces/club-leader-routes";
import { InvestorRoutes } from "./spaces/investor-routes";
import { ProjectHolderRoutes } from "./spaces/project-holder-routes";
import { WmaRoutes } from "./spaces/wma-routes";

export const useConnectedRoutes = () => {
  return (
    <Route element={<ProtectedRoute />}>
      <Route path="/wma/*" element={<WmaRoutes />} />
      <Route path="/project-holder/*" element={<ProjectHolderRoutes />} />
      <Route path="/club-leader/*" element={<ClubLeaderRoutes />} />
      <Route path="/my-account?/*" element={<InvestorRoutes />} />
      <Route>
        <Route path="/qualification/*" element={<QualificationRoute />} />
        <Route path="/logout" element={<LogoutPage />} />
        <Route path="/retry" element={<RetryPage />} />
        <Route path="*" element={<Navigate to="/dispatch" />} />
      </Route>
    </Route>
  );
};
