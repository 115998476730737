import { CMSEventBlock } from "@tudigo-monorepo/core-tudigo-api-models";
import { getImgSrc } from "@tudigo-monorepo/core-tudigo-utils";
import { Button, Chip } from "@tudigo-monorepo/web-tudigo-components";

type CardEventBlockProps = {
  block: CMSEventBlock;
};

export const CardEventBlock = (props: CardEventBlockProps) => {
  const {
    image,
    topChipLabel,
    description,
    title,
    subDescription,
    ctaLabel,
    ctaUrl,
  } = props.block.data;

  return (
    <div className="border-light-1 flex flex-col justify-start gap-6 rounded-lg border bg-white p-6 md:flex-row">
      {image && (
        <div className="hidden md:block md:max-w-[180px]">
          <img
            alt="avatar"
            className="h-full w-full object-contain"
            src={getImgSrc(image)}
          />
        </div>
      )}
      <div className="flex w-full flex-col items-center justify-between gap-2 md:flex-row">
        <div className="flex flex-col items-center gap-1 md:items-start">
          {topChipLabel && (
            <Chip label={topChipLabel} type="success" iconLeft="DotChip" />
          )}

          <h4 className="font-obviously text-dark-1 font-[570]">{title}</h4>
          <p
            className="font-montserrat text-dark-1 text-sm font-semibold"
            dangerouslySetInnerHTML={{ __html: description }}
          />

          <span
            className="font-montserrat text-dark-1 text-xs font-normal"
            dangerouslySetInnerHTML={{ __html: subDescription }}
          />
        </div>
        <div>
          <Button
            label={ctaLabel}
            onClick={() => window.open(ctaUrl, "_blank")}
          />
        </div>
      </div>
    </div>
  );
};
