import { useEffect } from "react";

import { useSubscribeClub } from "@tudigo-monorepo/web-tudigo-api-client";

import { useGetClubV2UsingIdPathParams } from "../../shared-internal/hooks/use-get-club-v2-using-id-path-params";
import { useGetCurrentClubMember } from "./use-get-current-club-member";

const useGetOrCreateClubMember = () => {
  const club = useGetClubV2UsingIdPathParams();
  const clubId = club?.id;
  const { clubMember, isFetched: isClubMemberFetched } =
    useGetCurrentClubMember(clubId);
  const clubMemberId = clubMember?.id;

  // User is automatically linked to the clubMember by backend during subscription
  const { mutate: subscribeUserToClub } = useSubscribeClub({
    onSuccess: () => {},
    onError: () => {},
  });

  useEffect(() => {
    if (clubId && isClubMemberFetched && !clubMemberId) {
      return subscribeUserToClub({ clubId });
    }
  }, [isClubMemberFetched, clubMemberId, clubId]);

  return clubMember;
};

export { useGetOrCreateClubMember };
