import * as React from "react";
import { ChevronDown, XIcon } from "lucide-react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { Badge } from "../../../old/badge/badge";
import { ButtonRebrand } from "../../../rebrand/ui/button-rebrand";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../command";
import { Popover, PopoverContent, PopoverTrigger } from "../../popover/popover";
import { Separator } from "../../separator";
import { Checkbox } from "../checkbox/checkbox";

interface MultiSelectProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  options: {
    label: string;
    value: string;
  }[];
  onValueChange: (value: string[]) => void;
  placeholder?: string;
  maxCount?: number;
  modalPopover?: boolean;
  className?: string;
  canSelectAll?: boolean;
  defaultValue?: string[];
}

export const MultiSelect = React.forwardRef<
  HTMLButtonElement,
  MultiSelectProps
>(
  (
    {
      options,
      onValueChange,
      placeholder = "Select options",
      maxCount = 5,
      modalPopover = false,
      canSelectAll = false,
      className,
      defaultValue = [],
      ...props
    },
    ref,
  ) => {
    const [selectedValues, setSelectedValues] = React.useState<string[]>(
      defaultValue || [],
    );

    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

    const handleInputKeyDown = (
      event: React.KeyboardEvent<HTMLInputElement>,
    ) => {
      if (event.key === "Enter") {
        setIsPopoverOpen(true);
      } else if (event.key === "Backspace" && !event.currentTarget.value) {
        const newSelectedValues = [...selectedValues];
        newSelectedValues.pop();
        setSelectedValues(newSelectedValues);
        onValueChange(newSelectedValues);
      }
    };

    const toggleOption = (value: string) => {
      const newSelectedValues = selectedValues.includes(value)
        ? selectedValues.filter((v) => v !== value)
        : [...selectedValues, value];
      setSelectedValues(newSelectedValues);
      onValueChange(newSelectedValues);
    };

    const handleClear = () => {
      setSelectedValues([]);
      onValueChange([]);
    };

    const handleTogglePopover = () => {
      setIsPopoverOpen((prev) => !prev);
    };

    const clearExtraOptions = () => {
      const newSelectedValues = selectedValues.slice(0, maxCount);
      setSelectedValues(newSelectedValues);
      onValueChange(newSelectedValues);
    };

    const toggleAll = () => {
      if (selectedValues.length === options.length) {
        handleClear();
      } else {
        const allValues = options.map((option) => option.value);
        setSelectedValues(allValues);
        onValueChange(allValues);
      }
    };

    return (
      <Popover
        open={isPopoverOpen}
        onOpenChange={setIsPopoverOpen}
        modal={modalPopover}
      >
        <PopoverTrigger asChild>
          <ButtonRebrand
            ref={ref}
            {...props}
            onClick={handleTogglePopover}
            variant="ghost"
            className={cn(
              `
              border-input bg-background ring-offset-background focus:ring-ring
              flex h-auto min-h-10 w-full
              items-center justify-between rounded-md border px-2
              py-2 text-sm
              focus:outline-none focus:ring-2
              focus:ring-offset-2
              disabled:cursor-not-allowed disabled:opacity-50
              [&>span]:line-clamp-1`,
              className,
            )}
          >
            {selectedValues.length > 0 ? (
              <div className="flex w-full items-center justify-between">
                <div className="flex flex-wrap items-center gap-2">
                  {selectedValues.slice(0, maxCount).map((value) => {
                    const option = options.find((o) => o.value === value);

                    return (
                      <Badge key={value} compact variant="accent-super-light">
                        {option?.label}
                        <XIcon
                          className="hover:text-primary ml-2 h-4 w-4 cursor-pointer"
                          onClick={(event) => {
                            event.stopPropagation();
                            toggleOption(value);
                          }}
                        />
                      </Badge>
                    );
                  })}
                  {selectedValues.length > maxCount && (
                    <Badge compact variant="accent-super-light">
                      {`+ ${selectedValues.length - maxCount} more`}
                      <XIcon
                        className="hover:text-primary ml-2 h-4 w-4 cursor-pointer"
                        onClick={(event) => {
                          event.stopPropagation();
                          clearExtraOptions();
                        }}
                      />
                    </Badge>
                  )}
                </div>
                <div className="flex items-center justify-between">
                  <XIcon
                    className="text-muted-foreground hover:text-primary mx-2 h-4 cursor-pointer"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleClear();
                    }}
                  />
                  <Separator
                    orientation="vertical"
                    className="flex h-full min-h-6"
                  />
                  <ChevronDown className="text-muted-foreground mx-2 h-4 cursor-pointer" />
                </div>
              </div>
            ) : (
              <div className="mx-auto flex w-full items-center justify-between">
                <span className="text-muted-foreground text-sm font-normal">
                  {placeholder}
                </span>
                <ChevronDown className="text-muted-foreground mx-2 h-4 cursor-pointer" />
              </div>
            )}
          </ButtonRebrand>
        </PopoverTrigger>
        <PopoverContent
          className="w-auto p-0"
          align="start"
          onEscapeKeyDown={() => setIsPopoverOpen(false)}
        >
          <Command>
            <CommandInput
              placeholder="Rechercher..."
              onKeyDown={handleInputKeyDown}
            />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {canSelectAll && (
                  <CommandItem
                    key="all"
                    onSelect={toggleAll}
                    className="cursor-pointer"
                  >
                    <Checkbox
                      checked={selectedValues.length === options.length}
                    />

                    <span className="ml-2">Tout selectionner</span>
                  </CommandItem>
                )}
                {options.map((option) => {
                  const isSelected = selectedValues.includes(option.value);

                  return (
                    <CommandItem
                      key={option.value}
                      onSelect={() => toggleOption(option.value)}
                      className="cursor-pointer"
                    >
                      <Checkbox checked={isSelected} />

                      <span className="ml-2">{option.label}</span>
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  },
);

/*

 <CommandSeparator />

  <CommandGroup>
    <div className="flex items-center justify-between">
      {selectedValues.length > 0 && (
        <>
          <CommandItem
            onSelect={handleClear}
            className="flex-1 cursor-pointer justify-center"
          >
            Tout effacer
          </CommandItem>
          <Separator
            orientation="vertical"
            className="flex h-full min-h-6"
          />
        </>
      )}
      <CommandItem
        onSelect={() => setIsPopoverOpen(false)}
        className="max-w-full flex-1 cursor-pointer justify-center"
      >
        Fermer
      </CommandItem>
    </div>
  </CommandGroup>
 */
