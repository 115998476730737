import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { MenuSection } from "../menu-navigation/menu-navigation";

type MenuSectionProps = {
  item: MenuSection;
  reducedDisplay: boolean;
};

export function MenuNavigationSectionItem(props: MenuSectionProps) {
  const { item, reducedDisplay } = props;

  return (
    <p
      className={cn(
        "text-dark-3 mt-8 text-[13px] font-bold opacity-100 transition-opacity",
        {
          "cursor-default opacity-0": reducedDisplay,
        },
      )}
    >
      {item.label}
    </p>
  );
}
