import { SwitchInput } from "@tudigo-monorepo/core-tudigo-api-models";
import { Toggle } from "@tudigo-monorepo/web-tudigo-components";

type SwitchInputComponentProps = {
  input: SwitchInput;
  selectedValue: boolean;
  onChange: (value: boolean) => void;
};

export function SwitchInputComponent(props: SwitchInputComponentProps) {
  const { input, selectedValue, onChange } = props;

  const getSwitchInputSideText = (choiceIndex: number) => {
    let text: string | null = choiceIndex === 0 ? "Non" : "Oui";
    if (
      input.parameters !== null &&
      input.parameters.choices !== null &&
      input.parameters.choices[choiceIndex] !== null &&
      input.parameters.choices[choiceIndex].label !== null
    ) {
      text = input.parameters.choices[choiceIndex].label as string;
    }

    return text;
  };

  const handleSwitch = (checked: boolean) => {
    onChange(checked);
  };

  return (
    <Toggle
      name="dynamic-form-switch-input"
      checked={selectedValue}
      leftText={getSwitchInputSideText(0)}
      rightText={getSwitchInputSideText(1)}
      onChange={handleSwitch}
    />
  );
}
