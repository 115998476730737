import React from "react";
import { useIntl } from "react-intl";

import { InvestmentDocument } from "@tudigo-monorepo/core-tudigo-api-models";
import {
  Avatar,
  Icon,
  ListElementComposable,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

export function InvestmentDocumentListElement(props: {
  investmentDocument: InvestmentDocument;
}) {
  const investmentDocument = props.investmentDocument;
  const intl = useIntl();

  const investmentDocumentName = () => {
    switch (investmentDocument.type) {
      case "subscription_form":
        return intl.formatMessage({
          id: "investment_document.type.subscription_form",
        });
      case "subscription_form_signed":
        return intl.formatMessage({
          id: "investment_document.type.subscription_form_signed",
        });
      case "title_deed":
        return intl.formatMessage({
          id: "investment_document.type.title_deed",
        });

      default:
        return intl.formatMessage({ id: "investment_document.type.empty" });
    }
  };

  return (
    <ListElementComposable
      onClick={() => window.open(investmentDocument.document?.signedUrl ?? "")}
      className="hover:border-accent-medium cursor-pointer "
    >
      <div className="flex items-center gap-3 md:basis-1/5">
        <Avatar icon={{ name: "Document" }} />
        <Typography variant="body3-semibold" className="text-dark-1">
          {investmentDocument.document?.originalFilename ??
            new Date(investmentDocument.createdAt).toLocaleDateString()}
        </Typography>
      </div>

      <div className="m-auto flex  md:basis-1/5">
        <Typography variant="body3-regular">
          {investmentDocumentName()}
        </Typography>
      </div>

      <div className="ml-auto flex gap-x-2">
        <Typography variant="body3-regular" className="text-dark-1">
          {investmentDocument.document?.mimeTypeExtension?.toUpperCase() ??
            "Document"}
        </Typography>
        <Icon name="Download" />
      </div>
    </ListElementComposable>
  );
}
