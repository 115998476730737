import {
  isFailedProject,
  Project,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { ChipProps } from "@tudigo-monorepo/web-tudigo-components";

const TRANSLATION_BASE_KEY = "projects.back_office.project_chip_status.goal";

const commonProps: Partial<ChipProps> = {
  iconLeft: "DotChip",
  size: "M",
};

const successProps: typeof commonProps = {
  ...commonProps,
  type: "success",
  label: "achieved",
};

const errorProps: typeof commonProps = {
  ...commonProps,
  type: "error",
  label: "failed",
};

export function useProjectGoalChipProps(project?: Project): ChipProps | null {
  const { t } = useTranslation();

  if (project?.status !== "finished" || !project.goal) {
    return null;
  }

  const isError = isFailedProject(project);

  if (isError) {
    return {
      ...errorProps,
      label: t(`${TRANSLATION_BASE_KEY}.${errorProps.label}`),
    };
  }

  return {
    ...successProps,
    label: t(`${TRANSLATION_BASE_KEY}.${successProps.label}`),
  };
}
