import { Route, Routes } from "react-router-dom";

import { ClubLeaderProjectsPage } from "../club-leader-projects-page";

export function ClubLeaderProjectsRouteWebapp() {
  return (
    <Routes>
      <Route path="*" element={<ClubLeaderProjectsPage />} />
    </Routes>
  );
}
