import * as React from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          `
          border-input bg-background ring-offset-background
          placeholder:text-muted-foreground focus-visible:ring-ring
          disabled:bg-light-1 disabled:text-dark-4 flex h-10 w-full rounded-md border px-3 py-2
          text-sm file:border-0 file:bg-transparent
          file:text-sm file:font-medium
          focus-visible:outline-none focus-visible:ring-2
          focus-visible:ring-offset-2
          disabled:cursor-not-allowed
          `,
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);

export { Input };
