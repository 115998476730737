import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import {
  SendInvestmentDocumentSignatureLinkRequest,
  TudigoError,
  TudigoResponse,
} from "@tudigo-monorepo/core-tudigo-api";
import { WMACustomer } from "@tudigo-monorepo/core-tudigo-api-models";

import { getApiClient } from "../../..";

export const sendInvestmentDocumentSignatureLink = async (
  params: SendInvestmentDocumentSignatureLinkRequest,
) => {
  const apiClient = getApiClient();

  const response =
    await apiClient.apiV1.investmentDocuments.sendInvestmentDocumentSignatureLink(
      params.investmentDocumentId,
      params.userId,
    );

  return response.data;
};

export const useSendInvestmentDocumentSignatureLink = (
  options?: UseMutationOptions<
    TudigoResponse<WMACustomer>,
    TudigoError,
    SendInvestmentDocumentSignatureLinkRequest
  >,
) => {
  return useMutation({
    ...options,
    mutationFn: (params: SendInvestmentDocumentSignatureLinkRequest) =>
      sendInvestmentDocumentSignatureLink(params),
    onSuccess: (...props) => {
      options?.onSuccess?.(...props);
    },
  });
};
