import { useProjectContext } from "../../project-page/project.context";
import { ProjectModalities } from "../../project-page/tabs/modalities/project-modalities";
import { ProjectPresentation } from "../../project-page/tabs/presentation/project-presentation";
import { ProjectQa } from "../../project-page/tabs/questions-answers/project-qa";
import { ProjectSimulator } from "../../project-page/tabs/simulator/project-simulator";

export function useProjectPageRoutes() {
  const project = useProjectContext();
  if (!project) return [];
  const isProjectFinished = ["finished", "prefinished"].includes(
    project!.status!,
  );
  const isSimulatorVisible =
    project?.marketingSettings?.simulatorVisibility === "visible";

  return [
    {
      key: "presentation",
      enabled: true,
      path: "",
      label: "Présentation",
      component: ProjectPresentation,
    },
    {
      key: "simulator",
      enabled: !isProjectFinished && isSimulatorVisible,
      path: "simulator",
      label: "Simulateur",
      component: ProjectSimulator,
    },
    {
      key: "modalities",
      enabled: !isProjectFinished,
      path: "modalities",
      label: "Analyse et modalités",
      component: ProjectModalities,
    },
    {
      key: "qa",
      enabled: !isProjectFinished,
      path: "qa",
      label: "Questions et réponses",
      component: ProjectQa,
    },
  ].filter((route) => route.enabled);
}

/*
    {
      enabled: !isProjectFinished,
      path: "news",
      label: "Actualités",
      component: ProjectNews,
    },
 */
