export enum PageEnum {
  HOME = "home",
  DASHBOARD = "dashboard",
  PROJECTS = "projects",
  PROJECT = "project",
  ACCOUNTS = "accounts",
  SETTINGS = "settings",
  ORGANIZATIONS = "organizations",
  ORGANIZATION_KYC_VIEWER = "organization_kyc_viewer",
  ORGANIZATION_KYC_PEA_VIEWER = "organization_kyc_pea_viewer",
  ORGANIZATION_KYC_FUNNEL = "organization_key_funnel",
}

export enum SpaceEnum {
  WMA = "wma",
  CIRCLE_LEADER = "circle_leader",
  PROJECT_HOLDER = "project_holder",
  INVESTOR = "investor",
  ADMIN = "admin",
}

export type SpaceType = `${SpaceEnum}`;
