import { PropsWithChildren, useContext, useEffect } from "react";

import { ContextIntl } from "@tudigo-monorepo/core-tudigo-translations";
import { useGetUserByIdQuery } from "@tudigo-monorepo/web-tudigo-api-client";
import useAuthStore, {
  useImpersonateId,
} from "@tudigo-monorepo/web-tudigo-auth-store";
import {
  datadogIdentify,
  hubspotIdentify,
  mixpanelIdentify,
} from "@tudigo-monorepo/web-tudigo-tracking";

import { useSessionStore } from "./session-store";
import { getUserRoleByPriorityOrder } from "./shared/utils/user-role";

export function SessionProvider(props: PropsWithChildren) {
  const { children } = props;

  const accessToken = useAuthStore((state) => state.accessToken);
  const impersonateId = useImpersonateId();

  const setUser = useSessionStore((state) => state.setUser);
  const setError = useSessionStore((state) => state.setError);
  const setSessionRole = useSessionStore((state) => state.setSessionRole);

  const {
    data: userResponse,
    isLoading,
    refetch: refetchUser,
    error: errorResponse,
  } = useGetUserByIdQuery(
    {
      userId: impersonateId ?? "me",
    },
    { enabled: !!accessToken },
  );

  const { selectLanguage, locale } = useContext(ContextIntl);

  useEffect(() => {
    if (accessToken) {
      refetchUser();
    } else {
      setUser(null);
    }
  }, [refetchUser, setUser, accessToken]);

  useEffect(() => {
    if (userResponse !== undefined) {
      hubspotIdentify(userResponse.email);
      mixpanelIdentify(userResponse.id);
      datadogIdentify(userResponse);
      setUser(userResponse);
      const role = getUserRoleByPriorityOrder(userResponse);
      setSessionRole(role);
    }

    setError(errorResponse);
  }, [userResponse, setUser, setError, setSessionRole, errorResponse]);

  useEffect(() => {
    if (userResponse?.locale && userResponse.locale !== locale) {
      selectLanguage(userResponse.locale);
    }
  }, [userResponse, selectLanguage, locale]);

  if (isLoading) {
    return <div className="absolute left-1/2 top-1/2">Loading session...</div>;
  }

  return children;
}
