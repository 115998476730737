import React from "react";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { useGetUserClubMembers } from "@tudigo-monorepo/web-tudigo-api-client";
import { TudigoCollection } from "@tudigo-monorepo/web-tudigo-collection";
import {
  Icon,
  Table,
  TableCardList,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { useGetMyCirclesColumns } from "../hooks/use-get-my-circles-columns";

type MyCirclesTableProps = {
  status?: string | string[];
};

export const MyCirclesTable = (props: MyCirclesTableProps) => {
  const { user } = useUserBusiness();
  const { t } = useTranslation();
  const { status } = props;

  const columns = useGetMyCirclesColumns();

  const goToMembershipDetail = (clubMembershipId: string) => {
    // TODO TT-1604
  };

  return (
    <div className="m-6">
      <TudigoCollection
        useLocationAsState
        columns={columns}
        useQueryFn={useGetUserClubMembers}
        pathParams={{ userId: user?.id as string, status: "active" }}
        filters={
          status
            ? {
                status: status,
              }
            : undefined
        }
        actions={[
          {
            render: () => <Icon name="ChevronRight" />,
            isAvailable: () => true,
            onClick: (clubMembership) =>
              goToMembershipDetail(clubMembership.id!),
          },
        ]}
        renderEmpty={() => (
          <Typography variant="body3-regular">
            {t("space_investor.my_circles.list.no_result")}
          </Typography>
        )}
        render={(table, props) => {
          return (
            <>
              <Table
                table={table}
                actions={props.actions}
                className="hidden sm:table"
              />
              <TableCardList table={table} className="sm:hidden" />
            </>
          );
        }}
      />
    </div>
  );
};
