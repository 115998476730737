import { Outlet } from "react-router-dom";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { PageHead, Tabs } from "@tudigo-monorepo/web-tudigo-components";

export function MySettingsPage() {
  const { t } = useTranslation();

  return (
    <div>
      <PageHead title="Mes paramètres" withEndLine />

      <Tabs
        tabs={[
          {
            name: t("my_settings.tabs.identifiers"),
            redirectTo: "identifiers",
          },
          {
            name: t("my_settings.tabs.profile_picture"),
            redirectTo: "profile-picture",
          },
          {
            name: t("my_settings.tabs.preferences"),
            redirectTo: "preferences",
          },
        ]}
        className="px-6"
      />

      <Outlet />
    </div>
  );
}
