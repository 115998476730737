import { Link } from "react-router-dom";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { PMS_FILES } from "@tudigo-monorepo/core-tudigo-utils";
import { PageEnum, useSpaceBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  Alert,
  Button,
  Chip,
  Icon,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { useKYCContext } from "../../../kyc-page";
import { KycErrorChip } from "../../../shared/components/kyc-error-chip";
import { KycSectionChipStatus } from "../../../shared/components/kyc-section-chip-status";

export function BankAccountSummarySection() {
  const { organization } = useKYCContext();
  const { t } = useTranslation();
  const bankAccount = organization.organizationBankAccounts[0] ?? {};
  const { currentPagePaths } = useSpaceBusiness();
  const kycPagePath = currentPagePaths[
    PageEnum.ORGANIZATION_KYC_FUNNEL
  ].replace(":organizationId", organization.id);

  const editPagePath = `${kycPagePath}/bank-account-information`;

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex gap-2">
          <Title level="h4">
            {t("kyc_funnel.step_bank_account_information.page.title")}
          </Title>
          <KycSectionChipStatus section="bank-account-informations" />
        </div>
        <Link to={editPagePath}>
          <Button
            label={t("kyc_funnel.final_summary.edit_btn.label")}
            iconLeft="Pen"
            variant="tertiary"
          />
        </Link>
      </div>

      <div className="border-light-2 my-4 border-b" />

      <div className="flex flex-col gap-4">
        {bankAccount.pmsFile?.status === PMS_FILES.STATUS.REJECTED && (
          <Alert
            variant="error"
            size="F"
            icon="Cross"
            title={t(
              "kyc_funnel.step_company_information.collapse_beneficial_owner.alert.title",
            )}
            description={t(
              "kyc_funnel.step_company_information.collapse_beneficial_owner.alert.description",
            )}
          />
        )}

        <div className="flex flex-col gap-2">
          <div className="flex justify-between">
            <Typography variant="caption1-regular">
              {t("kyc_funnel.step_bank_account_information.form.label.iban")}
            </Typography>
            <Typography variant="caption1-semibold">
              {bankAccount?.bankAccountNumber ??
                t("kyc_funnel.final_summary.input_empty")}
            </Typography>
          </div>
          <div className="flex justify-between">
            <Typography variant="caption1-regular">
              {t(
                "kyc_funnel.step_bank_account_information.form.label.holder_name",
              )}
            </Typography>
            <Typography variant="caption1-semibold">
              {bankAccount?.holderName ??
                t("kyc_funnel.final_summary.input_empty")}
            </Typography>
          </div>
          <div className="flex flex-col gap-2">
            <Typography variant="caption1-regular">
              {t(
                "kyc_funnel.step_bank_account_information.form.placeholder.rib",
              )}
            </Typography>
            {bankAccount?.document?.signedUrl ? (
              <div className="border-light-1 my-2 flex  items-center justify-between rounded-lg border p-4">
                <div className="flex items-center gap-4">
                  <Icon name="Document" size="S" />
                  <a
                    href={bankAccount.document.signedUrl}
                    rel="noreferrer"
                    target="_blank"
                    className="text-sm"
                  >
                    {bankAccount.document.originalFilename}
                  </a>
                </div>
                {bankAccount.pmsFile?.status === PMS_FILES.STATUS.REJECTED && (
                  <KycErrorChip />
                )}
              </div>
            ) : (
              <div className="my-2">
                <Chip
                  type="info"
                  label={t(
                    "kyc_funnel.final_summary.file_downloader.empty_file",
                  )}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
