export const imagesMimesTypes = {
  "image/*": [".png", ".gif", ".jpeg", ".jpg"],
};

export const getExtensions = (mimesTypes: Record<string, string[]>) => {
  return Object.values(mimesTypes);
};

export const pdfMimesTypes = {
  "application/pdf": [".pdf"],
};

export const bytesToMB = (bytes: number) => bytes / 1024 / 1024;

export const MBToBytes = (mb: number) => mb * 1024 * 1024;

export function readFileSync(file: File) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.onerror = reject;
    fr.readAsDataURL(file);
  });
}
