import { CountrySelector } from "@tudigo-monorepo/web-tudigo-components";

import {
  extractValueFromPath,
  SmartFieldCommonProps,
  updateObject,
} from "../../index";
import { useFormContext } from "../form-context/form-context";

export function SmartCountryField<T>(props: SmartFieldCommonProps<T>) {
  const { form, handleFormChange, error } = useFormContext();

  const value = extractValueFromPath(form, props.name as string);

  return (
    <CountrySelector
      {...props}
      selectedCountry={value}
      errors={error?.parametersErrors?.[props.name as string] || props.errors}
      setSelectedCountry={(country) => {
        handleFormChange({
          ...updateObject(form, props.name as string, country),
        });
      }}
    />
  );
}
