import { Route, Routes } from "react-router-dom";

import { AppLayout } from "@tudigo-monorepo/web-tudigo-layout";

import { CircleListingRouteWebapp } from "../../pages/circle-listing-page/routes/circle-listing-route.webapp";
import { ClubPageRouteWebapp } from "../../pages/club-page/routes/club-page-route.webapp";
import { SubscribeClubRoutesWebapp } from "../../pages/subscribe-club-page/routes/subscribe-club-routes.webapp";

export function ClubsV2ClubPageRouteWebapp() {
  return (
    <Routes>
      <Route path="/" element={<AppLayout />}>
        <Route path="list/*" element={<CircleListingRouteWebapp />} />
        <Route
          path="subscribe/clubs/:clubId/*"
          element={<SubscribeClubRoutesWebapp />}
        />
        <Route path=":clubId/*" element={<ClubPageRouteWebapp />} />
      </Route>
    </Routes>
  );
}
