import { useFormContext } from "react-hook-form";

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../form";
import { InputConnectedProps } from "../input.type";
import { InputTextarea } from "./input-textarea";

export function InputTextareaConnected({
  name,
  label,
  placeholder,
  className,
  description,
  required,
}: InputConnectedProps) {
  const form = useFormContext();

  if (!form) {
    throw new Error(
      "InputTextarea Connected must be used within a Form component",
    );
  }

  return (
    <FormField
      control={form?.control}
      name={name}
      render={({ field, fieldState }) => (
        <FormItem className={className}>
          {label && <FormLabel required={required}>{label}</FormLabel>}
          <FormControl>
            <InputTextarea placeholder={placeholder} {...field} />
          </FormControl>

          {fieldState?.error ? (
            <FormMessage />
          ) : (
            description && <FormDescription>{description}</FormDescription>
          )}
        </FormItem>
      )}
    />
  );
}
