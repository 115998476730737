import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  InvestmentStatus,
  useCreateInvestmentMutation,
  useGetUserInvestments,
} from "@tudigo-monorepo/web-tudigo-api-client";

const investmentsFields = "id";

export function Dispatcher() {
  const navigate = useNavigate();
  const projectId = useParams().projectId as string;

  const {
    data: ongoingInvestments,
    isPending: isOngoingInvestmentQueryLoading,
  } = useGetUserInvestments({
    pathParams: {
      userId: "me",
    },
    filters: {
      globalStatus: [InvestmentStatus.PENDING],
      projectId: projectId,
    },
    fields: investmentsFields,
  });

  const { mutateAsync: createInvestmentIntent } = useCreateInvestmentMutation();

  useEffect(() => {
    if (!isOngoingInvestmentQueryLoading) {
      if (!ongoingInvestments?.investments[0]) {
        createInvestmentIntent({
          data: {
            project: {
              id: projectId,
            },
          },
        }).then((investmentResponse) =>
          navigate(
            `/projects/${projectId}/invest/${investmentResponse.data.id}`,
            { replace: true },
          ),
        );
      } else {
        navigate(
          `/projects/${projectId}/invest/${ongoingInvestments?.investments[0].id}`,
          { replace: true },
        );
      }
    }
  }, [isOngoingInvestmentQueryLoading]);

  return null;
}
