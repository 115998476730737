import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import { Project } from "@tudigo-monorepo/core-tudigo-api-models";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";
import {
  Button,
  Chip,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

type TasksType = {
  avatar: ReactNode;
  title: string;
  description?: string;
};

type FinalPendingTasksBlockProps = {
  project: Project;
  tasks: TasksType[];
};

export function ProjectMetricsCard(props: { project: Project }) {
  const { project } = props;

  return (
    <div className="border-light-2 relative z-10 mr-12 mt-12 flex flex-col gap-2 rounded-lg border bg-white p-6 drop-shadow-2xl">
      <Chip label="En cours" type="info" />
      <Title level="h4">La levée de fonds</Title>
      <div className="flex items-center gap-6">
        <Title>
          {project.totalAmountCollectedInCents > 0
            ? formatCurrency(project.totalAmountCollectedInCents)
            : "0€"}
        </Title>
        <div className="flex items-center gap-2">
          <Chip
            label={`${project.fundraisingProgressMinGoal}%`}
            type={
              project.fundraisingProgressMinGoal >= 100 ? "success" : "waiting"
            }
          />
          <Typography variant="body3-regular">de l&apos;objectif</Typography>
        </div>
      </div>
    </div>
  );
}

export function FinalPendingTasksBlock(props: FinalPendingTasksBlockProps) {
  const { project, tasks } = props;
  const navigate = useNavigate();

  return (
    <div className="border-light-2 m-6 flex justify-between gap-6 rounded-lg border">
      <div className="flex flex-1 flex-col justify-between gap-4  px-8 py-12">
        <Title level="h2" className="mb-2">
          Et maintenant ?
        </Title>
        {tasks.map((task, index) => (
          <div key={index} className="flex items-center gap-4">
            {task.avatar}
            <div className="flex flex-col">
              <Typography variant="body2-semibold">{task.title}</Typography>
              <Typography variant="body2">{task.description}</Typography>
            </div>
          </div>
        ))}
        <div className="mt-2">
          <Button
            variant="primary"
            label="Mon espace investisseur"
            className="w-fit"
            onClick={() => {
              navigate("/my-account/dashboard");
            }}
          />
        </div>
      </div>
      <div className="relative w-full flex-1">
        <img
          src={project.content?.cover?.url ?? ""}
          alt="project cover"
          className="absolute inset-0 h-full w-full object-cover [clip-path:polygon(07%_0%,100%_0%,100%_100%,0%_100%)]"
        />
        <ProjectMetricsCard project={project} />
      </div>
    </div>
  );
}
