import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { IconName, PageHead } from "@tudigo-monorepo/web-tudigo-components";

import { useOrganizationKycEquitySavingPlanStatusChipProps } from "../../hooks/use-organization-kyc-equity-saving-plan-status-chip-props";
import { useInvestmentAccountContext } from "./investment-account-context";

type InvestmentAccountPageHeadProps = {
  goBackTo?: string;
  goBackIcon?: IconName;
};

export function InvestmentAccountPageHead({
  goBackTo,
  goBackIcon,
}: InvestmentAccountPageHeadProps) {
  const { t } = useTranslation();

  const { equitySavingPlan, organization } = useInvestmentAccountContext();

  const chipStatusProps = useOrganizationKycEquitySavingPlanStatusChipProps(
    equitySavingPlan,
    organization,
  );

  const displayedAccountType =
    equitySavingPlan.type === "pea" ? "PEA" : "PEA-PME";

  return (
    <PageHead
      withEndLine
      title={t("organizations.back_office.organization_page.title")}
      levels={[
        {
          url: goBackTo || "/organizations",
          iconName: goBackIcon || "Folder",
        },
      ]}
      chipsTop={chipStatusProps ? [chipStatusProps] : undefined}
      chipsBottom={[
        {
          label: `N°${equitySavingPlan.accountNumber}`,
        },
        {
          label: displayedAccountType,
          type: "secondary",
        },
      ]}
    />
  );
}
