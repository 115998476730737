import {
  StackAlignItems,
  StackDirection,
  StackJustifyContent,
  StackSpacing,
} from "./stack";

export const getFlexDirection = (direction?: StackDirection) =>
  direction ? `flex-${direction}` : "flex-col";

export const getSpacing = (spacing?: StackSpacing) =>
  spacing ? `gap-${spacing}` : "gap-4";

export const getJustifyContent = (justify?: StackJustifyContent) =>
  justify ? `justify-${justify}` : "justify-start";

export const getAlignItems = (align?: StackAlignItems) =>
  align ? `items-${align}` : "items-start";
