import React from "react";
import { useNavigate } from "react-router-dom";

import { Investment } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { getSignatureRequestSyncById } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  PageHead,
  SignatureIframe,
} from "@tudigo-monorepo/web-tudigo-components";

import { useInvestmentFunnelPageContext } from "../../../pages/investment-funnel-page";
import { ExpandedInvestmentDetailCard } from "../../shared/expanded-investment-detail-card";

function requirementsAreFulfilled(ongoingInvestment: Investment) {
  return (
    ongoingInvestment.commitmentLetterDocument &&
    ongoingInvestment.commitmentLetterDocument.signatureRequest &&
    ongoingInvestment.commitmentLetterDocument.signatureRequest.mySignatureLink
  );
}

export function InvestmentFunnelSignCommitmentLetterContent() {
  const { ongoingInvestment, project, invalidateOngoingInvestment } =
    useInvestmentFunnelPageContext();
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (!ongoingInvestment || !requirementsAreFulfilled(ongoingInvestment))
    return null;

  const onSignatureSuccess = async () => {
    await getSignatureRequestSyncById(
      ongoingInvestment.commitmentLetterDocument!.signatureRequest!.id,
    );
    await invalidateOngoingInvestment();
    navigate(`/projects/${project.id}/invest/${ongoingInvestment.id}`);
  };

  return (
    <>
      <ExpandedInvestmentDetailCard />
      <PageHead
        title={t("investment_funnel.step_sign_commitment_letter.page.title")}
        className="py-4"
        withEndLine
      />

      <div className="signature-iframe h-screen">
        <SignatureIframe
          signatureLink={
            ongoingInvestment.commitmentLetterDocument?.signatureRequest
              ?.mySignatureLink as string
          }
          onSuccess={onSignatureSuccess}
        />
      </div>
    </>
  );
}
