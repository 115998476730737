import { Outlet } from "react-router-dom";

import { PageHead } from "@tudigo-monorepo/web-tudigo-components";

export function WmaDashboardPage() {
  return (
    <div id="dashboard-page" className="w-full">
      <PageHead title="Tableau de bord" />

      <Outlet />
    </div>
  );
}
