import { CMSImageAndTextBlock } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { ImageBlock } from "./image-block";

type ImageAndTextBlockProps = {
  block: CMSImageAndTextBlock;
  editMode?: boolean;
};

export function ImageAndTextBlock(props: ImageAndTextBlockProps) {
  const { block } = props;
  const { image, text, imagePlacement } = block.data;

  return (
    <div
      className={cn("@md:flex-row flex flex-col gap-4", {
        "md:flex-row-reverse": imagePlacement === "right",
      })}
    >
      <div className="@md:w-1/2">
        <ImageBlock
          block={{
            id: block.id,
            type: "images",
            data: {
              type: "single",
              image: image,
              caption: null,
              stretched: null,
              withBorder: null,
              images: null,
              withBackground: null,
            },
          }}
        />
      </div>
      <div className="@md:w-1/2">
        <p
          className="rte-content text-dark-1 text-sm"
          dangerouslySetInnerHTML={{ __html: block.data.text }}
        />
      </div>
    </div>
  );
}
