import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { getAuthClient } from "@tudigo-monorepo/core-tudigo-api";
import { Title } from "@tudigo-monorepo/web-tudigo-components";

export function ConnectPage() {
  const [searchParams] = useSearchParams();
  const authUrl = getAuthClient().authorizeUrl();

  const type = searchParams.get("type");
  const redirectTo = searchParams.get("redirectTo");

  const registerUrl = useMemo(() => {
    const urlObject = new URL(authUrl);
    urlObject.searchParams.append("register", "1");

    return urlObject.toString();
  }, [authUrl]);

  useEffect(() => {
    if (!authUrl) return;
    localStorage.setItem("redirectTo", redirectTo || "/");
    const url = type === "register" ? registerUrl : authUrl;
    document.location.replace(url);
  }, [authUrl, registerUrl, redirectTo, type]);

  return (
    <div className="flex h-screen items-center justify-center">
      <div className="mt-8 flex w-1/2 flex-col items-center gap-6 text-center">
        <Title>Redirection en cours...</Title>
      </div>
    </div>
  );
}

export default ConnectPage;
