import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useGetClubMembers } from "@tudigo-monorepo/web-tudigo-api-client";
import { TudigoCollection } from "@tudigo-monorepo/web-tudigo-collection";
import {
  Icon,
  Table,
  TableCardList,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { UseClubLeaderMembersColumn } from "../hooks/use-club-leader-members-column";

export const ClubLeaderMembersAllContent = () => {
  const columns = UseClubLeaderMembersColumn();
  const clubId = useParams().clubId;
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (!clubId) {
    return null;
  }

  return (
    <div className="p-6">
      <TudigoCollection
        useLocationAsState
        columns={columns}
        useQueryFn={useGetClubMembers}
        pathParams={{ clubId }}
        actions={[
          {
            render: () => <Icon name="ChevronRight" />,
            isAvailable: () => true,
            onClick: (member) => {
              navigate(`/club-leader/${clubId}/member/${member.id}`);
            },
          },
        ]}
        renderEmpty={() => (
          <Typography variant="body3-regular">
            {t("club_leader.page_members.collection.no_result")}
          </Typography>
        )}
        fields="id,role,contract,statistics,user,status,joined_at,selected_price,created_at,updated_at,subscription,club,subscription"
        render={(table, props) => {
          return (
            <>
              <Table
                table={table}
                actions={props.actions}
                className="hidden sm:table"
              />
              <TableCardList table={table} className="sm:hidden" />
            </>
          );
        }}
      />
    </div>
  );
};
