import { CMSTableBlock } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import {
  Icon,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

type TableBlockProps = {
  block: CMSTableBlock;
  editMode?: boolean;
};

export function TableBlock(props: TableBlockProps) {
  const { block } = props;
  const { content, title } = block.data;

  return (
    <div className="border-medium-1 rounded-lg border p-4">
      <table>
        <thead>
          <tr>
            <th>
              <Title level="h4" className="text-left">
                {title}
              </Title>
            </th>
            <th className="flex justify-end">
              <Typography
                variant="body2"
                className="text-accent cursor-pointer"
              >
                En savoir plus
              </Typography>
            </th>
          </tr>
        </thead>

        <tbody>
          {content.map((line, lineIndex) => (
            <tr key={lineIndex}>
              {line.map((row, rowIndex) => {
                const [isLeft, isRight] = [rowIndex === 0, rowIndex === 1];

                return (
                  <td key={rowIndex}>
                    <div
                      className={cn("flex items-center py-2", {
                        "justify-start gap-2": isLeft,
                        "justify-end": isRight,
                      })}
                    >
                      <Typography
                        className={cn({ "text-right font-semibold": isRight })}
                        variant="body2"
                      >
                        {row}
                      </Typography>
                      {isLeft && (
                        <Icon
                          name="Info"
                          size="XS"
                          className="border-medium-1 rounded-full border"
                        />
                      )}
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
