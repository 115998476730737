import {
  Organization,
  OrganizationBankAccount,
  OrganizationPerson,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { PMS_FILES } from "@tudigo-monorepo/core-tudigo-utils";

import { isOrganizationPersonProofOfAddressRequired } from "./kyc-utils";

export const checkIfIdCardRequirementAreMet = (person: OrganizationPerson) => {
  const idCardFrontSide = person.documents.find(
    (doc) => doc.type === PMS_FILES.TYPE.IDENTITY_CARD_FRONT_SIDE,
  );
  const idCardBackSide = person.documents.find(
    (doc) => doc.type === PMS_FILES.TYPE.IDENTITY_CARD_BACK_SIDE,
  );

  const idCard = person.documents.find(
    (doc) => doc.type === PMS_FILES.TYPE.IDENTITY_CARD,
  );

  // id card is rejected and id is not uploaded
  if (
    idCard?.pmsFile?.status === PMS_FILES.STATUS.REJECTED &&
    !(idCardFrontSide?.document?.base64 || idCardBackSide?.document?.base64)
  ) {
    return false;
  }

  return !!(
    (idCardFrontSide?.document?.base64 || idCardFrontSide?.document?.url) &&
    (idCardBackSide?.document?.base64 || idCardBackSide?.document?.url)
  );
};

export const checkIfPassportRequirementAreMet = (
  person: OrganizationPerson,
) => {
  const passport = person.documents.find(
    (doc) => doc.type === PMS_FILES.TYPE.EUROPEAN_PASSPORT,
  );

  const passportFront = person.documents.find(
    (doc) => doc.type === PMS_FILES.TYPE.EUROPEAN_PASSPORT_FRONT_SIDE,
  );

  if (
    passport?.pmsFile?.status === PMS_FILES.STATUS.REJECTED &&
    !passportFront?.document?.base64
  ) {
    return false;
  }

  return !!(passportFront?.document?.base64 || passportFront?.document?.url);
};

const checkIfProofOfAddressRequirementAreMet = (person: OrganizationPerson) => {
  const isProofOfAddressRequired =
    isOrganizationPersonProofOfAddressRequired(person);

  const proofOfAddress = person.documents.find(
    (doc) => doc.type === PMS_FILES.TYPE.PROOF_OF_ADDRESS,
  );

  if (!isProofOfAddressRequired) {
    return true;
  }

  if (
    proofOfAddress?.pmsFile?.status === PMS_FILES.STATUS.REJECTED &&
    !proofOfAddress?.document?.base64
  ) {
    return false;
  }

  return !!(proofOfAddress?.document?.base64 || proofOfAddress?.document?.url);
};

const checkIfCompanyRegistrationRequirementAreMet = (
  organization: Organization,
) => {
  const companyRegistration = organization.documents.find(
    (doc) => doc.type === PMS_FILES.TYPE.COMPANY_REGISTRATION,
  );

  if (
    companyRegistration?.pmsFile?.status === PMS_FILES.STATUS.REJECTED &&
    !companyRegistration?.document?.base64
  ) {
    return false;
  }

  return !!(
    companyRegistration?.document?.base64 || companyRegistration?.document?.url
  );
};

const checkIfStatusRequirementAreMet = (organization: Organization) => {
  const status = organization.documents.find(
    (doc) => doc.type === PMS_FILES.TYPE.STATUS,
  );

  if (
    status?.pmsFile?.status === PMS_FILES.STATUS.REJECTED &&
    !status?.document?.base64
  ) {
    return false;
  }

  return !!(status?.document?.base64 || status?.document?.url);
};

const checkIfUboRegisterRequirementAreMet = (organization: Organization) => {
  const uboRegister = organization.documents.find(
    (doc) => doc.type === PMS_FILES.TYPE.UBO_REGISTER,
  );

  if (
    uboRegister?.pmsFile?.status === PMS_FILES.STATUS.REJECTED &&
    !uboRegister?.document?.base64
  ) {
    return false;
  }

  return !!(uboRegister?.document?.base64 || uboRegister?.document?.url);
};

export function checkIfOrganizationPersonIdentityDocumentsRequirementAreMet(
  person: OrganizationPerson,
  requireProofOfAddress = true,
) {
  return (
    (checkIfIdCardRequirementAreMet(person) ||
      checkIfPassportRequirementAreMet(person)) &&
    (!requireProofOfAddress || checkIfProofOfAddressRequirementAreMet(person))
  );
}

export function checkIfOrganizationDocumentsRequirementsAreMet(
  organization: Organization,
) {
  return (
    checkIfCompanyRegistrationRequirementAreMet(organization) &&
    checkIfStatusRequirementAreMet(organization) &&
    checkIfUboRegisterRequirementAreMet(organization)
  );
}

export const checkIfBankAccountRequirementsAreMet = (
  organizationBankAccount: OrganizationBankAccount,
) => {
  if (
    organizationBankAccount?.pmsFile?.status === PMS_FILES.STATUS.REJECTED &&
    !organizationBankAccount?.document?.base64
  ) {
    return false;
  }

  return !!(
    organizationBankAccount?.bankAccountNumber &&
    organizationBankAccount?.holderName &&
    (organizationBankAccount?.document.base64 ||
      organizationBankAccount?.document.signedUrl)
  );
};

export const filterOrganizationPersonDocumentIdType = (
  organizationPerson: OrganizationPerson,
) => {};
