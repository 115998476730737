import * as React from "react";
import intlTelInput from "intl-tel-input";
import { CheckIcon, ChevronsUpDown } from "lucide-react";
import { mergeRefs } from "react-merge-refs";
import PhoneInputWithCountrySelect, {
  Country,
  FlagProps,
  getCountryCallingCode,
  Props,
  Value,
} from "react-phone-number-input";
import flags from "react-phone-number-input/flags";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../command";
import { Popover, PopoverContent, PopoverTrigger } from "../../popover/popover";
import { ScrollArea } from "../../scroll-area";
import { Input, InputProps } from "../text-input/input";

import "intl-tel-input/build/css/intlTelInput.css";
import "./phone-input.css";

import { ButtonRebrand } from "../../../rebrand/ui/button-rebrand";
import {
  usePhoneNumberErrorMessage,
  validatePhoneNumber,
} from "./phone-input.utils";

export interface PhoneInputError {
  displayed: boolean;
  code: number | null;
}

type PhoneInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "onChange" | "value"
> &
  Omit<Props<typeof PhoneInputWithCountrySelect>, "onChange"> & {
    onChange?: (value: Value) => void;
    onErrorChange?: (error: boolean) => void;
  } & Omit<intlTelInput.Options, "initialCountry">;

const PhoneInput = React.forwardRef<
  React.ElementRef<typeof PhoneInputWithCountrySelect>,
  PhoneInputProps
>((props, ref) => {
  const {
    className,
    value,
    disabled,
    defaultCountry = "FR",
    preferredCountries = ["fr", "ca"],
    nationalMode = true,
    separateDialCode = false,
    onChange,
    onErrorChange,
    ...rest
  } = props;

  const phoneInputRef = React.useRef<HTMLInputElement>(null);

  const [phoneInputState, setPhoneInputState] = React.useState<
    intlTelInput.Plugin | undefined
  >();

  const [error, setError] = React.useState<PhoneInputError>({
    displayed: false,
    code: 0,
  });

  React.useEffect(() => {
    if (phoneInputRef.current && !phoneInputState) {
      const opts = {
        utilsScript: "node_modules/intl-tel-input/build/js/utils.js",
        initialCountry: defaultCountry,
        nationalMode,
        preferredCountries,
        separateDialCode,
        allowDropdown: false,
        autoPlaceholder: "polite",
      } satisfies intlTelInput.Options;

      const state = intlTelInput(phoneInputRef.current, opts);

      setPhoneInputState(state);
    }
  }, [
    defaultCountry,
    nationalMode,
    phoneInputState,
    preferredCountries,
    separateDialCode,
  ]);

  const handleValueChange = (value: Value) => {
    onChange?.((value || "") as Value);

    if (error.displayed && !value?.length) {
      setError({ displayed: false, code: null });
      onErrorChange?.(false);
    }
  };

  const handleCountryChange = (country: Country) => {
    if (country) {
      phoneInputState?.setCountry(country);
    }
  };

  const handleErrorOnBlur = () => {
    if (!phoneInputState) {
      return;
    }

    const { isError, errorCode } = validatePhoneNumber(phoneInputState);
    setError({ displayed: isError, code: errorCode });
    onErrorChange?.(isError);
  };

  return (
    <div className="flex flex-col gap-y-1">
      <PhoneInputWithCountrySelect
        ref={mergeRefs([ref, phoneInputRef as any])}
        className={cn(
          "flex",
          disabled &&
            "bg-light-1 text-dark-4 border-dark-3 overflow-hidden rounded-lg",
          className,
        )}
        flagComponent={FlagComponent}
        inputComponent={InputComponent}
        onBlur={handleErrorOnBlur}
        countrySelectComponent={CountrySelect}
        onCountryChange={handleCountryChange}
        defaultCountry={defaultCountry}
        /**
         * react-phone-number might trigger the onChange event as undefined
         * when a valid phone number is not entered. To prevent this,
         * the value is coerced to an empty string.
         *
         * @param {E164Number | undefined} value - The entered value
         */
        onChange={handleValueChange}
        value={value}
        disabled={disabled}
        focusInputOnCountrySelection
        {...rest}
      />

      <ErrorMessage error={error} phoneInputRef={phoneInputRef} />
    </div>
  );
});

const ErrorMessage = ({
  error,
  phoneInputRef,
}: {
  error: PhoneInputError;
  phoneInputRef: React.RefObject<HTMLInputElement>;
}) => {
  const expectedFormat = phoneInputRef.current?.placeholder;
  const errorMessage = usePhoneNumberErrorMessage(error, expectedFormat);

  if (!error.displayed) {
    return null;
  }

  return (
    <p
      className="text-error inline-block w-fit text-balance font-sans text-xs font-normal"
      dangerouslySetInnerHTML={{
        __html: errorMessage,
      }}
    />
  );
};

const InputComponent = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...props }, ref) => (
    <Input
      ref={ref}
      className={cn("rounded-e-lg rounded-s-none", className)}
      {...props}
    />
  ),
);

type CountrySelectOption = { label: string; value: Country };

type CountrySelectProps = {
  disabled?: boolean;
  value: Country;
  onChange: (value: Country) => void;
  options: CountrySelectOption[];
};

const CountrySelect = ({
  disabled,
  value,
  onChange,
  options,
}: CountrySelectProps) => {
  const handleSelect = React.useCallback(
    (country: Country) => {
      onChange(country);
    },
    [onChange],
  );

  return (
    <Popover>
      <PopoverTrigger asChild>
        <ButtonRebrand
          type="button"
          variant="outline"
          className={cn(
            "flex h-[40px] gap-1 rounded-e-none rounded-s-lg border-r-0 px-3",
          )}
          disabled={disabled}
        >
          <FlagComponent country={value} countryName={value} />
          <ChevronsUpDown
            className={cn(
              "-mr-2 h-4 w-4 opacity-50",
              disabled ? "hidden" : "opacity-100",
            )}
          />
        </ButtonRebrand>
      </PopoverTrigger>
      <PopoverContent className="w-[300px] p-0">
        <Command>
          <CommandList>
            <ScrollArea className="h-72">
              <CommandInput placeholder="Rechercher un pays" />
              <CommandEmpty>Aucun pays trouvé.</CommandEmpty>
              <CommandGroup>
                {options
                  .filter((x) => x.value)
                  .map((option) => (
                    <CommandItem
                      className="gap-2"
                      key={option.value}
                      onSelect={() => handleSelect(option.value)}
                    >
                      <FlagComponent
                        country={option.value}
                        countryName={option.label}
                      />
                      <span className="flex-1 text-sm">{option.label}</span>
                      {option.value && (
                        <span className="text-foreground/50 text-sm">
                          {`+${getCountryCallingCode(option.value)}`}
                        </span>
                      )}
                      <CheckIcon
                        className={cn(
                          "ml-auto h-4 w-4",
                          option.value === value ? "opacity-100" : "opacity-0",
                        )}
                      />
                    </CommandItem>
                  ))}
              </CommandGroup>
            </ScrollArea>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

const FlagComponent = ({ country, countryName }: FlagProps) => {
  const Flag = flags[country];

  return (
    <span className="bg-foreground/20 flex h-3 w-5 overflow-hidden rounded-sm">
      {Flag && <Flag title={countryName} />}
    </span>
  );
};
FlagComponent.displayName = "FlagComponent";

export { PhoneInput };
