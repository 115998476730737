import { SimulatorScenario } from "@tudigo-monorepo/core-tudigo-api-models";

export const SHARES_SCENARIOS = [
  {
    label: "Prudent",
    value: SimulatorScenario.Min,
  },
  {
    label: "Médian",
    value: SimulatorScenario.Medium,
  },
  {
    label: "Optimiste",
    value: SimulatorScenario.Max,
  },
];

export enum SimulatorTaxExemptionsEnum {
  IR = "IR",
  JEIR = "JEIR",
  JEIC = "JEIC",
}

export const SIMULATOR_TAX_EXEMPTION_INFOS = {
  [SimulatorTaxExemptionsEnum.IR]: {
    label: "IR-PME",
    value: 18,
  },
  [SimulatorTaxExemptionsEnum.JEIR]: {
    label: "JEIR",
    value: 50,
  },
  [SimulatorTaxExemptionsEnum.JEIC]: {
    label: "JEIC",
    value: 30,
  },
};
