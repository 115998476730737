import { useNavigate } from "react-router-dom";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Button } from "@tudigo-monorepo/web-tudigo-components";

import { useGetClubV2UsingIdPathParams } from "../../../shared-internal/hooks/use-get-club-v2-using-id-path-params";
import { getClubComponentByCMSBlock } from "../../../shared-internal/utils/get-club-component-by-cms-block";

export const ClubModalitiesContent = () => {
  const club = useGetClubV2UsingIdPathParams();

  const navigate = useNavigate();
  const { t } = useTranslation();

  if (!club || !club?.content?.modalities) {
    return;
  }

  return (
    <div className="flex flex-col gap-6">
      {club.content.modalities
        .map((block) => getClubComponentByCMSBlock(block, club))
        .map((Block, index) => (
          <Block key={index} />
        ))}
      <div className="flex w-full justify-center">
        <Button
          variant="ghost"
          className="w-fit"
          label={t("page_club.modalities_content.cta.label")}
          onClick={() => navigate(`faq`)}
        />
      </div>
    </div>
  );
};
