import React from "react";
import { Bell } from "lucide-react";
import { toast } from "react-toastify";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useCreateCircleMember } from "@tudigo-monorepo/web-tudigo-api-client";
import { Alert, ButtonRebrand } from "@tudigo-monorepo/web-tudigo-components";

import { useGetClubV2UsingIdPathParams } from "../../../../shared-internal/hooks/use-get-club-v2-using-id-path-params";
import { clubV2QueryFields } from "../../../../shared-internal/utils/club-v2-fields";
import {
  ClubLeadersAvatar,
  ClubLeadersStats,
} from "../../club-avatar-leaders/club-leaders-avatar";

export const ClubMembershipModalFollowContent = () => {
  const { t } = useTranslation();
  const club = useGetClubV2UsingIdPathParams();

  const { mutate: createCircleMember } = useCreateCircleMember({
    onSuccess() {
      toast((content) => (
        <Alert
          icon="Info"
          close={content.closeToast}
          variant="success"
          size="S"
          title="Vous suivez désormais ce cercle"
        />
      ));
    },
    onError() {
      toast((content) => (
        <Alert
          icon="Info"
          close={content.closeToast}
          variant="error"
          size="S"
          title="Une erreur est survenue. Veuillez réessayer."
        />
      ));
    },
  });
  const handleFollowAction = () => {
    if (!club) {
      return;
    }
    createCircleMember({
      clubId: club.id!,
      body: {
        status: "following",
      },
      query: {
        fields: clubV2QueryFields,
      },
    });
  };

  return (
    <div className="m-8 flex flex-col items-center justify-center gap-4 rounded-lg border p-8">
      <h3 className="text-primary font-bold">
        {t("page_club.membership_modal.follow_circle_block.title")}
      </h3>
      <div className="flex flex-wrap items-center justify-center gap-2">
        <ClubLeadersAvatar />
        <ClubLeadersStats />
      </div>
      <ButtonRebrand
        className="rounded-full"
        onClick={() => handleFollowAction()}
        variant="black"
      >
        <Bell height={20} className="mr-2" />
        {t("page_club.membership_modal.follow_button.label")}
      </ButtonRebrand>
    </div>
  );
};
