import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { Icon } from "@tudigo-monorepo/web-tudigo-components";

import { useProjectContext } from "../../project.context";
import { SimulatorWidget } from "../../tabs/simulator/sidebar/simulator-widget";

export function SidebarProjectSimulator() {
  const project = useProjectContext();

  return (
    <>
      <header className="mb-4 flex gap-3">
        <Icon
          name="Invest"
          primaryColor={themeColors.success}
          bgColor={themeColors["success-light"]}
        />
        <h2 className="text-primary font-brand">
          Simulez votre plus-value potentielle
        </h2>
      </header>
      {project && <SimulatorWidget />}
    </>
  );
}
