import { useIntl } from "react-intl";

import { Organization } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

import { useOrganizationKycRepresentativeStatusChipProps } from "../../../hooks/use-organization-kyc-representative-status-chip-props";
import { OrganizationCollapse } from "../../organization-collapse/organization-collapse";
import { OrganizationCollapseHeader } from "../../organization-collapse/organization-collapse-header";

type PersonalGeneralSectionProps = {
  organization: Organization;
};

export function PersonalGeneralSection({
  organization,
}: PersonalGeneralSectionProps) {
  const { organizationRepresentative: representative } = organization;

  const { t } = useTranslation();
  const { formatDate } = useIntl();

  const sectionChipStatus =
    useOrganizationKycRepresentativeStatusChipProps(organization);

  if (!representative) return null;

  const collapseItems = [
    {
      label: t(
        "organizations.back_office.organization_page.collapse.personal_informations.lastname",
      ),
      value: representative.lastName,
      chipProps: !representative.lastName ? sectionChipStatus : undefined,
    },
    {
      label: t(
        "organizations.back_office.organization_page.collapse.personal_informations.firstname",
      ),
      value: representative.firstName,
      chipProps: !representative.firstName ? sectionChipStatus : undefined,
    },
    {
      label: t(
        "organizations.back_office.organization_page.collapse.personal_informations.birth_date",
      ),
      value: formatDate(representative.birthDate || undefined),
      chipProps: !representative.birthDate ? sectionChipStatus : undefined,
    },
    {
      label: t(
        "organizations.back_office.organization_page.collapse.personal_informations.birth_country",
      ),
      value: representative.birthLocation?.country,
      chipProps: !representative.birthLocation?.country
        ? sectionChipStatus
        : undefined,
    },
    {
      label: t(
        "organizations.back_office.organization_page.collapse.personal_informations.nationality",
      ),
      value: representative.nationality,
      chipProps: !representative.nationality ? sectionChipStatus : undefined,
    },
    {
      label: t(
        "organizations.back_office.organization_page.collapse.personal_informations.phone",
      ),
      value: representative.phone,
      chipProps: !representative.phone ? sectionChipStatus : undefined,
    },
  ];

  return (
    <OrganizationCollapse items={collapseItems}>
      <OrganizationCollapseHeader
        label={t(
          "organizations.back_office.organization_page.collapse.personal_informations.title",
        )}
        chipProps={sectionChipStatus}
      />
    </OrganizationCollapse>
  );
}
