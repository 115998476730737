import {
  isComingProject,
  Project,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { ProjectTotalAmountCollected } from "../../../ui/project-total-amount-collected/project-total-amount-collected";
import { ProjectProgressBarRebrand } from "../project-progress-bar-rebrand/project-progress-bar-rebrand";
import { ProjectCardRebrandNotify } from "./project-card-rebrand-notify";

export function ProjectCardRebrandFooter(props: { project: Project }) {
  const { project } = props;

  const {
    fundraisingProgressMaxGoal,
    fundraisingProgressMinGoal,
    marketingSettings,
    totalAmountCollectedInCents,
  } = project;

  const amountClassName =
    fundraisingProgressMinGoal >= 100 ? "text-success" : "text-black-primary";

  return (
    <footer>
      {isComingProject(project) && (
        <ProjectCardRebrandNotify project={project} />
      )}

      {!isComingProject(project) &&
        marketingSettings?.gaugeVisibility === "visible" && (
          <ProjectProgressBarRebrand
            progressMaxGoal={fundraisingProgressMaxGoal}
            progressMinGoal={fundraisingProgressMinGoal}
          >
            <ProjectTotalAmountCollected
              className={cn("font-roobert", amountClassName)}
              amount={totalAmountCollectedInCents}
            />
          </ProjectProgressBarRebrand>
        )}
    </footer>
  );
}
