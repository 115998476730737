import {
  WMACustomer,
  WMACustomerStatusEnum,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { ChipProps } from "@tudigo-monorepo/web-tudigo-components";

const TRANSLATION_BASE_KEY =
  "wma.web_app.wma_customers_invitation_list_page.list_element.chip_status";

const commonProps: Partial<ChipProps> = {
  iconLeft: "DotChip",
  size: "M",
};

const waitingProps: ChipProps = {
  ...commonProps,
  label: "waiting_for_confirmation",
  type: "waiting",
};

const successProps: ChipProps = {
  ...commonProps,
  label: "confirmed",
  type: "success",
};

const errorProps: ChipProps = {
  ...commonProps,
  label: "rejected",
  type: "error",
};

const desactivateProps: ChipProps = {
  ...commonProps,
  label: "canceled",
  type: "desactivate",
};

export function useGetWMACustomerInvitationChipStatusProps(
  wmaCustomer?: WMACustomer,
): ChipProps {
  const { t } = useTranslation();

  switch (wmaCustomer?.status) {
    case WMACustomerStatusEnum.INVITED:
      return {
        ...waitingProps,
        label: t(`${TRANSLATION_BASE_KEY}.${waitingProps.label}`),
      };
    case WMACustomerStatusEnum.ACCEPTED:
      return {
        ...successProps,
        label: t(`${TRANSLATION_BASE_KEY}.${successProps.label}`),
      };
    case WMACustomerStatusEnum.REJECTED:
      return {
        ...errorProps,
        label: t(`${TRANSLATION_BASE_KEY}.${errorProps.label}`),
      };
    case WMACustomerStatusEnum.CANCELED:
      return {
        ...desactivateProps,
        label: t(`${TRANSLATION_BASE_KEY}.${desactivateProps.label}`),
      };

    default:
      return {
        ...waitingProps,
        label: t(`${TRANSLATION_BASE_KEY}.${waitingProps.label}`),
      };
  }
}
