import { ClubV2 } from "@tudigo-monorepo/core-tudigo-api-models";
import { BlockCarousel } from "@tudigo-monorepo/web-tudigo-components";

export function CircleMediasCarouselBlock({ club }: { club: ClubV2 }) {
  if (!club || !club.content?.presentationMedias) {
    return null;
  }

  const medias = club.content.presentationMedias;

  return (
    <div className="my-4">
      <BlockCarousel medias={medias} />
    </div>
  );
}
