import React from "react";
import { EllipsisVertical, Pen, Trash } from "lucide-react";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  ButtonRebrand,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@tudigo-monorepo/web-tudigo-components";

import { useDeleteNews } from "../hooks/useDeleteNews";
import { NewsUpdateModal } from "../update/news-update-modal";

export function NewsItemMenu() {
  const handleDeleteProjectNews = useDeleteNews();
  const { t } = useTranslation();

  const [showUpdateModal, setShowUpdateModal] = React.useState(false);

  return (
    <>
      <NewsUpdateModal
        isDialogOpen={showUpdateModal}
        setEditDialogOpen={setShowUpdateModal}
      />

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <ButtonRebrand variant="ghost">
            <EllipsisVertical className="h-5 w-5" />
          </ButtonRebrand>
        </DropdownMenuTrigger>
        <DropdownMenuContent side="left">
          <DropdownMenuItem onClick={() => setShowUpdateModal(true)}>
            <Pen className="h-5 w-5" />
            {t("users.back_office.user_list.table.row.actions.update")}
          </DropdownMenuItem>
          <DropdownMenuItem onClick={handleDeleteProjectNews}>
            <Trash className="h-5 w-5" />
            {t("users.back_office.user_list.table.row.actions.delete")}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
