import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { TudigoError } from "@tudigo-monorepo/core-tudigo-api";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { DeleteUserModal } from "@tudigo-monorepo/web-feature-users";
import {
  useRequestChangeUserEmailMutation,
  useRequestChangeUserPasswordMutation,
} from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  Button,
  CountrySelector,
  InputEmail,
  InputPassword,
  Label,
  PhoneInput,
  TextField,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

export function MySettingIdentifiersContent() {
  const { t } = useTranslation();
  const { user } = useUserBusiness();
  const navigate = useNavigate();

  const [newEmail, setNewEmail] = React.useState<string>(user?.email ?? "");
  const [oldPassword, setOldPassword] = React.useState<string>("");
  const [newPassword, setNewPassword] = React.useState<string>("");
  const [submitError, setSubmitError] = React.useState<TudigoError | null>(
    null,
  );
  const [country, setCountry] = React.useState(
    user?.individualOrganization?.individualInformation?.address?.country ??
      "FR",
  );
  const [hasRequestedChangeEmail, setHasRequestedChangeEmail] =
    React.useState(false);
  const [deleteUserModalOpen, setDeleteUserModalOpen] = React.useState(false);

  const { mutate: requestChangeUserEmail, isPending: isEmailLoading } =
    useRequestChangeUserEmailMutation({
      onSuccess: () => {
        setHasRequestedChangeEmail(true);
        toast((content) => (
          <Alert
            icon="Checked"
            close={content.closeToast}
            variant="success"
            size="S"
            title={t(
              "my-settings.my_setting_identifiers_content.alerts.change_email.success.title",
            )}
            description={t(
              "my-settings.my_setting_identifiers_content.alerts.change_email.success.message",
            )}
          />
        ));
      },
      onError(error) {
        setSubmitError(error);
      },
    });

  const { mutate: requestChangeUserpassword, isPending: isPasswordLoading } =
    useRequestChangeUserPasswordMutation({
      onSuccess: () => {
        toast((content) => (
          <Alert
            icon="Checked"
            close={content.closeToast}
            variant="success"
            size="S"
            title={t(
              "my-settings.my_setting_identifiers_content.alerts.change_password.success.title",
            )}
            description={t(
              "my-settings.my_setting_identifiers_content.alerts.change_password.success.message",
            )}
          />
        ));
        setOldPassword("");
        setNewPassword("");
      },
      onError: (error) => {
        setSubmitError(error);
      },
    });

  const onChangeEmailSubmit = () => {
    setSubmitError(null);
    requestChangeUserEmail({
      userId: user?.id ?? "me",
      data: {
        newEmail: newEmail,
      },
    });
  };

  const onChangePasswordSubmit = () => {
    setSubmitError(null);
    requestChangeUserpassword({
      userId: user?.id ?? "me",
      data: {
        oldPassword: oldPassword,
        newPassword: newPassword,
      },
    });
  };

  return (
    <div className="space-y-6 p-6">
      <Title h3Brand="brand-2" level="h3" className="text-primary">
        {t(
          "my-settings.my_setting_identifiers_content.block_my_informations.title",
        )}
      </Title>
      <div className="space-y-6 rounded-lg border px-10 py-8">
        <TextField
          className="w-[288px]"
          required
          value={user?.lastName}
          label={t(
            "my-settings.my_setting_identifiers_content.block_my_informations.lastName",
          )}
          disabled
        />
        <TextField
          className="w-[288px]"
          required
          label={t(
            "my-settings.my_setting_identifiers_content.block_my_informations.firstname",
          )}
          disabled
          value={user?.firstName}
        />

        <div className="w-[288px]">
          <Label>
            {t(
              "my-settings.my_setting_identifiers_content.block_my_informations.phoneNumber",
            )}
          </Label>
          <PhoneInput required value={user?.phoneNumber ?? ""} disabled />
        </div>

        <div className="w-[288px]">
          <CountrySelector
            selectedCountry={country}
            label={t(
              "my-settings.my_setting_identifiers_content.block_my_informations.country",
            )}
            required
            setSelectedCountry={(country) => setCountry(country)}
            disabled
          />
        </div>
      </div>

      <Title h3Brand="brand-2" level="h3" className="text-primary">
        {t("my-settings.my_setting_identifiers_content.block_email.title")}
      </Title>
      <div className="space-y-6 rounded-lg border px-10 py-8">
        {hasRequestedChangeEmail && (
          <Alert
            icon="Checked"
            variant="success"
            size="S"
            title={t(
              "my-settings.my_setting_identifiers_content.alerts.change_email.success.title",
            )}
            description={t(
              "my-settings.my_setting_identifiers_content.alerts.change_email.success.message",
            )}
            contentClassName="w-full"
          />
        )}

        <InputEmail
          className="w-[288px]"
          label={t(
            "my-settings.my_setting_identifiers_content.alerts.change_email.success.title",
          )}
          required
          errors={submitError?.parametersErrors?.["newEmail"]}
          placeholder="email@tudigo.co"
          value={newEmail}
          onChange={(e) => setNewEmail(e.currentTarget.value)}
        />
        <Button
          isLoading={isEmailLoading}
          variant="primary"
          disabled={
            isEmailLoading || newEmail.length === 0 || newEmail === user?.email
          }
          label={t(
            "my-settings.my_setting_identifiers_content.block_email.button_submit.label",
          )}
          onClick={onChangeEmailSubmit}
        />
      </div>
      <Title h3Brand="brand-2" level="h3" className="text-primary">
        {t("my-settings.my_setting_identifiers_content.block_password.title")}
      </Title>
      <div className="space-y-6 rounded-lg border px-10 py-8">
        <InputPassword
          className="w-[288px]"
          label={t(
            "my-settings.my_setting_identifiers_content.block_password.input.old_password.label",
          )}
          required
          errors={submitError?.parametersErrors?.["oldPassword"]}
          placeholder="***"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.currentTarget.value)}
        />
        <InputPassword
          className="w-[288px]"
          label={t(
            "my-settings.my_setting_identifiers_content.block_password.input.new_password.label",
          )}
          required
          errors={submitError?.parametersErrors?.["newPassword"]}
          placeholder="***"
          value={newPassword}
          onChange={(e) => setNewPassword(e.currentTarget.value)}
        />
        <Button
          isLoading={isPasswordLoading}
          variant="primary"
          disabled={
            isPasswordLoading ||
            oldPassword.length === 0 ||
            newPassword.length === 0
          }
          label={t(
            "my-settings.my_setting_identifiers_content.block_password.button_submit.label",
          )}
          onClick={onChangePasswordSubmit}
        />
      </div>

      <div className="space-y-6 rounded-lg border px-10 py-8">
        <Typography variant="body3-regular" className="text-error">
          {t(
            "my-settings.my_setting_identifiers_content.block_delete_account.title",
          )}
        </Typography>
        <Button
          onClick={() => setDeleteUserModalOpen(true)}
          iconRight="Delete"
          variant="destructive"
          label={t(
            "my-settings.my_setting_identifiers_content.block_delete_account.button_submit.label",
          )}
        />
        <DeleteUserModal
          user={user}
          isOpen={deleteUserModalOpen}
          onClose={setDeleteUserModalOpen}
          onSuccess={() => {
            setTimeout(() => {
              navigate("/dispatch");
            }, 2000);
          }}
        />
      </div>
    </div>
  );
}
