import { createColumnHelper } from "@tanstack/react-table";
import { useIntl } from "react-intl";

import { RepaymentPeriod } from "@tudigo-monorepo/core-tudigo-api-models";
import { Chip, Typography } from "@tudigo-monorepo/web-tudigo-components";

export function useDebtorRepaymentsTableColumns() {
  const intl = useIntl();
  const columnHelper = createColumnHelper<RepaymentPeriod>();

  return [
    columnHelper.accessor("periodIndex", {
      header: intl.formatMessage({
        id: "my_repayments_page.table.columns.index",
      }),
      cell: (info) => (
        <Typography variant="body3-regular">{info.getValue()}</Typography>
      ),
    }),
    columnHelper.accessor("dueDate", {
      header: "Date",
      cell: (info) => (
        <Typography variant="body3-regular">
          {info.getValue() != null
            ? new Date(info.getValue() as string).toLocaleDateString()
            : "-"}
        </Typography>
      ),
    }),
    columnHelper.accessor("interestAmountInCents", {
      header: intl.formatMessage({
        id: "my_repayments_page.table.columns.gross_interest",
      }),
      cell: (info) => (
        <Typography variant="body3-regular">
          {intl.formatNumber((info.getValue() as number) / 100, {
            style: "currency",
            currency: "EUR",
          })}
        </Typography>
      ),
    }),
    columnHelper.accessor("totalCreditorTaxes.detailsInCents.ir", {
      header: "IR",
      cell: (info) => {
        return (
          <Typography variant="body3-regular">
            {intl.formatNumber(((info.getValue() as number) ?? 0) / 100, {
              style: "currency",
              currency: "EUR",
            })}
          </Typography>
        );
      },
    }),
    columnHelper.accessor("totalCreditorTaxes.detailsInCents.csg", {
      header: "CSG",
      cell: (info) => (
        <Typography variant="body3-regular">
          {intl.formatNumber(((info.getValue() as number) ?? 0) / 100, {
            style: "currency",
            currency: "EUR",
          })}
        </Typography>
      ),
    }),
    columnHelper.accessor("capitalAmountInCents", {
      header: intl.formatMessage({
        id: "my_repayments_page.table.columns.gross_capital",
      }),
      cell: (info) => (
        <Typography variant="body3-regular">
          {intl.formatNumber((info.getValue() as number) / 100, {
            style: "currency",
            currency: "EUR",
          })}
        </Typography>
      ),
    }),
    columnHelper.accessor("penaltyAmountInCents", {
      header: intl.formatMessage({
        id: "my_repayments_page.table.columns.penalties",
      }),
      cell: (info) => (
        <Typography variant="body3-regular">
          {intl.formatNumber((info.getValue() as number) / 100, {
            style: "currency",
            currency: "EUR",
          })}
        </Typography>
      ),
    }),
    columnHelper.accessor("totalAmountInCents", {
      header: "Total brut",
      cell: (info) => (
        <Typography variant="body3-regular">
          {intl.formatNumber((info.getValue() as number) / 100, {
            style: "currency",
            currency: "EUR",
          })}
        </Typography>
      ),
    }),
    columnHelper.accessor("totalAmountInCents", {
      header: "Total net",
      cell: (info) => {
        const grossAmount = info.getValue() as number;
        const totalTaxes =
          (info?.row?.original?.totalCreditorTaxes?.totalInCents as number) ??
          0;
        const netAmount = grossAmount - totalTaxes;

        return (
          <Typography variant="body3-regular">
            {intl.formatNumber((netAmount as number) / 100, {
              style: "currency",
              currency: "EUR",
            })}
          </Typography>
        );
      },
    }),
    columnHelper.accessor("status", {
      header: intl.formatMessage({
        id: "my_repayments_page.table.columns.status",
      }),
      cell: (info) => ({
        render: () => {
          switch (info.getValue()) {
            case "remaining":
              return (
                <Chip
                  type="warning"
                  iconLeft="DotChip"
                  label="À venir"
                  size="M"
                />
              );
            case "waiting_approval":
              return (
                <Chip
                  type="warning"
                  iconLeft="DotChip"
                  label="En attente de validation"
                  size="M"
                />
              );
            case "paid":
            case "approved":
              if (
                info.row.original.penaltyAmountInCents &&
                info.row.original.penaltyAmountInCents > 0
              ) {
                return (
                  <Chip
                    type="info"
                    iconLeft="DotChip"
                    label="Versé en retard"
                    size="M"
                  />
                );
              }

              return (
                <Chip
                  type="success"
                  iconLeft="DotChip"
                  label="Versé"
                  size="M"
                />
              );
            default:
              return (
                <Chip
                  type="secondary"
                  iconLeft="DotChip"
                  label={intl.formatMessage({
                    id: "repayments.status.unknown",
                  })}
                  size="M"
                />
              );
          }
        },
      }),
    }),
  ];
}
