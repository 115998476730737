import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import {
  ChangePasswordRequestBody,
  TudigoError,
  TudigoResponse,
} from "@tudigo-monorepo/core-tudigo-api";
import { User } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient, getQueryDetailKey } from "../../../";

type RequestParams = {
  userId: User["id"] | "me";
  data: ChangePasswordRequestBody;
  query?: { fields?: string };
};

type MutationOptions = UseMutationOptions<
  TudigoResponse<void>,
  TudigoError,
  RequestParams
>;

export const requestChangeUserPassword = async (params: RequestParams) => {
  const apiClient = getApiClient();

  const { userId, data, query } = params;

  const response = await apiClient.apiV1.users.requestChangeUserPassword(
    userId,
    data,
    query,
  );

  return response.data;
};

export const useRequestChangeUserPasswordMutation = (
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (params: RequestParams) => requestChangeUserPassword(params),
    onSuccess: (...props) => {
      const [, params] = props;

      queryClient.invalidateQueries({
        queryKey: getQueryDetailKey({
          key: QUERY_KEYS.USERS,
          id: params.userId,
        }),
      });

      options?.onSuccess?.(...props);
    },
  });
};
