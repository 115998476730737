import React from "react";
import { useIntl } from "react-intl";

import { OrganizationPerson } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Chip, Typography } from "@tudigo-monorepo/web-tudigo-components";

import { KycCollapseFormGroup } from "../../../shared/components/kyc-collapse-form-group";
import { KycErrorChip } from "../../../shared/components/kyc-error-chip";
import { checkIfBeneficialOwnerPersonalInformationsRequirementsAreMet } from "../../../utils/kyc-utils";

type UboSummaryCollapseListElementProps = {
  beneficialOwner: OrganizationPerson;
};

export function UboSummaryCollapseListElement(
  props: UboSummaryCollapseListElementProps,
) {
  const { beneficialOwner } = props;
  const { t } = useTranslation();
  const intl = useIntl();

  const isError =
    !checkIfBeneficialOwnerPersonalInformationsRequirementsAreMet(
      beneficialOwner,
    );

  return (
    <KycCollapseFormGroup
      content={
        <div className="flex flex-col gap-2">
          <div className="flex justify-between">
            <Typography variant="caption1-regular">
              {t("kyc_funnel.organization_person_form.label.civility")}
            </Typography>
            <Typography variant="caption1-semibold">
              {beneficialOwner.gender === "male"
                ? t("kyc_funnel.organization_person_form.label.gender.m")
                : t("kyc_funnel.organization_person_form.label.gender.f")}
            </Typography>
          </div>
          <div className="flex justify-between">
            <Typography variant="caption1-regular">
              {t("kyc_funnel.organization_person_form.label.firstname")}
            </Typography>
            <Typography variant="caption1-semibold">
              {beneficialOwner.firstName}
            </Typography>
          </div>
          <div className="flex justify-between">
            <Typography variant="caption1-regular">
              {t("kyc_funnel.organization_person_form.label.lastname")}
            </Typography>
            <Typography variant="caption1-semibold">
              {beneficialOwner.lastName}
            </Typography>
          </div>
          <div className="flex justify-between">
            <Typography variant="caption1-regular">
              {t("kyc_funnel.organization_person_form.label.birth_detail")}
            </Typography>
            <Typography variant="caption1-semibold">
              {(beneficialOwner.birthDate && beneficialOwner.birthLocation && (
                <>
                  {new Date(beneficialOwner.birthDate).toLocaleDateString(
                    intl.locale,
                  )}{" "}
                  à {beneficialOwner.birthLocation.city},{" "}
                  {beneficialOwner.birthLocation.country}
                </>
              )) ||
                "non renseigné"}
            </Typography>
          </div>
          <div className="flex justify-between">
            <Typography variant="caption1-regular">
              {t("kyc_funnel.organization_person_form.label.nationality")}
            </Typography>
            <Typography variant="caption1-semibold">
              {beneficialOwner.nationality}
            </Typography>
          </div>
          <div className="flex justify-between">
            <Typography variant="caption1-regular">
              {t("kyc_funnel.organization_person_form.label.phone")}
            </Typography>
            <Typography variant="caption1-semibold">
              {beneficialOwner.phone}
            </Typography>
          </div>
          <div className="flex justify-between">
            <Typography variant="caption1-regular">
              {t("kyc_funnel.organization_person_form.label.legal_address")}
            </Typography>
            <Typography variant="caption1-semibold">
              {beneficialOwner.address?.street1},{" "}
              {beneficialOwner.address?.postalCode}{" "}
              {beneficialOwner.address?.city} {beneficialOwner.address?.country}
            </Typography>
          </div>
        </div>
      }
      title={`${t(
        "kyc_funnel.step_company_information.collapse_beneficial_owner.title",
      )} ${
        beneficialOwner.sharesCount
          ? `${beneficialOwner.sharesCount}%`
          : t(
              "kyc_funnel.step_company_information.collapse_beneficial_owner.shares_count.undefined.label",
            )
      }`}
      subtitle={`${
        beneficialOwner.firstName
      } ${beneficialOwner.lastName.toUpperCase()}`}
      chipChildren={
        <div className="flex items-center gap-x-2">
          {beneficialOwner.isOrganizationRepresentative && (
            <Chip
              type="info"
              label={t(
                "kyc_funnel.step_company_information.collapse_beneficial_owner.representative",
              )}
            />
          )}
          {isError && <KycErrorChip />}
        </div>
      }
    />
  );
}
