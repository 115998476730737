import { useLocation, useParams } from "react-router-dom";

import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  InvestmentAccountPageHead,
  InvestmentAccountProvider,
  InvestmentAccountViewer,
  organizationRequestFields,
} from "@tudigo-monorepo/web-feature-organizations";
import { useGetOrganizationByIdQuery } from "@tudigo-monorepo/web-tudigo-api-client";

export function MyProfileInvestmentAccountDetailsPage() {
  const { user } = useUserBusiness();
  const { type } = useParams();
  const location = useLocation();

  const { data: individualOrganization } = useGetOrganizationByIdQuery(
    user?.individualOrganization?.id as string,
    {
      fields: organizationRequestFields,
    },
  );

  const equitySavingPlan =
    individualOrganization?.equitySavingPlan ?? undefined;
  const smallBusinessEquitySavingPlan =
    individualOrganization?.smallBusinessEquitySavingPlan ?? undefined;
  const currentInvestmentAccount =
    type === "pea" ? equitySavingPlan : smallBusinessEquitySavingPlan;

  if (!currentInvestmentAccount) {
    return null;
  }

  return (
    <InvestmentAccountProvider
      equitySavingPlan={currentInvestmentAccount}
      organization={individualOrganization}
    >
      <InvestmentAccountPageHead
        goBackTo={location.state?.from}
        goBackIcon="Profile"
      />
      <InvestmentAccountViewer />
    </InvestmentAccountProvider>
  );
}
