import React from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Title, Typography } from "@tudigo-monorepo/web-tudigo-components";

import { useInvestmentFunnelPageContext } from "../../../../../pages/investment-funnel-page";

export function TaxExemptionEligibilitySection() {
  const { project } = useInvestmentFunnelPageContext();
  const { t } = useTranslation();

  const taxExemptionsList = project?.taxExemptionMechanics
    ?.map((item) => t(`component.project_card.list.item.tax_exemption.${item}`))
    .join(", ");

  return (
    <div className="tax-exemption-eligibility mb-6">
      <Title level="h2">Suis-je éligible à la défiscalisation ?</Title>

      <div className="border-light-2 my-4 border-b" />

      <Typography variant="body3-regular">
        Le projet dans lequel vous souhaitez investir est éligible aux
        mécanismes de défiscalisation suivants: {taxExemptionsList} . Pour
        savoir si vous êtes éligible à un de ces mécanismes, n&apos;hésitez pas
        à faire le point sur votre situation personnelle en prenant rendez-vous
        avec une conseillère ou un conseiller en investissement participatif ou
        en consultant <Link to="https://tudigo.co">notre FAQ.</Link>
      </Typography>
    </div>
  );
}
