/* eslint-disable */

export function getYouTubeThumbnail(url: any): string {
  if (!url || typeof url !== "string") {
    return "";
  }

  // Regular expression to extract video ID from various YouTube URL formats

  const regex =
    /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
  const match = url.match(regex);

  const video = match?.[1] ?? "";

  return "http://img.youtube.com/vi/" + video + "/0.jpg";
}
