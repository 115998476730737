import React from "react";
import { Check, Star } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { ButtonRebrand } from "@tudigo-monorepo/web-tudigo-components";

import { useGetClubV2UsingIdPathParams } from "../../../../shared-internal/hooks/use-get-club-v2-using-id-path-params";
import {
  ClubLeadersAvatar,
  ClubLeadersStats,
} from "../../club-avatar-leaders/club-leaders-avatar";

export const ClubMembershipModalPremiumContent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const circle = useGetClubV2UsingIdPathParams();

  if (!circle) {
    return;
  }

  return (
    <div className="bg-success-light m-8 flex flex-col items-center justify-center gap-6 rounded-lg border p-8">
      <h3 className="text-primary font-bold">
        {t("page_club.membership_modal.subscribe_circle_block.title")}
      </h3>
      <div className="flex w-full flex-col justify-start gap-2">
        {circle.content?.premiumAdvantages.map((advantage, index) => (
          <div className="flex gap-4" key={index}>
            <Check height={20} color="#219F63" />
            <div className="flex flex-col gap-2">
              <span className="text-primary text-sm font-semibold">
                {advantage.title}
              </span>
              <span className="text-dark-1 text-sm">
                {advantage.description}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-wrap items-center justify-center gap-2">
        <ClubLeadersAvatar />
        <ClubLeadersStats />
      </div>
      <ButtonRebrand
        onClick={() => navigate(`/clubs-v2/subscribe/clubs/${circle.id}/`)}
        className="rounded-full"
        variant="success"
      >
        <Star height={20} className="mr-2" fill="white" />
        {t("page_club.membership_modal.subscribe_button.label")}
      </ButtonRebrand>
    </div>
  );
};
