import { ClubMember } from "@tudigo-monorepo/core-tudigo-api-models";
import { TFunction } from "@tudigo-monorepo/core-tudigo-translations";
import { Chip } from "@tudigo-monorepo/web-tudigo-components";

export function getClubMemberStatusChip(
  status: ClubMember["status"],
  t: TFunction,
) {
  switch (status) {
    case "draft":
      return (
        <Chip label={t("club_member.status.draft")} size="S" type="secondary" />
      );
    case "active":
      return (
        <Chip
          label={t("club_member.status.active")}
          size="S"
          iconLeft="Star"
          type="secondary"
        />
      );
    case "deleted":
      return (
        <Chip label={t("club_member.status.deleted")} size="S" type="error" />
      );
    case "following":
      return (
        <Chip
          label={t("club_member.status.following")}
          size="S"
          type="secondary"
        />
      );
    case "cancelled":
      return (
        <Chip label={t("club_member.status.cancelled")} size="S" type="info" />
      );
    default:
      return (
        <Chip label={t("club_member.status.unknown")} size="S" type="warning" />
      );
  }
}
