import { useQueryClient } from "@tanstack/react-query";

import {
  Answers,
  InvestorProfile,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  getQueryDetailKey,
  useUpdateInvestorProfileMutation,
} from "@tudigo-monorepo/web-tudigo-api-client";

type QualificationActionsBinding = {
  setShowExitDialog: (show: boolean) => void;
  setShowDeclineDialog: (show: boolean) => void;
  setShowEndDialog: (show: boolean) => void;
  investorProfile?: InvestorProfile;
};

export const useQualificationActionsBinding = (
  params: QualificationActionsBinding,
) => {
  const { user } = useUserBusiness();
  const { mutate: updateInvestorProfile } = useUpdateInvestorProfileMutation();

  const queryClient = useQueryClient();

  return {
    exit: {
      onClick: () => {
        params.setShowExitDialog(true);
      },
    },

    decline: {
      onClick: () => {
        params.setShowDeclineDialog(true);
      },
    },

    submit: {
      onClick: (answers: Answers) => {
        updateInvestorProfile(
          {
            userId: user?.id ?? "me",
            investorProfile: {
              qualificationData: answers,
              beAdvisedOptIn: params.investorProfile?.beAdvisedOptIn ?? false,
            },
          },
          {
            onSuccess: (investorProfileResponse) => {
              queryClient.setQueryData(
                getQueryDetailKey({
                  key: QUERY_KEYS.INVESTOR_PROFILE,
                  id: `${user?.id ?? "me"}:current`,
                }),
                () => {
                  return investorProfileResponse.data;
                },
              );
            },
          },
        );
      },
    },
  };
};
