import React from "react";

import {
  Button,
  ChipCount,
  Title,
} from "@tudigo-monorepo/web-tudigo-components";

const ProjectsPageHeader = ({ children }: React.PropsWithChildren) => {
  return (
    <header className="border-light-1 flex w-full flex-col gap-y-4 border-b pb-4">
      {children}
    </header>
  );
};

const ProjectsPageHeaderTop = ({
  projectsCount,
}: {
  projectsCount: number;
}) => {
  return (
    <div className="flex items-center gap-x-4">
      <Title>Les opportunités d&apos;investissement</Title>
      <ChipCount
        size="S"
        label={`${projectsCount} levées`}
        className="mt-2 hidden h-6 sm:flex"
      />
    </div>
  );
};

const ProjectsPageHeaderActions = ({ children }: React.PropsWithChildren) => {
  return (
    <div className="flex flex-col items-center justify-between gap-y-4 sm:flex-row">
      {children}
    </div>
  );
};

const ProjectsPageHeaderActionsMobile = ({
  children,
  projectsCount,
}: React.PropsWithChildren<{
  projectsCount: number;
}>) => {
  return (
    <div className="flex w-full items-center justify-between sm:hidden">
      {children}
      <ChipCount size="S" label={`${projectsCount} levées`} className="h-6" />
    </div>
  );
};

const ProjectsPageHeaderMobileMenuTrigger = ({
  onTriggerClick,
}: {
  onTriggerClick: () => void;
}) => {
  return <Button label="Filtrer" iconLeft="Filter" onClick={onTriggerClick} />;
};

ProjectsPageHeader.Top = ProjectsPageHeaderTop;
ProjectsPageHeader.Actions = ProjectsPageHeaderActions;
ProjectsPageHeader.ActionsMobile = ProjectsPageHeaderActionsMobile;
ProjectsPageHeader.MenuTrigger = ProjectsPageHeaderMobileMenuTrigger;

export { ProjectsPageHeader };
