import { useState } from "react";
import { useInstantSearch } from "react-instantsearch";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

import { CustomRefinementList } from "./custom-refinement-list";
import { FiltersPanel } from "./filters-panel";

const ALGOLIA_INDEX = import.meta.env.VITE_ALGOLIA_INDEX_PREFIX as string;

enum RefinementAttributes {
  instrument = "instrument",
  taxExemptionMechanics = "taxExemptionMechanics",
  states = "states",
  maturity = "maturity.code",
  categories = "categories.name",
}

type FiltersProps = {
  collapseMode?: boolean;
};

type PanelsState = {
  [k: string]: boolean;
  mechanicOpen: boolean;
  taxExemptionOpen: boolean;
  statusOpen: boolean;
  maturityOpen: boolean;
  categoriesOpen: boolean;
};

export function ProjectsFilters({ collapseMode = false }: FiltersProps) {
  const { uiState } = useInstantSearch();

  const { t } = useTranslation();

  const refinementList: Record<string, string[]> =
    uiState[ALGOLIA_INDEX]?.refinementList ?? {};

  const [panelsState, setPanelsState] = useState<PanelsState>({
    mechanicOpen: false,
    taxExemptionOpen: false,
    statusOpen: false,
    maturityOpen: false,
    categoriesOpen: false,
  });

  const hasMechanicRefinements =
    refinementList[RefinementAttributes.instrument]?.length > 0;
  const hasTaxExemptionRefinements =
    refinementList[RefinementAttributes.taxExemptionMechanics]?.length > 0;
  const hasStatesRefinements =
    refinementList[RefinementAttributes.states]?.length > 0;
  const hasMaturityRefinements =
    refinementList[RefinementAttributes.maturity]?.length > 0;
  const hasCategoriesRefinements =
    refinementList[RefinementAttributes.categories]?.length > 0;

  const handlePanelClick = (panelKey: string) => {
    const isPrevPanelOpen = panelsState[panelKey];

    setPanelsState({
      ...panelsState,
      [panelKey]: !isPrevPanelOpen,
    });
  };

  return (
    <div className="flex w-full flex-col gap-y-8 pb-40">
      <FiltersPanel
        title={t("projects.web_app.refinements.mechanic.label")}
        collapseMode={collapseMode}
        open={hasMechanicRefinements || panelsState.mechanicOpen}
        active={hasMechanicRefinements}
        onClick={() => handlePanelClick("mechanicOpen")}
      >
        <CustomRefinementList
          attribute={RefinementAttributes.instrument}
          open={
            collapseMode
              ? hasMechanicRefinements || panelsState.mechanicOpen
              : true
          }
          transformItem={(item) => {
            return {
              ...item,
              label: t(`projects.mechanic.label.${item.label}`),
            };
          }}
        />
      </FiltersPanel>

      <FiltersPanel
        title={t("projects.web_app.refinements.taxExemptionMechanics.label")}
        collapseMode={collapseMode}
        open={hasTaxExemptionRefinements || panelsState.taxExemptionOpen}
        active={hasTaxExemptionRefinements}
        onClick={() => handlePanelClick("taxExemptionOpen")}
      >
        <CustomRefinementList
          attribute={RefinementAttributes.taxExemptionMechanics}
          open={
            collapseMode
              ? hasTaxExemptionRefinements || panelsState.taxExemptionOpen
              : true
          }
          transformItem={(item) => {
            return {
              ...item,
              label: t(
                `component.project_card.list.item.tax_exemption.${item.label}`,
              ),
            };
          }}
        />
      </FiltersPanel>

      <FiltersPanel
        title={t("projects.web_app.refinements.displayedStatus.label")}
        collapseMode={collapseMode}
        open={hasStatesRefinements || panelsState.statusOpen}
        active={hasStatesRefinements}
        onClick={() => handlePanelClick("statusOpen")}
      >
        <CustomRefinementList
          attribute={RefinementAttributes.states}
          open={
            collapseMode ? hasStatesRefinements || panelsState.statusOpen : true
          }
          transformItem={(item) => {
            return {
              ...item,
              label: t(`projects.stage.label.${item.label}`),
            };
          }}
        />
      </FiltersPanel>

      <FiltersPanel
        title={t("projects.web_app.refinements.maturity.label")}
        collapseMode={collapseMode}
        open={hasMaturityRefinements || panelsState.maturityOpen}
        active={hasMaturityRefinements}
        onClick={() => handlePanelClick("maturityOpen")}
      >
        <CustomRefinementList
          attribute={RefinementAttributes.maturity}
          open={
            collapseMode
              ? hasMaturityRefinements || panelsState.maturityOpen
              : true
          }
          transformItem={(item) => {
            return {
              ...item,
              label: t(`projects.maturity.label.${item.label}`),
            };
          }}
        />
      </FiltersPanel>

      <FiltersPanel
        title={t("projects.web_app.refinements.categories.label")}
        collapseMode={collapseMode}
        open={hasCategoriesRefinements || panelsState.categoriesOpen}
        active={hasCategoriesRefinements}
        onClick={() => handlePanelClick("categoriesOpen")}
      >
        <CustomRefinementList
          attribute={RefinementAttributes.categories}
          open={
            collapseMode
              ? hasCategoriesRefinements || panelsState.categoriesOpen
              : true
          }
          limit={5}
          showMore
        />
      </FiltersPanel>
    </div>
  );
}
