import { ReactNode } from "react";
import { cva } from "class-variance-authority";
import { NavLink } from "react-router-dom";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

type RouterTabProps = {
  to: string;
  children: ReactNode;
};

const routerTabClassVariants = cva(
  `h-[52px] flex items-center justify-center gap-4 text-sm text-dark-1
    border-b-2 border-transparent
  `,
  {
    variants: {
      isActive: {
        true: "font-semibold border-b-2 !border-dark-1",
      },
    },
  },
);

export function RouterTabs(props: { className?: string; children: ReactNode }) {
  return (
    <div className="mb-4 w-full border-b">
      <div
        className={cn(
          "container mx-auto flex flex-row gap-6",
          props?.className,
        )}
      >
        {props.children}
      </div>
    </div>
  );
}

export function RouterTabItem(props: RouterTabProps) {
  return (
    <NavLink
      end
      to={props.to}
      className={({ isActive }) => routerTabClassVariants({ isActive })}
    >
      {props.children}
    </NavLink>
  );
}
