import * as React from "react";

export const useScroll = (timing?: number) => {
  const [scrollY, setScrollY] = React.useState<number>(0);
  const [isScrollingUp, setIsScrollingUp] = React.useState<boolean>(false);
  const [isScrollingDown, setIsScrollingDown] = React.useState<boolean>(false);
  const isScrolling = isScrollingUp || isScrollingDown;
  const duration = timing ?? 3500; // ms

  React.useEffect(() => {
    const handleScrollingDir = (
      prevScrollY: number,
      currentScrollY: number,
    ) => {
      const isScrolledUp = prevScrollY > currentScrollY;
      setIsScrollingUp(isScrolledUp);
      const isScrolledDown = prevScrollY < currentScrollY;
      setIsScrollingDown(isScrolledDown);
      setTimeout(() => {
        if (window.scrollY === currentScrollY) {
          setIsScrollingDown(false);
          setIsScrollingUp(false);
        }
      }, duration);
    };

    let prevScrollY = window.scrollY;
    const onScrollChange = () => {
      const currentScrollY = window.scrollY;
      handleScrollingDir(prevScrollY, currentScrollY);
      prevScrollY = currentScrollY;
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", onScrollChange);

    return () => {
      window.removeEventListener("scroll", onScrollChange);
    };
  }, [duration]);

  return {
    isScrolling,
    isScrollingUp,
    isScrollingDown,
    scrollY,
  };
};
