import React from "react";
import { useIntl } from "react-intl";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { countries, getCountryEmoji } from "@tudigo-monorepo/core-tudigo-utils";

import AutoComplete from "../auto-complete/auto-complete";
import { InputGroupProps } from "../input-group";

type CountrySelectorProps = {
  selectedCountry?: string;
  setSelectedCountry: (country: string) => void;
  placeholder?: string;
} & InputGroupProps;

export function CountrySelector(props: CountrySelectorProps) {
  const intl = useIntl();
  const { t } = useTranslation();

  const { selectedCountry, setSelectedCountry, label, errors, placeholder } =
    props;

  return (
    <AutoComplete
      {...props}
      label={label}
      placeholder={placeholder || t("components.country_selector.placeholder")}
      errors={errors}
      items={countries
        .map((country) => ({
          id: country.id,
          name: intl.formatDisplayName(country.id, { type: "region" }) ?? "",
        }))
        .sort((a, b) => a.name.localeCompare(b.name))}
      filterFn={(item, value) =>
        item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
      }
      selection={countries.find((country) => country.id === selectedCountry)}
      onSelect={(value) => {
        setSelectedCountry(value.id);
      }}
      renderItem={(item: { id: string; name: string }) => (
        <div className="flex flex-row items-center gap-2">
          <div>{getCountryEmoji(item.id)}</div>
          <div>{intl.formatDisplayName(item.id, { type: "region" })}</div>
        </div>
      )}
      getId={(item) => item.id}
    />
  );
}
