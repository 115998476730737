import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";

type ProjectTotalAmountCollectedProps = {
  amount: number;
  className?: string;
};

export function ProjectTotalAmountCollected({
  amount,
  className,
}: ProjectTotalAmountCollectedProps) {
  return (
    <p className={cn("font-obviously text-primary", className)}>
      {formatCurrency(amount, "fr-fr", false)}
    </p>
  );
}
