import { Navigate, Route, Routes } from "react-router-dom";

import { ClubLeaderProjectDetailPage } from "../club-leader-project-detail-page";
import { ClubLeaderPageProjectTabInvestorsContent } from "../components/club-leader-page-project-tab-investors-content";

export function ClubLeaderProjectDetailRouteWebapp() {
  return (
    <Routes>
      <Route path="*" element={<ClubLeaderProjectDetailPage />}>
        <Route
          path="investors"
          element={<ClubLeaderPageProjectTabInvestorsContent />}
        />
        <Route path="*" element={<Navigate to="investors" />} />
      </Route>
    </Routes>
  );
}
