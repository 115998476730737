import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import {
  CancelInvestmentRequest,
  Investment,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient, getQueryDetailKey, getQueryListKey } from "../../..";

type CreateCancelInvestmentRequestParams = {
  data: CancelInvestmentRequest;
  userId?: string;
  query?: {
    fields?: string;
    filters?: { [key: string]: string };
  };
};

export const createCancelInvestmentRequest = async (
  params: CreateCancelInvestmentRequestParams,
) => {
  const apiClient = getApiClient();

  const response =
    await apiClient.apiV1.investments.createCancelInvestmentRequest(
      params.data,
    );

  return response.data;
};

export const useCreateCancelInvestmentRequestMutation = (
  options?: UseMutationOptions<
    TudigoResponse<Investment>,
    TudigoError,
    CreateCancelInvestmentRequestParams
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (params: CreateCancelInvestmentRequestParams) =>
      createCancelInvestmentRequest(params),
    onSuccess: (...props) => {
      const [, params] = props;

      const investmentChangesQueryKey = getQueryListKey({
        key: `${QUERY_KEYS.INVESTMENT_STATUS_CHANGES}_${params.data.investment.id}`,
      });

      const investmentQueryKey = getQueryDetailKey({
        key: `${QUERY_KEYS.INVESTMENTS}`,
        id: params.data.investment.id!,
        fields: params.query?.fields,
      });

      const userInvestmentsQueryKey = getQueryListKey({
        key: QUERY_KEYS.INVESTMENTS,
        fields: params.query?.fields,
        filters: params.query?.filters,
      });

      queryClient.invalidateQueries({
        queryKey: investmentQueryKey,
      });

      queryClient.invalidateQueries({
        queryKey: investmentChangesQueryKey,
      });

      queryClient.invalidateQueries({
        queryKey: userInvestmentsQueryKey,
      });

      options?.onSuccess?.(...props);
    },
  });
};
