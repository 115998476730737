import {
  InternalKycStatus,
  Organization,
  OrganizationDocument,
  OrganizationPersonDocument,
} from "@tudigo-monorepo/core-tudigo-api-models";

export const isOrganizationKycApproved = (organization: Organization) => {
  return organization.internalKycStatus === InternalKycStatus.APPROVED;
};

export const isRejectedOrganizationKycDocument = (
  doc: OrganizationDocument | OrganizationPersonDocument,
) => {
  return doc.pmsFile?.status === InternalKycStatus.REJECTED;
};
