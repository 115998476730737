import { useIntl } from "react-intl";

import { InvestorProfile } from "@tudigo-monorepo/core-tudigo-api-models";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";
import { Title } from "@tudigo-monorepo/web-tudigo-components";

import { InvestmentCapacityCardsContent } from "../../../content/investment-capacity-cards-content";
import { InvestmentCapacityCollapseContent } from "../../../content/investment-capacity-collapse-content";

export type InvestmentCapacitySummaryPageProps = {
  investorProfile: InvestorProfile;
};

export function InvestmentCapacitySummaryPage(
  props: InvestmentCapacitySummaryPageProps,
) {
  const { investorProfile } = props;
  const intl = useIntl();

  const getAnnualIncome = () => {
    return (investorProfile.financialSummary?.annualIncome || 0) as number;
  };

  const getDept = () => {
    return (
      (investorProfile.qualificationData?.financialSituation
        ?.realEstateLoans as number) || 0
    );
  };

  const getLiquidAssets = () => {
    return (
      ((investorProfile.qualificationData?.financialSituation
        ?.savingsProduct as number) || 0) +
      ((investorProfile.qualificationData?.financialSituation
        ?.listedSecurities as number) || 0) +
      ((investorProfile.qualificationData?.financialSituation
        ?.lifeInsurance as number) || 0) +
      ((investorProfile.qualificationData?.financialSituation
        ?.unlistedSecurities as number) || 0) +
      ((investorProfile.qualificationData?.financialSituation
        ?.cryptoCurrencies as number) || 0)
    );
  };

  const getNetAssets = () => {
    return getWealth() - getDept();
  };

  const getWealth = () => {
    return (
      ((investorProfile.qualificationData?.financialSituation
        ?.primaryResidence as number) || 0) +
      ((investorProfile.qualificationData?.financialSituation
        ?.realEstate as number) || 0) +
      ((investorProfile.qualificationData?.financialSituation
        ?.others as number) || 0)
    );
  };

  return (
    <div className="investment-capacity-summary-page flex flex-col gap-y-8">
      <Title className="border-light-1 w-full border-b pb-4">
        {intl.formatMessage({
          id: "qualification.summary_page.investment_capacity.title",
        })}
      </Title>
      <InvestmentCapacityCardsContent
        investmentCapacity={{
          annualIncome: getAnnualIncome(),
          debt: getDept(),
          liquidAssets: getLiquidAssets(),
          monthlyIncome: parseInt((getAnnualIncome() / 12).toFixed(0)),
          netAssets: getNetAssets(),
          wealth: getWealth(),
        }}
      />
      <InvestmentCapacityCollapseContent
        summaryItems={[
          {
            label: "Vos salaires nets /an",
            value: formatCurrency(
              (investorProfile.qualificationData?.financialSituation
                ?.annualSalary as number) * 100,
            ),
            type: "item",
          },
          {
            label: "Intérêts /an",
            value: formatCurrency(
              (investorProfile.qualificationData?.financialSituation
                ?.interests as number) * 100,
            ),
            type: "item",
          },
          {
            label: "Revenus fonciers /an",
            value: formatCurrency(
              (investorProfile.qualificationData?.financialSituation
                ?.landIncome as number) * 100,
            ),
            type: "item",
          },
          {
            label: "Dividendes /an",
            value: formatCurrency(
              (investorProfile.qualificationData?.financialSituation
                ?.dividends as number) * 100,
            ),
            type: "item",
          },
          {
            type: "separator",
          },
          {
            label: "Produits d’épargne",
            value: formatCurrency(
              (investorProfile.qualificationData?.financialSituation
                ?.savingsProduct as number) * 100,
            ),
            type: "item",
          },
          {
            label: "Titres cotés",
            value: formatCurrency(
              (investorProfile.qualificationData?.financialSituation
                ?.listedSecurities as number) * 100,
            ),
            type: "item",
          },
          {
            label: "Assurance vie et PER",
            value: formatCurrency(
              (investorProfile.qualificationData?.financialSituation
                ?.lifeInsurance as number) * 100,
            ),
            type: "item",
          },
          {
            label: "Titres non cotés",
            value: formatCurrency(
              (investorProfile.qualificationData?.financialSituation
                ?.unlistedSecurities as number) * 100,
            ),
            type: "item",
          },
          {
            label: "Crypto monnaie",
            value: formatCurrency(
              (investorProfile.qualificationData?.financialSituation
                ?.cryptoCurrencies as number) * 100,
            ),
            type: "item",
          },
          {
            label: "Investissements sur les 12 derniers mois",
            value: formatCurrency(
              (investorProfile.qualificationData?.financialSituation
                ?.investLastTwelvesMonths as number) * 100,
            ),
            type: "item",
          },
          {
            type: "separator",
          },
          {
            label: "Résidence principale",
            value: formatCurrency(
              (investorProfile.qualificationData?.financialSituation
                ?.primaryResidence as number) * 100,
            ),
            type: "item",
          },
          {
            label: "Investissements immobiliers",
            value: formatCurrency(
              (investorProfile.qualificationData?.financialSituation
                ?.realEstate as number) * 100,
            ),
            type: "item",
          },
          {
            label: "Autres",
            value: formatCurrency(
              (investorProfile.qualificationData?.financialSituation
                ?.others as number) * 100,
            ),
            type: "item",
          },
          {
            type: "separator",
          },
          {
            label: "Crédits immobilier",
            value: formatCurrency(
              (investorProfile.qualificationData?.financialSituation
                ?.realEstateLoans as number) * 100,
            ),
            type: "item",
          },
          {
            label: "Mensualités de crédits /an",
            value: formatCurrency(
              (investorProfile.qualificationData?.financialSituation
                ?.monthlyLoansPayments as number) * 100,
            ),
            type: "item",
          },
          {
            label: "Dépenses récurrentes /mois",
            value: formatCurrency(
              (investorProfile.qualificationData?.financialSituation
                ?.recurrentMonthlyExpenses as number) * 100,
            ),
            type: "item",
          },
        ]}
      />
    </div>
  );
}
