import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";

import { getApiClient } from "../../..";

type SendWmaInvestmentFolderEmailRequestParams = {
  wmaInvestmentId: string;
};

export const sendWmaInvestmentFolderEmail = async (
  params: SendWmaInvestmentFolderEmailRequestParams,
) => {
  const apiClient = getApiClient();

  const response =
    await apiClient.apiV1.wmaInvestments.sendWmaInvestmentFolderEmail(
      params.wmaInvestmentId,
    );

  return response.data;
};

export const useSendWmaInvestmentFolderEmailMutation = (
  options?: UseMutationOptions<
    TudigoResponse<void>,
    TudigoError,
    SendWmaInvestmentFolderEmailRequestParams
  >,
) => {
  return useMutation({
    ...options,
    mutationFn: (params: SendWmaInvestmentFolderEmailRequestParams) =>
      sendWmaInvestmentFolderEmail(params),
    onSuccess: (...props) => {
      options?.onSuccess?.(...props);
    },
  });
};
