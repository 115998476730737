import { Route, Routes } from "react-router-dom";

import { UserRepaymentsPage } from "../../pages/user-repayments-page/user-repayments-page";

export function RepaymentsRouteWebApp() {
  return (
    <Routes>
      <Route path="/" element={<UserRepaymentsPage />} />
    </Routes>
  );
}
