import { useNavigate } from "react-router-dom";

import { User } from "@tudigo-monorepo/core-tudigo-api-models";
import { getImageUrl } from "@tudigo-monorepo/core-tudigo-utils";
import { ListElement } from "@tudigo-monorepo/web-tudigo-components";

type QualificationListElementProps = {
  user: User;
};

export function QualificationListElement(props: QualificationListElementProps) {
  const { user } = props;

  const navigate = useNavigate();

  return (
    <ListElement
      onClick={() => {
        if (!user.investorProfile?.confirmed) {
          navigate("/qualification");
        }
      }}
      startElement={{
        img: {
          avatarUrl: getImageUrl(
            user.profileImage,
            40,
            40,
            "resized",
            "/images/avatar-placeholder.svg",
          ),
        },
        upperText: "Profil investisseur",
        lowerText: `${user.firstName} ${user.lastName}`,
      }}
      middleElement={{
        upperText:
          "Pour accéder aux informations financières des opportunités et pour pouvoir investir vous devez compléter votre profil investisseur",
      }}
      endElement={{
        info: {
          chip: user.investorProfile?.confirmed
            ? {
                label: "Complet",
                type: "success",
                iconLeft: "DotChip",
              }
            : {
                label: "À compléter",
                type: "warning",
                iconLeft: "DotChip",
              },
        },
        icon: !user.investorProfile?.confirmed ? "ChevronRight" : undefined,
      }}
    />
  );
}
