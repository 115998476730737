import { PropsWithChildren } from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { Icon } from "@tudigo-monorepo/web-tudigo-components";

type FiltersPanelProps = PropsWithChildren<{
  title: string;
  active?: boolean;
  collapseMode?: boolean;
  open?: boolean;
  onClick?: () => void;
}>;

export const FiltersPanel = ({
  children,
  title,
  collapseMode = false,
  active = false,
  open = false,
  onClick,
}: FiltersPanelProps) => {
  const handlePanelClick = () => {
    if (collapseMode && onClick) {
      onClick();
    }
  };

  const renderList = () => {
    if (collapseMode) {
      if (open) {
        return children;
      }

      return null;
    }

    return children;
  };

  return (
    <div className="flex flex-col gap-y-3">
      <div
        onClick={handlePanelClick}
        className="flex items-center justify-between"
      >
        <p
          className={cn(
            "font-montserrat text-dark-2 pb-2 text-[13px] font-bold transition-colors",
            { "text-accent": active },
          )}
        >
          {title}
        </p>

        {collapseMode && (
          <Icon
            name="ChevronDown"
            className={cn("rotate-0 transition-all", {
              "rotate-180": open,
            })}
          />
        )}
      </div>

      {renderList()}
    </div>
  );
};
