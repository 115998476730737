import { Route, Routes } from "react-router-dom";

import { ClubLeaderDashboardPage } from "../club-leader-dashboard-page";

export function ClubLeaderDashboardRouteWebapp() {
  return (
    <Routes>
      <Route path="*" element={<ClubLeaderDashboardPage />} />
    </Routes>
  );
}
