import {
  MultiSelectInput,
  SelectInput,
} from "@tudigo-monorepo/core-tudigo-api-models";

export const getSelectInputColumnsCount = (
  input: SelectInput | MultiSelectInput,
) => {
  return input.parameters !== null &&
    input.parameters.choices !== null &&
    input.parameters?.choices.length > 6
    ? 2
    : 1;
};
