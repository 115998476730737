import React from "react";

import { PaymentMethodType } from "@tudigo-monorepo/core-tudigo-api-models";
import { ORGANIZATIONS } from "@tudigo-monorepo/core-tudigo-utils";
import { ListElement, Title } from "@tudigo-monorepo/web-tudigo-components";

import { useEditWmaInvestmentPageContext } from "../../../../edit-wma-investment-page";

export function EditWmaInvestmentPaymentTabPeaPaymentReceivedSection() {
  const { investment } = useEditWmaInvestmentPageContext();

  const paymentDate = new Date(
    investment.paymentDate as string,
  ).toLocaleDateString();

  const signedCommitmentLetterDocument =
    investment.signedCommitmentLetterDocument;

  return (
    <div className="m-6 flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <Title level="h4">Lettre d&apos;engagement</Title>

        <ListElement
          key={investment.id}
          toggleActiveOnClick={false}
          startElement={{
            upperText: "Lettre d'engagement",
            lowerText: investment.investorOrganization.name,
            useProfileCard: true,
            profileCardClassName: "min-w-[280px] flex justify-start px-3 py-2",
            img: {
              icon: {
                name: "Document",
              },
            },
          }}
          middleElement={{
            upperText: "Lettre d'engagement signée",
            chips: [
              {
                type: "secondary",
                label:
                  investment.investorOrganization.type ===
                  ORGANIZATIONS.TYPE.COMPANY
                    ? "Personne morale"
                    : "Personne physique",
              },
            ],
          }}
          endElement={{
            icon: "Download",
            info: {
              text: "PDF",
            },
          }}
          onClick={() => {
            window.open(
              `${signedCommitmentLetterDocument?.document?.signedUrl}`,
              "_blank",
            );
          }}
        />
      </div>
      <div className="flex flex-col gap-2">
        <Title level="h4">
          Paiement :{" "}
          {investment.paymentMethodType === PaymentMethodType.PEA
            ? "PEA"
            : "PEA-PME"}
        </Title>
        <ListElement
          className="mt-2"
          key={investment.id}
          toggleActiveOnClick={false}
          startElement={{
            upperText: "Paiement",
            useProfileCard: true,
            profileCardClassName: "min-w-[280px] flex justify-start px-3 py-2",
            img: {
              icon: {
                name: "Invest",
              },
            },
          }}
          middleElement={{
            upperText: `Réalisé le ${paymentDate}`,
            chips: [
              {
                type: "secondary",
                label: "Virement",
              },
            ],
          }}
          endElement={{
            info: {
              chip: {
                type: "success",
                label: "Validé",
                iconLeft: "DotChip",
              },
            },
          }}
        />
      </div>
    </div>
  );
}
