import { Navigate, Route, Routes } from "react-router-dom";

import { MySettingIdentifiersContent } from "../content/my-setting-identifiers-content";
import { MySettingPreferencesContent } from "../content/my-setting-preferences-content";
import { MySettingProfilePictureContent } from "../content/my-setting-profile-picture-content";
import { MySettingsPage } from "../pages/my-settings-page";

export function MySettingsRoute() {
  return (
    <Routes>
      <Route element={<MySettingsPage />}>
        <Route path="identifiers" element={<MySettingIdentifiersContent />} />
        <Route
          path="profile-picture"
          element={<MySettingProfilePictureContent />}
        />
        <Route path="preferences" element={<MySettingPreferencesContent />} />
        <Route path="*" element={<Navigate to="identifiers" />} />
      </Route>
    </Routes>
  );
}
