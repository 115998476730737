import { ClubMember } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  useGetClubs,
  useGetUserClubMembers,
} from "@tudigo-monorepo/web-tudigo-api-client";
import { TudigoCollection } from "@tudigo-monorepo/web-tudigo-collection";
import {
  ClubCard,
  PageHead,
  Table,
  TableCardList,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { ClubMembersTableActions } from "./components/club-members-table-actions";
import { useMyClubsColumnsHelper } from "./hooks/use-my-clubs-columns-helper";
import placeHolderImg1 from "./placeholder1.png";
import placeHolderImg2 from "./placeholder2.png";

export const MyClubsPage = () => {
  const { t } = useTranslation();
  const { user } = useUserBusiness();
  const columns = useMyClubsColumnsHelper();

  const { data: clubs } = useGetClubs();

  if (!user) {
    return null;
  }

  return (
    <div className="w-full">
      <PageHead title={t("my_account.wep_app.clubs.title")} />
      <div className="px-6 py-4">
        <div className="flex">
          <Title h3Brand="brand-2" level="h3" className="text-primary mb-6">
            {t("my_account.wep_app.clubs.my_clubs")}
          </Title>
        </div>

        <TudigoCollection
          useLocationAsState
          columns={columns}
          useQueryFn={useGetUserClubMembers}
          pathParams={{ userId: user?.id }}
          actions={[
            {
              render: (row: ClubMember) => (
                <ClubMembersTableActions row={row} />
              ),
            },
          ]}
          renderEmpty={() => (
            <Typography variant="body3-regular">
              {t("my_account.wep_app.clubs.no_club")}
            </Typography>
          )}
          render={(table, props) => {
            return (
              <>
                <Table
                  table={table}
                  actions={props.actions}
                  className="hidden sm:table"
                />
                <TableCardList table={table} className="sm:hidden" />
              </>
            );
          }}
        />

        <div className="mt-20 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          {clubs?.data?.map((club, index) => {
            const tags = club?.advertisedProjectTags?.map((tag) => ({
              label: tag.name,
            }));
            const openedTag = {
              label: "Ouvert",
              type: "success",
              iconLeft: "DotChip",
            };

            return (
              <ClubCard
                description={club?.shortDescription}
                title={club?.name}
                imageUrl={club?.image?.url ?? ""}
                tags={[
                  ...(club?.isOpenedToSubscriptions ? [openedTag] : []),
                  ...(tags ?? []),
                ]}
                subtitle={club?.clubLeader}
                navigateTo={
                  club?.isOpenedToSubscriptions
                    ? `/clubs/join/${club?.id}/description`
                    : ""
                }
                key={index}
              />
            );
          })}
          <ClubCard imageUrl={placeHolderImg1} locked />
          <ClubCard imageUrl={placeHolderImg2} locked />
        </div>
      </div>
    </div>
  );
};
