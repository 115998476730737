import { Outlet } from "react-router-dom";

import { useGetClubV2UsingIdPathParams } from "../shared-internal/hooks/use-get-club-v2-using-id-path-params";
import { ClubCover } from "./components/club-cover/club-cover";
import { ClubPageHeaderSection } from "./components/club-page-header-section/club-page-header-section";
import { ClubPageSidebar } from "./components/club-page-sidebar/club-page-sidebar";

export const ClubPage = () => {
  const club = useGetClubV2UsingIdPathParams();

  if (!club) {
    return;
  }

  return (
    <div className="bg-black-800 flex w-full flex-col items-center justify-between">
      <ClubCover coverUrl={club.content?.cover?.url} />

      <div className="flex w-full justify-center bg-white">
        <section className="container">
          <ClubPageHeaderSection club={club} />
        </section>
      </div>

      <section className="container my-6">
        <div className="mx-6 flex flex-col-reverse justify-between gap-6 lg:flex-row">
          <div className="flex w-full flex-col lg:w-2/3">
            <Outlet />
          </div>
          <div className="w-full lg:w-1/3">
            <ClubPageSidebar club={club} />
          </div>
        </div>
      </section>
    </div>
  );
};
