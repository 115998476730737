import React from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { ASSEMBLY_PARTICIPANTS } from "@tudigo-monorepo/core-tudigo-utils";
import { useGetUserAssemblyParticipationsForOrganization } from "@tudigo-monorepo/web-tudigo-api-client";
import { TudigoCollection } from "@tudigo-monorepo/web-tudigo-collection";
import {
  Table,
  TableCardList,
  Title,
} from "@tudigo-monorepo/web-tudigo-components";

import { useAssemblyParticipantsTableColumns } from "../hooks/use-assembly-participants-table-columns";
import { useMyInvestmentPageContext } from "../hooks/use-my-investment-page-context";

export function InvestmentAssembliesContent() {
  const { project, userId } = useMyInvestmentPageContext();

  const intl = useIntl();
  const navigate = useNavigate();

  const assemblyParticipantsFields =
    "id,status,assembly.fields(id,name,status,configuration),participant_organization.fields(name)";

  return (
    <div className="m-6">
      <div className="tab-filter flex flex-row items-center justify-between  py-6">
        <Title h3Brand="brand-2" className="text-primary mb-6" level="h3">
          {intl.formatMessage({
            id: "my-investments.my_investment_page.assemblies.title",
          })}
        </Title>
      </div>
      <TudigoCollection
        useLocationAsState
        columns={useAssemblyParticipantsTableColumns()}
        fields={assemblyParticipantsFields}
        filters={{
          status: [
            ASSEMBLY_PARTICIPANTS.STATUS.STATUS_REPLIED,
            ASSEMBLY_PARTICIPANTS.STATUS.STATUS_NOTIFIED,
            ASSEMBLY_PARTICIPANTS.STATUS.WAITING_VOTE_SIGNATURE,
          ],
        }}
        useQueryFn={useGetUserAssemblyParticipationsForOrganization}
        pathParams={{
          userId: userId,
          organizationId: project.vehicleOrganization?.id ?? "",
        }}
        render={(table, props) => {
          return (
            <>
              <Table
                table={table}
                actions={props.actions}
                className="hidden sm:table"
                onRowClick={(row) => {
                  navigate(
                    `/my-account/my-investments/projects/${project.id}/assemblies/${row.assembly.id}/assembly-participant/${row.id}`,
                  );
                }}
              />
              <TableCardList
                table={table}
                className="sm:hidden"
                onCardClick={(row) => {
                  navigate(
                    `/my-account/my-investments/projects/${project.id}/assemblies/${row.assembly.id}/assembly-participant/${row.id}`,
                  );
                }}
              />
            </>
          );
        }}
      />
    </div>
  );
}
