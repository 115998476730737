import React from "react";
import { useNavigate } from "react-router-dom";

import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { useMarkIntroHasDone } from "@tudigo-monorepo/web-tudigo-api-client";
import { Button } from "@tudigo-monorepo/web-tudigo-components";

type InvestorIntroActionsProps = {
  setShowExitDialog: (show: boolean) => void;
};

export const InvestorIntroActions = (props: InvestorIntroActionsProps) => {
  const { setShowExitDialog } = props;
  const { user } = useUserBusiness();

  const navigate = useNavigate();

  const { mutate: markIntroDone, isPending } = useMarkIntroHasDone();

  return (
    <>
      <Button
        className="w-fit px-12"
        variant="primary"
        iconRight="ArrowRight"
        label="Commencer"
        onClick={() => {
          markIntroDone(
            {
              userId: user?.id || "me",
            },
            {
              onSuccess: () => {
                navigate("/qualification");
              },
            },
          );
        }}
      />
      <Button
        className="w-fit px-12 sm:hidden"
        variant="ghost"
        label="Quitter"
        isLoading={isPending}
        onClick={() => {
          setShowExitDialog(true);
        }}
      />
    </>
  );
};
