import { cva } from "class-variance-authority";

import { Project } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { useProjectFlagProps } from "./use-project-flag-props";

const projectFlagVariants = cva(
  "h-7 px-2 flex items-center rounded-md font-medium text-xs gap-x-2",
  {
    variants: {
      variant: {
        default: "bg-white",
        progress: "bg-black-800 text-black-900",
        new: "bg-accent-super-light-rebrand text-primary-rebrand",
        coming: "bg-secondary-super-light text-[#8E6D00]",
        "accent-super-light":
          "bg-accent-super-light border-transparent text-accent",
        success: "text-success bg-success-light",
        "success-light": "bg-success-light border-transparent text-success",
        warning: "bg-warning-super-light border-transparent text-warning",
        waiting: "bg-waiting-super-light border-transparent text-waiting",
        destructive: "bg-error-light text-error",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

export type ProjectFlagVariant =
  | "default"
  | "progress"
  | "new"
  | "coming"
  | "accent-super-light"
  | "success"
  | "success-light"
  | "warning"
  | "waiting"
  | "destructive";

export function ProjectFlagRebrand(props: {
  project?: Project;
  className?: string;
}) {
  const { project, className } = props;
  const { label, variant, timerIcon } = useProjectFlagProps(project);

  const flagClass = projectFlagVariants({ variant });

  return (
    <div className={cn(flagClass, className)}>
      {timerIcon}
      {label}
    </div>
  );
}
