import { SelectOption } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { Typography } from "@tudigo-monorepo/web-tudigo-components";

type DynamicFormOptionProps = {
  option: SelectOption;
  selected: boolean;
  disabled?: boolean;
  onSelect: (option: SelectOption) => void;
};

export function DynamicFormOption(props: DynamicFormOptionProps) {
  const { selected, option, onSelect, disabled } = props;

  return (
    <div
      onClick={() => (!disabled || (disabled && selected)) && onSelect(option)}
      className={cn(
        "form-option flex w-full cursor-pointer items-center gap-x-6 self-stretch rounded-lg border bg-white px-6 py-3 transition-colors",
        "hover:border-accent",
        {
          "bg-accent-super-light border-accent text-accent":
            selected && !disabled,
          "bg-light-2 border-dark-4": disabled && !selected,
          "bg-error-light border-error": disabled && selected,
        },
      )}
    >
      {option.bullet ? <span role="img">{option.bullet}</span> : null}
      <Typography>{option.label}</Typography>
    </div>
  );
}
