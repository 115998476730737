import { useIntl } from "react-intl";
import { Outlet } from "react-router-dom";

import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { PageHead } from "@tudigo-monorepo/web-tudigo-components";

export function InvestorDashboardPage() {
  const intl = useIntl();
  const { user } = useUserBusiness();

  return (
    <div id="dashboard-page" className="w-full">
      <PageHead
        title="Tableau de bord"
        subtitle={intl.formatMessage(
          { id: "dashboard.subtitle" },
          {
            firstName: user?.firstName,
            lastName: user?.lastName,
          },
        )}
      />

      <Outlet />
    </div>
  );
}

export default InvestorDashboardPage;
