import React from "react";

import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Icon } from "../../../old/icons/icon";
import { IconName } from "../../../old/icons/icon-name.type";

const socialMediaLinks = [
  {
    name: "SocialLinkedin",
    url: "https://www.linkedin.com/company/tudigo/",
  },
  {
    name: "SocialInstagram",
    url: "https://www.instagram.com/tudigo_fr/",
  },
  {
    name: "SocialYoutube",
    url: "https://www.youtube.com/c/Tudigo",
  },
  {
    name: "SocialTwitter",
    url: "https://twitter.com/tudigofr",
  },
  {
    name: "SocialFacebook",
    url: "https://www.facebook.com/tudigo.co/",
  },
];

interface SocialMediaLinksProps {
  className?: string;
}

export const SocialMediaLinks: React.FC<SocialMediaLinksProps> = ({
  className,
}) => {
  return (
    <ul className={className}>
      {socialMediaLinks.map((link, index) => (
        <li key={index}>
          <a target="_blank" href={link.url} rel="noreferrer">
            <Icon
              primaryColor={themeColors["black-primary"]}
              name={link.name as IconName}
            />
          </a>
        </li>
      ))}
    </ul>
  );
};
