import { useState } from "react";
import { Link } from "react-router-dom";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import {
  PageEnum,
  SessionCard,
  SessionPanel,
  useSpaceBusiness,
} from "@tudigo-monorepo/tudigo-session";
import {
  Icon,
  NavigationItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Sidebar,
  TudigoLogoRebrand,
  TudigoLogoRebrandSmall,
} from "@tudigo-monorepo/web-tudigo-components";

import { ConnectedMenuNavigation } from "./connected-menu-navigation";

export type ConnectedSidebarProps = {
  navigation: NavigationItem[];
};

export function ConnectedSidebar({ navigation }: ConnectedSidebarProps) {
  const { currentSpace, currentPagePaths } = useSpaceBusiness();
  const [config, setConfig] = useState({
    isExpanded: true,
    isSessionPanelOpen: false,
  });

  return (
    <Sidebar expanded={config.isExpanded}>
      <div className="flex h-full w-full flex-col items-start justify-start gap-4">
        <div
          className={cn("flex w-full flex-col items-end gap-y-2.5 px-4", {
            "items-center": !config.isExpanded,
          })}
        >
          <Link
            to={currentPagePaths[PageEnum.HOME]}
            className="transition lg:mx-auto"
          >
            {config.isExpanded ? (
              <TudigoLogoRebrand />
            ) : (
              <TudigoLogoRebrandSmall />
            )}
          </Link>
          <button
            className={cn({
              "mx-auto -rotate-180": !config.isExpanded,
            })}
            onClick={() => {
              setConfig({
                ...config,
                isExpanded: !config.isExpanded,
              });
            }}
          >
            <Icon name="NavCloser" primaryColor={themeColors["dark-2"]} />
          </button>
        </div>

        <div className="w-full">
          <Popover
            open={config.isSessionPanelOpen}
            onOpenChange={() => {
              setConfig({
                ...config,
                isSessionPanelOpen: !config.isSessionPanelOpen,
              });
            }}
          >
            <PopoverTrigger className="min-h-[76px] w-full px-4">
              <SessionCard
                currentSpace={currentSpace}
                reducedDisplay={!config.isExpanded}
                textClassName="max-w-[140px]"
                className={cn("hover:border-accent-medium hover:border", {
                  "border-accent-medium bg-accent-super-light":
                    config.isSessionPanelOpen,
                })}
              />
            </PopoverTrigger>
            <PopoverContent
              side="right"
              align="start"
              alignOffset={-36}
              className="p-0"
            >
              <SessionPanel
                onClose={() => {
                  setConfig({
                    ...config,
                    isSessionPanelOpen: false,
                  });
                }}
              />
            </PopoverContent>
          </Popover>

          <ConnectedMenuNavigation
            navigation={navigation}
            reducedDisplay={!config.isExpanded}
          />
        </div>
      </div>
    </Sidebar>
  );
}
