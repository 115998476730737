import { ShareButton } from "@tudigo-monorepo/web-tudigo-components";

import { useGetClubV2UsingIdPathParams } from "../../../shared-internal/hooks/use-get-club-v2-using-id-path-params";
import {
  ClubLeadersAvatar,
  ClubLeadersStats,
} from "../club-avatar-leaders/club-leaders-avatar";
import { ClubMembershipButton } from "./club-membership-button";

const ClubMembershipHeader = () => {
  const club = useGetClubV2UsingIdPathParams();

  if (!club) {
    return;
  }
  const tagline = club?.content?.tagline;

  return (
    <div>
      <div className="flex items-center justify-center px-7 text-center">
        <p className="text-primary text-sm font-semibold">{tagline}</p>
      </div>
      <div className="absolute right-0 top-0">
        <ShareButton />
      </div>
    </div>
  );
};

export const ClubMembership = () => {
  return (
    <article className="border-light-1 relative flex w-full flex-col items-center gap-4 rounded-lg border bg-white p-4">
      <ClubMembershipHeader />
      <div className="flex flex-wrap items-center justify-center gap-2">
        <ClubLeadersAvatar />
        <ClubLeadersStats />
      </div>
      <ClubMembershipButton />
    </article>
  );
};
