import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function SocialDiscord(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9308 1.463C15.6561 0.878094 14.2892 0.447165 12.8599 0.200353C12.8339 0.19559 12.8079 0.207494 12.7945 0.231302C12.6187 0.543981 12.4239 0.951897 12.2876 1.27252C10.7503 1.04238 9.22099 1.04238 7.71527 1.27252C7.57887 0.94477 7.37707 0.543981 7.20048 0.231302C7.18707 0.208288 7.16107 0.196384 7.13504 0.200353C5.70659 0.446376 4.33963 0.877305 3.06411 1.463C3.05307 1.46776 3.04361 1.4757 3.03732 1.48601C0.444493 5.35964 -0.265792 9.13807 0.0826501 12.8696C0.0842267 12.8879 0.0944749 12.9054 0.108665 12.9165C1.81934 14.1727 3.47642 14.9354 5.10273 15.4409C5.12876 15.4489 5.15634 15.4394 5.1729 15.4179C5.55761 14.8926 5.90054 14.3386 6.19456 13.7561C6.21192 13.722 6.19535 13.6815 6.15989 13.668C5.61594 13.4617 5.098 13.2101 4.59977 12.9244C4.56037 12.9014 4.55721 12.845 4.59346 12.818C4.69831 12.7395 4.80318 12.6577 4.9033 12.5752C4.92141 12.5601 4.94665 12.5569 4.96794 12.5665C8.24107 14.0609 11.7846 14.0609 15.0191 12.5665C15.0404 12.5562 15.0657 12.5593 15.0846 12.5744C15.1847 12.6569 15.2895 12.7395 15.3952 12.818C15.4314 12.845 15.4291 12.9014 15.3897 12.9244C14.8914 13.2156 14.3735 13.4617 13.8288 13.6672C13.7933 13.6807 13.7775 13.722 13.7949 13.7561C14.0952 14.3378 14.4381 14.8918 14.8157 15.4171C14.8315 15.4394 14.8599 15.4489 14.8859 15.4409C16.5201 14.9354 18.1772 14.1727 19.8879 12.9165C19.9028 12.9054 19.9123 12.8887 19.9139 12.8704C20.3309 8.55632 19.2154 4.80888 16.9568 1.4868C16.9513 1.4757 16.9419 1.46776 16.9308 1.463ZM6.68335 10.5975C5.69792 10.5975 4.88594 9.6928 4.88594 8.58173C4.88594 7.47066 5.68217 6.56595 6.68335 6.56595C7.69239 6.56595 8.49651 7.4786 8.48073 8.58173C8.48073 9.6928 7.68451 10.5975 6.68335 10.5975ZM13.329 10.5975C12.3435 10.5975 11.5316 9.6928 11.5316 8.58173C11.5316 7.47066 12.3278 6.56595 13.329 6.56595C14.338 6.56595 15.1421 7.4786 15.1264 8.58173C15.1264 9.6928 14.338 10.5975 13.329 10.5975Z"
        fill="#00083C"
      />
    </svg>
  );
}
