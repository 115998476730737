import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { ThinLayout } from "@tudigo-monorepo/web-tudigo-components";

import { fromBlockObjectGetComponent } from "../../../../shared/cms/from-block-object-get-component";
import { LimitedAccess } from "../../../../shared/components/limited-access";
import { useProjectContext } from "../../project.context";
import { ModalitiesSummaryCard } from "./modalities-summary-card/modalities-summary-card";
import { ProjectModalitiesLegalInfo } from "./project-modalities-legal-info";

export function ProjectModalities() {
  const project = useProjectContext();
  const { isQualifiedOrWma } = useUserBusiness();

  if (!isQualifiedOrWma) {
    return (
      <ThinLayout>
        <LimitedAccess />
      </ThinLayout>
    );
  }

  return (
    <section className="flex flex-col gap-y-6">
      {project?.content?.modalities
        .map((block) =>
          fromBlockObjectGetComponent({
            ...block,
          }),
        )
        .map((Block, index) => <Block key={index} />)}

      <ModalitiesSummaryCard />
      <ProjectModalitiesLegalInfo />
    </section>
  );
}
