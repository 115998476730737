import { ClubHead } from "../../../club-page/components/club-head/club-head";
import { ClubPremiumAdvantageList } from "../../../shared-internal/components/club-premium-advantage-list";
import { useGetClubV2UsingIdPathParams } from "../../../shared-internal/hooks/use-get-club-v2-using-id-path-params";
import { ClubPremiumSubscribeCard } from "./club-premium-subscribe-card";

export function SubscribeClubStep1OfferPage() {
  const club = useGetClubV2UsingIdPathParams();

  if (!club) {
    return null;
  }

  return (
    <section className="container mx-auto flex flex-col gap-y-6 p-4 lg:py-10">
      <ClubHead club={club} />

      <div className="flex flex-col gap-6 pt-4 md:flex-row">
        <article className="border-light-1 w-full rounded-lg border bg-white p-6">
          <ClubPremiumAdvantageList />
        </article>
        <article className="border-light-1 bg-success-light h-fit w-full rounded-lg border p-6">
          <ClubPremiumSubscribeCard />
        </article>
      </div>
    </section>
  );
}
