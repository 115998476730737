import {
  Project,
  ProjectDocumentEnum,
} from "@tudigo-monorepo/core-tudigo-api-models";

import {
  getProjectOptionalDocumentTypes,
  getProjectRequiredDocumentTypes,
} from "../utils/project-documents-utils";

export const useProjectDocuments = (project: Project | null) => {
  const requiredDocumentTypes = getProjectRequiredDocumentTypes(project);
  const optionalDocumentTypes = getProjectOptionalDocumentTypes(project);

  const requiredDocuments =
    project?.projectDocuments?.filter((doc) =>
      requiredDocumentTypes.includes(doc.type as ProjectDocumentEnum),
    ) ?? [];
  const optionalDocuments =
    project?.projectDocuments?.filter((doc) =>
      optionalDocumentTypes.includes(doc.type as ProjectDocumentEnum),
    ) ?? [];

  const projectDocuments = [...requiredDocuments, ...optionalDocuments];

  return {
    requiredDocuments,
    optionalDocuments,
    projectDocuments,
  };
};
