import {
  Form,
  FormPage,
  FormSection,
} from "@tudigo-monorepo/core-tudigo-api-models";

export const isFormPage = (page: FormPage): page is FormPage => {
  return !!(page as FormPage)?.id;
};

export const normalizePages = (form: Form, summaryPages: FormPage[] = []) => {
  const sections = form.sections as FormSection[];

  const pages = sections
    .flatMap(
      (section) =>
        section.pages?.map((page) => {
          return {
            ...page,
            sectionKey: section.key,
          } as FormPage;
        }) ?? [],
    )
    .sort((a, b) => a.index! - b.index!);

  if (summaryPages) {
    summaryPages.forEach((sumPage) => {
      pages.push({
        ...sumPage,
        index: pages.length + 1,
      });
    });
  }

  return pages;
};
