import React from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { ListElementProps } from "./list-element";

type LightListElementProps = Partial<ListElementProps> & {
  children: React.ReactNode;
};

export function ListElementComposable(props: LightListElementProps) {
  const { children, className, ...rest } = props;

  const listElementClassName = cn(
    "rounded-lg p-4",
    "border-medium-2 border border-solid",
    "min-w-[280px] ",
    "flex items-center gap-2",
    className,
  );

  return (
    <div role="listitem" className={listElementClassName} {...rest}>
      {children}
    </div>
  );
}
