import {
  InternalKycStatus,
  Investment,
  PaymentMethodType,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { SIGNATURE_REQUESTS } from "@tudigo-monorepo/core-tudigo-utils";

type FunnelStep = {
  id: string;
  label: string;
  path: string;
  index: number;
};

export function getFunnelStepsConfiguration(
  projectId: string,
  investmentId: string,
): FunnelStep[] {
  return [
    {
      id: "select-amount",
      label: "Investissement",
      path: `/projects/${projectId}/invest/${investmentId}/select-amount`,
      index: 0,
    },
    {
      id: "select-organization",
      label: "Compte",
      path: `/projects/${projectId}/invest/${investmentId}/select-organization`,
      index: 1,
    },
    {
      id: "agreement-and-security",
      label: "Consentement et sécurité",
      path: `/projects/${projectId}/invest/${investmentId}/agreement-and-security`,
      index: 2,
    },
    {
      id: "sign-subscription-form",
      label: "Bulletin de souscription",
      path: `/projects/${projectId}/invest/${investmentId}/sign-subscription-form`,
      index: 3,
    },
    {
      id: "select-payment-method",
      label: "Paiement",
      path: `/projects/${projectId}/invest/${investmentId}/select-payment-method`,
      index: 4,
    },
    {
      id: "sign-commitment-letter",
      label: "Paiement",
      path: `/projects/${projectId}/invest/${investmentId}/sign-commitment-letter`,
      index: 5,
    },
    {
      id: "final-page",
      label: "Finalisation",
      path: `/projects/${projectId}/invest/${investmentId}/final-page`,
      index: 6,
    },
  ];
}

function requirementsForStepSelectOrganizationAreFulfilled(
  investment: Investment,
): boolean {
  return (
    investment.totalAmountInCents !== null &&
    investment.investorOrganization?.internalKycStatus !==
      InternalKycStatus.APPROVED
  );
}

function requirementsForStepAgreementAndSecurityAreFulfilled(
  investment: Investment,
): boolean {
  return (
    !!investment.totalAmountInCents &&
    !!investment.investorOrganization &&
    investment.projectAgreementAndSecurityConsent === null
  );
}

function requirementsForStepSignSubscriptionFormAreFulfilled(
  investment: Investment,
): boolean {
  return (
    !!investment.totalAmountInCents &&
    !!investment.investorOrganization &&
    investment.projectAgreementAndSecurityConsent !== null &&
    investment.subscriptionFormStatus === "pending"
  );
}

function requirementsForStepSelectPaymentMethodAreFulfilled(
  investment: Investment,
): boolean {
  const hasTotalAmount = !!investment.totalAmountInCents;
  const hasInvestorOrganization = !!investment.investorOrganization;
  const hasSignedSubscriptionFormDocument =
    investment.subscriptionFormStatus === "signed";
  const isCardPaymentMethod = investment.paymentMethodType === "card";

  return (
    (hasTotalAmount &&
      hasInvestorOrganization &&
      hasSignedSubscriptionFormDocument &&
      !investment.paymentMethodType) ||
    (isCardPaymentMethod &&
      !["payed", "amount_received"].includes(investment.paymentStatus))
  );
} // add another prop to check payment is not done yet

function requirementsForStepSignCommitmentLetterAreFulfilled(
  investment: Investment,
): boolean {
  return !!(
    investment.project.commitmentLetterSigningEnabled &&
    investment.commitmentLetterDocument &&
    investment.commitmentLetterDocument.signatureRequest &&
    investment.commitmentLetterDocument.signatureRequest.status ===
      SIGNATURE_REQUESTS.STATUS.ONGOING
  );
}

export function getAvailableStepsForInvestment(
  investment: Investment | null | undefined,
  stepsConfig: FunnelStep[],
): string[] {
  if (!investment?.totalAmountInCents) {
    return [stepsConfig[0].id];
  }

  if (requirementsForStepSelectPaymentMethodAreFulfilled(investment)) {
    return [stepsConfig[4].id];
  }

  if (requirementsForStepSignSubscriptionFormAreFulfilled(investment)) {
    return [
      stepsConfig[0].id,
      stepsConfig[1].id,
      stepsConfig[2].id,
      stepsConfig[3].id,
    ];
  }

  if (requirementsForStepAgreementAndSecurityAreFulfilled(investment)) {
    return [stepsConfig[0].id, stepsConfig[1].id, stepsConfig[2].id];
  }

  if (requirementsForStepSelectOrganizationAreFulfilled(investment)) {
    return [stepsConfig[0].id, stepsConfig[1].id];
  }

  if (
    investment.paymentMethodType === PaymentMethodType.PEA ||
    investment.paymentMethodType === PaymentMethodType.PEA_PME
  ) {
    if (requirementsForStepSignCommitmentLetterAreFulfilled(investment)) {
      return [stepsConfig[5].id];
    }
  }

  return [stepsConfig[6].id];
}
