import React from "react";
import { toast } from "react-toastify";

import { PaymentMethodType } from "@tudigo-monorepo/core-tudigo-api-models";
import { useSendWmaInvestmentFolderEmailMutation } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  Button,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { ProjectMetricsCard } from "../../../../components/project-metric-card";
import { useEditWmaInvestmentPageContext } from "../../../../edit-wma-investment-page";

export function EditWmaInvestmentPaymentTabPeaWaitingProjectClosureSection() {
  const { investment, wmaInvestment } = useEditWmaInvestmentPageContext();
  const project = investment.project;
  const signedCommitmentLetterDocument =
    investment.signedCommitmentLetterDocument;
  const archiveEspDocument = investment.archiveEspDocument;

  const { mutate: sendWmaInvestmentFolderEmail, isPending } =
    useSendWmaInvestmentFolderEmailMutation({
      onSuccess: () => {
        toast((content) => {
          return (
            <Alert
              icon="Checked"
              variant="success"
              size="S"
              close={content.closeToast}
              title="Email envoyé"
            />
          );
        });
      },
      onError: () => {
        toast((content) => {
          return (
            <Alert
              icon="Error"
              variant="error"
              size="S"
              close={content.closeToast}
              title="Une erreur est survenue"
            />
          );
        });
      },
    });

  return (
    <div className="m-6 flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <Title level="h4">Lettre d&apos;engagement</Title>
        <div className="flex gap-4">
          <Button label="Envoyer le lien par email" iconRight="Mail" disabled />
          <Button label="Copier le lien" iconRight="Duplicate" disabled />
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <Title level="h4">
          Procéder au paiement :{" "}
          {investment.paymentMethodType === PaymentMethodType.PEA
            ? "PEA"
            : "PEA-PME"}
        </Title>
        <Typography variant="body2">
          Les documents sont disponibles dès que le plancher de la collecte est
          atteint.
        </Typography>
        <ProjectMetricsCard project={project} />
        <div className="mt-2 flex gap-4">
          <Button
            label="Envoyer le dossier par email"
            iconRight="Mail"
            isLoading={isPending}
            onClick={() => {
              if (!wmaInvestment?.id) return;

              sendWmaInvestmentFolderEmail({
                wmaInvestmentId: wmaInvestment?.id,
              });
            }}
            disabled={!signedCommitmentLetterDocument}
          />
          <Button
            label="Télécharger le dossier"
            iconRight="Download"
            onClick={() => {
              window.open(
                `${archiveEspDocument?.document?.signedUrl}`,
                "_blank",
              );
            }}
            disabled={!signedCommitmentLetterDocument}
          />
        </div>
      </div>
    </div>
  );
}
