import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import {
  TudigoError,
  TudigoResponse,
  UpdateInvestmentBody,
} from "@tudigo-monorepo/core-tudigo-api";
import { Investment } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient, getQueryDetailKey } from "../../..";

type UpdateInvestmentRequestParams = {
  investmentId: Investment["id"];
  data: UpdateInvestmentBody;
  query?: { fields?: string };
};

export const updateInvestment = async (
  params: UpdateInvestmentRequestParams,
) => {
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.investments.updateInvestment(
    params.investmentId,
    params.data,
  );

  return response.data;
};

export const useUpdateInvestmentMutation = (
  options?: UseMutationOptions<
    TudigoResponse<Investment>,
    TudigoError,
    UpdateInvestmentRequestParams
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (params: UpdateInvestmentRequestParams) =>
      updateInvestment(params),
    onSuccess: (...props) => {
      const [response, params] = props;
      const investmentQueryKey = getQueryDetailKey({
        key: QUERY_KEYS.INVESTMENTS,
        id: params.investmentId!,
        fields: params?.query?.fields,
      });

      queryClient.setQueryData(investmentQueryKey, response.data);

      options?.onSuccess?.(...props);
    },
  });
};
