import { Link } from "react-router-dom";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { ORGANIZATIONS } from "@tudigo-monorepo/core-tudigo-utils";
import { PageEnum, useSpaceBusiness } from "@tudigo-monorepo/tudigo-session";
import { Alert, Button, Title } from "@tudigo-monorepo/web-tudigo-components";

import { useKYCContext } from "../../../kyc-page";
import { KycSectionChipStatus } from "../../../shared/components/kyc-section-chip-status";
import { KycSectionType } from "../../../shared/hooks/use-kyc-section-chip-status-props";
import { checkIfCompanyOrganizationPersonsDocumentsRequirementsAreMet } from "../../../utils/kyc-utils";
import { IndividualDocumentSummaryCollapseListElement } from "../components/individual-documents-summary-collapse-list-element";
import { UboDocumentsSummaryCollapseListElement } from "../components/ubo-documents-summary-collapse-list-element";

interface UboDocumentsSummarySectionProps {
  section: KycSectionType;
}

export function UboDocumentsSummarySection(
  props: UboDocumentsSummarySectionProps,
) {
  const { section } = props;
  const { t } = useTranslation();
  const { organization } = useKYCContext();

  const { currentPagePaths } = useSpaceBusiness();
  const kycPagePath = currentPagePaths[
    PageEnum.ORGANIZATION_KYC_FUNNEL
  ].replace(":organizationId", organization.id);

  const ultimateBeneficialOwners = organization.ultimateBeneficialOwners;
  const editPagePath = `${kycPagePath}/documents`;

  const isError = !checkIfCompanyOrganizationPersonsDocumentsRequirementsAreMet(
    ultimateBeneficialOwners,
    organization.type === ORGANIZATIONS.TYPE.INDIVIDUAL,
  );

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Title level="h4">
            {t("kyc_funnel.step_documents.form.organization_document.title")}
          </Title>
          <KycSectionChipStatus section={section} />
        </div>
        <Link to={editPagePath}>
          <Button
            label={t("kyc_funnel.final_summary.edit_btn.label")}
            iconLeft="Pen"
            variant="tertiary"
          />
        </Link>
      </div>

      <div className="border-light-2 my-4 border-b" />

      <div className="flex flex-col gap-4">
        {isError && (
          <Alert
            variant="error"
            size="F"
            icon="Cross"
            title={t(
              "kyc_funnel.step_company_information.collapse_beneficial_owner.alert.title",
            )}
            description={t(
              "kyc_funnel.step_company_information.collapse_beneficial_owner.alert.description",
            )}
          />
        )}

        {ultimateBeneficialOwners.map((ubo) => {
          if (organization.type === ORGANIZATIONS.TYPE.COMPANY) {
            return (
              <UboDocumentsSummaryCollapseListElement
                beneficialOwner={ubo}
                key={`company-${ubo.id}`}
              />
            );
          }

          return (
            <IndividualDocumentSummaryCollapseListElement
              beneficialOwner={ubo}
              key={`individual-${ubo.id}`}
            />
          );
        })}
      </div>
    </div>
  );
}
