import { ClubV2 } from "../club-v2/models";
import { CMSBlock } from "../cms";
import { Contract } from "../contracts";
import { Document } from "../documents";
import { Image } from "../images";
import { Investment } from "../investments";
import { Media } from "../media/models";
import { Organization } from "../organizations";
import { User } from "../users";

export enum BondsRepaymentTypeEnum {
  AT_MATURITY = "at_maturity", // in fine
  CONSTANT_ANNUITY = "constant_annuity",
  CONSTANT_AMORTIZATION = "constant_amortization",
  AT_MATURITY_V2 = "at_maturity_v2", // in fine
  CONSTANT_ANNUITY_V2 = "constant_annuity_v2",
  CONSTANT_AMORTIZATION_V2 = "constant_amortization_v2",
}

export enum BondsRepaymentFrequencyEnum {
  MONTHLY = "monthly",
  QUARTERLY = "quarterly",
  SEMI_ANNUAL = "semi_annual",
  YEARLY = "yearly",
  UNIQUE = "unique",
}

export enum RepaymentScheduleVisibilityEnum {
  HIDDEN = "hidden",
  GROSS = "gross",
  GROSS_AND_NET = "gross_and_net",
}

export interface ProjectContent {
  id: string | null;
  locale: string;
  version: number;
  name: string;
  shortDescription: string;
  logo?: Image | null;
  cover: Image | null;
  description: CMSBlock[];
  writer: Partial<User> | null;
  shortPresentation: CMSBlock[];
  modalities: CMSBlock[];
  medias?: Media[];
  slug?: string;
}

export interface BondsMechanicSettings {
  annualInterestNoDecimalRate: number;
  annualInterestPercent: number;
  bondLoanDate?: string;
  capitalDefermentDurationInMonth: number;
  durationInMonth: number;
  isConvertible: boolean;
  minimumTicketAmountInCents: number;
  nonConversionBonusNoDecimalRate: number;
  penaltyRate: number;
  repaymentStartDate: Date | string;
  repaymentType: `${BondsRepaymentTypeEnum}`;
  repaymentFrequency: `${BondsRepaymentFrequencyEnum}`;
  repaymentScheduleVisibility: `${RepaymentScheduleVisibilityEnum}`;
  unitPriceInCents: number;
  penaltyNoDecimalRate: number;
  conversionHorizonInMonth?: number | null;
  entryFeesNoDecimalRate: number;
  managementFeesNoDecimalRate: number;
  taxesApplied: boolean;
}

export interface SharesMechanicSettings {
  unitPriceInCents: number;
  minimumTicketAmountInCents: number;
  targetedYield?: {
    minInPercent: number;
    mediumInPercent: number;
    maxInPercent: number;
  } | null;
  preOperationValuation: number;
  sharesCount: number;
  buyPack: boolean;
  buyPackPeriodInMonth: number;
  internalNoDecimalRateOfReturn: number;
  internalRateOfReturn: number;
  potentialReturnPeriodInMonth: number;
  carriedNoDecimalRate: number;
  entryFeesNoDecimalRate: number;
  managementFeesNoDecimalRate: number;
  capitalIncreaseDate?: string;
  capitalIncreaseDecisionDate?: string;
}

export interface ProjectGoal {
  minInCents: number;
  maxInCents: number;
}

export interface ProjectCategory {
  code: string;
  name: string;
  image: Image | null;
  status: string;
}

export type ProjectMarketingFlag = "new" | "last_days";

export interface ProjectMarketingSettings {
  simulatorVisibility: "visible" | "hidden";
  goalVisibility: "visible" | "hidden";
  gaugeVisibility: "visible" | "hidden";
  flag: ProjectMarketingFlag | null;
  addedAmountInCents: number | null;
  addedInvestorCount: number | null;
}

export enum ProjectDocumentEnum {
  KEY_INFORMATION_DOCUMENT = "key_information_document",
  INVESTMENT_NOTE = "investment_note",
  SHARE_HOLDER_AGREEMENT = "share_holder_agreement",
  BOND_PACT = "bond_pact",
  TRADE_REGISTRATION_CERTIFICATE = "trade_registration_certificate",
  LAST_ASSEMBLY = "last_assembly",
  INCOME_TAX = "income_tax",
  TAX_ELIGIBILITY_CERTIFICATE = "tax_eligibility_certificate",
  CERTIFICATE_OF_HONOUR = "certificate_of_honour",
  INCOME_TAX_SME_CERTIFICATE = "income_tax_sme_certificate",
  TERMS_AND_CONDITIONS = "terms_and_conditions",
  PROGRAM_PRESENTATION = "program_presentation",
  DEBT_STATEMENT_ISSUING_COMPANY = "debt_statement_issuing_company",
  DEBT_STATEMENT_GUARANTOR_COMPANY = "debt_statement_guarantor_company",
  KBIS = "kbis",
  INTENTION_LETTER = "intention_letter",
  STATUTES = "statutes",
  ASSEMBLY_REPORT_CAPITAL_RAISING = "assembly_report_capital_raising",
  WAIVER_PREFERENTIAL_SUBSCRIPTION_RIGHTS = "waiver_preferential_subscription_rights",
  SIGNATURE_PROOF_ON_PV_AND_STATUTES = "signature_proof_on_pv_and_statutes",
  OTHER = "other",
}

export const REQUIRED_PROJECT_DOCUMENTS = [
  `${ProjectDocumentEnum.SHARE_HOLDER_AGREEMENT}`,
  `${ProjectDocumentEnum.BOND_PACT}`,
  `${ProjectDocumentEnum.STATUTES}`,
  `${ProjectDocumentEnum.KBIS}`,
  `${ProjectDocumentEnum.KEY_INFORMATION_DOCUMENT}`,
  `${ProjectDocumentEnum.INVESTMENT_NOTE}`,
  `${ProjectDocumentEnum.ASSEMBLY_REPORT_CAPITAL_RAISING}`,
  `${ProjectDocumentEnum.WAIVER_PREFERENTIAL_SUBSCRIPTION_RIGHTS}`,
  `${ProjectDocumentEnum.SIGNATURE_PROOF_ON_PV_AND_STATUTES}`,
];

export type ProjectDocumentType = `${ProjectDocumentEnum}`;

export type ProjectDocumentVisibilityType = "public" | "qualified" | "investor";

export interface ProjectDocument {
  id: string | null;
  locale: string;
  document: Document | null;
  type: ProjectDocumentType;
  name: string | null;
  visibility?: ProjectDocumentVisibilityType;
  createdAt?: string;
}

export interface ProjectMaturity {
  code: string;
  name: string;
  image: Image | null;
  status: string;
}

export interface ProjectTag {
  code: string;
  name: string;
  image: Image | null;
  status: string;
}

export interface ProjectNews {
  id: string;
  title: string;
  type: "document" | "information";
  description: string | null;
  content: string | null;
  document: Document | null;
  createdAt: string;
}

export enum TaxExemptionMechanicsEnum {
  IR = "IR",
  PEA = "PEA",
  PEA_PME = "PEA_PME",
  BTER = "150_0_BTER",
  JEIR = "JEIR",
  JEIC = "JEIC",
}

export type ProjectStatus =
  | "draft"
  | "scheduled"
  | "started"
  | "prefinished"
  | "finished";

export type ProjectTransitionStatus =
  | "start"
  | "schedule"
  | "unschedule"
  | "prefinish"
  | "finish";

export type ProjectFlagType = Omit<ProjectStatus, "draft"> &
  ProjectMarketingFlag;

export interface ProjectCommon {
  id: string;
  categories: ProjectCategory[] | null;
  commitmentLetterSigningEnabled: boolean | null;
  content: ProjectContent | null;
  currency: string;
  finishedAt: string | number | null;
  fundraisingProgressMinGoal: number;
  fundraisingProgressMaxGoal: number;
  goal: ProjectGoal | null;
  holderOrganization: Organization | null;
  holdingFundsTransferDate?: string;
  impactScore: number | null;
  isReferenced: boolean;
  marketingSettings: ProjectMarketingSettings;
  maturity: ProjectMaturity | null;
  myOngoingInvestment: Omit<
    Investment,
    | "project"
    | "subscriptionFormDocument"
    | "commitmentLetterDocument"
    | "signedSubscriptionFormDocument"
    | "signedCommitmentLetterDocument"
    | "archiveEspDocument"
  > | null;
  projectAgreementAndSecurityContract: Contract | null;
  projectDocuments: ProjectDocument[] | null;
  scheduledEndDate: string | null;
  scheduledStartDate: string | null;
  startedAt: string | number | null;
  status: ProjectStatus;
  tags: ProjectTag[] | null;
  taxExemptionMechanics: TaxExemptionMechanicsEnum[] | null;
  totalAmountCollectedInCents: number;
  totalInvestorsCount: number;
  vehicleOrganization: Organization | null;
  club?: ClubV2;
}

export interface BondsProject extends ProjectCommon {
  mechanic: "bonds";
  bondsMechanicSettings: BondsMechanicSettings;
}

export interface SharesProject extends ProjectCommon {
  mechanic: "shares";
  sharesMechanicSettings: SharesMechanicSettings;
}

export type Project = BondsProject | SharesProject;

export type ProjectDisplayedStatusFilter =
  | "started"
  | "draft"
  | "prefinished"
  | "finished"
  | "scheduled";

export const PROJECT_DOCUMENTS_TYPES: ProjectDocumentType[] = [
  "key_information_document",
  "share_holder_agreement",
  "bond_pact",
  "investment_note",
  "certificate_of_honour",
  "debt_statement_guarantor_company",
  "debt_statement_issuing_company",
  "income_tax",
  "income_tax_sme_certificate",
  "last_assembly",
  "program_presentation",
  "tax_eligibility_certificate",
  "trade_registration_certificate",
  "terms_and_conditions",
  "kbis",
  "statutes",
  "assembly_report_capital_raising",
  "waiver_preferential_subscription_rights",
  "signature_proof_on_pv_and_statutes",
  "other",
];

export const PROJECT_DOCUMENT_VISIBILITIES: ProjectDocumentVisibilityType[] = [
  "public",
  "qualified",
  "investor",
];
