import * as React from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { useTopbar } from "./use-topbar";

type TopbarProps = {
  children: React.ReactNode;
  expanded?: boolean;
};

export function Topbar(props: TopbarProps) {
  const { children, expanded = false } = props;

  const visible = useTopbar(expanded);

  return (
    <div
      id="topbar"
      className={cn(
        "fixed left-0 top-0 z-30 block w-full bg-white duration-200 ease-in-out lg:hidden",
        {
          "h-0 min-h-0": !visible,
          "h-[60px] min-h-[60px]": visible,
          "h-screen": expanded,
        },
      )}
    >
      {visible ? children : null}
    </div>
  );
}
