import React from "react";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Tabs } from "@tudigo-monorepo/web-tudigo-components";

export const CirclesListingPageHead = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-col gap-1">
        <p>{t("circles_listing_page.title")}</p>
        <p className="text-dark-1 ">{t("circles_listing_page.subtitle")}</p>
      </div>
      <Tabs
        tabs={[
          {
            name: t("circles_listing_page.tabs.all_circles"),
            redirectTo: "all",
          },
          {
            name: t("circles_listing_page.tabs.my_circles"),
            redirectTo: "my-circles",
          },
        ]}
        className="mt-6"
      />
    </>
  );
};
