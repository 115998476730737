export function InfoCardItem(props: {
  label: string;
  value: string | number | undefined | null;
}) {
  const { label, value } = props;

  return (
    <li className="text-primary flex items-center justify-between">
      <p className="font-montserrat text-[13px]">{label}</p>
      <p className="font-montserrat text-[13px] font-semibold">
        {value ?? "N/A"}
      </p>
    </li>
  );
}
