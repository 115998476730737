import { useState } from "react";
import { toast } from "react-toastify";

import { TudigoError } from "@tudigo-monorepo/core-tudigo-api";
import { Investment } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  FormContextProvider,
  getSmartFormComponents,
} from "@tudigo-monorepo/web-feature-form";
import { investmentFunnelFields } from "@tudigo-monorepo/web-feature-investment-funnel";
import { useUpdateInvestmentMutation } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  Modal,
  TextField,
} from "@tudigo-monorepo/web-tudigo-components";

import { getInvestmentAvailablePaymentMethods } from "../../../shared/utils/investment-utils";

type ChangeInvestmentPaymentMethodModalProps = {
  investment: Investment;
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
};

export function ChangeInvestmentPaymentMethodModal(
  props: ChangeInvestmentPaymentMethodModalProps,
) {
  const { investment, isModalOpen, setIsModalOpen } = props;
  const { t } = useTranslation();

  const [error, setError] = useState<TudigoError | null>(null);
  const [formData, setFormData] = useState<{
    paymentMethod: Investment["paymentMethodType"];
  }>({
    paymentMethod: investment.paymentMethodType,
  });

  const { SmartSelectField } = getSmartFormComponents<typeof formData>();

  const { mutate: updateInvestment, isPending: isLoading } =
    useUpdateInvestmentMutation({
      onSuccess: () => {
        setIsModalOpen(false);
        toast((content) => (
          <Alert
            close={content.closeToast}
            icon="Checked"
            variant="success"
            title={t(
              "investments.back_office.investment_detail.change_payment_method_modal.toast.success",
            )}
          />
        ));
      },
      onError: (err) => {
        setError(err);
        toast((content) => (
          <Alert
            close={content.closeToast}
            icon="Error"
            variant="error"
            title={t(
              "investments.back_office.investment_detail.change_payment_method_modal.toast.error",
            )}
          />
        ));
      },
    });

  const handleSubmit = () => {
    updateInvestment({
      investmentId: investment.id,
      query: {
        fields: investmentFunnelFields,
      },
      data: {
        paymentMethodType: formData.paymentMethod,
      },
    });
  };

  if (
    investment.paymentStatus !== "payed" ||
    investment.investorOrganization.type !== "individual"
  ) {
    return null;
  }

  return (
    <Modal
      title={t(
        "investments.back_office.investment_detail.change_payment_method_modal.title",
      )}
      confirmLabel={t(
        "investments.back_office.investment_detail.change_payment_method_modal.confirm_button.label",
      )}
      isOpen={isModalOpen}
      setIsOpen={() => setIsModalOpen(false)}
      confirmAction={handleSubmit}
      confirmButtonProps={{
        isLoading: isLoading,
        disabled:
          !formData.paymentMethod ||
          formData.paymentMethod === investment.paymentMethodType,
        variant: "primary",
      }}
      className="min-h-[605px] w-[calc(100%-32px)] sm:max-w-[654px]"
    >
      <div className="flex flex-col gap-y-4 p-4">
        <div>
          <Alert
            icon="Info"
            variant="info"
            className="mb-4"
            size="F"
            title={t(
              "investments.back_office.investment_detail.change_payment_method_modal.content.alert.title",
            )}
            description={
              <div className="flex flex-col gap-y-2.5">
                <p className="text-primary font-sans text-xs">
                  {t(
                    "investments.back_office.investment_detail.change_payment_method_modal.content.alert.description_top",
                  )}
                </p>
                <p className="text-primary font-sans text-xs">
                  {t(
                    "investments.back_office.investment_detail.change_payment_method_modal.content.alert.description_bottom",
                  )}
                </p>
              </div>
            }
          />
        </div>

        <div className="border-light-1 flex flex-col gap-4 rounded-lg border p-4">
          <TextField
            label={t(
              "investments.back_office.investment_detail.change_payment_method_modal.content.form.select_project",
            )}
            value={investment.project.content?.name}
            disabled
            required
          />
          <TextField
            label={t(
              "investments.back_office.investment_detail.change_payment_method_modal.content.form.select_client",
            )}
            value={`${investment.investorOrganization?.name ?? ""} - ${
              investment.investorOrganization?.organizationRepresentative
                ?.email ?? ""
            }`}
            disabled
            required
          />
          <TextField
            label={t(
              "investments.back_office.investment_detail.change_payment_method_modal.content.form.select_account",
            )}
            value={t(
              "investments.back_office.investment_detail.change_payment_method_modal.content.form.select_account_value",
            )}
            disabled
            required
          />

          <FormContextProvider
            error={error}
            formData={formData}
            setFormData={setFormData}
          >
            <SmartSelectField
              name="paymentMethod"
              label={t(
                "investments.back_office.investment_detail.change_payment_method_modal.content.form.select_payment_method",
              )}
              items={getInvestmentAvailablePaymentMethods(investment).map(
                (method) => ({
                  value: method,
                  label: t(
                    `investments.back_office.investment_detail.change_payment_method_modal.content.form.method.${method}`,
                  ),
                }),
              )}
              getValueFromItem={(item) => item.value}
              labelFunction={(item) => item.label}
              comparisonFunction={(item, selection) => item.value === selection}
              valid={formData.paymentMethod !== investment.paymentMethodType}
              required
            />
          </FormContextProvider>
        </div>
      </div>
    </Modal>
  );
}
