import React, { ForwardedRef, InputHTMLAttributes } from "react";
import DatePicker, {
  CalendarContainer,
  CalendarContainerProps,
  ReactDatePickerCustomHeaderProps,
  ReactDatePickerProps,
} from "react-datepicker";
import { FormattedDate, useIntl } from "react-intl";

import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Icon } from "../icons/icon";
import { InputGroup, InputGroupProps } from "../input-group";
import { Title } from "../title";
import { Typography } from "../typography";

type SimpleInputDateProps = {
  range: false;
  onChange?: (value?: Date) => void;
  value?: Date;
} & InputGroupProps &
  Omit<InputHTMLAttributes<HTMLInputElement>, "onChange" | "value">;

type InputDateRangeProps = {
  range: true;
  value: [start?: Date, end?: Date];
  onChange?: (dates: [start?: Date, end?: Date]) => void;
} & InputGroupProps &
  Omit<InputHTMLAttributes<HTMLInputElement>, "onChange" | "value">;

export type InputDateProps = (InputDateRangeProps | SimpleInputDateProps) & {
  popperPlacement?: ReactDatePickerProps["popperPlacement"];
  showTimeSelect?: boolean;
};

const isRange = (b: InputDateProps): b is InputDateRangeProps => {
  return (b as InputDateRangeProps).range;
};

export function InputDate(props: InputDateProps) {
  const {
    id,
    label,
    className,
    value = "",
    disabled = false,
    required = false,
    errors,
    groupRef,
    popperPlacement,
    valid,
  } = props;

  const intl = useIntl();

  let inputId = React.useId();
  inputId = id || inputId;

  const MyContainer = (props: CalendarContainerProps) => {
    return (
      <CalendarContainer className={props.className}>
        {props.children}
      </CalendarContainer>
    );
  };

  const renderCustomHeader = (props: ReactDatePickerCustomHeaderProps) => {
    const {
      decreaseYear,
      decreaseMonth,
      increaseYear,
      increaseMonth,
      prevMonthButtonDisabled,
      prevYearButtonDisabled,
      nextMonthButtonDisabled,
      nextYearButtonDisabled,
      monthDate,
    } = props;

    return (
      <div className="flex items-center justify-center">
        <button
          className="p-3"
          onClick={(e) => {
            decreaseYear();
            e.preventDefault();
            e.stopPropagation();
          }}
          disabled={prevYearButtonDisabled}
        >
          <Icon
            name="FirstPagination"
            primaryColor={themeColors["dark-2"]}
            size="XS"
          />
        </button>
        <button
          className="p-3"
          onClick={(e) => {
            decreaseMonth();
            e.preventDefault();
            e.stopPropagation();
          }}
          disabled={prevMonthButtonDisabled}
        >
          <Icon
            name="ChevronRight"
            primaryColor={themeColors["dark-2"]}
            size="XS"
            className="rotate-180"
          />
        </button>
        <Title h3Brand="brand-2" level="h3" className="text-primary">
          <FormattedDate value={monthDate} month="short" year="numeric" />
        </Title>
        <button
          className="p-3"
          onClick={(e) => {
            increaseMonth();
            e.preventDefault();
            e.stopPropagation();
          }}
          disabled={nextMonthButtonDisabled}
        >
          <Icon
            name="ChevronRight"
            primaryColor={themeColors["dark-2"]}
            size="XS"
          />
        </button>
        <button
          className="p-3 "
          onClick={(e) => {
            increaseYear();
            e.preventDefault();
            e.stopPropagation();
          }}
          disabled={nextYearButtonDisabled}
        >
          <Icon
            name="LastPagination"
            primaryColor={themeColors["dark-2"]}
            size="XS"
          />
        </button>
      </div>
    );
  };

  const getWeekdays = (dayOfWeek: string) => {
    switch (dayOfWeek) {
      case "Sunday":
        return (
          <Typography variant="body3-semibold">
            <FormattedDate weekday="narrow" value={new Date("2023-12-24")} />
          </Typography>
        );
      case "Monday":
        return (
          <Typography variant="body3-semibold">
            <FormattedDate weekday="narrow" value={new Date("2023-12-25")} />
          </Typography>
        );
      case "Tuesday":
        return (
          <Typography variant="body3-semibold">
            <FormattedDate weekday="narrow" value={new Date("2023-12-26")} />
          </Typography>
        );
      case "Wednesday":
        return (
          <Typography variant="body3-semibold">
            <FormattedDate weekday="narrow" value={new Date("2023-12-27")} />
          </Typography>
        );
      case "Thursday":
        return (
          <Typography variant="body3-semibold">
            <FormattedDate weekday="narrow" value={new Date("2023-12-28")} />
          </Typography>
        );
      case "Friday":
        return (
          <Typography variant="body3-semibold">
            <FormattedDate weekday="narrow" value={new Date("2023-12-29")} />
          </Typography>
        );
      case "Saturday":
        return (
          <Typography variant="body3-semibold">
            <FormattedDate weekday="narrow" value={new Date("2023-12-30")} />
          </Typography>
        );
    }
  };

  const renderDayContents = (dayOfMonth: number) => {
    return <span className="z-10">{dayOfMonth}</span>;
  };

  //<Calendar
  //         selected={{
  //           from: props.value[0],
  //           to: props.value[1],
  //         }}
  //         mode="range"
  //         numberOfMonths={2}
  //         fromYear={1900}
  //         toYear={2100}
  //         captionLayout={"dropdown"}
  //         onSelect={(values) => {
  //           props.onChange &&
  //             props.onChange([
  //               values?.from ?? undefined,
  //               values?.to ?? undefined,
  //             ]);
  //         }}
  //       />

  const formatDateToUTC = (date: Date) => {
    if (props.showTimeSelect === true) {
      return date;
    }

    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds(),
      ),
    );
  };

  const renderDatePicker = (inputProps: {
    ref: ForwardedRef<HTMLInputElement>;
    className?: string;
  }) => {
    return isRange(props) ? (
      <DatePicker
        popperPlacement={popperPlacement}
        selected={props.value[0]}
        value={
          "From " +
          (props.value[0]
            ? intl.formatDate(props.value[0], {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
            : "                  ") +
          " → To " +
          (props.value[1]
            ? intl.formatDate(props.value[1], {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
            : "")
        }
        customInput={<input type="text" id={inputId} {...inputProps} />}
        startDate={props.value[0]}
        endDate={props.value[1]}
        onChange={(values) => {
          props.onChange &&
            props.onChange([values[0] ?? undefined, values[1] ?? undefined]);
        }}
        calendarContainer={MyContainer}
        dateFormat="dd/MM/yyyy"
        monthsShown={2}
        wrapperClassName="w-full inline-flex"
        dayClassName={() => "inline-flex justify-center items-center"}
        selectsRange
        renderDayContents={renderDayContents}
        renderCustomHeader={renderCustomHeader}
        calendarIconClassname="end-[19px] top-[10px] !p-0"
        formatWeekDay={(day) => {
          return getWeekdays(day);
        }}
      />
    ) : (
      <DatePicker
        popperPlacement={popperPlacement}
        selected={props.value ? formatDateToUTC(props.value) : undefined}
        customInput={<input type="text" id={inputId} {...inputProps} />}
        startDate={props.value}
        onChange={(values) => {
          props.onChange &&
            props.onChange(values ? formatDateToUTC(values) : undefined);
        }}
        dateFormat="dd/MM/yyyy"
        placeholderText="dd/MM/yyyy"
        wrapperClassName="w-full inline-flex"
        calendarContainer={MyContainer}
        monthsShown={1}
        dayClassName={() => "inline-flex justify-center items-center"}
        selectsRange={false}
        renderDayContents={renderDayContents}
        renderCustomHeader={renderCustomHeader}
        calendarIconClassname="end-[19px] top-[10px] !p-0"
        formatWeekDay={(day) => {
          return getWeekdays(day);
        }}
        showTimeSelect={props.showTimeSelect}
        timeIntervals={15}
      />
    );
  };

  return (
    <InputGroup
      id={inputId}
      className={className}
      disabled={disabled}
      label={label}
      required={required}
      errors={errors}
      hasValue={!!value}
      labelPosition="top"
      groupRef={groupRef}
      valid={valid}
      iconRight={
        <Icon
          name="Calendar"
          primaryColor={themeColors["dark-2"]}
          size="S"
          height={18}
          width={18}
        />
      }
      renderInput={(inputProps) => renderDatePicker(inputProps)}
    />
  );
}
