import {
  Icon,
  IconName,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

type AlertBlockProps = {
  level: string | null;
  text: string | null;
  icon: IconName | null;
  textClassName?: string;
};

export function AlertBlock(props: AlertBlockProps) {
  const { text, icon, textClassName } = props;

  if (!text) return null;

  return (
    <div className="dynamic-form-alert-block bg-light-2 flex w-full items-center justify-center gap-x-1.5 rounded px-2 py-0.5">
      {icon ? <Icon name={icon} size="XS" /> : null}
      <Typography variant="body3-regular" className={textClassName}>
        {text}
      </Typography>
    </div>
  );
}
