import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { ChipProps } from "@tudigo-monorepo/web-tudigo-components";

const TRANSLATION_BASE_KEY =
  "organizations.back_office.organization_page.chip_status";

const commonProps: Partial<ChipProps> = {
  iconLeft: "DotChip",
  size: "M",
};

const completedProps: ChipProps = {
  ...commonProps,
  label: "completed",
  type: "success",
};

const validatedProps: ChipProps = {
  ...commonProps,
  label: "valid",
  type: "success",
};

const toCompleteProps: ChipProps = {
  ...commonProps,
  label: "to_complete",
  type: "warning",
};

const submittedProps: ChipProps = {
  ...commonProps,
  label: "submitted",
  type: "waiting",
};

const rejectedProps: ChipProps = {
  ...commonProps,
  label: "error",
  type: "error",
};

const disabledProps: ChipProps = {
  ...commonProps,
  label: "disabled",
  type: "desactivate",
  className: "bg-transparent",
};

export const useOrganizationChipProps = (): Record<string, ChipProps> => {
  const { t } = useTranslation();

  return {
    completedProps: {
      ...completedProps,
      label: t(`${TRANSLATION_BASE_KEY}.${completedProps.label}`),
    },
    validatedProps: {
      ...validatedProps,
      label: t(`${TRANSLATION_BASE_KEY}.${validatedProps.label}`),
    },
    toCompleteProps: {
      ...toCompleteProps,
      label: t(`${TRANSLATION_BASE_KEY}.${toCompleteProps.label}`),
    },
    submittedProps: {
      ...submittedProps,
      label: t(`${TRANSLATION_BASE_KEY}.${submittedProps.label}`),
    },
    rejectedProps: {
      ...rejectedProps,
      label: t(`${TRANSLATION_BASE_KEY}.${rejectedProps.label}`),
    },
    disabledProps: {
      ...disabledProps,
      label: t(`${TRANSLATION_BASE_KEY}.${disabledProps.label}`),
    },
  };
};
