import React from "react";
import { toast } from "react-toastify";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { ORGANIZATIONS } from "@tudigo-monorepo/core-tudigo-utils";
import { useSendInvestmentDocumentSignatureLink } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  Button,
  ListElement,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { useEditWmaInvestmentPageContext } from "../edit-wma-investment-page";

export function EditWmaInvestmentSubscriptionFormContent() {
  const { investment } = useEditWmaInvestmentPageContext();
  const { t } = useTranslation();

  const subscriptionFormSignatureStatus = investment.subscriptionFormStatus;
  const isSubscriptionFormSigned = subscriptionFormSignatureStatus === "signed";

  const subscriptionFormSignatureLink =
    investment.subscriptionFormDocument?.signatureRequest?.signers?.find(
      (signer) => signer.userId === investment.user?.id,
    )?.signatureLink;

  const [isLinkCopyLoading, setIsLinkCopyLoading] = React.useState(false);
  const [isSendMailActive, setIsSendMailActive] = React.useState(true);

  const { mutateAsync: sendSubscriptionFormSignatureLink, isPending } =
    useSendInvestmentDocumentSignatureLink({
      onSuccess: () => {
        toast((content) => {
          return (
            <Alert
              icon="Checked"
              variant="success"
              size="S"
              close={content.closeToast}
              title="Email envoyé"
            />
          );
        });
        setIsSendMailActive(false);
      },
      onError: () => {
        toast((content) => {
          return (
            <Alert
              icon="Error"
              variant="error"
              size="S"
              close={content.closeToast}
              title="Une erreur est survenue"
            />
          );
        });
        setIsSendMailActive(true);
      },
    });

  const handleSendSubscriptionFormMail = async () => {
    await sendSubscriptionFormSignatureLink({
      investmentDocumentId: investment.subscriptionFormDocument?.id as string,
      userId: investment.user?.id as string,
    });
  };

  const copyLink = () => {
    setIsLinkCopyLoading(true);
    navigator.clipboard
      .writeText(`${subscriptionFormSignatureLink}`)
      .then(() => {
        toast((content) => {
          return (
            <Alert
              icon="Checked"
              variant="success"
              size="S"
              close={content.closeToast}
              title="Le lien de signature a été copié dans le presse-papier"
            />
          );
        });
        setTimeout(() => {
          setIsLinkCopyLoading(false);
        }, 500);
      });
  };

  if (!isSubscriptionFormSigned) {
    return (
      <div className="m-6">
        <Title level="h4">Signature du bulletin de souscription</Title>
        <Typography variant="body2">
          Cette signature est dématérialisée : en quelques clics votre client
          aura souscrit officiellement à l&apos;investissement choisi.
        </Typography>
        <div className="mt-4 flex gap-2">
          <Button
            label="Envoyer le lien par email"
            disabled={!isSendMailActive}
            iconRight="Mail"
            variant="primary"
            className="w-fit"
            onClick={() => handleSendSubscriptionFormMail()}
            isLoading={false}
          />
          <Button
            label="Copier le lien"
            iconRight="Duplicate"
            variant="tertiary"
            className="w-fit"
            onClick={() => copyLink()}
            isLoading={isLinkCopyLoading}
          />
        </div>
      </div>
    );
  }

  return (
    <ListElement
      className="m-6"
      key={investment.id}
      toggleActiveOnClick={false}
      startElement={{
        upperText: "Bulletin de souscription",
        lowerText: investment.investorOrganization.name,
        useProfileCard: true,
        profileCardClassName: "min-w-[280px] flex justify-start px-3 py-2",
        img: {
          icon: {
            name: "Document",
          },
        },
      }}
      middleElement={{
        upperText: "Signature de la souscription",
        chips: [
          {
            type: "info",
            label:
              investment.investorOrganization.type ===
              ORGANIZATIONS.TYPE.COMPANY
                ? "Personne morale"
                : "Personne physique",
          },
        ],
      }}
      endElement={{
        icon: "Download",
        info: {
          text: "PDF",
        },
      }}
      onClick={() => {
        window.open(
          `${investment.signedSubscriptionFormDocument?.document?.signedUrl}`,
          "_blank",
        );
      }}
    />
  );
}
