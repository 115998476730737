import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { User, UserState } from "@tudigo-monorepo/core-tudigo-api-models";

import { getApiClient } from "../../../";
import queryKeyFactory from "../../query-key-factory";

type RequestParams = {
  userId: User["id"] | "me";
};

type MutationOptions = UseMutationOptions<
  TudigoResponse<UserState>,
  TudigoError,
  RequestParams
>;

export const markIntroHasDone = async (params: RequestParams) => {
  const apiClient = getApiClient();
  const { userId } = params;
  const response = await apiClient.apiV1.users.markIntroHasDone(userId);

  return response.data;
};

export const useMarkIntroHasDone = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: RequestParams) => markIntroHasDone(params),
    ...options,
    onSuccess: (...props) => {
      const [_] = props;

      queryClient.invalidateQueries({
        queryKey: queryKeyFactory("users").detail("me"),
      });

      options?.onSuccess?.(...props);
    },
  });
};
