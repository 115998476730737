import { useIntl } from "react-intl";

// An invest status is used for routing, selecting a tab and filtering a table
// Each of these goals needs a specific formatting (URL, UI, data)
export type InvestStatus = {
  urlParamValue: string;
  label: string;
  filterValue: string;
};

export function useInvestStatuses(): InvestStatus[] {
  const intl = useIntl();

  return [
    {
      urlParamValue: "all",
      label: intl.formatMessage({ id: "all" }),
      filterValue: "all",
    },
    {
      urlParamValue: "validated",
      label: intl.formatMessage({ id: "investment.global_status.validated" }),
      filterValue: "validated",
    },
    {
      urlParamValue: "waiting_for_payment",
      label: intl.formatMessage({
        id: "investment.global_status.waiting_for_payment",
      }),
      filterValue: "waiting_for_payment",
    },
    {
      urlParamValue: "waiting_for_signature",
      label: intl.formatMessage({
        id: "investment.global_status.waiting_for_signature",
      }),
      filterValue: "waiting_for_signature",
    },
    {
      urlParamValue: "cancelled",
      label: intl.formatMessage({ id: "investment.global_status.cancelled" }),
      filterValue: "cancelled",
    },
  ];
}
