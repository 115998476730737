import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { Check } from "../check";

export type RadioItem<T> = {
  label: string;
  value: T;
};

export type RadioGroupProps<T> = {
  items: RadioItem<T>[];
  selectedValue: T;
  singleline?: boolean;
  onChange?: (value: T) => void;
};

export function RadioGroup<T>(props: RadioGroupProps<T>) {
  const { items, selectedValue, singleline, onChange } = props;

  const handleChange = (checked: boolean, index: number) => {
    if (checked && onChange) {
      onChange(items[index].value);
    }
  };

  const isChecked = (itemValue: T) => {
    if (typeof selectedValue === "object") {
      return JSON.stringify(itemValue) === JSON.stringify(selectedValue);
    }

    return itemValue === selectedValue;
  };

  return (
    <div
      className={cn("radio-group flex flex-col gap-2.5", {
        "flex-row flex-wrap": singleline,
      })}
    >
      {items.map((item, index) => (
        <Check
          key={`${item.label}-${index}`}
          type="radio"
          min={2}
          label={item.label}
          checked={isChecked(item.value)}
          onChange={(checked) => handleChange(checked, index)}
        />
      ))}
    </div>
  );
}
