import { useIntl } from "react-intl";

import {
  Organization,
  OrganizationPerson,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  AccountCard,
  Avatar,
  ChipProps,
} from "@tudigo-monorepo/web-tudigo-components";

import {
  OrganizationCollapse,
  OrganizationCollapseItem,
} from "../organization-collapse/organization-collapse";

type OrganizationCompanyBeneficialOwnersProps = {
  organization: Organization | undefined;
};

const checkIfBeneficialOwnerPersonalInformationsRequirementsAreMet = (
  beneficialOwner: OrganizationPerson,
): boolean => {
  return !!(
    beneficialOwner.firstName &&
    beneficialOwner.lastName &&
    beneficialOwner.address &&
    beneficialOwner.phone &&
    beneficialOwner.birthDate &&
    beneficialOwner.birthLocation &&
    beneficialOwner.nationality &&
    beneficialOwner.sharesCount !== null &&
    beneficialOwner.sharesCount >= 25
  );
};

const useOrganizationBeneficialOwnerChipStatus = (): ((
  beneficialOwner: OrganizationPerson,
) => ChipProps | undefined) => {
  const { t } = useTranslation();

  return (beneficialOwner: OrganizationPerson) => {
    const isError =
      !checkIfBeneficialOwnerPersonalInformationsRequirementsAreMet(
        beneficialOwner,
      );

    if (!isError) {
      return;
    }

    return {
      label: t("organizations.back_office.organization_page.chip_status.error"),
      type: "error",
      iconLeft: "DotChip",
      size: "M",
    };
  };
};

export function OrganizationCompanyBeneficialOwners({
  organization,
}: OrganizationCompanyBeneficialOwnersProps) {
  const { t } = useTranslation();
  const { formatDate } = useIntl();
  const getChipStatus = useOrganizationBeneficialOwnerChipStatus();

  return (
    <div className="col-span-4 flex flex-col gap-y-6 sm:col-span-8">
      {organization?.ultimateBeneficialOwners.map((ubo) => {
        const chipStatus = getChipStatus(ubo);

        return (
          <OrganizationCollapse
            key={ubo.id}
            defaultExpanded={false}
            disableCollapse={false}
            items={
              [
                {
                  label: t(
                    "organizations.back_office.organization_page.collapse.personal_informations.lastname",
                  ),
                  value: ubo.lastName,
                  chipProps: !ubo.lastName ? chipStatus : undefined,
                },
                {
                  label: t(
                    "organizations.back_office.organization_page.collapse.personal_informations.firstname",
                  ),
                  value: ubo.firstName,
                  chipProps: !ubo.firstName ? chipStatus : undefined,
                },
                {
                  label: t(
                    "organizations.back_office.organization_page.collapse.personal_informations.birth_date",
                  ),
                  value: formatDate(ubo.birthDate || undefined),
                  chipProps: !ubo.birthDate ? chipStatus : undefined,
                },
                {
                  label: t(
                    "organizations.back_office.organization_page.collapse.personal_informations.birth_country",
                  ),
                  value: ubo.birthLocation?.country,
                  chipProps: !ubo.birthLocation?.country
                    ? chipStatus
                    : undefined,
                },
                {
                  label: t(
                    "organizations.back_office.organization_page.collapse.personal_informations.nationality",
                  ),
                  value: ubo.nationality,
                  chipProps: !ubo.nationality ? chipStatus : undefined,
                },
                {
                  label: t(
                    "organizations.back_office.organization_page.collapse.personal_informations.phone",
                  ),
                  value: ubo.phone,
                  chipProps: !ubo.phone ? chipStatus : undefined,
                },
                {
                  label: t(
                    "organizations.back_office.organization_page.collapse.fiscal_address.title",
                  ),
                  value: `${ubo.address?.street1} ${ubo.address?.postalCode} ${ubo.address?.city}`,
                  chipProps: !ubo.address ? chipStatus : undefined,
                },
              ] as OrganizationCollapseItem[]
            }
          >
            <div className="flex items-center gap-x-3">
              <AccountCard
                className="p-0"
                upperText={
                  ubo.sharesCount
                    ? `${t(
                        "organizations.back_office.edit_organization_page.persons.shares_count",
                      )} ${ubo.sharesCount}%`
                    : ""
                }
                lowerText={`${ubo.firstName} ${ubo.lastName}`}
                avatar={
                  <Avatar icon={{ name: "Profile" }} className="h-6 w-6" />
                }
              />
            </div>
          </OrganizationCollapse>
        );
      })}
    </div>
  );
}
