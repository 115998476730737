import React from "react";

import { ClubMember } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";
import { Chip } from "@tudigo-monorepo/web-tudigo-components";

export const ClubLeaderMemberDetailStatisticsCard = (props: {
  clubMember: ClubMember;
}) => {
  const clubMember = props.clubMember;
  const { t } = useTranslation();

  return (
    <div className="border-light-1 m-6 flex flex-1 flex-col gap-6 rounded-lg border">
      <div className=" text-dark-2 border-light-1 border-b p-6 text-sm">
        {t("club_leader.page_detail_member.statistics_detail_card.title")}
      </div>
      <div className="flex flex-col gap-2 px-6 pb-6">
        <div className="flex justify-between">
          <span className="text-primary text-sm">
            {t(
              "club_leader.page_detail_member.statistics_detail_card.investment_count.label",
            )}
          </span>
          <span className="text-primary text-sm font-semibold">
            {clubMember.statistics?.totalInvestmentsCount ?? 0}
          </span>
        </div>
        <div className="flex justify-between">
          <span className="text-primary text-sm">
            {t(
              "club_leader.page_detail_member.statistics_detail_card.total_amount.label",
            )}
          </span>
          <span className="text-primary text-sm font-semibold">
            {formatCurrency(
              clubMember.statistics?.totalInvestmentsAmountInCents ?? 0,
            )}
          </span>
        </div>
        <div className="flex justify-between">
          <span className="text-primary text-sm">
            {t(
              "club_leader.page_detail_member.statistics_detail_card.investmens_list.label",
            )}
          </span>
          <span className="text-primary text-sm font-semibold">
            {clubMember.investments
              ?.map((investment) => investment.project.content?.name)
              .join(", ") || (
              <Chip
                label={t(
                  "club_leader.page_detail_member.statistics_detail_card.investmens_list.none.label",
                )}
                type="secondary"
              />
            )}
          </span>
        </div>
      </div>
    </div>
  );
};
