import { JSX } from "react/jsx-runtime";

import { CMSListBlock } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { Typography } from "../../typography";

export function ContractListBlock(block: CMSListBlock) {
  const { data } = block;
  const { content, type } = data;

  let listVariant = "ul";
  switch (type) {
    case "ordered":
      listVariant = "ol";
      break;
    case "unordered":
      listVariant = "ul";
      break;
    default:
      listVariant = "ul";
  }
  const List = listVariant as keyof JSX.IntrinsicElements;

  return (
    <List
      className={cn("list-outside pl-4", {
        "list-disc": type === "unordered",
        "list-decimal": type === "ordered",
      })}
    >
      {content.map((listElement, index) => (
        <li key={index} className="m-2">
          <Typography variant="body2" className="block" dangerouslySetInnerHTML>
            {listElement}
          </Typography>
        </li>
      ))}
    </List>
  );
}
