import { useNavigate } from "react-router-dom";

import { ClubV2, mockProjects } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useGetProjectsQuery } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Button,
  ProjectCardRebrand,
} from "@tudigo-monorepo/web-tudigo-components";

export const CirclePresentationProjectsBlock = ({ club }: { club: ClubV2 }) => {
  const { t } = useTranslation();
  const { data: projectsResponse } = useGetProjectsQuery({
    pathParams: undefined,
    filters: { clubId: club?.id || "undefined" },
    pagination: { limit: 2 },
  });

  const projects = projectsResponse?.data?.length
    ? projectsResponse.data
    : mockProjects;

  // Todo: get projects from club + external url
  const navigate = useNavigate();

  if (!club) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-4 lg:flex-row">
        {projects
          ?.slice(0, 2)
          .map((project) => (
            <ProjectCardRebrand
              key={project.id}
              className="max-w-1/2 w-full min-w-[300px] snap-center"
              project={project}
              navigateTo="/"
              target="_blank"
              smallCard
            />
          ))}
      </div>
      <div className="flex w-full justify-end">
        <Button
          variant="tertiary"
          onClick={() => navigate(`/clubs-v2/${club.id}/fundraising`)}
          label={t(
            "page_club.tab_introduction.block_projects.see_more_button.label",
          )}
        />
      </div>
    </div>
  );
};
