import { ReactNode } from "react";

import { IconName } from "../icons/icon-name.type";
import { MenuNavigationLinkItem } from "../menu-navigation-item";
import { MenuNavigationSectionItem } from "../menu-navigation-item/menu-navigation-section-item";
import { MenuNavigationSelectItem } from "../menu-navigation-item/menu-navigation-select-item";
import { MenuNavigationSimpleItem } from "../menu-navigation-item/menu-navigation-simple-item";

interface MenuItemCommon {
  label: string;
  type: MenuItemType;
  action?: () => void;
  hidden?: boolean;
}

type MenuItemType = "link" | "section" | "simple" | "select";

export interface MenuLink extends MenuItemCommon {
  icon?: IconName | null;
  type: "link";
  path: string;
  end?: boolean;
  target?: "_self" | "_blank";
}

export interface MenuSelect extends MenuItemCommon {
  icon?: ReactNode | null;
  type: "select";
  onChange: (value: string) => void;
  selectOptions: MenuSelectOption[];
  popoverTitle: string;
}

type MenuSelectOption = {
  value: string;
  label: string;
};

export interface MenuSimple extends MenuItemCommon {
  icon?: IconName | null;
  type: "simple";
}

export interface MenuSection extends MenuItemCommon {
  icon?: IconName | null;
  type: "section";
}

export type NavigationItem = MenuLink | MenuSection | MenuSimple | MenuSelect;

type MenuNavigationProps = {
  items: NavigationItem[];
  reducedDisplay?: boolean;
};

export function MenuNavigation(props: MenuNavigationProps) {
  const { items, reducedDisplay = false } = props;

  const renderItem = (menuItem: NavigationItem, index: number) => {
    if (menuItem.hidden) {
      return null;
    }

    switch (menuItem.type) {
      case "link":
        return (
          <MenuNavigationLinkItem
            key={index}
            item={menuItem}
            reducedDisplay={reducedDisplay}
          />
        );
      case "select":
        return (
          <MenuNavigationSelectItem
            key={index}
            item={menuItem}
            reducedDisplay={reducedDisplay}
          />
        );
      case "section":
        return (
          <MenuNavigationSectionItem
            key={index}
            item={menuItem}
            reducedDisplay={reducedDisplay}
          />
        );
      case "simple":
        return (
          <MenuNavigationSimpleItem
            key={index}
            item={menuItem}
            reducedDisplay={reducedDisplay}
          />
        );
    }
  };

  return (
    <div className="flex w-full flex-col gap-y-3">{items.map(renderItem)}</div>
  );
}
