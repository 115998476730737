import { useParams } from "react-router-dom";

import { useGetOrganizationByIdQuery } from "@tudigo-monorepo/web-tudigo-api-client";

import { organizationRequestFields } from "../../utils/organization-request-fields-utils";
import { EditOrganizationLink } from "../edit-organization-link/edit-organization-link";
import { OrganizationCompanyBeneficialOwners } from "../organization-company-beneficial-owners/organization-company-beneficial-owners";
import { BankAccountSection } from "./sections/bank-account-section";
import { CompanySection } from "./sections/company-section";
import { DocumentsSection } from "./sections/documents-section";
import { PersonalAddressSection } from "./sections/personal-address-section";
import { PersonalGeneralSection } from "./sections/personal-general-section";

export function OrganizationKYCViewer() {
  const { organizationId } = useParams();

  const { data: organization } = useGetOrganizationByIdQuery(
    organizationId || "",
    {
      fields: organizationRequestFields,
    },
  );

  if (!organization) return null;

  const { type: organizationType } = organization;

  return (
    <div className="m-6 flex flex-col gap-y-6">
      <div className="grid grid-cols-4 gap-6 sm:grid-cols-12">
        <div className="col-span-4 flex flex-col gap-y-6 sm:col-span-8">
          <PersonalGeneralSection organization={organization} />
          <PersonalAddressSection organization={organization} />
          {organizationType === "company" && (
            <CompanySection organization={organization} />
          )}
          <DocumentsSection organization={organization} />
          {organizationType === "company" && (
            <OrganizationCompanyBeneficialOwners organization={organization} />
          )}
        </div>
        <BankAccountSection organization={organization} />
      </div>
      <EditOrganizationLink organizationId={organizationId} />
    </div>
  );
}
