import { ReactNode } from "react";

import { Badge, BadgeProps } from "../../badge/badge";
import { TabProps } from "../../tab";
import { Title } from "../../title";
import { Typography } from "../../typography";

type TabTextProps = {
  children: ReactNode;
  selected: Required<TabProps["selected"]>;
  search: TabProps["search"];
  deactivated: TabProps["deactivated"];
  badge?: BadgeProps;
  badgeOnly?: boolean;
};

export function TabText(props: TabTextProps): ReactNode {
  const {
    children,
    selected,
    search,
    deactivated,
    badge,
    badgeOnly = false,
  } = props;

  return (
    <>
      {(selected || search) && !badgeOnly && (
        <Title level="h5" className={search ? "text-accent" : undefined}>
          {children}
        </Title>
      )}
      {!selected && !search && !badgeOnly && (
        <Typography
          variant="body2"
          className={deactivated ? "text-medium-2" : undefined}
        >
          {children}
        </Typography>
      )}
      {badge !== undefined && <Badge {...badge} />}
    </>
  );
}
