import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Button, Chip } from "@tudigo-monorepo/web-tudigo-components";

import {
  TaxExemptionBlock,
  TaxExemptionBlockContent,
  TaxExemptionBlockHeader,
  TaxExemptionBlockText,
  TaxExemptionBlockTitle,
} from "./tax-exemption-block";

interface TaxExemptionBlockIRProps {
  validityStartDate: string;
  onLearnMoreClick: () => void;
  onSignClick: () => void;
  isLoading?: boolean;
}

export function TaxExemptionBlockIR(props: TaxExemptionBlockIRProps) {
  const {
    validityStartDate,
    onLearnMoreClick,
    onSignClick,
    isLoading = false,
  } = props;

  const { t } = useTranslation();

  return (
    <TaxExemptionBlock>
      <TaxExemptionBlockHeader>
        <Chip label={new Date(validityStartDate).getFullYear()} />
        <TaxExemptionBlockTitle>
          {t("my-profile-exemptions.tax-document-PFU.title")}
        </TaxExemptionBlockTitle>
      </TaxExemptionBlockHeader>

      <TaxExemptionBlockContent>
        <TaxExemptionBlockText>
          {t("my-profile-exemptions.tax-document-PFU.text")}
        </TaxExemptionBlockText>
        <Button
          onClick={onLearnMoreClick}
          label={t("my-profile-exemptions.tax-document.buttons-learn-more")}
          className="font-montserrat w-fit"
          variant="ghost"
          iconRight="ChevronRight"
        />
        <Button
          onClick={onSignClick}
          label={t("my-profile-exemptions.tax-document.buttons-sign-PFU")}
          className="font-montserrat w-fit"
          variant="primary"
          isLoading={isLoading}
          disabled={isLoading}
        />
      </TaxExemptionBlockContent>
    </TaxExemptionBlock>
  );
}
