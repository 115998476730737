import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { TudigoError } from "@tudigo-monorepo/core-tudigo-api";
import {
  BondProjectSimulatorQuery,
  ShareProjectSimulatorQuery,
  Simulator,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient } from "../../../";

type GetSimulatorByProjectId = {
  projectId: string | undefined;
  query: ShareProjectSimulatorQuery | BondProjectSimulatorQuery;
};

export const getSimulatorByProjectId = async (
  params: GetSimulatorByProjectId,
) => {
  const apiClient = getApiClient();

  if (!params.projectId) {
    throw new Error("projectId is required");
  }

  const response = await apiClient.apiV1.simulator.getSimulatorById(
    params?.projectId,
    params?.query,
  );

  return response.data?.data;
};

export const useGetSimulatorByProject = (
  params: GetSimulatorByProjectId,
  options?: Omit<
    UseQueryOptions<Simulator, TudigoError>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery({
    queryKey: [QUERY_KEYS.SIMULATOR, params.projectId, params?.query],
    queryFn: () => getSimulatorByProjectId(params),
    ...options,
  });
