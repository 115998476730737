import { toast } from "react-toastify";

import { User } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { useDeleteUserMutation } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  Modal,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

type DeleteUserModalProps = {
  user: User | null;
  isOpen?: boolean;
  onClose?: (open: boolean) => void;
  onSuccess?: () => void;
};

export function DeleteUserModal(props: DeleteUserModalProps) {
  const { user, isOpen = false, onClose, onSuccess } = props;

  const { user: connectedUser } = useUserBusiness();
  const { t } = useTranslation();

  const isOwnAccount = connectedUser?.id === user?.id;

  const { mutate: deleteUser, isPending } = useDeleteUserMutation({
    onSuccess: () => {
      onSuccess?.();
      onClose?.(false);
      toast((content) => (
        <Alert
          close={content.closeToast}
          icon="Checked"
          variant="success"
          title={
            isOwnAccount
              ? t(
                  "my-settings.my_setting_identifiers_content.block_delete_account.confirmation_modal.success.title",
                )
              : t("users.back_office.delete_user.alert.success.title")
          }
        />
      ));
    },
    onError: (err) => {
      console.error("deleteUser error ->", err);
      onClose?.(false);
      toast((content) => (
        <Alert
          close={content.closeToast}
          icon="Error"
          variant="error"
          title={
            isOwnAccount
              ? t(
                  "my-settings.my_setting_identifiers_content.block_delete_account.confirmation_modal.error.title",
                )
              : t("users.back_office.delete_user.alert.error.title")
          }
        />
      ));
    },
  });

  const handleDeleteUser = () => {
    if (user) {
      deleteUser(user.id);
    }
  };

  const handleCancel = () => {
    onClose?.(false);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      isOpen
      setIsOpen={handleCancel}
      confirmAction={handleDeleteUser}
      confirmButtonProps={{
        isLoading: isPending,
        disabled: isPending,
        variant: "destructive",
      }}
      title={t("users.back_office.delete_user.confirmation_modal.title")}
      confirmLabel={t(
        "users.back_office.delete_user.confirmation_modal.button.label",
      )}
    >
      <div className="flex flex-col gap-y-4 px-8 py-4">
        <Typography variant="caption1-regular">
          {isOwnAccount
            ? t(
                "my-settings.my_setting_identifiers_content.block_delete_account.confirmation_modal.warning",
              )
            : t("users.back_office.delete_user.confirmation_modal.warning")}
          {!!user?.email && <strong> {user?.email}</strong>} ?
        </Typography>
        <Alert
          icon="Info"
          variant="warning"
          title={t(
            "users.back_office.delete_user.confirmation_modal.warning.title",
          )}
          description={t(
            "users.back_office.delete_user.confirmation_modal.warning.description",
          )}
        />
      </div>
    </Modal>
  );
}
