import { ReactNode } from "react";
import { NavLink } from "react-router-dom";

type RedirectWrapperProps = {
  children: ReactNode;
  redirectTo: string;
};

export function RedirectWrapper(props: RedirectWrapperProps): ReactNode {
  const { children, redirectTo } = props;

  return <NavLink to={redirectTo}>{children}</NavLink>;
}
