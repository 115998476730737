import { Alert } from "@tudigo-monorepo/web-tudigo-components";

export const ProjectHolderIntro = () => {
  return (
    <>
      <div className="flex flex-col gap-4">
        <h1 className="font-brand text-primary text-[27px]">
          Porteur de projet ?
        </h1>
        <hr />
      </div>
      <Alert
        icon="InformationIcon"
        variant="info"
        size="M"
        title="Vous souhaitez lever des fonds ?"
        description={
          <>Créez votre compte puis soumettez votre projet à nos équipes.</>
        }
      />
    </>
  );
};
