import { ReactNode } from "react";
import { useLocation } from "react-router-dom";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { useProjectContext } from "../project.context";
import { SidebarProjectFinancialStats } from "./parts/financial-stats/sidebar-project-financial-stats";
import { SidebarProjectDocuments } from "./parts/sidebar-project-documents";
import { SidebarProjectSecurityAndRequirements } from "./parts/sidebar-project-security-and-requirements";
import { SidebarProjectSimulator } from "./parts/sidebar-project-simulator";

const ProjectSidebarCard = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => (
  <section
    className={cn(
      "border-light-1 flex w-full flex-col gap-3 rounded-lg border p-6",
      className,
    )}
  >
    {children}
  </section>
);

export function ProjectSidebar() {
  const currentRoute = useLocation();
  const project = useProjectContext();

  const isSimulatorRoute = currentRoute.pathname.includes("simulator");
  const isSimulatorVisible =
    project?.marketingSettings?.simulatorVisibility === "visible";

  return (
    <div className="flex w-full flex-col gap-4">
      <ProjectSidebarCard>
        <SidebarProjectFinancialStats />
      </ProjectSidebarCard>

      {!isSimulatorRoute &&
        isSimulatorVisible &&
        project?.status !== "finished" &&
        project?.status !== "prefinished" && (
          <ProjectSidebarCard className="hidden lg:block">
            <SidebarProjectSimulator />
          </ProjectSidebarCard>
        )}

      <ProjectSidebarCard>
        <SidebarProjectDocuments />
      </ProjectSidebarCard>

      <ProjectSidebarCard>
        <SidebarProjectSecurityAndRequirements />
      </ProjectSidebarCard>
    </div>
  );
}
