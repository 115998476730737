import { ReactNode } from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { TabProps } from "../tab";

type OnClickWrapperProps = {
  children: ReactNode;
  onClick: TabProps["onClick"];
  selected: Required<TabProps["selected"]>;
  search: TabProps["search"];
  deactivated: TabProps["deactivated"];
};

export function TabCommonWrapper(props: OnClickWrapperProps): ReactNode {
  const { children, onClick, selected, search, deactivated } = props;

  return (
    <div
      onClick={onClick}
      className={cn(
        "px-1",
        "h-[52px] w-fit",
        "flex items-center justify-center gap-2",
        "border-b-2",
        "transition-colors",
        {
          "border-dark-1": selected,
          "border-accent": search,
          "border-transparent": !selected && !search,
          "hover:border-accent-medium": !selected && !search && !deactivated,
        },
        {
          "cursor-pointer": !deactivated && !selected,
        },
        { "bg-accent-super-light px-3": search },
      )}
    >
      {children}
    </div>
  );
}
