import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import {
  CreateOrganizationRequestBody,
  TudigoError,
  TudigoResponse,
} from "@tudigo-monorepo/core-tudigo-api";
import { Organization } from "@tudigo-monorepo/core-tudigo-api-models";

import { getApiClient } from "../../..";

type MutationOptions = UseMutationOptions<
  TudigoResponse<Organization>,
  TudigoError,
  CreateOrganizationRequestBody
>;

export const createOrganization = async (
  params: CreateOrganizationRequestBody,
) => {
  const apiClient = getApiClient();

  const response =
    await apiClient.apiV1.organizations.createOrganization(params);

  return response.data;
};

export const useCreateOrganizationMutation = (options?: MutationOptions) => {
  return useMutation({
    mutationFn: (params: CreateOrganizationRequestBody) =>
      createOrganization(params),
    ...options,
  });
};
