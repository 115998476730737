import React from "react";
import { useHitsPerPage, useInfiniteHits } from "react-instantsearch";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useGetProjectsQuery } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  Button,
  DotsLoader,
} from "@tudigo-monorepo/web-tudigo-components";

import { ProjectsSection } from "./projects-section";
import { CustomHits } from "./shared/component/custom-hits";
import { EmptyResults } from "./shared/component/empty-results";

const ProjectsPageMainContent = ({ children }: React.PropsWithChildren) => {
  return (
    <main className="flex h-full w-full flex-col gap-y-6 py-6">{children}</main>
  );
};

const ProjectsPageMainContentLoader = ({ loading }: { loading: boolean }) => {
  if (!loading) return null;

  return <DotsLoader className="mx-auto" />;
};

const ProjectsPageMainContentResults = ({ visible }: { visible: boolean }) => {
  const { hits, isLastPage, showMore } = useInfiniteHits({
    showPrevious: false,
  });

  useHitsPerPage({
    items: [{ label: "", value: 12, default: true }],
  });

  return (
    <div className={cn("flex flex-col gap-y-6", { hidden: !visible })}>
      <CustomHits hits={hits as any} />

      {!isLastPage && (
        <Button
          onClick={showMore}
          label="Afficher plus"
          variant="tertiary"
          className="mx-auto w-fit"
        />
      )}
    </div>
  );
};

const ProjectsPageMainContentEmptyResults = ({
  visible,
}: {
  visible: boolean;
}) => {
  const { data } = useGetProjectsQuery({
    filters: { status: "started" },
    pathParams: undefined,
  });

  if (!data) return null;

  return <EmptyResults visible={visible} suggestedProjects={data?.data} />;
};

const ProjectsPageMainContentFallback = ({ error }: { error?: Error }) => {
  if (!error) return null;

  return (
    <Alert
      variant="error"
      title="Une erreur est survenue. Veuillez réessayer ultérieurement"
      icon="Error"
      className="[&_*]:text-error items-center"
    />
  );
};

const ProjectsPageMainContentProjectsGallery = ({
  visible,
}: {
  visible: boolean;
}) => {
  if (!visible) return null;

  return (
    <>
      <ProjectsSection name="ongoing" limit={12} />
      <ProjectsSection name="coming" limit={3} />
      <ProjectsSection name="finished" limit={3} />
    </>
  );
};

ProjectsPageMainContent.Loader = ProjectsPageMainContentLoader;
ProjectsPageMainContent.Results = ProjectsPageMainContentResults;
ProjectsPageMainContent.EmptyResults = ProjectsPageMainContentEmptyResults;
ProjectsPageMainContent.Fallback = ProjectsPageMainContentFallback;
ProjectsPageMainContent.ProjectsGallery =
  ProjectsPageMainContentProjectsGallery;

export { ProjectsPageMainContent };
