import { RefinementListProps, useRefinementList } from "react-instantsearch";

import { capitalizeFirstChar } from "@tudigo-monorepo/core-tudigo-utils";
import {
  Button,
  Checkbox,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

type ListProps = RefinementListProps & {
  open?: boolean;
  showMore?: boolean;
  transformItem?: (item: { label: string } & any) => { label: string } & any;
};

export const CustomRefinementList = (props: ListProps) => {
  const { showMore } = props;
  const { transformItem, ...rest } = props;

  const { items, refine, canToggleShowMore, isShowingMore, toggleShowMore } =
    useRefinementList({
      ...rest,
    });

  const getLabel = (item: any) => {
    if (transformItem) {
      item = transformItem(item)!;

      return item.label;
    }

    return capitalizeFirstChar(item.label);
  };

  return (
    <>
      <ul className="flex flex-col gap-y-3">
        {items.map((item) => (
          <li key={item.value} className="flex items-center">
            <Checkbox
              className="mr-3"
              checked={item.isRefined}
              onCheckedChange={() => refine(item.value)}
              id={item.label}
            />
            <label
              htmlFor={item.label}
              className="flex w-full items-center justify-between"
            >
              <Typography
                variant="body3-regular"
                className="text-dark-1 cursor-pointer"
              >
                {getLabel(item)}
              </Typography>
              <Typography className="bg-light-3 cursor-pointer rounded-[30px] px-2 text-[10px]">
                {item.count}
              </Typography>
            </label>
          </li>
        ))}
      </ul>

      {showMore && canToggleShowMore && (
        <Button
          className="m-auto min-w-44"
          label={isShowingMore ? "Afficher moins" : "Afficher plus"}
          variant="tertiary"
          iconRight={isShowingMore ? "ChevronUp" : "ChevronDown"}
          onClick={toggleShowMore}
        />
      )}
    </>
  );
};
