import {
  Address,
  OrganizationPerson,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { getSmartFormComponents } from "@tudigo-monorepo/web-feature-form";
import {
  AddressSelector,
  Title,
  Toggle,
} from "@tudigo-monorepo/web-tudigo-components";

type OrganizationPersonFormContentProps = {
  organizationPerson: OrganizationPerson;
  updateOrganizationPerson: (organizationPerson: OrganizationPerson) => void;
  onFormDataUpdated?: (organizationPerson: OrganizationPerson) => void;
  splitSections?: boolean;
  onPhoneNumberErrorChange?: (isError: boolean) => void;
};

export function OrganizationPersonFormContent(
  props: OrganizationPersonFormContentProps,
) {
  const {
    organizationPerson,
    splitSections = false,
    updateOrganizationPerson,
    onFormDataUpdated,
    onPhoneNumberErrorChange,
  } = props;

  const { SmartTextField, SmartDateField, SmartCountryField, SmartPhoneField } =
    getSmartFormComponents<OrganizationPerson>();

  const { t } = useTranslation();

  const handleToggleGenderChange = (e: boolean) => {
    const updatedOwnerData: OrganizationPerson = {
      ...organizationPerson,
      gender: e ? "female" : "male",
    };

    updateOrganizationPerson(updatedOwnerData);
    if (onFormDataUpdated) onFormDataUpdated(updatedOwnerData);
  };

  const handleAddressChange = (address: Address) => {
    const updatedOwnerData: OrganizationPerson = {
      ...organizationPerson,
      address: address,
    };

    updateOrganizationPerson(updatedOwnerData);

    if (onFormDataUpdated) onFormDataUpdated(updatedOwnerData);
  };

  if (splitSections) {
    return (
      <>
        <Title level="h4">Informations civiles</Title>
        <div className="rounded-lg border px-4 py-8 lg:px-10">
          <div className="flex w-full flex-col gap-4 md:w-[350px]">
            <Toggle
              checked={organizationPerson?.gender === "female"}
              leftText={t("kyc_funnel.organization_person_form.label.gender.m")}
              name="gender"
              rightText={t(
                "kyc_funnel.organization_person_form.label.gender.f",
              )}
              onChange={(e) => handleToggleGenderChange(e)}
              blueStyle
            />
            <SmartTextField
              name="firstName"
              label={t("kyc_funnel.organization_person_form.label.firstname")}
              required
            />
            <SmartTextField
              name="lastName"
              label={t("kyc_funnel.organization_person_form.label.lastname")}
              required
            />
            <SmartDateField
              name="birthDate"
              label={t("kyc_funnel.organization_person_form.label.birth_date")}
              required
            />
            <SmartTextField
              name="birthLocation.city"
              label={t(
                "kyc_funnel.organization_person_form.label.birth_location.city",
              )}
              required
            />
            <SmartCountryField
              name="birthLocation.country"
              label={t(
                "kyc_funnel.organization_person_form.label.birth_location.country",
              )}
              required
            />
            <SmartCountryField
              name="nationality"
              label={t("kyc_funnel.organization_person_form.label.nationality")}
              required
            />
            <SmartPhoneField
              name="phone"
              label={t("kyc_funnel.organization_person_form.label.phone")}
              required
              onErrorChange={(isError) => {
                onPhoneNumberErrorChange?.(isError);
              }}
            />
          </div>
        </div>
        <Title level="h4">Adresse fiscale</Title>
        <div className="rounded-lg border px-4 py-8 lg:px-10">
          <div className="flex w-full flex-col gap-4 md:w-[350px]">
            <SmartCountryField
              required
              name="address.country"
              label={t(
                "kyc_funnel.organization_person_form.label.address.country",
              )}
            />
            <AddressSelector
              label={t("kyc_funnel.organization_person_form.label.address")}
              country={organizationPerson?.address?.country}
              onAddressSelected={(address) => {
                handleAddressChange(address);
              }}
            />
            <SmartTextField
              name="address.street1"
              label={t(
                "kyc_funnel.organization_person_form.label.address.street1",
              )}
              required
            />
            <SmartTextField
              required
              name="address.postalCode"
              label={t(
                "kyc_funnel.organization_person_form.label.address.postal_code",
              )}
            />
            <SmartTextField
              required
              name="address.city"
              label={t(
                "kyc_funnel.organization_person_form.label.address.city",
              )}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="mt-4 flex w-full flex-col gap-4 md:w-[350px]">
      <Toggle
        checked={organizationPerson?.gender === "female"}
        leftText={t("kyc_funnel.organization_person_form.label.gender.m")}
        name="gender"
        rightText={t("kyc_funnel.organization_person_form.label.gender.f")}
        onChange={(e) => handleToggleGenderChange(e)}
        blueStyle
      />
      <SmartTextField
        name="firstName"
        label={t("kyc_funnel.organization_person_form.label.firstname")}
        required
      />
      <SmartTextField
        name="lastName"
        label={t("kyc_funnel.organization_person_form.label.lastname")}
        required
      />
      <SmartDateField
        name="birthDate"
        label={t("kyc_funnel.organization_person_form.label.birth_date")}
        required
      />
      <SmartTextField
        name="birthLocation.city"
        label={t(
          "kyc_funnel.organization_person_form.label.birth_location.city",
        )}
        required
      />
      <SmartCountryField
        name="birthLocation.country"
        label={t(
          "kyc_funnel.organization_person_form.label.birth_location.country",
        )}
        required
      />
      <SmartCountryField
        name="nationality"
        label={t("kyc_funnel.organization_person_form.label.nationality")}
        required
      />
      <SmartPhoneField
        name="phone"
        label={t("kyc_funnel.organization_person_form.label.phone")}
        required
        onErrorChange={(isError) => {
          onPhoneNumberErrorChange?.(isError);
        }}
      />
      <SmartCountryField
        required
        name="address.country"
        label={t("kyc_funnel.organization_person_form.label.address.country")}
      />
      <AddressSelector
        country={organizationPerson?.address?.country}
        label={t("kyc_funnel.organization_person_form.label.address")}
        onAddressSelected={(address) => {
          handleAddressChange(address);
        }}
      />
      <SmartTextField
        name="address.street1"
        label={t("kyc_funnel.organization_person_form.label.address.street1")}
        required
      />
      <SmartTextField
        required
        name="address.postalCode"
        label={t(
          "kyc_funnel.organization_person_form.label.address.postal_code",
        )}
      />
      <SmartTextField
        required
        name="address.city"
        label={t("kyc_funnel.organization_person_form.label.address.city")}
      />
    </div>
  );
}
