import { createColumnHelper } from "@tanstack/react-table";
import { FormattedDate } from "react-intl";

import { Project } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";
import { Chip } from "@tudigo-monorepo/web-tudigo-components";

export function useClubProjectsTableColumns() {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<Project>();

  return [
    columnHelper.accessor("content.name", {
      header: t("club_leader.page_projects.table.column.name"),
      cell: (info) => (
        <p className="text-primary text-xs">{info.getValue() || "-"}</p>
      ),
    }),
    columnHelper.display({
      header: t("club_leader.page_projects.table.column.start_date"),
      cell: (info) => (
        <p className="text-primary text-xs">
          <FormattedDate value={info.row.original.startedAt ?? undefined} />
        </p>
      ),
    }),
    columnHelper.display({
      header: t("club_leader.page_projects.table.column.end_date"),
      cell: (info) => (
        <p className="text-primary text-xs">
          <FormattedDate value={info.row.original.finishedAt ?? undefined} />
        </p>
      ),
    }),
    columnHelper.display({
      header: t(
        "club_leader.page_projects.table.column.total_amount_collected",
      ),
      cell: (info) => (
        <p className="text-primary text-xs">
          {formatCurrency(info.row.original.totalAmountCollectedInCents)}
        </p>
      ),
    }),
    columnHelper.accessor("mechanic", {
      header: t("club_leader.page_projects.table.column.mechanic"),
      cell: (info) => (
        <Chip label={t(`projects.mechanic.label.${info.getValue()}`)} />
      ),
    }),
    columnHelper.accessor("status", {
      header: t("club_leader.page_projects.table.column.status"),
      cell: (info) => (
        <Chip
          label={t(`projects.stage.label.${info.getValue()}`)}
          type="info"
          iconLeft="DotChip"
        />
      ),
    }),
  ];
}
