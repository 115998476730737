import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { Shareholder } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient, getQueryDetailKey } from "../../../";

export type getShareholderById = {
  shareholderId?: string;
  query?: { fields?: string };
};

export const getShareholderById = async (params: getShareholderById) => {
  const apiClient = getApiClient();

  if (!params.shareholderId) {
    throw new Error("shareholderId is required");
  }

  const response =
    await apiClient.apiV1.organizationShareholders.getShareholderById(
      params?.shareholderId,
      params?.query,
    );

  return response.data;
};

export const useGetShareholderById = (
  params: getShareholderById,
  options?: UseQueryOptions<TudigoResponse<Shareholder>, TudigoError>,
) =>
  useQuery({
    queryKey: getQueryDetailKey({
      key: QUERY_KEYS.ORGANIZATION_SHAREHOLDERS,
      id: params.shareholderId!,
      fields: params?.query?.fields,
    }),
    queryFn: () => getShareholderById(params),
    retry: 0,
    ...options,
  });
