import { createColumnHelper } from "@tanstack/react-table";

import { SubscriptionPeriod } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";

export const useClubSubscriptionPeriodsColumn = () => {
  const columnHelper = createColumnHelper<SubscriptionPeriod>();
  const { t } = useTranslation();

  return [
    columnHelper.display({
      header: "Nom",
      cell: (info) => (
        <p className="text-primary text-sm">
          {`${info.row.original.clubMember.user.firstName} ${info.row.original.clubMember.user.lastName}`}
        </p>
      ),
    }),
    columnHelper.accessor("currentPeriodStartDate", {
      header: t("club_leader.page_members.table_members.column.joined_at"),
      cell: (info) => (
        <p className="text-primary text-sm">
          {info.getValue()
            ? new Date(info.getValue()).toLocaleDateString()
            : "-"}
        </p>
      ),
    }),
    columnHelper.accessor("price.unitAmountInCents", {
      header: "Montant",
      cell: (info) => (
        <p className="text-primary text-sm">
          {formatCurrency(info.getValue())}
        </p>
      ),
    }),
  ];
};
