import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import {
  getResourceFromTudigoResponse,
  TudigoError,
} from "@tudigo-monorepo/core-tudigo-api";
import { Form } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient } from "../../../";
import { getQueryDetailKey } from "../../query-key-factory";

type GetFormById = {
  formId: string;
  query?: { fields?: string };
};

export const getFormById = async (params: GetFormById) => {
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.forms.getFormById(
    params?.formId,
    params?.query,
  );

  return getResourceFromTudigoResponse(response.data);
};

export const useGetFormById = (
  params: GetFormById,
  options?: UseQueryOptions<Form, TudigoError>,
) =>
  useQuery({
    queryKey: getQueryDetailKey({
      key: QUERY_KEYS.FORM,
      id: params.formId,
      fields: params?.query?.fields,
    }),
    queryFn: () => getFormById(params),
    ...options,
  });
