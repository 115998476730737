import {
  Stack,
  Title,
  TitleVariant,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

type HeadingBlockProps = {
  title: string | null;
  level: number | null;
  caption: string | null;
};

export function HeadingBlock(props: HeadingBlockProps) {
  const { title, level, caption } = props;

  if (!title) return null;

  return (
    <Stack
      className="dynamic-form-heading-block border-light-1 border-b pb-4"
      spacing={2}
    >
      <Title
        level={level ? (`h${level}` as TitleVariant) : "h1"}
        className="w-full leading-9 sm:w-11/12"
      >
        {title}
      </Title>
      {caption ? <Typography>{caption}</Typography> : null}
    </Stack>
  );
}
