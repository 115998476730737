import { toast } from "react-toastify";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { getSignatureRequestSyncById } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  SignatureIframe,
  SlidingPanel,
} from "@tudigo-monorepo/web-tudigo-components";

interface TaxExemptionSignaturePanelProps {
  isOpen: boolean;
  signatureLink: string | null;
  taxExemptionName: string;
  onClose: () => void;
  onSignatureChange: (isSuccess: boolean) => void;
}

export function TaxExemptionSignaturePanel(
  props: TaxExemptionSignaturePanelProps,
) {
  const {
    isOpen,
    signatureLink,
    taxExemptionName,
    onClose,
    onSignatureChange,
  } = props;

  const { t } = useTranslation();

  const handleSignatureSuccess = async (signatureRequestId: string) => {
    try {
      await getSignatureRequestSyncById(signatureRequestId);
      toast((content) => (
        <Alert
          close={content.closeToast}
          icon="Checked"
          variant="success"
          size="S"
          title={t(
            `my-profile-exemptions.tax-exemption.${taxExemptionName}.alert_success`,
          )}
        />
      ));
      onSignatureChange(true);
    } catch (error) {
      toast((content) => (
        <Alert
          close={content.closeToast}
          icon="Error"
          variant="error"
          size="S"
          title={t(
            `my-profile-exemptions.tax-exemption.${taxExemptionName}.alert_error`,
          )}
        />
      ));
      onSignatureChange(false);
    }
  };

  return (
    <SlidingPanel isOpen={isOpen} setIsOpen={onClose}>
      {signatureLink && (
        <SignatureIframe
          signatureLink={signatureLink}
          onSuccess={handleSignatureSuccess}
        />
      )}
    </SlidingPanel>
  );
}
