import { useState } from "react";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Checkbox } from "@tudigo-monorepo/web-tudigo-components";

const qualificationConsents = {
  investmentCapacityWarning: false,
  investorProfileUnderstanding: false,
  investmentRisksUnderstanding: false,
};

interface InvestorProfileConsentsContentProps {
  onChange: (
    data: typeof qualificationConsents & { allConsented: boolean },
  ) => void;
}

export function InvestorProfileConsentsContent({
  onChange,
}: InvestorProfileConsentsContentProps) {
  const { t } = useTranslation();

  const [consents, setConsents] = useState<typeof qualificationConsents>(
    qualificationConsents,
  );

  const handleConsentsChange = (key: string, checked: boolean) => {
    setConsents((prev) => {
      const newConsents = { ...prev, [key]: checked };

      const allConsented = Object.values(newConsents).every(
        (consented) => consented,
      );

      onChange({ allConsented, ...newConsents });

      return newConsents;
    });
  };

  return (
    <ul className="flex flex-col gap-y-4">
      <li className="flex items-center gap-x-2.5">
        <Checkbox
          id="investmentCapacityWarning"
          className="relative"
          checked={consents.investmentCapacityWarning}
          onCheckedChange={(checked) => {
            handleConsentsChange("investmentCapacityWarning", !!checked);
          }}
        />
        <label
          htmlFor="investmentCapacityWarning"
          className="font-montserrat text-dark-1 cursor-pointer text-[13px]"
        >
          {t(
            "qualification.summary_page.investor_profile.consents.investment_capacity_warning",
          )}
        </label>
      </li>

      <li className="flex items-center gap-x-2.5">
        <Checkbox
          id="investorProfileUnderstanding"
          className="relative"
          checked={consents.investorProfileUnderstanding}
          onCheckedChange={(checked) => {
            handleConsentsChange("investorProfileUnderstanding", !!checked);
          }}
        />
        <label
          htmlFor="investorProfileUnderstanding"
          className="font-montserrat text-dark-1 cursor-pointer text-[13px]"
        >
          {t(
            "qualification.summary_page.investor_profile.consents.investor_profile_understanding",
          )}
        </label>
      </li>

      <li className="flex items-center gap-x-2.5">
        <Checkbox
          id="investmentRisksUnderstanding"
          className="relative"
          checked={consents.investmentRisksUnderstanding}
          onCheckedChange={(checked) => {
            handleConsentsChange("investmentRisksUnderstanding", !!checked);
          }}
        />
        <label
          htmlFor="investmentRisksUnderstanding"
          className="font-montserrat text-dark-1 cursor-pointer text-[13px]"
        >
          {t(
            "qualification.summary_page.investor_profile.consents.investment_risks_understanding",
          )}
        </label>
      </li>
    </ul>
  );
}
