import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import {
  WMACustomer,
  WMACustomerStatusEnum,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient, getQueryDetailKey } from "../../../";

type UpdateWMACustomerInvitationParams = {
  wmaCustomerId: string;
  data: { status: WMACustomerStatusEnum };
  query?: { fields?: string };
};

export const updateWMACustomerInvitation = async (
  params: UpdateWMACustomerInvitationParams,
) => {
  const apiClient = getApiClient();
  const { wmaCustomerId, data, query } = params;

  const response =
    await apiClient.apiV1.wmaCustomers.updateWMACustomerInvitation(
      wmaCustomerId,
      { status: data.status },
      query,
    );

  return response.data;
};

export const useUpdateWMACustomerInvitationMutation = (
  options?: UseMutationOptions<
    TudigoResponse<WMACustomer>,
    TudigoError,
    UpdateWMACustomerInvitationParams
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (params: UpdateWMACustomerInvitationParams) =>
      updateWMACustomerInvitation(params),
    onSuccess: (...props) => {
      const [, params] = props;

      const detailKey = getQueryDetailKey({
        key: QUERY_KEYS.WMA_CUSTOMERS,
        id: params?.wmaCustomerId,
        fields: params.query?.fields,
      });

      queryClient.invalidateQueries({ queryKey: detailKey });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.WMA_CUSTOMERS] });

      options?.onSuccess?.(...props);
    },
  });
};
