import React from "react";

import { DynamicFormPage } from "./dynamic-form-page";
import { useDynamicFormContext } from "./store/dynamic-form-context";

export function DynamicFormSections() {
  const { currentPageIndex, currentPage } = useDynamicFormContext();

  const containerRef = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ top: 0 });
    }
  }, [currentPageIndex]);

  return (
    <main
      ref={containerRef}
      className="dynamic-form-sections mx-auto mb-[120px] mt-[104px] w-full overflow-y-auto overflow-x-hidden px-4 py-8 sm:mb-[104px] sm:w-2/3 sm:overflow-x-visible sm:px-12 sm:pb-16"
    >
      <DynamicFormPage key={currentPageIndex} page={currentPage} />
    </main>
  );
}
