import { ArrowLeft } from "lucide-react";
import { Link } from "react-router-dom";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { ButtonRebrand, Stepper } from "@tudigo-monorepo/web-tudigo-components";

import { useSubscribeClubNavigationStepper } from "./use-subscribe-club-navigation-stepper";

export function SubscribeClubNavigationStepper({
  clubId,
}: {
  clubId?: string;
}) {
  const { t } = useTranslation();

  const { steps, isLastStep, activeStep } =
    useSubscribeClubNavigationStepper(clubId);

  return (
    <nav className="flex w-full items-center justify-between gap-x-6">
      <Link
        to={`/clubs-v2/${clubId}`}
        className={cn({ invisible: isLastStep })}
      >
        <ButtonRebrand
          variant="outline"
          className="border-medium-2 hover:bg-accent-light h-10 w-10 gap-x-2 rounded-3xl px-3 py-[7px] lg:w-[176px] lg:px-4"
        >
          <ArrowLeft className="text-dark-2 h-5 w-5" />
          <span className="text-dark-2 hidden font-sans text-sm lg:inline-block">
            {t("subscribe_club_page.navigation_stepper.button.back_to_circle")}
          </span>
        </ButtonRebrand>
      </Link>

      <Stepper
        steps={steps}
        activeStepIndex={activeStep.page - 1}
        className="mx-auto lg:max-w-[600px]"
      />

      <aside className="h-10 w-10 lg:w-[176px]" />
    </nav>
  );
}
