import { PropsWithChildren } from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { AccountCard } from "@tudigo-monorepo/web-tudigo-components";

import { useUserBusiness } from "../../hooks/use-user-business";
import { SpaceType } from "../../types";
import { SessionAvatar } from "../session-avatar/session-avatar";

type SessionCardProps = PropsWithChildren<{
  currentSpace: SpaceType;
  description?: string;
  reducedDisplay?: boolean;
  className?: string;
  textClassName?: string;
  onClick?(): void;
}>;

export function SessionCard({
  children,
  currentSpace,
  description,
  reducedDisplay,
  className,
  textClassName,
  onClick,
}: SessionCardProps) {
  const { t } = useTranslation();
  const { user } = useUserBusiness();

  if (!user) {
    return null;
  }

  const fullUserName = `${user.firstName} ${user.lastName}`;

  return (
    <AccountCard
      onClick={onClick}
      upperText={t(`user_role.${currentSpace}`)}
      lowerText={description || fullUserName}
      size={reducedDisplay ? "XS" : "full"}
      avatar={<SessionAvatar profileImage={user.profileImage} />}
      className={cn("hover:border-transparent", className)}
      textClassName={textClassName}
    >
      {children}
    </AccountCard>
  );
}
