import { Investment } from "@tudigo-monorepo/core-tudigo-api-models";
import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  Chip,
  Icon,
  ListElement,
} from "@tudigo-monorepo/web-tudigo-components";

export function UserPayInvestmentTodoListElement({
  investment,
}: {
  investment: Investment | null;
}) {
  const { t } = useTranslation();

  if (!investment) {
    return null;
  }

  if (investment.globalStatus !== "waiting_for_payment") {
    return null;
  }

  const payInvestmentPath = `/projects/${investment.project.id}/invest/${investment.id}`;

  return (
    <ListElement
      navigateTo={payInvestmentPath}
      startElement={{
        img: {
          icon: { name: "Invest" },
        },
        upperText: t(
          "dashboard.user_todo_list.investment_to_pay.list_element.title",
        ),
        lowerText: investment.project.content?.name,
        profileCardClassName: "min-w-[280px] flex justify-start px-3 py-2",
      }}
      middleElement={{
        upperText: t(
          "dashboard.user_todo_list.investment_to_pay.list_element.desc",
        ),
        chips: [
          {
            label: investment.investorOrganization.name || "",
            type: "secondary",
          },
        ],
      }}
      endElement={{
        render: () => {
          return (
            <div className="flex items-center gap-x-8">
              <Chip
                label={t(
                  "dashboard.user_todo_list.investment_to_pay.list_element.chip.label",
                )}
                size="S"
                type="warning"
                iconLeft="DotChip"
              />
              <Icon name="ChevronRight" primaryColor={themeColors["dark-2"]} />
            </div>
          );
        },
      }}
    />
  );
}
