import { useMemo } from "react";
import { Link } from "react-router-dom";

import { UserRole } from "@tudigo-monorepo/core-tudigo-api-models";
import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { Icon } from "@tudigo-monorepo/web-tudigo-components";

import { useUserBusiness } from "../../hooks/use-user-business";
import { useSpaceBusiness } from "../../providers/space-provider";
import { PageEnum, SpaceEnum } from "../../types";
import { SessionCard } from "../session-card/session-card";

type SessionRoleSwitcherProps = {
  onChangeRole?: () => void;
};

export function SpaceSwitcher(props: SessionRoleSwitcherProps) {
  const { pagePaths } = useSpaceBusiness();
  const { user } = useUserBusiness();
  const { currentSpace } = useSpaceBusiness();
  const isBackOffice = window.location.hostname.startsWith("bo");
  const availableSpacesToSwitchOn = useMemo(() => {
    if (isBackOffice) {
      return [];
    }
    const spaces = [];
    spaces.push(SpaceEnum.INVESTOR);
    if (user?.appRoles?.includes(UserRole.WMA)) {
      spaces.push(SpaceEnum.WMA);
    }
    if (user?.appRoles?.includes(UserRole.PROJECT_HOLDER)) {
      spaces.push(SpaceEnum.PROJECT_HOLDER);
    }
    if (user?.appRoles?.includes(UserRole.CIRCLE_LEADER)) {
      spaces.push(SpaceEnum.CIRCLE_LEADER);
    }

    return spaces.filter((space) => space.toString() !== currentSpace);
  }, [currentSpace, isBackOffice, user?.appRoles]);

  return (
    <div className="flex flex-col gap-y-1">
      {availableSpacesToSwitchOn.map((space) => (
        <Link to={pagePaths[PageEnum.DASHBOARD][space]} key={space}>
          <SessionCard
            currentSpace={space}
            className="hover:border-accent-medium hover:border"
          >
            <Icon
              name="ChevronRight"
              primaryColor={themeColors["dark-2"]}
              /* not implemented in MVP 1 */
              // name={hasRoleAccess(item) ? "ChevronRight" : "Plus"}
            />
          </SessionCard>
        </Link>
      ))}
    </div>
  );
}
