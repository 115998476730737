import { Navigate, Route, Routes } from "react-router-dom";

import { MyClubsRoutes } from "@tudigo-monorepo/feature-clubs";
import { SpaceEnum, SpaceProvider } from "@tudigo-monorepo/tudigo-session";
import { InvestorDashboardRoute } from "@tudigo-monorepo/web-feature-dashboard";
import { InvestmentFunnelRoute } from "@tudigo-monorepo/web-feature-investment-funnel";
import { InvestmentRouteWebapp } from "@tudigo-monorepo/web-feature-investments";
import { KYCRoute } from "@tudigo-monorepo/web-feature-kyc";
import { MyProfileRoute } from "@tudigo-monorepo/web-feature-my-profile";
import { MySettingsRoute } from "@tudigo-monorepo/web-feature-my-settings";
import { ProjectRoutesWebApp } from "@tudigo-monorepo/web-feature-projects";
import { RepaymentsRouteWebApp } from "@tudigo-monorepo/web-feature-repayments";
import { WMARoutesWebapp } from "@tudigo-monorepo/web-feature-wma";
import {
  ConnectedLayout,
  useInvestorNavigation,
} from "@tudigo-monorepo/web-tudigo-layout";
import {
  ClubsV2ClubPageRouteWebapp,
  ClubsV2InvestorRouteWebapp,
} from "@tudigo-monorepo/web/feature-clubs-v2";

export const InvestorRoutes = () => {
  return (
    <SpaceProvider currentSpace={SpaceEnum.INVESTOR}>
      <Routes>
        <Route
          path="projects/:projectId/invest/*"
          element={<InvestmentFunnelRoute />}
        />
        <Route
          path={"organization/:organizationId/kyc/*"}
          element={<KYCRoute />}
        />
        <Route path="projects?/*" element={<ProjectRoutesWebApp />} />
        <Route path="clubs-v2/*" element={<ClubsV2ClubPageRouteWebapp />} />
        <Route
          element={<ConnectedLayout navigation={useInvestorNavigation()} />}
        >
          <Route path="dashboard/*" element={<InvestorDashboardRoute />} />
          <Route path="my-investments/*" element={<InvestmentRouteWebapp />} />
          <Route path="my-repayments/" element={<RepaymentsRouteWebApp />} />
          <Route path="clubs/*" element={<MyClubsRoutes />} />
          <Route path="circles/*" element={<ClubsV2InvestorRouteWebapp />} />
          <Route path="wma/*" element={<WMARoutesWebapp />} />
          <Route path="my-settings/*" element={<MySettingsRoute />} />
          <Route path="profile-and-accounts/*" element={<MyProfileRoute />} />
          <Route path="*" element={<Navigate to="/my-account/dashboard" />} />
        </Route>
      </Routes>
    </SpaceProvider>
  );
};
