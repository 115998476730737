import { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { PageHead } from "@tudigo-monorepo/web-tudigo-components";

export function ProjectHolderDashboardPage() {
  const { user } = useUserBusiness();
  const { projectId } = useParams();
  const navigate = useNavigate();

  const projects = user?.projectHolderProfile?.projects;

  useEffect(() => {
    if (projects && projects.length >= 1) {
      const project =
        projects.find((project) => project.id === projectId) ?? projects[0];

      navigate(`/project-holder/dashboard/${project.id}`, { replace: true });
    }
  }, [projects, projectId, user, navigate]);

  return (
    <div id="dashboard-page" className="w-full">
      <PageHead title="Tableau de bord" />

      <Outlet />
    </div>
  );
}
