import { useEffect } from "react";
import mixpanel from "mixpanel-browser";

export const useInitMixpanel = (token: string) => {
  useEffect(() => {
    mixpanel.init(token, {
      debug: false,
      track_pageview: true,
      persistence: "cookie",
    });

    return () => {
      mixpanel.reset();
    };
  }, [token]);
};

export const mixpanelIdentify = (userId: string) => {
  mixpanel.identify(userId);
};

export const mixpanelTrackPageView = (
  pageName: string,
  properties?: Record<string, string | undefined>,
) => {
  mixpanel.track_pageview({
    page: pageName,
    ...properties,
  });
};

export const mixpanelTrackEvent = (
  name: string,
  properties: Record<string, string | undefined>,
) => {
  mixpanel.track(name, properties);
};
