import { PropsWithChildren } from "react";

import { User } from "@tudigo-monorepo/core-tudigo-api-models";
import { getImageUrl, getInitials } from "@tudigo-monorepo/core-tudigo-utils";
import { Avatar } from "@tudigo-monorepo/web-tudigo-components";

import { useUserBusiness } from "../../hooks/use-user-business";

type SessionAvatarProps = PropsWithChildren<{
  profileImage?: User["profileImage"] | null;
}>;

export function SessionAvatar({ profileImage, children }: SessionAvatarProps) {
  const { user } = useUserBusiness();

  const connectedUserInitials = getInitials(
    user?.firstName ?? "",
    user?.lastName ?? "",
  );

  if (profileImage) {
    return <Avatar url={getImageUrl(profileImage, 40, 40, "cropped", "")} />;
  }

  if (children) {
    return <Avatar>{children}</Avatar>;
  }

  return <Avatar>{connectedUserInitials}</Avatar>;
}
