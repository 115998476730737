import {
  CMSCardsTextBlock,
  CMSCardsTextWithImageBlock,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { ThinLayout } from "@tudigo-monorepo/web-tudigo-components";
import { EditorSettings } from "@tudigo-monorepo/web-tudigo-editor";

import { ProjectCardBlock } from "./project-card-block";

type ProjectCardsBlockProps = {
  block: CMSCardsTextBlock | CMSCardsTextWithImageBlock;
  editMode?: boolean;
  editorSettings?: EditorSettings;
};

export function ProjectCardsBlock(props: ProjectCardsBlockProps) {
  const { block } = props;

  const { type } = block;
  const { cards } = block.data;
  const blockType =
    type === "cards-text" ? "card-text" : "card-text-with-image";

  const isPair = cards.length % 2 === 0;

  return (
    <ThinLayout className="@md:grid-cols-2 grid grid-cols-1 gap-4">
      {cards.map((card, index) => (
        <ProjectCardBlock
          key={index}
          className={cn(!isPair && index === 0 && "@md:col-span-2 col-span-1")}
          block={{ id: block.id, type: blockType, data: card as any }}
        />
      ))}
    </ThinLayout>
  );
}
