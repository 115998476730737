import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { Comment } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient } from "../../..";

type CreateCommentParams = {
  project?: {
    id: string;
  };
  club?: {
    id: string;
  };
  value: string;
  parent?: { id: string };
};

export const createComment = async (params: CreateCommentParams) => {
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.comments.createComment(params);

  return response.data;
};

export const useCreateCommentMutation = (
  options?: UseMutationOptions<
    TudigoResponse<Comment>,
    TudigoError,
    CreateCommentParams
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (params) => createComment(params),
    onSuccess: async (...props) => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.COMMENTS],
      });
      options?.onSuccess?.(...props);
    },
  });
};
