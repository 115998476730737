import React from "react";

import { Organization } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { ORGANIZATIONS } from "@tudigo-monorepo/core-tudigo-utils";
import { useGetUserOrganizationsQuery } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  AutoComplete,
  TextField,
} from "@tudigo-monorepo/web-tudigo-components";

type UserOrganizationsAutocompleteProps = {
  userId: string | null;
  selectedOrganization: Organization | null;
  setSelectedOrganization: (organization: Organization) => void;
  onChange?: (organization: Organization) => void;
  required?: boolean;
};

export function UserOrganizationAutocomplete(
  props: UserOrganizationsAutocompleteProps,
) {
  const {
    userId,
    setSelectedOrganization,
    selectedOrganization,
    onChange,
    required = false,
  } = props;

  const { t } = useTranslation();

  const { data: usersOrganizationsResponses, isPending } =
    useGetUserOrganizationsQuery({
      filters: { status: "ACTIVE" },
      pagination: { limit: 1000 },
      pathParams: { userId: userId ?? "me" },
      fields:
        "id,name,type,internal_kyc_status,equity_saving_plan,small_business_equity_saving_plan",
    });
  const userOrganizations = usersOrganizationsResponses?.data ?? [];

  const kycApprovedOrganizations = userOrganizations.filter(
    (organization) =>
      organization.internalKycStatus ===
      ORGANIZATIONS.INTERNAL_KYC_STATUS.APPROVED,
  );

  if (!isPending && kycApprovedOrganizations.length === 0) {
    return (
      <>
        <Alert
          variant="warning"
          icon="Info"
          description={t(
            "investments.back_office.create_investment_page.form.user_organizations_autocomplete.no_data_alert.description",
          )}
        />
        <TextField
          disabled
          label={t(
            "investments.back_office.create_investment_page.form.user_organizations_autocomplete.label",
          )}
          value={t(
            "investments.back_office.create_investment_page.form.user_organizations_autocomplete.no_data.placeholder",
          )}
        />
      </>
    );
  }

  return (
    <AutoComplete
      label={t(
        "investments.back_office.create_investment_page.form.user_organizations_autocomplete.label",
      )}
      required={required}
      errors={[]}
      items={kycApprovedOrganizations.map((organization) => ({
        id: organization.id,
        name: `${organization.name} (${
          organization.type === ORGANIZATIONS.TYPE.INDIVIDUAL
            ? "Personne physique"
            : "Personne morale"
        })`,
      }))}
      filterFn={(item, value) =>
        item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
      }
      selection={kycApprovedOrganizations
        .map((organization) => ({
          id: organization.id,
          name: `${organization.name} (${
            organization.type === ORGANIZATIONS.TYPE.INDIVIDUAL
              ? "Personne physique"
              : "Personne morale"
          })`,
        }))
        .find((organization) => organization.id === selectedOrganization?.id)}
      onSelect={(value) => {
        const organization = kycApprovedOrganizations.find(
          (organization) => organization.id === value.id,
        ) as Organization;
        setSelectedOrganization(organization);
        if (onChange) {
          onChange(organization);
        }
      }}
      renderItem={(item: { id: string; name: string }) => (
        <div className="flex flex-row items-center gap-2">
          <div>{item.name}</div>
        </div>
      )}
      getId={(item) => item.id}
      disabled={userId === null}
    />
  );
}
