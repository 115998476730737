import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { createInvestorExport } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  FilterConfiguration,
  TudigoCollection,
  TudigoFilters,
} from "@tudigo-monorepo/web-tudigo-collection";
import {
  Button,
  Table,
  TableCardList,
} from "@tudigo-monorepo/web-tudigo-components";

import {
  ProjectHolderInvestorsFilters,
  useGetProjectHolderInvestors,
} from "../../../../../../tudigo-api-client/src/lib/queries/project-holders/use-get-project-holder-investors";
import {
  InvestStatus,
  useInvestStatuses,
} from "../../../shared/hooks/use-invest-statuses";
import { useProjectHolderInvestorsColumns } from "../hooks/use-project-holder-investors-columns";

const requestInvestorOrganizationFields =
  "id,name,type,organization_representative.fields(phone)";
const requestProjectFields =
  "mechanic,content.fields(name),holder_organization.fields(id,name,internal_kyc_status)";
const requestFields =
  "id," +
  "total_amount_in_cents," +
  "investor_organization.fields(" +
  requestInvestorOrganizationFields +
  ")," +
  "payment_method_type," +
  "payment_date," +
  "created_at," +
  "status," +
  "global_status," +
  "project.fields(" +
  requestProjectFields +
  ")";

export function InvestorsListContent() {
  const columns = useProjectHolderInvestorsColumns();

  const urlInvestStatus = useParams().status as string;

  const intl = useIntl();
  const { user } = useUserBusiness();
  const userId = user?.id as string;

  const investStatuses: InvestStatus[] = useInvestStatuses();

  const currentInvestStatus = investStatuses.find(
    ({ urlParamValue }) => urlParamValue === urlInvestStatus,
  );

  const globalStatus = useMemo(() => {
    return currentInvestStatus && currentInvestStatus.urlParamValue !== "all"
      ? currentInvestStatus.filterValue
      : undefined;
  }, [currentInvestStatus]);

  const [filters, setFilters] = React.useState<ProjectHolderInvestorsFilters>({
    createdAt: undefined,
    projectId: undefined,
    paymentMethodType: "",
    globalStatus: globalStatus === "all" ? undefined : globalStatus,
    status: undefined,
  });

  const [downloading, setDownloading] = useState(false);
  const downloadCSV = () => {
    setDownloading(true);
    createInvestorExport({
      investorProfileId: userId,
      filters: {
        ...filters,
      },
    }).then((response) => {
      setDownloading(false);
      window.open(response.data.signedUrl, "_blank");
    });
  };

  const filterConfiguration: FilterConfiguration<ProjectHolderInvestorsFilters>[] =
    [
      {
        label: intl.formatMessage({ id: "account" }),
        name: "createdAt",
        type: "dateRange",
      },
      {
        label: intl.formatMessage({
          id: "investments.table.columns.payment_method",
        }),
        name: "paymentMethodType",
        placeholder: intl.formatMessage({
          id: "projects.back_office.investments_list.tab-navigation.item.label.all",
        }),
        type: "select",
        options: [
          {
            value: "wire",
            name: intl.formatMessage({
              id: "investment.payment_methode_type.label.wire",
            }),
          },
          {
            value: "card",
            name: intl.formatMessage({
              id: "investment.payment_methode_type.label.card",
            }),
          },
          {
            value: "pea",
            name: intl.formatMessage({
              id: "investment.payment_methode_type.label.pea",
            }),
          },
        ],
      },
    ];

  return (
    <div className="flex flex-col">
      <div className="mx-6 mt-8 md:self-end">
        <Button
          onClick={downloadCSV}
          label="Download CSV"
          iconRight="Download"
          className={cn("w-full md:w-fit")}
          variant="tertiary"
          isLoading={downloading}
          disabled={downloading}
        />
      </div>
      <TudigoFilters
        className="mb-6 mt-4"
        useLocationAsState
        filters={filters}
        setFilters={setFilters}
        filtersConfiguration={filterConfiguration}
      />
      <TudigoCollection
        useLocationAsState
        columns={columns}
        useQueryFn={useGetProjectHolderInvestors}
        pathParams={{ projectHolderProfileId: userId }}
        fields={requestFields}
        filters={{ ...filters, globalStatus }}
        pagination={{ pageIndex: 0, pageSize: 20 }}
        render={(table, props) => {
          return (
            <>
              <Table
                table={table}
                actions={props.actions}
                className="hidden sm:table"
              />
              <TableCardList table={table} className="sm:hidden" />
            </>
          );
        }}
      />
    </div>
  );
}
