import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { InvestorProfile } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient, getQueryDetailKey, getQueryKey } from "../../../";

type InvestorProfileParams = {
  userId: string;
  investorProfile: InvestorProfile;
};

export const updateInvestorProfile = async (params: InvestorProfileParams) => {
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.investorProfiles.updateInvestorProfile(
    params.userId,
    params.investorProfile,
  );

  return response.data;
};

export const useUpdateInvestorProfileMutation = (
  options?: UseMutationOptions<
    TudigoResponse<InvestorProfile>,
    TudigoError,
    InvestorProfileParams
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: InvestorProfileParams) =>
      updateInvestorProfile(params),
    ...options,
    onSuccess: (...props) => {
      const [, params] = props;

      queryClient.invalidateQueries({
        queryKey: getQueryKey(QUERY_KEYS.USERS),
      });

      queryClient.invalidateQueries({
        queryKey: getQueryDetailKey({
          key: QUERY_KEYS.USERS,
          id: params.userId,
        }),
      });

      queryClient.invalidateQueries({
        queryKey: getQueryDetailKey({
          key: QUERY_KEYS.USERS,
          id: "me",
        }),
      });

      options?.onSuccess?.(...props);
    },
  });
};
