import { FC, PropsWithChildren } from "react";
import { Link } from "react-router-dom";

import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Icon } from "../icons/icon";
import { IconName } from "../icons/icon-name.type";
import { Typography } from "../typography/typography";

export type BreadcrumbLevel = {
  url?: string;
  callback?: () => void;
  text?: string;
  iconName?: IconName;
};

type BreadcrumbsProps = {
  levels: BreadcrumbLevel[];
};

type BreadcrumbLevelProps = {
  level: BreadcrumbLevel;
};

type OnClickWrapperProps = {
  url: BreadcrumbLevel["url"];
  callback: BreadcrumbLevel["callback"];
};

const OnClickWrapper: FC<PropsWithChildren<OnClickWrapperProps>> = ({
  url,
  callback,
  children,
}) => {
  if (!url && !callback) return children;
  if (url) return <Link to={url}>{children}</Link>;

  return (
    <div className="cursor-pointer" onClick={callback}>
      {children}
    </div>
  );
};

const BreadcrumbLevel: FC<BreadcrumbLevelProps> = ({ level }) => {
  const { url, callback, text, iconName } = level;

  return (
    <OnClickWrapper url={url} callback={callback}>
      {iconName ? (
        <Icon name={iconName} />
      ) : (
        <Typography variant="body2">{text}</Typography>
      )}
    </OnClickWrapper>
  );
};

export function Breadcrumbs(props: BreadcrumbsProps) {
  const { levels } = props;

  return (
    <div className="flex flex-row gap-4">
      {levels.map((level, levelIndex) => (
        <div key={levelIndex} className="flex flex-row items-center gap-4">
          <BreadcrumbLevel level={level} />
          <Icon
            name="ChevronRight"
            width={18}
            height={18}
            primaryColor={themeColors["medium-1"]}
          />
        </div>
      ))}
    </div>
  );
}
