import { CancellationDetailReasonType } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

export type CancelInvestmentReasonItem = {
  label: string;
  value: CancellationDetailReasonType;
};

export const useCancelInvestmentReasons = (): Record<
  "userCancelReasons" | "internalCancelReasons" | "dunningCancelReasons",
  CancelInvestmentReasonItem[]
> => {
  const { t } = useTranslation();

  const userCancelReasons = [
    {
      label: t(
        "cancel_investment_request.cancellation_detail.reason.lack_of_funds.label",
      ),
      value: CancellationDetailReasonType.LACK_OF_FUNDS,
    },
    {
      label: t(
        "cancel_investment_request.cancellation_detail.reason.change_of_mind.label",
      ),
      value: CancellationDetailReasonType.CHANGE_OF_MIND,
    },
    {
      label: t(
        "cancel_investment_request.cancellation_detail.reason.pea_process.label",
      ),
      value: CancellationDetailReasonType.PEA_PROCESS,
    },
  ];

  const internalCancelReasons = [
    {
      label: t(
        "cancel_investment_request.cancellation_detail.reason.payment_method_update.label",
      ),
      value: CancellationDetailReasonType.PAYMENT_METHOD_UPDATE,
    },
    {
      label: t(
        "cancel_investment_request.cancellation_detail.reason.amount_update.label",
      ),
      value: CancellationDetailReasonType.AMOUNT_UPDATE,
    },
    {
      label: t(
        "cancel_investment_request.cancellation_detail.reason.project_update.label",
      ),
      value: CancellationDetailReasonType.PROJECT_UPDATE,
    },
    {
      label: t(
        "cancel_investment_request.cancellation_detail.reason.account_update.label",
      ),
      value: CancellationDetailReasonType.ACCOUNT_UPDATE,
    },
    {
      label: t(
        "cancel_investment_request.cancellation_detail.reason.test_team_tudigo.label",
      ),
      value: CancellationDetailReasonType.TEST_TEAM_TUDIGO,
    },
    {
      label: t(
        "cancel_investment_request.cancellation_detail.reason.test_investor.label",
      ),
      value: CancellationDetailReasonType.TEST_INVESTOR,
    },
    {
      label: t(
        "cancel_investment_request.cancellation_detail.reason.project_fundraising_failure.label",
      ),
      value: CancellationDetailReasonType.PROJECT_FUNDRAISING_FAILURE,
    },
    {
      label: t(
        "cancel_investment_request.cancellation_detail.reason.duplicate.label",
      ),
      value: CancellationDetailReasonType.DUPLICATE,
    },
    {
      label: t(
        "cancel_investment_request.cancellation_detail.reason.lemonway.label",
      ),
      value: CancellationDetailReasonType.LEMONWAY,
    },
    {
      label: t(
        "cancel_investment_request.cancellation_detail.reason.conflict_of_interest.label",
      ),
      value: CancellationDetailReasonType.CONFLICT_OF_INTEREST,
    },
  ];

  const dunningCancelReasons = [
    {
      label: t(
        "cancel_investment_request.cancellation_detail.reason.no_payment_method.label",
      ),
      value: CancellationDetailReasonType.NO_PAYMENT_METHOD,
    },
    {
      label: t(
        "cancel_investment_request.cancellation_detail.reason.unreachable.label",
      ),
      value: CancellationDetailReasonType.UNREACHABLE,
    },
    {
      label: t(
        "cancel_investment_request.cancellation_detail.reason.change_of_mind.label",
      ),
      value: CancellationDetailReasonType.CHANGE_OF_MIND,
    },
    {
      label: t(
        "cancel_investment_request.cancellation_detail.reason.lack_of_funds.label",
      ),
      value: CancellationDetailReasonType.LACK_OF_FUNDS,
    },
    {
      label: t(
        "cancel_investment_request.cancellation_detail.reason.out_of_time.label",
      ),
      value: CancellationDetailReasonType.OUT_OF_TIME,
    },
    {
      label: t(
        "cancel_investment_request.cancellation_detail.reason.pea_process.label",
      ),
      value: CancellationDetailReasonType.PEA_PROCESS,
    },
    {
      label: t(
        "cancel_investment_request.cancellation_detail.reason.ceiling_reached.label",
      ),
      value: CancellationDetailReasonType.CEILING_REACHED,
    },
    {
      label: t(
        "cancel_investment_request.cancellation_detail.reason.does_not_want_to_join_club.label",
      ),
      value: CancellationDetailReasonType.DOES_NOT_WANT_TO_JOIN_CLUB,
    },
    {
      label: t(
        "cancel_investment_request.cancellation_detail.reason.pea_minimum.label",
      ),
      value: CancellationDetailReasonType.PEA_MINIMUM,
    },
  ];

  return { userCancelReasons, internalCancelReasons, dunningCancelReasons };
};
