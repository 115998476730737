import { useParams } from "react-router-dom";

import {
  emptyClubShape,
  type ClubV2,
} from "@tudigo-monorepo/core-tudigo-api-models";
import {
  useGetCircleById,
  type GetCircleByIdQueryOptions,
} from "@tudigo-monorepo/web-tudigo-api-client";

import { clubV2QueryFields } from "../utils/club-v2-fields";

interface useGetClubV2UsingIdPathParamsParams {
  clubId?: string;
  fields?: string;
  defaultEmptyClub?: boolean;
  options?: GetCircleByIdQueryOptions;
}

/**
 * Wrapper around useGetCircleById query. Fetches a club by clubId path params and returns it.
 * @function useGetClubV2UsingIdPathParams
 * @param {string} [params.clubId] - (Optional) The id of the club to fetch. Defaults to the clubId from the url.
 * @param {string} [params.fields] - (Optional) The fields to fetch. Defaults to the clubV2QueryFields.
 * @param {boolean} [params.defaultEmptyClub] - (Optional) Whether to return an empty club if the club is not found.
 * @param {Object} [params.options] - (Optional) The options object to pass to the useGetCircleById query.
 */
const useGetClubV2UsingIdPathParams = <
  TParams extends useGetClubV2UsingIdPathParamsParams,
>(
  params?: TParams,
) => {
  const clubId = useParams().clubId as string;
  const defaultClub = params?.defaultEmptyClub ? emptyClubShape : undefined;

  const { data: club = defaultClub } = useGetCircleById(
    {
      clubId: params?.clubId ?? clubId,
      fields: params?.fields ?? clubV2QueryFields,
    },
    params?.options,
  );

  return club as TParams["defaultEmptyClub"] extends true
    ? ClubV2
    : ClubV2 | undefined;
};

export { useGetClubV2UsingIdPathParams };
