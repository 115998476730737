import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Modal, Typography } from "@tudigo-monorepo/web-tudigo-components";

type EditOrganizationConfirmationModalProps = {
  open: boolean;
  isBankAccountUpdate?: boolean;
  onCancel: (open: boolean) => void;
  onConfirm: () => void;
};

export function EditOrganizationConfirmationModal({
  open,
  isBankAccountUpdate = false,
  onCancel,
  onConfirm,
}: EditOrganizationConfirmationModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={open}
      setIsOpen={() => onCancel(false)}
      className="min-h-[605px] w-[calc(100%-32px)] sm:max-w-[654px]"
      title={t("organizations.back_office.update_informations.modal.title")}
      negativeButtonProps={{ variant: "ghost" }}
      confirmLabel={t(
        "organizations.back_office.update_informations.modal.confirm_label",
      )}
      confirmAction={onConfirm}
    >
      <div className="flex flex-col gap-y-4 p-4">
        <Typography variant="caption1-regular">
          {isBankAccountUpdate
            ? t(
                "organizations.back_office.update_informations.modal.desc.bank_account_warning",
              )
            : t(
                "organizations.back_office.update_informations.modal.desc.marital_or_financial_status_warning",
              )}
        </Typography>
        <Typography variant="caption1-regular">
          {t(
            "organizations.back_office.update_informations.modal.desc.validation_time",
          )}
        </Typography>
        <Typography variant="caption1-regular">
          {t(
            "organizations.back_office.update_informations.modal.desc.contact",
          )}
        </Typography>
        <Typography variant="caption1-semibold">
          {isBankAccountUpdate
            ? t(
                "organizations.back_office.update_informations.modal.desc.bank_account_confirmation",
              )
            : t(
                "organizations.back_office.update_informations.modal.desc.marital_or_financial_status_confirmation",
              )}
        </Typography>
      </div>
    </Modal>
  );
}
