import { Navigate, Route, Routes } from "react-router-dom";

import { SpaceEnum, useSpaceBusiness } from "@tudigo-monorepo/tudigo-session";

import { InvestorProfileInformationContent } from "../pages/investor-profile-page/content/investor-profile-information-content";
import { InvestorProfileOrganizationsContent } from "../pages/investor-profile-page/content/investor-profile-organizations-content";
import { InvestorProfileTaxExemptionsContent } from "../pages/investor-profile-page/content/investor-profile-tax-exemptions-content";
import { InvestorProfilePage } from "../pages/investor-profile-page/investor-profile-page";
import { MyProfileInvestmentAccountDetailsPage } from "../pages/my-profile-investment-account-details-page/my-profile-investment-account-details-page";
import { MyProfileOrganizationDetailsPage } from "../pages/my-profile-organization-details-page/my-profile-organization-details-page";
import { ProjectHolderProfileOrganizationsContent } from "../pages/project-holder-profile-page/content/project-holder-profile-organizations-content";
import { ProjectHolderProfilePage } from "../pages/project-holder-profile-page/project-holder-profile-page";
import { WmaProfileOrganizationsContent } from "../pages/wma-profile-page/content/wma-profile-organizations-content";
import { WmaProfilePage } from "../pages/wma-profile-page/wma-profile-page";

export function MyProfileRoute() {
  const { currentSpace } = useSpaceBusiness();

  return (
    <Routes>
      <Route
        path="organizations/*"
        element={
          <Routes>
            <Route
              path=":organizationId"
              element={<MyProfileOrganizationDetailsPage />}
            />
            <Route
              path=":organizationId/type/:type"
              element={<MyProfileInvestmentAccountDetailsPage />}
            />
          </Routes>
        }
      />
      {currentSpace === SpaceEnum.INVESTOR && (
        <Route element={<InvestorProfilePage />}>
          <Route
            path="informations"
            element={<InvestorProfileInformationContent />}
          />
          <Route
            path="organizations"
            index
            element={<InvestorProfileOrganizationsContent />}
          />

          <Route
            path="tax-exemptions"
            element={<InvestorProfileTaxExemptionsContent />}
          />
          <Route path="*" element={<Navigate to="informations" />} />
        </Route>
      )}
      {currentSpace === SpaceEnum.WMA && (
        <Route element={<WmaProfilePage />}>
          <Route
            path="organizations"
            element={<WmaProfileOrganizationsContent />}
          />
          <Route path="*" element={<Navigate to="organizations" />} />
        </Route>
      )}

      {currentSpace === SpaceEnum.PROJECT_HOLDER && (
        <Route element={<ProjectHolderProfilePage />}>
          <Route
            path="organizations"
            element={<ProjectHolderProfileOrganizationsContent />}
          />
          <Route path="*" element={<Navigate to="organizations" />} />
        </Route>
      )}
    </Routes>
  );
}
