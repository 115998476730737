import { ChangeEvent } from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import {
  SessionAvatar,
  useSessionStore,
} from "@tudigo-monorepo/tudigo-session";

import { Button } from "../../old/button";
import { TextField } from "../../old/text-field";

type AddCommentProps = {
  newComment: string;
  handleChangeNewComment: (event: ChangeEvent<HTMLInputElement>) => void;
  handleCreateComment: () => void;
  placeholder: string;
  className?: string;
};

export const AddComment = ({
  newComment,
  handleChangeNewComment,
  handleCreateComment,
  placeholder,
  className,
}: AddCommentProps) => {
  const profileImage = useSessionStore((state) => state.user?.profileImage);

  return (
    <div className={cn(className, "flex flex-row justify-between")}>
      <SessionAvatar profileImage={profileImage} />
      <TextField
        type="text"
        value={newComment}
        onChange={handleChangeNewComment}
        className="basis-4/5"
        placeholder={placeholder}
      />
      <Button
        singleIcon="ChevronRight"
        disabled={!newComment}
        onClick={handleCreateComment}
      />
    </div>
  );
};
