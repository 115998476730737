import React from "react";

import { RangeSliderInput } from "@tudigo-monorepo/core-tudigo-api-models";
import { InputSlider } from "@tudigo-monorepo/web-tudigo-components";

import { getSliderIndicators } from "../../utils/form-inputs";

type SliderInputComponentProps = {
  input: RangeSliderInput;
  selectedValue: number;
  onChange: (value: number) => void;
};

export function RangeSliderInputComponent(props: SliderInputComponentProps) {
  const { input, selectedValue, onChange } = props;

  const sliderMin = input.parameters?.min || 0;
  const sliderMax = input.parameters?.max || 10;
  const sliderStep = input.parameters?.step || 1;

  const handleChange = (
    _: React.ChangeEvent<HTMLInputElement> | null,
    value: number,
  ) => {
    onChange(value);
  };

  return (
    <InputSlider
      min={sliderMin}
      max={sliderMax}
      value={selectedValue}
      step={sliderStep}
      valueChip
      indicators={getSliderIndicators(input, sliderMax)}
      onChange={handleChange}
    />
  );
}
