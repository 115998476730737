import React, { MouseEventHandler, PropsWithChildren, ReactNode } from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { Chip } from "../chip";
import { Typography } from "../typography";

type AccountCardProps = PropsWithChildren<{
  upperText?: string;
  lowerText?: string;
  lowerTextAsChip?: boolean;
  avatar?: ReactNode;
  border?: boolean;
  className?: string;
  textClassName?: string;
  disabled?: boolean;
  size?: "XS" | "full";
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  variant?: "dark" | "light";
}>;

export function AccountCard(props: AccountCardProps) {
  const {
    avatar,
    children,
    className,
    textClassName: textClassNameProp,
    upperText,
    lowerText,
    lowerTextAsChip = false,
    size,
    disabled = false,
    border = false,
    onClick,
    onMouseEnter,
    onMouseLeave,
    variant = "dark",
  } = props;

  const textColor = disabled ? "text-dark-4" : "text-dark-1";
  const textClassName = cn(
    "max-w-[204px] truncate text-balance",
    textColor,
    {
      "text-white": variant === "light",
    },
    textClassNameProp,
  );

  const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (onClick && !disabled) {
      onClick();
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <div
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={cn(
        "account-card flex items-center justify-between rounded-lg border px-3 py-2 transition-colors",
        {
          "w-[44px] justify-center px-[7px]": size === "XS",
          "w-full": size === "full",
          "cursor-pointer": (onClick || onMouseEnter) && !disabled,
          "hover:border-accent-medium":
            (onClick || onMouseEnter) && !disabled && size !== "XS",
          "border-transparent": !border,
          "bg-light-2": disabled,
        },
        className,
      )}
    >
      <div className="flex items-center gap-4">
        {avatar ? avatar : null}

        {size !== "XS" ? (
          <div className="flex flex-col items-start text-left">
            {upperText && (
              <Typography variant="caption1-semibold" className={textClassName}>
                {upperText}
              </Typography>
            )}

            {lowerText ? (
              lowerTextAsChip ? (
                <Chip type="primary" label={lowerText} size="S" />
              ) : (
                <Typography variant="body2" className={textClassName}>
                  {lowerText}
                </Typography>
              )
            ) : null}
          </div>
        ) : null}
      </div>

      {children}
    </div>
  );
}
