import { CMSVideoBlock } from "@tudigo-monorepo/core-tudigo-api-models";
import { getVideoIframe } from "@tudigo-monorepo/core-tudigo-utils";

export const VideoBlock = ({
  block,
  editMode,
}: {
  block: CMSVideoBlock;
  editMode?: boolean;
}) => {
  const { url } = block.data;

  if (!url && !editMode) return null;

  return url ? (
    getVideoIframe(
      url,
      undefined,
      undefined,
      "aspect-video h-full w-full rounded-lg",
    )
  ) : (
    <div className="bg-light-3 h-full w-full rounded-lg" />
  );
};
