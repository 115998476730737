import React from "react";
import { toast } from "react-toastify";

import { useSendWMACustomerQualificationLinkMutation } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  Button,
  Chip,
  ListElement,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { useEditWmaCustomerPageContext } from "../edit-wma-customer-page";

export function EditWmaCustomerQualificationContent() {
  const { customer } = useEditWmaCustomerPageContext();
  const isQualificationDone =
    customer.customerUser?.investorProfile?.isQualificationExpired === false &&
    customer.customerUser?.investorProfile?.confirmed;

  const [isLinkCopyLoading, setIsLinkCopyLoading] = React.useState(false);
  const [isSendMailActive, setIsSendMailActive] = React.useState(true);

  const copyLink = () => {
    setIsLinkCopyLoading(true);
    navigator.clipboard
      .writeText(`${window.location.origin}/qualification/intro`)
      .then(() => {
        toast((content) => {
          return (
            <Alert
              icon="Checked"
              variant="success"
              size="S"
              close={content.closeToast}
              title="Le lien de qualification a été copié dans le presse-papier"
            />
          );
        });
        setTimeout(() => {
          setIsLinkCopyLoading(false);
        }, 500);
      });
  };

  const { mutateAsync: sendQualificationMail, isPending } =
    useSendWMACustomerQualificationLinkMutation({
      onSuccess: () => {
        toast((content) => {
          return (
            <Alert
              icon="Checked"
              variant="success"
              size="S"
              close={content.closeToast}
              title="Email envoyé"
            />
          );
        });
        setIsSendMailActive(false);
      },
      onError: () => {
        toast((content) => {
          return (
            <Alert
              icon="Error"
              variant="error"
              size="S"
              close={content.closeToast}
              title="Une erreur est survenue"
            />
          );
        });
        setIsSendMailActive(true);
      },
    });

  const handleSendQualificationMail = async () => {
    await sendQualificationMail({ wmaCustomerId: String(customer.id) });
  };

  if (customer.status !== "accepted") {
    return (
      <Alert
        className="m-6"
        icon="Cadenas"
        variant="warning"
        size="F"
        title="Le client n'a pas encore accepté votre invitation"
      />
    );
  }

  return (
    <div className="m-6 flex flex-col gap-6">
      <Typography variant="body1">
        Votre client doit compléter lui même son profil chaque année selon la
        loi
      </Typography>
      <ListElement
        startElement={{
          upperText: "Profil investisseur",
          lowerText: `${customer.customerUser?.firstName} ${customer.customerUser?.lastName}`,
          img: {
            icon: { name: "Profile" },
          },
          profileCardClassName: "min-w-[280px] flex justify-start px-3 py-2",
        }}
        middleElement={{
          upperText:
            "Pour accéder aux informations financières des opportunités et pour pouvoir investir votre client doit passer le parcours de qualification de son profil investisseur",
        }}
        endElement={{
          render: () => {
            return (
              <div className="flex items-center gap-x-8">
                <Chip
                  label={isQualificationDone ? "Validé" : "À compléter"}
                  type={isQualificationDone ? "success" : "waiting"}
                  iconLeft="DotChip"
                />
              </div>
            );
          },
        }}
      />
      {!isQualificationDone && (
        <div className="flex gap-2">
          <Button
            label="Envoyer le lien par email"
            iconRight="Mail"
            disabled={!isSendMailActive}
            variant="primary"
            className="w-fit"
            onClick={handleSendQualificationMail}
            isLoading={isPending}
          />
          <Button
            label="Copier le lien"
            iconRight="Duplicate"
            variant="tertiary"
            className="w-fit"
            onClick={() => copyLink()}
            isLoading={isLinkCopyLoading}
          />
        </div>
      )}
    </div>
  );
}
