import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Modal } from "@tudigo-monorepo/web-tudigo-components";

import {
  TaxExemptionCSGFormFields,
  TaxExemptionModalName,
} from "../../content/investor-profile-tax-exemptions-content";
import { isTaxExemptionCSGFormValid } from "../utils/tax-exemptions-utils";
import { ModalTaxExemptionCSG } from "./modal-tax-exemption-csg";
import { ModalTaxExemptionCSGForm } from "./modal-tax-exemption-csg-form";
import { ModalTaxExemptionIR } from "./modal-tax-exemption-ir";

interface TaxExemptionsModalsProps {
  currentModalOpen: TaxExemptionModalName | null;
  CSGFormData: TaxExemptionCSGFormFields;
  onCloseModal: () => void;
  setCSGFormData: (data: TaxExemptionCSGFormFields) => void;
}

export function TaxExemptionsModals(props: TaxExemptionsModalsProps) {
  const {
    currentModalOpen = null,
    CSGFormData,
    setCSGFormData,
    onCloseModal,
  } = props;

  const { t } = useTranslation();

  if (!currentModalOpen) {
    return null;
  }

  return (
    <>
      <Modal
        isOpen={currentModalOpen === "IR"}
        setIsOpen={onCloseModal}
        title={t("my-profile-exemptions.tax-document-modal.title")}
        content={<ModalTaxExemptionIR />}
        className="w-[calc(100%-32px)] items-center"
      />

      <Modal
        isOpen={currentModalOpen === "CSG"}
        setIsOpen={onCloseModal}
        title={t("my-profile-exemptions.tax-document-modal.title")}
        content={<ModalTaxExemptionCSG />}
        className="w-[calc(100%-32px)] items-center"
      />

      <Modal
        isOpen={currentModalOpen === "CSG-form"}
        setIsOpen={onCloseModal}
        title={t("my-profile-exemptions.tax-document-modal-CSG-form.title")}
        content={
          <ModalTaxExemptionCSGForm
            formData={CSGFormData}
            setFormData={setCSGFormData}
          />
        }
        className="w-[calc(100%-32px)] items-center"
        confirmAction={onCloseModal}
        confirmLabel="Confirmer"
        confirmButtonProps={{
          disabled: !isTaxExemptionCSGFormValid(CSGFormData),
        }}
      />
    </>
  );
}
