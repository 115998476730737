import React from "react";

import { getProjectMechanicSettings } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  formatCurrency,
  ORGANIZATIONS,
} from "@tudigo-monorepo/core-tudigo-utils";
import { getInvestmentPaymentMethodTypeTranslateKey } from "@tudigo-monorepo/web-feature-investments";
import {
  CurrencyInput,
  TextField,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { useEditWmaInvestmentPageContext } from "../edit-wma-investment-page";

export function EditWmaInvestmentContent() {
  const { investment } = useEditWmaInvestmentPageContext();
  const { t } = useTranslation();

  const projectMechanicSettings = getProjectMechanicSettings(
    investment.project,
  );

  return (
    <div className="border-light-1 m-6 rounded-lg  border p-6">
      <div className="flex w-full flex-col gap-4 md:w-[450px]">
        <TextField
          value={investment.project?.content?.name}
          label="Sélectionner une collecte"
          required
          disabled
        />
        <TextField
          value={`${investment.user?.firstName} ${investment.user?.lastName}`}
          label="Sélectionner un client"
          required
          disabled
        />
        <TextField
          value={`${investment.investorOrganization.name} - ${
            investment.investorOrganization.type ===
            ORGANIZATIONS.TYPE.INDIVIDUAL
              ? "Personne physique"
              : "Personne morale"
          }`}
          label="Sélectionner le compte"
          required
          disabled
        />
        <CurrencyInput
          value={investment.totalAmountInCents}
          label="Montant total"
          required
          disabled
        />
        <TextField
          value={`${t(getInvestmentPaymentMethodTypeTranslateKey(investment))}`}
          label="Méthode de paiement"
          required
          disabled
        />
        <div className="border-light-1 flex flex-col gap-4 border-t pt-4">
          <Typography variant="caption1-regular">
            {`${t(
              "investments.back_office.create_investment_page.form.investment_detail.unit_price",
            )} ${formatCurrency(projectMechanicSettings.unitPriceInCents)}`}
          </Typography>
          <Typography variant="caption1-regular">
            {`Ticket minimum : ${formatCurrency(
              projectMechanicSettings.minimumTicketAmountInCents,
            )}`}
          </Typography>
          <div className="bg-success-light flex justify-between rounded-lg p-4">
            <Typography variant="caption1-regular">Nombre de titres</Typography>
            <Typography variant="caption1-regular">
              {investment.quantity}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
