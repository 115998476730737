import { SharesProject } from "@tudigo-monorepo/core-tudigo-api-models";
import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { Chip, Icon } from "@tudigo-monorepo/web-tudigo-components";

import { useProjectContext } from "../../../project.context";
import {
  SideFinStatsLabel,
  SideFinStatsValue,
} from "./sidebar-project-financial-stats";

export function SidebarProjectFinancialStatsShare() {
  const project = useProjectContext() as SharesProject;
  const { isQualifiedOrWma } = useUserBusiness();
  const { t } = useTranslation();

  if (!project) return null;

  const minTargetedYield =
    project?.sharesMechanicSettings?.targetedYield?.minInPercent ?? 0;
  const maxTargetedYield =
    project?.sharesMechanicSettings?.targetedYield?.maxInPercent ?? 0;

  return (
    <>
      {project?.status !== "prefinished" &&
        project?.status !== "finished" &&
        minTargetedYield > 0 &&
        maxTargetedYield > 0 && (
          <article>
            <SideFinStatsLabel tooltipDesc="Potentiel de rendement proportionnel aux risques">
              Rendement cible
            </SideFinStatsLabel>
            {isQualifiedOrWma ? (
              <SideFinStatsValue>
                {`
        x${minTargetedYield / 100} -
        x${maxTargetedYield / 100}
        `}
              </SideFinStatsValue>
            ) : (
              <SideFinStatsValue>
                <Icon
                  name="Cadenas"
                  size="XS"
                  primaryColor={themeColors["dark-2"]}
                />
              </SideFinStatsValue>
            )}
          </article>
        )}

      <article>
        <SideFinStatsLabel tooltipDesc="Les avantages fiscaux sont offerts en contrepartie d’un risque de perte et d’une durée de détention">
          Défiscalisation
        </SideFinStatsLabel>
        <div className="flex flex-wrap gap-2">
          {!!project.taxExemptionMechanics?.length &&
            project.taxExemptionMechanics.map((taxExemption) => (
              <Chip
                key={taxExemption}
                type="secondary"
                label={t(
                  `component.project_card.list.item.tax_exemption.${taxExemption}`,
                )}
              />
            ))}
        </div>
      </article>
    </>
  );
}
