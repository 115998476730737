import { useState } from "react";

import { ClubMember } from "@tudigo-monorepo/core-tudigo-api-models";
import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUnsubscribeClub } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Icon,
  IconName,
  Modal,
  Popper,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

type Action = {
  icon: IconName;
  label: string;
  color?: string;
  onClick: () => void;
};

export const ClubMembersTableActions = ({ row }: { row: ClubMember }) => {
  const { t } = useTranslation();
  const [isOpenUnsubscribeModal, setIsOpenUnsubcribeModal] = useState(false);

  const { mutate: unsubscribeClub } = useUnsubscribeClub({
    onSuccess: () => setIsOpenUnsubcribeModal(false),
  });

  const actions: Action[] = [
    {
      icon: "Download",
      color: themeColors["dark-2"],
      label: "my_account.wep_app.clubs.table.actions.download",
      onClick: () => window.open(row?.contract?.document.signedUrl, "_blank"),
    },
    {
      icon: "Delete",
      color: themeColors["error"],
      label: "my_account.wep_app.clubs.table.actions.cancelled",
      onClick: () => {
        setIsOpenUnsubcribeModal(true);
      },
    },
  ];

  return (
    <>
      <Popper clickable>
        <Popper.Trigger>
          <Icon name="Dots" />
        </Popper.Trigger>
        <Popper.Content className="max-w-48">
          <div className="flex flex-col gap-4">
            {actions.map((action, index) => (
              <button
                key={`${action.icon}-${index}`}
                className="flex flex-row items-center gap-4"
                onClick={action.onClick}
              >
                <Icon primaryColor={action.color} name={action.icon} />
                <Typography className="text-dark-1 text-left" variant="body2">
                  {t(action.label)}
                </Typography>
              </button>
            ))}
          </div>
        </Popper.Content>
      </Popper>
      <Modal
        isOpen={isOpenUnsubscribeModal}
        setIsOpen={setIsOpenUnsubcribeModal}
        title={t("my_account.wep_app.clubs.table.actions.modal.title")}
        confirmAction={() => unsubscribeClub({ clubMemberId: row.id })}
        confirmLabel={t(
          "my_account.wep_app.clubs.table.actions.modal.confirm_label",
        )}
        content={t("my_account.wep_app.clubs.table.actions.modal.content")}
      />
    </>
  );
};
