import { EquitySavingPlan } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  PageEnum,
  useSpaceBusiness,
  useUserBusiness,
} from "@tudigo-monorepo/tudigo-session";
import {
  CreateInvestmentAccountModal,
  CreateInvestmentAccountModalTrigger,
  InvestmentAccountListElement,
  InvestmentAccountProvider,
  organizationRequestFields,
} from "@tudigo-monorepo/web-feature-organizations";
import { useGetOrganizationByIdQuery } from "@tudigo-monorepo/web-tudigo-api-client";
import { Title } from "@tudigo-monorepo/web-tudigo-components";

import { MyProfileOrganizationList } from "../../../shared/components/my-profile-organization-list";

export function InvestorProfileOrganizationsContent() {
  const { user } = useUserBusiness();
  const { currentPagePaths } = useSpaceBusiness();

  const { t } = useTranslation();

  const { data: individualOrganization } = useGetOrganizationByIdQuery(
    user?.individualOrganization?.id as string,
    {
      fields: organizationRequestFields,
    },
  );

  return (
    <div className="m-6 flex flex-col gap-y-16">
      <section className="flex flex-col gap-y-6">
        <Title h3Brand="brand-2" level="h3" className="text-primary">
          {t(
            "my_account.web_app.my_profile_page.organizations.content.my_investments_accounts",
          )}
        </Title>

        <MyProfileOrganizationList userId={user?.id} />

        {individualOrganization?.equitySavingPlan && (
          <InvestmentAccountListElement
            asLink
            equitySavingPlan={
              individualOrganization.equitySavingPlan as EquitySavingPlan
            }
            organization={individualOrganization}
            navigateTo={currentPagePaths[PageEnum.ORGANIZATION_KYC_PEA_VIEWER]
              .replace(":organizationId", individualOrganization.id)
              .replace(":type", "pea")}
          />
        )}

        {individualOrganization?.smallBusinessEquitySavingPlan && (
          <InvestmentAccountListElement
            asLink
            equitySavingPlan={
              individualOrganization.smallBusinessEquitySavingPlan as EquitySavingPlan
            }
            organization={individualOrganization}
            navigateTo={currentPagePaths[PageEnum.ORGANIZATION_KYC_PEA_VIEWER]
              .replace(":organizationId", individualOrganization.id)
              .replace(":type", "pea-pme")}
          />
        )}
      </section>

      <section className="flex flex-col gap-y-6">
        <Title h3Brand="brand-2" level="h3" className="text-primary">
          {t(
            "my_account.web_app.my_profile_page.organizations.content.add_account",
          )}
        </Title>
        <InvestmentAccountProvider>
          <CreateInvestmentAccountModalTrigger />
          <CreateInvestmentAccountModal />
        </InvestmentAccountProvider>
      </section>
    </div>
  );
}
