import React from "react";
import { useNavigate } from "react-router-dom";

import {
  Modal,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

type ConfirmationModalProps = {
  showDeclineDialog: boolean;
  setShowDeclineDialog: (show: boolean) => void;
};
export const DeclineConfirmationModal = (props: ConfirmationModalProps) => {
  const { showDeclineDialog, setShowDeclineDialog } = props;

  const navigate = useNavigate();

  return (
    <Modal
      title="Confirmation"
      content={
        <div>
          <Title level="h6" h3Brand="brand-2">
            Dernière ligne droite !
          </Title>
          <Typography variant="caption1-regular">
            Pour une raison légale et règlementaire, vous devez compléter votre
            profil investisseur. :<br />
            <strong>
              Si vous faites le choix de refuser ces conditions, nous ne seront
              pas en mesure de vous donner :
            </strong>
            <ul className="mt-3 list-inside list-disc">
              <li>
                accès aux informations complètes concernant les opportunités
                d’investissement
              </li>
              <li>investir sur un projet</li>
            </ul>
          </Typography>
        </div>
      }
      negativeLabel="Je souhaite quitter tout de même"
      negativeAction={() => {
        navigate("/my-account/dashboard");
      }}
      negativeButtonProps={{
        variant: "ghost",
      }}
      confirmAction={() => {
        setShowDeclineDialog(false);
      }}
      confirmLabel="Annuler et continuer"
      isOpen={showDeclineDialog}
      setIsOpen={setShowDeclineDialog}
    />
  );
};
