import numeral from "numeral";
import CountUp from "react-countup";

import {
  isSharesProject,
  type BondProjectSimulatorQuery,
  type ShareProjectSimulatorQuery,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { getCurrencySymbol } from "@tudigo-monorepo/core-tudigo-utils";
import { useGetSimulatorByProject } from "@tudigo-monorepo/web-tudigo-api-client";
import { Badge } from "@tudigo-monorepo/web-tudigo-components";

import { useProjectContext } from "../../../project.context";

type SimulatorQueryFilters =
  | ShareProjectSimulatorQuery
  | BondProjectSimulatorQuery;

export const SimulatorRoiBlock = (props: {
  filters: SimulatorQueryFilters;
}) => {
  const project = useProjectContext();
  const isReady = Boolean(
    project && (!isSharesProject(project) || "scenario" in props.filters),
  );

  const { data: simulatorData } = useGetSimulatorByProject(
    {
      projectId: project?.id,
      query: props.filters,
    },
    {
      enabled: isReady,
    },
  );

  return (
    <div className="border-success-medium bg-success-light rounded-lg border p-4">
      <div className="flex flex-col items-center justify-center font-sans">
        <h5 className="text-primary text-sm font-semibold">
          Retour sur investissement
        </h5>
        <span className="text-success text-lg font-bold">
          <CountUp
            start={props.filters.amountInCents / 100}
            end={simulatorData ? Number(simulatorData?.roiInCents) / 100 : 0}
            duration={0.5}
            suffix={` ${getCurrencySymbol()}`}
            separator=" "
          />
        </span>
        <Badge className="border-light-2 text-black-900 mt-2 rounded p-2 text-[13px] font-semibold">
          {`X${simulatorData?.roiYield ?? "-"} (soit ${
            simulatorData?.annualRoiYield
              ? numeral(simulatorData?.annualRoiYield).format("0%")
              : "-"
          } par an)`}
        </Badge>
        <p className="text-primary mt-2 text-[10px] font-normal">
          {`Simulation hors frais Tudigo et brut d'impôts`}
        </p>
      </div>
    </div>
  );
};
