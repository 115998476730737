import type { ClubV2Configuration } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Chip } from "@tudigo-monorepo/web-tudigo-components";

import { ClubMembershipButton } from "../../../club-page/components/club-membership/club-membership-button";
import { useGetClubV2UsingIdPathParams } from "../../../shared-internal/hooks/use-get-club-v2-using-id-path-params";
import { useNavigateToSubscribeClubStep } from "../../hooks/use-navigate-to-subscribe-club-step";
import { SubscribeClubStepPage } from "../../models/subscribe-club-step";
import { ClubSubscriptionPrice } from "../club-subscription-price/club-subscription-price";

const ClubPremiumSubscribePrice = (props: {
  configuration: ClubV2Configuration;
}) => {
  const { t } = useTranslation();

  const isDiscountedPrice = Boolean(
    props.configuration.introductorySubscriptionPrice,
  );

  return (
    <div className="flex flex-col items-start justify-between sm:flex-row sm:items-center">
      <p
        className={cn("text-primary font-sans text-[13px]", {
          "text-dark-4 text-[10px]": isDiscountedPrice,
        })}
      >
        {t(
          "my.account.web.app.clubs.membership_info.subscription.amount_subscription",
        )}
      </p>
      <ClubSubscriptionPrice
        price={props.configuration?.subscriptionPrice?.unitAmountInCents}
        asOldPrice={isDiscountedPrice}
      />
    </div>
  );
};

const ClubPremiumSubscribeDiscountPrice = (props: {
  configuration: ClubV2Configuration;
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-start justify-between sm:flex-row sm:items-center">
      <p className="text-primary font-sans text-[13px]">
        {t("my.account.web.app.clubs.membership_info.subscription", {
          subscriptionsCount: props.configuration.maxIntroductorySubscriptions,
        })}
      </p>
      <div className="flex w-full items-center justify-between gap-x-1.5 sm:w-fit">
        <ClubSubscriptionPrice
          price={
            props.configuration?.introductorySubscriptionPrice
              ?.unitAmountInCents
          }
          className="text-[13px] font-semibold"
        />
        <Chip
          label={t(
            "my.account.web.app.clubs.membership_info.subscription.limited_offer",
          )}
          type="secondary"
        />
      </div>
    </div>
  );
};

const ClubPremiumSubscribeCard = () => {
  const club = useGetClubV2UsingIdPathParams();
  const navigateToStep = useNavigateToSubscribeClubStep();
  const { t } = useTranslation();

  if (!club) {
    return null;
  }

  const configuration = club.configuration;

  return (
    <div className="flex flex-col items-center gap-y-8">
      <div className="flex w-full flex-col gap-y-2">
        <h4 className="text-primary font-sans font-bold">
          {t("my_account.wep_app.clubs.membership")}
        </h4>

        {Boolean(configuration.introductorySubscriptionPrice) && (
          <>
            <ClubPremiumSubscribeDiscountPrice configuration={configuration} />
            <hr className="border-light-1 my-2 h-px w-full" />
          </>
        )}

        <ClubPremiumSubscribePrice configuration={configuration} />
      </div>

      <ClubMembershipButton
        onClick={() => navigateToStep(SubscribeClubStepPage.STEP_2_SIGN)}
      >
        {t(
          "my.account.web.app.clubs.membership_info.subscription.continue_subscription",
        )}
      </ClubMembershipButton>
    </div>
  );
};

export { ClubPremiumSubscribeCard };
