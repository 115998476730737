import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import {
  Chip,
  Icon,
  ListElement,
} from "@tudigo-monorepo/web-tudigo-components";

export function UserQualificationListElement() {
  return (
    <ListElement
      navigateTo="/qualification"
      startElement={{
        img: {
          icon: { name: "Profile" },
        },
        upperText: "Profil Investisseur",
        lowerText: "Mon profil d’investissement",
        profileCardClassName: "min-w-[280px] flex justify-start px-3 py-2",
      }}
      middleElement={{
        upperText:
          "Pour accéder aux informations financières des opportunités et pour pouvoir investir vous devez compléter votre profil investisseur",
      }}
      endElement={{
        render: () => {
          return (
            <div className="flex items-center gap-x-8">
              <Chip
                label="A compléter"
                size="S"
                type="warning"
                iconLeft="DotChip"
              />
              <Icon name="ChevronRight" primaryColor={themeColors["dark-2"]} />
            </div>
          );
        },
      }}
    />
  );
}
