import { ClubV2 } from "@tudigo-monorepo/core-tudigo-api-models";
import { ClubProfilePicture } from "@tudigo-monorepo/web-tudigo-components";

type ClubHeadProps = {
  club: ClubV2;
};
export const ClubHead = ({ club }: ClubHeadProps) => {
  return (
    <div className="z-50 flex flex-col items-center gap-4 lg:flex-row">
      <ClubProfilePicture club={club} />
      <div className="lg: flex flex-col items-center justify-end text-center lg:h-[94px] lg:items-start lg:text-left">
        <p className="text-dark-2 font-montserrat text-xs font-semibold leading-tight">
          Cercle
        </p>
        <h1 className="font-obviously text-dark-1 mb-1 text-[27px] font-[570] leading-none">
          {club?.content?.name}
        </h1>
        <p className="text-dark-2 font-montserrat font-medium">
          {club?.content?.baseline}
        </p>
      </div>
    </div>
  );
};
