import { SearchBox, useInstantSearch } from "react-instantsearch";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import {
  DotsLoader,
  Icon,
  useToggler,
} from "@tudigo-monorepo/web-tudigo-components";

export const CustomSearchBox = () => {
  const { results, status } = useInstantSearch();
  const [focused, toggle] = useToggler();
  const searching = results.query.length > 0;

  return (
    <SearchBox
      placeholder="Rechercher"
      onFocus={toggle}
      onBlur={toggle}
      submitIconComponent={() => <Icon name="Search" />}
      resetIconComponent={() => <Icon name="Cross" />}
      loadingIconComponent={() => <DotsLoader size="XS" />}
      classNames={{
        root: "w-full",
        form: cn(
          "h-[38px] w-full sm:w-[288px]",
          "flex items-center",
          "px-4",
          "text-[13px]",
          "text-dark-2 bg-white",
          "border-dark-4 focus:border-accent-medium rounded-lg border",
          {
            "border-accent-medium": focused,
          },
        ),
        input:
          "w-full search-cancel:appearance-none outline-none bg-transparent text-[13px] text-dark-2 font-montserrat ",
        submit: cn({ hidden: searching || status === "loading" }),
      }}
    />
  );
};
