import { ClubV2 } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { ClubProfilePicture } from "../club-profile-picture/club-profile-picture";

export const ProjectClubIconRebrand = ({
  club,
  className,
}: {
  club: ClubV2;
  className?: string;
}) => {
  return (
    <div className={cn("absolute bottom-3 right-5", className)}>
      <ClubProfilePicture club={club} size="XS" className="rounded-tl-lg" />
    </div>
  );
};
