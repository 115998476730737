import React from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import {
  Avatar,
  Icon,
  ListElementComposable,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { NewsItemMenu } from "./components/news-item-menu";
import { useNewsItem } from "./context/news-item-provider";

export function NewsInformations() {
  const news = useNewsItem();

  const newsCreationDate = news.createdAt
    ? new Date(news.createdAt)
    : new Date();

  const [expanded, setExpanded] = React.useState(false);

  const toggleExpanded = (e: React.MouseEvent) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  return (
    <>
      <ListElementComposable onClick={() => setExpanded(!expanded)}>
        <div className="flex min-w-40 items-center gap-3 md:basis-1/5">
          <Avatar icon={{ name: "News" }} />
          <Typography variant="body3-semibold" className="text-dark-1">
            {newsCreationDate.toLocaleDateString()}
          </Typography>
        </div>

        <Typography
          className="m-auto flex md:basis-1/5"
          variant="body3-regular"
        >
          {news.title}
        </Typography>

        <div className="ml-auto flex gap-x-2 ">
          <Typography className="m-auto" variant="body3-regular">
            Information
          </Typography>
          {news.description ? (
            <button onClick={toggleExpanded}>
              <Icon name={expanded ? "Minus" : "Plus"} />
            </button>
          ) : (
            <div className="w-6" />
          )}
          <div onClick={(e) => e.stopPropagation()}>
            <NewsItemMenu />
          </div>
        </div>
      </ListElementComposable>
      {news.description && (
        <div
          className={cn(
            "border-light-1 rounded-lg border-b border-l border-r p-6",
            {
              hidden: !expanded,
            },
          )}
        >
          <Typography variant="body2">{news.description}</Typography>
        </div>
      )}
    </>
  );
}
