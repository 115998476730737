import React from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { BreadcrumbLevel, Breadcrumbs } from "../breadcrumbs";
import { Chip, ChipSize, ChipType } from "../chip";
import { IconName } from "../icons/icon-name.type";
import { Title } from "../title";

export type PageHeadChipProps = {
  label: string | number;
  type?: ChipType;
  iconLeft?: IconName;
  size?: ChipSize;
  count?: boolean;
};

export type PageHeadProps = {
  title: string;
  className?: string;
  chipsBottom?: PageHeadChipProps[];
  chipsTop?: PageHeadChipProps[];
  levels?: BreadcrumbLevel[];
  subtitle?: string;
  withEndLine?: boolean;
};

export function PageHead(props: React.PropsWithChildren<PageHeadProps>) {
  const {
    children,
    title,
    subtitle,
    levels,
    chipsTop,
    chipsBottom,
    className,
    withEndLine = false,
  } = props;

  return (
    <header
      className={cn("mx-6 flex flex-col gap-4 py-4", className, {
        "border-light-1 border-b": withEndLine,
      })}
    >
      {levels && <Breadcrumbs levels={levels} />}
      {!!chipsTop && (
        <div className="flex flex-wrap gap-2">
          {chipsTop.map((chip, index) => (
            <Chip key={index} {...chip} />
          ))}
        </div>
      )}
      <div className="flex flex-col gap-y-1.5">
        <Title>{title}</Title>
        {subtitle && <Title level="subtitle">{subtitle}</Title>}
      </div>
      {!!chipsBottom && (
        <div className="flex flex-wrap gap-2">
          {chipsBottom.map((chip, index) => (
            <Chip key={index} {...chip} />
          ))}
        </div>
      )}
      {children}
    </header>
  );
}
