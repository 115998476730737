import { createColumnHelper } from "@tanstack/react-table";
import { useIntl } from "react-intl";

import { CreditorRepayment } from "@tudigo-monorepo/core-tudigo-api-models";
import { Chip, Typography } from "@tudigo-monorepo/web-tudigo-components";

export function useRepaymentsGrossOnlyTableColumns() {
  const intl = useIntl();
  const columnHelper = createColumnHelper<CreditorRepayment>();

  return [
    columnHelper.accessor("periodIndex", {
      header: intl.formatMessage({
        id: "my_repayments_page.table.columns.index",
      }),
      cell: (info) => (
        <Typography variant="body3-regular">{info.getValue()}</Typography>
      ),
    }),
    columnHelper.accessor("dueDate", {
      header: intl.formatMessage({
        id: "my_repayments_page.table.columns.date",
      }),
      cell: (info) => (
        <Typography variant="body3-regular">
          {info.getValue() != null
            ? new Date(info.getValue() as string).toLocaleDateString()
            : info.row.original.debtorRepayment?.transferDate
              ? new Date(
                  info.row.original.debtorRepayment?.transferDate as string,
                ).toLocaleDateString()
              : "-"}
        </Typography>
      ),
    }),
    columnHelper.accessor("organization.name", {
      header: intl.formatMessage({
        id: "my_repayments_page.table.columns.account",
      }),
      cell: (info) => <Chip type="secondary" label={info.getValue()} />,
    }),
    columnHelper.accessor("interestAmountInCents", {
      header: intl.formatMessage({
        id: "my_repayments_page.table.columns.gross_interest",
      }),
      cell: (info) => (
        <Typography variant="body3-regular">
          {intl.formatNumber((info.getValue() as number) / 100, {
            style: "currency",
            currency: "EUR",
          })}
        </Typography>
      ),
    }),
    columnHelper.accessor("capitalAmountInCents", {
      header: intl.formatMessage({
        id: "my_repayments_page.table.columns.gross_capital",
      }),
      cell: (info) => (
        <Typography variant="body3-regular">
          {intl.formatNumber((info.getValue() as number) / 100, {
            style: "currency",
            currency: "EUR",
          })}
        </Typography>
      ),
    }),
    columnHelper.accessor("penaltyAmountInCents", {
      header: intl.formatMessage({
        id: "my_repayments_page.table.columns.penalties",
      }),
      cell: (info) => (
        <Typography variant="body3-regular">
          {intl.formatNumber((info.getValue() as number) / 100, {
            style: "currency",
            currency: "EUR",
          })}
        </Typography>
      ),
    }),
    columnHelper.accessor("grossTotalAmountInCents", {
      header: "Total Brut",
      cell: (info) => {
        return (
          <Typography variant="body3-regular">
            {intl.formatNumber(((info.getValue() as number) ?? 0) / 100, {
              style: "currency",
              currency: "EUR",
            })}
          </Typography>
        );
      },
    }),
  ];
}
