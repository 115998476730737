import {
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";

import {
  Investment,
  WMAInvestment,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { ORGANIZATIONS } from "@tudigo-monorepo/core-tudigo-utils";
import { useGetWmaInvestmentById } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  IconName,
  PageHead,
  Tabs,
} from "@tudigo-monorepo/web-tudigo-components";

import { getEditWmaInvestmentPageTabs } from "./utils/get-edit-wma-investment-page-tabs.utils";

const investorOrganizationFields = `id,name,type,logo,persons,pms_account.fields(virtual_iban)`;
const projectFields = `id,content,mechanic,maturity,bonds_mechanic_settings,shares_mechanic_settings,goal,marketing_settings,scheduled_start_date,scheduled_end_date,started_at,finished_at,categories,tags,tax_exemption_mechanics,impact_score,currency,holder_organization.fields(id),vehicle_organization.fields(id,name),total_amount_collected_in_cents,total_investors_count,fundraising_progress_min_goal,fundraising_progress_max_goal,status,project_agreement_and_security_contract,project_documents,is_referenced,commitment_letter_signing_enabled`;
const investmentFields = `id,global_status,payment_status,subscription_form_status,created_at,updated_at,project.fields(${projectFields}),currency,total_amount_in_cents,unit_price_in_cents,quantity,investor_organization.fields(${investorOrganizationFields}),user,is_investor_organization_kyc_valid,payment_method_type,documents,payment_date,subscription_form_document,signed_subscription_form_document,commitment_letter_document,archive_esp_document,signed_commitment_letter_document,project_agreement_and_security_consent`;
const wmaInvestmentFields = `id,investment.fields(${investmentFields})`;

export type EditWmaInvestmentPageContext = {
  investment: Investment;
  wmaInvestment: WMAInvestment | undefined;
};

export function EditWmaInvestmentPage() {
  const navigate = useNavigate();
  const wmaInvestmentId = useParams().wmaInvestmentId as string;

  const { data: wmaInvestment } = useGetWmaInvestmentById({
    wmaInvestmentId,
    query: {
      fields: wmaInvestmentFields,
    },
  });

  if (!wmaInvestment) {
    return null;
  }

  const investment = wmaInvestment.investment;

  if (!investment) {
    return null;
  }

  return (
    <div id="organizations-list-manager-page">
      <PageHead
        title="Édition d'une souscription"
        chipsBottom={[
          {
            label: investment.project?.content?.name ?? "",
            type: "primary",
          },
          {
            label: investment.investorOrganization.name,
            type: "primary",
          },
          {
            label:
              investment.investorOrganization.type ===
              ORGANIZATIONS.TYPE.INDIVIDUAL
                ? "Personne physique"
                : "Personne morale",
            type: "secondary",
          },
        ]}
        withEndLine
        levels={[
          {
            iconName: "Group" as IconName,
            callback: () => {
              navigate("list/all");
            },
          },
        ]}
      />

      <Tabs tabs={getEditWmaInvestmentPageTabs(investment)} className="px-6" />

      <Outlet
        context={
          {
            investment: investment,
            wmaInvestment: wmaInvestment,
          } satisfies EditWmaInvestmentPageContext
        }
      />
    </div>
  );
}

export function useEditWmaInvestmentPageContext() {
  return useOutletContext<EditWmaInvestmentPageContext>();
}
