import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

const buttonRebrandVariants = cva(
  "inline-flex items-center justify-center px-6 py-4 whitespace-nowrap rounded-lg border border-transparent text-base font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        black: "bg-black-primary text-white shadow hover:bg-black-primary/90",
        destructive:
          "bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90",
        outline:
          "border border-input bg-background shadow-sm hover:bg-accent-rebrand hover:text-accent-foreground",
        secondary:
          "bg-black-400 text-black-primary border border-black-500 shadow-sm hover:bg-black-400/90",
        ghost:
          "bg-transparent text-black-900 hover:text-primary-rebrand hover:bg-accent-rebrand/90",
        success: "bg-success text-white shadow-sm hover:bg-success/90",
        link: "text-primary underline-offset-4 hover:underline",
        "accent-light":
          "bg-accent-super-light-rebrand text-primary-rebrand border border-accent-rebrand hover:bg-accent-super-light-rebrand/90",
      },
      size: {
        sm: "h-8 rounded-md px-3 text-xs",
        default: "h-12 px-4 py-4 text-sm",
        lg: "h-10 rounded-md px-6 text-base",
        xl: "h-14 rounded-lg px-6 text-lg",
        icon: "h-9 w-9",
      },
    },
    defaultVariants: {
      variant: "black",
      size: "default",
    },
  },
);

export interface ButtonRebrandProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonRebrandVariants> {
  asChild?: boolean;
}

const ButtonRebrand = React.forwardRef<HTMLButtonElement, ButtonRebrandProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";

    return (
      <Comp
        className={cn(buttonRebrandVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);

export { ButtonRebrand, buttonRebrandVariants };
