import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { Button, Tooltip } from "@tudigo-monorepo/web-tudigo-components";

import { useCanInvest } from "../hooks/use-can-invest";

export interface InvestButtonProps {
  className?: string;
}

export function InvestButton(props: InvestButtonProps) {
  const { className } = props;

  const canInvest = useCanInvest();

  const {
    label,
    handleClick,
    displayButton,
    buttonVariant,
    disabled,
    message,
  } = canInvest;

  if (!displayButton) {
    return null;
  }

  return (
    <Tooltip description={message} placement="top" childrenClassName="flex">
      <Button
        label={label}
        variant={buttonVariant}
        className={cn("w-full", className)}
        disabled={disabled}
        onClick={handleClick}
      />
    </Tooltip>
  );
}
