import React from "react";

import { EquitySavingPlan } from "@tudigo-monorepo/core-tudigo-api-models";
import { Checkbox, Typography } from "@tudigo-monorepo/web-tudigo-components";

type WmaCustomerPeaPmeAccountSelectorProps = {
  smallBusinessEquitySavingPlan: EquitySavingPlan;
  peaPmeAccountSelected: boolean;
  handleChangePeaPmeAccountCheckbox: (value: boolean) => void;
};

export function WmaCustomerPeaPmeAccountSelector(
  props: WmaCustomerPeaPmeAccountSelectorProps,
) {
  const { peaPmeAccountSelected, handleChangePeaPmeAccountCheckbox } = props;

  return (
    <div className="flex items-center gap-1">
      <Checkbox
        id="pea-pme-checkbox"
        className="relative"
        checked={peaPmeAccountSelected}
        onCheckedChange={(checked) =>
          handleChangePeaPmeAccountCheckbox(!!checked)
        }
      />
      <label
        htmlFor="pea-pme-checkbox"
        className="mt-0.5 flex w-full items-center justify-between"
      >
        <Typography variant="caption1-regular">
          Investir via le compte PEA-PME (
          {props.smallBusinessEquitySavingPlan?.bank?.name})
        </Typography>
      </label>
    </div>
  );
}
