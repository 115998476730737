import React from "react";

import { BondsProject } from "@tudigo-monorepo/core-tudigo-api-models";
import {
  Icon,
  PageHead,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

export function BondsHeadProjectDetailContent(props: {
  project: BondsProject;
}) {
  const { project } = props;

  return (
    <>
      <PageHead
        title={project?.content?.name ?? "Investissement"}
        className="pb-4"
      />
      <div className="border-light-2 border-b" />

      <div className="my-6 flex w-full items-stretch gap-4">
        <div className="relative w-1/2 overflow-hidden rounded-lg">
          <img
            src={project.content?.cover?.url ?? ""}
            alt="Cover"
            className="absolute inset-0 h-full w-full object-cover object-center"
          />
        </div>
        <div className="border-light-1 flex w-1/2 flex-col gap-2 rounded-lg border p-4">
          <div className="flex items-center gap-2">
            <Typography variant="body2">Instrument financier</Typography>
            <Icon name="Info" size="XS" />
          </div>
          <Title level="h5">Obligations</Title>
          <div className="flex items-center gap-2">
            <Typography variant="body2">Taux d&apos;intérêt</Typography>
            <Icon name="Info" size="XS" />
          </div>
          <Title level="h5">
            {project?.bondsMechanicSettings?.annualInterestPercent
              ? `${project?.bondsMechanicSettings?.annualInterestPercent}%`
              : "Non défini"}
          </Title>
          <div className="flex items-center gap-2">
            <Typography variant="body2">Durée</Typography>
            <Icon name="Info" size="XS" />
          </div>
          <Title level="h5">
            {project?.bondsMechanicSettings?.durationInMonth
              ? `${project?.bondsMechanicSettings?.durationInMonth / 12} an${
                  project?.bondsMechanicSettings?.durationInMonth / 12 > 1
                    ? "s"
                    : ""
                }`
              : "Non défini"}
          </Title>
          <div className="flex items-center gap-2">
            <Typography variant="body2">
              Typologie d&apos;obligations
            </Typography>
            <Icon name="Info" size="XS" />
          </div>
          <Title level="h5">
            {project?.bondsMechanicSettings?.repaymentType
              ? `${project?.bondsMechanicSettings?.repaymentType}`
              : "Non défini"}
          </Title>
          <div className="flex items-center gap-2">
            <Typography variant="body2">Remboursement</Typography>
            <Icon name="Info" size="XS" />
          </div>
          <Title level="h5">
            {project?.bondsMechanicSettings?.repaymentFrequency
              ? `${project?.bondsMechanicSettings?.repaymentFrequency}`
              : "Non défini"}
          </Title>
        </div>
      </div>
    </>
  );
}
