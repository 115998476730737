import {
  CMSCardsProfileBlock,
  CMSCardsProfileBlockDataCard,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { getImgSrc } from "@tudigo-monorepo/core-tudigo-utils";
import {
  Icon,
  ResponsiveBlocksToCarousel,
  ThinLayout,
} from "@tudigo-monorepo/web-tudigo-components";
import { EditorSettings } from "@tudigo-monorepo/web-tudigo-editor";

type ProjectUserCardsBlockProps = {
  block: CMSCardsProfileBlock;
  editMode?: boolean;
  editorSettings?: EditorSettings;
};

function ProjectUserCard(props: CMSCardsProfileBlockDataCard) {
  const { image, text, name, linkedinProfileUrl, job } = props;

  return (
    <article className=" border-medium-1 flex h-full flex-col items-center gap-3 rounded-lg border p-4">
      {image && getImgSrc(image) && (
        <img
          className="bg-light-1 h-16 w-16 rounded-full"
          src={getImgSrc(image)}
          alt={name}
        />
      )}

      <h2 className="text-dark-1 text-center text-sm uppercase">{name}</h2>
      <h3 className="text-dark-1 text-sm font-semibold uppercase">{job}</h3>
      <p
        className="text-dark-2 text-xs"
        dangerouslySetInnerHTML={{ __html: text }}
      />

      {linkedinProfileUrl && (
        <a href={linkedinProfileUrl} target="_blank" rel="noreferrer noopener">
          <Icon name="SocialLinkedin" />
        </a>
      )}
    </article>
  );
}

export function ProjectUserCardsBlock(props: ProjectUserCardsBlockProps) {
  const { block } = props;
  const { cards } = block.data;

  return (
    <ThinLayout>
      <ResponsiveBlocksToCarousel
        Component={ProjectUserCard}
        blocks={cards}
        itemMax={3}
      />
    </ThinLayout>
  );
}
