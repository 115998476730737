import { Organization } from "@tudigo-monorepo/core-tudigo-api-models";
import { PMS_FILES } from "@tudigo-monorepo/core-tudigo-utils";
import {
  checkIfOrganizationDocumentsRequirementsAreMet,
  checkIfOrganizationPersonIdentityDocumentsRequirementAreMet,
} from "@tudigo-monorepo/web-feature-kyc";

import { isOrganizationKycApproved } from "../utils/organization-kyc-utils";
import { useOrganizationChipProps } from "./use-organization-chip-props";

export function useOrganizationKycDocumentsStatusChipProps(
  organization?: Organization,
) {
  const { toCompleteProps, rejectedProps } = useOrganizationChipProps();

  if (!organization) return;

  const isValidSection =
    (organization.type === "company" &&
      checkIfOrganizationDocumentsRequirementsAreMet(organization)) ||
    (organization.type === "individual" &&
      checkIfOrganizationPersonIdentityDocumentsRequirementAreMet(
        organization.organizationRepresentative,
        true,
      ));

  const areOrganizationDocumentsOnError = organization.documents?.some(
    (doc) => doc.pmsFile?.status === PMS_FILES.STATUS.REJECTED,
  );

  const areOrganizationRepresentativeDocumentsOnError =
    organization.organizationRepresentative?.documents.some(
      (doc) => doc.pmsFile?.status === PMS_FILES.STATUS.REJECTED,
    );

  if (
    isOrganizationKycApproved(organization) ||
    (isValidSection &&
      !areOrganizationDocumentsOnError &&
      !areOrganizationRepresentativeDocumentsOnError)
  )
    return;

  return areOrganizationDocumentsOnError ||
    areOrganizationRepresentativeDocumentsOnError
    ? rejectedProps
    : toCompleteProps;
}
