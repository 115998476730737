import { SortDirectionItem } from "@tudigo-monorepo/core-tudigo-api-models";

export const sortDirections: SortDirectionItem[] = [
  {
    value: "ASC",
    label: "projects.web_app.questions_answers.sort_older.label",
  },
  {
    value: "DESC",
    label: "projects.web_app.questions_answers.sort_newer.label",
  },
];
