import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { Club } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { CollectionRequestParams, getApiClient } from "../../..";
import { getQueryListKey } from "../../query-key-factory";

type GetClubsQuery = {
  fields?: string;
  limit?: number;
  offset?: number;
};

export const getClubs = async (query: GetClubsQuery) => {
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.clubs.getClubs(query);

  return response.data;
};

export const useGetClubs = (
  params?: CollectionRequestParams<undefined, GetClubsQuery>,
  options?: UseQueryOptions<TudigoResponse<Club[]>, TudigoError>,
) => {
  const {
    pagination,
    fields = "id,name,short_description,club_leader,description,created_at,configuration,social_links,advertised_project_tags,is_introductory_price_available,is_opened_to_subscriptions,image,mobile_image,dark_logo,light_logo",
    filters,
  } = params ?? {};

  return useQuery({
    queryKey: getQueryListKey({
      key: QUERY_KEYS.CLUBS,
      filters,
    }),
    queryFn: () =>
      getClubs({
        ...filters,
        fields,
        offset: pagination?.offset,
        limit: pagination?.limit,
      }),
    ...options,
  });
};
