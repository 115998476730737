import React from "react";
import { useIntl } from "react-intl";

import { MetricsTableCard } from "@tudigo-monorepo/web-tudigo-components";

import { useMyInvestmentAssemblyPageContext } from "../../investment-assembly-page/my-investment-assembly-page";

export function AssemblyResultSummaryCard() {
  const intl = useIntl();

  const { assembly } = useMyInvestmentAssemblyPageContext();

  return (
    <MetricsTableCard
      title={intl.formatMessage({
        id: "RÉSULTATS",
      })}
      rows={assembly.topics.map((topic) => ({
        label: topic.title ?? "",
        value: intl.formatMessage({
          id: `assembly_result_summary.label.${topic.finalStatusAfterVoting}`,
        }),
        valueCustomClassName:
          topic.finalStatusAfterVoting === "approved"
            ? "text-green-600"
            : "text-orange-500",
      }))}
    />
  );
}
