import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { InvestmentsFilters } from "@tudigo-monorepo/web-tudigo-api-client";
import { FilterConfiguration } from "@tudigo-monorepo/web-tudigo-collection";

export const useClubLeaderProjectInvestmentsFilters = () => {
  const { t } = useTranslation();

  const filterConfiguration: FilterConfiguration<InvestmentsFilters>[] = [
    {
      label: t(
        "club_leader.page_club_leader_project_detail.investments_table.filters.created_at.label",
      ),
      name: "createdAt",
      type: "dateRange",
      placeholder: t(
        "club_leader.page_club_leader_project_detail.investments_table.filters.default_value",
      ),
    },
    {
      label: t(
        "club_leader.page_club_leader_project_detail.investments_table.filters.payment_method.label",
      ),
      name: "paymentMethodType",
      type: "select",
      placeholder: t(
        "club_leader.page_club_leader_project_detail.investments_table.filters.default_value",
      ),
      options: [
        {
          value: "wire",
          name: t("investment.payment_methode_type.label.wire"),
        },
        {
          value: "card",
          name: t("investment.payment_methode_type.label.card"),
        },
        {
          value: "pea",
          name: t("investment.payment_methode_type.label.pea"),
        },
      ],
    },
    {
      label: t(
        "club_leader.page_club_leader_project_detail.investments_table.filters.status.label",
      ),
      name: "globalStatus",
      type: "select",
      placeholder: t(
        "club_leader.page_club_leader_project_detail.investments_table.filters.default_value",
      ),
      options: [
        {
          value: "validated",
          name: t("investment.global_status.validated"),
        },
        {
          value: "waiting_for_payment",
          name: t("investment.global_status.waiting_for_payment"),
        },
        {
          value: "waiting_for_signature",
          name: t("investment.global_status.waiting_for_signature"),
        },
        {
          value: "cancelled",
          name: t("investment.global_status.cancelled"),
        },
      ],
    },
  ];

  return filterConfiguration;
};
