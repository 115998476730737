export const PMS_FILES = {
  TYPE: {
    IDENTITY_CARD: "identity_card",
    IDENTITY_CARD_FRONT_SIDE: "identity_card_front_side",
    IDENTITY_CARD_BACK_SIDE: "identity_card_back_side",
    PROOF_OF_ADDRESS: "proof_of_address",
    PROOF_OF_BANK_ACCOUNT: "proof_of_bank_account",
    EUROPEAN_PASSPORT: "european_passport",
    EUROPEAN_PASSPORT_FRONT_SIDE: "european_passport_front_side",
    OUTSIDE_EUROPEAN_PASSPORT: "outside_european_passport",
    OUTSIDE_EUROPEAN_PASSPORT_FRONT_SIDE:
      "outside_european_passport_front_side",
    RESIDENCE_PERMIT: "residence_permit",
    DRIVING_LICENSE: "driving_license",
    STATUS: "status",
    COMPANY_REGISTRATION: "company_registration",
    UBO_REGISTER: "ubo_register",
    SELFIE: "selfie",
    OTHER: "other",
    PROFESSIONAL_CIVIL_LIABILITY: "professional_civil_liability",
    ORIAS_CERTIFICATE: "orias_certificate",
  },
  STATUS: {
    WAITING_APPROVAL: "waiting_approval",
    APPROVED: "approved",
    REJECTED: "rejected",
  },
};
