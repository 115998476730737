import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Alert,
  Modal,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

type KycRedirectModalProps = {
  modalKycOpen: boolean;
  setModalKycOpen: (open: boolean) => void;
  selectedOrganizationId: string | null;
  projectId: string;
  ongoingInvestmentId: string | undefined;
};

export function KycRedirectModal(props: KycRedirectModalProps) {
  const {
    modalKycOpen,
    selectedOrganizationId,
    ongoingInvestmentId,
    projectId,
    setModalKycOpen,
  } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const redirectToKycFunnelForOrganization = () => {
    if (!projectId || !ongoingInvestmentId || !selectedOrganizationId) return;

    navigate(
      `/organization/${selectedOrganizationId}/kyc/personal-information?${queryString.stringify(
        {
          investProjectUrl: location.pathname,
        },
      )}`,
    );
  };

  return (
    <Modal
      title="Compte non vérifié"
      confirmLabel="Oui, je souhaite compléter le compte"
      negativeLabel="Sélectionner un autre compte"
      isOpen={modalKycOpen}
      setIsOpen={() => setModalKycOpen(false)}
      confirmAction={() => redirectToKycFunnelForOrganization()}
      negativeButtonProps={{
        variant: "ghost",
      }}
      negativeAction={() => setModalKycOpen(false)}
      className="min-h-[605px] w-[calc(100%-32px)] sm:max-w-[654px]"
    >
      <div className="flex flex-col gap-y-4 p-4">
        <Alert variant="warning" title="Compte non validé" icon="Info" />
        <Typography variant="body3-regular">
          Le compte selectionné n&apos;a pas encore été vérifié. Souhaitez-vous
          compléter les informations manquantes ?
        </Typography>
      </div>
    </Modal>
  );
}
