import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { ORGANIZATIONS } from "@tudigo-monorepo/core-tudigo-utils";
import { PageEnum, useSpaceBusiness } from "@tudigo-monorepo/tudigo-session";
import { Alert, Chip, PageHead } from "@tudigo-monorepo/web-tudigo-components";

import { useKYCContext } from "../kyc-page";

export function KycWaitingApproval() {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { organization, refetchOrganization } = useKYCContext();
  const { currentPagePaths } = useSpaceBusiness();
  const kycPagePath = currentPagePaths[
    PageEnum.ORGANIZATION_KYC_FUNNEL
  ].replace(":organizationId", organization.id);

  useEffect(() => {
    if (
      organization?.internalKycStatus ===
      ORGANIZATIONS.INTERNAL_KYC_STATUS.APPROVED
    ) {
      navigate(`${kycPagePath}/done`, {
        replace: true,
      });

      return;
    }
    if (
      organization?.internalKycStatus !==
      ORGANIZATIONS.INTERNAL_KYC_STATUS.SUBMITTED_AND_WAITING_APPROVAL
    ) {
      navigate(`${kycPagePath}/summary`, {
        replace: true,
      });
    }
  }, [kycPagePath, navigate, organization.id, organization?.internalKycStatus]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetchOrganization();
    }, 5000);

    const timeout = setTimeout(() => {
      clearInterval(interval);
      navigate(`${kycPagePath}/waiting-manual-approval`, {
        replace: true,
      });
    }, 45000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [navigate, organization.id, refetchOrganization]);

  return (
    <>
      <div className="mx-6">
        {organization.type === ORGANIZATIONS.TYPE.INDIVIDUAL && (
          <Chip
            label={t(
              "kyc_funnel.step_bank_account_information.page.chip.label.individual",
            )}
            type="secondary"
          />
        )}
        {organization.type === ORGANIZATIONS.TYPE.COMPANY && (
          <div className="flex gap-2">
            <Chip
              label={t(
                "kyc_funnel.step_bank_account_information.page.chip.label.company",
              )}
              type="secondary"
            />
            <Chip label={organization.name} type="info" />
          </div>
        )}
      </div>
      <PageHead
        title={t("kyc_funnel.waiting_approval_page.title")}
        withEndLine
      />
      <div className="mx-6">
        <Alert
          size="F"
          title={t("kyc_funnel.waiting_approval_page.alert.title")}
          description={t("kyc_funnel.waiting_approval_page.alert.description")}
          variant="waiting"
          icon="Loading"
        />
      </div>
    </>
  );
}
