import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { ClubMember } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { CollectionRequestParams, getApiClient } from "../../..";
import { getQueryListKey } from "../../query-key-factory";

type UserClubMembersQuery = {
  fields?: string;
  limit?: number;
  offset?: number;
  status?: string | string[];
  sort_direction?: "ASC" | "DESC";
};

const defaultFields =
  "id,role,contract,user,status,joined_at,selected_price,created_at,updated_at,subscription,club,subscription";

export const getUserClubMembers = async ({
  userId,
  query,
}: {
  userId: string;
  query: UserClubMembersQuery;
}) => {
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.clubs.getUserClubMembers(
    userId,
    query,
  );

  return response.data;
};

export const useGetUserClubMembers = (
  params: CollectionRequestParams<{ userId: string }, UserClubMembersQuery>,
  options?: Partial<UseQueryOptions<TudigoResponse<ClubMember[]>, TudigoError>>,
) => {
  const { pagination, fields = defaultFields, filters, pathParams } = params;

  return useQuery({
    queryKey: getQueryListKey({
      key: QUERY_KEYS.MY_CLUB_MEMBERS,
    }),
    queryFn: () =>
      getUserClubMembers({
        userId: pathParams.userId,
        query: {
          ...filters,
          fields,
          offset: pagination?.offset,
          limit: pagination?.limit,
        },
      }),
    ...options,
  });
};
