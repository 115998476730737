import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Typography } from "@tudigo-monorepo/web-tudigo-components";

export function ModalTaxExemptionCSG() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <Typography variant="body2-semibold">
        {t("my-profile-exemptions.tax-document-modal-CSG.content-1")}
      </Typography>
      <Typography variant="body2" dangerouslySetInnerHTML>
        {t("my-profile-exemptions.tax-document-modal-CSG.content-2")}
      </Typography>
      <div className="px-5 py-4"></div>
    </div>
  );
}
