import { ReactNode } from "react";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Button } from "@tudigo-monorepo/web-tudigo-components";

import { useInvestmentAccountContext } from "../investment-account-context";

export const CreateInvestmentAccountModalTrigger = (props: {
  children?: ReactNode;
}) => {
  const { t } = useTranslation();
  const { onModalOpenChange } = useInvestmentAccountContext();

  return (
    <Button
      onClick={() => onModalOpenChange(true)}
      variant="tertiary"
      iconRight="Plus"
      className="w-full whitespace-nowrap sm:w-fit sm:min-w-[213px]"
      label={
        props.children ??
        t(
          "my_account.web_app.my_profile_page.organizations.content.add_account",
        )
      }
    />
  );
};
