import { useState } from "react";
import { toast } from "react-toastify";

import { TudigoError } from "@tudigo-monorepo/core-tudigo-api";
import {
  CancellationDetail,
  CancellationDetailType,
  Investment,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  FormContextProvider,
  getSmartFormComponents,
} from "@tudigo-monorepo/web-feature-form";
import { useCreateCancelInvestmentRequestMutation } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  Modal,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { managerInvestmentQueryFields } from "../../pages/investment-manager-page/investment-manager-page";
import { userInvestmentsQueryFields } from "../../pages/investment-page/investment-page";
import { useCancelInvestmentReasons } from "../hooks/use-cancel-investment-reasons";

type CancelInvestmentModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
  investment: Investment;
};

export function CancelInvestmentModal(props: CancelInvestmentModalProps) {
  const { investment, isModalOpen, setIsModalOpen } = props;

  const { isAdmin, user } = useUserBusiness();
  const { userCancelReasons, internalCancelReasons, dunningCancelReasons } =
    useCancelInvestmentReasons();

  const { SmartTextAreaField, SmartToggleField, SmartSelectField } =
    getSmartFormComponents<CancellationDetail>();
  const { t } = useTranslation();

  const [error, setError] = useState<TudigoError | null>(null);
  const [cancelInvestmentRequestFormData, setCancelInvestmentRequestFormData] =
    useState<CancellationDetail>({
      reason: "",
      type: isAdmin
        ? CancellationDetailType.INTERNAL
        : CancellationDetailType.USER_REQUEST,
      comment: "",
    });
  const [reasonItems, setReasonItems] = useState(
    isAdmin ? internalCancelReasons : userCancelReasons,
  );

  const handleFormChange = (formData: CancellationDetail) => {
    setCancelInvestmentRequestFormData(formData);

    if (formData.type === cancelInvestmentRequestFormData.type) {
      return;
    }

    switch (formData.type) {
      case CancellationDetailType.USER_REQUEST:
        return setReasonItems(userCancelReasons);
      case CancellationDetailType.INTERNAL:
        return setReasonItems(internalCancelReasons);
      case CancellationDetailType.DUNNING:
        return setReasonItems(dunningCancelReasons);
      default:
        break;
    }
  };

  const submitEnabled = cancelInvestmentRequestFormData.reason !== "";

  const {
    mutate: createCancelInvestmentRequest,
    isPending: isCreateCancelInvestmentRequestLoading,
  } = useCreateCancelInvestmentRequestMutation({
    onSuccess: () => {
      setIsModalOpen(false);
      toast((content) => (
        <Alert
          close={content.closeToast}
          icon="Checked"
          variant="success"
          title={t(
            "investments.back_office.investment_detail.cancel_investment_modal.alert_on_success.title",
          )}
        />
      ));
    },
    onError: (err) => {
      setError(err);
      toast((content) => (
        <Alert
          close={content.closeToast}
          icon="Error"
          variant="error"
          title={t(
            "investments.back_office.investment_detail.cancel_investment_modal.alert_on_error.title",
          )}
        />
      ));
    },
  });

  const handleSubmit = () => {
    const queryFields = isAdmin
      ? managerInvestmentQueryFields
      : userInvestmentsQueryFields;

    const queryFilters = {
      userId: user?.id ?? "me",
      projectId: investment.project.id,
    };

    createCancelInvestmentRequest({
      query: {
        fields: queryFields,
        filters: queryFilters,
      },
      data: {
        investment: {
          id: investment.id,
        },
        cancellationDetail: {
          ...cancelInvestmentRequestFormData,
        },
      },
    });
  };

  return (
    <Modal
      title={t(
        "investments.back_office.investment_detail.cancel_investment_modal.title",
      )}
      confirmLabel={t(
        "investments.back_office.investment_detail.cancel_investment_modal.confirm_label",
      )}
      isOpen={isModalOpen}
      setIsOpen={() => setIsModalOpen(false)}
      confirmAction={handleSubmit}
      confirmButtonProps={{
        disabled: !submitEnabled,
        iconRight: "Delete",
        isLoading: isCreateCancelInvestmentRequestLoading,
        variant: "destructive",
      }}
      className="min-h-[605px] w-[calc(100%-32px)] sm:max-w-[654px]"
    >
      <div className="flex flex-col gap-y-4 p-4">
        <div>
          <Typography variant="body3-regular">
            {t(
              "investments.back_office.investment_detail.cancel_investment_modal.content",
            )}
          </Typography>
        </div>
        <div className="border-light-1 rounded-lg border p-4">
          <FormContextProvider
            error={error}
            formData={cancelInvestmentRequestFormData}
            setFormData={handleFormChange}
          >
            <div className="flex flex-col gap-4">
              {isAdmin && (
                <SmartToggleField
                  name="type"
                  blueStyle
                  leftText={t(
                    "cancel_investment_request.cancellation_detail.type.internal.label",
                  )}
                  rightText={t(
                    "cancel_investment_request.cancellation_detail.type.dunning.label",
                  )}
                  label={t(
                    "investments.back_office.investment_detail.cancel_investment_modal.form.type.label",
                  )}
                  required
                  mapCheckedValue={(value: boolean) =>
                    value
                      ? CancellationDetailType.DUNNING
                      : CancellationDetailType.INTERNAL
                  }
                  mapValueChecked={(value: string) =>
                    value === CancellationDetailType.DUNNING
                  }
                />
              )}
              <SmartSelectField
                label={t(
                  "investments.back_office.investment_detail.cancel_investment_modal.form.reason.label",
                )}
                name="reason"
                required
                items={reasonItems}
                getValueFromItem={(item) => item.value}
                labelFunction={(item) => item.label}
                comparisonFunction={(item, selection) =>
                  item.value === selection
                }
              />
              <SmartTextAreaField
                name="comment"
                label={t(
                  "investments.back_office.investment_detail.cancel_investment_modal.form.comment.label",
                )}
              />
            </div>
          </FormContextProvider>
        </div>
      </div>
    </Modal>
  );
}
