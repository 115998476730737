import { Organization } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Title, Typography } from "@tudigo-monorepo/web-tudigo-components";

import { useKYCContext } from "../../../kyc-page";
import { UboKycDocumentsCollapsListItem } from "../components/ubo-kyc-documents-collapse-list-item";

type UboKycDocumentsFormGroupProps = {
  organizationFormData: Organization;
  setOrganizationFormData: (organization: Organization) => void;
};

export function UboKycDocumentsFormGroup(props: UboKycDocumentsFormGroupProps) {
  const { organizationFormData, setOrganizationFormData } = props;

  const { organization } = useKYCContext();
  const { t } = useTranslation();

  return (
    <div className="ubo-documents">
      <Title h3Brand="brand-2" level="h3" className="text-primary">
        {t("kyc_funnel.step_documents.ubo_section.page.title")}
      </Title>
      <Typography variant="body1" className="text-primary">
        {t("kyc_funnel.step_documents.ubo_section.page.subtitle")}
      </Typography>

      <div className="my-6 flex w-full flex-col gap-4">
        {organization?.ultimateBeneficialOwners?.map((beneficialOwner) => (
          <UboKycDocumentsCollapsListItem
            key={beneficialOwner.id}
            beneficialOwner={beneficialOwner}
            organizationFormData={organizationFormData}
            setOrganizationFormData={setOrganizationFormData}
          />
        ))}
      </div>
    </div>
  );
}
