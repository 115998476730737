import { useNavigate } from "react-router-dom";

import { useGetCircles } from "@tudigo-monorepo/web-tudigo-api-client";
import { TudigoCollection } from "@tudigo-monorepo/web-tudigo-collection";
import { CircleCard } from "@tudigo-monorepo/web-tudigo-components";

export const CircleListingAllContent = () => {
  const navigate = useNavigate();
  const listingCirclesFields =
    "id,content,industries,maturities,mentorings,territories,statistics,leaders";

  return (
    <div>
      <TudigoCollection
        useQueryFn={useGetCircles}
        columns={[]}
        fields={listingCirclesFields}
        actions={[]}
        pagination={{
          pageSize: 6,
          pageIndex: 0,
        }}
        render={(table) => (
          <div className="grid grid-cols-1 gap-6 lg:grid-cols-2 xl:grid-cols-3 ">
            {table.getRowModel().rows?.map(({ original: circle }) => {
              return (
                <CircleCard
                  circle={circle}
                  key={circle.id}
                  onClick={() =>
                    navigate(`/clubs-v2/${circle.id}/presentation`)
                  }
                />
              );
            })}
          </div>
        )}
      />
    </div>
  );
};
