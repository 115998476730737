import React, { useEffect, useRef, useState } from "react";

import {
  CMSBlock,
  Consent,
  Contract,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { decimalToPercent } from "@tudigo-monorepo/core-tudigo-utils";

import { Alert } from "../alert";
import { Button } from "../button";
import { ContractHeadingBlock } from "./contract-blocks/contract-heading-block";
import { ContractListBlock } from "./contract-blocks/contract-list-block";
import { ContractParagraphBlock } from "./contract-blocks/contract-paragraph-block";

type ContractConsentFormProps = {
  contract: Contract;
  userConsent?: Consent;
  onConsent: () => void;
  scrollBoxHeight?: number;
  btnIsLoading?: boolean;
};

export function ContractConsentForm({
  contract,
  userConsent,
  onConsent,
  scrollBoxHeight,
  btnIsLoading,
}: ContractConsentFormProps) {
  const [isAlertDisplayed, setIsAlertDisplayed] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  useEffect(() => {
    const checkScroll = () => {
      const element = contentRef.current;
      if (element) {
        setIsButtonEnabled(element.scrollHeight <= element.clientHeight);
        setIsAlertDisplayed(element.scrollHeight > element.clientHeight);
      }
    };

    checkScroll();
  }, []);
  const onScroll = () => {
    const element = contentRef.current;
    if (element) {
      const scrolledPercentage = decimalToPercent(
        (element.scrollTop + element.clientHeight) / element.scrollHeight,
      );
      setIsButtonEnabled(scrolledPercentage >= 98);
    }
  };

  const renderBlock = (block: CMSBlock, index: number) => {
    switch (block.type) {
      case "list":
        return <ContractListBlock key={index} {...block} />;
      case "heading":
        return <ContractHeadingBlock key={index} {...block} />;
      case "paragraph":
        return <ContractParagraphBlock key={index} {...block} />;
    }
  };

  return (
    <div>
      {userConsent && (
        <Alert
          className="mb-4"
          icon="Next"
          variant="success"
          size="F"
          description={t(
            "investment_funnel.security_and_consent.alert.description.completed",
          )}
        />
      )}

      {!userConsent && isAlertDisplayed && (
        <Alert
          className="mb-4"
          icon="InformationIcon"
          variant="info"
          size="F"
          description={t(
            "investment_funnel.security_and_consent.alert.description",
          )}
        />
      )}

      <div
        ref={contentRef}
        onScroll={onScroll}
        className={`h-[${
          scrollBoxHeight?.toString() ?? "400"
        }px] bg-light-1 overflow-y-scroll rounded-xl p-6`}
      >
        {contract.content.map((block, index) => renderBlock(block, index))}
      </div>

      <div className="consent-btn my-6 flex justify-center">
        {!userConsent && (
          <Button
            isLoading={btnIsLoading}
            label={contract.checkBoxLabel}
            onClick={onConsent}
            disabled={!isButtonEnabled || btnIsLoading}
            iconRight="ArrowRight"
          />
        )}
      </div>
    </div>
  );
}
