import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient, getQueryDetailKey } from "../../../index";

export type DeleteNewsParams = {
  newsId: string;
  fields?: string;
};

export const deleteProjectNews = async (newsId: string) => {
  const apiClient = getApiClient();
  const response = await apiClient.apiV1.projects.deleteProjectNews(newsId);

  return response.data;
};

export const useDeleteProjectNewsMutation = (
  options?: UseMutationOptions<
    TudigoResponse<void>,
    TudigoError,
    DeleteNewsParams
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (params: DeleteNewsParams) => deleteProjectNews(params.newsId),
    onSuccess: (...props) => {
      const [, params] = props;

      const projectNewsQueryKey = getQueryDetailKey({
        key: QUERY_KEYS.PROJECT_NEWS,
        id: params.newsId,
        fields: params.fields,
      });

      queryClient.invalidateQueries({
        queryKey: projectNewsQueryKey,
      });

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_NEWS],
      });

      options?.onSuccess?.(...props);
    },
  });
};
