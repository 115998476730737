import { Organization } from "@tudigo-monorepo/core-tudigo-api-models";
import { checkIfCompanyInformationRequirementsAreMet } from "@tudigo-monorepo/web-feature-kyc";

import { isOrganizationKycApproved } from "../utils/organization-kyc-utils";
import { useOrganizationChipProps } from "./use-organization-chip-props";

export function useOrganizationKycCompanyStatusChipProps(
  organization?: Organization,
) {
  const { toCompleteProps } = useOrganizationChipProps();

  if (!organization || !organization.companyInformation) {
    return;
  }

  const isValidSection = checkIfCompanyInformationRequirementsAreMet(
    organization.companyInformation,
  );

  if (isOrganizationKycApproved(organization) || isValidSection) return;

  return toCompleteProps;
}
