import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { NavigationMenu } from "../../../ui/navigation-menu";
import { SocialMediaLinks } from "../../../ui/social-media-links/social-media-links";
import { NavbarCardAbout } from "../navbar-cards/navbar-card-about";
import { NavbarCardFunds } from "../navbar-cards/navbar-card-funds";
import { NavbarCardInvest } from "../navbar-cards/navbar-card-invest";
import { NavbarCardResources } from "../navbar-cards/navbar-card-resources";
import { NavbarMenuAbout } from "../navbar-menus/navbar-menu-about";
import { NavbarMenuFunds } from "../navbar-menus/navbar-menu-funds";
import { NavbarMenuInvest } from "../navbar-menus/navbar-menu-invest";
import { NavbarMenuResources } from "../navbar-menus/navbar-menu-resources";
import { NavbarMenuTestimonials } from "../navbar-menus/navbar-menu-testimonials";
import { NavbarUserBlock } from "../navbar-user-block";
import { VerticalMenuCollapse } from "./vertical-menu-collapse";

export const VerticalMenu = (props: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}) => {
  const { isOpen, setIsOpen } = props;

  const handleLinkClick = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  return (
    <div
      className={cn(
        "fixed left-0 top-[67px] z-[1000] flex h-[calc(100vh-67px)] w-screen flex-col gap-y-8 overflow-y-auto bg-white p-4 lg:hidden",
        { hidden: !isOpen },
      )}
    >
      <NavigationMenu
        orientation="vertical"
        delayDuration={200}
        className="w-full max-w-none flex-col items-start justify-start"
      >
        <VerticalMenuCollapse menuValue="invest">
          <NavbarMenuInvest
            className="bg-black-800 mb-3 rounded p-6"
            onLinkClick={handleLinkClick}
          />
          <NavbarCardInvest />
        </VerticalMenuCollapse>
        <VerticalMenuCollapse menuValue="funds">
          <NavbarMenuFunds
            className="bg-black-800 mb-3 rounded p-6"
            onLinkClick={handleLinkClick}
          />
          <NavbarCardFunds />
        </VerticalMenuCollapse>
        <VerticalMenuCollapse menuValue="testimonials">
          <NavbarMenuTestimonials
            className="bg-black-800 mb-3 rounded p-6"
            onLinkClick={handleLinkClick}
          />
        </VerticalMenuCollapse>
        <VerticalMenuCollapse menuValue="about">
          <NavbarMenuAbout
            className="bg-black-800 mb-3 rounded p-6"
            onLinkClick={handleLinkClick}
          />
          <NavbarCardAbout />
        </VerticalMenuCollapse>
        <VerticalMenuCollapse menuValue="resources">
          <NavbarMenuResources
            className="bg-black-800 mb-3 rounded p-6"
            onLinkClick={handleLinkClick}
          />
          <NavbarCardResources />
        </VerticalMenuCollapse>
      </NavigationMenu>

      <NavbarUserBlock />

      <div className="mb-12 flex flex-col items-center gap-y-4">
        <p className="font-roobert rounded-md bg-white font-medium">
          Pour nous suivre
        </p>
        <SocialMediaLinks className="flex justify-center gap-3" />
      </div>
    </div>
  );
};
