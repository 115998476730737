import {
  CMSBlockQuoteCard,
  CMSCardsQuotesBlock,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import {
  Icon,
  ResponsiveBlocksToCarousel,
} from "@tudigo-monorepo/web-tudigo-components";

export const QuotesBlock = (props: { block: CMSCardsQuotesBlock }) => {
  const { block } = props;

  return (
    <ResponsiveBlocksToCarousel
      blocks={block?.data?.cards}
      itemMax={1}
      Component={(item: CMSBlockQuoteCard) => (
        <article className="border-medium-1 flex h-full flex-col items-center justify-evenly gap-2 rounded-lg border p-4 text-sm">
          <div className="flex items-stretch gap-5">
            <div className="flex w-5 items-start justify-center">
              <Icon
                name="QuoteLeft"
                primaryColor={themeColors["accent-light"]}
              />
            </div>
            <p
              className="text-dark-2 text-center font-semibold"
              dangerouslySetInnerHTML={{
                __html: item.quote,
              }}
            />
            <div className="flex w-5 items-end justify-center">
              <Icon
                name="QuoteRight"
                primaryColor={themeColors["accent-light"]}
              />
            </div>
          </div>
          <p className="text-dark-1 font-normal">{item.name}</p>
          <p className="text-dark-1 font-semibold">{item.job}</p>
        </article>
      )}
    />
  );
};
