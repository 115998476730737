import React from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { useIntl } from "react-intl";

import { AssemblyParticipant } from "@tudigo-monorepo/core-tudigo-api-models";
import { ASSEMBLY_PARTICIPANTS } from "@tudigo-monorepo/core-tudigo-utils";
import { Chip, Typography } from "@tudigo-monorepo/web-tudigo-components";

export function useAssemblyParticipantsTableColumns() {
  const intl = useIntl();
  const columnHelper = createColumnHelper<AssemblyParticipant>();

  return [
    columnHelper.accessor("assembly.name", {
      header: intl.formatMessage({ id: "assemblies.table.columns.name" }),
      cell: (info) => (
        <Typography variant="body3-regular">
          {info.getValue() || "-"}
        </Typography>
      ),
    }),
    columnHelper.accessor("participantOrganization.name", {
      header: intl.formatMessage({
        id: "assemblies.table.columns.organization.name",
      }),
      cell: (info) => <Chip label={info.getValue()} />,
    }),
    columnHelper.accessor("assembly.status", {
      header: intl.formatMessage({ id: "assemblies.table.columns.state" }),
      cell: (info) => {
        switch (info.getValue()) {
          case "published":
            return (
              <Chip
                label={intl.formatMessage({
                  id: `assembly.status.published`,
                })}
                type="info"
                iconLeft="DotChip"
              />
            );
          case "closed":
            return (
              <Chip
                label={intl.formatMessage({
                  id: `assembly.status.closed`,
                })}
                type="success"
                iconLeft="DotChip"
              />
            );
        }
      },
    }),
    columnHelper.accessor("assembly.configuration.scheduledEndDate", {
      header: intl.formatMessage({
        id: "assemblies.table.columns.scheduled_end_date",
      }),
      cell: (info) => {
        const date = new Date(info.getValue() as string);
        if (isNaN(date.getTime())) {
          return <Typography variant="body3-regular">-</Typography>;
        }

        return (
          <Typography variant="body3-regular">
            {`${date.toLocaleDateString(intl.locale)} ${date.toLocaleTimeString(
              intl.locale,
            )}`}
          </Typography>
        );
      },
    }),
    columnHelper.accessor("assembly.configuration.channel", {
      header: intl.formatMessage({
        id: "assemblies.table.columns.configuration.channel",
      }),
      cell: (info) => {
        return (
          <Chip
            label={intl.formatMessage({
              id: `assembly.configuration.channel.${info.getValue()}`,
            })}
            type="primary"
          />
        );
      },
    }),
    columnHelper.accessor("status", {
      header: intl.formatMessage({
        id: "assemblies.table.columns.my_participation.status",
      }),
      cell: (info) => {
        switch (info.getValue()) {
          case ASSEMBLY_PARTICIPANTS.STATUS.STATUS_NOTIFIED:
            return <Chip label="Non répondu" type="error" iconLeft="DotChip" />;
          case ASSEMBLY_PARTICIPANTS.STATUS.WAITING_VOTE_SIGNATURE:
            return <Chip label="À signer" type="waiting" iconLeft="DotChip" />;
          case ASSEMBLY_PARTICIPANTS.STATUS.STATUS_REPLIED:
            return <Chip label="A voté" type="success" iconLeft="DotChip" />;
        }
      },
    }),
  ];
}
