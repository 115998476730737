import { CMSCarouselBlock } from "@tudigo-monorepo/core-tudigo-api-models";
import {
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  CarouselRoot,
} from "@tudigo-monorepo/web-tudigo-components";

type CarouselBlockProps = {
  block: CMSCarouselBlock;
  editMode?: boolean;
};

export function CarouselBlock(props: CarouselBlockProps) {
  const { block } = props;
  const { images } = block.data;

  return (
    <CarouselRoot className="w-full">
      {images && images?.length > 1 && (
        <div className="mb-4 flex justify-end gap-1">
          <CarouselPrevious />
          <CarouselNext />
        </div>
      )}
      <CarouselContent className="mb-4">
        {images?.map((img, index) => (
          <CarouselItem key={index} className="!md:basis-1/2">
            <img
              src={img.url}
              alt={img.caption || ""}
              className="max-h-[300px] w-full rounded-lg"
            />
            <p className="text-dark-2 pt-1 text-sm">{img.caption}</p>
          </CarouselItem>
        ))}
      </CarouselContent>
    </CarouselRoot>
  );
}
