import { Navigate, Route, Routes } from "react-router-dom";

import { ClubLeaderDashboardRouteWebapp } from "../../pages/club-leader-dashboard-page/routes/club-leader-dashboard-route.webapp";
import { ClubLeaderMemberDetailRouteWebapp } from "../../pages/club-leader-member-detail-page/routes/club-leader-member-detail-route.webapp";
import { ClubLeaderMembersRouteWebapp } from "../../pages/club-leader-members-page/routes/club-leader-members-route.webapp";
import { ClubLeaderProjectDetailRouteWebapp } from "../../pages/club-leader-project-detail-page/routes/club-leader-project-detail-route.webapp";
import { ClubLeaderProjectsRouteWebapp } from "../../pages/club-leader-projects-page/routes/club-leader-projects-route.webapp";
import { ClubLeaderQuestionsRouteWebapp } from "../../pages/club-leader-questions-page/routes/club-leader-questions-route.webapp";

export function ClubsV2ClubLeaderRouteWebapp() {
  return (
    <Routes>
      <Route path="/dashboard" element={<ClubLeaderDashboardRouteWebapp />} />
      <Route path="/members/*" element={<ClubLeaderMembersRouteWebapp />} />
      <Route
        path="/member/:id"
        element={<ClubLeaderMemberDetailRouteWebapp />}
      />
      <Route path="/projects" element={<ClubLeaderProjectsRouteWebapp />} />
      <Route
        path="/projects/:id/*"
        element={<ClubLeaderProjectDetailRouteWebapp />}
      />
      <Route path="/questions" element={<ClubLeaderQuestionsRouteWebapp />} />
      <Route path="*" element={<Navigate to="dashboard" />} />
    </Routes>
  );
}
