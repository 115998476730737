import React from "react";

import { Project } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  Breadcrumbs,
  Chip,
  Tabs,
} from "@tudigo-monorepo/web-tudigo-components";

type ClubLeaderPageProjectHeadProps = {
  project: Project;
};

export const ClubLeaderPageProjectHead = (
  props: ClubLeaderPageProjectHeadProps,
) => {
  const { project } = props;
  const { t } = useTranslation();

  const getProjectStatusVariant = () => {
    switch (project.status) {
      case "draft":
        return "secondary";
      case "finished":
        return "success";
      case "scheduled":
        return "warning";
      default:
        return "info";
    }
  };

  return (
    <>
      <div className="border-light-1 mx-6 flex flex-col gap-6 border-b py-4">
        <Breadcrumbs
          levels={[
            {
              url: "/club-leader/projects",
              iconName: "Flash",
            },
          ]}
        />
        <Chip
          label={t(`projects.stage.label.${project.status}`)}
          type={getProjectStatusVariant()}
          iconLeft="DotChip"
        />
        <h1 className="text-primary font-obviously text-[27px] font-[570]">
          {project.content?.name}
        </h1>
      </div>

      <Tabs
        tabs={[
          {
            name: t(
              "club_leader.page_club_leader_project_detail.tabs.investors",
            ),
            redirectTo: "investors",
          },
        ]}
        className="px-6"
      />
    </>
  );
};
