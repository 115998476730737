import { TaxExemptionSituationEnum } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  FormContextProvider,
  getSmartFormComponents,
} from "@tudigo-monorepo/web-feature-form";
import { Alert, Form } from "@tudigo-monorepo/web-tudigo-components";

import { TaxExemptionCSGFormFields } from "../../content/investor-profile-tax-exemptions-content";

interface ModalTaxExemptionCSGFormProps {
  formData: TaxExemptionCSGFormFields;
  setFormData: (data: TaxExemptionCSGFormFields) => void;
}

const alertContent = [
  "my-profile-exemptions.tax-document-modal-CSG-form.alert.open-date",
  "my-profile-exemptions.tax-document-modal-CSG-form.alert.state-of-affiliation",
  "my-profile-exemptions.tax-document-modal-CSG-form.alert.affiliation-organization",
  "my-profile-exemptions.tax-document-modal-CSG-form.alert.your-situation",
  "my-profile-exemptions.tax-document-modal-CSG-form.alert.current-city",
];

export function ModalTaxExemptionCSGForm(props: ModalTaxExemptionCSGFormProps) {
  const { formData, setFormData } = props;

  const {
    SmartCountryField,
    SmartDateField,
    SmartTextField,
    SmartSelectField,
  } = getSmartFormComponents<TaxExemptionCSGFormFields>();

  const { t } = useTranslation();

  return (
    <div className="space-y-8 px-3">
      <Alert
        size="F"
        icon="Info"
        description={
          <ul className="ml-4 list-disc">
            {alertContent.map((content, index) => (
              <li key={index}>{t(content)}</li>
            ))}
          </ul>
        }
      />

      <FormContextProvider
        formData={formData}
        setFormData={setFormData}
        error={null}
      >
        <div className="bg-light-3 w-full rounded-lg border p-4">
          <Form className="flex w-full flex-col gap-4">
            <SmartDateField
              name="since"
              label="Je relève, depuis le "
              required
              active
              canBeReset
            />
            <SmartCountryField name="state" label="Dans l’Etat de" required />
            <SmartTextField
              name="socialProtectionFound"
              label="De la caisse de protection sociale"
              required
            />
            <SmartSelectField
              name="situation"
              label="En qualité de"
              items={[
                {
                  value: TaxExemptionSituationEnum.NON_EMPLOYED_WORKER,
                  label: "Travailleur non salarié",
                },
                {
                  value: TaxExemptionSituationEnum.EMPLOYED_WORKER,
                  label: "Travailleur salarié",
                },
                {
                  value: TaxExemptionSituationEnum.PENSIONER_OR_ANNUITANT,
                  label: "Pensionné ou rentier",
                },
                {
                  value: TaxExemptionSituationEnum.OTHER,
                  label: "Autre",
                },
              ]}
              getValueFromItem={(item) => item.value}
              labelFunction={(item) => item.label}
              comparisonFunction={(item, selection) => item.value === selection}
              required
            />
          </Form>
        </div>
      </FormContextProvider>
    </div>
  );
}
