import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import {
  PatchOrganizationRequestBody,
  TudigoError,
  TudigoResponse,
} from "@tudigo-monorepo/core-tudigo-api";
import { Organization } from "@tudigo-monorepo/core-tudigo-api-models";

import { getApiClient } from "../../..";

export const patchOrganizationBankAccount = async (
  params: PatchOrganizationRequestBody,
) => {
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.organizations.patchOrganization(
    params.id,
    params,
  );

  return response.data;
};

export const usePatchOrganization = (
  options?: UseMutationOptions<
    TudigoResponse<Organization>,
    TudigoError,
    PatchOrganizationRequestBody
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (params: PatchOrganizationRequestBody) =>
      patchOrganizationBankAccount(params),
    onSuccess: (...props) => {
      const [response] = props;
      queryClient.invalidateQueries({
        queryKey: [response.data.id],
      });
      options?.onSuccess?.(...props);
    },
  });
};
