import React from "react";
import { DropzoneOptions } from "react-dropzone";

import { RebrandBadge } from "../../../rebrand-badge";
import { bytesToMB } from "../file-helpers";

export function FileUploadInfos(options: DropzoneOptions) {
  if (!options?.accept) {
    return null;
  }

  const maxFiles = options.maxFiles || 1;

  const extensionsAuthorized = Object.values(options?.accept).reduce(
    (acc, curr) => {
      return acc.concat(curr);
    },
    [],
  );

  return (
    <div className="flex justify-center gap-2">
      {extensionsAuthorized.length > 0 &&
        extensionsAuthorized.map((ext) =>
          ext === "*" ? (
            <RebrandBadge key={ext} variant="info" className="rounded-full">
              Tous les fichiers
            </RebrandBadge>
          ) : (
            <RebrandBadge
              key={ext}
              variant="info"
              className="rounded-full uppercase"
            >
              {ext.replace(".", "")}
            </RebrandBadge>
          ),
        )}

      {maxFiles > 1 && (
        <RebrandBadge className="rounded-full">
          {maxFiles} fichiers
        </RebrandBadge>
      )}

      {options?.maxSize && (
        <RebrandBadge className="rounded-full">
          {bytesToMB(options.maxSize)} Mo max
        </RebrandBadge>
      )}
    </div>
  );
}
