import {
  SelectInput,
  SelectOption,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { Stack } from "@tudigo-monorepo/web-tudigo-components";

import { getSelectInputColumnsCount } from "../../utils/form-layout";
import { DynamicFormOption } from "../dynamic-form-option";

type SelectInputProps = {
  input: SelectInput;
  onChange: (value: string | number) => void;
  selectedValue: string | number | null;
};

export const SelectInputComponent = (props: SelectInputProps) => {
  const { input, selectedValue, onChange } = props;

  const handleSelect = (option: SelectOption) => {
    onChange(option.value);
  };

  const isSelected = (option: SelectOption) => {
    return option.value === selectedValue;
  };

  const columnsCount: number = getSelectInputColumnsCount(input);

  return (
    <div className="select-input flex flex-col gap-y-6">
      <Stack
        fullWidth
        className={cn("grid grid-cols-1", {
          "sm:grid-cols-2 sm:gap-x-6": columnsCount === 2,
        })}
      >
        {input.parameters?.choices?.map((option, index) => (
          <DynamicFormOption
            key={index}
            option={option}
            selected={isSelected(option)}
            onSelect={handleSelect}
          />
        ))}
      </Stack>
    </div>
  );
};
