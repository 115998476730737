import { cva } from "class-variance-authority";

import { ClubV2 } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";

type ClubProfilePictureProps = {
  club: ClubV2;
  size?: "XS" | "S" | "M";
  className?: string;
  text?: string;
};

const sizeVariants = cva([], {
  variants: {
    size: {
      XS: "h-[32px] w-[32px]",
      S: "h-[54px] w-[54px]",
      M: "h-[94px] w-[94px]",
    },
  },
  defaultVariants: {
    size: "M",
  },
});

export const ClubProfilePicture = ({
  club,
  size = "M",
  text,
  className,
}: ClubProfilePictureProps) => {
  const logoUrl = club.content?.logo?.url;
  const borderColor = club.iconBorderColor;

  return (
    <div
      className={cn(
        `flex flex-col items-center justify-center`,
        text ? `bg-[${borderColor}]` : "",
        className,
        {
          "flex w-fit flex-row justify-start gap-2 rounded-lg pr-4": !!text,
        },
      )}
    >
      <img
        src={logoUrl ?? undefined}
        alt="club logo"
        className={cn(
          sizeVariants({ size }),
          `border-2 border-[${borderColor}] rounded-lg `,
          className,
        )}
      />
      {text && <p className="text-sm text-gray-800">{text}</p>}
    </div>
  );
};
