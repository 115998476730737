import { useFormContext } from "react-hook-form";

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../form";
import { InputConnectedProps } from "../input.type";
import { Input } from "./input";

export function InputConnected({
  name,
  label,
  placeholder,
  className,
  description,
  required,
}: InputConnectedProps) {
  const form = useFormContext();

  if (!form) {
    throw new Error("InputConnected must be used within a Form component");
  }

  return (
    <FormField
      control={form?.control}
      name={name}
      render={({ field, fieldState }) => (
        <FormItem className={className}>
          {label && <FormLabel required={required}>{label}</FormLabel>}
          <FormControl>
            <Input placeholder={placeholder} {...field} />
          </FormControl>

          {fieldState?.error ? (
            <FormMessage />
          ) : (
            description && <FormDescription>{description}</FormDescription>
          )}
        </FormItem>
      )}
    />
  );
}
