import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Chip } from "@tudigo-monorepo/web-tudigo-components";

import { useGetClubV2UsingIdPathParams } from "../../../shared-internal/hooks/use-get-club-v2-using-id-path-params";

type ClubAvatarType = {
  url: string;
};
const ClubAvatar = (props: ClubAvatarType) => {
  const { url } = props;

  return (
    <img
      className="inline-block size-8 rounded-full object-cover ring-2 ring-white"
      src={url}
      alt="Club Leader Avatar"
    />
  );
};

type ClubAvatarLeadersProps = {
  countAvatarDisplayed?: number;
};

export const ClubLeadersStats = () => {
  const club = useGetClubV2UsingIdPathParams();

  const { t } = useTranslation();

  if (!club) {
    return;
  }

  return (
    <p className="text-primary text-sm">
      {t("page_club.club_membership_component.club_leaders.paragraph", {
        followersCount: club.statistics.followersCount,
      })}
    </p>
  );
};

export const ClubLeadersAvatar = ({
  countAvatarDisplayed = 3,
}: ClubAvatarLeadersProps) => {
  const club = useGetClubV2UsingIdPathParams();

  if (!club || !club?.leaders) {
    return;
  }
  const leaders = club.leaders;

  return (
    <div className="flex items-center -space-x-2">
      {leaders
        ?.slice(0, countAvatarDisplayed)
        .map((leader) => (
          <ClubAvatar key={leader.id} url={leader.profileImage?.url ?? ""} />
        ))}
      {leaders?.length > countAvatarDisplayed && (
        <Chip
          label={`+${leaders.length - countAvatarDisplayed}`}
          type="info"
          className="h-6"
        />
      )}
    </div>
  );
};
