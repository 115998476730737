import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import {
  getResourceFromTudigoResponse,
  TudigoError,
} from "@tudigo-monorepo/core-tudigo-api";
import { Investment } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient, getQueryDetailKey } from "../../../";

type GetInvestmentByIdParams = {
  investmentId: string | undefined;
  query?: { fields?: string };
};

export const getInvestmentById = async (params: GetInvestmentByIdParams) => {
  const apiClient = getApiClient();
  const response = await apiClient.apiV1.investments.getInvestmentById(
    params!.investmentId!,
    params?.query,
  );

  return getResourceFromTudigoResponse(response.data);
};

export const useGetInvestmentById = (
  params: GetInvestmentByIdParams,
  options?: Omit<UseQueryOptions<Investment, TudigoError>, "queryKey">,
) =>
  useQuery({
    queryKey: getQueryDetailKey({
      key: QUERY_KEYS.INVESTMENTS,
      id: params.investmentId!,
      fields: params?.query?.fields,
    }),
    queryFn: () => getInvestmentById(params),
    ...options,
  });
