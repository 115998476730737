import React from "react";
import { useNavigate } from "react-router-dom";

import { PaymentMethodType } from "@tudigo-monorepo/core-tudigo-api-models";
import { INVESTMENTS } from "@tudigo-monorepo/core-tudigo-utils";
import { Alert } from "@tudigo-monorepo/web-tudigo-components";

import { useEditWmaInvestmentPageContext } from "../../edit-wma-investment-page";
import { EditWmaInvestmentPaymentTabPeaPaymentReceivedSection } from "./partials/pea-pea-pme/edit-wma-investment-payment-tab-pea-payment-received-section";
import { EditWmaInvestmentPaymentTabPeaWaitingPaymentSection } from "./partials/pea-pea-pme/edit-wma-investment-payment-tab-pea-waiting-payment-section";
import { EditWmaInvestmentPaymentTabPeaWaitingProjectClosureSection } from "./partials/pea-pea-pme/edit-wma-investment-payment-tab-pea-waiting-project-closure-section";
import { EditWmaInvestmentPaymentTabWaitingWireSection } from "./partials/wire/edit-wma-investment-payment-tab-waiting-wire-section";
import { EditWmaInvestmentPaymentTabWireReceivedSection } from "./partials/wire/edit-wma-investment-payment-tab-wire-received-section";

export function EditWmaInvestmentPaymentContent() {
  const { investment } = useEditWmaInvestmentPageContext();
  const navigate = useNavigate();

  const subscriptionFormSignatureStatus = investment.subscriptionFormStatus;
  const isSubscriptionFormSigned = subscriptionFormSignatureStatus === "signed";

  if (!isSubscriptionFormSigned) {
    return (
      <Alert
        className="m-6"
        variant="warning"
        icon="Cadenas"
        title="Le bulletin de souscription doit être signé par votre client avant de pouvoir effectuer le paiement"
        size="F"
      />
    );
  }

  if (investment.paymentMethodType === PaymentMethodType.WIRE) {
    if (investment.globalStatus === INVESTMENTS.GLOBAL_STATUS.VALIDATED) {
      return <EditWmaInvestmentPaymentTabWireReceivedSection />;
    }

    return <EditWmaInvestmentPaymentTabWaitingWireSection />;
  }

  if (
    investment.paymentMethodType === PaymentMethodType.PEA ||
    investment.paymentMethodType === PaymentMethodType.PEA_PME
  ) {
    if (investment.globalStatus === INVESTMENTS.GLOBAL_STATUS.VALIDATED) {
      return <EditWmaInvestmentPaymentTabPeaPaymentReceivedSection />;
    }

    if (!investment.project.commitmentLetterSigningEnabled) {
      return <EditWmaInvestmentPaymentTabPeaWaitingProjectClosureSection />;
    } else {
      return <EditWmaInvestmentPaymentTabPeaWaitingPaymentSection />;
    }
  }

  navigate("/wma/investments/list");
}
