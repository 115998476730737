import { Fragment } from "react";
import { Outlet } from "react-router-dom";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { ConnectedNavigation } from "../hooks/use-connected-navigation";
import { ConnectedSidebar } from "./connected-sidebar";
import { ConnectedTopbar } from "./connected-topbar";

export function ConnectedLayout(props: { navigation?: ConnectedNavigation }) {
  const { navigation } = props;

  return (
    <div className="relative flex h-screen min-h-screen w-full overflow-x-hidden">
      {navigation && navigation.items && navigation.items.length > 0 && (
        <Fragment>
          <ConnectedTopbar navigation={navigation.items} />
          <ConnectedSidebar navigation={navigation.items} />
        </Fragment>
      )}

      <section
        className={cn("mt-[60px] w-full duration-200 ease-in-out lg:mt-0")}
      >
        <div className="pb-8">
          <Outlet />
        </div>
      </section>
    </div>
  );
}
