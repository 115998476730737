import {
  ProjectDocument,
  ProjectDocumentEnum,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

export const useProjectDocumentLabel = (doc: ProjectDocument) => {
  const { t } = useTranslation();

  if (doc.type === ProjectDocumentEnum.OTHER) {
    return (
      doc.name ||
      doc.document?.originalFilename ||
      t("projects.back_office.edit_project_page.documents.document_type.other")
    );
  }

  return t(
    `projects.back_office.edit_project_page.documents.document_type.${doc.type}`,
  );
};
