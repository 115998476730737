import { TaxExemption } from "@tudigo-monorepo/core-tudigo-api-models";

import { TaxExemptionCSGFormFields } from "../../content/investor-profile-tax-exemptions-content";
import { getIRAvailabilityDateLimit } from "./dates";

export const getLatestTaxExemptionByEndDate = (
  taxExemptions: TaxExemption[],
) => {
  return taxExemptions.reduce((prev, current) => {
    if (!prev.id) {
      return current;
    }

    return new Date(current.validityEndDate) > new Date(prev.validityEndDate)
      ? current
      : prev;
  }, {} as TaxExemption);
};

export const isOutdatedTaxExemptionIR = (taxExemption: TaxExemption) => {
  const { taxName, status, periodIsCurrentlyValid, validityStartDate } =
    taxExemption;

  if (taxName !== "IR") {
    return false;
  }

  if (
    status === "draft" &&
    new Date() > new Date(getIRAvailabilityDateLimit())
  ) {
    return true;
  }

  if (
    status === "signed" &&
    new Date() >= new Date(validityStartDate) &&
    !periodIsCurrentlyValid
  ) {
    return true;
  }

  return false;
};

export const hasIRTaxExemptions = (taxExemptions: TaxExemption[]) => {
  return taxExemptions.some((item) => item.taxName === "IR");
};

export const hasCSGTaxExemption = (taxExemptions: TaxExemption[]) => {
  return !!taxExemptions.find((item) => item.taxName === "CSG");
};

export const isTaxExemptionCSGFormValid = (form: TaxExemptionCSGFormFields) => {
  const { since, state, socialProtectionFound, situation } = form;

  return Boolean(
    since &&
      state &&
      state.length > 0 &&
      socialProtectionFound &&
      socialProtectionFound.length > 0 &&
      situation,
  );
};
