import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { IconElementsProps } from "./icon-elements-props.type";
import { ArrowLeft } from "./icon-elements/arrow-left";
import { ArrowRight } from "./icon-elements/arrow-right";
import { Audio } from "./icon-elements/audio";
import { AutoBlock } from "./icon-elements/auto-block";
import { Bold } from "./icon-elements/bold";
import { Cadenas } from "./icon-elements/cadenas";
import { Calendar } from "./icon-elements/calendar";
import { CardQuote } from "./icon-elements/card-quote";
import { CardText } from "./icon-elements/card-text";
import { CardTextWithImage } from "./icon-elements/card-text-with-image";
import { CardUser } from "./icon-elements/card-user";
import { Checked } from "./icon-elements/checked";
import { ChevronDown } from "./icon-elements/chevron-down";
import { ChevronLeft } from "./icon-elements/chevron-left";
import { ChevronRight } from "./icon-elements/chevron-right";
import { ChevronUp } from "./icon-elements/chevron-up";
import { CircleProjects } from "./icon-elements/circle-projects";
import { CircleTeam } from "./icon-elements/circle-team";
import { CircleTrack } from "./icon-elements/circle-track";
import { CmsAnalyzeIcon } from "./icon-elements/cms-analyze-icon";
import { CmsHighlightIcon } from "./icon-elements/cms-highlight-icon";
import { Cross } from "./icon-elements/cross";
import { Dashboard } from "./icon-elements/dashboard";
import { Delete } from "./icon-elements/delete";
import { Desktop } from "./icon-elements/desktop";
import { Document } from "./icon-elements/document";
import { DotChip } from "./icon-elements/dot-chip";
import { Dots } from "./icon-elements/dots";
import { Download } from "./icon-elements/download";
import { Drag } from "./icon-elements/drag";
import { Duplicate } from "./icon-elements/duplicate";
import { Error } from "./icon-elements/error";
import { EventPlugin } from "./icon-elements/event-plugin";
import { EvolutionDown } from "./icon-elements/evolution-down";
import { EvolutionUp } from "./icon-elements/evolution-up";
import { Export } from "./icon-elements/export";
import { Faq } from "./icon-elements/faq";
import { Filter } from "./icon-elements/filter";
import { FirstPagination } from "./icon-elements/first-pagination";
import { Flash } from "./icon-elements/flash";
import { Folder } from "./icon-elements/folder";
import { GeometricPlanetIcon } from "./icon-elements/geometric-planet-icon";
import { Group } from "./icon-elements/group";
import { Hamburger } from "./icon-elements/hamburger";
import { Heading } from "./icon-elements/heading";
import { Helpdesk } from "./icon-elements/helpdesk";
import { Hide } from "./icon-elements/hide";
import { Iframe } from "./icon-elements/iframe";
import { Image } from "./icon-elements/image";
import { ImageAndText } from "./icon-elements/image-and-text";
import { Info } from "./icon-elements/info";
import { InformationIcon } from "./icon-elements/information-icon";
import { InsertLink } from "./icon-elements/insert-link";
import { Invest } from "./icon-elements/invest";
import { Italic } from "./icon-elements/italic";
import { LastPagination } from "./icon-elements/last-pagination";
import { Link } from "./icon-elements/link";
import { List } from "./icon-elements/list";
import { ListIcon } from "./icon-elements/list-icon";
import { Loading } from "./icon-elements/loading";
import { Location } from "./icon-elements/location";
import { Logout } from "./icon-elements/logout";
import { Mail } from "./icon-elements/mail";
import { Minus } from "./icon-elements/minus";
import { Money } from "./icon-elements/money";
import { NavCloser } from "./icon-elements/nav-closer";
import { NavOpener } from "./icon-elements/nav-opener";
import { News } from "./icon-elements/news";
import { Next } from "./icon-elements/next";
import { Notification } from "./icon-elements/notification";
import { OrderedList } from "./icon-elements/ordered-list";
import { PaperIcon } from "./icon-elements/paper-icon";
import { Pen } from "./icon-elements/pen";
import { Phone } from "./icon-elements/phone";
import { PictureMulti } from "./icon-elements/picture-multi";
import { Play } from "./icon-elements/play";
import { Plus } from "./icon-elements/plus";
import { Previous } from "./icon-elements/previous";
import { Profile } from "./icon-elements/profile";
import { Question } from "./icon-elements/question";
import { QuoteLeft } from "./icon-elements/quote-left";
import { QuoteRight } from "./icon-elements/quote-right";
import { ReloadIcon } from "./icon-elements/reload-icon";
import { Search } from "./icon-elements/search";
import { Settings } from "./icon-elements/settings";
import { Share } from "./icon-elements/share";
import { Show } from "./icon-elements/show";
import { SocialDiscord } from "./icon-elements/social-discord";
import { SocialFacebook } from "./icon-elements/social-facebook";
import { SocialInstagram } from "./icon-elements/social-instagram";
import { SocialLinkedin } from "./icon-elements/social-linkedin";
import { SocialLinkedin2 } from "./icon-elements/social-linkedin2";
import { SocialTiktok } from "./icon-elements/social-tiktok";
import { SocialTwitter } from "./icon-elements/social-twitter";
import { SocialYoutube } from "./icon-elements/social-youtube";
import { Star } from "./icon-elements/star";
import { Stats } from "./icon-elements/stats";
import { Target } from "./icon-elements/target";
import { Team } from "./icon-elements/team";
import { Text } from "./icon-elements/text";
import { Thumb } from "./icon-elements/thumb";
import { Timeline } from "./icon-elements/timeline";
import { Timer } from "./icon-elements/timer";
import { TudigoLogoIcon } from "./icon-elements/tudigo-logo-icon";
import { TudigoLogoSmallIcon } from "./icon-elements/tudigo-logo-small-icon";
import { UnorderedList } from "./icon-elements/unordered-list";
import { Upload } from "./icon-elements/upload";
import { UserChat } from "./icon-elements/user-chat";
import { Video } from "./icon-elements/video";
import { WalletIcon } from "./icon-elements/wallet-icon";
import { IconName } from "./icon-name.type";

export const icons = Object.freeze({
  ArrowLeft: ArrowLeft,
  ArrowRight: ArrowRight,
  Audio: Audio,
  Cadenas: Cadenas,
  Calendar: Calendar,
  CardText: CardText,
  CardTextWithImage: CardTextWithImage,
  CardQuote: CardQuote,
  CardUser: CardUser,
  Checked: Checked,
  ChevronDown: ChevronDown,
  ChevronLeft: ChevronLeft,
  ChevronRight: ChevronRight,
  ChevronUp: ChevronUp,
  Cross: Cross,
  Dashboard: Dashboard,
  Document: Document,
  Delete: Delete,
  DotChip: DotChip,
  Dots: Dots,
  Download: Download,
  Duplicate: Duplicate,
  Error: Error,
  EvolutionDown: EvolutionDown,
  EvolutionUp: EvolutionUp,
  Export: Export,
  Faq: Faq,
  Filter: Filter,
  FirstPagination: FirstPagination,
  Folder: Folder,
  GeometricPlanetIcon: GeometricPlanetIcon,
  Group: Group,
  Hamburger: Hamburger,
  Helpdesk: Helpdesk,
  Hide: Hide,
  Info: Info,
  InformationIcon: InformationIcon,
  Invest: Invest,
  LastPagination: LastPagination,
  List: List,
  ListIcon: ListIcon,
  Loading: Loading,
  Logout: Logout,
  Mail: Mail,
  Minus: Minus,
  Money: Money,
  NavCloser: NavCloser,
  NavOpener: NavOpener,
  News: News,
  Next: Next,
  Notification: Notification,
  PaperIcon: PaperIcon,
  Pen: Pen,
  PictureMulti: PictureMulti,
  Play: Play,
  Plus: Plus,
  Previous: Previous,
  Profile: Profile,
  Question: Question,
  QuoteLeft: QuoteLeft,
  QuoteRight: QuoteRight,
  ReloadIcon: ReloadIcon,
  Search: Search,
  Settings: Settings,
  Show: Show,
  SocialFacebook: SocialFacebook,
  SocialInstagram: SocialInstagram,
  SocialLinkedin: SocialLinkedin,
  SocialLinkedin2: SocialLinkedin2,
  SocialTwitter: SocialTwitter,
  SocialYoutube: SocialYoutube,
  Stats: Stats,
  Target: Target,
  Team: Team,
  Timer: Timer,
  TudigoLogoIcon: TudigoLogoIcon,
  TudigoLogoSmallIcon: TudigoLogoSmallIcon,
  Thumb: Thumb,
  Upload: Upload,
  UserChat: UserChat,
  WalletIcon: WalletIcon,
  Location: Location,
  Bold,
  Desktop,
  Drag,
  Heading,
  Image,
  ImageAndText,
  InsertLink,
  Italic,
  OrderedList,
  Phone,
  Text,
  Timeline,
  UnorderedList,
  Video,
  Share,
  Link,
  Iframe,
  AutoBlock,
  SocialDiscord,
  SocialTiktok,
  Star,
  Flash,
  CmsHighlight: CmsHighlightIcon,
  CmsAnalyze: CmsAnalyzeIcon,
  CircleTrack: CircleTrack,
  CircleProject: CircleProjects,
  CircleTeam: CircleTeam,
  EventPlugin: EventPlugin,
});

export type IconProps = { name: IconName } & IconElementsProps;

export function Icon(props: IconProps) {
  const { name, bgColor, className } = props;
  const RequestedIcon = icons[name];

  if (!RequestedIcon) return null;

  if (bgColor) {
    return (
      <figure
        className={cn(
          "flex items-center justify-center rounded p-0.5",
          className,
        )}
        style={{ backgroundColor: bgColor }}
      >
        <RequestedIcon {...props} />
      </figure>
    );
  }

  return <RequestedIcon {...props} />;
}
