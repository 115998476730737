import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { InputSelect } from "@tudigo-monorepo/web-tudigo-components";

type OrganizationOption =
  | "pea"
  | "pea_pme"
  | "company"
  | "individual"
  | "operator";

type OrganizationTypeSelectorProps = {
  options: OrganizationOption[];
  selectedValue?: OrganizationOption;
  onSelect?: (value: OrganizationOption) => void;
};

export function OrganizationTypeSelector({
  options,
  selectedValue,
  onSelect,
}: OrganizationTypeSelectorProps) {
  const { t } = useTranslation();

  return (
    <InputSelect
      label={t("organizations.create_organization.modal.account_type.label")}
      placeholder={t(
        "organizations.create_organization.modal.account_type.placeholder",
      )}
      items={options.map((type) => ({
        label: t(
          `organizations.create_organization.modal.account_type.${type}`,
        ),
        value: type,
      }))}
      onChange={(value) => {
        onSelect?.(value as OrganizationOption);
      }}
      selectedValue={selectedValue}
      labelFunction={(item) => item.label}
      getValueFromItem={(item) => item.value as OrganizationOption}
      comparisonFunction={(item, selection) => item.value === selection}
      required
    />
  );
}
