import { useState } from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../ui/popover/popover";
import { AccountCard } from "../account-card";
import { MenuSelect } from "../menu-navigation";

type MenuLinkProps = {
  item: MenuSelect;
  reducedDisplay: boolean;
  className?: string;
};

export function MenuNavigationSelectItem(props: MenuLinkProps) {
  const { item, reducedDisplay, className } = props;
  const [popoverOpen, setPopoverOpen] = useState(false);

  const onItemSelected = (value: string) => {
    item.onChange(value);
    setPopoverOpen(false);
  };

  return (
    <div className={className}>
      <Popover
        open={popoverOpen}
        onOpenChange={() => {
          if (item.selectOptions.length > 0) {
            setPopoverOpen(!popoverOpen);
          }
        }}
      >
        <PopoverTrigger className="min-h-[76px] w-full">
          <AccountCard
            upperText={item.label}
            size={reducedDisplay ? "XS" : "full"}
            avatar={item.icon}
            textClassName=""
            className={cn("hover:border-accent-medium hover:border", {
              "border-accent-medium bg-accent-super-light": popoverOpen,
              "px-0 py-1 hover:border-none": reducedDisplay,
            })}
          ></AccountCard>
        </PopoverTrigger>
        {item.selectOptions.length > 0 && (
          <PopoverContent
            side="right"
            align="start"
            alignOffset={-36}
            className="p-0"
          >
            <div className="divide-y px-6 py-4 sm:px-0 sm:py-0">
              <h5 className="font-montserrat text-dark-3 p-3 text-sm font-semibold">
                {item.popoverTitle}
              </h5>
            </div>
            {item.selectOptions.map((option, index) => (
              <div
                key={index}
                onClick={() => onItemSelected(option.value)}
                className="menu-navigation-item hover:border-accent-medium flex min-h-[43px] w-full cursor-pointer items-center gap-3 rounded-lg border border-transparent px-3 py-2 text-[#6F6A73] transition-all"
              >
                <p className="whitespace-nowrap opacity-100 transition-opacity duration-300 ease-in-out">
                  {option.label}
                </p>
              </div>
            ))}
          </PopoverContent>
        )}
      </Popover>
    </div>
  );
}
