import React, { useState } from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";

import { AssemblyParticipant } from "@tudigo-monorepo/core-tudigo-api-models";
import {
  FormContextProvider,
  getSmartFormComponents,
} from "@tudigo-monorepo/web-feature-form";
import { useChangeAssemblyParticipationMutation } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  Button,
  Form,
  Title,
} from "@tudigo-monorepo/web-tudigo-components";

import { useMyInvestmentAssemblyPageContext } from "../../investment-assembly-page/my-investment-assembly-page";

type PickProxyTypeFormKey = Pick<
  Omit<AssemblyParticipant, "assembly">,
  "proxyType"
>;

type AssemblyProxyTypeFormProps = {
  setProxyTypeFormIsOpen: (value: boolean) => void;
};

export function ProxyTypeForm(props: AssemblyProxyTypeFormProps) {
  const intl = useIntl();
  const { assemblyParticipant, assembly } =
    useMyInvestmentAssemblyPageContext();

  const [proxyTypeFormData, setProxyTypeFormData] = useState({
    ...assemblyParticipant,
  });

  const { setProxyTypeFormIsOpen } = props;

  const { SmartRadioGroupField } =
    getSmartFormComponents<PickProxyTypeFormKey>();

  const {
    mutate: changeAssemblyParticipation,
    isPending: isParticipationFormLoading,
  } = useChangeAssemblyParticipationMutation();

  const onProxyTypeFormSubmit = () => {
    changeAssemblyParticipation(
      {
        assemblyId: assembly.id,
        participantId: assemblyParticipant.id,
        data: {
          proxyType: proxyTypeFormData.proxyType,
        },
      },
      {
        onSuccess() {
          toast((content) => (
            <Alert
              icon="Info"
              close={content.closeToast}
              variant="success"
              size="S"
              title="Type de procuration enregistré"
              description="Le type de procuration a bien été enregistré. Merci de bien vouloir signer le document de procuration"
            />
          ));
          setProxyTypeFormIsOpen(false);
        },
        onError(error) {
          console.log(error);
        },
      },
    );
  };

  return (
    <FormContextProvider
      formData={proxyTypeFormData}
      setFormData={setProxyTypeFormData}
      error={null}
    >
      <div className="rounded-lg border px-10 py-8">
        <Title h3Brand="brand-2" level="h5" className="text-primary mb-6">
          Choisissez votre type de procuration
        </Title>
        <div className="w-[251px]">
          <Form className="flex flex-col gap-4">
            <SmartRadioGroupField
              label="proxyType"
              name="proxyType"
              selectedValue={proxyTypeFormData?.proxyType as string}
              items={[
                {
                  label: "Tudigo",
                  value: "tudigo",
                },
                {
                  label: "Réprésentant de la société",
                  value: "organization_representative",
                },
              ]}
            />
          </Form>
          <Button
            isLoading={isParticipationFormLoading}
            className="mt-6"
            variant="primary"
            label={intl.formatMessage({
              id: "assembly.my_participation.participation_form.submit_btn",
            })}
            disabled={
              isParticipationFormLoading || !proxyTypeFormData?.proxyType
            }
            onClick={onProxyTypeFormSubmit}
          />
        </div>
      </div>
    </FormContextProvider>
  );
}
