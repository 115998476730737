import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  isBondsProject,
  isSharesProject,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { FormContextProvider } from "@tudigo-monorepo/web-feature-form";
import { useUpdateInvestmentMutation } from "@tudigo-monorepo/web-tudigo-api-client";
import { Button } from "@tudigo-monorepo/web-tudigo-components";

import { getInvestmentAmountError } from "../../../../investment-utils";
import {
  investmentFunnelFields,
  useInvestmentFunnelPageContext,
} from "../../../../pages/investment-funnel-page";
import { BondsInvestmentAmountFormContent } from "./bonds_parts/bonds-investment-amount-form-content";
import { ForecastRepaymentPeriodsSection } from "./bonds_parts/forecast-repayment-periods-section";
import { SharesInvestmentAmountFormContent } from "./shares_parts/shares-investment-amount-form-content";

export type InvestmentAmountFormData = {
  investmentAmount: number | null;
};

export function InvestmentAmountForm() {
  const { project, ongoingInvestment } = useInvestmentFunnelPageContext();
  const navigate = useNavigate();
  const [investmentFormData, setInvestmentFormData] =
    useState<InvestmentAmountFormData>({
      investmentAmount: ongoingInvestment
        ? ongoingInvestment.totalAmountInCents
        : null,
    });
  const [toggleAgreementAccepted, setToggleAgreementAccepted] =
    useState<boolean>(!!ongoingInvestment?.totalAmountInCents);

  const {
    mutateAsync: updateInvestmentIntent,
    isPending: isUpdateInvestmentIntentLoading,
  } = useUpdateInvestmentMutation({
    onSuccess: async () => {
      navigate(`/projects/${project.id}/invest/${ongoingInvestment?.id}`);
    },
  });

  const handleSubmitForInvestmentUpdate = () => {
    if (investmentFormData.investmentAmount === null || !ongoingInvestment) {
      return;
    }
    updateInvestmentIntent({
      investmentId: ongoingInvestment.id,
      query: {
        fields: investmentFunnelFields,
      },
      data: {
        totalAmountInCents: investmentFormData.investmentAmount,
      },
    });
  };

  const submitIsDisabled = () => {
    if (investmentFormData.investmentAmount === null) {
      return true;
    }

    if (
      ongoingInvestment !== undefined &&
      ongoingInvestment.totalAmountInCents ===
        investmentFormData.investmentAmount
    ) {
      return true;
    }

    switch (project.mechanic) {
      case "bonds":
        if (
          investmentFormData.investmentAmount <
          project.bondsMechanicSettings.minimumTicketAmountInCents
        ) {
          return true;
        }
        break;
      case "shares":
        if (
          investmentFormData.investmentAmount <
          project.sharesMechanicSettings.minimumTicketAmountInCents
        ) {
          return true;
        }
        break;
    }

    return !(
      investmentFormData.investmentAmount > 0 &&
      toggleAgreementAccepted &&
      !getInvestmentAmountError(investmentFormData.investmentAmount, project)
    );
  };

  const handleFormDataChange = (formData: InvestmentAmountFormData) => {
    setInvestmentFormData({
      ...formData,
    });
  };

  return (
    <div className="mb-6">
      <FormContextProvider
        formData={investmentFormData}
        setFormData={handleFormDataChange}
        error={null}
      >
        <div className="flex flex-col items-center">
          {isSharesProject(project) && (
            <SharesInvestmentAmountFormContent
              project={project}
              investmentFormData={investmentFormData}
              toggleAgreementAccepted={toggleAgreementAccepted}
              setToggleAgreementAccepted={setToggleAgreementAccepted}
            />
          )}
          {isBondsProject(project) && (
            <BondsInvestmentAmountFormContent
              project={project}
              investmentFormData={investmentFormData}
              toggleAgreementAccepted={toggleAgreementAccepted}
              setToggleAgreementAccepted={setToggleAgreementAccepted}
            />
          )}
          <Button
            isLoading={isUpdateInvestmentIntentLoading}
            disabled={submitIsDisabled() || isUpdateInvestmentIntentLoading}
            className="w-fit px-12"
            variant="green"
            label={
              ongoingInvestment?.totalAmountInCents
                ? "Modifier mon investissement"
                : "Investir"
            }
            iconRight="ArrowRight"
            onClick={handleSubmitForInvestmentUpdate}
          />
        </div>
      </FormContextProvider>
      {project.mechanic === "bonds" && (
        <ForecastRepaymentPeriodsSection
          totalAmountInCents={investmentFormData.investmentAmount ?? 0}
        />
      )}
    </div>
  );
}
