import { ClubV2 } from "@tudigo-monorepo/core-tudigo-api-models";
import { DiscordCard } from "@tudigo-monorepo/web-tudigo-components";

import { ClubBasicIdentityCard } from "../club-basic-identity-card/club-basic-identity-card";
import { ClubTrackRecordCard } from "../club-track-record-card/club-track-record-card";

type ClubPageSidebarProps = {
  club: ClubV2;
};

export const ClubPageSidebar = ({ club }: ClubPageSidebarProps) => {
  return (
    <div className="flex w-full flex-col gap-4">
      <ClubTrackRecordCard trackRecords={club.trackRecords} />
      <ClubBasicIdentityCard club={club} />
      {club.socialLinks.discordUrl && (
        <div className="hidden lg:block">
          <DiscordCard url={club.socialLinks.discordUrl} />
        </div>
      )}
    </div>
  );
};
