import React from "react";
import { useNavigate } from "react-router-dom";

import { Investment } from "@tudigo-monorepo/core-tudigo-api-models";
import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useGetUserInvestments } from "@tudigo-monorepo/web-tudigo-api-client";
import { TudigoCollection } from "@tudigo-monorepo/web-tudigo-collection";
import {
  Icon,
  Table,
  TableCardList,
} from "@tudigo-monorepo/web-tudigo-components";

import { useEditWmaCustomerPageContext } from "../edit-wma-customer-page";
import { useCustomerInvestmentsColumns } from "../hooks/use-customer-investments-columns";

const requestProjectFields =
  "mechanic,content.fields(name),holder_organization.fields(id,name,internal_kyc_status)";
const requestFields =
  "id," +
  "total_amount_in_cents," +
  "investor_organization.fields(id,name)," +
  "payment_method_type," +
  "created_at," +
  "status," +
  "global_status," +
  "wma_investment.fields(id)," +
  "project.fields(" +
  requestProjectFields +
  ")";

export function EditWmaCustomerInvestmentsContent() {
  const { customer } = useEditWmaCustomerPageContext();
  const navigate = useNavigate();

  const columns = useCustomerInvestmentsColumns();

  const userId = customer?.customerUser?.id;

  return (
    <div className="m-6 flex flex-col gap-6">
      <TudigoCollection
        useLocationAsState
        columns={columns}
        useQueryFn={useGetUserInvestments}
        pathParams={{
          userId: userId!,
        }}
        actions={[
          {
            render: (investment: Investment) =>
              investment.wmaInvestment ? (
                <Icon
                  name="ChevronRight"
                  primaryColor={themeColors["dark-2"]}
                />
              ) : null,
          },
        ]}
        fields={requestFields}
        render={(table, props) => {
          return (
            <>
              <Table
                table={table}
                actions={props.actions}
                className="hidden sm:table"
                onRowClick={(investment) => {
                  if (investment.wmaInvestment) {
                    navigate(
                      `/wma/investments/edit/${investment.wmaInvestment.id}`,
                    );
                  }
                }}
              />
              <TableCardList
                table={table}
                className="sm:hidden"
                onCardClick={(investment) => {
                  navigate(
                    `/my-account/my-investments/projects/${investment.project.id}`,
                  );
                }}
              />
            </>
          );
        }}
      />
    </div>
  );
}
