import { Organization } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { PageEnum, useSpaceBusiness } from "@tudigo-monorepo/tudigo-session";
import { IconName, PageHead } from "@tudigo-monorepo/web-tudigo-components";

import { useOrganizationKycStatusChipProps } from "../../hooks/use-organization-kyc-status-chip-props";

type OrganizationKYCPageHeadProps = {
  organization: Organization;
  goBackIcon?: IconName;
};

export function OrganizationKYCPageHead({
  organization,
  goBackIcon,
}: OrganizationKYCPageHeadProps) {
  const { t } = useTranslation();

  const { currentPagePaths } = useSpaceBusiness();

  const chipStatusProps = useOrganizationKycStatusChipProps(organization);

  return (
    <PageHead
      withEndLine
      title={t("organizations.back_office.organization_page.title")}
      levels={[
        {
          url: currentPagePaths[PageEnum.ORGANIZATIONS],
          iconName: goBackIcon || "Folder",
        },
        {
          text: `${organization.name}`,
        },
      ]}
      chipsTop={chipStatusProps ? [chipStatusProps] : undefined}
      chipsBottom={[
        { label: organization.name },
        {
          label: t(
            `organizations.back_office.organization_page.page_head.chip.${organization.type}.label`,
          ),
          type: "secondary",
        },
      ]}
    />
  );
}
