import React from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { Avatar, AvatarVariant } from "../avatar";
import { IconName } from "../icons/icon-name.type";
import { IconSizes } from "../icons/icon-sizes.type";
import { Typography } from "../typography";

type ProfileCardProps = {
  upperText: string;
  lowerText?: string;
  active?: boolean;
  avatarText?: string;
  avatarUrl?: string;
  avatarVariant?: AvatarVariant;
  border?: boolean;
  className?: string;
  disabled?: boolean;
  icon?: {
    name: IconName;
    color?: string;
    size?: IconSizes;
  };
  size?: "XS" | "full";
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  variant?: "dark" | "light";
};

export function ProfileCard(props: ProfileCardProps) {
  const {
    avatarText,
    avatarUrl,
    avatarVariant,
    className,
    upperText,
    lowerText,
    size,
    active: activeProp = false,
    disabled = false,
    border = false,
    icon,
    onClick,
    onMouseEnter,
    onMouseLeave,
    variant = "dark",
  } = props;

  const [active, setActive] = React.useState<boolean>(activeProp);
  const textColor = disabled ? "text-dark-4" : "text-dark-1";
  const textClassName = cn(
    "w-full max-w-[204px] truncate text-pretty",
    textColor,
    {
      "text-white": variant === "light",
    },
  );

  const handleClick = () => {
    if (onClick && !disabled) {
      setActive((prev) => !prev);
      onClick();
    }
  };

  React.useEffect(() => {
    setActive(activeProp);
  }, [activeProp]);

  return (
    <div
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={cn(
        "profile-card w-full justify-between rounded-lg border transition-colors",
        {
          "w-[44px] justify-center": size === "XS",
          "w-full": size === "full",
          "cursor-pointer": (onClick || onMouseEnter) && !disabled,
          "hover:border-accent-medium p-2":
            (onClick || onMouseEnter) && !disabled && size !== "XS",
          "bg-accent-super-light border-accent-medium": active,
          "border-transparent": !border,
          "bg-light-2": disabled,
        },
        className,
      )}
    >
      <div className="flex items-center gap-3">
        <Avatar
          url={avatarUrl}
          icon={icon}
          variant={active ? "blue" : avatarVariant}
          className={cn({ "border-dark-2": active })}
        >
          {avatarText ? (
            avatarText
          ) : (
            <span role="img" aria-label="avatar">
              🦊
            </span>
          )}
        </Avatar>
        {size !== "XS" ? (
          <div className="flex flex-col">
            <Typography variant="caption1-semibold" className={textClassName}>
              {upperText}
            </Typography>
            {lowerText ? (
              <Typography variant="body2" className={textClassName}>
                {lowerText}
              </Typography>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}
