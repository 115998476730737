import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import {
  EquitySavingPlan,
  Organization,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient, getQueryDetailKey } from "../../..";

type CreateOrganizationEquitySavingPlanParams = {
  organizationId: Organization["id"];
  data: EquitySavingPlan;
  fields?: string;
};

export const createOrganizationEquitySavingPlan = async (
  params: CreateOrganizationEquitySavingPlanParams,
) => {
  const apiClient = getApiClient();

  const response =
    await apiClient.apiV1.organizations.createOrganizationEquitySavingPlan(
      params.organizationId,
      params.data,
    );

  return response.data;
};

export const useCreateOrganizationEquitySavingPlanMutation = (
  options?: UseMutationOptions<
    TudigoResponse<EquitySavingPlan>,
    TudigoError,
    CreateOrganizationEquitySavingPlanParams
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (params: CreateOrganizationEquitySavingPlanParams) =>
      createOrganizationEquitySavingPlan(params),
    onSuccess: (...props) => {
      const [, params] = props;

      const organizationQueryKey = getQueryDetailKey({
        key: QUERY_KEYS.ORGANIZATION,
        id: params.organizationId,
        fields: params.fields,
      });

      queryClient.invalidateQueries({
        queryKey: organizationQueryKey,
      });

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.USER_ORGANIZATIONS],
      });

      options?.onSuccess?.(...props);
    },
  });
};
