import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import {
  InvestorDocument,
  User,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import {
  CollectionRequestParams,
  getApiClient,
  getQueryListKey,
} from "../../..";

type GetInvestorDocumentsParams = CollectionRequestParams<
  { userId: User["id"] | "me" },
  undefined
>;

export const getGetInvestorDocuments = async (
  params: GetInvestorDocumentsParams,
) => {
  const apiClient = getApiClient();
  const { fields, pathParams } = params;
  const response = await apiClient.apiV1.investorDocuments.getInvestorDocuments(
    pathParams?.userId || "me",
    {
      fields,
    },
  );

  return response.data;
};

export const useGetInvestorDocumentsQuery = (
  params: GetInvestorDocumentsParams,
  options?: UseQueryOptions<TudigoResponse<InvestorDocument[]>, TudigoError>,
) => {
  return useQuery({
    queryKey: getQueryListKey({
      key: QUERY_KEYS.INVESTOR_DOCUMENTS,
      fields: params?.fields,
      filters: params?.filters,
    }),
    queryFn: () => getGetInvestorDocuments(params),
    ...options,
  });
};
