import React from "react";
import { useIntl } from "react-intl";

import { ListElement, Title } from "@tudigo-monorepo/web-tudigo-components";

import { useMyInvestmentAssemblyPageContext } from "../../investment-assembly-page/my-investment-assembly-page";

export function AssemblyDocumentsContent() {
  const { assembly, assemblyParticipant } =
    useMyInvestmentAssemblyPageContext();
  const intl = useIntl();

  return (
    <div className="p-6">
      <Title h3Brand="brand-2" className="text-primary mb-6" level="h3">
        Documents
      </Title>

      {assembly.documents?.map((assemblyDocument, index) => (
        <div key={index} className="mb-6 flex-row">
          <ListElement
            startElement={{
              img: {
                icon: { name: "PaperIcon" },
              },
              upperText: assemblyDocument.documentName as string,
            }}
            onClick={() =>
              window.open(assemblyDocument.document.signedUrl ?? "")
            }
            toggleActiveOnClick={false}
            middleElement={{
              upperText: "",
            }}
            endElement={{
              info: {
                text: "PDF",
              },
              icon: "Show",
            }}
          />
        </div>
      ))}

      {assemblyParticipant.convocationDocument && (
        <div className="mb-6 flex-row">
          <ListElement
            startElement={{
              img: {
                icon: { name: "PaperIcon" },
              },
              upperText: intl.formatMessage({
                id: "assembly.my_participation.convocation_document.label",
              }),
            }}
            onClick={() =>
              window.open(
                assemblyParticipant.convocationDocument?.signedUrl ?? "",
              )
            }
            toggleActiveOnClick={false}
            middleElement={{
              upperText: "",
            }}
            endElement={{
              info: {
                text: "PDF",
              },
              icon: "Show",
            }}
          />
        </div>
      )}

      {assemblyParticipant.proxyDocument && (
        <div className="mb-6 flex-row">
          <ListElement
            startElement={{
              img: {
                icon: { name: "PaperIcon" },
              },
              upperText: "Procuration",
            }}
            onClick={() =>
              window.open(assemblyParticipant.proxyDocument?.signedUrl ?? "")
            }
            toggleActiveOnClick={false}
            middleElement={{
              upperText: "",
            }}
            endElement={{
              info: {
                text: "PDF",
              },
              icon: "Show",
            }}
          />
        </div>
      )}

      {assemblyParticipant.signedProxyDocument && (
        <div className="mb-6 flex-row">
          <ListElement
            startElement={{
              img: {
                icon: { name: "PaperIcon" },
              },
              upperText: "Procuration signée",
            }}
            onClick={() =>
              window.open(
                assemblyParticipant.signedProxyDocument?.signedUrl ?? "",
              )
            }
            toggleActiveOnClick={false}
            middleElement={{
              upperText: "",
            }}
            endElement={{
              info: {
                text: "PDF",
              },
              icon: "Show",
            }}
          />
        </div>
      )}

      {assemblyParticipant.signedVoteDocument && (
        <div className="mb-6 flex-row">
          <ListElement
            startElement={{
              img: {
                icon: { name: "PaperIcon" },
              },
              upperText: "Vote signé",
            }}
            onClick={() =>
              window.open(
                assemblyParticipant.signedVoteDocument?.signedUrl ?? "",
              )
            }
            toggleActiveOnClick={false}
            middleElement={{
              upperText: "",
            }}
            endElement={{
              info: {
                text: "PDF",
              },
              icon: "Show",
            }}
          />
        </div>
      )}
    </div>
  );
}
