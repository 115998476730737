import { useIntl } from "react-intl";

import { Assembly } from "@tudigo-monorepo/core-tudigo-api-models";

export const useAssemblyParticipationFormItems = (
  channel: Assembly["configuration"]["channel"],
  proxyAllowed: Assembly["configuration"]["proxyAllowed"],
) => {
  const intl = useIntl();

  let items;

  switch (channel) {
    case "in_person":
      items = [
        {
          label: intl.formatMessage({
            id: "assembly.my_participation.participation_form.label.physical_presence",
          }),
          value: "physical_presence",
        },
        {
          label: intl.formatMessage({
            id: "assembly.my_participation.participation_form.label.not_present",
          }),
          value: "not_present",
        },
      ];
      break;
    case "video_conference":
      items = [
        {
          label: intl.formatMessage({
            id: "assembly.my_participation.participation_form.label.remote_presence",
          }),
          value: "remote_presence",
        },
        {
          label: intl.formatMessage({
            id: "assembly.my_participation.participation_form.label.not_present",
          }),
          value: "not_present",
        },
      ];
      break;
    case "hybrid":
      items = [
        {
          label: intl.formatMessage({
            id: "assembly.my_participation.participation_form.label.physical_presence",
          }),
          value: "physical_presence",
        },
        {
          label: intl.formatMessage({
            id: "assembly.my_participation.participation_form.label.remote_presence",
          }),
          value: "remote_presence",
        },
        {
          label: intl.formatMessage({
            id: "assembly.my_participation.participation_form.label.not_present",
          }),
          value: "not_present",
        },
      ];
      break;
    case "online_voting_only":
      items = [
        {
          label: intl.formatMessage({
            id: "assembly.my_participation.participation_form.label.online_voting",
          }),
          value: "online_voting",
        },
        {
          label: intl.formatMessage({
            id: "assembly.my_participation.participation_form.label.not_present",
          }),
          value: "not_present",
        },
      ];
      break;
    default:
      items = [
        {
          label: intl.formatMessage({
            id: "assembly.my_participation.participation_form.label.physical_presence",
          }),
          value: "physical_presence",
        },
        {
          label: intl.formatMessage({
            id: "assembly.my_participation.participation_form.label.remote_presence",
          }),
          value: "remote_presence",
        },
        {
          label: intl.formatMessage({
            id: "assembly.my_participation.participation_form.label.online_voting",
          }),
          value: "online_voting",
        },
        {
          label: intl.formatMessage({
            id: "assembly.my_participation.participation_form.label.not_present",
          }),
          value: "not_present",
        },
      ];
  }

  if (proxyAllowed) {
    items.push({
      label: intl.formatMessage({
        id: "assembly.my_participation.participation_form.label.voting_by_proxy",
      }),
      value: "voting_by_proxy",
    });
  }

  return items;
};
