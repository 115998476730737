import { useLocation, useNavigate } from "react-router-dom";

import {
  StepObject,
  Stepper,
  Title,
} from "@tudigo-monorepo/web-tudigo-components";

import { InvestmentFunnelNavigationButton } from "./investment-funnel-navigation-button";

type FunnelInvestmentNavigationProps = {
  title: string;
  onBackButtonClick: () => void;
  steps: string[] | StepObject[];
  activeStepIndex?: number;
};

export function InvestmentFunnelNavigation(
  props: FunnelInvestmentNavigationProps,
) {
  const { title, onBackButtonClick, steps, activeStepIndex } = props;
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div
      role="navigation"
      className="dynamic-form-navigation fixed left-0 top-0 z-50 flex h-[104px] w-full flex-col items-center justify-center gap-y-3 border-b bg-white px-6 sm:gap-y-0"
    >
      <Title
        level="h2"
        className="hidden whitespace-nowrap text-center sm:block sm:translate-y-2"
      >
        {title}
      </Title>
      <Title
        level="h5"
        className="block whitespace-nowrap text-center sm:hidden"
      >
        {title}
      </Title>

      <div className="flex w-full items-center justify-between gap-x-4">
        {(!location.pathname.includes("final-page") && (
          <InvestmentFunnelNavigationButton
            type="back"
            onClick={onBackButtonClick}
          />
        )) || (
          <div className="sm:mb-[calc((104px/2)-(43px/2))] sm:w-[236px]"></div>
        )}

        <div className="contents sm:block sm:w-[600px] sm:max-w-[600px]">
          <Stepper steps={steps} activeStepIndex={activeStepIndex} />
        </div>

        {(location.pathname.includes("final-page") && (
          <InvestmentFunnelNavigationButton
            type="exit"
            onClick={() => navigate("/dispatch")}
          />
        )) || (
          <div className="sm:mb-[calc((104px/2)-(43px/2))] sm:w-[236px]"></div>
        )}
      </div>
    </div>
  );
}
