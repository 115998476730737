import { useEffect, useMemo, useState } from "react";
import useDebouncedCallback from "@restart/hooks/useDebouncedCallback";
import { useLocation } from "react-router-dom";

import {
  ShareProjectSimulatorQuery,
  SharesProject,
  SimulatorScenario,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";
import { useGetSimulatorByProject } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Chip,
  CurrencyInput,
  Icon,
  InputSlider,
  Tooltip,
} from "@tudigo-monorepo/web-tudigo-components";
import {
  currentEnvEventsName,
  HubspotEventEnum,
  hubspotTrackEvent,
} from "@tudigo-monorepo/web-tudigo-tracking";

import { InvestButton } from "../../../../../shared/components/invest-button";
import { ToggleChip } from "../../../../../shared/components/toggle-chip";
import { useProjectContext } from "../../../project.context";
import { SimulatorPlaygroundBlock } from "../components/simulator-playground-block";
import { SimulatorResultsBlock } from "../components/simulator-results-block";
import { SimulatorRoiBlock } from "../components/simulator-roi-block";
import {
  SHARES_SCENARIOS,
  SIMULATOR_TAX_EXEMPTION_INFOS,
  SimulatorTaxExemptionsEnum,
} from "../simulator-constants";

export function SimulatorShares() {
  const project = useProjectContext() as SharesProject;
  const location = useLocation();

  const [isReady, setIsReady] = useState(false);
  const [simulatorForm, setSimulatorForm] =
    useState<ShareProjectSimulatorQuery>({
      amountInCents: 100000,
      taxRate: 0,
    });

  const setFormValue = (key: string, value: string | number) => {
    setSimulatorForm((prev) => ({ ...prev, [key]: value }));
  };

  const { data: simulatorData } = useGetSimulatorByProject(
    {
      projectId: project?.id,
      query: simulatorForm,
    },
    {
      enabled: isReady,
    },
  );

  const filteredTaxExemptions = useMemo(() => {
    if (!project?.taxExemptionMechanics) return null;

    return project?.taxExemptionMechanics?.filter((taxKey) =>
      Object.keys(SimulatorTaxExemptionsEnum).includes(taxKey),
    );
  }, [project]) as SimulatorTaxExemptionsEnum[] | null;

  const handleSliderChange = useDebouncedCallback((e) => {
    hubspotTrackEvent(
      currentEnvEventsName[HubspotEventEnum.PROJECT_SIMULATION_DONE],
      {
        project_id: project?.id,
        project_slug: project?.content?.slug,
        amount: e?.target?.value,
      },
    );
    setFormValue("amountInCents", e?.target?.value * 100);
  }, 150);

  const maxValue = project.goal?.maxInCents
    ? project.goal?.maxInCents / 100
    : 2000000;

  const isShareCapitalized = !!project?.sharesMechanicSettings?.buyPack;

  useEffect(() => {
    if (!project) {
      return;
    }
    if (!isShareCapitalized) {
      setFormValue("scenario", SimulatorScenario.Min);
    }
    setFormValue(
      "amountInCents",
      location.state?.amountInCents ??
        project.sharesMechanicSettings?.minimumTicketAmountInCents,
    );

    if (filteredTaxExemptions && filteredTaxExemptions.length > 0) {
      setFormValue(
        "taxRate",
        SIMULATOR_TAX_EXEMPTION_INFOS[filteredTaxExemptions[0]].value,
      );
    }

    setIsReady(true);
  }, [isShareCapitalized, project]);

  const underMinimumErrors = useMemo(() => {
    if (
      simulatorForm?.amountInCents <
      project?.sharesMechanicSettings?.minimumTicketAmountInCents
    ) {
      return [
        `Le montant minimum est de ${formatCurrency(
          project?.sharesMechanicSettings?.minimumTicketAmountInCents,
        )}`,
      ];
    }

    return [];
  }, [
    simulatorForm?.amountInCents,
    project?.sharesMechanicSettings?.minimumTicketAmountInCents,
  ]);

  function getTooltipDesc(): string {
    const scenario = SHARES_SCENARIOS.find(
      (s) => s.value === simulatorForm?.scenario,
    );

    if (!scenario) return "";

    const min =
      (project?.sharesMechanicSettings?.targetedYield?.minInPercent ?? 0) / 100;
    const medium =
      (project?.sharesMechanicSettings?.targetedYield?.mediumInPercent ?? 0) /
      100;
    const max =
      (project?.sharesMechanicSettings?.targetedYield?.maxInPercent ?? 0) / 100;
    const currentScenario = (simulatorData?.targetedYieldInPercent ?? 0) / 100;

    if (!currentScenario) return "";

    return `Potentiel de rendement : ${currentScenario} Les scenarios sont : Prudent = ${min} / Médian = ${medium} / Optimiste = ${max}. Les performances passées ne préjugent pas des performances futures.`;
  }

  if (!project) return null;

  return (
    <div className="flex flex-col overflow-hidden md:flex-row">
      <SimulatorPlaygroundBlock>
        <article>
          <h2 className="text-primary mb-3 font-bold">{`Montant de l'investissement`}</h2>
          <CurrencyInput
            value={simulatorForm?.amountInCents}
            errors={underMinimumErrors}
            onChange={(number) => setFormValue("amountInCents", number)}
          />
          <InputSlider
            valueChip={false}
            min={
              project?.sharesMechanicSettings?.minimumTicketAmountInCents / 100
            }
            max={maxValue}
            step={project?.sharesMechanicSettings?.unitPriceInCents / 100}
            className="-mt-[10px]"
            value={simulatorForm.amountInCents / 100}
            onChange={handleSliderChange}
          />
          <footer className="-mt-[15px] flex justify-between">
            <div>Ticket minimum</div>
            <div>
              {formatCurrency(
                project?.sharesMechanicSettings?.minimumTicketAmountInCents,
              )}
            </div>
          </footer>
        </article>

        {!isShareCapitalized && (
          <article>
            <div className="flex">
              <h2 className="text-primary mb-3 font-bold">Scénario</h2>
              <Tooltip description={getTooltipDesc()}>
                <Icon name="Info" size="XS" />
              </Tooltip>
            </div>
            <div className="flex flex-wrap gap-2">
              {SHARES_SCENARIOS.map((scenario) => (
                <ToggleChip
                  key={scenario.label}
                  label={scenario.label}
                  value={scenario.value}
                  selectedValue={simulatorForm?.scenario}
                  setSelectedValue={(value) => setFormValue("scenario", value)}
                />
              ))}
            </div>
          </article>
        )}

        <article>
          <div className="flex">
            <h2 className="text-primary mb-3 font-bold">Défiscalisation</h2>
            <Tooltip description="Réduction d'impôts accordée par l'État pour certains investissements. Il appartient à chacun de vérifier son éligibilité.">
              <Icon name="Info" size="XS" />
            </Tooltip>
          </div>
          <div className="flex flex-wrap gap-2">
            <ToggleChip
              label="Aucune"
              value={0}
              selectedValue={simulatorForm.taxRate}
              setSelectedValue={(value) => setFormValue("taxRate", value)}
            />
            {filteredTaxExemptions?.map((taxKey) => (
              <ToggleChip
                key={taxKey}
                label={SIMULATOR_TAX_EXEMPTION_INFOS[taxKey].label}
                value={SIMULATOR_TAX_EXEMPTION_INFOS[taxKey].value}
                selectedValue={simulatorForm.taxRate}
                setSelectedValue={(value) => setFormValue("taxRate", value)}
              />
            ))}
          </div>
        </article>
      </SimulatorPlaygroundBlock>

      <SimulatorResultsBlock>
        <div className="flex justify-between">
          <h2>Montant investi</h2>
          <span className="font-semibold">
            {formatCurrency(simulatorForm.amountInCents)}
          </span>
        </div>
        {simulatorForm?.taxRate !== 0 && (
          <div className="flex justify-between">
            <h2>Gain fiscal immédiat</h2>
            <span className="font-semibold">
              {formatCurrency(Number(simulatorData?.taxExemptionInCents))}
            </span>
          </div>
        )}
        <hr className="border-light-1 border" />

        {isShareCapitalized && (
          <div className="flex items-center justify-between">
            <h2>
              TRI capitalisé sur <span className="font-semibold">4 ans</span>
            </h2>
            <Chip
              label={simulatorData?.tri ? `X${simulatorData?.tri}%` : "-"}
              type="success"
            />
          </div>
        )}

        {!isShareCapitalized && (
          <div className="flex items-center justify-between">
            <h2>
              Potentiel de rendement à{" "}
              <span className="font-semibold">5 ans</span>
            </h2>
            <Chip
              label={`X${(simulatorData?.targetedYieldInPercent ?? 0) / 100}`}
              type="success"
            />
          </div>
        )}

        <div className="flex justify-between">
          <h2>Valorisation estimée de sortie</h2>
          <span className="font-semibold">
            {formatCurrency(Number(simulatorData?.targetedOutInCents))}
          </span>
        </div>
        <div className="mb-4 flex justify-between">
          <h2>Plus-value potentielle</h2>
          <span className="font-semibold">
            {formatCurrency(Number(simulatorData?.targetedGainInCents))}
          </span>
        </div>

        <SimulatorRoiBlock filters={simulatorForm} />
        <InvestButton className="mt-2" />
      </SimulatorResultsBlock>
    </div>
  );
}
