import React from "react";
import { ceil } from "lodash";

import {
  BondsMechanicSettings,
  EquitySavingPlan,
  Organization,
  PaymentMethodType,
  Project,
  SharesMechanicSettings,
  TaxExemptionMechanicsEnum,
  WMACustomer,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  formatCurrency,
  ORGANIZATIONS,
} from "@tudigo-monorepo/core-tudigo-utils";
import {
  FormContextProvider,
  getSmartFormComponents,
} from "@tudigo-monorepo/web-feature-form";
import {
  Alert,
  Button,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { WmaCreateInvestmentCustomerSelector } from "./wma-create-investment-customer-selector";
import { WmaCreateInvestmentOrganizationSelector } from "./wma-create-investment-organization-selector";
import { WmaCreateInvestmentProjectSelector } from "./wma-create-investment-project-selector";
import { WmaCustomerPeaAccountSelector } from "./wma-customer-pea-account-selector";
import { WmaCustomerPeaPmeAccountSelector } from "./wma-customer-pea-pme-account-selector";

export type CreateWmaInvestmentFormData = {
  projectId: string | null;
  userId: string | null;
  wmaCustomerId: string | null;
  organizationId: string | null;
  paymentMethod: PaymentMethodType | undefined;
  totalAmountInCents: number;
  equitySavingPlan?: {
    id: string;
  };
};

type WmaCreateInvestmentFormProps = {
  projects: Project[];
  selectedProjectId: string | null;
  handleProjectSelect: (projectId: string) => void;
  selectedCustomer: WMACustomer | null;
  handleCustomerSelect: (customer: WMACustomer) => void;
  selectedOrganization: Organization | null;
  setSelectedOrganization: React.Dispatch<
    React.SetStateAction<Organization | null>
  >;
  handleAutocompleteChange: (
    fieldName: string,
    value: string | null | undefined,
  ) => void;
  selectedProject: Project | null;
  selectedProjectMechanicSettings:
    | BondsMechanicSettings
    | SharesMechanicSettings
    | null;
  investmentFormData: CreateWmaInvestmentFormData;
  setInvestmentFormData: React.Dispatch<
    React.SetStateAction<CreateWmaInvestmentFormData>
  >;
  peaAccountSelected: boolean;
  setPeaAccountSelected: React.Dispatch<React.SetStateAction<boolean>>;
  peaPmeAccountSelected: boolean;
  setPeaPmeAccountSelected: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: () => void;
  isCreateWmaInvestment: boolean;
  checkIfFormIsValid: () => boolean;
};

export function WmaCreateInvestmentForm(props: WmaCreateInvestmentFormProps) {
  const {
    projects,
    selectedProjectId,
    handleProjectSelect,
    selectedCustomer,
    handleCustomerSelect,
    selectedOrganization,
    setSelectedOrganization,
    handleAutocompleteChange,
    selectedProject,
    selectedProjectMechanicSettings,
    investmentFormData,
    setInvestmentFormData,
    peaAccountSelected,
    setPeaAccountSelected,
    peaPmeAccountSelected,
    setPeaPmeAccountSelected,
    handleSubmit,
    isCreateWmaInvestment,
    checkIfFormIsValid,
  } = props;
  const { t } = useTranslation();
  const { SmartCurrencyField } =
    getSmartFormComponents<CreateWmaInvestmentFormData>();

  const handleChangePeaAccountCheckbox = (value: boolean) => {
    setPeaAccountSelected(value);
    setPeaPmeAccountSelected(false);
    if (value) {
      setInvestmentFormData((prevState) => ({
        ...prevState,
        equitySavingPlan: {
          id: selectedOrganization?.equitySavingPlan?.id as string,
        },
        paymentMethod: PaymentMethodType.PEA,
      }));
    } else {
      setInvestmentFormData((prevState) => ({
        ...prevState,
        equitySavingPlan: undefined,
        paymentMethod: PaymentMethodType.WIRE,
      }));
    }
  };

  const handleChangePeaPmeAccountCheckbox = (value: boolean) => {
    setPeaPmeAccountSelected(value);
    setPeaAccountSelected(false);
    if (value) {
      setInvestmentFormData((prevState) => ({
        ...prevState,
        equitySavingPlan: {
          id: selectedOrganization?.smallBusinessEquitySavingPlan?.id as string,
        },
        paymentMethod: PaymentMethodType.PEA_PME,
      }));
    } else {
      setInvestmentFormData((prevState) => ({
        ...prevState,
        equitySavingPlan: undefined,
        paymentMethod: PaymentMethodType.WIRE,
      }));
    }
  };

  const renderPeaAccountsSection = () => {
    const projectIsEligibleForPea =
      selectedProject?.taxExemptionMechanics?.includes(
        TaxExemptionMechanicsEnum.PEA,
      );
    const organizationIsEligibleForPea =
      selectedOrganization?.equitySavingPlan?.invalidatedAt === null &&
      selectedOrganization.internalKycStatus ===
        ORGANIZATIONS.INTERNAL_KYC_STATUS.APPROVED;

    if (projectIsEligibleForPea && organizationIsEligibleForPea) {
      return (
        <WmaCustomerPeaAccountSelector
          equitySavingPlan={
            selectedOrganization?.equitySavingPlan as EquitySavingPlan
          }
          peaAccountSelected={peaAccountSelected}
          handleChangePeaAccountCheckbox={handleChangePeaAccountCheckbox}
        />
      );
    }
  };

  const renderPeaPmeAccountsSection = () => {
    const projectIsEligibleForPeaPme =
      selectedProject?.taxExemptionMechanics?.includes(
        TaxExemptionMechanicsEnum.PEA_PME,
      );
    const organizationIsEligibleForPeaPme =
      selectedOrganization?.smallBusinessEquitySavingPlan?.invalidatedAt ===
        null &&
      selectedOrganization.internalKycStatus ===
        ORGANIZATIONS.INTERNAL_KYC_STATUS.APPROVED;

    if (projectIsEligibleForPeaPme && organizationIsEligibleForPeaPme) {
      return (
        <WmaCustomerPeaPmeAccountSelector
          smallBusinessEquitySavingPlan={
            selectedOrganization?.smallBusinessEquitySavingPlan as EquitySavingPlan
          }
          peaPmeAccountSelected={peaPmeAccountSelected}
          handleChangePeaPmeAccountCheckbox={handleChangePeaPmeAccountCheckbox}
        />
      );
    }
  };

  const shouldRenderPeaPeaPmeAccountsSection = () => {
    const projectIsEligibleForPeaPme =
      selectedProject?.taxExemptionMechanics?.includes(
        TaxExemptionMechanicsEnum.PEA_PME,
      );
    const organizationIsEligibleForPeaPme =
      selectedOrganization?.smallBusinessEquitySavingPlan?.invalidatedAt ===
        null &&
      selectedOrganization.internalKycStatus ===
        ORGANIZATIONS.INTERNAL_KYC_STATUS.APPROVED;

    const projectIsEligibleForPea =
      selectedProject?.taxExemptionMechanics?.includes(
        TaxExemptionMechanicsEnum.PEA,
      );
    const organizationIsEligibleForPea =
      selectedOrganization?.equitySavingPlan?.invalidatedAt === null &&
      selectedOrganization.internalKycStatus ===
        ORGANIZATIONS.INTERNAL_KYC_STATUS.APPROVED;

    return (
      (projectIsEligibleForPeaPme && organizationIsEligibleForPeaPme) ||
      (projectIsEligibleForPea && organizationIsEligibleForPea)
    );
  };

  return (
    <FormContextProvider
      formData={investmentFormData}
      setFormData={setInvestmentFormData}
      error={null}
    >
      <div className="flex flex-col gap-6 py-6">
        <div className="rounded-lg border px-10 py-8">
          <div className="flex w-full flex-col gap-4 md:w-[450px]">
            <WmaCreateInvestmentProjectSelector
              projects={projects}
              selectedProjectId={selectedProjectId}
              handleProjectSelect={handleProjectSelect}
            />
            <WmaCreateInvestmentCustomerSelector
              handleCustomerSelect={handleCustomerSelect}
            />
            <WmaCreateInvestmentOrganizationSelector
              selectedCustomer={selectedCustomer}
              selectedOrganization={selectedOrganization}
              setSelectedOrganization={setSelectedOrganization}
              handleAutocompleteChange={handleAutocompleteChange}
            />
            {shouldRenderPeaPeaPmeAccountsSection() && (
              <div className="border-light-1 bg-light-3 flex flex-col gap-2 rounded-lg border p-3">
                <div className="flex items-center gap-1">
                  <Typography variant="body3-semibold">Options </Typography>
                  <Typography variant="caption1-regular" className=" mt-0.5">
                    (ne pas cocher pour un virement bancaire classique) :
                  </Typography>
                </div>
                {renderPeaAccountsSection()}
                {renderPeaPmeAccountsSection()}
              </div>
            )}
            <SmartCurrencyField
              name="totalAmountInCents"
              label="Montant de l'investissement"
              disabled={
                selectedProjectId === null ||
                selectedProjectMechanicSettings === null
              }
              required
            />
            {selectedProjectMechanicSettings && (
              <div className="flex flex-col gap-4">
                <Typography variant="caption1-regular">
                  {`${t(
                    "investments.back_office.create_investment_page.form.investment_detail.unit_price",
                  )} ${formatCurrency(
                    selectedProjectMechanicSettings.unitPriceInCents,
                  )}`}
                </Typography>
                <Typography variant="caption1-regular">
                  {`Ticket minimum : ${formatCurrency(
                    selectedProjectMechanicSettings.minimumTicketAmountInCents,
                  )}`}
                </Typography>
                <div className="bg-success-light flex justify-between rounded-lg p-4">
                  <Typography variant="caption1-regular">
                    Nombre de titres
                  </Typography>
                  <Typography variant="caption1-regular">
                    {ceil(
                      investmentFormData.totalAmountInCents /
                        selectedProjectMechanicSettings.unitPriceInCents,
                      2,
                    )}
                  </Typography>
                </div>
                {investmentFormData.totalAmountInCents %
                  selectedProjectMechanicSettings.unitPriceInCents !==
                  0 && (
                  <Alert
                    variant="info"
                    size="F"
                    title="Montant saisi invalide"
                    description="Merci de saisir un nombre de titre entier"
                    icon="Info"
                  />
                )}
                {investmentFormData.totalAmountInCents <
                  selectedProjectMechanicSettings.minimumTicketAmountInCents && (
                  <Alert
                    variant="info"
                    size="F"
                    title="Montant saisi invalide"
                    description="Le montant saisi est inférieur au ticket minimum"
                    icon="Info"
                  />
                )}
              </div>
            )}
            <Button
              onClick={handleSubmit}
              isLoading={isCreateWmaInvestment}
              iconRight="ArrowRight"
              disabled={!checkIfFormIsValid() || isCreateWmaInvestment}
              label="Valider"
              variant="primary"
              className="mt-4 sm:w-fit"
            />
          </div>
        </div>
      </div>
    </FormContextProvider>
  );
}
