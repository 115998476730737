import React from "react";
import {
  flexRender,
  Row as TanstackRow,
  Table as TanstackTable,
} from "@tanstack/react-table";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Icon } from "../icons/icon";
import { Typography } from "../typography";

interface TableCardRow {
  label: string;
  value: React.ReactNode | React.JSX.Element;
}

export interface TableCardListProps<T> extends React.ComponentProps<"div"> {
  table: TanstackTable<T>;
  cardClassName?: string;
  onCardClick?: (card: T) => void;
  isCardAvailable?: (card: T) => boolean;
}

export function TableCardList<T>(props: TableCardListProps<T>) {
  const { table, className, cardClassName, onCardClick, isCardAvailable } =
    props;

  const tableColumnsHeaders = table.getHeaderGroups().map((headerGroup) => {
    return headerGroup.headers.map((header) => {
      if (header.column.columnDef.header) {
        return header.column.columnDef.header.toString();
      }

      return "-";
    });
  })[0];

  const getCardDataFromTableRow = (row: TanstackRow<T>) => {
    const rowData: TableCardRow[] = [];
    row.getVisibleCells().forEach((cell, index) => {
      rowData.push({
        label: tableColumnsHeaders[index],
        value: flexRender(cell.column.columnDef.cell, cell.getContext()),
      });
    });

    return rowData;
  };

  return (
    <div
      className={cn(
        "table-card-list flex w-full flex-col gap-y-2.5",
        className,
      )}
    >
      {table.getRowModel().rows.map((tableRow, tableRowIndex) => {
        if (
          isCardAvailable?.(tableRow.original) ||
          isCardAvailable === undefined
        ) {
          const rowData = getCardDataFromTableRow(tableRow);

          return (
            <ul
              key={tableRowIndex}
              className="table-card border-light-2 flex w-full flex-col rounded-lg border px-2.5 py-1.5"
            >
              {rowData.map((row, rowIndex) => (
                <li
                  key={rowIndex}
                  className={cn(
                    "flex w-full items-center justify-between gap-x-3 px-5 py-3",
                    { "border-light-2 border-b py-3.5": rowIndex === 0 },
                    cardClassName,
                  )}
                >
                  {rowIndex === 0 ? (
                    <button
                      className="flex w-full items-center justify-between"
                      onClick={() => {
                        if (onCardClick) {
                          onCardClick(tableRow.original);
                        }
                      }}
                    >
                      <span>{row.value}</span>
                      <Icon
                        name="ChevronRight"
                        primaryColor={themeColors["dark-2"]}
                      />
                    </button>
                  ) : (
                    <>
                      <Typography
                        variant="body3-regular"
                        className="text-dark-2"
                      >
                        {row.label}
                      </Typography>
                      <span>{row.value}</span>
                    </>
                  )}
                </li>
              ))}
            </ul>
          );
        }

        return null;
      })}
    </div>
  );
}
