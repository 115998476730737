import {
  ChoicesSliderInput,
  RangeSliderInput,
  SelectInput,
  SelectOption,
} from "@tudigo-monorepo/core-tudigo-api-models";
import {
  formatCurrency,
  formatLargeCurrency,
} from "@tudigo-monorepo/core-tudigo-utils";
import { InputSliderIndicator } from "@tudigo-monorepo/web-tudigo-components";

import { FormChoiceSliderInputPlugin } from "../input-plugins/form-choice-slider-plugin";
import { FormHiddenInputPlugin } from "../input-plugins/form-hidden-input-plugin";
import { FormMultiSelectInputPlugin } from "../input-plugins/form-multi-select-input-plugin";
import { FormRangeSliderInputPlugin } from "../input-plugins/form-range-slider-plugin";
import { FormSelectInputPlugin } from "../input-plugins/form-select-input-plugin";
import { FormSwitchInputPlugin } from "../input-plugins/form-switch-input-plugin";

export function getInputPlugins() {
  return [
    FormChoiceSliderInputPlugin,
    FormHiddenInputPlugin,
    FormMultiSelectInputPlugin,
    FormRangeSliderInputPlugin,
    FormSelectInputPlugin,
    FormSwitchInputPlugin,
  ];
}

type Ranges = ([number, number] | [number])[] | [];

const getRanges = (input: RangeSliderInput): Ranges => {
  const { parameters } = input;
  if (!parameters) return [];

  return [
    [parameters.min],
    [parameters.max / 10, parameters.max / 5],
    [parameters.max / 2.5, parameters.max / 2],
    [parameters.max],
  ];
};

const formatRangeLabel = (
  rangeItem: [number, number] | [number],
  isFirst: boolean,
  isLast: boolean,
): string => {
  const isSingleValue = rangeItem.length === 1;

  if (isSingleValue) {
    const leftPart = isFirst ? "<" : null;
    const rigthPart = isLast ? "+" : null;

    return `
      ${leftPart ?? ""}
      ${formatCurrency(rangeItem[0])}${rigthPart ?? ""}
    `;
  } else {
    const leftPart = isFirst ? "<" : formatCurrency(rangeItem[0]);
    const rigthPart = formatCurrency(rangeItem[1]);
    const separator = !isFirst && !isLast ? "-" : null;

    return `${leftPart} ${separator ?? ""} ${rigthPart}`;
  }
};

const getItemsFromRanges = <SliderIndicatorType, SelectOptionType>(
  ranges: Ranges,
  isSelectInput = false,
) => {
  return ranges.map((range, index) => {
    const isFirst = index === 0;
    const isLast = index === ranges.length - 1;
    const label = formatRangeLabel(range, isFirst, isLast);
    const value = range[1] ? range[1] : range[0];
    const item = { label, value };

    return isSelectInput
      ? ({ ...item, bullet: null } as SelectOptionType)
      : ({ ...item } as SliderIndicatorType);
  });
};

export const getSliderIndicators = (
  input: ChoicesSliderInput | RangeSliderInput,
  sliderMax: number,
): InputSliderIndicator[] | undefined => {
  const { parameters } = input;
  if (!parameters) return;

  let indicators: InputSliderIndicator[] | undefined = undefined;

  if (
    input.type === "choices_slider" &&
    "choices" in parameters &&
    parameters.choices !== null
  ) {
    indicators = parameters.choices.map((choice, index) => ({
      label: choice.label || "",
      value: typeof choice.value === "number" ? choice.value : index,
      icon: choice.bullet || undefined,
    }));
  } else if (input.type === "range_slider") {
    if ("multiRanges" in parameters && parameters.multiRanges) {
      const ranges = getRanges(input);
      indicators = getItemsFromRanges(ranges);
    } else {
      const firstValue: number = sliderMax / 10;
      const secondValue: number = sliderMax / 4;
      const thirdValue: number = sliderMax / 2;

      indicators = [
        {
          label: formatLargeCurrency(firstValue * 100).toUpperCase(),
          value: firstValue,
        },
        {
          label: formatLargeCurrency(secondValue * 100).toUpperCase(),
          value: secondValue,
        },
        {
          label: formatLargeCurrency(thirdValue * 100).toUpperCase(),
          value: thirdValue,
        },
        {
          label: `${formatLargeCurrency(sliderMax * 100).toUpperCase()} +`,
          value: sliderMax,
        },
      ];
    }
  }

  return indicators;
};

export const fromSliderToSelect = (
  input: ChoicesSliderInput | RangeSliderInput,
): SelectInput | null => {
  const { parameters } = input;
  if (!parameters) return null;

  let selectChoices: SelectOption[] = [];

  if (input.type === "range_slider") {
    const ranges = getRanges(input);
    selectChoices = getItemsFromRanges(ranges, true);
  } else if (
    input.type === "choices_slider" &&
    "choices" in parameters &&
    parameters.choices !== null
  ) {
    selectChoices = parameters.choices.map((choice) => {
      return {
        label: choice.label || "",
        value: choice.value,
        bullet: choice.bullet,
      };
    });
  }

  return {
    ...input,
    type: "select",
    parameters: {
      choices: selectChoices,
      maxChoices: parameters.maxChoices,
      initialValue: parameters.initialValue,
    },
  };
};
