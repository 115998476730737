import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import {
  PageEnum,
  SessionAvatar,
  SessionCard,
  SessionPanel,
  useSpaceBusiness,
  useUserBusiness,
} from "@tudigo-monorepo/tudigo-session";
import {
  Icon,
  NavigationItem,
  Topbar,
  TudigoLogoRebrand,
  TudigoLogoRebrandSmall,
} from "@tudigo-monorepo/web-tudigo-components";

import { ConnectedMenuNavigation } from "./connected-menu-navigation";

export type ConnectedTopbarProps = {
  navigation: NavigationItem[];
};

export function ConnectedTopbar({ navigation }: ConnectedTopbarProps) {
  const { user } = useUserBusiness();
  const { currentSpace, currentPagePaths } = useSpaceBusiness();
  const location = useLocation();

  const [config, setConfig] = useState({
    isExpanded: false,
    isSessionPanelOpen: false,
  });

  useEffect(() => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      isExpanded: false,
    }));
  }, [location.pathname]);

  return (
    <Topbar expanded={config.isExpanded}>
      {config.isSessionPanelOpen && (
        <SessionPanel
          onClose={() => {
            setConfig({
              ...config,
              isSessionPanelOpen: false,
            });
          }}
        />
      )}

      <div className="flex h-full w-full flex-col items-start justify-start gap-4 bg-white">
        <div
          className={cn(
            "h-[60px] max-h-[60px] w-full",
            "flex items-center justify-between gap-4",
            "px-4",
            "border-b border-[#CCCDD2] bg-white",
            {
              "h-full": !config.isExpanded,
              "min-h-[60px]": config.isExpanded,
            },
          )}
        >
          {!config.isExpanded && (
            <SessionAvatar profileImage={user?.profileImage} />
          )}

          <Link to={currentPagePaths[PageEnum.HOME]}>
            {config.isExpanded ? (
              <TudigoLogoRebrand />
            ) : (
              <TudigoLogoRebrandSmall />
            )}
          </Link>

          <button
            onClick={() =>
              setConfig((prevConfig) => ({
                ...prevConfig,
                isExpanded: !prevConfig.isExpanded,
              }))
            }
          >
            <Icon
              name={config.isExpanded ? "NavCloser" : "NavOpener"}
              primaryColor={themeColors["dark-2"]}
            />
          </button>
        </div>

        {config.isExpanded && (
          <>
            <SessionCard
              currentSpace={currentSpace}
              reducedDisplay={!config.isExpanded}
              className="ml-4"
              onClick={() => {
                setConfig({
                  ...config,
                  isSessionPanelOpen: true,
                });
              }}
            />
            <ConnectedMenuNavigation
              navigation={navigation}
              reducedDisplay={!config.isExpanded}
            />
          </>
        )}
      </div>
    </Topbar>
  );
}
