import { ReactNode } from "react";

import {
  isComingProject,
  isFinishedProject,
  isNewProject,
  isPrefinishedProject,
  Project,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { getDate } from "@tudigo-monorepo/core-tudigo-utils";
import { useProjectBusiness } from "@tudigo-monorepo/web-feature-projects";

import { Icon } from "../../../old/icons/icon";
import { ProjectFlagVariant } from "./projet-flag-rebrand";

const TRANSLATION_BASE_KEY = "component.project_card.header.flag";

export const useProjectFlagProps = (
  project?: Project,
): {
  label: string | null;
  variant: ProjectFlagVariant | null;
  timerIcon: ReactNode;
} => ({
  label: useProjectFlagLabel(project),
  variant: useProjectFlagVariant(project),
  timerIcon: useProjectFlagTimerIcon(project),
});

function useProjectFlagLabel(project?: Project): string | null {
  const { isLastDays, endDate, daysLeft, hoursLeft } =
    useProjectBusiness(project);
  const { t } = useTranslation();

  if (isNewProject(project)) {
    return t(`${TRANSLATION_BASE_KEY}.new`);
  }

  if (isComingProject(project)) {
    return t(`${TRANSLATION_BASE_KEY}.coming_soon_rebrand`);
  }

  if (isPrefinishedProject(project)) {
    return t(`${TRANSLATION_BASE_KEY}.financed`);
  }

  if (isFinishedProject(project) && endDate) {
    return t(`${TRANSLATION_BASE_KEY}.financed_in`, {
      year: getDate(endDate).getFullYear(),
    });
  }

  if (isLastDays && endDate) {
    if (daysLeft >= 1) {
      return t(`${TRANSLATION_BASE_KEY}.days_left`, { daysLeft });
    }

    if (new Date() > new Date(endDate)) {
      return null;
    }

    return t(`${TRANSLATION_BASE_KEY}.hours_left`, { hoursLeft });
  }

  if (!project || !project.marketingSettings?.flag) {
    return t(`${TRANSLATION_BASE_KEY}.in_progress`);
  }

  return null;
}

function useProjectFlagVariant(project?: Project): ProjectFlagVariant | null {
  const { isLastDays, endDate, daysLeft } = useProjectBusiness(project);

  if (isNewProject(project)) {
    return "new";
  }

  if (isComingProject(project)) {
    return "coming";
  }

  if (isFinishedProject(project) || isPrefinishedProject(project)) {
    return "success";
  }

  if (isLastDays) {
    if (daysLeft >= 1) {
      return "warning";
    }

    return "destructive";
  }

  if (!project || !project.marketingSettings?.flag) {
    return "progress";
  }

  return null;
}

function useProjectFlagTimerIcon(project?: Project): ReactNode {
  const { isLastDays, isLastHours } = useProjectBusiness(project);
  const color = isLastHours ? "error" : "warning";

  if (
    (!isLastHours && !isLastDays) ||
    isPrefinishedProject(project) ||
    isFinishedProject(project)
  ) {
    return null;
  }

  return <Icon name="Timer" size="XS" primaryColor={themeColors[color]} />;
}
