import { useState } from "react";

import { OrganizationPerson } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  FormContextProvider,
  getSmartFormComponents,
} from "@tudigo-monorepo/web-feature-form";

import { KycCollapseFormGroup } from "../../../shared/components/kyc-collapse-form-group";
import { KycErrorChip } from "../../../shared/components/kyc-error-chip";
import { OrganizationPersonFormContent } from "../../../shared/organization-person-form-content";

type BeneficialOwnerKycCollapseFromGroupProps = {
  index: number;
  beneficialOwner: OrganizationPerson;
  removeBeneficialOwner: (beneficialOwnerIndex: number) => void;
  beneficialOwnersFormData: OrganizationPerson[];
  setBeneficialOwnersFormData: (beneficialOwners: OrganizationPerson[]) => void;
};
export function BeneficialOwnerKycCollapseFromGroup(
  props: BeneficialOwnerKycCollapseFromGroupProps,
) {
  const {
    index,
    beneficialOwner,
    removeBeneficialOwner,
    beneficialOwnersFormData,
    setBeneficialOwnersFormData,
  } = props;

  const [beneficialOwnerFormData, setBeneficialOwnerFormData] =
    useState<OrganizationPerson>(beneficialOwner);

  const { SmartTextField } = getSmartFormComponents<OrganizationPerson>();
  const { t } = useTranslation();

  const handleBeneficialOwnerFormDataChange = (
    formData: OrganizationPerson,
  ) => {
    if (formData.sharesCount && formData.sharesCount > 100)
      formData.sharesCount = 100;

    if (formData.sharesCount && formData.sharesCount < 0)
      formData.sharesCount = 0;

    const ownerFormData = {
      ...formData,
      sharesCount: parseInt(formData.sharesCount as unknown as string), // Input type number return a string value...
    };

    setBeneficialOwnerFormData(ownerFormData);

    const updatedOwners = [...beneficialOwnersFormData];

    if (updatedOwners.length > 0) {
      updatedOwners[index] = ownerFormData;
    }

    setBeneficialOwnersFormData(updatedOwners);
  };

  const isSharesCountError =
    beneficialOwner.sharesCount !== null && beneficialOwner.sharesCount < 25;

  return (
    <FormContextProvider
      error={null}
      formData={beneficialOwnerFormData}
      setFormData={handleBeneficialOwnerFormDataChange}
    >
      <div className="beneficial-owner-list-elements w-full" key={index}>
        <KycCollapseFormGroup
          content={
            <>
              <OrganizationPersonFormContent
                organizationPerson={beneficialOwnerFormData}
                updateOrganizationPerson={setBeneficialOwnerFormData}
                onFormDataUpdated={handleBeneficialOwnerFormDataChange}
              />
              <div className="bg-light-3 rounded-lg border p-6">
                <div className="md:w-[350px]">
                  <SmartTextField
                    name="sharesCount"
                    label={t(
                      "kyc_funnel.organization_person_form.label.shares_count",
                    )}
                    required
                    type="number"
                    errors={
                      isSharesCountError
                        ? [
                            t(
                              "kyc_funnel.organization_person_form.label.shares_count.error",
                            ),
                          ]
                        : undefined
                    }
                  />
                </div>
              </div>
            </>
          }
          title={`${t(
            "kyc_funnel.step_company_information.collapse_beneficial_owner.title",
          )} ${
            beneficialOwner.sharesCount
              ? `${beneficialOwner.sharesCount}%`
              : t(
                  "kyc_funnel.step_company_information.collapse_beneficial_owner.shares_count.undefined.label",
                )
          }`}
          subtitle={`${
            beneficialOwner.firstName
          } ${beneficialOwner.lastName.toUpperCase()}`}
          chipChildren={isSharesCountError ? <KycErrorChip /> : null}
          iconBtn={{
            singleIcon: "Delete",
            variant: "destructive",
            onClick: () => removeBeneficialOwner(index),
          }}
        />
      </div>
    </FormContextProvider>
  );
}
