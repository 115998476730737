import { Outlet, useParams } from "react-router-dom";

import { isFailedProject } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  useProjectGoalChipProps,
  useProjectStatusChipProps,
} from "@tudigo-monorepo/web-feature-projects";
import { useGetInvestmentById } from "@tudigo-monorepo/web-tudigo-api-client";
import { Alert, PageHead, Tabs } from "@tudigo-monorepo/web-tudigo-components";

import { InvestmentQuickActionsMenu } from "../../shared/components/investment-quick-actions-menu";
import { useGetInvestmentManagerPageTabs } from "./hooks/get-investment-manager-page-tabs";
import { InvestmentManagerPageContext } from "./hooks/use-investment-manager-page-context";

export const managerInvestmentQueryFields =
  "id,global_status,payment_method_type,payment_status,total_amount_in_cents,payment_date,investor_organization.fields(id,type,name,organization_representative.fields(email,user.fields(id)),organization_bank_accounts,equity_saving_plan,small_business_equity_saving_plan,pms_account.fields(internal_id,balance_in_cents)),project,equity_saving_plan.fields(bank,account_number)";

export function InvestmentManagerPage() {
  const { t } = useTranslation();
  const investmentId = useParams().investmentId as string;

  const { data: investment } = useGetInvestmentById({
    investmentId: investmentId,
    query: {
      fields: managerInvestmentQueryFields,
    },
  });
  const { isAdmin } = useUserBusiness();
  const tabs = useGetInvestmentManagerPageTabs();
  const chipStatusProps = useProjectStatusChipProps(investment?.project);
  const chipGoalProps = useProjectGoalChipProps(investment?.project);

  if (!investment) {
    return;
  }

  const project = investment.project;

  return (
    <div id="my-investments-page" className="w-full">
      <PageHead
        title={project?.content?.name ?? ""}
        levels={[
          {
            url: "/investments/list/all",
            text: t("investments.my_investments"),
            iconName: "Invest",
          },
        ]}
        chipsTop={
          project?.status === "finished" && chipGoalProps
            ? [chipStatusProps, chipGoalProps]
            : [chipStatusProps]
        }
        withEndLine
      >
        {isAdmin && (
          <InvestmentQuickActionsMenu
            investment={investment}
            label={t(
              "projects.back_office.edit_project_page.contextual_menu.label",
            )}
          />
        )}
      </PageHead>

      {isFailedProject(project) && (
        <Alert
          variant="warning"
          icon="Invest"
          size="M"
          title={t("investments.my_investments.project_failed.alert.title")}
          description={t(
            "investments.my_investments.project_failed.alert.desc",
          )}
          className="mx-6"
        />
      )}

      <Tabs tabs={tabs} className="px-6" />

      <Outlet
        context={
          {
            project,
            investment,
          } satisfies InvestmentManagerPageContext
        }
      />
    </div>
  );
}
