import { Chip } from "../chip";
import { ChipCount } from "../chip-count";
import { MetricsChipProps } from "../metrics-card";
import { Typography } from "../typography";

export type MetricsHeadingProps = {
  title: string;
  subtitle?: string;
  firstChip?: MetricsChipProps;
  secondChip?: MetricsChipProps;
};

export function MetricsHeading(props: MetricsHeadingProps) {
  const { title, subtitle, firstChip, secondChip } = props;

  return (
    <div className="metrics-heading flex flex-col gap-y-1.5">
      {firstChip ? (
        firstChip.count ? (
          <ChipCount {...firstChip} />
        ) : (
          <Chip {...firstChip} />
        )
      ) : null}
      <div className="flex flex-wrap justify-between gap-x-4 gap-y-2 min-[400px]:items-center">
        <h4 className="text-dark-1 font-sans font-bold">{title}</h4>

        {secondChip ? (
          secondChip.count ? (
            <ChipCount {...secondChip} />
          ) : (
            <Chip {...secondChip} />
          )
        ) : null}
      </div>
      {subtitle ? <Typography>{subtitle}</Typography> : null}
    </div>
  );
}
