import { CMSParagraphBlock } from "@tudigo-monorepo/core-tudigo-api-models";
import { ThinLayout } from "@tudigo-monorepo/web-tudigo-components";

export const ParagraphBlock = (props: { block: CMSParagraphBlock }) => {
  const { block } = props;

  if (!block.data) return null;

  return (
    <ThinLayout className="rte-content text-dark-1 text-sm">
      <p dangerouslySetInnerHTML={{ __html: block.data.text }} />
    </ThinLayout>
  );
};
