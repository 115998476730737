import { useEffect } from "react";
import * as React from "react";
import { createPortal } from "react-dom";
import { useIntl } from "react-intl";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { Button } from "../button";
import { OutClickBox } from "../out-click-box/out-click-box";

type SlidingPanelProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children?: React.ReactNode;
};

export function SlidingPanel({
  children,
  isOpen,
  setIsOpen,
}: SlidingPanelProps) {
  const intl = useIntl();

  useEffect(() => {
    const body = document.body;
    if (isOpen) {
      body.classList.add("overflow-hidden");
    } else {
      body.classList.remove("overflow-hidden");
    }

    return () => {
      body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  const slidingPanel = (
    <div
      className={cn("w-full overflow-hidden", {
        "fixed inset-0 z-30 bg-black bg-opacity-50 backdrop-blur-sm": isOpen,
      })}
    >
      <OutClickBox onOutClick={() => setIsOpen(false)}>
        <div
          className={cn(
            "fixed right-0 top-0 z-40 h-dvh w-full overflow-hidden bg-white shadow-[0_4px_16px_0_rgba(0,4,32,0.10)] transition-transform duration-500 ease-in-out lg:w-3/4",
            {
              "translate-x-0": isOpen,
              "translate-x-full": !isOpen,
            },
          )}
        >
          <div className="px-6 py-4">
            <Button
              variant="tertiary"
              label={intl.formatMessage({ id: "close" })}
              iconLeft="NavCloser"
              onClick={() => setIsOpen(!isOpen)}
            />
          </div>
          <div className="border-light-2 mx-6 border-b"></div>
          <div className="h-full overflow-y-auto px-6 py-4">{children}</div>
        </div>
      </OutClickBox>
    </div>
  );

  return createPortal(slidingPanel, document.body);
}
