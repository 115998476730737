import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import {
  ChangeInvestmentStatusRequest,
  Investment,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient, getQueryDetailKey, getQueryListKey } from "../../..";

type ChangeInvestmentStatusRequestParams = {
  investmentId: Investment["id"];
  investmentFields?: string;
  data: ChangeInvestmentStatusRequest;
};

export const changeInvestmentStatusRequest = async (
  params: ChangeInvestmentStatusRequestParams,
) => {
  const apiClient = getApiClient();

  const response =
    await apiClient.apiV1.investments.changeInvestmentStatusRequest(
      params.investmentId,
      params.data,
    );

  return response.data;
};

export const useChangeInvestmentStatusRequestMutation = (
  options?: UseMutationOptions<
    TudigoResponse<Investment>,
    TudigoError,
    ChangeInvestmentStatusRequestParams
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (params: ChangeInvestmentStatusRequestParams) =>
      changeInvestmentStatusRequest(params),
    onSuccess: (...props) => {
      const [, params] = props;
      const investmentChangesQueryKey = getQueryListKey({
        key: `${QUERY_KEYS.INVESTMENT_STATUS_CHANGES}_${params.investmentId!}`,
      });
      const investmentQueryKey = getQueryDetailKey({
        key: `${QUERY_KEYS.INVESTMENTS}`,
        id: params.investmentId!,
        fields: params.investmentFields,
      });
      queryClient.invalidateQueries({
        queryKey: investmentChangesQueryKey,
      });
      queryClient.invalidateQueries({
        queryKey: investmentQueryKey,
      });
      options?.onSuccess?.(...props);
    },
  });
};
