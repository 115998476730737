import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useScreenSize } from "@tudigo-monorepo/core-tudigo-utils";

import {
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  CarouselRoot,
} from "./carousel";

export function ResponsiveBlocksToCarousel({
  blocks,
  Component,
  itemMax,
}: {
  blocks: any[];
  Component: any;
  itemMax: number;
}) {
  const screenSizes = useScreenSize();

  const getGridColClass = () => {
    switch (blocks.length) {
      case 1:
      default:
        return "grid-cols-1";
      case 2:
        return "grid-cols-2";
      case 3:
        return "grid-cols-3";
    }
  };

  const getFlexBasisClass = () => {
    switch (itemMax) {
      case 1:
      default:
        return "";
      case 2:
        return "md:basis-1/2";
      case 3:
        return "md:basis-1/3";
    }
  };

  if (screenSizes.width < 768 || blocks.length > itemMax) {
    return (
      <CarouselRoot
        opts={{
          slidesToScroll: "auto",
        }}
      >
        {blocks && blocks?.length > 1 && (
          <div className="mb-4 flex justify-end gap-1">
            <CarouselPrevious />
            <CarouselNext />
          </div>
        )}
        <CarouselContent className="mb-4">
          {blocks?.map((block, index) => (
            <CarouselItem key={index} className={getFlexBasisClass()}>
              <Component {...block} />
            </CarouselItem>
          ))}
        </CarouselContent>
      </CarouselRoot>
    );
  }

  return (
    blocks.length <= itemMax && (
      <div className={cn("grid gap-4", getGridColClass())}>
        {blocks.map((block, index) => (
          <Component key={index} {...block} />
        ))}
      </div>
    )
  );
}
