import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export type AuthState = {
  accessToken: string | null;
  refreshToken: string | null;
  accessTokenExpirationDate: number | null;
  impersonateId: string | null;
};

export type AuthActions = {
  setAccessToken: (accessToken: string) => void;
  setAuth: (authResponse: AuthState) => void;
  setImpersonateId: (impersonateId: string | null) => void;
  reset: () => void;
};

const defaultInitialState: AuthState = {
  accessToken: null,
  refreshToken: null,
  accessTokenExpirationDate: null,
  impersonateId: null,
};

const useAuthStore = create<AuthState & AuthActions>()(
  persist(
    (set) => ({
      ...defaultInitialState,
      setAccessToken: (accessToken) => set(() => ({ accessToken })),
      setAuth: (authResponse) => set(authResponse),
      reset: () => set(defaultInitialState),
      setImpersonateId: (impersonateId) => set(() => ({ impersonateId })),
    }),
    {
      name: "auth-store",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export const useImpersonateId = () =>
  useAuthStore((state) => state.impersonateId);

export const useSetImpersonateId = () =>
  useAuthStore((state) => state.setImpersonateId);

export default useAuthStore;
