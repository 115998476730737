import { useNavigate } from "react-router-dom";

import {
  ProjectDocumentEnum,
  SharesProject,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";
import { SmartCurrencyField } from "@tudigo-monorepo/web-feature-form";
import {
  Button,
  Title,
  Toggle,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import {
  getInvestmentAmountError,
  InvestmentAmountErrorEnum,
} from "../../../../../investment-utils";
import { InvestmentAmountFormData } from "../investment-amount-form";

type SharesInvestmentAmountFormContentProps = {
  project: SharesProject;
  investmentFormData: InvestmentAmountFormData;
  toggleAgreementAccepted: boolean;
  setToggleAgreementAccepted: (accepted: boolean) => void;
};

export function SharesInvestmentAmountFormContent(
  props: SharesInvestmentAmountFormContentProps,
) {
  const {
    project,
    investmentFormData,
    toggleAgreementAccepted,
    setToggleAgreementAccepted,
  } = props;

  const { minimumTicketAmountInCents, unitPriceInCents } =
    project.sharesMechanicSettings;
  const { investmentAmount } = investmentFormData;
  const navigate = useNavigate();

  const shareHoldersAgreement = project.projectDocuments?.find(
    (doc) => doc.type === ProjectDocumentEnum.SHARE_HOLDER_AGREEMENT,
  );

  const ErrorMessage = () => {
    const investmentAmountError = getInvestmentAmountError(
      investmentFormData.investmentAmount,
      project,
    );
    switch (investmentAmountError) {
      case InvestmentAmountErrorEnum.SHOULD_BE_MULTIPLE:
        return (
          <Typography variant="body3-regular" className="text-error">
            {`Le montant de l'investissement doit être un multiple du prix
            d'une action`}
          </Typography>
        );
      case InvestmentAmountErrorEnum.SHOULD_BE_GREATER_THAN_MINIMUM:
        return (
          <Typography variant="body3-regular" className="text-error">
            {`Le montant de l'investissement doit être supérieur au ticket minimum`}
          </Typography>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="investment-amount-form bg-success-light w-full rounded-lg p-6">
        <Title level="h5" className="my-4">
          Montant de l&apos;investissement
        </Title>
        <SmartCurrencyField<InvestmentAmountFormData>
          name="investmentAmount"
          placeholder="1000"
        />
        <ErrorMessage />
        <div className="mt-4 flex justify-between">
          <Typography variant="body3-regular">Ticket minimum</Typography>
          <Typography variant="body3-semibold">
            {formatCurrency(minimumTicketAmountInCents)}
          </Typography>
        </div>
        <div className="flex justify-between">
          <Typography variant="body3-regular">
            Prix d&apos;une action
          </Typography>
          <Typography variant="body3-semibold">
            {formatCurrency(unitPriceInCents)}
          </Typography>
        </div>
        <div className="border-light-2 border-b py-2" />
        <div className="mt-4 flex justify-between">
          <Typography variant="body3-semibold">
            Mon nombre d&apos;actions
          </Typography>
          <Typography variant="body3-semibold">
            {investmentAmount ? investmentAmount / unitPriceInCents : 0}
          </Typography>
        </div>
      </div>
      <div className="border-light-1 my-6 flex w-full flex-col gap-2 rounded-lg border p-6">
        <Typography variant="body3-regular">
          Afin d’approfondir vos connaissances sur les termes de votre futur
          investissement, nous vous invitons à télécharger le pacte d’associés
          ci-dessous. Il vous permet de consulter à tout moment vos droits et
          vos garanties en tant qu’investisseur.
        </Typography>
        <div className="w-fit">
          <Button
            onClick={() => {
              window.open(shareHoldersAgreement?.document?.signedUrl as string);
            }}
            iconLeft="Download"
            variant="tertiary"
            className="mt-4"
            label={"Télécharger le pacte d'associés"}
          />
        </div>
        <div className="flex flex-col gap-1">
          <Typography variant="body3-regular" className="mt-4">
            J&apos;accepte les conditions du pacte d&apos;associés&nbsp;
            <span className="text-error">*</span>
          </Typography>
          <Toggle
            checked={toggleAgreementAccepted}
            onChange={(e) => setToggleAgreementAccepted(e)}
            leftText="Non"
            name="name"
            rightText="Oui"
          />
        </div>
      </div>
    </>
  );
}
