import { capitalize } from "lodash";

import { ClubLeader, ClubV2 } from "@tudigo-monorepo/core-tudigo-api-models";
import { Chip, Icon } from "@tudigo-monorepo/web-tudigo-components";

export function CircleLeadersListBlock({ club }: { club: ClubV2 }) {
  if (!club) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      {club.leaders?.map((leader) => (
        <LeaderElement key={leader.id} leader={leader} />
      ))}
    </div>
  );
}

const LeaderElement = ({ leader }: { leader: ClubLeader }) => {
  return (
    <div className="border-light-1 flex items-center gap-6 rounded-lg border bg-white p-6">
      <div className="flex min-w-fit flex-col items-center gap-2">
        <img
          className="inline-block size-16 rounded-full object-cover ring-2 ring-white"
          src={leader.profileImage.url ?? ""}
          alt="Club Leader Avatar"
        />
        {leader.socialLinks.linkedin && (
          <a
            href={leader.socialLinks.linkedin}
            target="_blank"
            rel="noreferrer"
          >
            <Icon name="SocialLinkedin2" />
          </a>
        )}
      </div>
      <div className="flex  flex-col gap-2">
        <div className="text-dark-1 text-sm font-semibold">{`${capitalize(
          leader.lastName,
        )} ${capitalize(leader.firstName)}`}</div>
        <div className="text-dark-1 text-xs">{leader.title}</div>
        <div className="text-dark-2 text-xs">{leader.description}</div>
        {leader.specialities && (
          <div className="flex flex-col gap-2 sm:flex-row">
            {leader.specialities.map((speciality) => (
              <Chip key={speciality.code} label={speciality.name} type="info" />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
