import React from "react";
import { useNavigate } from "react-router-dom";

import { Organization } from "@tudigo-monorepo/core-tudigo-api-models";
import { ORGANIZATIONS } from "@tudigo-monorepo/core-tudigo-utils";
import { PageEnum, useSpaceBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  CreateInvestmentAccountModal,
  CreateInvestmentAccountModalTrigger,
  InvestmentAccountProvider,
} from "@tudigo-monorepo/web-feature-organizations";
import {
  Alert,
  Button,
  Checkbox,
  ChipType,
  IconName,
  ListElement,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { useEditWmaCustomerPageContext } from "../edit-wma-customer-page";

const kycIsApproveOrWaitingApproval = (organization: Organization) =>
  organization.internalKycStatus ===
    ORGANIZATIONS.INTERNAL_KYC_STATUS.SUBMITTED_AND_WAITING_APPROVAL ||
  organization.internalKycStatus === ORGANIZATIONS.INTERNAL_KYC_STATUS.APPROVED;

export function EditWmaCustomerKycContent() {
  const { customer } = useEditWmaCustomerPageContext();
  const navigate = useNavigate();

  const { currentPagePaths } = useSpaceBusiness();

  const hasStartedKyc =
    customer.customerUser?.organizationsOwned?.some(
      (org) =>
        org.internalKycStatus !==
        ORGANIZATIONS.INTERNAL_KYC_STATUS.SHOULD_BE_COMPLETED,
    ) || false;

  const [consentAccepted, setConsentAccepted] = React.useState(hasStartedKyc);
  const [consentChecked, setConsentChecked] = React.useState(false);

  type OrganizationChipProps = {
    label: string;
    type: ChipType;
    iconLeft: IconName;
  };

  const organizationStatusChip = (
    organization: Organization,
  ): OrganizationChipProps => {
    switch (organization.internalKycStatus) {
      case ORGANIZATIONS.INTERNAL_KYC_STATUS.APPROVED:
        return {
          type: "success",
          label: "Validé",
          iconLeft: "DotChip",
        };
      case ORGANIZATIONS.INTERNAL_KYC_STATUS.SUBMITTED_AND_WAITING_APPROVAL:
        return {
          type: "waiting",
          label: "En attente de validation",
          iconLeft: "DotChip",
        };
      case ORGANIZATIONS.INTERNAL_KYC_STATUS.REJECTED:
        return {
          type: "error",
          label: "Rejeté",
          iconLeft: "DotChip",
        };
      default:
        return {
          type: "warning",
          label: "À compléter",
          iconLeft: "DotChip",
        };
    }
  };

  const renderOrganizationsListElements = () => {
    if (!customer.customerUser?.organizationsOwned?.length) return null;

    return customer.customerUser?.organizationsOwned.map((organization) => {
      return (
        <ListElement
          key={organization.id}
          toggleActiveOnClick={false}
          startElement={{
            upperText: "Compte",
            lowerText: organization.name,
            useProfileCard: true,
            profileCardClassName: "min-w-[280px] flex justify-start px-3 py-2",
            img: {
              icon: {
                name: "Profile",
              },
            },
          }}
          middleElement={{
            upperText: "Informations d’identité et informations bancaires",
            chips: [
              {
                type: "info",
                label:
                  organization.type === ORGANIZATIONS.TYPE.COMPANY
                    ? "Personne morale"
                    : "Personne physique",
              },
            ],
          }}
          endElement={{
            icon: "ChevronRight",
            info: {
              chip: {
                ...organizationStatusChip(organization),
              },
            },
          }}
          onClick={
            kycIsApproveOrWaitingApproval(organization)
              ? undefined
              : () => handleRedirectKyc(organization.id)
          }
        />
      );
    });
  };

  const handleRedirectKyc = (organizationId: Organization["id"]) => {
    localStorage.setItem(
      "target-url",
      `/wma/customers/edit/${customer.id}/kyc`,
    );
    navigate(
      currentPagePaths[PageEnum.ORGANIZATION_KYC_FUNNEL].replace(
        ":organizationId",
        organizationId,
      ),
    );
  };

  if (customer.status !== "accepted") {
    return (
      <Alert
        className="m-6"
        icon="Cadenas"
        variant="warning"
        size="F"
        title="Le client n'a pas encore accepté votre invitation"
      />
    );
  }

  return (
    <div className="m-6 flex flex-col gap-6">
      <Typography variant="body1">
        La validation d’identité est obligatoire. Elle se fait à l’aide d’un
        justificatif d’identité et d’un justificatif de domicile de votre client
        pour les personnes physiques, et des documents de l’entreprise et des
        bénéficiaires effectifs, pour les personnes morales.
      </Typography>
      {!consentAccepted && (
        <div className="border-light-1 flex flex-col gap-4 rounded-lg border p-6">
          <div className="flex items-center gap-4">
            <Checkbox
              id="consent-checkbox"
              onCheckedChange={(checked) => {
                setConsentChecked(!!checked);
              }}
            />
            <label
              className="font-montserrat text-primary inline text-sm font-normal leading-5"
              htmlFor="consent-checkbox"
            >
              En tant que CGP vous attestez sur l’honneur être dument mandaté
              par vos clients à effet de communiquer tout élément permettant de
              vérifier leur identité à la plateforme Tudigo, et que les
              transmissions effectuées sont consenties par vos clients en
              conformité avec la réglementation RGPD en vigeur.
            </label>
          </div>
          <Button
            label="Commencer la procédure"
            variant="primary"
            disabled={!consentChecked}
            iconRight="ArrowRight"
            className="w-fit"
            onClick={() => setConsentAccepted(true)}
          />
        </div>
      )}
      {consentAccepted && renderOrganizationsListElements()}

      <InvestmentAccountProvider>
        <CreateInvestmentAccountModalTrigger />
        <CreateInvestmentAccountModal />
      </InvestmentAccountProvider>
    </div>
  );
}
