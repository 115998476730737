import { ORGANIZATIONS, PMS_FILES } from "@tudigo-monorepo/core-tudigo-utils";
import { ChipProps } from "@tudigo-monorepo/web-tudigo-components";

import { useKYCContext } from "../../kyc-page";
import {
  checkIfBankAccountRequirementsAreMet,
  checkIfOrganizationDocumentsRequirementsAreMet,
} from "../../utils/kyc-documents-utils";
import {
  checkIfCompanyInformationRequirementsAreMet,
  checkIfCompanyOrganizationPersonsDocumentsRequirementsAreMet,
  checkIfOrganizationRepresentativeRequirementsAreMet,
  checkIfUltimateBeneficialOwnersFormDataRequirementsAreMet,
} from "../../utils/kyc-utils";

export type KycSectionType =
  | "personal-informations"
  | "company-informations"
  | "ubo-informations"
  | "personal-documents"
  | "company-documents"
  | "ubo-documents"
  | "bank-account-informations";

const errorProps: Partial<ChipProps> = {
  type: "error",
  iconLeft: "Cross",
  iconSize: "S",
};

const successProps: Partial<ChipProps> = {
  type: "success",
  iconLeft: "Checked",
  iconSize: "S",
};

export const useKycSectionChipStatusProps = (section: KycSectionType) => {
  const { organization } = useKYCContext();

  const organizationRepresentative = organization.organizationRepresentative;
  const companyInformation = organization.companyInformation;
  const ultimateBeneficialOwners = organization.ultimateBeneficialOwners;

  switch (section) {
    case "personal-informations": {
      const isError = !checkIfOrganizationRepresentativeRequirementsAreMet(
        organizationRepresentative,
      );

      return isError ? errorProps : successProps;
    }
    case "company-informations": {
      const isError =
        !companyInformation ||
        !checkIfCompanyInformationRequirementsAreMet(companyInformation);

      return isError ? errorProps : successProps;
    }
    case "ubo-informations": {
      const isError =
        !checkIfUltimateBeneficialOwnersFormDataRequirementsAreMet(
          organization.ultimateBeneficialOwners,
        );

      return isError ? errorProps : successProps;
    }
    case "personal-documents": {
      const isError =
        !checkIfCompanyOrganizationPersonsDocumentsRequirementsAreMet(
          ultimateBeneficialOwners,
          organization.type === ORGANIZATIONS.TYPE.INDIVIDUAL,
        );

      return isError ? errorProps : successProps;
    }
    case "company-documents": {
      const isError =
        !checkIfOrganizationDocumentsRequirementsAreMet(organization);

      return isError ? errorProps : successProps;
    }
    case "ubo-documents": {
      const isError =
        !checkIfCompanyOrganizationPersonsDocumentsRequirementsAreMet(
          ultimateBeneficialOwners,
          organization.type === ORGANIZATIONS.TYPE.INDIVIDUAL,
        );

      return isError ? errorProps : successProps;
    }
    case "bank-account-informations": {
      const bankAccount = organization.organizationBankAccounts[0] ?? {};

      const isError =
        bankAccount.pmsFile?.status === PMS_FILES.STATUS.REJECTED
          ? true
          : !checkIfBankAccountRequirementsAreMet(bankAccount);

      return isError ? errorProps : successProps;
    }
    default:
      break;
  }
};
