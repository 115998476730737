import { Answers, FormSection } from "@tudigo-monorepo/core-tudigo-api-models";

const INITIAL_INPUT_VALUE = {
  multi_select: null,
  select: null,
  switch: false,
  choices_slider: null,
  range_slider: null,
  hidden: null,
};

export const getInitialFormAnswers = (formSections: FormSection[]) => {
  const answers: Answers = {};

  formSections.forEach((section) => {
    if (section.key !== null) {
      answers[section.key] = {};
      section.pages?.forEach((page) => {
        page.inputs?.forEach((input) => {
          if (section.key != null && input.name != null && input.type != null) {
            answers[section.key][input.name] = INITIAL_INPUT_VALUE[input.type];
          }
        });
      });
    }
  });

  return answers;
};
