import { Navigate, Route } from "react-router-dom";

import {
  AuthCallbackPage,
  ConnectPage,
  LoginPage,
} from "@tudigo-monorepo/web-feature-login";
import { ProjectRoutesWebApp } from "@tudigo-monorepo/web-feature-projects";
import { AppLayout } from "@tudigo-monorepo/web-tudigo-layout";
import { RetryPage } from "@tudigo-monorepo/web-tudigo-security";
import { ClubsV2ClubPageRouteWebapp } from "@tudigo-monorepo/web/feature-clubs-v2";

export const useNotConnectedRoutes = () => {
  return (
    <Route>
      <Route path="/connect" element={<ConnectPage />} />
      <Route path="/login" element={<AppLayout />}>
        <Route index element={<LoginPage />} />
        <Route path="/login/callback" element={<AuthCallbackPage />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Route>
      <Route path="/retry" element={<RetryPage />} />
      <Route path="/clubs-v2/*" element={<ClubsV2ClubPageRouteWebapp />} />
      <Route path="/projects?/*" element={<ProjectRoutesWebApp />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Route>
  );
};
