import { Outlet } from "react-router-dom";

import { SubscribeClubNavigation } from "./components/subscribe-club-navigation/subscribe-club-navigation";
import { useSubscribeClubStepDispatcher } from "./hooks/use-subscribe-club-step-dispatcher";

export function SubscribeClubPage() {
  // Redirect user to the correct step (based on the url and server data)
  useSubscribeClubStepDispatcher();

  return (
    <div className="fixed left-0 top-0 z-[2000] h-screen w-full overflow-y-auto bg-white">
      <SubscribeClubNavigation />
      <Outlet />
    </div>
  );
}
