import React from "react";

import { Alert, Typography } from "@tudigo-monorepo/web-tudigo-components";
import { AlertBlock } from "@tudigo-monorepo/web-tudigo-dynamic-forms";

import { ExplanationModal } from "../modal/explanation-modal";

export const InvestorIntro = () => {
  const [showExplanation, setShowExplanation] = React.useState(false);

  return (
    <>
      <div className="flex flex-col gap-4">
        <h1 className="font-brand text-primary text-[27px]">
          Créer votre profil investisseur
        </h1>
        <hr />
      </div>
      <Alert
        icon="InformationIcon"
        variant="info"
        size="M"
        title="Pourquoi renseigner son profil investisseur ?"
        description={
          <>
            Avant de vous permettre d’avoir pleinement accès aux
            investissements, Tudigo doit évaluer si les services de financement
            participatif sont appropriés au regard de votre profil
            d’investisseur.
            <br />
            <br />
            <span
              className="cursor-pointer underline"
              onClick={() => {
                setShowExplanation(!showExplanation);
              }}
            >
              En savoir plus
            </span>
          </>
        }
      />
      <AlertBlock
        level=""
        text="Définir votre profil investisseur vous prendra environ 4 min"
        icon="Timer"
        textClassName="font-semibold text-primary text-center"
      />
      <Typography variant="body3-regular" className="text-dark-2">
        En tant que partenaire financier nous vous garantissons une
        confidentialité totale de toutes les données que vous nous transmettez.
      </Typography>
      <ExplanationModal
        showExplanationDialog={showExplanation}
        setShowExplanationDialog={setShowExplanation}
      />
    </>
  );
};
