import { Document } from "../documents";
import { Organization } from "../organizations";
import { SignatureRequest } from "../signatures";

export type TaxExemptionName = "IR" | "CSG";

export type TaxExemptionStatus = "draft" | "signed";

export enum TaxExemptionSituationEnum {
  EMPLOYED_WORKER = "employed_worker",
  NON_EMPLOYED_WORKER = "non_employed_worker",
  PENSIONER_OR_ANNUITANT = "pensioner_or_annuitant",
  OTHER = "other",
}

export interface TaxExemption {
  id: string;
  taxName: TaxExemptionName;
  status: TaxExemptionStatus;
  organization: Organization | null;
  document: Document | null;
  signedDocument: Document | null;
  signatureRequest: SignatureRequest | null;
  validityStartDate: string;
  validityEndDate: string;
  periodIsCurrentlyValid: boolean;
  createdAt: string | null;
  updatedAt: string | null;
  since: string | null;
  state: string | null;
  socialProtectionFound: string | null;
  situation: TaxExemptionSituationEnum | null;
}
