import {
  Chip,
  ChipProps,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

type OrganizationCollapseHeaderProps = {
  label: string;
  chipProps?: ChipProps;
};

export function OrganizationCollapseHeader({
  label,
  chipProps,
}: OrganizationCollapseHeaderProps) {
  return (
    <div className="flex items-center gap-x-3">
      <Typography variant="body3-regular" className="text-dark-2">
        {label}
      </Typography>
      {chipProps && <Chip {...chipProps} />}
    </div>
  );
}
