import React from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { useIntl } from "react-intl";

import { Investment } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";
import {
  AccountCard,
  Avatar,
  Chip,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { getInvestmentPaymentMethodTypeTranslateKey } from "../../../shared/utils/investment-utils";

export const useProjectHolderInvestorsColumns = () => {
  const columnHelper = createColumnHelper<Investment>();
  const intl = useIntl();
  const { t } = useTranslation();

  return [
    columnHelper.display({
      header: t("project_holder.web_app.investor_list_page.table.column.name"),
      cell: (info) => {
        const avatar = info.row.original.investorOrganization?.logo?.url ? (
          <Avatar url={info.row.original.investorOrganization?.logo.url} />
        ) : (
          <Avatar>
            <span>🦊</span>
          </Avatar>
        );

        const investorName =
          info.row.original.investorOrganization?.name ??
          `${info.row.original.user?.firstName} ${info.row.original.user?.lastName}`;

        const organizationType = t(
          `project_holder.web_app.investor_list_page.table.row.organization_type.${info.row.original.investorOrganization?.type}`,
        );

        return (
          <AccountCard
            avatar={avatar}
            upperText={investorName}
            lowerText={organizationType}
            lowerTextAsChip
          />
        );
      },
    }),
    columnHelper.accessor(
      "investorOrganization.organizationRepresentative.phone",
      {
        header: t(
          "project_holder.web_app.investor_list_page.table.column.phone",
        ),
        cell: (info) => (
          <Typography variant="body3-regular">
            {
              info.row.original?.investorOrganization
                ?.organizationRepresentative?.phone
            }
          </Typography>
        ),
      },
    ),
    columnHelper.accessor("totalAmountInCents", {
      header: t(
        "project_holder.web_app.investor_list_page.table.column.amount",
      ),
      cell: (info) => (
        <Typography variant="body3-regular">
          {formatCurrency(info.row.original.totalAmountInCents)}
        </Typography>
      ),
    }),

    columnHelper.accessor("globalStatus", {
      header: t(
        "project_holder.web_app.investor_list_page.table.column.status",
      ),
      cell: (info) => (
        <Chip
          label={t(
            `investment.global_status.${info.row.original.globalStatus}`,
          )}
          iconLeft="DotChip"
          type={(() => {
            switch (info.row.original.globalStatus) {
              case "validated":
                return "success";
              case "transfer_funds_failed":
                return "error";
              default:
                return "warning";
            }
          })()}
        />
      ),
    }),
    columnHelper.accessor("paymentDate", {
      header: t(
        "project_holder.web_app.investor_list_page.table.column.validated_at",
      ),
      cell: (info) => (
        <Typography variant="body3-regular">
          {intl.formatDate(info.row.original.paymentDate ?? undefined)}
        </Typography>
      ),
    }),
    columnHelper.accessor("createdAt", {
      header: t("project_holder.web_app.investor_list_page.table.column.date"),
      cell: (info) => (
        <Typography variant="body3-regular">
          {intl.formatDate(info.row.original.createdAt)}
        </Typography>
      ),
    }),
    columnHelper.accessor("paymentMethodType", {
      header: t(
        "project_holder.web_app.investor_list_page.table.column.payment",
      ),
      cell: (info) => (
        <Typography variant="body3-regular">
          {t(getInvestmentPaymentMethodTypeTranslateKey(info.row.original))}
        </Typography>
      ),
    }),
  ];
};
