import { WMACustomer } from "@tudigo-monorepo/core-tudigo-api-models";
import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Icon, ListElement } from "@tudigo-monorepo/web-tudigo-components";

import { getWMAFullNameFromWMACustomer } from "../utils/wma-customer-utils";
import { WMACustomerInvitationChipStatus } from "./wma-customer-invitation-chip-status";

type WMACustomerInvitationListElementProps = {
  wmaCustomer?: WMACustomer;
  navigateTo?: string;
};

export function WMACustomerInvitationListElement({
  wmaCustomer,
  navigateTo,
}: WMACustomerInvitationListElementProps) {
  const { t } = useTranslation();

  if (!wmaCustomer) {
    return null;
  }

  return (
    <ListElement
      navigateTo={navigateTo}
      startElement={{
        upperText: getWMAFullNameFromWMACustomer(wmaCustomer),
        // lowerText: `Société : ${wmaCustomer.wmaUser?.individualOrganization}`, // <= TODO MVP 2
        img: {
          icon: { name: "UserChat" },
        },
        profileCardClassName: "min-w-[280px] flex justify-start px-3 py-2",
      }}
      middleElement={{
        upperText: t(
          "wma.web_app.wma_customers_invitation_list_page.list_element.desc",
        ),
      }}
      endElement={{
        render: () => {
          return (
            <div className="flex items-center gap-x-8">
              <WMACustomerInvitationChipStatus wmaCustomer={wmaCustomer} />
              {navigateTo && (
                <Icon
                  name="ChevronRight"
                  primaryColor={themeColors["dark-2"]}
                />
              )}
            </div>
          );
        },
      }}
    />
  );
}
