import React from "react";

import { OrganizationPerson } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Alert, Button } from "@tudigo-monorepo/web-tudigo-components";

import { getDefaultOrganizationPersonModel } from "../../../utils/kyc-utils";
import { BeneficialOwnerKycCollapseFromGroup } from "../components/beneficial-owner-kyc-collapse-form-group";
import { RepresentantKycCollapseFormGroup } from "../components/representant-kyc-collapse-form-group";

type BeneficialOwnerFromPropsType = {
  organizationRepresentative: OrganizationPerson;
  beneficialOwnersFormData: OrganizationPerson[];
  setBeneficialOwnersFormData: (beneficialOwners: OrganizationPerson[]) => void;
};

export function BeneficialOwnersForm(props: BeneficialOwnerFromPropsType) {
  const {
    beneficialOwnersFormData,
    setBeneficialOwnersFormData,
    organizationRepresentative,
  } = props;

  const { t } = useTranslation();

  const removeBeneficialOwner = (beneficialOwnerIndex: number) => {
    const updatedOwners = beneficialOwnersFormData.filter(
      (_, index) => index !== beneficialOwnerIndex,
    );

    setBeneficialOwnersFormData(updatedOwners);
  };

  const formList = beneficialOwnersFormData.map((beneficialOwner, index) => {
    if (beneficialOwner.id === organizationRepresentative.id) {
      return (
        <RepresentantKycCollapseFormGroup
          beneficialOwner={beneficialOwner}
          index={index}
          key={index}
          beneficialOwnersFormData={beneficialOwnersFormData}
          setBeneficialOwnersFormData={setBeneficialOwnersFormData}
        />
      );
    }

    return (
      <BeneficialOwnerKycCollapseFromGroup
        beneficialOwner={beneficialOwner}
        index={index}
        key={index}
        removeBeneficialOwner={removeBeneficialOwner}
        beneficialOwnersFormData={beneficialOwnersFormData}
        setBeneficialOwnersFormData={setBeneficialOwnersFormData}
      />
    );
  });

  return (
    <>
      {formList}
      {beneficialOwnersFormData.length > 3 && (
        <Alert
          variant="info"
          icon="Info"
          size="F"
          description={t(
            "kyc_funnel.step_company_information.alert.maximum_beneficial_owners_reached",
          )}
        />
      )}
      <Button
        variant="tertiary"
        label={t(
          "kyc_funnel.step_company_information.button.add_beneficial_owner.label",
        )}
        className="w-fit"
        iconRight="Plus"
        disabled={beneficialOwnersFormData.length > 3}
        onClick={() =>
          setBeneficialOwnersFormData([
            ...beneficialOwnersFormData,
            getDefaultOrganizationPersonModel(false, true),
          ])
        }
      />
    </>
  );
}
