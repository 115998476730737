import { Organization } from "@tudigo-monorepo/core-tudigo-api-models";
import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Icon, ListElement } from "@tudigo-monorepo/web-tudigo-components";

import { OrganizationKycChip } from "../organization-chips/organization-kyc-chip";

type OrganizationKycListElementProps = {
  organization: Organization;
  navigateTo?: string;
  onClick?: () => void;
};
export function OrganizationKycListElement(
  props: OrganizationKycListElementProps,
) {
  const { t } = useTranslation();
  const { organization, navigateTo, onClick } = props;

  return (
    <ListElement
      onClick={onClick}
      navigateTo={navigateTo}
      startElement={{
        upperText: t(
          "organizations.back_office.edit_organization_page.account.list_element.start_element.upper_text",
        ),
        lowerText: organization.name,
        img: {
          icon: { name: "Profile" },
        },
        profileCardClassName: "min-w-[280px] flex justify-start px-3 py-2",
      }}
      middleElement={{
        upperText: t(
          "organizations.back_office.edit_organization_page.account.list_element.middle_element.upper_text",
        ),
        chips: organization.type
          ? [
              {
                label: t(
                  `organizations.back_office.edit_organization_page.account.list_element.middle_element.chip.${organization?.type}.label`,
                ),
                type: "secondary",
              },
            ]
          : undefined,
      }}
      endElement={{
        render: () => {
          return (
            <div className="flex items-center gap-x-8">
              <OrganizationKycChip organization={organization} />
              {(navigateTo || onClick) && (
                <Icon
                  name="ChevronRight"
                  primaryColor={themeColors["dark-2"]}
                />
              )}
            </div>
          );
        },
      }}
    />
  );
}
