import { ClubV2 } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Tabs } from "@tudigo-monorepo/web-tudigo-components";

import { ClubHead } from "../club-head/club-head";
import { ClubMembership } from "../club-membership/club-membership";

type ClubPageHeaderSectionProps = {
  club: ClubV2;
};

export const ClubPageHeaderSection = ({ club }: ClubPageHeaderSectionProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="z-50 mx-6 -mt-16 flex flex-col items-center justify-between gap-4 lg:flex-row xl:-mt-14">
        <div className="z-50 w-full items-center lg:w-2/3">
          <ClubHead club={club} />
        </div>
        <div className="flex w-full flex-col items-center lg:w-1/3">
          <ClubMembership />
        </div>
      </div>
      <Tabs
        tabs={[
          {
            name: t("page_club.header.tabs.introduction.label"),
            redirectTo: "presentation",
          },
          {
            name: t("page_club.header.tabs.thesis.label"),
            redirectTo: "thesis",
          },
          {
            name: t("page_club.header.tabs.team.label"),
            redirectTo: "team",
          },
          {
            name: t("page_club.header.tabs.track_record.label"),
            redirectTo: "track-record",
          },
          {
            name: t("page_club.header.tabs.fundraising.label"),
            redirectTo: "fundraising",
          },
          {
            name: t("page_club.header.tabs.modalities.label"),
            redirectTo: "modalities",
          },
          {
            name: t("page_club.header.tabs.media.label"),
            redirectTo: "media",
          },
          {
            name: t("page_club.header.tabs.qa.label"),
            redirectTo: "qa",
            section: "qa",
            badge: {
              icon: "Question",
              variant: "default",
            },
            badgeOnly: true,
          },
        ]}
        className="px-6 pt-4"
      />
    </div>
  );
};
