import axios from "axios";

import { CompanyInformation } from "@tudigo-monorepo/core-tudigo-api-models";

import { PappersCompanyDetailResult } from "./model";

const PAPPERS_API_KEY = String(import.meta.env["VITE_PAPPERS_API_KEY"]);
const PAPPERS_API_URL = String(import.meta.env["VITE_PAPPERS_API_URL"]);

export const getPappersCompaniesSuggestions = async (
  search: string,
): Promise<PappersCompanyDetailResult[]> => {
  const response = await axios.get(`${PAPPERS_API_URL}/suggestions`, {
    params: {
      q: search,
      pays: "FR",
      api_token: PAPPERS_API_KEY,
    },
  });

  return response.data.resultats_nom_entreprise ?? [];
};

export const getPappersCompanyDetails = async (
  siren: string,
): Promise<PappersCompanyDetailResult> => {
  const response = await axios.get(`${PAPPERS_API_URL}/entreprise`, {
    params: {
      siren,
      pays: "FR",
      api_token: PAPPERS_API_KEY,
    },
  });

  return response.data ?? null;
};

export const formatPappersCompanyResult = (
  result: PappersCompanyDetailResult,
): CompanyInformation => {
  return {
    legalName: result.nom_entreprise,
    legalStatus: result.forme_juridique,
    capital: result.capital,
    capitalInCents: Number((result.capital * 100).toFixed(0)),
    capitalCurrency: result.devise_capital,
    registrationLocation: {
      id: null,
      name: null,
      state: null,
      email: null,
      firstName: null,
      lastName: null,
      isVerified: null,
      phoneNumber: null,
      region: null,
      street1: result.siege.adresse_ligne_1,
      street2: result.siege.adresse_ligne_2,
      city: result.siege.ville,
      country: "FR",
      postalCode: result.siege.code_postal,
      formatted: "",
    },
    headOfficeAddress: {
      id: null,
      name: null,
      state: null,
      email: null,
      firstName: null,
      lastName: null,
      isVerified: null,
      phoneNumber: null,
      region: null,
      street1: result.siege.adresse_ligne_1,
      street2: result.siege.adresse_ligne_2,
      city: result.siege.ville,
      country: "FR",
      postalCode: result.siege.code_postal,
      formatted: "",
    },
    registrationNumbers: {
      siret: result.siege.siret,
      siren: result.siren,
      rcs: result.numero_rcs,
    },
  };
};
