import { useState } from "react";

import {
  Organization,
  OrganizationPerson,
  OrganizationPersonDocument,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { PMS_FILES } from "@tudigo-monorepo/core-tudigo-utils";
import {
  FormContextProvider,
  getSmartFormComponents,
} from "@tudigo-monorepo/web-feature-form";
import {
  Title,
  Toggle,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import {
  getDefaultOrganizationPersonIdentityDocumentType,
  getOrganizationPersonDocumentsData,
  isOrganizationPersonProofOfAddressRequired,
  OrganizationPersonDocumentsData,
} from "../../../utils/kyc-utils";

type IndividualKycDocumentsFormGroupProps = {
  beneficialOwner: OrganizationPerson;
  organizationFormData: Organization;
  setOrganizationFormData: (organization: Organization) => void;
};

export function IndividualKycDocumentsFormGroup(
  props: IndividualKycDocumentsFormGroupProps,
) {
  const { beneficialOwner, organizationFormData, setOrganizationFormData } =
    props;

  const { SmartMultiTypeUploaderField, SmartPdfField } =
    getSmartFormComponents<OrganizationPersonDocumentsData>();

  const { t } = useTranslation();

  const organizationPersonDocumentsData = getOrganizationPersonDocumentsData(
    beneficialOwner,
    isOrganizationPersonProofOfAddressRequired(beneficialOwner),
  );

  const [documentType, setDocumentType] = useState<
    "identity_card" | "european_passport"
  >(
    getDefaultOrganizationPersonIdentityDocumentType(
      organizationPersonDocumentsData,
    ),
  );

  const documentTypeChangeHandler = (isPassport: boolean) => {
    setDocumentType(isPassport ? "european_passport" : "identity_card");
  };

  const mergedIdentityCardIsRejected =
    organizationPersonDocumentsData.mergedIdentityCard.pmsFile?.status ===
    PMS_FILES.STATUS.REJECTED;

  const mergedPassportIsRejected =
    organizationPersonDocumentsData.mergedPassport.pmsFile?.status ===
      PMS_FILES.STATUS.REJECTED &&
    organizationPersonDocumentsData.passportFrontSide.document.base64 ===
      undefined;

  const proofOfAddressIsRejected =
    organizationPersonDocumentsData.proofOfAddress?.pmsFile?.status ===
      PMS_FILES.STATUS.REJECTED &&
    organizationPersonDocumentsData.proofOfAddress.document.base64 ===
      undefined;

  const isProofOfAddressRequired =
    isOrganizationPersonProofOfAddressRequired(beneficialOwner);

  const handleOrganizationPersonDocumentsFormDataChange = (
    newFormData: OrganizationPersonDocumentsData,
  ) => {
    const formDocuments: OrganizationPersonDocument[] = [];
    if (documentType === "identity_card") {
      formDocuments.push({
        organizationPerson: { id: beneficialOwner.id },
        ...newFormData.identityCardFrontSide,
      });
      formDocuments.push({
        organizationPerson: { id: beneficialOwner.id },
        ...newFormData.identityCardBackSide,
      });
      formDocuments.push({
        organizationPerson: { id: beneficialOwner.id },
        ...newFormData.mergedIdentityCard,
      });
    } else {
      formDocuments.push({
        organizationPerson: { id: beneficialOwner.id },
        ...newFormData.passportFrontSide,
      });
      formDocuments.push({
        organizationPerson: { id: beneficialOwner.id },
        ...newFormData.mergedPassport,
      });
    }

    if (newFormData.proofOfAddress) {
      formDocuments.push({
        organizationPerson: { id: beneficialOwner.id },
        ...newFormData.proofOfAddress,
      });
    }

    setOrganizationFormData({
      ...organizationFormData,
      ultimateBeneficialOwners:
        organizationFormData.ultimateBeneficialOwners.map((owner) => {
          if (owner.id === beneficialOwner.id) {
            return { ...owner, documents: formDocuments };
          }

          return owner;
        }),
    });
  };

  return (
    <FormContextProvider
      error={null}
      formData={organizationPersonDocumentsData}
      setFormData={handleOrganizationPersonDocumentsFormDataChange}
    >
      <div className="domiciliation-documents">
        <Title h3Brand="brand-2" level="h3" className="text-primary">
          {t("kyc_funnel.step_documents.individual_section.page.title")}
        </Title>

        <div className="my-6 rounded-lg border px-10 py-8">
          <div className="flex flex-col gap-4">
            <Toggle
              checked={documentType === "european_passport"}
              leftText={t(
                "kyc_funnel.step_documents.form.organization_person_document.label.identity_card",
              )}
              name="type"
              rightText={t(
                "kyc_funnel.step_documents.form.organization_person_document.label.passport",
              )}
              onChange={(e) => documentTypeChangeHandler(e)}
              blueStyle
            />
            <Typography variant="body1">
              {t(
                "kyc_funnel.step_documents.form.organization_person_document.document_requirements",
              )}
            </Typography>

            <div className="border-light-2 w-full border-b border-dashed" />

            <div
              className={cn("flex flex-col gap-6", {
                hidden: documentType === "european_passport",
              })}
            >
              <SmartMultiTypeUploaderField
                name="identityCardFrontSide.document"
                label={t(
                  "kyc_funnel.step_documents.form.organization_person_document.label.identity_card_front_side",
                )}
                maxSizeInMB={4}
                required
                disabled={
                  organizationPersonDocumentsData.mergedIdentityCard.pmsFile
                    ?.status === PMS_FILES.STATUS.WAITING_APPROVAL
                }
                errors={
                  mergedIdentityCardIsRejected &&
                  !organizationPersonDocumentsData.identityCardFrontSide
                    ?.document?.base64
                    ? [t("kyc_funnel.document_status.rejected")]
                    : undefined
                }
              />
              <SmartMultiTypeUploaderField
                name="identityCardBackSide.document"
                label={t(
                  "kyc_funnel.step_documents.form.organization_person_document.label.identity_card_back_side",
                )}
                maxSizeInMB={4}
                required
                disabled={
                  organizationPersonDocumentsData.mergedIdentityCard.pmsFile
                    ?.status === PMS_FILES.STATUS.WAITING_APPROVAL
                }
                errors={
                  mergedIdentityCardIsRejected &&
                  !organizationPersonDocumentsData.identityCardBackSide
                    ?.document?.base64
                    ? [t("kyc_funnel.document_status.rejected")]
                    : undefined
                }
              />
            </div>

            <div
              className={cn("flex flex-col gap-6", {
                hidden: documentType === "identity_card",
              })}
            >
              <SmartMultiTypeUploaderField
                name="passportFrontSide.document"
                label={t(
                  "kyc_funnel.step_documents.form.organization_person_document.label.passport_front_side",
                )}
                maxSizeInMB={4}
                required
                disabled={
                  organizationPersonDocumentsData.mergedPassport.pmsFile
                    ?.status === PMS_FILES.STATUS.WAITING_APPROVAL
                }
                errors={
                  mergedPassportIsRejected
                    ? [t("kyc_funnel.document_status.rejected")]
                    : undefined
                }
              />
            </div>
          </div>
        </div>
      </div>

      {isProofOfAddressRequired && (
        <div className="domiciliation-documents">
          <Title h3Brand="brand-2" level="h3" className="text-primary">
            {t(
              "kyc_funnel.step_documents.individual_company_section.proof_of_address.title",
            )}
          </Title>

          <div className="my-6 flex flex-col gap-6 rounded-lg border px-10 py-8">
            <Typography variant="body2" className="text-dark-4">
              {t(
                "kyc_funnel.step_documents.individual_company_section.proof_of_address.form.requirements",
              )}
            </Typography>

            <div className="border-light-1 border border-b border-dashed" />

            <div className="flex flex-col gap-4">
              <SmartPdfField
                name="proofOfAddress.document"
                label={t(
                  "kyc_funnel.step_documents.individual_company_section.proof_of_address.form.label",
                )}
                required
                disabled={
                  organizationPersonDocumentsData.proofOfAddress?.pmsFile
                    ?.status === PMS_FILES.STATUS.WAITING_APPROVAL
                }
                errors={
                  proofOfAddressIsRejected
                    ? [t("kyc_funnel.document_status.rejected")]
                    : undefined
                }
              />
            </div>
          </div>
        </div>
      )}
    </FormContextProvider>
  );
}
