import { createColumnHelper } from "@tanstack/react-table";

import { Investment } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Chip, Typography } from "@tudigo-monorepo/web-tudigo-components";

export const useCustomerInvestmentsColumns = () => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<Investment>();

  return [
    columnHelper.accessor("investorOrganization.name", {
      header: t("account"),
      cell: (info) => {
        if (!info.getValue()) {
          return <Typography variant="body3-regular">-</Typography>;
        }

        return <Chip label={info.getValue()} />;
      },
    }),
    columnHelper.accessor("project.content.name", {
      header: t("investments.table.columns.name"),
      cell: (info) => (
        <Typography variant="body3-regular">
          {info.getValue() || "-"}
        </Typography>
      ),
    }),
    columnHelper.accessor("totalAmountInCents", {
      header: t("investments.table.columns.amount"),
      cell: (info) => (
        <Typography variant="body3-regular">
          {info.getValue() / 100 + "€"}
        </Typography>
      ),
    }),
    columnHelper.accessor("createdAt", {
      header: t("investments.table.columns.date"),
      cell: (info) => (
        <Typography variant="body3-regular">
          {new Date(info.getValue()).toLocaleDateString()}
        </Typography>
      ),
    }),
    columnHelper.accessor("project.mechanic", {
      header: t("investments.table.columns.instrument"),
      cell: (info) => (
        <Chip label={t(`projects.mechanic.label.${info.getValue()}`)} />
      ),
    }),
    columnHelper.accessor("paymentMethodType", {
      header: t("investments.table.columns.payment_method"),
      cell: (info) => (
        <Typography variant="body3-regular">
          {info.getValue()
            ? t(`investment.payment_methode_type.label.${info.getValue()}`)
            : "-"}
        </Typography>
      ),
    }),
    columnHelper.accessor("globalStatus", {
      header: t("investments.table.columns.status"),
      cell: (info) => {
        const chipType = {
          waiting_for_payment: "warning",
          waiting_for_signature: "warning",
          validated: "success",
          transfer_funds_failed: "success",
          cancelled: "error",
        } as const;
        const chipLabel: Record<keyof typeof chipType, string> = {
          waiting_for_payment: t(
            "investment_global_status_waiting_for_payment",
          ),
          waiting_for_signature: t(
            "investment_global_status_waiting_for_signature",
          ),
          validated: t("investment_global_status_validated"),
          cancelled: t("investment_global_status_cancelled"),
          transfer_funds_failed: t("investment_global_status_validated"),
        } as const;
        const status = info.getValue() as keyof typeof chipType;

        return (
          <Chip
            type={chipType[status]}
            iconLeft="DotChip"
            label={chipLabel[status]}
          />
        );
      },
    }),
  ];
};
