import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { Organization } from "@tudigo-monorepo/core-tudigo-api-models";

import { getApiClient } from "../../..";

type PostKycSubmitDataRequestMutationParams = {
  organizationId: Organization["id"];
};

export const postKycSubmitDataRequest = async (
  params: PostKycSubmitDataRequestMutationParams,
) => {
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.organizations.postKycSubmitDataRequest(
    params.organizationId,
  );

  return response.data;
};

export const usePostKycSubmitDataRequestMutation = (
  options?: UseMutationOptions<
    TudigoResponse<Organization>,
    TudigoError,
    PostKycSubmitDataRequestMutationParams
  >,
) =>
  useMutation({
    mutationFn: (params: PostKycSubmitDataRequestMutationParams) =>
      postKycSubmitDataRequest(params),
    ...options,
  });
