import { Link } from "react-router-dom";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { PageEnum, useSpaceBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  Alert,
  Button,
  Chip,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { useKYCContext } from "../../../kyc-page";
import { KycDocumentViewer } from "../../../shared/components/kyc-document-viewer";
import { KycSectionChipStatus } from "../../../shared/components/kyc-section-chip-status";
import { checkIfOrganizationDocumentsRequirementsAreMet } from "../../../utils/kyc-documents-utils";
import { getOrganizationCompanyDocumentsData } from "../../../utils/kyc-utils";

export function OrganizationDocumentsSummarySection() {
  const { organization } = useKYCContext();

  const { currentPagePaths } = useSpaceBusiness();
  const kycPagePath = currentPagePaths[
    PageEnum.ORGANIZATION_KYC_FUNNEL
  ].replace(":organizationId", organization.id);

  const editPagePath = `${kycPagePath}/documents`;

  const { t } = useTranslation();

  const documentsData = getOrganizationCompanyDocumentsData(organization);

  const isError = !checkIfOrganizationDocumentsRequirementsAreMet(organization);

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex gap-2">
          <Title level="h4">
            {t("kyc_funnel.step_documents.page.title")} {organization.name}
          </Title>
          <KycSectionChipStatus section="company-documents" />
        </div>
        <Link to={editPagePath}>
          <Button
            label={t("kyc_funnel.final_summary.edit_btn.label")}
            iconLeft="Pen"
            variant="tertiary"
          />
        </Link>
      </div>

      <div className="border-light-2 my-4 border-b" />

      <div className="flex flex-col gap-4">
        {isError && (
          <Alert
            variant="error"
            size="F"
            icon="Cross"
            title={t(
              "kyc_funnel.step_company_information.collapse_beneficial_owner.alert.title",
            )}
            description={t(
              "kyc_funnel.step_company_information.collapse_beneficial_owner.alert.description",
            )}
          />
        )}

        <div className="flex flex-col">
          <Typography variant="caption1-regular">
            {t(
              "kyc_funnel.step_documents.form.organization_document.label.company_registration",
            )}
          </Typography>
          {documentsData.companyRegistration.document.signedUrl ? (
            <KycDocumentViewer doc={documentsData.companyRegistration} />
          ) : (
            <div className="my-2">
              <Chip
                type="info"
                label={t("kyc_funnel.final_summary.file_downloader.empty_file")}
              />
            </div>
          )}
        </div>

        <div className="flex flex-col">
          <Typography variant="caption1-regular">
            {t(
              "kyc_funnel.step_documents.form.organization_document.label.legal_status",
            )}
          </Typography>
          {documentsData.status.document.signedUrl ? (
            <KycDocumentViewer doc={documentsData.status} />
          ) : (
            <div className="my-2">
              <Chip
                type="info"
                label={t("kyc_funnel.final_summary.file_downloader.empty_file")}
              />
            </div>
          )}
        </div>

        <div className="flex flex-col">
          <Typography variant="caption1-regular">
            {t(
              "kyc_funnel.step_documents.form.organization_document.label.ubo_register",
            )}
          </Typography>
          {documentsData.uboRegister.document.signedUrl ? (
            <KycDocumentViewer doc={documentsData.uboRegister} />
          ) : (
            <div className="my-2">
              <Chip
                type="info"
                label={t("kyc_funnel.final_summary.file_downloader.empty_file")}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
