import React from "react";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Icon } from "../icons/icon";
import { IconName } from "../icons/icon-name.type";

export type AvatarSize = "S" | "M";

export type AvatarVariant =
  | "white"
  | "yellow"
  | "blue"
  | "green"
  | "red"
  | "orange";

type AvatarProps = {
  alt?: string;
  children?: React.ReactNode;
  className?: string;
  icon?: { name: IconName; color?: string };
  randomVariant?: boolean;
  size?: AvatarSize;
  url?: string;
  variant?: AvatarVariant;
};

const variantColors: Record<AvatarVariant, string> = {
  white: "bg-white border border-dark-2",
  yellow: "bg-waiting-super-light border border-transparent",
  blue: "bg-accent-super-light border border-transparent",
  green: "bg-success-light border border-transparent",
  red: "bg-error-light border border-transparent",
  orange: "bg-warning-super-light border border-transparent",
};

const sizes: Record<AvatarSize, string> = {
  S: "w-8 h-8 min-w-[32px] min-h-[32px]",
  M: "w-12 h-12 min-w-[48px] min-h-[48px]",
};

const autoSizeText = (children: React.ReactNode) => {
  if (typeof children === "string") {
    const { length } = children;
    if (length <= 2) return "text-sm";
    if (length === 3) return "text-xs";
    if (length >= 4) return "text-[9px]";
  }

  return "text-sm";
};

const getRandomVariant = () => {
  const variantArray = Object.keys(variantColors);
  const min = 0;
  const max = variantArray.length - 1;
  const randomIndex = Math.floor(Math.random() * (max - min + 1) + min);
  const variantKey = variantArray[randomIndex];

  return variantColors[variantKey as AvatarVariant];
};

export function Avatar(props: AvatarProps) {
  const {
    alt = "avatar",
    children,
    className,
    icon,
    randomVariant = false,
    size = "S",
    url,
    variant = "white",
  } = props;

  const backgroundColor = randomVariant
    ? getRandomVariant()
    : variantColors[variant];

  return (
    <div
      className={cn(
        "flex items-center justify-center rounded-full",
        { [backgroundColor]: !url },
        sizes[size],
        className,
      )}
    >
      {url ? (
        <img
          src={url}
          className="aspect-square h-full w-full rounded-full object-cover"
          alt={alt}
        />
      ) : icon ? (
        <Icon
          name={icon.name}
          size="S"
          primaryColor={
            icon.color
              ? themeColors[icon.color as keyof typeof themeColors]
              : themeColors["dark-2"]
          }
        />
      ) : children ? (
        <span
          className={cn(
            "font-montserrat font-normal uppercase leading-none",
            autoSizeText(children),
          )}
        >
          {typeof children === "string" ? children.slice(0, 4) : children}
        </span>
      ) : null}
    </div>
  );
}
