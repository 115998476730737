import { PropsWithChildren } from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";

import { Icon } from "../../old/icons/icon";
import { ButtonRebrand } from "../ui/button-rebrand";

export interface UserAccountLinkProps extends PropsWithChildren {
  className?: string;
  reducedDisplay?: boolean;
  onClick?: () => void;
}

export function UserAccountLink(props: UserAccountLinkProps) {
  const { children, className, reducedDisplay, onClick } = props;

  const { user } = useUserBusiness();
  const { t } = useTranslation();

  if (!user) {
    return null;
  }

  const fullUserName = `${user.firstName} ${user.lastName}`;

  return (
    <ButtonRebrand
      onClick={onClick}
      variant="ghost"
      size="lg"
      className={cn("font-roobert gap-x-2", className)}
    >
      <Icon name="Profile" primaryColor="black-900" size="XS" />
      {!reducedDisplay && (
        <span className="w-fit text-wrap sm:text-left">
          {t("my_account")}{" "}
          <span className="hidden xl:inline-block">({fullUserName})</span>
        </span>
      )}
      {children}
    </ButtonRebrand>
  );
}
