import {
  EquitySavingPlan,
  Organization,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { ChipProps } from "@tudigo-monorepo/web-tudigo-components";

import { useOrganizationChipProps } from "./use-organization-chip-props";
import { useOrganizationKycStatusChipProps } from "./use-organization-kyc-status-chip-props";

export function useOrganizationKycEquitySavingPlanStatusChipProps(
  equitySavingPlan?: EquitySavingPlan,
  organization?: Organization,
): ChipProps {
  const { disabledProps } = useOrganizationChipProps();
  const organizationKycStatus = useOrganizationKycStatusChipProps(organization);

  if (equitySavingPlan?.invalidatedAt) {
    return disabledProps;
  }

  return organizationKycStatus;
}
