import countriesList from "./countries.json";
import euCountryCodesData from "./eu-country-codes.json";

export type Country = {
  id: string;
  name: string;
  emoji: string;
};

export type CountryCode = {
  code: string;
  name: string;
};

export const countries: Country[] = Object.entries(countriesList).map(
  ([id, value]) => ({
    id: id,
    name: value.name,
    emoji: value.emoji,
  }),
);

export const euCountryCodes: CountryCode[] = Object.entries(
  euCountryCodesData,
).map(([code, value]) => ({
  code: code,
  name: value,
}));

export const getCountryEmoji = (countryCode: string) =>
  (countriesList as any)[countryCode]?.emoji ?? "";

export const isEUMemberCountry = (countryCode: string | null | undefined) =>
  euCountryCodes.some((country) => country.code === countryCode);
