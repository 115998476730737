import { useIntl } from "react-intl";

import {
  CommitmentProfile,
  PrivilegedSector,
  Strategy,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";
import { SummaryCard } from "@tudigo-monorepo/web-tudigo-components";

type InvestorProfileCardsContentProps = {
  commitmentProfile?: `${CommitmentProfile}`;
  investmentCapacity: { amount: number; currency?: string };
  strategy?: `${Strategy}`;
  privilegedSectors: `${PrivilegedSector}`[];
};

export function InvestorProfileCardsContent(
  props: InvestorProfileCardsContentProps,
) {
  const {
    commitmentProfile,
    investmentCapacity: { amount },
    privilegedSectors,
    strategy,
  } = props;

  const intl = useIntl();

  return (
    <div className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2">
      <SummaryCard
        title={intl.formatMessage({
          id: "qualification.summary_page.investor_profile.card.commitment.title",
        })}
        chips={[
          {
            label: intl.formatMessage({
              id: `qualification.summary_page.investor_profile.card.commitment.${commitmentProfile}.chip.label`,
            }),
            type: "success",
          },
        ]}
        content={intl.formatMessage({
          id: `qualification.summary_page.investor_profile.card.commitment.${commitmentProfile}.content`,
        })}
      />
      <SummaryCard
        title={intl.formatMessage({
          id: "qualification.summary_page.investor_profile.card.privileged_sectors.title",
        })}
        chips={privilegedSectors.map((sector) => ({
          label: intl.formatMessage({
            id: `qualification.summary_page.investor_profile.card.privileged_sectors.${sector}.chip.label`,
          }),
        }))}
        content={intl.formatMessage({
          id: "qualification.summary_page.investor_profile.card.privileged_sectors.content",
        })}
      />
      <SummaryCard
        title={intl.formatMessage({
          id: "qualification.summary_page.investor_profile.card.strategy.title",
        })}
        chips={[
          {
            label: intl.formatMessage({
              id: `qualification.summary_page.investor_profile.card.strategy.${strategy}.chip.label`,
            }),
            type: "success",
          },
        ]}
        content={intl.formatMessage({
          id: `qualification.summary_page.investor_profile.card.strategy.${strategy}.content`,
        })}
      />
      <SummaryCard
        variant="success"
        title={intl.formatMessage({
          id: "qualification.summary_page.investor_profile.card.investment_capacity.title",
        })}
        subtitle={intl.formatMessage(
          {
            id: "qualification.summary_page.investor_profile.card.investment_capacity.subtitle",
          },
          {
            amount: formatCurrency(amount * 100),
          },
        )}
        content={intl.formatMessage({
          id: "qualification.summary_page.investor_profile.card.investment_capacity.content",
        })}
      />
    </div>
  );
}
