import { Outlet } from "react-router-dom";

import {
  PageHead,
  TabElement,
  Tabs,
  Title,
} from "@tudigo-monorepo/web-tudigo-components";

import {
  InvestStatus,
  useInvestStatuses,
} from "../../shared/hooks/use-invest-statuses";

export function ProjectHolderInvestorsPage() {
  const investStatuses: InvestStatus[] = useInvestStatuses();

  const tabs: TabElement[] = investStatuses
    .filter(
      ({ urlParamValue }) =>
        urlParamValue !== "cancelled" &&
        urlParamValue !== "waiting_for_signature",
    )
    .map(({ urlParamValue, label }) => ({
      name: label,
      redirectTo: `${urlParamValue}`,
    }));

  return (
    <div id="my-investments-page" className="w-full">
      <PageHead title="Liste des investisseurs" />

      <div className="border-light-2 mx-6 border-b" />

      <Tabs tabs={tabs} className="px-6" />

      <div className="m-6">
        <Title h3Brand="brand-2" level="h3" className="text-primary mb-6">
          Liste des investisseurs
        </Title>

        <Outlet />
      </div>
    </div>
  );
}
