import { PropsWithChildren, ReactNode } from "react";
import { createPortal } from "react-dom";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { Button, ButtonVariant } from "../button";
import { IconName } from "../icons/icon-name.type";
import { Typography } from "../typography";

type ModalProps = {
  title: string;
  content?: ReactNode;
  isOpen: boolean;
  className?: string;
  setIsOpen: (isOpen: boolean) => void;
  negativeLabel?: string;
  negativeAction?: () => void;
  confirmAction?: () => void;
  confirmLabel?: string;
  dismissOnOutsideClick?: boolean;
  confirmButtonProps?: {
    className?: string;
    isLoading?: boolean;
    iconRight?: IconName;
    variant?: ButtonVariant;
    disabled?: boolean;
  };
  negativeButtonProps?: {
    className?: string;
    isLoading?: boolean;
    variant?: ButtonVariant;
  };
  hideCrossButton?: boolean;
};

const Modal = (props: PropsWithChildren<ModalProps>) => {
  const {
    children,
    title,
    content,
    isOpen,
    setIsOpen,
    negativeLabel,
    negativeAction,
    negativeButtonProps = {
      isLoading: false,
      variant: "primary",
    },
    confirmAction,
    confirmLabel,
    confirmButtonProps = {
      isLoading: false,
      variant: "primary",
    },
    className,
    dismissOnOutsideClick = true,
    hideCrossButton = false,
  } = props;

  const modal = (
    <div
      onClick={() => {
        if (dismissOnOutsideClick) {
          setIsOpen(false);
        }
      }}
      className={cn(
        "pointer-events-none fixed inset-0 z-[999] grid h-screen w-screen place-items-center bg-black bg-opacity-60 opacity-0 backdrop-blur-sm transition-opacity duration-300",
        {
          "opacity-1 pointer-events-auto": isOpen,
        },
      )}
    >
      <div className={cn("relative mx-auto my-6 w-full max-w-3xl", className)}>
        <div
          className="rounded-lg bg-white shadow-md"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className="border-light-2 flex w-full items-center justify-between border-b px-[20px] py-4">
            <Typography className="text-primary font-bold">{title}</Typography>
            {!hideCrossButton && (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpen(false);
                  negativeAction?.();
                }}
                label="Fermer"
                variant="ghost"
                singleIcon="Cross"
              />
            )}
          </div>

          <div className="max-h-[50dvh] overflow-scroll px-[20px] py-[16px]">
            {content}
            {children}
          </div>

          <div
            className={cn(
              "border-light-2 flex w-full flex-col items-center gap-3 border-t px-[20px] py-4 sm:flex-row",
              {
                "justify-between": confirmLabel && negativeLabel,
                "justify-end": confirmLabel && !negativeLabel,
              },
            )}
          >
            {negativeLabel && (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  negativeAction && negativeAction();
                }}
                label={negativeLabel}
                variant={negativeButtonProps.variant}
                className={negativeButtonProps.className}
                isLoading={negativeButtonProps.isLoading}
              />
            )}
            {confirmLabel && (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  confirmAction && confirmAction();
                }}
                label={confirmLabel}
                variant={confirmButtonProps.variant}
                className={confirmButtonProps.className}
                isLoading={confirmButtonProps.isLoading}
                disabled={confirmButtonProps.disabled}
                iconRight={confirmButtonProps.iconRight}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );

  return createPortal(modal, document.body);
};

export { Modal };
