import React from "react";
import { Outlet } from "react-router-dom";

import { CirclesListingPageHead } from "./components/circles-listing-page-head";

export const CircleListingPage = () => {
  return (
    <section className="container mx-auto mb-6 mt-6 px-6 ">
      <CirclesListingPageHead />
      <div className="my-6">
        <Outlet />
      </div>
    </section>
  );
};
