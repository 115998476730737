import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import { Project } from "@tudigo-monorepo/core-tudigo-api-models";
import { useGetProjectById } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  PromotionalBlocks,
  ShareButton,
} from "@tudigo-monorepo/web-tudigo-components";
import {
  hubspotTrackPageView,
  mixpanelTrackEvent,
  mixpanelTrackPageView,
  useTrack,
} from "@tudigo-monorepo/web-tudigo-tracking";

import { ProjectPageRoutesWebApp } from "../../routes/web-app/project-page/project-page-routes.web-app";
import { AppointmentCard } from "../../shared/components/appointment-card";
import { BlockProjectIntroTitle } from "./introduction/block-project-intro.title";
import { BlockProjectCarousel } from "./introduction/block.project-carousel";
import { BlockProjectInvestCard } from "./introduction/block.project-invest-card";
import { ProjectPageNavigation } from "./project-page-navigation";
import { ProjectContext } from "./project.context";
import { ProjectSidebar } from "./sidebar/project-sidebar";

type ProjectPageProps = {
  projectId?: string;
  project?: Project;
};

const projectPageFields =
  "id,content,mechanic,maturity,bonds_mechanic_settings,shares_mechanic_settings,goal,marketing_settings,scheduled_start_date,scheduled_end_date,started_at,finished_at,categories,tags,tax_exemption_mechanics,impact_score,currency,total_amount_collected_in_cents,total_investors_count,fundraising_progress_min_goal,fundraising_progress_max_goal,status,project_agreement_and_security_contract,project_documents,is_referenced,my_ongoing_investment";

export function ProjectPage(props: ProjectPageProps) {
  const { project } = props;

  const { slug: projectSlug } = useParams();

  const { data: projectFromApi } = useGetProjectById({
    projectId: projectSlug,
    query: {
      fields: projectPageFields,
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useTrack(() => {
    if (!projectFromApi) return false;
    mixpanelTrackEvent("project-page-viewed", {
      projectId: projectFromApi.id,
    });
    mixpanelTrackPageView("project-page", {
      projectId: projectFromApi.id,
    });
    if (projectFromApi.content?.name) {
      document.title = `Tudigo - Projet ${projectFromApi?.content?.name}`;
      hubspotTrackPageView();
    }

    return true;
  }, [projectFromApi]);

  if (!projectSlug) {
    console.error(
      "Tried to display a project without any slug identifier in the URL",
    );

    return null;
  }

  const currentProject = project || projectFromApi;

  if (!currentProject) {
    return <div className="h-screen" />;
  }

  return (
    <ProjectContext.Provider value={currentProject}>
      {!currentProject?.isReferenced && (
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      )}

      <section className="container mx-auto mb-6 mt-6 grid grid-cols-1 gap-x-8 gap-y-4 px-4 lg:grid-cols-3">
        <div className="col-span-2 lg:col-span-3">
          <BlockProjectIntroTitle />
        </div>
        <div className="col-span-2 lg:col-span-2">
          <BlockProjectCarousel />
        </div>
        <div className="relative col-span-2 flex items-start lg:col-span-1">
          <ShareButton className="absolute -top-[54px] right-0 hidden lg:block" />
          <div className="h-full w-full items-end">
            <BlockProjectInvestCard />
          </div>
        </div>
      </section>

      <section className="@container container mx-auto mt-6 grid grid-cols-1 gap-x-8 gap-y-4 px-4 lg:grid-cols-3">
        {currentProject?.status !== "finished" && (
          <div className="order-2 col-span-2 lg:order-1 lg:col-span-3">
            <ProjectPageNavigation />
          </div>
        )}
        <div className="order-3 col-span-2 lg:order-2 lg:col-span-2">
          <div className="mb-13">
            <ProjectPageRoutesWebApp />
          </div>
          <div className="-mr-8 mt-6">
            <PromotionalBlocks className="bg-light-3 px-6 py-8 md:-ml-4 xl:-ml-0">
              <AppointmentCard />
            </PromotionalBlocks>
          </div>
        </div>
        <div className="order-1 col-span-2 lg:order-3 lg:col-span-1">
          <ProjectSidebar />
        </div>
      </section>
    </ProjectContext.Provider>
  );
}
