import { PropsWithChildren } from "react";

import { ProjectContent } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Icon } from "../../../old/icons/icon";

type ProjectCardCoverProps = PropsWithChildren<{
  projectContent: ProjectContent | null;
  onClick?: () => void;
  className?: string;
}>;

export function ProjectCardRebrandCover({
  projectContent,
  className,
  children,
  onClick,
}: ProjectCardCoverProps) {
  const coverImage =
    projectContent?.cover?.url || projectContent?.cover?.base64 || undefined;

  return (
    <div onClick={onClick} className={cn("relative", className)}>
      {coverImage ? (
        <img
          src={coverImage}
          alt={projectContent?.name || "Project image"}
          loading="lazy"
          className={cn(
            "h-[240px] w-full rounded-tl-lg rounded-tr-lg object-cover",
          )}
        />
      ) : (
        <div
          className={cn(
            "flex h-[240px] items-center justify-center rounded-tl-lg rounded-tr-lg bg-gray-100",
          )}
          aria-label={projectContent?.name || "Project image"}
        >
          <Icon
            name="Image"
            className="h-10 w-10"
            primaryColor={themeColors["dark-4"]}
          />
        </div>
      )}

      {children}
    </div>
  );
}
