import React from "react";

import {
  InvestmentDocument,
  InvestmentFeed,
  ProjectDocument,
} from "@tudigo-monorepo/core-tudigo-api-models";

import { NewsItemProvider } from "../../../shared/components/news-invest/context/news-item-provider";
import { NewsDocument } from "../../../shared/components/news-invest/news-document";
import { NewsInformations } from "../../../shared/components/news-invest/news-informations";
import { InvestmentDocumentListElement } from "./investment-document-list-element";
import { ProjectDocumentListElement } from "./project-document-list-element";

export function InvestmentFeedListElement(props: {
  investmentFeedItem: InvestmentFeed;
}) {
  const investmentFeedItem = props.investmentFeedItem;

  switch (investmentFeedItem.refType) {
    case "investment_document":
      return (
        <InvestmentDocumentListElement
          investmentDocument={
            investmentFeedItem.investmentDocument as InvestmentDocument
          }
        />
      );
    case "project_document":
      return (
        <ProjectDocumentListElement
          projectDocument={
            investmentFeedItem.projectDocument as ProjectDocument
          }
        />
      );
    case "news":
      return (
        investmentFeedItem?.news && (
          <NewsItemProvider news={investmentFeedItem.news}>
            {investmentFeedItem.news.type === "document" && <NewsDocument />}
            {investmentFeedItem.news.type === "information" && (
              <NewsInformations />
            )}
          </NewsItemProvider>
        )
      );
  }
}
