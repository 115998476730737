"use client";

import * as React from "react";
import * as SwitchPrimitives from "@radix-ui/react-switch";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      `
      focus-visible:ring-ring
      focus-visible:ring-offset-background
      bg-accent-light
      peer inline-flex h-7 w-12 shrink-0 cursor-pointer items-center rounded-full
      border-[4px] border-transparent transition-colors focus-visible:outline-none
      focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed
      disabled:opacity-50`,
      className,
    )}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        `bg-primary-rebrand pointer-events-none block h-5 w-5 rounded-full
        shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-[20px]
        data-[state=unchecked]:translate-x-0`,
      )}
    />
  </SwitchPrimitives.Root>
));

export { Switch };
