import React, {
  Dispatch,
  ReactElement,
  ReactNode,
  Ref,
  SetStateAction,
  useMemo,
  useRef,
  useState,
} from "react";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

import { Dropdown } from "../dropdown/dropdown";
import DropdownMenu from "../dropdown/dropdown-menu";
import DropdownItem from "../dropdown/dropdown-menu-item";
import DropdownToggle from "../dropdown/dropdown-toggle";
import { Icon } from "../icons/icon";
import { useFocus } from "../input-group";
import { InputGroup } from "../input-group/input-group";
import { TextField } from "../text-field";

type AutoCompleteProps<T> = {
  label?: string | ReactNode;
  errors?: string[];
  placeholder?: string;
  items: T[];
  selection?: T;
  getId: (item: T) => string;
  renderItem: (item: T, highlight?: boolean) => ReactElement;
  filterFn: (item: T, value: string) => boolean;
  onSelect: (value: T) => void;
  required?: boolean;
  className?: string;
  searchValue?: string;
  groupRef?: Ref<HTMLDivElement>;
  setSearchValue?: Dispatch<SetStateAction<string>>;
  disabled?: boolean;
};

export function AutoComplete<T>(props: AutoCompleteProps<T>) {
  const listRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const {
    groupRef,
    className,
    required,
    onSelect,
    items,
    filterFn,
    selection,
    label,
    errors,
    searchValue: searchValueProp,
    setSearchValue: setSearchValueProp,
    disabled = false,
  } = props;
  const [selectionIndex, setSelectionIndex] = useState<number>();
  const [show, setShow] = useState(false);
  let [searchValue, setSearchValue] = useState<string>("");

  if (searchValueProp !== undefined) {
    searchValue = searchValueProp;
  }
  if (setSearchValueProp !== undefined) {
    setSearchValue = setSearchValueProp;
  }

  const [inputRef, focusAutoComplete] = useFocus();

  const filteredItems = useMemo(() => {
    return items.filter((item) => {
      return filterFn(item, searchValue);
    });
  }, [filterFn, items, searchValue]);

  const onSelectItem = (item: T) => {
    onSelect(item);
    setShow(false);
    setSearchValue("");
  };

  const select = (index?: number, autoScroll?: boolean) => {
    setSelectionIndex(index);
    if (autoScroll && index !== undefined) {
      if (listRef.current) {
        const clientHeight = listRef.current.clientHeight;
        const currentScrollOffset = listRef.current.scrollTop;
        let endOffset = 0;
        let startOffset = 0;
        for (let i = 0; i <= index; i++) {
          const itemElement = listRef.current.children.item(i);
          if (itemElement) {
            const itemHeight = itemElement.getBoundingClientRect().height;
            endOffset += itemHeight;
            startOffset = endOffset - itemHeight;
          }
        }

        if (startOffset < currentScrollOffset) {
          listRef.current.scrollTop = startOffset;
        } else if (endOffset > clientHeight + currentScrollOffset) {
          listRef.current.scrollTop = endOffset - clientHeight;
        }
      }
    }
  };

  const searchOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const key = e.key || e.keyCode;
    if (key === "ArrowDown" || key === 40) {
      if (filteredItems.length > 0) {
        if (selectionIndex === undefined) {
          select(0, true);
        } else if (filteredItems.length > selectionIndex + 1) {
          select(selectionIndex + 1, true);
        } else {
          select(filteredItems.length - 1, true);
        }
      }
    }

    if (key === "ArrowUp" || key === 38) {
      if (filteredItems.length > 0) {
        if (selectionIndex === undefined) {
          select(0, true);
        } else if (selectionIndex > 0) {
          select(selectionIndex - 1, true);
        }
      }
    }
    if (key === "Enter" || key === 13) {
      if (
        selectionIndex !== undefined &&
        filteredItems.length > selectionIndex
      ) {
        onSelectItem(filteredItems[selectionIndex]);
        e.stopPropagation();
        e.preventDefault();
      }
    }
  };

  return (
    <Dropdown
      show={show}
      onToggle={(open) => {
        if (!disabled) {
          setShow(open);
          if (inputRef.current) {
            setTimeout(() => {
              focusAutoComplete(open);
            }, 0);
          }
        }
      }}
    >
      <DropdownToggle className="flex flex-row items-center justify-between gap-2">
        <InputGroup
          hasValue={!!selection}
          errors={errors}
          label={label}
          active={show}
          required={required}
          groupRef={groupRef}
          disabled={disabled}
          iconRight={
            <Icon
              className={show ? "rotate-180 transform" : ""}
              name="ChevronDown"
              primaryColor={themeColors["dark-2"]}
              size="S"
              height={18}
              width={18}
            />
          }
          renderInput={(inputProps) => {
            return (
              <div className="flex w-full flex-row items-center justify-between">
                <div className={inputProps.className}>
                  {selection
                    ? props.renderItem(selection)
                    : props.placeholder ??
                      t("component.autocomplete.placeholder.select")}
                </div>
              </div>
            );
          }}
        />
      </DropdownToggle>
      <DropdownMenu>
        <TextField
          ref={inputRef}
          placeholder={t("component.autocomplete.placeholder.search")}
          value={searchValue}
          autoComplete="off"
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={searchOnKeyDown}
          autoFocus
        />
        <div className="border-light-1 border-b" />
        <div
          ref={listRef}
          className={cn("max-h-[200px] overflow-auto", className)}
        >
          {filteredItems.map((item, index) => (
            <div
              key={index}
              onMouseOver={() => {
                select(index);
              }}
              onMouseOut={() => {
                select(undefined);
              }}
              onClick={() => {
                onSelectItem(item);
              }}
            >
              <DropdownItem active={selectionIndex === index}>
                {props.renderItem(item, selectionIndex === index)}
              </DropdownItem>
            </div>
          ))}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
}

export default AutoComplete;
