import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { getSignatureRequestSyncById } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  Button,
  SignatureIframe,
  SlidingPanel,
} from "@tudigo-monorepo/web-tudigo-components";

import { useMyInvestmentAssemblyPageContext } from "../../investment-assembly-page/my-investment-assembly-page";

export function ProxySignatureSlidingPanel() {
  const { assemblyParticipant } = useMyInvestmentAssemblyPageContext();
  const [isSlidingPanelOpen, setIsSlidingPanelOpen] = useState(false);

  const queryClient = useQueryClient();

  const onProxySignatureSuccess = async () => {
    await getSignatureRequestSyncById(
      assemblyParticipant.proxySignatureRequest!.id,
    );
    await queryClient.invalidateQueries({
      queryKey: [`assembly_participant-${assemblyParticipant.id}`],
    });
    toast((content) => (
      <Alert
        icon="Upload"
        close={content.closeToast}
        variant="success"
        size="S"
        title="Signature de la procuration enregistrée"
        description="Votre procuration a bien été signée. Vous pouvez désormais revenir à la page de l'assemblée."
      />
    ));
  };

  if (
    assemblyParticipant.proxySignatureRequest === null ||
    assemblyParticipant.proxySignatureRequest.mySignatureLink === null
  ) {
    return null;
  }

  return (
    <>
      <Button
        onClick={() => setIsSlidingPanelOpen(true)}
        label="Signer la demande de procuration"
      />

      <SlidingPanel
        isOpen={isSlidingPanelOpen}
        setIsOpen={setIsSlidingPanelOpen}
      >
        <div className="flex h-full flex-col">
          <Alert
            className="mb-4"
            icon="InformationIcon"
            variant="warning"
            size="F"
            title="Signature procuration :"
            description="La signature de ce document est nécessaire pour valider votre procuration.Une fois le document signé vous ne pourrez plus modifier votre choix et revenir en arrière."
          />
          <SignatureIframe
            signatureLink={
              assemblyParticipant.proxySignatureRequest.mySignatureLink
            }
            onSuccess={onProxySignatureSuccess}
          />
        </div>
      </SlidingPanel>
    </>
  );
}
