import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import useAuthStore from "@tudigo-monorepo/web-tudigo-auth-store";

const apiBaseUrl = import.meta.env.VITE_API_URL as string;

export function LogoutPage() {
  const navigate = useNavigate();
  const reset = useAuthStore((state) => state.reset);

  const searchParams = useMemo(() => {
    return new URLSearchParams(window.location.search);
  }, []);

  useEffect(() => {
    if (searchParams.has("logout")) {
      navigate("/");
      reset();
    }
  }, [navigate, reset, searchParams]);

  if (searchParams.has("logout")) {
    return <div></div>;
  }

  const redirectUri = encodeURIComponent(
    `${window.location.origin}/logout?logout=true`,
  );

  document.location.href = `${apiBaseUrl}/logout?target=${redirectUri}`;

  return <div></div>;
}

export default LogoutPage;
