import React from "react";
import { useParams } from "react-router-dom";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useGetClubMember } from "@tudigo-monorepo/web-tudigo-api-client";
import { Breadcrumbs, Chip } from "@tudigo-monorepo/web-tudigo-components";

import { ClubLeaderMemberDetailInformationCard } from "./components/club-leader-member-detail-information-card";
import { ClubLeaderMemberDetailStatisticsCard } from "./components/club-leader-member-detail-statistics-card";
import { ClubLeaderMemberDetailSubscriptionPeriodsTable } from "./components/club-leader-member-detail-subscription-periods-table";

export const ClubLeaderMemberDetailPage = () => {
  const clubId = useParams().clubId as string;
  const memberId = useParams().id as string;
  const { t } = useTranslation();
  const clubFields =
    "user.fields(investor_profile,first_name,last_name,created_at),statistics,investments,status,subscription_periods";
  const { data: member } = useGetClubMember({
    clubId,
    clubMemberId: memberId,
    query: {
      fields: clubFields,
    },
  });

  const clubMember = member?.data;
  if (!clubMember) {
    return null;
  }

  return (
    <>
      <div className="border-light-1 mx-6 flex flex-col gap-6 border-b py-4">
        <Breadcrumbs
          levels={[
            {
              url: `/club-leader/${clubId}/members`,
              iconName: "Group",
            },
          ]}
        />
        <Chip
          label={t(`club_member.status.${clubMember.status}`)}
          type="secondary"
        />
        <h1 className="text-primary font-obviously text-[27px] font-[570]">
          {t("club_leader.page_detail_member.title")}
        </h1>
      </div>
      <div className="flex flex-col md:flex-row">
        <ClubLeaderMemberDetailStatisticsCard clubMember={clubMember} />
        <ClubLeaderMemberDetailInformationCard clubMember={clubMember} />
      </div>
      {clubMember.status === "active" && (
        <ClubLeaderMemberDetailSubscriptionPeriodsTable
          clubMember={clubMember}
        />
      )}
    </>
  );
};
