import {
  EquitySavingPlan,
  Organization,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  Chip,
  Icon,
  ListElement,
} from "@tudigo-monorepo/web-tudigo-components";

import { useOrganizationKycEquitySavingPlanStatusChipProps } from "../../hooks/use-organization-kyc-equity-saving-plan-status-chip-props";

type InvestmentAccountListElementProps = {
  equitySavingPlan?: EquitySavingPlan;
  navigateTo?: string;
  organization?: Organization;
  asLink?: boolean;
  onClick?: () => void;
};
export function InvestmentAccountListElement(
  props: InvestmentAccountListElementProps,
) {
  const {
    equitySavingPlan,
    organization,
    navigateTo,
    asLink = false,
    onClick,
  } = props;

  const { t } = useTranslation();

  const chipStatusProps = useOrganizationKycEquitySavingPlanStatusChipProps(
    equitySavingPlan,
    organization,
  );

  if (!equitySavingPlan) {
    return null;
  }

  const displayedAccountType =
    equitySavingPlan.type === "pea" ? "PEA" : "PEA-PME";

  return (
    <ListElement
      onClick={onClick}
      navigateTo={navigateTo}
      className={cn({ "bg-light-2": equitySavingPlan.invalidatedAt })}
      startElement={{
        upperText: t(
          "organizations.back_office.edit_organization_page.account.list_element.start_element.upper_text",
        ),
        lowerText: `N°${equitySavingPlan.accountNumber}`,
        img: {
          icon: { name: "Profile" },
        },
        profileCardClassName: "min-w-[280px] flex justify-start px-3 py-2",
      }}
      middleElement={{
        upperText: t(
          "organizations.back_office.edit_organization_page.account.list_element.middle_element.upper_text",
        ),
        chips: [
          {
            label: displayedAccountType,
            type: "secondary",
          },
        ],
      }}
      endElement={{
        render: () => {
          return (
            <div className="flex items-center gap-x-8">
              {!!chipStatusProps && <Chip {...chipStatusProps} />}
              {(navigateTo || asLink) && (
                <Icon
                  name="ChevronRight"
                  primaryColor={themeColors["dark-2"]}
                />
              )}
            </div>
          );
        },
      }}
    />
  );
}
