import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";

import { Investment, Project } from "@tudigo-monorepo/core-tudigo-api-models";
import { ContextIntl } from "@tudigo-monorepo/core-tudigo-translations";
import {
  useGetInvestmentById,
  useGetProjectById,
  useInvalidateInvestmentQueryKey,
} from "@tudigo-monorepo/web-tudigo-api-client";

import {
  getAvailableStepsForInvestment,
  getFunnelStepsConfiguration,
} from "../funnel-utils";
import { InvestmentFunnelNavigation } from "./partials/investment-funnel-navigation";

export type InvestmentFunnelPageContext = {
  project: Project;
  ongoingInvestment?: Investment;
  investmentFields: string;
  invalidateOngoingInvestment: () => Promise<void>;
  availableSteps: string[];
  stepsConfig: { id: string; label: string; path: string; index: number }[];
  setActiveStepIndex: (path: string) => void;
  dispatchToLastStepAvailable: () => void;
};

export const investmentFunnelFields =
  "id,global_status,payment_status,subscription_form_status,created_at,updated_at,project.fields(id,content,mechanic,maturity,bonds_mechanic_settings,shares_mechanic_settings,goal,marketing_settings,scheduled_start_date,started_at,finished_at,categories,tags,tax_exemption_mechanics,impact_score,currency,holder_organization.fields(id,name,internal_kyc_status),vehicle_organization.fields(id,name,internal_kyc_status),total_amount_collected_in_cents,fundraising_progress_min_goal,fundraising_progress_max_goal,status,project_agreement_and_security_contract,project_documents,commitment_letter_signing_enabled),currency,total_amount_in_cents,unit_price_in_cents,quantity,investor_organization.fields(id,name,type,ultimate_beneficial_owners,organization_bank_accounts,documents,organization_representative,pms_account,internal_kyc_status,ready_to_subscribe),user,is_investor_organization_kyc_valid,payment_method_type,documents,payment_date,subscription_form_document,project_agreement_and_security_consent,payment_intents,commitment_letter_document";

export function InvestmentFunnelPage() {
  const projectId = useParams().projectId as string;
  const investmentId = useParams().investmentId as string;
  const navigate = useNavigate();
  const location = useLocation();
  const invalidateOngoingInvestment = useInvalidateInvestmentQueryKey(
    investmentId,
    investmentFunnelFields,
  );
  const stepsConfig = useMemo(
    () => getFunnelStepsConfiguration(projectId, investmentId),
    [investmentId, projectId],
  );

  const { locale } = useContext(ContextIntl);

  const projectFields = `id,content,mechanic,maturity,bonds_mechanic_settings,shares_mechanic_settings,goal,marketing_settings,scheduled_start_date,started_at,finished_at,categories,tags,tax_exemption_mechanics,impact_score,currency,holder_organization.fields(id,name,internal_kyc_status),vehicle_organization.fields(id,name,internal_kyc_status),total_amount_collected_in_cents,fundraising_progress_min_goal,fundraising_progress_max_goal,status,project_agreement_and_security_contract.locale(${locale}),project_documents,commitment_letter_signing_enabled`;

  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [availableSteps, setAvailableSteps] = useState<string[]>([]);

  const { data: project } = useGetProjectById({
    projectId: projectId,
    query: {
      fields: projectFields,
    },
  });

  const {
    data: investment,
    isLoading: investmentQueryIsLoading,
    refetch,
  } = useGetInvestmentById({
    investmentId: investmentId,
    query: {
      fields: investmentFunnelFields,
    },
  });

  useEffect(() => {
    if (!investmentQueryIsLoading) {
      if (!investment) {
        navigate(`/projects/${project?.content?.slug ?? projectId}`);

        return;
      }
      setAvailableSteps(
        getAvailableStepsForInvestment(investment, stepsConfig),
      );
    }
  }, [investmentQueryIsLoading, investment, stepsConfig]);

  const dispatchToLastStepAvailable = useCallback(async () => {
    await refetch();

    const uri = !availableSteps
      ? stepsConfig[0].path
      : `/projects/${projectId}/invest/${investmentId}/${availableSteps.slice(
          -1,
        )}${location.search ? location.search : ""}`;

    navigate(uri, { replace: true });
  }, [availableSteps, navigate, projectId, stepsConfig, location.search]);

  const updateActiveStepIndex = (path: string) => {
    const step = stepsConfig.find((step) => step.path === path);
    if (step) setActiveStepIndex(step.index);
  };

  useEffect(() => {
    const updateStepBasedOnLocation = () => {
      const currentStep = stepsConfig.find(
        (step) => step.path === location.pathname,
      );
      if (currentStep && availableSteps.includes(currentStep.id)) {
        setActiveStepIndex(currentStep.index);
      } else {
        dispatchToLastStepAvailable();
      }
    };

    updateStepBasedOnLocation();
  }, [location.pathname, stepsConfig, availableSteps]);

  if (!project || !availableSteps) {
    return null;
  }

  return (
    <div id="funnel-investments-page" className="w-full">
      <InvestmentFunnelNavigation
        title="Investissement"
        onBackButtonClick={() =>
          navigate(`/projects/${project?.content?.slug ?? projectId}`)
        }
        steps={stepsConfig.map((step) => ({
          ...step,
          navigateTo: availableSteps.includes(step.id) ? step.path : undefined,
        }))}
        activeStepIndex={activeStepIndex}
      />

      <div className="mx-auto mb-20 mt-[104px] w-full overflow-y-auto overflow-x-hidden px-4 py-8 sm:w-2/3 sm:overflow-x-visible sm:px-12 sm:pb-16">
        <Outlet
          context={{
            project,
            investmentFields: investmentFunnelFields,
            ongoingInvestment: investment,
            invalidateOngoingInvestment,
            availableSteps,
            stepsConfig,
            setActiveStepIndex: updateActiveStepIndex,
            dispatchToLastStepAvailable,
          }}
        />
      </div>
    </div>
  );
}

export function useInvestmentFunnelPageContext() {
  return useOutletContext<InvestmentFunnelPageContext>();
}
