export function toScaledNumber(num: number): number {
  return num * 100;
}

export function toRegularNumber(num: number): number {
  return num / 100;
}

export function decimalToPercent(num: number): number {
  return num * 100;
}
