import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";

import { UserInvestmentFeedFilters } from "@tudigo-monorepo/web-tudigo-api-client";
import { TabButtons, Title } from "@tudigo-monorepo/web-tudigo-components";

import { InvestmentFeedList } from "../components/investment-feed-list";
import { useMyInvestmentPageContext } from "../hooks/use-my-investment-page-context";

export function InvestmentDocumentsContent() {
  const { project, userId } = useMyInvestmentPageContext();

  const { filter: pathFilter } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();

  const [filter, setFilter] = React.useState<
    "all-documents-only" | "investment_documents" | "news"
  >("all-documents-only");
  const [requestFilter, setRequestFilter] =
    React.useState<UserInvestmentFeedFilters>(undefined);
  const [activeTabsButton, setActiveTabsButton] = React.useState<number>(0);

  useEffect(() => {
    if (pathFilter === "personal") {
      setFilter("investment_documents");
      setRequestFilter(undefined);
      setActiveTabsButton(1);
    } else if (pathFilter === "global") {
      setFilter("news");
      setRequestFilter({ type: "document" });
      setActiveTabsButton(2);
    } else {
      setFilter("all-documents-only");
      setRequestFilter(undefined);
      setActiveTabsButton(0);
    }
  }, [pathFilter]);

  return (
    <div className="m-6">
      <div className="tab-filter flex flex-row items-center justify-between  py-6">
        <Title h3Brand="brand-2" className="text-primary mb-6" level="h3">
          {intl.formatMessage({
            id: "my-investments.my_investment_page.documents.all_documents.title",
          })}
        </Title>
        <div className="w-[258px]">
          <TabButtons
            activeButtonIndex={activeTabsButton}
            buttons={[
              {
                text: intl.formatMessage({
                  id: "my-investments.my_investment_page.documents.all_documents.tab-buttons.all",
                }),
                callback: () => {
                  navigate(
                    `/my-account/my-investments/projects/${project.id}/documents/all`,
                  );
                },
              },
              {
                text: intl.formatMessage({
                  id: "my-investments.my_investment_page.documents.all_documents.tab-buttons.personal",
                }),
                callback: () => {
                  navigate(
                    `/my-account/my-investments/projects/${project.id}/documents/personal`,
                  );
                },
              },
              {
                text: intl.formatMessage({
                  id: "my-investments.my_investment_page.documents.all_documents.tab-buttons.global",
                }),
                callback: () => {
                  navigate(
                    `/my-account/my-investments/projects/${project.id}/documents/global`,
                  );
                },
              },
            ]}
          />
        </div>
      </div>

      <InvestmentFeedList
        project={project}
        userId={userId}
        filter={filter}
        requestFilters={requestFilter}
      />
    </div>
  );
}
