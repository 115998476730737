import { useEffect } from "react";
import { useCurrentRefinements, useInstantSearch } from "react-instantsearch";

import {
  PromotionalBlocks,
  Sidebar,
  Title,
  useToggler,
} from "@tudigo-monorepo/web-tudigo-components";

import { AppointmentCard } from "../../shared/components/appointment-card";
import { ProjectsPageHeader } from "./projects-page-header";
import { ProjectsPageMainContent } from "./projects-page-main-content";
import { ProjectsPageMenuMobile } from "./projects-page-menu-mobile";
import { CustomSearchBox } from "./shared/component/custom-search-box";
import { ProjectsFilters } from "./shared/component/projects-filters";
import { ResetFilters } from "./shared/component/reset-filters";

export function ProjectsPage() {
  const { results, error } = useInstantSearch();
  const { items: currentRefinements, canRefine: hasRefinements } =
    useCurrentRefinements();

  const [isMenuMobileOpen, toggleIsMenuMobileOpen] = useToggler();

  const projectsCount = results.nbHits;
  const hasQuery = results.query.length > 0;
  const hasQueryResults = (hasQuery || hasRefinements) && projectsCount >= 1;
  const hasEmptyResults = (hasQuery || hasRefinements) && projectsCount === 0;
  const mergedRefinementsCount = currentRefinements
    .map(({ refinements }) => refinements)
    .flat().length;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [mergedRefinementsCount]);

  return (
    <div className="flex w-full">
      <Sidebar
        expanded
        className="h-screen-custom top-[75px] w-[264px] gap-y-8 overflow-y-scroll px-4 lg:flex"
      >
        <div className="flex items-center justify-between">
          <Title level="h3" h3Brand="brand-2">
            Filtres
          </Title>
          <ResetFilters visible={hasRefinements} />
        </div>
        <ProjectsFilters />
      </Sidebar>

      <ProjectsPageMenuMobile visible={isMenuMobileOpen}>
        <ProjectsPageMenuMobile.Head onClose={toggleIsMenuMobileOpen} />
        <ProjectsPageMenuMobile.Actions>
          <ProjectsFilters collapseMode />
        </ProjectsPageMenuMobile.Actions>
        <ProjectsPageMenuMobile.Footer
          hasFilters={hasRefinements}
          projectsCount={projectsCount}
          onClose={toggleIsMenuMobileOpen}
        />
      </ProjectsPageMenuMobile>

      <div className="flex h-full w-full flex-col gap-y-6 overflow-x-hidden">
        <div className="px-4 py-6 lg:px-6">
          <ProjectsPageHeader>
            <ProjectsPageHeader.Top projectsCount={projectsCount} />
            <ProjectsPageHeader.Actions>
              <CustomSearchBox />
              <ProjectsPageHeader.ActionsMobile projectsCount={projectsCount}>
                <ProjectsPageHeader.MenuTrigger
                  onTriggerClick={toggleIsMenuMobileOpen}
                />
              </ProjectsPageHeader.ActionsMobile>
            </ProjectsPageHeader.Actions>
          </ProjectsPageHeader>

          <ProjectsPageMainContent>
            <ProjectsPageMainContent.Fallback error={error} />
            <ProjectsPageMainContent.Results
              visible={hasQueryResults && !error}
            />
            <ProjectsPageMainContent.EmptyResults visible={hasEmptyResults} />
            <ProjectsPageMainContent.ProjectsGallery
              visible={!hasQueryResults && !hasEmptyResults}
            />
          </ProjectsPageMainContent>
        </div>

        <PromotionalBlocks className="bg-light-3 px-6 py-8 md:-ml-4 xl:-ml-0">
          <AppointmentCard />
        </PromotionalBlocks>
      </div>
    </div>
  );
}
