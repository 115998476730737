import { useState } from "react";

import { User } from "@tudigo-monorepo/core-tudigo-api-models";
import { dispatchImpersonateUser } from "@tudigo-monorepo/core-tudigo-utils";

import { Modal } from "../old/modal";
import { UsersAutocomplete } from "./users-autocomplete";

type SwitchUserModalProps = {
  isOpen: boolean;
  onClose?: () => void;
};

export function SwitchUserModal({ isOpen, onClose }: SwitchUserModalProps) {
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const handleSelectUser = (user: User) => setSelectedUser(user);

  const handleSwitchUser = () => {
    if (selectedUser) {
      dispatchImpersonateUser({ user: selectedUser });
    }
  };

  const handleConfirm = () => {
    handleSwitchUser();
    onClose?.();
  };

  const handleCancel = () => {
    if (selectedUser) {
      setSelectedUser(null);
    }
    onClose?.();
  };

  return (
    <Modal
      title="Switch User"
      isOpen={isOpen}
      setIsOpen={() => {}}
      confirmLabel="Confirmer"
      confirmAction={handleConfirm}
      negativeAction={handleCancel}
      confirmButtonProps={{ disabled: !selectedUser }}
      className="h-[605px] min-h-[605px] w-[calc(100%-32px)] lg:w-[423px] lg:max-w-[654px]"
    >
      <div className="flex w-full flex-col gap-y-4 p-4">
        <UsersAutocomplete
          className="z-[1000] w-[calc(100vw-32px)] lg:w-[351px]"
          onSelectUser={handleSelectUser}
        />
      </div>
    </Modal>
  );
}
