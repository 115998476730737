import React from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { useIntl } from "react-intl";

import { Investment } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";
import { getInvestmentPaymentMethodTypeTranslateKey } from "@tudigo-monorepo/web-feature-investments";
import {
  AccountCard,
  Avatar,
  Chip,
} from "@tudigo-monorepo/web-tudigo-components";

export const useClubLeaderProjectInvestmentsColumns = () => {
  const columnHelper = createColumnHelper<Investment>();
  const intl = useIntl();
  const { t } = useTranslation();

  return [
    columnHelper.display({
      header: t(
        "club_leader.page_club_leader_project_detail.investments_table.columns.name.label",
      ),
      cell: (info) => (
        <AccountCard
          avatar={
            info.row.original.investorOrganization?.logo?.url ? (
              <Avatar url={info.row.original.investorOrganization?.logo.url} />
            ) : (
              <Avatar>
                <span>🦊</span>
              </Avatar>
            )
          }
          upperText={
            info.row.original.investorOrganization?.name ??
            `${info.row.original.user?.firstName} ${info.row.original.user?.lastName}`
          }
          lowerText={info.row.original.investorOrganization?.type}
          lowerTextAsChip
        />
      ),
    }),
    columnHelper.accessor("totalAmountInCents", {
      header: t(
        "club_leader.page_club_leader_project_detail.investments_table.columns.total_amount.label",
      ),
      cell: (info) => (
        <p className="text-primary text-sm">
          {formatCurrency(info.row.original.totalAmountInCents)}
        </p>
      ),
    }),
    columnHelper.accessor("globalStatus", {
      header: t(
        "club_leader.page_club_leader_project_detail.investments_table.columns.status.label",
      ),
      cell: (info) => (
        <Chip
          label={t(
            `investment.global_status.${info.row.original.globalStatus}`,
          )}
          iconLeft="DotChip"
          type={(() => {
            switch (info.row.original.globalStatus) {
              case "validated":
                return "success";
              case "transfer_funds_failed":
                return "error";
              default:
                return "warning";
            }
          })()}
        />
      ),
    }),
    columnHelper.accessor("createdAt", {
      header: t(
        "club_leader.page_club_leader_project_detail.investments_table.columns.created_at.label",
      ),
      cell: (info) => (
        <p className="text-primary text-sm">
          {intl.formatDate(info.row.original.createdAt)}
        </p>
      ),
    }),
    columnHelper.accessor("paymentMethodType", {
      header: t(
        "club_leader.page_club_leader_project_detail.investments_table.columns.payment_method_type.label",
      ),
      cell: (info) => (
        <p className="text-primary text-sm">
          {intl.formatMessage({
            id: getInvestmentPaymentMethodTypeTranslateKey(info.row.original),
          })}
        </p>
      ),
    }),
  ];
};
