import { useCallback, useState } from "react";

import {
  WMACustomer,
  WMAProfile,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { wmaCustomersFields } from "@tudigo-monorepo/web-feature-wma";
import { useGetWmaCustomersByWmaUserId } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Button,
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
  CommandLoading,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@tudigo-monorepo/web-tudigo-components";

export type WmaCustomersAutocompleteProps = {
  label?: string;
  emptyMessage?: string;
  placeholder?: string;
  className?: string;
  wmaUserId?: WMAProfile["id"];
  onSelectCustomer: (customer: WMACustomer) => void;
};

export const WmaCustomersAutocomplete = (
  props: WmaCustomersAutocompleteProps,
) => {
  const {
    label,
    emptyMessage,
    placeholder,
    className,
    wmaUserId,
    onSelectCustomer,
  } = props;

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState<WMACustomer | null>(
    null,
  );

  const { data, isLoading } = useGetWmaCustomersByWmaUserId({
    filters: {},
    pathParams: { wmaUserId: wmaUserId ?? "me" },
    pagination: { limit: 100 },
    fields: wmaCustomersFields,
  });

  const { t } = useTranslation();

  const handleValueChange = (currentValue: string) => {
    setSearch(currentValue);
  };

  const handleCustomerChange = (item: WMACustomer) => {
    setSelectedCustomer(item);
    onSelectCustomer(item);
    setOpen(false);
  };

  const getLabel = useCallback(() => {
    return (
      label ?? t("users.back_office.switch_user.modal.users_autocomplete.label")
    );
  }, [label, t]);

  const getTriggerLabel = useCallback(() => {
    if (selectedCustomer) {
      return `${selectedCustomer.customerUser?.firstName} ${selectedCustomer.customerUser?.lastName}`;
    }

    if (placeholder) {
      return placeholder;
    }

    return t(
      "users.back_office.switch_user.modal.users_autocomplete.placeholder",
    );
  }, [selectedCustomer, placeholder, t]);

  const filteredCustomers = data?.data.filter((customer) => {
    const user = customer.customerUser;
    const fullname = `${user?.firstName} ${user?.lastName}`;

    return fullname.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <div className="flex flex-col gap-0">
      <label>
        <p className="text-dark-1 font-monserrat text-[13px]">
          {getLabel()}
          <span className="text-error">*</span>
        </p>
      </label>

      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="tertiary"
            aria-expanded={open}
            className="!space-between w-full !justify-between rounded-lg"
            iconRight="ChevronDown"
            label={getTriggerLabel()}
          />
        </PopoverTrigger>
        <PopoverContent className={cn("p-0", className)}>
          <Command shouldFilter={false}>
            <CommandInput
              value={search}
              onValueChange={handleValueChange}
              placeholder={
                placeholder ??
                t(
                  "investments.back_office.create_investment_page.form.users_autocomplete.placeholder",
                )
              }
            />

            {isLoading && (
              <CommandList>
                <CommandLoading className="flex justify-center p-4">
                  {t(
                    "users.back_office.switch_user.modal.users_autocomplete.loading",
                  )}
                </CommandLoading>
              </CommandList>
            )}

            {!isLoading && (
              <CommandList>
                <CommandEmpty>
                  {emptyMessage ??
                    t(
                      "users.back_office.switch_user.modal.users_autocomplete.no_data_result",
                    )}
                </CommandEmpty>

                {filteredCustomers!.map((item) => (
                  <CommandItem
                    key={item.id}
                    value={item.id}
                    onSelect={() => handleCustomerChange(item)}
                  >
                    {item.customerUser?.firstName} {item.customerUser?.lastName}{" "}
                    ({item.customerUser?.email})
                  </CommandItem>
                ))}
              </CommandList>
            )}
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};
