import {
  CMSCardTextBlock,
  CMSCardTextWithImageBlock,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { ThinLayout } from "@tudigo-monorepo/web-tudigo-components";

type ProjectCardBlockProps = {
  block: CMSCardTextBlock | CMSCardTextWithImageBlock;
  editMode?: boolean;
  className?: string;
};

export function ProjectCardBlock(props: ProjectCardBlockProps) {
  const { block, className } = props;

  const { data } = block;
  const { variant, title, text } = data;

  const imgSrc =
    block.type === "card-text-with-image" && "image" in data
      ? data.image?.base64 || data.image?.url
      : undefined;

  return (
    <ThinLayout
      className={cn(
        "border-light-1 w-full rounded-lg border",
        "flex flex-col items-center gap-4 p-4 text-center",
        {
          "bg-white": !variant || variant === "white",
          "bg-success-light": variant === "green",
        },
        className,
      )}
    >
      {imgSrc && (
        <img src={imgSrc} alt={title || text || ""} className="max-h-[250px]" />
      )}

      {title && !imgSrc && (
        <h3 className="text-dark-1 font-brand text-center">{title}</h3>
      )}

      {title && imgSrc && (
        <h3 className="text-dark-1 text-center font-semibold">{title}</h3>
      )}

      {text && (
        <p
          className="text-dark-2 text-xs"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}
    </ThinLayout>
  );
}
