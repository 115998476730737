export function getInvestmentCardColSpanClassName(
  index: number,
  totalItems: number,
): string {
  const modulo = totalItems % 3;
  const isLastRow = index >= totalItems - modulo;

  if (modulo === 1 && isLastRow && index === totalItems - 1) {
    return "col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6";
  } else if (modulo === 2 && isLastRow) {
    return "col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6";
  }

  return "col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-4";
}
