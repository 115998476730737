import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import {
  CreateWmaCustomerRequestBody,
  TudigoError,
  TudigoResponse,
} from "@tudigo-monorepo/core-tudigo-api";
import { WMACustomer } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient } from "../../..";

export const createWMACustomer = async (
  params: CreateWmaCustomerRequestBody,
) => {
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.wmaCustomers.createWMACustomer(params);

  return response.data;
};

export const useCreateWMACustomerMutation = (
  options?: UseMutationOptions<
    TudigoResponse<WMACustomer>,
    TudigoError,
    CreateWmaCustomerRequestBody
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (params: CreateWmaCustomerRequestBody) =>
      createWMACustomer(params),
    onSuccess: (...props) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.WMA_CUSTOMERS] });
      options?.onSuccess?.(...props);
    },
  });
};
