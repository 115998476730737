import { Route, Routes } from "react-router-dom";

import { ClubLeaderQuestionsPage } from "../club-leader-questions-page";

export function ClubLeaderQuestionsRouteWebapp() {
  return (
    <Routes>
      <Route path="*" element={<ClubLeaderQuestionsPage />} />
    </Routes>
  );
}
