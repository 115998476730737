import React, { useCallback, useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { DropzoneOptions, useDropzone } from "react-dropzone";

import { DocumentExtended } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Icon } from "../../../old/icons/icon";
import { DocumentCard } from "./components/file-upload-card";
import { FileUploadInfos } from "./components/file-upload-infos";
import { readFileSync } from "./file-helpers";

interface FileUploadProps extends DropzoneOptions {
  onChange?: (documents: DocumentExtended[]) => void;
  description?: string;
  defaultPdfs?: DocumentExtended[];
}

export function FileUpload({
  defaultPdfs,
  onChange,
  ...options
}: FileUploadProps) {
  const [documents, setDocuments] = useState<DocumentExtended[]>(
    defaultPdfs || [],
  );

  useEffect(() => {
    if (onChange) {
      onChange(documents);
    }
  }, [documents]);

  async function handleChangeFile(file: File) {
    const binary = await readFileSync(file);

    const newDocument = {
      base64: String(binary),
      url: undefined,
      id: undefined,
      type: file?.type,
      originalFilename: file.name,
    };

    setDocuments((documents) => {
      return [...documents, newDocument];
    });
  }

  async function onDropAccepted(acceptedFiles: File[]) {
    Promise.all(acceptedFiles.map(handleChangeFile));
  }

  const onDelete = useCallback(
    (document: DocumentExtended) => {
      setDocuments(documents.filter((d) => d !== document));
    },
    [documents],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    maxFiles: 2,
    multiple: true,
    disabled: documents.length >= (options?.maxFiles || 1),
    ...options,
  });

  return (
    <div className="space-y-4">
      <div
        {...getRootProps()}
        className={cn(`
          flex flex-col rounded-md
          border-2 border-dashed p-4
        `)}
      >
        <input {...getInputProps()} />

        <AnimatePresence>
          <div className="flex flex-col gap-3">
            {documents?.length < (options?.maxFiles || 1) && (
              <div className="flex h-[50px] items-center justify-center gap-4 py-6">
                <Icon name="Upload" primaryColor={themeColors["dark-2"]} />

                <div className="text-muted-foreground text-xs">
                  {isDragActive
                    ? "Déposer le fichier ici"
                    : "Cliquer pour parcourir ou glisser, déposer un fichier"}
                </div>
              </div>
            )}

            {documents.length > 0 &&
              documents.map((document, index) => (
                <DocumentCard
                  key={"doc" + index}
                  document={document}
                  onDelete={onDelete}
                />
              ))}
          </div>
        </AnimatePresence>
      </div>
      <FileUploadInfos {...options} />
    </div>
  );
}
