/* eslint-disable react/jsx-key */
import { EquitySavingPlan } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { getSmartFormComponents } from "@tudigo-monorepo/web-feature-form";
import { Collapse, Typography } from "@tudigo-monorepo/web-tudigo-components";

type PickBankContactInformationFormKey = Pick<
  EquitySavingPlan,
  "bankContactInformation"
>;

export function BankAdvisorContactFields() {
  const { t } = useTranslation();
  const { SmartTextField, SmartPhoneField } =
    getSmartFormComponents<PickBankContactInformationFormKey>();

  const formFields = [
    <SmartTextField
      name="bankContactInformation.firstName"
      label={t(
        "my_account.web_app.my_profile_page.organizations.bank_advisor_contact_form.first_name",
      )}
      placeholder={t(
        "my_account.web_app.my_profile_page.organizations.bank_advisor_contact_form.first_name",
      )}
      className="w-full sm:w-[251px]"
    />,
    <SmartTextField
      name="bankContactInformation.lastName"
      label={t(
        "my_account.web_app.my_profile_page.organizations.bank_advisor_contact_form.last_name",
      )}
      placeholder={t(
        "my_account.web_app.my_profile_page.organizations.bank_advisor_contact_form.last_name",
      )}
      className="w-full sm:w-[251px]"
    />,
    <SmartTextField
      name="bankContactInformation.email"
      label={t(
        "my_account.web_app.my_profile_page.organizations.bank_advisor_contact_form.email",
      )}
      placeholder={t(
        "my_account.web_app.my_profile_page.organizations.bank_advisor_contact_form.email",
      )}
      className="w-full sm:w-[251px]"
    />,
    <SmartPhoneField
      name="bankContactInformation.phone"
      label={t(
        "my_account.web_app.my_profile_page.organizations.bank_advisor_contact_form.phone",
      )}
      className="w-full sm:w-[251px]"
    />,
  ];

  return (
    <Collapse items={formFields} headerClassName="mt-4">
      <div className="flex max-w-[432px] flex-col">
        <Typography variant="body3-medium" className="text-dark-2">
          {t(
            "my_account.web_app.my_profile_page.organizations.bank_advisor_contact_form.title",
          )}
        </Typography>
        <Typography variant="body3-regular" className="text-dark-2">
          {t(
            "my_account.web_app.my_profile_page.organizations.bank_advisor_contact_form.desc",
          )}
        </Typography>
      </div>
    </Collapse>
  );
}
