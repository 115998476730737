import React, { useCallback, useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { ORGANIZATIONS } from "@tudigo-monorepo/core-tudigo-utils";
import { PageEnum, useSpaceBusiness } from "@tudigo-monorepo/tudigo-session";
import { usePatchOrganization } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  Button,
  FaqBlock,
  PageHead,
} from "@tudigo-monorepo/web-tudigo-components";

import { useKYCContext } from "../../kyc-page";
import {
  checkIfOrganizationDocumentsRequirementsAreMet,
  checkIfOrganizationPersonIdentityDocumentsRequirementAreMet,
} from "../../utils/kyc-documents-utils";
import { CompanyKycDocumentsFormGroup } from "./partials/company-kyc-documents-form-group";
import { IndividualKycDocumentsFormGroup } from "./partials/individual-kyc-documents-form-group";
import { UboKycDocumentsFormGroup } from "./partials/ubo-kyc-documents-form-group";

export function KycDocuments() {
  const { organization, organizationQueryKey, setOrganization } =
    useKYCContext();
  const { t, tNode } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { currentPagePaths } = useSpaceBusiness();
  const kycPagePath = currentPagePaths[
    PageEnum.ORGANIZATION_KYC_FUNNEL
  ].replace(":organizationId", organization.id);

  const {
    mutateAsync: patchOrganization,
    isPending: isPatchOrganizationLoading,
  } = usePatchOrganization({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: organizationQueryKey,
      });
      navigate(`${kycPagePath}/summary`, {
        replace: true,
      });
    },
  });
  const persons = organization.ultimateBeneficialOwners;

  const canSubmit = useMemo(() => {
    if (organization.type === ORGANIZATIONS.TYPE.INDIVIDUAL) {
      return checkIfOrganizationPersonIdentityDocumentsRequirementAreMet(
        persons[0],
        true,
      );
    }

    if (organization.type === ORGANIZATIONS.TYPE.COMPANY) {
      return (
        checkIfOrganizationDocumentsRequirementsAreMet(organization) &&
        persons.every((person) => {
          return checkIfOrganizationPersonIdentityDocumentsRequirementAreMet(
            person,
            false,
          );
        })
      );
    }

    return false;
  }, [organization]);

  const handleSubmit = useCallback(() => {
    patchOrganization({
      id: organization.id,
      documents: organization.documents,
      ultimateBeneficialOwners: organization.ultimateBeneficialOwners,
    });
  }, [organization, patchOrganization]);

  return (
    <div>
      <PageHead
        chipsTop={[
          {
            label:
              organization.type === ORGANIZATIONS.TYPE.COMPANY
                ? t("kyc_funnel.step_documents.page.chip.label.company")
                : t("kyc_funnel.step_documents.page.chip.label.individual"),
          },
          {
            label: organization.name,
            type: "info",
          },
        ]}
        title={t("kyc_funnel.step_documents.page.title")}
        withEndLine
      />
      <div className="flex flex-col gap-6 p-6">
        <Alert
          variant="info"
          icon="Info"
          size="F"
          description={tNode("kyc_funnel.step_documents.alert.content")}
        />
        {organization.type === ORGANIZATIONS.TYPE.COMPANY ? (
          <>
            <UboKycDocumentsFormGroup
              organizationFormData={organization}
              setOrganizationFormData={setOrganization}
            />
            <CompanyKycDocumentsFormGroup
              organizationFormData={organization}
              setOrganizationFormData={setOrganization}
            />
            <FaqBlock
              className="border-light-1 rounded-lg border p-6"
              items={[
                {
                  question: t(
                    "investment_funnel.step_documents.company_type.faq.row_one.question",
                  ),
                  answer: t(
                    "investment_funnel.step_documents.company_type.faq.row_one.answer",
                  ),
                },
                {
                  question: t(
                    "investment_funnel.step_documents.company_type.faq.row_two.question",
                  ),
                  answer: t(
                    "investment_funnel.step_documents.company_type.faq.row_two.answer",
                  ),
                },
                {
                  question: t(
                    "investment_funnel.step_documents.company_type.faq.row_three.question",
                  ),
                  answer: t(
                    "investment_funnel.step_documents.company_type.faq.row_three.answer",
                  ),
                },
              ]}
            />
          </>
        ) : (
          <IndividualKycDocumentsFormGroup
            beneficialOwner={persons[0]}
            organizationFormData={organization}
            setOrganizationFormData={setOrganization}
          />
        )}
      </div>
      <div className="border-light-1 fixed bottom-0 left-0 z-20 flex h-[80px] w-full items-center justify-center gap-x-4 border-t bg-white px-6 sm:h-[104px]">
        <Button
          isLoading={isPatchOrganizationLoading}
          disabled={!canSubmit}
          className="w-fit px-12"
          variant="primary"
          iconRight="ArrowRight"
          label={t("kyc_funnel.form.button.next.label")}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}
