import { mockOrganizations } from "../organizations";
import { ClubV2, MembershipStatus } from "./models";

export const mockClub: ClubV2 = {
  id: "1",
  name: "Super Capital",
  status: "published",
  iconBorderColor: "#FF0000",
  organization: mockOrganizations[0],
  mechanicSettings: {
    minimumTicketAmountFromInCents: 19999,
    minimumTicketAmountToInCents: 19999,
    investmentDurationFromInMonth: 19,
    investmentDurationToInMonth: 43,
    entryFeesFromNoDecimalRate: 999,
    entryFeesToNoDecimalRate: 999,
    carriedFromNoDecimalRate: 199,
    carriedToNoDecimalRate: 299,
  },
  content: {
    name: "Super Capital",
    baseline: "Club deal, micro-vs et banque d'affaires de la tech européenne",
    tagline: "Prenez part au futur de la Tech avec Super Capital",
    premiumAdvantages: [
      {
        title: "Lorem ipsum",
        description:
          "Lorem ipsum dolor sit amet consectetur. Ipsum in cras arcu imperdiet ut nisi amet",
      },
      {
        title: "Lorem ipsum",
        description:
          "Lorem ipsum dolor sit amet consectetur. Ipsum in cras arcu imperdiet ut nisi amet",
      },
      {
        title: "Lorem ipsum",
        description:
          "Lorem ipsum dolor sit amet consectetur. Ipsum in cras arcu imperdiet ut nisi amet",
      },
      {
        title: "Lorem ipsum",
        description:
          "Lorem ipsum dolor sit amet consectetur. Ipsum in cras arcu imperdiet ut nisi amet",
      },
      {
        title: "Lorem ipsum",
        description:
          "Lorem ipsum dolor sit amet consectetur. Ipsum in cras arcu imperdiet ut nisi amet",
      },
    ],
    presentationMedias: [
      {
        type: "video",
        data: {
          url: "https://www.youtube.com/watch?v=jKEVHykhCN0",
          platform: "youtube",
          title: "Super Capital - Présentation",
          iframeUrl: "https://www.youtube.com/embed/jKEVHykhCN0",
          platformId: "9bZkp7q19f0",
        },
      },
      {
        type: "image",
        data: {
          id: "img_01",
          url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_aHK0bhh6tArmyjxrz5N8fFq0Ij5UgwvGgw&s",
          base64: null,
          originalFilename: null,
          resizedImages: null,
        },
      },
      {
        type: "image",
        data: {
          id: "img_01",
          url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_aHK0bhh6tArmyjxrz5N8fFq0Ij5UgwvGgw&s",
          base64: null,
          originalFilename: null,
          resizedImages: null,
        },
      },
      {
        type: "image",
        data: {
          id: "img_01",
          url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_aHK0bhh6tArmyjxrz5N8fFq0Ij5UgwvGgw&s",
          base64: null,
          originalFilename: null,
          resizedImages: null,
        },
      },
      {
        type: "image",
        data: {
          id: "img_01",
          url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_aHK0bhh6tArmyjxrz5N8fFq0Ij5UgwvGgw&s",
          base64: null,
          originalFilename: null,
          resizedImages: null,
        },
      },
    ],
    medias: [
      {
        id: "1",
        type: "heading",
        data: {
          title: "La presse en parle",
          level: 2,
          caption: null,
        },
      },
      {
        id: "2",
        type: "card-press-coverage",
        data: {
          cards: [
            {
              image: {
                id: null,
                originalFilename: null,
                base64: null,
                url: "https://upload.wikimedia.org/wikipedia/commons/4/49/Logo_Maddyness.png",
                resizedImages: null,
              },
              content:
                "Lorem ipsum dolor sit amet consectetur. Eget ante sapien montes pellentesque donec.",
              date: "2024-01-01",
            },
            {
              image: {
                id: null,
                originalFilename: null,
                base64: null,
                url: "https://upload.wikimedia.org/wikipedia/commons/4/49/Logo_Maddyness.png",
                resizedImages: null,
              },
              content:
                "Lorem ipsum dolor sit amet consectetur. Eget ante sapien montes pellentesque donec.",
              date: "2024-01-01",
            },
            {
              image: {
                id: null,
                originalFilename: null,
                base64: null,
                url: "https://upload.wikimedia.org/wikipedia/commons/4/49/Logo_Maddyness.png",
                resizedImages: null,
              },
              content:
                "Lorem ipsum dolor sit amet consectetur. Eget ante sapien montes pellentesque donec.",
              date: "2024-01-01",
            },
          ],
        },
      },
      {
        id: "3",
        type: "heading",
        data: {
          title: "Les derniers podcasts",
          level: 2,
          caption: null,
        },
      },
      {
        id: "4",
        type: "podcast-carousel",
        data: {
          podcasts: [
            {
              url: "https://open.spotify.com/embed/episode/23yFTDdAsndrK04bJyxfE9?utm_source=generator&theme=0",
            },
            {
              url: "https://open.spotify.com/embed/episode/23yFTDdAsndrK04bJyxfE9?utm_source=generator&theme=0",
            },
            {
              url: "https://open.spotify.com/embed/episode/23yFTDdAsndrK04bJyxfE9?utm_source=generator&theme=0",
            },
          ],
        },
      },
      {
        id: "5",
        type: "heading",
        data: {
          title: "Super Capital : investir de manière plus smart",
          level: 2,
          caption: null,
        },
      },
      {
        id: "6",
        type: "video",
        data: {
          url: "https://www.youtube.com/watch?v=jKEVHykhCN0",
        },
      },
      {
        id: "7",
        type: "heading",
        data: {
          title: "Derniers projets accompagnés",
          level: 2,
          caption: null,
        },
      },
      {
        id: "8",
        type: "mosaic-gallery",
        data: {
          elements: [
            {
              image: {
                id: null,
                originalFilename: null,
                base64: null,
                url: "https://picsum.photos/800",
                resizedImages: null,
              },
            },
            {
              image: {
                id: null,
                originalFilename: null,
                base64: null,
                url: "https://picsum.photos/801",
                resizedImages: null,
              },
            },
            {
              image: {
                id: null,
                originalFilename: null,
                base64: null,
                url: "https://picsum.photos/802",
                resizedImages: null,
              },
            },
            {
              image: {
                id: null,
                originalFilename: null,
                base64: null,
                url: "https://picsum.photos/803",
                resizedImages: null,
              },
            },
            {
              image: {
                id: null,
                originalFilename: null,
                base64: null,
                url: "https://picsum.photos/804",
                resizedImages: null,
              },
            },
            {
              image: {
                id: null,
                originalFilename: null,
                base64: null,
                url: "https://picsum.photos/805",
                resizedImages: null,
              },
            },
          ],
        },
      },
    ],
    presentation: [
      {
        id: "1",
        type: "paragraph",
        data: {
          text: "Super Capital est un club d'investissement qui investit dans des startups à fort potentiel de croissance. Nous investissons dans des startups de la Tech, de la MedTech, de l'IA et du SaaS.",
        },
      },
      {
        id: "2",
        type: "club-medias-carousel",
        data: null,
      },
      {
        id: "3",
        type: "heading",
        data: {
          title: "Actualité",
          level: 2,
          caption: null,
        },
      },
      {
        id: "4",
        type: "card-event",
        data: {
          title: "Financer l'innovation",
          description: "Avec Corentin Orsini et Frédéric Baecke",
          subDescription: "Mercredi 12 juin 2024 - 12h00",
          ctaLabel: "S'inscrire",
          ctaUrl: "https://tudigo.co",
          topChipLabel: "Live pitch",
          image: {
            id: null,
            originalFilename: null,
            base64: null,
            url: "https://i.ibb.co/T0hppC2/Capture-d-e-cran-2024-07-17-a-11-34-09.png",
            resizedImages: null,
          },
        },
      },
      {
        id: "5",
        type: "card-event",
        data: {
          title: "Financer l'innovation",
          description: "Avec Corentin Orsini et Frédéric Baecke",
          subDescription: "Mercredi 12 juin 2024 - 12h00",
          ctaLabel: "S'inscrire",
          ctaUrl: "https://tudigo.co",
          topChipLabel: "Live pitch",
          image: {
            id: null,
            originalFilename: null,
            base64: null,
            url: "https://i.ibb.co/T0hppC2/Capture-d-e-cran-2024-07-17-a-11-34-09.png",
            resizedImages: null,
          },
        },
      },
      {
        id: "6",
        type: "heading",
        data: {
          title: "Dernières levées",
          level: 2,
          caption: null,
        },
      },
      {
        id: "7",
        type: "club-projects",
        data: null,
      },
      {
        id: "8",
        type: "heading",
        data: {
          title: "Une équipe expérimentée",
          level: 2,
          caption: null,
        },
      },
      {
        id: "9",
        type: "club-team-leaders",
        data: null,
      },
      {
        id: "10",
        type: "heading",
        data: {
          title: "Track record",
          level: 2,
          caption: null,
        },
      },
      {
        id: "11",
        type: "club-track-record",
        data: null,
      },
    ],
    mentoringDescription: [
      {
        id: "1",
        type: "heading",
        data: {
          title: "Accompagnement",
          level: 2,
          caption: null,
        },
      },
      {
        id: "2",
        type: "paragraph",
        data: {
          text:
            "Lorem ipsum dolor sit amet consectetur. Ipsum in cras arcu imperdiet ut nisi amet a. Pulvinar mattis malesuada at in nulla volutpat enim lorem convallis. In lectus nec ac non elementum semper. Velit sed euismod rhoncus tortor ultrices tortor pharetra. Curabitur mauris sed cursus varius. Venenatis ipsum etiam molestie sed sed consectetur. Urna tincidunt bibendum aliquet molestie arcu lorem aenean.\n" +
            "Velit sed euismod rhoncus tortor ultrices tortor pharetra. Curabitur mauris sed cursus varius. Venenatis ipsum etiam molestie sed sed consectetur. Urna tincidunt bibendum aliquet molestie arcu lorem aenean.",
        },
      },
      {
        id: "3",
        type: "heading",
        data: {
          title: "Une équipe expérimentée",
          level: 2,
          caption: null,
        },
      },
      {
        id: "4",
        type: "club-leader-list",
        data: null,
      },
      {
        id: "5",
        type: "heading",
        data: {
          title: "Super Capital",
          level: 2,
          caption: null,
        },
      },
      {
        id: "6",
        type: "club-organization-card",
        data: null,
      },
    ],
    exitDescription: [
      {
        id: "1",
        type: "paragraph",
        data: {
          text:
            "Lorem ipsum dolor sit amet consectetur. Ipsum in cras arcu imperdiet ut nisi amet a. Pulvinar mattis malesuada at in nulla volutpat enim lorem convallis. In lectus nec ac non elementum semper. Velit sed euismod rhoncus tortor ultrices tortor pharetra. Curabitur mauris sed cursus varius. Venenatis ipsum etiam molestie sed sed consectetur. Urna tincidunt bibendum aliquet molestie arcu lorem aenean.\n" +
            "Velit sed euismod rhoncus tortor ultrices tortor pharetra. Curabitur mauris sed cursus varius. Venenatis ipsum etiam molestie sed sed consectetur. Urna tincidunt bibendum aliquet molestie arcu lorem aenean.",
        },
      },
    ],
    thesis: [
      {
        id: "1",
        type: "paragraph",
        data: {
          text: "Créé en 2018, Super Capital s’est imposé comme l’un des acteurs majeurs du financement de l'écosystème tech français avec une cinquantaine d’investissements par an, et une équipe d’experts pour accompagner les entrepreneurs dans leur stratégie de financement à 360° (financements bancaires, BPI, subventions et crédits d’impôts).",
        },
      },
      {
        id: "2",
        type: "heading",
        data: {
          title: "Thèse d’investissement",
          level: 2,
          caption: null,
        },
      },
      {
        id: "3",
        type: "paragraph",
        data: {
          text: "Super Capital investit du <b>pre-seed à la série-A, sur des tickets de 30k à 500k.</b> <br />Présent en France, en Inde et en Allemagne, le groupe consacre environ 20% de ses investissements à l’international et s’appuie sur sa communauté de +2,000 membres entrepreneurs/investisseurs pour proposer des services de conseil en m&a et croissance externe.Super Capital intervient sur tous les secteurs de la tech avec un focus sur les industries du SaaS, AI, impact, fintech, marketplace et dev tools.",
        },
      },
      {
        id: "4",
        type: "cards-text-with-image-and-chip",
        data: {
          cards: [
            {
              title: "Aria",
              image: null,
              chip: { text: "Fintech & insurtech" },
              text: "Lorem ipsum dolor sit amet consectetur. Eget ante sapien montes pellentesque donec.",
            },
            {
              title: "Aria",
              image: null,
              chip: { text: "Fintech & insurtech", variant: "desactivate" },
              text: "Lorem ipsum dolor sit amet consectetur. Eget ante sapien montes pellentesque donec.",
            },
          ],
        },
      },
      {
        id: "5",
        type: "heading",
        data: {
          title: "Leadership de l'équipe fondatrice",
          level: 2,
          caption: null,
        },
      },
      {
        id: "6",
        type: "paragraph",
        data: {
          text: "Nous finançons des entrepreneurs visionnaires, fédérateurs, présentant une forte capacité d'exécution et de résilience. Idéalement des équipes de 2 à 3 cofondateurs/trices, ayant quelques années d'expérience dans l'industrie ou une première expérience entrepreneuriale",
        },
      },
      {
        id: "7",
        type: "heading",
        data: {
          title: "Must-have vs. nice-to-have",
          level: 2,
          caption: null,
        },
      },
      {
        id: "8",
        type: "paragraph",
        data: {
          text: "Nous privilégions les projets qui adressent des problématiques de fonds, coeur business pour les entreprises ou autour des transformations profondes des modèles de société (IA, SaaS B2B, formation, énergie décarbonée, gestion des déchets, fintech b2b, etc.).",
        },
      },
      {
        id: "9",
        type: "heading",
        data: {
          title: "Product-Market-fit & Traction",
          level: 2,
          caption: null,
        },
      },
      {
        id: "10",
        type: "paragraph",
        data: {
          text: "Nous ciblons des projets principalement post product-market-fit, présentant un CA compris entre 10k et 50k MRR. Pour certains projets à forte dimension R&D ou pour des levées de fonds de repeat entrepreneurs, nous pouvons intervenir sur un simple deck ou idée",
        },
      },
      {
        id: "11",
        type: "heading",
        data: {
          title: "Round & Valorisation",
          level: 2,
          caption: null,
        },
      },
      {
        id: "12",
        type: "paragraph",
        data: {
          text: "Nous intervenons du pre-seed à la série-A, sur des tours d'un montant minimum de 500 K€ afin de donner suffisamment de visibilité aux entrepreneurs pour déployer leur stratégie. 80% de nos levées se font à des valorisations inférieurs à 10 M€.",
        },
      },
      {
        id: "13",
        type: "heading",
        data: {
          title: "Scalabilité & Profondeur de marché",
          level: 2,
          caption: null,
        },
      },
      {
        id: "14",
        type: "paragraph",
        data: {
          text: "Nous favorisons des business scalables à l'international, récurrents et capables de viser un CA cible de 70M+ sous 7 à 10 ans. La plupart des dossiers que nous sélectionnons sont digitaux (SaaS, Marketplace, API) ou sont des concepts/marques forts et duplicables.",
        },
      },
      {
        id: "15",
        type: "cards-quote",
        data: {
          cards: [
            {
              name: "John Doe",
              quote: "Lorem ipsum",
              job: "Expert Branding",
            },
            {
              name: "John Doe",
              quote: "Lorem ipsum",
              job: "Expert Branding",
            },
            {
              name: "John Doe",
              quote: "Lorem ipsum",
              job: "Expert Branding",
            },
          ],
        },
      },
      {
        id: "16",
        type: "heading",
        data: {
          title: "Une équipe expérimentée",
          level: 2,
          caption: null,
        },
      },
      {
        id: "17",
        type: "club-team-leaders",
        data: null,
      },
      {
        id: "18",
        type: "heading",
        data: {
          title: "Track record",
          level: 2,
          caption: null,
        },
      },
      {
        id: "19",
        type: "club-track-record",
        data: null,
      },
    ],
    modalities: [
      {
        id: "1",
        type: "heading",
        data: {
          title: "Lorem ipsum",
          level: 2,
          caption: null,
        },
      },
      {
        id: "2",
        type: "paragraph",
        data: {
          text:
            "Lorem ipsum dolor sit amet consectetur. Ipsum in cras arcu imperdiet ut nisi amet a. Pulvinar mattis malesuada at in nulla volutpat enim lorem convallis. In lectus nec ac non elementum semper. Velit sed euismod rhoncus tortor ultrices tortor pharetra. Curabitur mauris sed cursus varius. Venenatis ipsum etiam molestie sed sed consectetur. Urna tincidunt bibendum aliquet molestie arcu lorem aenean.\n" +
            "Velit sed euismod rhoncus tortor ultrices tortor pharetra. Curabitur mauris sed cursus varius. Venenatis ipsum etiam molestie sed sed consectetur. Urna tincidunt bibendum aliquet molestie arcu lorem aenean.",
        },
      },
      {
        id: "3",
        type: "club-modalities-card",
        data: null,
      },
      {
        id: "4",
        type: "faq-block",
        data: {
          elements: [
            {
              question: "Lorem ipsum",
              answer:
                "Lorem ipsum dolor sit amet consectetur. Ipsum in cras arcu imperdiet ut nisi amet",
            },
            {
              question: "Lorem ipsum",
              answer:
                "Lorem ipsum dolor sit amet consectetur. Ipsum in cras arcu imperdiet ut nisi amet",
            },
            {
              question: "Lorem ipsum",
              answer:
                "Lorem ipsum dolor sit amet consectetur. Ipsum in cras arcu imperdiet ut nisi amet",
            },
            {
              question: "Lorem ipsum",
              answer:
                "Lorem ipsum dolor sit amet consectetur. Ipsum in cras arcu imperdiet ut nisi amet",
            },
          ],
        },
      },
    ],
    logo: {
      id: "img_01",
      url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_aHK0bhh6tArmyjxrz5N8fFq0Ij5UgwvGgw&s",
      base64: null,
      originalFilename: null,
      resizedImages: [],
    },
    cover: {
      id: "img_02",
      url: "https://s3-alpha-sig.figma.com/img/7340/34c8/f77fe471422b236644415a7b4f1317bb?Expires=1722816000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=WxvksCUFIatUubcqaz7whDwJXL-k0IynKs0K05WmEtnMkSROjA9xOSuvtHkom6Bdht3EyBKWtGN0iXjYfyBTTSBktYC4GwJ-tRYr6NAcQj3SzOVl-tz-96XWShe54KfwET5Z9LYw8GcMXmsWH74nAL6Na79uE~u7wffNRCgepRcsauZFhk2lTNVLUUb8HXUh5uBgusLUnyC2wNLeMRbr9IfKPnarCZMAOpdvfbXCtM7wQw-jIFbWAtq2IXbr8LJkSu3GGvBqj1LOSF9L1ZtQPpL1uonkNaZuMy5s4rifxpUQphxOk1vEO0qup9HKFtee7c5nH9m9DeNv8cY8akTh9g__",
      base64: null,
      originalFilename: null,
      resizedImages: [],
    },
    thankYouImage: {
      id: "img_03",
      url: "https://s3-alpha-sig.figma.com/img/7340/34c8/f77fe471422b236644415a7b4f1317bb?Expires=1722816000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=WxvksCUFIatUubcqaz7whDwJXL-k0IynKs0K05WmEtnMkSROjA9xOSuvtHkom6Bdht3EyBKWtGN0iXjYfyBTTSBktYC4GwJ-tRYr6NAcQj3SzOVl-tz-96XWShe54KfwET5Z9LYw8GcMXmsWH74nAL6Na79uE~u7wffNRCgepRcsauZFhk2lTNVLUUb8HXUh5uBgusLUnyC2wNLeMRbr9IfKPnarCZMAOpdvfbXCtM7wQw-jIFbWAtq2IXbr8LJkSu3GGvBqj1LOSF9L1ZtQPpL1uonkNaZuMy5s4rifxpUQphxOk1vEO0qup9HKFtee7c5nH9m9DeNv8cY8akTh9g__",
      base64: null,
      originalFilename: null,
      resizedImages: [],
    },
  },
  configuration: {
    isPremiumAvailable: true,
    subscriptionsOpeningDate: "2024-01-01",
    subscriptionsClosingDate: "2025-01-01",
    subscriptionPrice: {
      unitAmountInCents: 10000,
      currency: "EUR",
      renewalInterval: "month",
      renewalIntervalCount: 1,
      active: true,
    },
    introductorySubscriptionPrice: {
      unitAmountInCents: 10000,
      currency: "EUR",
      renewalInterval: "month",
      renewalIntervalCount: 1,
      active: true,
    },
    maxIntroductorySubscriptions: 100,
    maxSubscriptions: 1000,
  },
  leaders: [
    {
      id: "1",
      profileImage: {
        url: "https://i.pravatar.cc/300?img=1",
        id: null,
        base64: null,
        originalFilename: null,
        resizedImages: null,
      },
      firstName: "John",
      lastName: "Doe",
      socialLinks: {
        linkedin: "https://www.linkedin.com",
      },
      title: "Expert Branding",
      shortDescription: "Lorem ipsum",
      description:
        "Lorem ipsum dolor sit amet consectetur. Eget ante sapien montes pellentesque donec.Lorem ipsum dolor sit amet consectetur. Eget ante sapien montes pellentesque donec.",
      specialities: [
        {
          code: "00001",
          name: "speciality_1",
        },
      ],
      position: 1,
    },
    {
      id: "2",
      profileImage: {
        url: "https://i.pravatar.cc/300?img=32",
        id: null,
        base64: null,
        originalFilename: null,
        resizedImages: null,
      },
      firstName: "John",
      lastName: "Doe",
      socialLinks: {
        linkedin: "https://www.linkedin.com",
      },
      title: "Expert Branding",
      specialities: [
        {
          code: "00001",
          name: "speciality_1",
        },
      ],
      shortDescription: "Lorem ipsum",
      description:
        "Lorem ipsum dolor sit amet consectetur. Eget ante sapien montes pellentesque donec.Lorem ipsum dolor sit amet consectetur. Eget ante sapien montes pellentesque donec.",
      position: 2,
    },
    {
      id: "3",
      profileImage: {
        url: "https://i.pravatar.cc/300?img=13",
        id: null,
        base64: null,
        originalFilename: null,
        resizedImages: null,
      },
      firstName: "John",
      lastName: "Doe",
      socialLinks: {
        linkedin: "https://www.linkedin.com",
      },
      title: "Expert Branding",
      specialities: [
        {
          code: "00001",
          name: "speciality_1",
        },
      ],
      shortDescription: "Lorem ipsum",
      description:
        "Lorem ipsum dolor sit amet consectetur. Eget ante sapien montes pellentesque donec.Lorem ipsum dolor sit amet consectetur. Eget ante sapien montes pellentesque donec.",
      position: 3,
    },
    {
      id: "4",
      profileImage: {
        url: "https://i.pravatar.cc/300?img=4",
        id: null,
        base64: null,
        originalFilename: null,
        resizedImages: null,
      },
      firstName: "John",
      lastName: "Doe",
      socialLinks: {
        linkedin: "https://www.linkedin.com",
      },
      title: "Expert Branding",
      specialities: [
        {
          code: "00001",
          name: "speciality_1",
        },
      ],
      shortDescription: "Lorem ipsum",
      description:
        "Lorem ipsum dolor sit amet consectetur. Eget ante sapien montes pellentesque donec.Lorem ipsum dolor sit amet consectetur. Eget ante sapien montes pellentesque donec.",
      position: 4,
    },
    {
      id: "5",
      profileImage: {
        url: "https://i.pravatar.cc/300?img=5",
        id: null,
        base64: null,
        originalFilename: null,
        resizedImages: null,
      },
      firstName: "John",
      lastName: "Doe",
      socialLinks: {
        linkedin: "https://www.linkedin.com",
      },
      title: "Expert Branding",
      specialities: [
        {
          code: "00001",
          name: "speciality_1",
        },
      ],
      shortDescription: "Lorem ipsum",
      description:
        "Lorem ipsum dolor sit amet consectetur. Eget ante sapien montes pellentesque donec.Lorem ipsum dolor sit amet consectetur. Eget ante sapien montes pellentesque donec.",
      position: 5,
    },
  ],
  statistics: {
    followersCount: 255,
    clubLeadersCount: 5,
    liveProjectsCount: 5,
  },
  myMembership: {
    status: MembershipStatus.SUBSCRIBED,
  },
  socialLinks: {
    discordUrl: "https://discord.gg/7j4jWx5",
  },
  trackRecords: [
    {
      id: "1",
      logo: {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_aHK0bhh6tArmyjxrz5N8fFq0Ij5UgwvGgw&s",
        id: "img_01",
        base64: null,
        originalFilename: null,
        resizedImages: [],
      },
      title: "track_record_1",
      industry: {
        code: "00001",
        name: "industry_00001",
      },
      shortDescription: "Lorem ipsum",
      description: "Lorem ipsum",
      link: "https://tudigo.co",
      position: 1,
    },
    {
      id: "2",
      logo: {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_aHK0bhh6tArmyjxrz5N8fFq0Ij5UgwvGgw&s",
        id: "img_02",
        base64: null,
        originalFilename: null,
        resizedImages: [],
      },
      title: "track_record_1",
      industry: {
        code: "00001",
        name: "industry_00001",
      },
      shortDescription: "Lorem ipsum",
      description: "Lorem ipsum",
      link: "https://tudigo.co",
      position: 2,
    },
    {
      id: "3",
      logo: {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_aHK0bhh6tArmyjxrz5N8fFq0Ij5UgwvGgw&s",
        id: "img_03",
        base64: null,
        originalFilename: null,
        resizedImages: [],
      },
      title: "track_record_1",
      industry: {
        code: "00001",
        name: "industry_00001",
      },
      shortDescription: "Lorem ipsum",
      description: "Lorem ipsum",
      link: "https://tudigo.co",
      position: 3,
    },
    {
      id: "4",
      logo: {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_aHK0bhh6tArmyjxrz5N8fFq0Ij5UgwvGgw&s",
        id: "img_04",
        base64: null,
        originalFilename: null,
        resizedImages: [],
      },
      title: "track_record_1",
      industry: {
        code: "00001",
        name: "industry_00001",
      },
      shortDescription: "Lorem ipsum",
      description: "Lorem ipsum",
      link: "https://tudigo.co",
      position: 4,
    },
    {
      id: "5",
      logo: {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_aHK0bhh6tArmyjxrz5N8fFq0Ij5UgwvGgw&s",
        id: "img_05",
        base64: null,
        originalFilename: null,
        resizedImages: [],
      },
      title: "track_record_1",
      industry: {
        code: "00001",
        name: "industry_00001",
      },
      shortDescription: "Lorem ipsum",
      description: "Lorem ipsum",
      link: "https://tudigo.co",
      position: 5,
    },
    {
      id: "6",
      logo: {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_aHK0bhh6tArmyjxrz5N8fFq0Ij5UgwvGgw&s",
        id: "img_06",
        base64: null,
        originalFilename: null,
        resizedImages: [],
      },
      title: "track_record_1",
      industry: {
        code: "00001",
        name: "industry_00001",
      },
      shortDescription: "Lorem ipsum",
      description: "Lorem ipsum",
      link: "https://tudigo.co",
      position: 6,
    },
    {
      id: "7",
      logo: {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_aHK0bhh6tArmyjxrz5N8fFq0Ij5UgwvGgw&s",
        id: "img_07",
        base64: null,
        originalFilename: null,
        resizedImages: [],
      },
      title: "track_record_1",
      industry: {
        code: "00001",
        name: "industry_00001",
      },
      shortDescription: "Lorem ipsum",
      description: "Lorem ipsum",
      link: "https://tudigo.co",
      position: 7,
    },
    {
      id: "8",
      logo: {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_aHK0bhh6tArmyjxrz5N8fFq0Ij5UgwvGgw&s",
        id: "img_08",
        base64: null,
        originalFilename: null,
        resizedImages: [],
      },
      title: "track_record_1",
      industry: {
        code: "00001",
        name: "industry_00001",
      },
      shortDescription: "Lorem ipsum",
      link: "https://tudigo.co",
      position: 8,
    },
    {
      id: "9",
      logo: {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_aHK0bhh6tArmyjxrz5N8fFq0Ij5UgwvGgw&s",
        id: "img_09",
        base64: null,
        originalFilename: null,
        resizedImages: [],
      },
      title: "track_record_1",
      industry: {
        code: "00001",
        name: "industry_00001",
      },
      shortDescription: "Lorem ipsum",
      description: "Lorem ipsum",
      link: "https://tudigo.co",
      position: 9,
    },
    {
      id: "10",
      logo: {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_aHK0bhh6tArmyjxrz5N8fFq0Ij5UgwvGgw&s",
        id: "img_10",
        base64: null,
        originalFilename: null,
        resizedImages: [],
      },
      title: "track_record_1",
      industry: {
        code: "00001",
        name: "industry_00001",
      },
      shortDescription: "Lorem ipsum",
      description: "Lorem ipsum",
      link: "https://tudigo.co",
      position: 10,
    },
  ],
  industries: [
    {
      code: "00001",
      name: "Tech",
    },
    {
      code: "00002",
      name: "Medical Devices",
    },
    {
      code: "00003",
      name: "SaaS",
    },
    {
      code: "00004",
      name: "Insurance",
    },
    {
      code: "00005",
      name: "AI/ML",
    },
    {
      code: "00006",
      name: "LegalTech",
    },
    {
      code: "00007",
      name: "Impact",
    },
  ],
  territories: [
    {
      code: "00001",
      name: "Europe",
    },
    {
      code: "00002",
      name: "Asie",
    },
    {
      code: "00003",
      name: "Amérique du nord",
    },
  ],
  maturities: [
    {
      code: "00001",
      name: "Pre-Seed",
    },
    {
      code: "00002",
      name: "Seed",
    },
    {
      code: "00003",
      name: "Série A",
    },
  ],
  mentorings: [
    {
      code: "00001",
      name: "Advisory",
    },
    {
      code: "00002",
      name: "Direction financière",
    },
  ],
  faqs: [],
};

export const mockClub2: ClubV2 = {
  id: "2",
  name: "Super Capital 2",
  status: "published",
  iconBorderColor: "#FF0000",
  organization: mockOrganizations[0],
  mechanicSettings: {
    minimumTicketAmountFromInCents: 19999,
    minimumTicketAmountToInCents: 19999,
    investmentDurationFromInMonth: 19,
    investmentDurationToInMonth: 43,
    entryFeesFromNoDecimalRate: 999,
    entryFeesToNoDecimalRate: 999,
    carriedFromNoDecimalRate: 199,
    carriedToNoDecimalRate: 299,
  },
  content: {
    name: "Super Capital 2",
    baseline: "Club deal, micro-vs et banque d'affaires de la tech européenne",
    tagline: "Prenez part au futur de la Tech avec Super Capital",
    premiumAdvantages: [
      {
        title: "Lorem ipsum",
        description:
          "Lorem ipsum dolor sit amet consectetur. Ipsum in cras arcu imperdiet ut nisi amet",
      },
      {
        title:
          "Lorem ipsum Ipsum in cras arcu imperdiet ut nisi amet blablabla blablabla bla",
        description:
          "Lorem ipsum dolor sit amet consectetur. Ipsum in cras arcu imperdiet ut nisi amet",
      },
      {
        title: "Lorem ipsum",
        description:
          "Lorem ipsum dolor sit amet consectetur. Ipsum in cras arcu imperdiet ut nisi amet",
      },
      {
        title: "Lorem ipsum",
        description:
          "Lorem ipsum dolor sit amet consectetur. Ipsum in cras arcu imperdiet ut nisi amet",
      },
      {
        title: "Lorem ipsum",
        description:
          "Lorem ipsum dolor sit amet consectetur. Ipsum in cras arcu imperdiet ut nisi amet",
      },
    ],
    presentationMedias: [
      {
        type: "video",
        data: {
          url: "https://www.youtube.com/watch?v=jKEVHykhCN0",
          platform: "youtube",
          title: "Super Capital - Présentation",
          iframeUrl: "https://www.youtube.com/embed/jKEVHykhCN0",
          platformId: "9bZkp7q19f0",
        },
      },
      {
        type: "image",
        data: {
          id: "img_01",
          url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_aHK0bhh6tArmyjxrz5N8fFq0Ij5UgwvGgw&s",
          base64: null,
          originalFilename: null,
          resizedImages: null,
        },
      },
      {
        type: "image",
        data: {
          id: "img_01",
          url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_aHK0bhh6tArmyjxrz5N8fFq0Ij5UgwvGgw&s",
          base64: null,
          originalFilename: null,
          resizedImages: null,
        },
      },
      {
        type: "image",
        data: {
          id: "img_01",
          url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_aHK0bhh6tArmyjxrz5N8fFq0Ij5UgwvGgw&s",
          base64: null,
          originalFilename: null,
          resizedImages: null,
        },
      },
      {
        type: "image",
        data: {
          id: "img_01",
          url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_aHK0bhh6tArmyjxrz5N8fFq0Ij5UgwvGgw&s",
          base64: null,
          originalFilename: null,
          resizedImages: null,
        },
      },
    ],
    medias: [
      {
        id: "1",
        type: "heading",
        data: {
          title: "La presse en parle",
          level: 2,
          caption: null,
        },
      },
      {
        id: "2",
        type: "card-press-coverage",
        data: {
          cards: [
            {
              image: {
                id: null,
                originalFilename: null,
                base64: null,
                url: "https://s3-alpha-sig.figma.com/img/9259/408c/d06133048724352b6f2889a92cfe6b8d?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=JOS1UQv1lxAVXRKpGECvvnqBXdWMTvrwtfo3vBfURVFmJDKMZzR405XE06G6j1RQZlJT9V4D6tX~f8597nMl7ZghG0F5rd9gm4-zoRl1djcIFEFoiwk1r-AAn-u5YCtcBITVqATFyrBGnOkb2OnII8YUKFCQzo7fU8MytkIud-hkJMJjwsTnvmpsK-56PjvbI-XDzcY8~~ZDOCOgJ59CJ2JM8r3ML9Y1hhkwh20TQqQ81UTnacBG095SeRU9O12MXnAKq0SUQjCHuhOoB7v0LOKjrj66aCcACFU1YVER3Flg1rbheFGFHdn1ktZLaOcH42-Xt7EG21gxyHMlL9RWbQ__",
                resizedImages: null,
              },
              content:
                "Lorem ipsum dolor sit amet consectetur. Eget ante sapien montes pellentesque donec.",
              date: "2024-01-01",
            },
            {
              image: {
                id: null,
                originalFilename: null,
                base64: null,
                url: "https://s3-alpha-sig.figma.com/img/9259/408c/d06133048724352b6f2889a92cfe6b8d?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=JOS1UQv1lxAVXRKpGECvvnqBXdWMTvrwtfo3vBfURVFmJDKMZzR405XE06G6j1RQZlJT9V4D6tX~f8597nMl7ZghG0F5rd9gm4-zoRl1djcIFEFoiwk1r-AAn-u5YCtcBITVqATFyrBGnOkb2OnII8YUKFCQzo7fU8MytkIud-hkJMJjwsTnvmpsK-56PjvbI-XDzcY8~~ZDOCOgJ59CJ2JM8r3ML9Y1hhkwh20TQqQ81UTnacBG095SeRU9O12MXnAKq0SUQjCHuhOoB7v0LOKjrj66aCcACFU1YVER3Flg1rbheFGFHdn1ktZLaOcH42-Xt7EG21gxyHMlL9RWbQ__",
                resizedImages: null,
              },
              content:
                "Lorem ipsum dolor sit amet consectetur. Eget ante sapien montes pellentesque donec.",
              date: "2024-01-01",
            },
            {
              image: {
                id: null,
                originalFilename: null,
                base64: null,
                url: "https://s3-alpha-sig.figma.com/img/9259/408c/d06133048724352b6f2889a92cfe6b8d?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=JOS1UQv1lxAVXRKpGECvvnqBXdWMTvrwtfo3vBfURVFmJDKMZzR405XE06G6j1RQZlJT9V4D6tX~f8597nMl7ZghG0F5rd9gm4-zoRl1djcIFEFoiwk1r-AAn-u5YCtcBITVqATFyrBGnOkb2OnII8YUKFCQzo7fU8MytkIud-hkJMJjwsTnvmpsK-56PjvbI-XDzcY8~~ZDOCOgJ59CJ2JM8r3ML9Y1hhkwh20TQqQ81UTnacBG095SeRU9O12MXnAKq0SUQjCHuhOoB7v0LOKjrj66aCcACFU1YVER3Flg1rbheFGFHdn1ktZLaOcH42-Xt7EG21gxyHMlL9RWbQ__",
                resizedImages: null,
              },
              content:
                "Lorem ipsum dolor sit amet consectetur. Eget ante sapien montes pellentesque donec.",
              date: "2024-01-01",
            },
          ],
        },
      },
      {
        id: "3",
        type: "heading",
        data: {
          title: "Les derniers podcasts",
          level: 2,
          caption: null,
        },
      },
      {
        id: "4",
        type: "podcast-carousel",
        data: {
          podcasts: [
            {
              url: "https://open.spotify.com/embed/episode/23yFTDdAsndrK04bJyxfE9?utm_source=generator&theme=0",
            },
            {
              url: "https://open.spotify.com/embed/episode/23yFTDdAsndrK04bJyxfE9?utm_source=generator&theme=0",
            },
            {
              url: "https://open.spotify.com/embed/episode/23yFTDdAsndrK04bJyxfE9?utm_source=generator&theme=0",
            },
          ],
        },
      },
      {
        id: "5",
        type: "heading",
        data: {
          title: "Super Capital : investir de manière plus smart",
          level: 2,
          caption: null,
        },
      },
      {
        id: "6",
        type: "video",
        data: {
          url: "https://www.youtube.com/watch?v=jKEVHykhCN0",
        },
      },
      {
        id: "7",
        type: "heading",
        data: {
          title: "Derniers projets accompagnés",
          level: 2,
          caption: null,
        },
      },
      {
        id: "8",
        type: "mosaic-gallery",
        data: {
          elements: [
            {
              image: {
                id: null,
                originalFilename: null,
                base64: null,
                url: "https://picsum.photos/800",
                resizedImages: null,
              },
            },
            {
              image: {
                id: null,
                originalFilename: null,
                base64: null,
                url: "https://picsum.photos/801",
                resizedImages: null,
              },
            },
            {
              image: {
                id: null,
                originalFilename: null,
                base64: null,
                url: "https://picsum.photos/802",
                resizedImages: null,
              },
            },
            {
              image: {
                id: null,
                originalFilename: null,
                base64: null,
                url: "https://picsum.photos/803",
                resizedImages: null,
              },
            },
            {
              image: {
                id: null,
                originalFilename: null,
                base64: null,
                url: "https://picsum.photos/804",
                resizedImages: null,
              },
            },
            {
              image: {
                id: null,
                originalFilename: null,
                base64: null,
                url: "https://picsum.photos/805",
                resizedImages: null,
              },
            },
          ],
        },
      },
    ],
    presentation: [
      {
        id: "1",
        type: "paragraph",
        data: {
          text: "Super Capital est un club d'investissement qui investit dans des startups à fort potentiel de croissance. Nous investissons dans des startups de la Tech, de la MedTech, de l'IA et du SaaS.",
        },
      },
      {
        id: "2",
        type: "club-medias-carousel",
        data: null,
      },
      {
        id: "3",
        type: "heading",
        data: {
          title: "Actualité",
          level: 2,
          caption: null,
        },
      },
      {
        id: "4",
        type: "card-event",
        data: {
          title: "Financer l'innovation",
          description: "Avec Corentin Orsini et Frédéric Baecke",
          subDescription: "Mercredi 12 juin 2024 - 12h00",
          ctaLabel: "S'inscrire",
          ctaUrl: "https://tudigo.co",
          topChipLabel: "Live pitch",
          image: {
            id: null,
            originalFilename: null,
            base64: null,
            url: "https://i.ibb.co/T0hppC2/Capture-d-e-cran-2024-07-17-a-11-34-09.png",
            resizedImages: null,
          },
        },
      },
      {
        id: "5",
        type: "card-event",
        data: {
          title: "Financer l'innovation",
          description: "Avec Corentin Orsini et Frédéric Baecke",
          subDescription: "Mercredi 12 juin 2024 - 12h00",
          ctaLabel: "S'inscrire",
          ctaUrl: "https://tudigo.co",
          topChipLabel: "Live pitch",
          image: {
            id: null,
            originalFilename: null,
            base64: null,
            url: "https://i.ibb.co/T0hppC2/Capture-d-e-cran-2024-07-17-a-11-34-09.png",
            resizedImages: null,
          },
        },
      },
      {
        id: "6",
        type: "heading",
        data: {
          title: "Dernières levées",
          level: 2,
          caption: null,
        },
      },
      {
        id: "7",
        type: "club-projects",
        data: null,
      },
      {
        id: "8",
        type: "heading",
        data: {
          title: "Une équipe expérimentée",
          level: 2,
          caption: null,
        },
      },
      {
        id: "9",
        type: "club-team-leaders",
        data: null,
      },
      {
        id: "10",
        type: "heading",
        data: {
          title: "Track record",
          level: 2,
          caption: null,
        },
      },
      {
        id: "11",
        type: "club-track-record",
        data: null,
      },
    ],
    mentoringDescription: [
      {
        id: "1",
        type: "heading",
        data: {
          title: "Accompagnement",
          level: 2,
          caption: null,
        },
      },
      {
        id: "2",
        type: "paragraph",
        data: {
          text:
            "Lorem ipsum dolor sit amet consectetur. Ipsum in cras arcu imperdiet ut nisi amet a. Pulvinar mattis malesuada at in nulla volutpat enim lorem convallis. In lectus nec ac non elementum semper. Velit sed euismod rhoncus tortor ultrices tortor pharetra. Curabitur mauris sed cursus varius. Venenatis ipsum etiam molestie sed sed consectetur. Urna tincidunt bibendum aliquet molestie arcu lorem aenean.\n" +
            "Velit sed euismod rhoncus tortor ultrices tortor pharetra. Curabitur mauris sed cursus varius. Venenatis ipsum etiam molestie sed sed consectetur. Urna tincidunt bibendum aliquet molestie arcu lorem aenean.",
        },
      },
      {
        id: "3",
        type: "heading",
        data: {
          title: "Une équipe expérimentée",
          level: 2,
          caption: null,
        },
      },
      {
        id: "4",
        type: "club-leader-list",
        data: null,
      },
      {
        id: "5",
        type: "heading",
        data: {
          title: "Super Capital",
          level: 2,
          caption: null,
        },
      },
      {
        id: "6",
        type: "club-organization-card",
        data: null,
      },
    ],
    exitDescription: [
      {
        id: "1",
        type: "paragraph",
        data: {
          text:
            "Lorem ipsum dolor sit amet consectetur. Ipsum in cras arcu imperdiet ut nisi amet a. Pulvinar mattis malesuada at in nulla volutpat enim lorem convallis. In lectus nec ac non elementum semper. Velit sed euismod rhoncus tortor ultrices tortor pharetra. Curabitur mauris sed cursus varius. Venenatis ipsum etiam molestie sed sed consectetur. Urna tincidunt bibendum aliquet molestie arcu lorem aenean.\n" +
            "Velit sed euismod rhoncus tortor ultrices tortor pharetra. Curabitur mauris sed cursus varius. Venenatis ipsum etiam molestie sed sed consectetur. Urna tincidunt bibendum aliquet molestie arcu lorem aenean.",
        },
      },
    ],
    thesis: [
      {
        id: "1",
        type: "paragraph",
        data: {
          text: "Créé en 2018, Super Capital s’est imposé comme l’un des acteurs majeurs du financement de l'écosystème tech français avec une cinquantaine d’investissements par an, et une équipe d’experts pour accompagner les entrepreneurs dans leur stratégie de financement à 360° (financements bancaires, BPI, subventions et crédits d’impôts).",
        },
      },
      {
        id: "2",
        type: "heading",
        data: {
          title: "Thèse d’investissement",
          level: 2,
          caption: null,
        },
      },
      {
        id: "3",
        type: "paragraph",
        data: {
          text: "Super Capital investit du <b>pre-seed à la série-A, sur des tickets de 30k à 500k.</b> <br />Présent en France, en Inde et en Allemagne, le groupe consacre environ 20% de ses investissements à l’international et s’appuie sur sa communauté de +2,000 membres entrepreneurs/investisseurs pour proposer des services de conseil en m&a et croissance externe.Super Capital intervient sur tous les secteurs de la tech avec un focus sur les industries du SaaS, AI, impact, fintech, marketplace et dev tools.",
        },
      },
      {
        id: "4",
        type: "cards-text-with-image-and-chip",
        data: {
          cards: [
            {
              title: "Aria",
              image: null,
              chip: { text: "Fintech & insurtech" },
              text: "Lorem ipsum dolor sit amet consectetur. Eget ante sapien montes pellentesque donec.",
            },
            {
              title: "Aria",
              image: null,
              chip: { text: "Fintech & insurtech", variant: "desactivate" },
              text: "Lorem ipsum dolor sit amet consectetur. Eget ante sapien montes pellentesque donec.",
            },
          ],
        },
      },
      {
        id: "5",
        type: "heading",
        data: {
          title: "Leadership de l'équipe fondatrice",
          level: 2,
          caption: null,
        },
      },
      {
        id: "6",
        type: "paragraph",
        data: {
          text: "Nous finançons des entrepreneurs visionnaires, fédérateurs, présentant une forte capacité d'exécution et de résilience. Idéalement des équipes de 2 à 3 cofondateurs/trices, ayant quelques années d'expérience dans l'industrie ou une première expérience entrepreneuriale",
        },
      },
      {
        id: "7",
        type: "heading",
        data: {
          title: "Must-have vs. nice-to-have",
          level: 2,
          caption: null,
        },
      },
      {
        id: "8",
        type: "paragraph",
        data: {
          text: "Nous privilégions les projets qui adressent des problématiques de fonds, coeur business pour les entreprises ou autour des transformations profondes des modèles de société (IA, SaaS B2B, formation, énergie décarbonée, gestion des déchets, fintech b2b, etc.).",
        },
      },
      {
        id: "9",
        type: "heading",
        data: {
          title: "Product-Market-fit & Traction",
          level: 2,
          caption: null,
        },
      },
      {
        id: "10",
        type: "paragraph",
        data: {
          text: "Nous ciblons des projets principalement post product-market-fit, présentant un CA compris entre 10k et 50k MRR. Pour certains projets à forte dimension R&D ou pour des levées de fonds de repeat entrepreneurs, nous pouvons intervenir sur un simple deck ou idée",
        },
      },
      {
        id: "11",
        type: "heading",
        data: {
          title: "Round & Valorisation",
          level: 2,
          caption: null,
        },
      },
      {
        id: "12",
        type: "paragraph",
        data: {
          text: "Nous intervenons du pre-seed à la série-A, sur des tours d'un montant minimum de 500 K€ afin de donner suffisamment de visibilité aux entrepreneurs pour déployer leur stratégie. 80% de nos levées se font à des valorisations inférieurs à 10 M€.",
        },
      },
      {
        id: "13",
        type: "heading",
        data: {
          title: "Scalabilité & Profondeur de marché",
          level: 2,
          caption: null,
        },
      },
      {
        id: "14",
        type: "paragraph",
        data: {
          text: "Nous favorisons des business scalables à l'international, récurrents et capables de viser un CA cible de 70M+ sous 7 à 10 ans. La plupart des dossiers que nous sélectionnons sont digitaux (SaaS, Marketplace, API) ou sont des concepts/marques forts et duplicables.",
        },
      },
      {
        id: "15",
        type: "cards-quote",
        data: {
          cards: [
            {
              name: "John Doe",
              quote: "Lorem ipsum",
              job: "Expert Branding",
            },
            {
              name: "John Doe",
              quote: "Lorem ipsum",
              job: "Expert Branding",
            },
            {
              name: "John Doe",
              quote: "Lorem ipsum",
              job: "Expert Branding",
            },
          ],
        },
      },
      {
        id: "16",
        type: "heading",
        data: {
          title: "Une équipe expérimentée",
          level: 2,
          caption: null,
        },
      },
      {
        id: "17",
        type: "club-team-leaders",
        data: null,
      },
      {
        id: "18",
        type: "heading",
        data: {
          title: "Track record",
          level: 2,
          caption: null,
        },
      },
      {
        id: "19",
        type: "club-track-record",
        data: null,
      },
    ],
    modalities: [
      {
        id: "1",
        type: "heading",
        data: {
          title: "Lorem ipsum",
          level: 2,
          caption: null,
        },
      },
      {
        id: "2",
        type: "paragraph",
        data: {
          text:
            "Lorem ipsum dolor sit amet consectetur. Ipsum in cras arcu imperdiet ut nisi amet a. Pulvinar mattis malesuada at in nulla volutpat enim lorem convallis. In lectus nec ac non elementum semper. Velit sed euismod rhoncus tortor ultrices tortor pharetra. Curabitur mauris sed cursus varius. Venenatis ipsum etiam molestie sed sed consectetur. Urna tincidunt bibendum aliquet molestie arcu lorem aenean.\n" +
            "Velit sed euismod rhoncus tortor ultrices tortor pharetra. Curabitur mauris sed cursus varius. Venenatis ipsum etiam molestie sed sed consectetur. Urna tincidunt bibendum aliquet molestie arcu lorem aenean.",
        },
      },
      {
        id: "3",
        type: "club-modalities-card",
        data: null,
      },
      {
        id: "4",
        type: "faq-block",
        data: {
          elements: [
            {
              question: "Lorem ipsum",
              answer:
                "Lorem ipsum dolor sit amet consectetur. Ipsum in cras arcu imperdiet ut nisi amet",
            },
            {
              question: "Lorem ipsum",
              answer:
                "Lorem ipsum dolor sit amet consectetur. Ipsum in cras arcu imperdiet ut nisi amet",
            },
            {
              question: "Lorem ipsum",
              answer:
                "Lorem ipsum dolor sit amet consectetur. Ipsum in cras arcu imperdiet ut nisi amet",
            },
            {
              question: "Lorem ipsum",
              answer:
                "Lorem ipsum dolor sit amet consectetur. Ipsum in cras arcu imperdiet ut nisi amet",
            },
          ],
        },
      },
    ],
    logo: {
      id: "img_01",
      url: "https://s3-alpha-sig.figma.com/img/9459/8ce9/180accc2f8c4c4631442e106558ac3c2?Expires=1724025600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=LeHBRXGLLn5eXEdknpJdtII4~J4E0Bx~EuAEhFxhunpyDA~2AeHjXwv3nGrsJaOauzPGweIKk5fru4TXW8fPUZWxyGnx9NQLI51a6Qnk-1jT~k-dGgm~0hX0bc7lEwpwe0xTG0pEiBVSqu48JelWL-Ll~e5lRI8pQpRHj2OTNr5K7M-GRfHMUnm~Bi1s8lQXI6-W8USHy2gljdZM~B6SSD5bDApM4Zuj-8QrBPKippEy5Ptn4m7QQ~UD90IVKhrIYbYQz-XPedwJ9THfUt8lR~zlcHS7Roek0bfZ-BjCf5cOqPRzhFveR5i4R~waJ6wvG1jqk7M4Um-PrUGa9tPYdg__",
      base64: null,
      originalFilename: null,
      resizedImages: [],
    },
    cover: {
      id: "img_02",
      url: "https://s3-alpha-sig.figma.com/img/7340/34c8/f77fe471422b236644415a7b4f1317bb?Expires=1722816000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=WxvksCUFIatUubcqaz7whDwJXL-k0IynKs0K05WmEtnMkSROjA9xOSuvtHkom6Bdht3EyBKWtGN0iXjYfyBTTSBktYC4GwJ-tRYr6NAcQj3SzOVl-tz-96XWShe54KfwET5Z9LYw8GcMXmsWH74nAL6Na79uE~u7wffNRCgepRcsauZFhk2lTNVLUUb8HXUh5uBgusLUnyC2wNLeMRbr9IfKPnarCZMAOpdvfbXCtM7wQw-jIFbWAtq2IXbr8LJkSu3GGvBqj1LOSF9L1ZtQPpL1uonkNaZuMy5s4rifxpUQphxOk1vEO0qup9HKFtee7c5nH9m9DeNv8cY8akTh9g__",
      base64: null,
      originalFilename: null,
      resizedImages: [],
    },
    thankYouImage: {
      id: "img_03",
      url: "https://s3-alpha-sig.figma.com/img/7340/34c8/f77fe471422b236644415a7b4f1317bb?Expires=1722816000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=WxvksCUFIatUubcqaz7whDwJXL-k0IynKs0K05WmEtnMkSROjA9xOSuvtHkom6Bdht3EyBKWtGN0iXjYfyBTTSBktYC4GwJ-tRYr6NAcQj3SzOVl-tz-96XWShe54KfwET5Z9LYw8GcMXmsWH74nAL6Na79uE~u7wffNRCgepRcsauZFhk2lTNVLUUb8HXUh5uBgusLUnyC2wNLeMRbr9IfKPnarCZMAOpdvfbXCtM7wQw-jIFbWAtq2IXbr8LJkSu3GGvBqj1LOSF9L1ZtQPpL1uonkNaZuMy5s4rifxpUQphxOk1vEO0qup9HKFtee7c5nH9m9DeNv8cY8akTh9g__",
      base64: null,
      originalFilename: null,
      resizedImages: [],
    },
  },
  configuration: {
    isPremiumAvailable: true,
    subscriptionsOpeningDate: "2024-01-01",
    subscriptionsClosingDate: "2025-01-01",
    subscriptionPrice: {
      unitAmountInCents: 10000,
      currency: "EUR",
      renewalInterval: "month",
      renewalIntervalCount: 1,
      active: true,
    },
    introductorySubscriptionPrice: {
      unitAmountInCents: 10000,
      currency: "EUR",
      renewalInterval: "month",
      renewalIntervalCount: 1,
      active: true,
    },
    maxIntroductorySubscriptions: 100,
    maxSubscriptions: 1000,
  },
  leaders: [
    {
      id: "1",
      profileImage: {
        url: "https://s3-alpha-sig.figma.com/img/5b70/9ab2/a497c64b4459c8a8f9eb42c42bbc5857?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dtp2WR61-khfAEI~PlLKQvVMR4jJmn6i8A4w08t7zXvfr8-9uTVUgmVQ~0QWRMdCEiz77VE1HbKkFqYfJkTLZkntwqtTm8-faPN5tV4OEGXAZQPs7qhfG~jfOOH8LLYOEgBeQdwmxfgz6TzOipXSqKac4tvHv1d6bpxhshaRIvZWIl4TF-4K-8V4-qCK4~0vV0FovuSGNP8F3ROEeM2dLW3XhnBZfruDFGe8O66xreNNzl5gIhtdzW-k7lG02g0JGSG5Rtz9omeupQg12AIJh8y88EYUBqTidzk4VxWKrljwPsa85oCRl514JcW8IdBwYUlGSWHKvfO0S0y7smvN2Q__",
        id: null,
        base64: null,
        originalFilename: null,
        resizedImages: null,
      },
      firstName: "John",
      lastName: "Doe",
      socialLinks: {
        linkedin: "https://www.linkedin.com",
      },
      title: "Expert Branding",
      shortDescription: "Lorem ipsum",
      description:
        "Lorem ipsum dolor sit amet consectetur. Eget ante sapien montes pellentesque donec.Lorem ipsum dolor sit amet consectetur. Eget ante sapien montes pellentesque donec.",
      specialities: [
        {
          code: "00001",
          name: "speciality_1",
        },
      ],
      position: 1,
    },
    {
      id: "2",
      profileImage: {
        url: "https://s3-alpha-sig.figma.com/img/eb9a/abb1/fb35007cd2de4b11adc77f41b0a77be0?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=WV7i8D1~RTgo3v61IWn2GiI42u74G03kfzWZ7THoEOWldZwtUkPcNT9R~zkOuvLRPYE6GBhrK1jowphh8t8n-i8J7JqD9wZ74YNZDP1cIC69e3rDXV29FGndRQsRb148Ug0kZiNKeOtBH4KLDKkv8O~N14FyByfyaufSgthHiop21EhcTmZUvGIDSo11L9bBeVCuBa0PEyg2XnLKFeWhrOWPVie9DyUEMBIwpfnOOfYmF~nHAvakKi8MeCRG4EQcYRcSYdcDoFW4EeJoGd6AphXg3UB2QABiHAfwxGH5wEknU3F9ZiHO17f~-P3btp5~0dXzB48T1sdqpcE6ERU~6A__",
        id: null,
        base64: null,
        originalFilename: null,
        resizedImages: null,
      },
      firstName: "John",
      lastName: "Doe",
      socialLinks: {
        linkedin: "https://www.linkedin.com",
      },
      title: "Expert Branding",
      specialities: [
        {
          code: "00001",
          name: "speciality_1",
        },
      ],
      shortDescription: "Lorem ipsum",
      description:
        "Lorem ipsum dolor sit amet consectetur. Eget ante sapien montes pellentesque donec.Lorem ipsum dolor sit amet consectetur. Eget ante sapien montes pellentesque donec.",
      position: 2,
    },
    {
      id: "3",
      profileImage: {
        url: "https://s3-alpha-sig.figma.com/img/5b70/9ab2/a497c64b4459c8a8f9eb42c42bbc5857?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dtp2WR61-khfAEI~PlLKQvVMR4jJmn6i8A4w08t7zXvfr8-9uTVUgmVQ~0QWRMdCEiz77VE1HbKkFqYfJkTLZkntwqtTm8-faPN5tV4OEGXAZQPs7qhfG~jfOOH8LLYOEgBeQdwmxfgz6TzOipXSqKac4tvHv1d6bpxhshaRIvZWIl4TF-4K-8V4-qCK4~0vV0FovuSGNP8F3ROEeM2dLW3XhnBZfruDFGe8O66xreNNzl5gIhtdzW-k7lG02g0JGSG5Rtz9omeupQg12AIJh8y88EYUBqTidzk4VxWKrljwPsa85oCRl514JcW8IdBwYUlGSWHKvfO0S0y7smvN2Q__",
        id: null,
        base64: null,
        originalFilename: null,
        resizedImages: null,
      },
      firstName: "John",
      lastName: "Doe",
      socialLinks: {
        linkedin: "https://www.linkedin.com",
      },
      title: "Expert Branding",
      specialities: [
        {
          code: "00001",
          name: "speciality_1",
        },
      ],
      shortDescription: "Lorem ipsum",
      description:
        "Lorem ipsum dolor sit amet consectetur. Eget ante sapien montes pellentesque donec.Lorem ipsum dolor sit amet consectetur. Eget ante sapien montes pellentesque donec.",
      position: 3,
    },
    {
      id: "4",
      profileImage: {
        url: "https://s3-alpha-sig.figma.com/img/eb9a/abb1/fb35007cd2de4b11adc77f41b0a77be0?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=WV7i8D1~RTgo3v61IWn2GiI42u74G03kfzWZ7THoEOWldZwtUkPcNT9R~zkOuvLRPYE6GBhrK1jowphh8t8n-i8J7JqD9wZ74YNZDP1cIC69e3rDXV29FGndRQsRb148Ug0kZiNKeOtBH4KLDKkv8O~N14FyByfyaufSgthHiop21EhcTmZUvGIDSo11L9bBeVCuBa0PEyg2XnLKFeWhrOWPVie9DyUEMBIwpfnOOfYmF~nHAvakKi8MeCRG4EQcYRcSYdcDoFW4EeJoGd6AphXg3UB2QABiHAfwxGH5wEknU3F9ZiHO17f~-P3btp5~0dXzB48T1sdqpcE6ERU~6A__",
        id: null,
        base64: null,
        originalFilename: null,
        resizedImages: null,
      },
      firstName: "John",
      lastName: "Doe",
      socialLinks: {
        linkedin: "https://www.linkedin.com",
      },
      title: "Expert Branding",
      specialities: [
        {
          code: "00001",
          name: "speciality_1",
        },
      ],
      shortDescription: "Lorem ipsum",
      description:
        "Lorem ipsum dolor sit amet consectetur. Eget ante sapien montes pellentesque donec.Lorem ipsum dolor sit amet consectetur. Eget ante sapien montes pellentesque donec.",
      position: 4,
    },
    {
      id: "5",
      profileImage: {
        url: "https://s3-alpha-sig.figma.com/img/5b70/9ab2/a497c64b4459c8a8f9eb42c42bbc5857?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dtp2WR61-khfAEI~PlLKQvVMR4jJmn6i8A4w08t7zXvfr8-9uTVUgmVQ~0QWRMdCEiz77VE1HbKkFqYfJkTLZkntwqtTm8-faPN5tV4OEGXAZQPs7qhfG~jfOOH8LLYOEgBeQdwmxfgz6TzOipXSqKac4tvHv1d6bpxhshaRIvZWIl4TF-4K-8V4-qCK4~0vV0FovuSGNP8F3ROEeM2dLW3XhnBZfruDFGe8O66xreNNzl5gIhtdzW-k7lG02g0JGSG5Rtz9omeupQg12AIJh8y88EYUBqTidzk4VxWKrljwPsa85oCRl514JcW8IdBwYUlGSWHKvfO0S0y7smvN2Q__",
        id: null,
        base64: null,
        originalFilename: null,
        resizedImages: null,
      },
      firstName: "John",
      lastName: "Doe",
      socialLinks: {
        linkedin: "https://www.linkedin.com",
      },
      title: "Expert Branding",
      specialities: [
        {
          code: "00001",
          name: "speciality_1",
        },
      ],
      shortDescription: "Lorem ipsum",
      description:
        "Lorem ipsum dolor sit amet consectetur. Eget ante sapien montes pellentesque donec.Lorem ipsum dolor sit amet consectetur. Eget ante sapien montes pellentesque donec.",
      position: 5,
    },
  ],
  statistics: {
    followersCount: 255,
    clubLeadersCount: 5,
    liveProjectsCount: 5,
  },
  myMembership: {
    status: MembershipStatus.SUBSCRIBED,
  },
  socialLinks: {
    discordUrl: "https://discord.gg/7j4jWx5",
  },
  trackRecords: [
    {
      id: "1",
      logo: {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_aHK0bhh6tArmyjxrz5N8fFq0Ij5UgwvGgw&s",
        id: "img_01",
        base64: null,
        originalFilename: null,
        resizedImages: [],
      },
      title: "track_record_1",
      industry: {
        code: "00001",
        name: "industry_00001",
      },
      shortDescription: "Lorem ipsum",
      description: "Lorem ipsum",
      link: "https://tudigo.co",
      position: 1,
    },
    {
      id: "2",
      logo: {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_aHK0bhh6tArmyjxrz5N8fFq0Ij5UgwvGgw&s",
        id: "img_02",
        base64: null,
        originalFilename: null,
        resizedImages: [],
      },
      title: "track_record_1",
      industry: {
        code: "00001",
        name: "industry_00001",
      },
      shortDescription: "Lorem ipsum",
      description: "Lorem ipsum",
      link: "https://tudigo.co",
      position: 2,
    },
    {
      id: "3",
      logo: {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_aHK0bhh6tArmyjxrz5N8fFq0Ij5UgwvGgw&s",
        id: "img_03",
        base64: null,
        originalFilename: null,
        resizedImages: [],
      },
      title: "track_record_1",
      industry: {
        code: "00001",
        name: "industry_00001",
      },
      shortDescription: "Lorem ipsum",
      description: "Lorem ipsum",
      link: "https://tudigo.co",
      position: 3,
    },
    {
      id: "4",
      logo: {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_aHK0bhh6tArmyjxrz5N8fFq0Ij5UgwvGgw&s",
        id: "img_04",
        base64: null,
        originalFilename: null,
        resizedImages: [],
      },
      title: "track_record_1",
      industry: {
        code: "00001",
        name: "industry_00001",
      },
      shortDescription: "Lorem ipsum",
      description: "Lorem ipsum",
      link: "https://tudigo.co",
      position: 4,
    },
    {
      id: "5",
      logo: {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_aHK0bhh6tArmyjxrz5N8fFq0Ij5UgwvGgw&s",
        id: "img_05",
        base64: null,
        originalFilename: null,
        resizedImages: [],
      },
      title: "track_record_1",
      industry: {
        code: "00001",
        name: "industry_00001",
      },
      shortDescription: "Lorem ipsum",
      description: "Lorem ipsum",
      link: "https://tudigo.co",
      position: 5,
    },
    {
      id: "6",
      logo: {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_aHK0bhh6tArmyjxrz5N8fFq0Ij5UgwvGgw&s",
        id: "img_06",
        base64: null,
        originalFilename: null,
        resizedImages: [],
      },
      title: "track_record_1",
      industry: {
        code: "00001",
        name: "industry_00001",
      },
      shortDescription: "Lorem ipsum",
      description: "Lorem ipsum",
      link: "https://tudigo.co",
      position: 6,
    },
    {
      id: "7",
      logo: {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_aHK0bhh6tArmyjxrz5N8fFq0Ij5UgwvGgw&s",
        id: "img_07",
        base64: null,
        originalFilename: null,
        resizedImages: [],
      },
      title: "track_record_1",
      industry: {
        code: "00001",
        name: "industry_00001",
      },
      shortDescription: "Lorem ipsum",
      description: "Lorem ipsum",
      link: "https://tudigo.co",
      position: 7,
    },
    {
      id: "8",
      logo: {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_aHK0bhh6tArmyjxrz5N8fFq0Ij5UgwvGgw&s",
        id: "img_08",
        base64: null,
        originalFilename: null,
        resizedImages: [],
      },
      title: "track_record_1",
      industry: {
        code: "00001",
        name: "industry_00001",
      },
      shortDescription: "Lorem ipsum",
      description: "Lorem ipsum",
      link: "https://tudigo.co",
      position: 8,
    },
    {
      id: "9",
      logo: {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_aHK0bhh6tArmyjxrz5N8fFq0Ij5UgwvGgw&s",
        id: "img_09",
        base64: null,
        originalFilename: null,
        resizedImages: [],
      },
      title: "track_record_1",
      industry: {
        code: "00001",
        name: "industry_00001",
      },
      shortDescription: "Lorem ipsum",
      link: "https://tudigo.co",
      position: 9,
    },
    {
      id: "10",
      logo: {
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_aHK0bhh6tArmyjxrz5N8fFq0Ij5UgwvGgw&s",
        id: "img_10",
        base64: null,
        originalFilename: null,
        resizedImages: [],
      },
      title: "track_record_1",
      industry: {
        code: "00001",
        name: "industry_00001",
      },
      shortDescription: "Lorem ipsum",
      link: "https://tudigo.co",
      position: 10,
    },
  ],
  industries: [
    {
      code: "00001",
      name: "Tech",
    },
    {
      code: "00002",
      name: "Medical Devices",
    },
    {
      code: "00003",
      name: "SaaS",
    },
    {
      code: "00004",
      name: "Insurance",
    },
    {
      code: "00005",
      name: "AI/ML",
    },
    {
      code: "00006",
      name: "LegalTech",
    },
    {
      code: "00007",
      name: "Impact",
    },
  ],
  territories: [
    {
      code: "00001",
      name: "Europe",
    },
    {
      code: "00002",
      name: "Asie",
    },
    {
      code: "00003",
      name: "Amérique du nord",
    },
  ],
  maturities: [
    {
      code: "00001",
      name: "Pre-Seed",
    },
    {
      code: "00002",
      name: "Seed",
    },
    {
      code: "00003",
      name: "Série A",
    },
  ],
  faqs: [],
  mentorings: [
    {
      code: "00001",
      name: "Advisory",
    },
    {
      code: "00002",
      name: "Direction financière",
    },
  ],
};
