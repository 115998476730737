import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PKResult } from "@placekit/client-js";
import placekit from "@placekit/client-js/lite";
import { beforeWrite } from "@popperjs/core/lib/enums";
import { Modifier } from "@popperjs/core/lib/types";
import { usePopper } from "react-popper";

import { Address } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

import { Icon } from "../icons/icon";
import { InputGroupProps } from "../input-group";
import { TextField } from "../text-field";

type AddressSelectorProps = {
  onAddressSelected: (value: Address) => void;
  country?: string;
} & InputGroupProps;

const placeKitApiKey = import.meta.env["VITE_PLACEKIT_API_KEY"] as string;
const pk = placekit(placeKitApiKey);

export function AddressSelector(props: AddressSelectorProps) {
  const { onAddressSelected, country = "FR" } = props;
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = React.useState<string>("");
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  const setMinWithModifier = useMemo(() => {
    return {
      enabled: true,
      phase: beforeWrite,
      name: "setPopperMinWidth",
      requires: ["computeStyles"],
      fn: ({ state }) => {
        state.styles.popper.minWidth = `${state.rects.reference.width}px`;

        return state;
      },
    } as Modifier<"setPopperMinWidth", any>;
  }, []);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    strategy: "fixed",
    modifiers: [
      setMinWithModifier,
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const [results, setResults] = useState<PKResult[]>([]);
  const [show, setShow] = useState(false);
  const [selectionIndex, setSelectionIndex] = useState<number>();

  useEffect(() => {
    setSelectionIndex(undefined);
    if (searchValue.length === 0) return;
    pk.search(searchValue, {
      countries: [country],
      types: ["street"],
    }).then((response) => {
      setResults(response.results);
    });
  }, [country, searchValue]);

  useEffect(() => {
    setShow(results.length > 0 && searchValue.length > 0);
  }, [results, searchValue.length]);

  const pkResultToAddress = useCallback((item: PKResult): Address => {
    return {
      id: null,
      name: null,
      street2: null,
      state: null,
      email: null,
      firstName: null,
      lastName: null,
      isVerified: null,
      phoneNumber: null,
      region: null,
      street1: item.name,
      city: item.city,
      country: item.countrycode?.toUpperCase(),
      postalCode: item.zipcode.join(","),
      formatted: "",
    };
  }, []);

  const onSelectItem = (item: PKResult) => {
    onAddressSelected && onAddressSelected(pkResultToAddress(item));
    setShow(false);
    setSearchValue("");
  };

  const select = (index?: number, autoScroll?: boolean) => {
    setSelectionIndex(index);
    if (autoScroll && index !== undefined) {
      if (popperElement) {
        const clientHeight = popperElement.clientHeight;
        const currentScrollOffset = popperElement.scrollTop;
        let endOffset = 0;
        let startOffset = 0;
        for (let i = 0; i <= index; i++) {
          const itemElement = popperElement.children.item(i);
          if (itemElement) {
            const itemHeight = itemElement.getBoundingClientRect().height;
            endOffset += itemHeight;
            startOffset = endOffset - itemHeight;
          }
        }

        if (startOffset < currentScrollOffset) {
          popperElement.scrollTop = startOffset;
        } else if (endOffset > clientHeight + currentScrollOffset) {
          popperElement.scrollTop = endOffset - clientHeight;
        }
      }
    }
  };

  const searchOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const key = e.key || e.keyCode;
    if (key === "ArrowDown" || key === 40) {
      if (results.length > 0) {
        if (selectionIndex === undefined) {
          select(0, true);
        } else if (results.length > selectionIndex + 1) {
          select(selectionIndex + 1, true);
        } else {
          select(results.length - 1, true);
        }
      }
    }

    if (key === "ArrowUp" || key === 38) {
      if (results.length > 0) {
        if (selectionIndex === undefined) {
          select(0, true);
        } else if (selectionIndex > 0) {
          select(selectionIndex - 1, true);
        }
      }
    }
    if (key === "Enter" || key === 13) {
      if (selectionIndex !== undefined && results.length > selectionIndex) {
        onSelectItem(results[selectionIndex]);
        e.stopPropagation();
        e.preventDefault();
      }
    }
  };

  return (
    <>
      <TextField
        {...props}
        autoComplete="off"
        groupRef={setReferenceElement}
        value={searchValue}
        placeholder={t("components.address_selector.placeholder")}
        iconRight={
          <Icon
            name="Location"
            primaryColor={themeColors["dark-2"]}
            size="S"
            height={18}
            width={18}
          />
        }
        onKeyDown={searchOnKeyDown}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <div
        ref={setPopperElement}
        className={cn(
          "border-accent-medium address-list z-10 hidden flex-col gap-1 overflow-y-scroll rounded-lg border bg-white p-3",
          {
            flex: show,
          },
        )}
        style={styles.popper}
        {...attributes.popper}
      >
        {results.map((result, index) => (
          <div
            onMouseOver={() => {
              select(index);
            }}
            onMouseOut={() => {
              select(undefined);
            }}
            onClick={() => {
              onSelectItem(result);
            }}
            key={index}
            className={cn(
              "hover:border-accent-medium flex min-h-[38px] w-full cursor-pointer items-center rounded-lg border border-transparent bg-white px-4 transition-all",
              {
                "bg-accent-super-light border-accent-medium":
                  index === selectionIndex,
              },
            )}
          >
            {result.name}, {result.city}, {result.country}
          </div>
        ))}
      </div>
    </>
  );
}
