import { cn } from "@tudigo-monorepo/core-tudigo-theme";

export function ToggleChip({
  label,
  value,
  selectedValue,
  setSelectedValue,
}: {
  label: string;
  value: string | number;
  selectedValue: string | number | undefined;
  setSelectedValue: (value: string | number) => void;
}) {
  return (
    <button
      className={cn(
        "whitespace-nowrap rounded border px-2 py-1 text-sm font-medium",
        "border-dark-2 text-dark-2",
        selectedValue === value &&
          "border-accent text-accent bg-accent-super-light",
      )}
      onClick={() => setSelectedValue(value)}
    >
      {label}
    </button>
  );
}
