import { Navigate, Route, Routes } from "react-router-dom";

import { CircleListingPage } from "../circle-listing-page";
import { CircleListingAllContent } from "../components/circle-listing-all-content";
import { CircleListingMyCircleContent } from "../components/circle-listing-my-circle-content";

export function CircleListingRouteWebapp() {
  return (
    <Routes>
      <Route element={<CircleListingPage />}>
        <Route path="all" element={<CircleListingAllContent />} />
        <Route path="my-circles" element={<CircleListingMyCircleContent />} />
        <Route path="*" element={<Navigate to="all" />} />
      </Route>
    </Routes>
  );
}
