import { useState } from "react";
import { toast } from "react-toastify";

import { TudigoError } from "@tudigo-monorepo/core-tudigo-api";
import {
  BSRegenerationRequest,
  Investment,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  FormContextProvider,
  getSmartFormComponents,
} from "@tudigo-monorepo/web-feature-form";
import { useCreateBSRegenerationRequestMutation } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  Modal,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { managerInvestmentQueryFields } from "../../pages/investment-manager-page/investment-manager-page";
import { userInvestmentsQueryFields } from "../../pages/investment-page/investment-page";

type RegenerateBsInvestmentModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
  investment: Investment;
};

export function BSRegenerationModal(props: RegenerateBsInvestmentModalProps) {
  const { investment, isModalOpen, setIsModalOpen } = props;

  const { isAdmin, user } = useUserBusiness();

  const { SmartTextAreaField } =
    getSmartFormComponents<BSRegenerationRequest>();
  const { t } = useTranslation();

  const [error, setError] = useState<TudigoError | null>(null);
  const [bsRegenerationRequestFormData, setBsRegenerationRequestFormData] =
    useState<BSRegenerationRequest>({
      comment: "",
    });

  const submitEnabled = bsRegenerationRequestFormData.comment !== "";

  const {
    mutate: createBsRegenerationRequest,
    isPending: isCreateBsRegenerationRequestLoading,
  } = useCreateBSRegenerationRequestMutation({
    onSuccess: () => {
      setIsModalOpen(false);
      toast((content) => (
        <Alert
          close={content.closeToast}
          icon="Checked"
          variant="success"
          title={t(
            "investments.back_office.investment_detail.bs_regeneration_modal.alert_on_success.title",
          )}
        />
      ));
    },
    onError: (err) => {
      setError(err);
      toast((content) => (
        <Alert
          close={content.closeToast}
          icon="Error"
          variant="error"
          title={t(
            "investments.back_office.investment_detail.bs_regeneration_modal.alert_on_error.title",
          )}
        />
      ));
    },
  });

  const handleSubmit = () => {
    const queryFields = isAdmin
      ? managerInvestmentQueryFields
      : userInvestmentsQueryFields;

    const queryFilters = {
      userId: user?.id ?? "me",
      projectId: investment.project.id,
    };

    createBsRegenerationRequest({
      investmentId: investment.id,
      query: {
        fields: queryFields,
        filters: queryFilters,
      },
      data: bsRegenerationRequestFormData,
    });
  };

  return (
    <Modal
      title={t(
        "investments.back_office.investment_detail.bs_regeneration_modal.title",
      )}
      confirmLabel={t(
        "investments.back_office.investment_detail.bs_regeneration_modal.confirm_label",
      )}
      isOpen={isModalOpen}
      setIsOpen={() => setIsModalOpen(false)}
      confirmAction={handleSubmit}
      confirmButtonProps={{
        disabled: !submitEnabled,
        iconRight: "Delete",
        isLoading: isCreateBsRegenerationRequestLoading,
        variant: "destructive",
      }}
      className="min-h-[605px] w-[calc(100%-32px)] sm:max-w-[654px]"
    >
      <div className="flex flex-col gap-y-4 p-4">
        <div>
          <Typography variant="body3-regular">
            {t(
              "investments.back_office.investment_detail.bs_regeneration_modal.content",
            )}
          </Typography>
        </div>
        <div className="border-light-1 rounded-lg border p-4">
          <FormContextProvider
            error={error}
            formData={bsRegenerationRequestFormData}
            setFormData={setBsRegenerationRequestFormData}
          >
            <div className="flex flex-col gap-4">
              <SmartTextAreaField
                name="comment"
                label={t(
                  "investments.back_office.investment_detail.bs_regeneration_modal.form.comment.label",
                )}
              />
            </div>
          </FormContextProvider>
        </div>
      </div>
    </Modal>
  );
}
