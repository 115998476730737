import { Simulator } from "@tudigo-monorepo/core-tudigo-api-models";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";
import {
  NTable,
  NTableBody,
  NTableCell,
  NTableHead,
  NTableHeader,
  NTableRow,
} from "@tudigo-monorepo/web-tudigo-components";

export function SimulatorBondsRepayments({
  data,
}: {
  data: Simulator | undefined;
}) {
  return (
    <>
      <h3 className="text-primary mb-4 mt-6 text-lg font-bold">Echéancier</h3>
      <NTable>
        <NTableHeader>
          <NTableRow>
            <NTableHead>Période</NTableHead>
            <NTableHead>Interêts (€)</NTableHead>
            <NTableHead>Capital (€)</NTableHead>
            <NTableHead>Paiement total (€)</NTableHead>
          </NTableRow>
        </NTableHeader>
        <NTableBody>
          {data?.repayments?.map((row, index) => (
            <NTableRow key={index}>
              <NTableCell>Échéance {row.periodIndex}</NTableCell>
              <NTableCell>
                {formatCurrency(row?.interestAmountInCents)}
              </NTableCell>
              <NTableCell>
                {formatCurrency(row?.capitalAmountInCents)}
              </NTableCell>
              <NTableCell>
                {formatCurrency(
                  Number(
                    (row?.interestAmountInCents ?? 0) +
                      (row?.capitalAmountInCents ?? 0),
                  ),
                )}
              </NTableCell>
            </NTableRow>
          ))}
        </NTableBody>
      </NTable>
    </>
  );
}
