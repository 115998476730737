import React from "react";
import { useIntl } from "react-intl";
import { Outlet } from "react-router-dom";

import { Chip, PageHead, Tabs } from "@tudigo-monorepo/web-tudigo-components";

import {
  MyInvestmentAssemblyPageContext,
  useMyInvestmentAssemblyPageContext,
} from "../investment-assembly-page/my-investment-assembly-page";

export function MyInvestmentAssemblyDetailPage() {
  const intl = useIntl();

  const {
    project,
    assembly,
    assemblyParticipant,
    participationFormData,
    setParticipationFormData,
    isAssemblyOpenForVoting,
    isAssemblyOpenForParticipationUpdate,
    isAssemblyExpired,
  } = useMyInvestmentAssemblyPageContext();

  const assemblyScheduledDate: Date | null = new Date(
    assembly.configuration?.scheduledEndDate ?? "",
  );
  let formattedScheduledDate: string | null;
  if (!isNaN(assemblyScheduledDate.getTime())) {
    formattedScheduledDate = `${assemblyScheduledDate.toLocaleDateString(
      intl.locale,
    )} ${assemblyScheduledDate.toLocaleTimeString(intl.locale)}`;
  } else {
    formattedScheduledDate = null;
  }

  return (
    <div id="my-investments-assembly-page" className="w-full">
      <PageHead
        title={intl.formatMessage({ id: "assembly_detail_page.head.title" })}
        levels={[
          {
            url: "/my-account/my-investments/list/all",
            text: intl.formatMessage({ id: "investments.my_investments" }),
            iconName: "Invest",
          },
          {
            url: `/my-account/my-investments/projects/${project.id}/assemblies`,
            text: project?.content?.name ?? "",
          },
        ]}
        chipsTop={
          assemblyParticipant.status === "replied"
            ? [
                {
                  label: intl.formatMessage({
                    id: "assembly.my_participation.status.replied",
                  }),
                  type: "success",
                  iconLeft: "DotChip",
                },
              ]
            : [
                {
                  label: intl.formatMessage({
                    id: "assembly.my_participation.status.notified",
                  }),
                  type: "error",
                  iconLeft: "DotChip",
                },
              ]
        }
      />

      <div className="mx-6 flex gap-4">
        <Chip label={assembly.name ?? ""} type="primary" className="mb-6" />
        <Chip
          label={formattedScheduledDate ?? ""}
          type="primary"
          className="mb-6"
        />
      </div>

      <div className="boder-light-2 mx-6 border-b" />

      <Tabs
        tabs={[
          {
            name: intl.formatMessage({
              id: "assembly_detail_page.tab.vote",
            }),
            redirectTo: "vote",
          },
          {
            name: intl.formatMessage({
              id: "assembly_detail_page.tab.documents",
            }),
            redirectTo: "documents",
          },
        ]}
        className="px-6 py-4"
      />

      <Outlet
        context={
          {
            project,
            assembly,
            assemblyParticipant,
            participationFormData,
            setParticipationFormData,
            isAssemblyOpenForVoting,
            isAssemblyOpenForParticipationUpdate,
            isAssemblyExpired,
          } satisfies MyInvestmentAssemblyPageContext
        }
      />
    </div>
  );
}
