import { Route, Routes } from "react-router-dom";

import { ProjectHolderRepaymentsPage } from "../../../pages/project-holder-repayments-page/project-holder-repayments-page";

export function ProjectHolderRepaymentsRouteWebApp() {
  return (
    <Routes>
      <Route path="/:projectId?" element={<ProjectHolderRepaymentsPage />} />
    </Routes>
  );
}
