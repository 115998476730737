import {
  ImageMedia,
  Media,
  VideoMedia,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { getYouTubeThumbnail } from "@tudigo-monorepo/core-tudigo-utils";

import { Icon } from "../../old/icons/icon";
import {
  CarouselContent,
  CarouselItem,
  CarouselRemainingThumbsCount,
  CarouselRoot,
  CarouselThumb,
  CarouselThumbList,
  FloatingCarouselNext,
  FloatingCarouselPrevious,
} from "../carousel";

const CarouselImage = ({
  media,
  className,
}: {
  media: ImageMedia;
  className?: string;
}) => {
  return (
    <img
      className={cn("w-full object-cover object-center", className)}
      src={media.data?.base64 || (media.data?.url as string)}
    />
  );
};

const CarouselVideo = ({ media }: { media: VideoMedia }) => (
  <iframe
    className="aspect-video h-full w-full object-cover object-center"
    src={media.data.iframeUrl}
    title={media.data.title}
  />
);

const CarouselVideoThumb = ({ media }: { media: VideoMedia }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${getYouTubeThumbnail(media.data.url)})`,
      }}
      className="flex h-full w-full items-center justify-center bg-cover bg-center"
    >
      <Icon
        name="Play"
        primaryColor={themeColors.white}
        className="h-10 w-10 rounded-full text-white"
        bgColor={themeColors.primary}
      />
    </div>
  );
};

const CarouselMedia = ({
  media,
  isPreviewMode = false,
  className,
}: {
  media: ImageMedia | VideoMedia;
  isPreviewMode?: boolean;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        "flex h-full justify-center overflow-hidden rounded-[7px] bg-gray-200",
        className,
      )}
    >
      {media.type === "image" && (
        <CarouselImage
          media={media}
          className={isPreviewMode ? "w-full" : ""}
        />
      )}
      {media.type === "video" &&
        (isPreviewMode ? (
          <CarouselVideoThumb media={media} />
        ) : (
          <CarouselVideo media={media} />
        ))}
    </div>
  );
};

type BlockCarouselProps = {
  medias?: Media[];
};

export function BlockCarousel(props: BlockCarouselProps) {
  const { medias } = props;

  if (medias?.length === 0)
    return (
      <div className="flex h-[460px] items-center justify-center rounded-lg bg-gray-100">
        <Icon
          name="Image"
          className="h-10 w-10"
          primaryColor={themeColors["dark-4"]}
        />
      </div>
    );

  return (
    <CarouselRoot>
      <div className="relative">
        <CarouselContent className="mb-4">
          {medias?.map((media, index) => (
            <CarouselItem key={index}>
              <CarouselMedia media={media} className="aspect-video" />
            </CarouselItem>
          ))}
        </CarouselContent>
        {medias && medias?.length > 1 && (
          <>
            <FloatingCarouselPrevious />
            <FloatingCarouselNext />
          </>
        )}
      </div>
      {medias && medias?.length > 1 && (
        // grid of 4 column
        <CarouselThumbList className="max-h-[150px] grid-cols-4">
          {medias.map((media, index) => {
            if (medias?.length > 4 && index > 3) {
              return null;
            }

            return (
              <CarouselThumb
                slideIndex={index}
                key={index}
                className="relative aspect-video w-full"
              >
                <CarouselRemainingThumbsCount
                  itemsCount={medias.length}
                  currentIndex={index}
                />
                <CarouselMedia media={media} isPreviewMode />
              </CarouselThumb>
            );
          })}
        </CarouselThumbList>
      )}
    </CarouselRoot>
  );
}
