import {
  Organization,
  PaymentMethodType,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { ORGANIZATIONS } from "@tudigo-monorepo/core-tudigo-utils";
import { ListElement } from "@tudigo-monorepo/web-tudigo-components";

type OrganizationSelectorProps = {
  organization: Organization;
  active: boolean;
  setInvestmentOrganizationFormData: (data: any) => void;
  isPeaAccount?: boolean;
  isPeaPmeAccount?: boolean;
  setSelectedOrganizationId: (organizationId: string) => void;
  setKycModalOpen: (isOpen: boolean) => void;
};

export function OrganizationSelectorElement(props: OrganizationSelectorProps) {
  const {
    organization,
    active,
    setInvestmentOrganizationFormData,
    setSelectedOrganizationId,
    setKycModalOpen,
    isPeaPmeAccount = false,
    isPeaAccount = false,
  } = props;

  const { t } = useTranslation();

  const upperText = isPeaAccount
    ? t("investment_funnel.step_select_organization.organization_type.pea")
    : isPeaPmeAccount
      ? t(
          "investment_funnel.step_select_organization.organization_type.pea_pme",
        )
      : organization.type === ORGANIZATIONS.TYPE.COMPANY
        ? t(
            "investment_funnel.step_select_organization.organization_type.company",
          )
        : t(
            "investment_funnel.step_select_organization.organization_type.individual",
          );

  const unselectOrganization = () => {
    setInvestmentOrganizationFormData({
      organizationId: null,
      paymentMethodType: undefined,
    });
  };

  const handleClick = () => {
    if (active) {
      return unselectOrganization();
    }

    if (isPeaPmeAccount) {
      return setInvestmentOrganizationFormData({
        organizationId: organization.id,
        paymentMethodType: PaymentMethodType.PEA_PME,
      });
    }

    if (isPeaAccount) {
      return setInvestmentOrganizationFormData({
        organizationId: organization.id,
        paymentMethodType: PaymentMethodType.PEA,
      });
    }

    setInvestmentOrganizationFormData({
      organizationId: organization.id,
    });
  };

  const handleClickOnUnvalidatedOrganization = () => {
    if (active) {
      return unselectOrganization();
    }

    setSelectedOrganizationId(organization.id);
    setKycModalOpen(true);
  };

  const isReadyToSubscribe = organization.readyToSubscribe;
  const isKYCApproved =
    organization.internalKycStatus ===
    ORGANIZATIONS.INTERNAL_KYC_STATUS.APPROVED;

  return (
    <ListElement
      toggleActiveOnClick={false}
      active={isReadyToSubscribe ? active : false}
      startElement={{
        upperText: upperText,
        lowerText: organization.name,
        img: {
          icon: {
            name: "Profile",
          },
        },
      }}
      endElement={{
        info: {
          chip: {
            type: isKYCApproved ? "success" : "warning",
            label: t(
              isKYCApproved
                ? "investment_funnel.step_select_organization.status.approved"
                : "investment_funnel.step_select_organization.status.waiting_kyc_validation",
            ),
            iconLeft: "DotChip",
          },
        },
        checkbox: true,
      }}
      onClick={
        isReadyToSubscribe ? handleClick : handleClickOnUnvalidatedOrganization
      }
    />
  );
}
