import { Title } from "@tudigo-monorepo/web-tudigo-components";

export function LoginPage() {
  return (
    <div className="flex h-screen items-center justify-center">
      <div className="mt-8 flex w-1/2 flex-col items-center gap-6 text-center">
        <Title>Welcome to LoginPage!</Title>
      </div>
    </div>
  );
}

export default LoginPage;
