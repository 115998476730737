import { CMSBlock } from "../cms";

export interface CommonFormOption {
  bullet: string | null;
}

export type Answers = {
  [key: string]: {
    [inputName: string]:
      | string
      | number
      | Array<number | string>
      | boolean
      | null;
  };
};

export type AnswerSetter = (
  sectionKey: string,
  input: FormInput,
  value: string | number | Array<number | string> | null | boolean | [],
) => Answers;

export interface CommonFormParameters {
  maxChoices: number | null;
  initialValue: null;
}

export interface SelectOption extends CommonFormOption {
  label: string | number;
  value: string | number;
  bullet: string | null;
  exclusive?: boolean;
  disableCondition?: string;
}

export interface SelectParameters extends CommonFormParameters {
  choices: SelectOption[] | null;
}

export interface SwitchOption extends CommonFormOption {
  label: string | null;
  value: string;
}

export interface SwitchParameters extends CommonFormParameters {
  choices: SwitchOption[] | null;
}

export interface SliderOption extends CommonFormOption {
  label: string | null;
  value: number | string;
}

export interface ChoicesSliderParameters extends CommonFormParameters {
  choices: SliderOption[] | null;
}

export interface RangeSliderParameters extends CommonFormParameters {
  min: number;
  max: number;
  step: number;
  unit: string;
  multiRanges: boolean;
}

export interface CommonFormInput {
  id: string | null;
  name: string | null;
  label: string | null;
  description: string | null;
  position: number | null;
  parameters: CommonFormParameters | null;
}

export interface MultiSelectInput extends CommonFormInput {
  type: "multi_select" | null;
  parameters: SelectParameters | null;
}

export interface HiddenInput extends CommonFormInput {
  type: "hidden" | null;
}

export interface SelectInput extends CommonFormInput {
  type: "select" | null;
  parameters: SelectParameters | null;
}

export interface SwitchInput extends CommonFormInput {
  type: "switch" | null;
  parameters: SwitchParameters | null;
}

export interface ChoicesSliderInput extends CommonFormInput {
  type: "choices_slider" | null;
  parameters: ChoicesSliderParameters | null;
}

export interface RangeSliderInput extends CommonFormInput {
  type: "range_slider" | null;
  parameters: RangeSliderParameters | null;
}

export type FormInput =
  | MultiSelectInput
  | SelectInput
  | SwitchInput
  | ChoicesSliderInput
  | HiddenInput
  | RangeSliderInput;

export enum ActionTypes {
  SUBMIT = "submit",
  DECLINE = "decline",
  EXIT = "exit",
  CONFIRM = "confirm",
}

export interface FormPageAction {
  type: `${ActionTypes}` | string;
  label: string | null;
  onClick?: (answers: Answers) => void;
  isLoading?: boolean;
}

export interface FormPage {
  id: string | null;
  title: string | null;
  index: number | null;
  sectionKey?: string | null;
  inputs: FormInput[] | null;
  rendering: CMSBlock[] | null;
  actions: FormPageAction[] | null;
}

export interface FormSection {
  id: string | null;
  name: string | null;
  key: string | null;
  position: number | null;
  pages: FormPage[] | null;
}

export interface Form {
  id: string | null;
  name: string | null;
  locale: string | null;
  status: string | null;
  lock: boolean | null;
  sections: FormSection[] | null;
}
