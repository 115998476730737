import { getApiClient, InvestmentsFilters } from "../../..";
import { ProjectHolderInvestorsFilters } from "../../queries/project-holders/use-get-project-holder-investors";

type CreateInvestmentExportParams = {
  filters: InvestmentsFilters;
  limit?: number;
};

type CreateInvestorExportParams = {
  investorProfileId: string;
  filters: ProjectHolderInvestorsFilters;
};

export const createInvestmentExport = async (
  params: CreateInvestmentExportParams,
) => {
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.investments.createInvestmentsExport({
    ...params.filters,
    limit: params.limit,
  });

  return response.data;
};

export const createInvestorExport = async (
  params: CreateInvestorExportParams,
) => {
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.investments.createInvestorExport(
    params.investorProfileId,
    {
      ...params.filters,
    },
  );

  return response.data;
};
