import { Navigate, Route, Routes } from "react-router-dom";

import { SpaceEnum, SpaceProvider } from "@tudigo-monorepo/tudigo-session";
import { ProjectHolderDashboardRoute } from "@tudigo-monorepo/web-feature-dashboard";
import {
  InvestmentRouteWebapp,
  ProjectHolderInvestorsRouteWebapp,
} from "@tudigo-monorepo/web-feature-investments";
import { KYCRoute } from "@tudigo-monorepo/web-feature-kyc";
import { MyProfileRoute } from "@tudigo-monorepo/web-feature-my-profile";
import { MySettingsRoute } from "@tudigo-monorepo/web-feature-my-settings";
import { ProjectRoutesWebApp } from "@tudigo-monorepo/web-feature-projects";
import { ProjectHolderRepaymentsRouteWebApp } from "@tudigo-monorepo/web-feature-repayments";
import {
  ConnectedLayout,
  useProjectHolderNavigation,
} from "@tudigo-monorepo/web-tudigo-layout";

export const ProjectHolderRoutes = () => {
  return (
    <SpaceProvider currentSpace={SpaceEnum.PROJECT_HOLDER}>
      <Routes>
        <Route
          path={"organization/:organizationId/kyc/*"}
          element={<KYCRoute />}
        />
        <Route path="projects?/*" element={<ProjectRoutesWebApp />} />
        <Route
          element={
            <ConnectedLayout navigation={useProjectHolderNavigation()} />
          }
        >
          <Route path="dashboard/*" element={<ProjectHolderDashboardRoute />} />
          <Route
            path="investors/*"
            element={<ProjectHolderInvestorsRouteWebapp />}
          />
          <Route
            path="repayments/*"
            element={<ProjectHolderRepaymentsRouteWebApp />}
          />
          <Route path="profile-and-accounts/*" element={<MyProfileRoute />} />
          <Route path="statistics/*" element={<InvestmentRouteWebapp />} />
          <Route path="my-settings/*" element={<MySettingsRoute />} />
          <Route
            path="*"
            element={<Navigate to="/project-holder/dashboard" />}
          />
        </Route>
      </Routes>
    </SpaceProvider>
  );
};
