import {
  MultiSelectInput,
  SelectOption,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { Stack } from "@tudigo-monorepo/web-tudigo-components";

import { getSelectInputColumnsCount } from "../../utils/form-layout";
import { DynamicFormOption } from "../dynamic-form-option";

type MultiselectInputProps = {
  input: MultiSelectInput;
  selectedValues: Array<string | number> | null;
  onChange: (values: Array<string | number>) => void;
  disabledValues?: Array<string | number>;
};

export const MultiSelectInputComponent = (props: MultiselectInputProps) => {
  const { input, selectedValues, onChange, disabledValues } = props;

  const findAnswerFromOption = (option: SelectOption) => {
    return selectedValues?.find((value) => value === option.value);
  };

  const handleSelect = (option: SelectOption) => {
    const foundAnswer = findAnswerFromOption(option);

    if (foundAnswer) {
      onChange(selectedValues!.filter((value) => value !== option.value));

      return;
    }
    if (selectedValues === null) {
      onChange([option.value]);

      return;
    }
    if (option.exclusive === true) {
      onChange([option.value]);

      return;
    }

    const allExclusiveValues = input.parameters?.choices
      ?.filter((option) => option.exclusive)
      .map((option) => option.value);

    const selectedValuesWithoutExclusive = selectedValues.filter(
      (value) => !allExclusiveValues?.includes(value),
    );

    if (
      !input.parameters?.maxChoices ||
      selectedValuesWithoutExclusive.length < input.parameters?.maxChoices
    ) {
      onChange([...selectedValuesWithoutExclusive, option.value]);
    }
  };

  const isSelected = (option: SelectOption) => {
    const foundAnswer = findAnswerFromOption(option);

    return !!foundAnswer;
  };

  const columnsCount: number = getSelectInputColumnsCount(input);

  return (
    <div className="multi-select-input flex flex-col gap-y-6">
      <Stack
        fullWidth
        className={cn("grid grid-cols-1", {
          "sm:grid-cols-2 sm:gap-x-6": columnsCount === 2,
        })}
      >
        {input.parameters?.choices?.map((option, index) => (
          <DynamicFormOption
            key={index}
            option={option}
            selected={isSelected(option)}
            onSelect={handleSelect}
            disabled={disabledValues?.includes(option.value)}
          />
        ))}
      </Stack>
    </div>
  );
};
