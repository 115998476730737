import React from "react";

import { MetricsTableCard } from "@tudigo-monorepo/web-tudigo-components";

import { useMyInvestmentAssemblyPageContext } from "../../investment-assembly-page/my-investment-assembly-page";
import { ProxySignatureSlidingPanel } from "./proxy-signature-sliding-panel";

type ProxyRepresentativeDetailCardProps = {
  setProxyTypeFormIsOpen: (value: boolean) => void;
};

export function ProxyRepresentativeDetailCard(
  props: ProxyRepresentativeDetailCardProps,
) {
  const { assemblyParticipant, assembly } =
    useMyInvestmentAssemblyPageContext();

  const { setProxyTypeFormIsOpen } = props;

  return (
    <>
      <MetricsTableCard
        title="Procuration : votre représentant"
        rows={[
          {
            label: "Forme Juridique",
            value:
              assemblyParticipant.proxyType === "tudigo"
                ? "SAS"
                : (assembly.organization?.type === "company"
                    ? assembly.organization?.companyInformation?.legalStatus
                    : "Organisation individuelle") ?? "",
          },
          {
            label: "Raison Sociale",
            value:
              assemblyParticipant.proxyType === "tudigo"
                ? "Bulb in Town"
                : assembly.organization?.name ?? "",
          },
          {
            label: "Civilité",
            value:
              assemblyParticipant.proxyType === "tudigo"
                ? "Monsieur"
                : assembly.organization?.organizationRepresentative?.gender ??
                  "",
          },
          {
            label: "Nom",
            value:
              assemblyParticipant.proxyType === "tudigo"
                ? "Vromman"
                : assembly.organization?.organizationRepresentative?.lastName ??
                  "",
          },
          {
            label: "Prénom",
            value:
              assemblyParticipant.proxyType === "tudigo"
                ? "Stéphane"
                : assembly.organization?.organizationRepresentative
                    ?.firstName ?? "",
          },
          {
            label: "Poste",
            value:
              assemblyParticipant.proxyType === "tudigo"
                ? "Directeur général"
                : assembly.organization?.organizationRepresentative?.position ??
                  "",
          },
          {
            label: "Adresse",
            value:
              assemblyParticipant.proxyType === "tudigo"
                ? "1 rue d'Enghien, 33000 Bordeaux, France"
                : `${assembly.organization?.organizationRepresentative?.address?.street1}, ${assembly.organization?.organizationRepresentative?.address?.postalCode} ${assembly.organization?.organizationRepresentative?.address?.city}, ${assembly.organization?.organizationRepresentative?.address?.country}` ??
                  "",
          },
        ]}
        footer={
          assemblyParticipant.proxyStatus !== "signed"
            ? {
                btn: {
                  label: "Modifier",
                  variant: "tertiary",
                  onClick: () => setProxyTypeFormIsOpen(true),
                },
              }
            : {}
        }
      />
      {assemblyParticipant.proxyStatus === "waiting_signature" &&
        assemblyParticipant.proxySignatureRequest &&
        assemblyParticipant.proxySignatureRequest.mySignatureLink && (
          <div className="p-6">
            <ProxySignatureSlidingPanel />
          </div>
        )}
    </>
  );
}
