import { useQueryClient } from "@tanstack/react-query";
import { Route, Routes } from "react-router-dom";

import { AppLayout } from "@tudigo-monorepo/web-tudigo-layout";
import { SearchProvider } from "@tudigo-monorepo/web-tudigo-search";

import { ProjectPage } from "../../pages/project-page/project-page";
import { ProjectsPage } from "../../pages/projects-page/projects-page";
import projectPageLoader from "./projectPageLoader";

export function ProjectRoutesWebApp() {
  const queryClient = useQueryClient();

  return (
    <Routes>
      <Route path="/" element={<AppLayout />}>
        <Route
          index
          element={
            <SearchProvider>
              <ProjectsPage />
            </SearchProvider>
          }
        />
        <Route
          index
          path=":slug/*"
          element={<ProjectPage />}
          loader={({ params }) => {
            const { id } = params as { id: string };

            return projectPageLoader({ id, queryClient });
          }}
        />
      </Route>
    </Routes>
  );
}
