import React from "react";

import { Project } from "@tudigo-monorepo/core-tudigo-api-models";
import { MetricsCard } from "@tudigo-monorepo/web-tudigo-components";

export function ProjectHolderProjectInvestorsCard(props: { project: Project }) {
  const { project } = props;

  return (
    <MetricsCard
      className="snap-center"
      value={project.totalInvestorsCount}
      heading={{
        firstChip: {
          label: project.content?.name ?? "",
          type: "info",
        },
      }}
      title="Investisseurs"
    />
  );
}
