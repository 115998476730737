import { HiddenInput } from "@tudigo-monorepo/core-tudigo-api-models";

import { FormInputPlugin } from "./form-input-plugin";

export const FormHiddenInputPlugin: FormInputPlugin<"hidden", HiddenInput> = {
  type: "hidden",
  getDefaultValue: () => {
    return true;
  },
  renderingBlock: () => {
    return null;
  },
  canAutoSubmit: false,
  defaultValueIfNull: true,
};
