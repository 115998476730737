import React, { useEffect, useState } from "react";
import { Outlet, useOutletContext, useParams } from "react-router-dom";

import {
  Assembly,
  AssemblyParticipant,
  Project,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { ASSEMBLIES } from "@tudigo-monorepo/core-tudigo-utils";
import {
  useGetAssemblyByIdQuery,
  useGetAssemblyParticipantByIdQuery,
  useGetProjectById,
} from "@tudigo-monorepo/web-tudigo-api-client";

export type MyInvestmentAssemblyPageContext = {
  project: Project;
  assembly: Assembly;
  assemblyParticipant: AssemblyParticipant;
  participationFormData: AssemblyParticipant | undefined;
  setParticipationFormData: (assemblyParticipant: AssemblyParticipant) => void;
  isAssemblyOpenForVoting: (assembly: Assembly) => boolean;
  isAssemblyOpenForParticipationUpdate: (
    assembly: Assembly,
    assemblyParticipant: AssemblyParticipant,
  ) => boolean;
  isAssemblyExpired: (assembly: Assembly) => boolean;
};

const assemblyRequestFields =
  "id,name,status,organization.fields(id,name,organization_representative,company_information,individual_information),documents,configuration,topics.fields(id,title,content,assembly.fields(id),choices,majority,answers,final_status_after_voting,created_at,updated_at)";

const assemblyParticipantRequestFields =
  "id,user,participation_channel,presence_confirmation,assembly.fields(id),answers.fields(id,choices,assembly_topic.fields(title)),status,convocation_document,proxy_type,proxy_document,signed_proxy_document,signed_vote_document,proxy_status,proxy_signature_request.fields(id,status,my_signature_link),vote_signature_request.fields(id,status,my_signature_link),convocation_send_date,created_at,updated_at";

export function MyInvestmentAssemblyPage() {
  const projectId = useParams().projectId as string;
  const assemblyId = useParams().assemblyId as string;
  const assemblyParticipantId = useParams().assemblyParticipantId as string;

  const { data: assembly } = useGetAssemblyByIdQuery(assemblyId, {
    fields: assemblyRequestFields,
  });

  const { data: project } = useGetProjectById({
    projectId,
  });

  const { data: assemblyParticipant } = useGetAssemblyParticipantByIdQuery(
    assemblyId,
    assemblyParticipantId,
    {
      fields: assemblyParticipantRequestFields,
    },
  );

  const [participationFormData, setParticipationFormData] = useState(
    assemblyParticipant ? { ...assemblyParticipant } : undefined,
  );

  const isAssemblyExpired = (assembly: Assembly) => {
    if (assembly.status === "closed") {
      return true;
    }
    const now = new Date();
    const scheduledEndDate = new Date(
      assembly.configuration.scheduledEndDate ?? "",
    );

    return now > scheduledEndDate;
  };

  const isAssemblyOpenForVoting = (assembly: Assembly) => {
    if (assembly.status === "closed") {
      return false;
    }
    const now = new Date();

    const votingStartDate = new Date(
      assembly.configuration.scheduledStartDate ?? "",
    );

    const votingEndDate = new Date(
      assembly.configuration.scheduledEndDate ?? "",
    );

    return now >= votingStartDate && now <= votingEndDate;
  };

  const isAssemblyOpenForParticipationUpdate = (
    assembly: Assembly,
    assemblyParticipant: AssemblyParticipant,
  ) => {
    const now = new Date();
    const scheduledEndDate = new Date(
      assembly.configuration.scheduledEndDate ?? "",
    );
    if (assemblyParticipant?.status === "replied") {
      return false;
    }
    if (assembly?.status === ASSEMBLIES.STATUS.STATUS_CLOSED) {
      return false;
    }

    return now <= scheduledEndDate;
  };

  useEffect(() => {
    if (assemblyParticipant !== undefined) {
      setParticipationFormData({ ...assemblyParticipant });
    }
  }, [assemblyParticipant]);

  if (!project || !assembly || !assemblyParticipant) {
    return null;
  }

  return (
    <div id="my-investments-assembly-page" className="w-full">
      <Outlet
        context={
          {
            project,
            assembly,
            assemblyParticipant,
            participationFormData,
            setParticipationFormData,
            isAssemblyOpenForVoting,
            isAssemblyOpenForParticipationUpdate,
            isAssemblyExpired,
          } satisfies MyInvestmentAssemblyPageContext
        }
      />
    </div>
  );
}

export function useMyInvestmentAssemblyPageContext() {
  return useOutletContext<MyInvestmentAssemblyPageContext>();
}
