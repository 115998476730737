import { RangeSliderInput } from "@tudigo-monorepo/core-tudigo-api-models";

import { RangeSliderInputComponent } from "../components/inputs/range-slider-input";
import { FormInputPlugin } from "./form-input-plugin";

const getDefaultValue = (input: RangeSliderInput) => {
  return input.parameters?.min || 0;
};

export const FormRangeSliderInputPlugin: FormInputPlugin<
  "range_slider",
  RangeSliderInput
> = {
  type: "range_slider",
  getDefaultValue: (input) => {
    return getDefaultValue(input);
  },
  renderingBlock: (input, context) => {
    const { sectionKey, answers, setAnswer } = context;
    if (!sectionKey) return;

    const selectedValue = answers[sectionKey][input.name as string] as number;

    return (
      <RangeSliderInputComponent
        input={input}
        selectedValue={selectedValue}
        onChange={(value) => {
          setAnswer(sectionKey, input, value);
        }}
      />
    );
  },
  canAutoSubmit: true,
  defaultValueIfNull: true,
};
