import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { Organization } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { CollectionRequestParams, getApiClient } from "../../../index";
import { getQueryListKey } from "../../query-key-factory";

export type OrganizationsFilters = {
  term?: string;
  type?: Organization["type"];
};

type GetOrganizationsParams = CollectionRequestParams<
  undefined,
  OrganizationsFilters
>;

export const getOrganizations = async (params: GetOrganizationsParams) => {
  const apiClient = getApiClient();
  const { pagination, fields, filters } = params;

  const response = await apiClient.apiV1.organizations.getOrganizations({
    ...filters,
    fields: fields,
    offset: pagination?.offset,
    limit: pagination?.limit,
  });

  return response.data;
};

export const useGetOrganizations = (
  params: GetOrganizationsParams,
  options?: UseQueryOptions<TudigoResponse<Organization[]>, TudigoError>,
) => {
  return useQuery({
    queryKey: getQueryListKey({
      key: QUERY_KEYS.ORGANIZATIONS,
      filters: params.filters,
      fields: params.fields,
    }),
    queryFn: () => getOrganizations(params),
    ...options,
  });
};
