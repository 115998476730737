import React from "react";

import { Project } from "@tudigo-monorepo/core-tudigo-api-models";
import { ProjectsAutocomplete } from "@tudigo-monorepo/web-feature-investments";

type WmaCreateInvestmentProjectSelectorProps = {
  projects: Project[];
  selectedProjectId: string | null;
  handleProjectSelect: (projectId: string) => void;
};

export function WmaCreateInvestmentProjectSelector(
  props: WmaCreateInvestmentProjectSelectorProps,
) {
  const { projects, selectedProjectId, handleProjectSelect } = props;

  return (
    <ProjectsAutocomplete
      projects={projects}
      selectedProjectId={selectedProjectId}
      handleProjectSelect={handleProjectSelect}
    />
  );
}
