import { useCallback, useEffect, useState, type KeyboardEvent } from "react";
import { debounce } from "lodash";
import { toast } from "react-toastify";

import { CompanyInformation } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Alert } from "../alert";
import { Icon } from "../icons/icon";
import { InputGroupProps } from "../input-group";
import { TextField } from "../text-field";
import {
  formatPappersCompanyResult,
  getPappersCompaniesSuggestions,
  getPappersCompanyDetails,
} from "./legal-company-selector.utils";

interface DisplayedCompany {
  siren: string;
  name: string;
  postalCode: string;
}

export interface LegalCompanySelectorProps extends InputGroupProps {
  onCompanySelected: (value: CompanyInformation) => void;
}

export function LegalCompanySelector(props: LegalCompanySelectorProps) {
  const { onCompanySelected } = props;

  const [searchValue, setSearchValue] = useState<string>("");
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const [results, setResults] = useState<DisplayedCompany[]>([]);
  const [selectionIndex, setSelectionIndex] = useState<number>();

  const searchCompanyList = useCallback(
    debounce(async () => {
      if (searchValue.length === 0) return;

      try {
        const suggestions = await getPappersCompaniesSuggestions(searchValue);

        if (suggestions.length > 0) {
          const items = suggestions.map((item) => ({
            siren: item.siren,
            name: item.nom_entreprise,
            postalCode: item.siege.code_postal,
          }));
          setResults(items);
        }
      } catch (error) {
        toast(
          <Alert
            variant="error"
            title="Une erreur est survenue"
            description="La récupération des informations n'a pas pû être effectuée"
          />,
        );

        setSearchValue("");
      }
    }, 300),
    [searchValue],
  );

  useEffect(() => {
    setSelectionIndex(undefined);
    if (searchValue.length === 0) return;
    searchCompanyList();
  }, [searchCompanyList, searchValue]);

  const handleSelectItem = async (siren: string): Promise<void> => {
    try {
      const companyDetails = await getPappersCompanyDetails(siren);
      const formattedData = formatPappersCompanyResult(companyDetails);
      onCompanySelected(formattedData);
    } catch (error) {
      toast(
        <Alert
          variant="error"
          title="Une erreur est survenue"
          description="La récupération des informations n'a pas pû être effectuée"
        />,
      );
    } finally {
      setSearchValue("");
    }
  };

  const select = (index?: number, autoScroll?: boolean) => {
    setSelectionIndex(index);
    if (autoScroll && index !== undefined) {
      if (popperElement) {
        const clientHeight = popperElement.clientHeight;
        const currentScrollOffset = popperElement.scrollTop;
        let endOffset = 0;
        let startOffset = 0;
        for (let i = 0; i <= index; i++) {
          const itemElement = popperElement.children.item(i);
          if (itemElement) {
            const itemHeight = itemElement.getBoundingClientRect().height;
            endOffset += itemHeight;
            startOffset = endOffset - itemHeight;
          }
        }

        if (startOffset < currentScrollOffset) {
          popperElement.scrollTop = startOffset;
        } else if (endOffset > clientHeight + currentScrollOffset) {
          popperElement.scrollTop = endOffset - clientHeight;
        }
      }
    }
  };

  const searchOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const key = e.key || e.keyCode;
    if (key === "ArrowDown" || key === 40) {
      if (results.length > 0) {
        if (selectionIndex === undefined) {
          select(0, true);
        } else if (results.length > selectionIndex + 1) {
          select(selectionIndex + 1, true);
        } else {
          select(results.length - 1, true);
        }
      }
    }

    if (key === "ArrowUp" || key === 38) {
      if (results.length > 0) {
        if (selectionIndex === undefined) {
          select(0, true);
        } else if (selectionIndex > 0) {
          select(selectionIndex - 1, true);
        }
      }
    }
    if (key === "Enter" || key === 13) {
      if (selectionIndex !== undefined && results.length > selectionIndex) {
        handleSelectItem(results[selectionIndex].siren);
        e.stopPropagation();
        e.preventDefault();
      }
    }
  };

  return (
    <>
      <TextField
        {...props}
        autoComplete="off"
        value={searchValue}
        iconRight={
          <Icon
            name="Search"
            primaryColor={themeColors["dark-2"]}
            size="S"
            height={18}
            width={18}
          />
        }
        onKeyDown={searchOnKeyDown}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <div
        ref={setPopperElement}
        className={cn(
          "border-accent-medium z-10 hidden flex-col gap-1 overflow-y-scroll rounded-lg border bg-white p-3",
          {
            flex: results.length > 0 && searchValue.length > 0,
          },
        )}
      >
        {results.map((result, index) => (
          <div
            onMouseOver={() => {
              select(index);
            }}
            onMouseOut={() => {
              select(undefined);
            }}
            onClick={() => {
              handleSelectItem(result.siren);
            }}
            key={index}
            className={cn(
              "hover:border-accent-medium flex min-h-[38px] w-full cursor-pointer items-center rounded-lg border border-transparent bg-white px-4 transition-all",
              {
                "bg-accent-super-light border-accent-medium":
                  index === selectionIndex,
              },
            )}
          >
            {result.name} ({result.postalCode})
          </div>
        ))}
      </div>
    </>
  );
}
