const queryKeyFactory = (key: string) => ({
  all: [key] as const,
  list: (fields?: string, filters?: any) =>
    [...queryKeyFactory(key).all, "list", { ...filters, fields }] as const,
  detail: (id: number | string, fields?: string) => [id, { fields }] as const,
});

export const getQueryKey = (key: string) => queryKeyFactory(key).all;

export const getQueryListKey = ({
  key,
  fields,
  filters,
}: {
  key: string;
  fields?: string;
  filters?: any;
}) => queryKeyFactory(key).list(fields, filters);

export const getQueryDetailKey = ({
  key,
  id,
  fields,
}: {
  key: string;
  id: number | string;
  fields?: string;
}) => queryKeyFactory(key).detail(id, fields);

export default queryKeyFactory;
