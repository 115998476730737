import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import {
  TudigoError,
  TudigoResponse,
  UpdateProjectNewsRequestBody,
} from "@tudigo-monorepo/core-tudigo-api";
import { News } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient, getQueryDetailKey } from "../../../index";

export type UpdateNewsParams = {
  newsId: string;
  data: UpdateProjectNewsRequestBody;
  fields?: string;
};

export const updateProjectNews = async (params: UpdateNewsParams) => {
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.projects.updateProjectNews(
    params.newsId,
    params.data,
  );

  return response.data;
};

export const useUpdateProjectNewsMutation = (
  options?: UseMutationOptions<
    TudigoResponse<News>,
    TudigoError,
    UpdateNewsParams
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (params: UpdateNewsParams) => updateProjectNews(params),
    onSuccess: (...props) => {
      const [, params] = props;

      const projectNewsQueryKey = getQueryDetailKey({
        key: QUERY_KEYS.PROJECT_NEWS,
        id: params.newsId,
        fields: params.fields,
      });

      queryClient.invalidateQueries({
        queryKey: projectNewsQueryKey,
      });

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_NEWS],
      });
      options?.onSuccess?.(...props);
    },
  });
};
