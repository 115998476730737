import { toast } from "react-toastify";

import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  useCreateProjectHolderProfile,
  useMarkIntroHasDone,
} from "@tudigo-monorepo/web-tudigo-api-client";
import { Alert, Button } from "@tudigo-monorepo/web-tudigo-components";

type ProjectHolderIntroActionsProps = {
  setShowExitDialog: (show: boolean) => void;
  onCreateProfile?: () => void;
};

export const ProjectHolderIntroActions = (
  props: ProjectHolderIntroActionsProps,
) => {
  const { setShowExitDialog, onCreateProfile } = props;
  const { user } = useUserBusiness();
  const userId = user?.id ?? "me";

  const handleError = () => {
    toast((content) => (
      <Alert
        close={content.closeToast}
        icon="Error"
        variant="error"
        title="Erreur lors de la création du compte"
        description="Le compte Porteur de projet n'a pas pu être créé"
      />
    ));
  };

  const { mutate: markIntroDone, isPending: isMarkIntroDoneLoading } =
    useMarkIntroHasDone({
      onSuccess: onCreateProfile,
      onError: handleError,
    });

  const {
    mutate: createProjectHolderProfile,
    isPending: isCreateProjectHolderProfileLoading,
  } = useCreateProjectHolderProfile({
    onSuccess: () => markIntroDone({ userId }),
    onError: handleError,
  });

  const handleCreateProjectHolderProfile = () => {
    createProjectHolderProfile({
      data: {
        id: userId,
        status: "enabled",
      },
    });
  };

  const isLoading =
    isCreateProjectHolderProfileLoading || isMarkIntroDoneLoading;

  return (
    <>
      <Button
        onClick={handleCreateProjectHolderProfile}
        className="w-fit px-12"
        variant="primary"
        iconRight="ArrowRight"
        label="Créer mon compte Porteur de projet"
        disabled={isLoading}
        isLoading={isLoading}
      />
      <Button
        onClick={() => setShowExitDialog(true)}
        className="w-fit px-12 sm:hidden"
        variant="ghost"
        label="Quitter"
        disabled={isLoading}
      />
    </>
  );
};
