import {
  LogoutLink,
  MenuNavigation,
  NavigationItem,
} from "@tudigo-monorepo/web-tudigo-components";

export type ConnectedMenuNavigationProps = {
  navigation: NavigationItem[];
  reducedDisplay?: boolean;
};

export function ConnectedMenuNavigation({
  navigation,
  reducedDisplay = false,
}: ConnectedMenuNavigationProps) {
  return (
    <div className="flex w-full flex-col justify-between gap-y-8 overflow-y-auto px-4 pb-6">
      <MenuNavigation items={navigation} reducedDisplay={reducedDisplay} />

      <LogoutLink reducedDisplay={reducedDisplay} />
    </div>
  );
}
