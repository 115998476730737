import React from "react";
import { useLocation } from "react-router-dom";

import { Todo } from "@tudigo-monorepo/core-tudigo-api-models";
import {
  PageEnum,
  useSpaceBusiness,
  useUserBusiness,
} from "@tudigo-monorepo/tudigo-session";
import { OrganizationKycListElement } from "@tudigo-monorepo/web-feature-organizations";
import { useGetMyTodosQuery } from "@tudigo-monorepo/web-tudigo-api-client";
import { Title } from "@tudigo-monorepo/web-tudigo-components";

import { UserPayInvestmentTodoListElement } from "../list-elements/user-pay-investment-todo-list-element";
import { UserQualificationListElement } from "../list-elements/user-qualification-list-element";
import { UserSignInvestmentTodoListElement } from "../list-elements/user-sign-investment-todo-list-element";

export function UserTodoList() {
  const { user } = useUserBusiness();
  const userId = user?.id as string;

  const { currentPagePaths } = useSpaceBusiness();
  const location = useLocation();

  const { data: todos, isLoading } = useGetMyTodosQuery({
    pathParams: { userId },
    filters: { status: "todo" },
    pagination: { limit: 1000 },
  });

  const renderTodo = (todo: Todo) => {
    switch (todo.type) {
      case "investment_to_sign":
        return (
          <UserSignInvestmentTodoListElement
            investment={todo.subjectInvestment}
          />
        );

      case "investment_to_pay":
        return (
          <UserPayInvestmentTodoListElement
            investment={todo.subjectInvestment}
          />
        );

      case "user_qualification":
        return <UserQualificationListElement />;

      case "organization_kyc": {
        if (!todo.subjectOrganization) {
          return null;
        }

        return (
          <OrganizationKycListElement
            organization={todo.subjectOrganization}
            onClick={() => {
              localStorage.setItem("target-url", location.pathname);
            }}
            navigateTo={currentPagePaths[
              PageEnum.ORGANIZATION_KYC_FUNNEL
            ].replace(":organizationId", todo.subjectOrganization.id)}
          />
        );
      }

      default:
        return null;
    }
  };

  if (isLoading || !todos || todos.data.length === 0) {
    return null;
  }

  return (
    <div className="m-6 pt-6">
      <Title h3Brand="brand-2" className="text-primary mb-6" level="h3">
        Actions en attente
      </Title>
      {todos.data.map((todo, index) => (
        <div key={index} className="flex flex-col">
          <div className="mb-6 flex-row" key={todo.id}>
            {renderTodo(todo)}
          </div>
        </div>
      ))}
    </div>
  );
}
