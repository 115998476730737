import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Alert, AlertProps } from "@tudigo-monorepo/web-tudigo-components";

export const NotReadyRepaymentSchedule = (props: AlertProps) => {
  const { t } = useTranslation();

  return (
    <Alert
      className={cn("w-full 2xl:w-2/3", props.className)}
      contentClassName={cn("text-pretty", props.contentClassName)}
      title={
        props.title || t("my_repayments_page.under_maintenance.alert.title")
      }
      description={
        props.description ||
        t("my_repayments_page.under_maintenance.alert.desc")
      }
      size="F"
      variant="info"
      icon="Info"
    />
  );
};
