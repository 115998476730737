import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { Shareholder } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import {
  CollectionRequestParams,
  getApiClient,
  getQueryListKey,
} from "../../../";

export type ShareholdersFilters = {
  id?: string;
  organizationId?: string;
  ownerOrganizationId?: string;
};

type GetShareholdersParams = CollectionRequestParams<
  undefined,
  ShareholdersFilters
>;
export const getShareholders = async (params: GetShareholdersParams) => {
  const apiClient = getApiClient();
  const { pagination, fields, filters } = params;
  const response =
    await apiClient.apiV1.organizationShareholders.getShareholders({
      ...filters,
      fields: fields,
      offset: pagination?.offset,
      limit: pagination?.limit,
    });

  return response.data;
};

export const useGetShareholders = (
  params: GetShareholdersParams,
  options?: UseQueryOptions<TudigoResponse<Shareholder[]>, TudigoError>,
) =>
  useQuery({
    queryKey: getQueryListKey({
      key: QUERY_KEYS.ORGANIZATION_SHAREHOLDERS,
      fields: params?.fields,
      filters: params?.filters,
    }),
    queryFn: () => getShareholders(params),
    ...options,
  });
