import { TFunction } from "@tudigo-monorepo/core-tudigo-translations";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";

export function formatRangeOrSingleValue(
  fromValue: number,
  toValue: number,
  translationKeyRange: string,
  translationKeySingle: string,
  t: TFunction,
  isCurrency = false,
) {
  if (fromValue === toValue) {
    return t(translationKeySingle, {
      value: isCurrency ? formatCurrency(fromValue) : fromValue,
    });
  } else {
    return t(translationKeyRange, {
      fromValue: isCurrency ? formatCurrency(fromValue) : fromValue,
      toValue: isCurrency ? formatCurrency(toValue) : toValue,
    });
  }
}
