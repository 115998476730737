import {
  CMSPodcastCarouselBlock,
  CMSPodcastIframeElement,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { Carousel } from "@tudigo-monorepo/web-tudigo-components";

type PodcastCarouselBlockProps = {
  block: CMSPodcastCarouselBlock;
};

export const PodcastCarouselBlock = ({ block }: PodcastCarouselBlockProps) => {
  return (
    <Carousel
      className="mb-6"
      slidesToShow={1}
      slidesToScroll={1}
      arrowsPlacement="above"
      autoplay={false}
      infinite
    >
      {block.data.podcasts.map((podcast, index) => (
        <PodcastElement
          key={index}
          url={podcast.url}
          width={podcast.width}
          height={podcast.height}
        />
      ))}
    </Carousel>
  );
};

const PodcastElement = (props: CMSPodcastIframeElement) => {
  const { url, width, height } = props;
  const getValidHeight = () => {
    if (!height || height < 50 || height > 1400) return 152;

    return height;
  };

  return (
    <div
      className="bg-light-3 h-full w-full rounded-lg"
      style={{
        height: `${getValidHeight()}px`,
        width: width ? `${width}px` : "100%",
      }}
    >
      {url && (
        <iframe
          src={url}
          title="project-iframe-block"
          className="h-full w-full"
          allowFullScreen
        />
      )}
    </div>
  );
};
