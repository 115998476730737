import React from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { Button, Title } from "@tudigo-monorepo/web-tudigo-components";

import { ResetFilters } from "./shared/component/reset-filters";

type ProjectsPageMenuMobileProps = React.PropsWithChildren<{
  visible: boolean;
}>;

const ProjectsPageMenuMobile = ({
  children,
  visible,
}: ProjectsPageMenuMobileProps) => {
  return (
    <div
      className={cn(
        "fixed left-0 top-0 z-[100] hidden h-full min-h-screen w-screen flex-col overflow-y-auto bg-white sm:hidden",
        { flex: visible },
      )}
    >
      {children}
    </div>
  );
};

const ProjectsPageMenuMobileHead = ({ onClose }: { onClose: () => void }) => {
  return (
    <div className="border-medium-2 flex items-center justify-between border-b px-4 py-1.5">
      <Title level="h3" h3Brand="brand-2">
        Filtrer et trier
      </Title>
      <Button singleIcon="Cross" variant="ghost" onClick={onClose} />
    </div>
  );
};

const ProjectsPageMenuMobileActions = ({
  children,
}: React.PropsWithChildren) => {
  return (
    <div className="flex h-full flex-col gap-y-6 p-4 pb-40">{children}</div>
  );
};

const ProjectsPageMenuMobileFooter = ({
  hasFilters,
  projectsCount,
  onClose,
}: {
  hasFilters: boolean;
  projectsCount: number;
  onClose: () => void;
}) => {
  return (
    <div className="border-medium-2 fixed bottom-0 left-0 flex w-full items-center justify-center gap-x-4 border-t bg-white p-4">
      <ResetFilters visible={hasFilters} className="w-1/2" />

      <Button
        label={`${projectsCount} levées`}
        iconRight="ArrowRight"
        className={cn("flex w-full items-center justify-center", {
          "w-1/2": hasFilters,
        })}
        onClick={onClose}
      />
    </div>
  );
};

ProjectsPageMenuMobile.Head = ProjectsPageMenuMobileHead;
ProjectsPageMenuMobile.Actions = ProjectsPageMenuMobileActions;
ProjectsPageMenuMobile.Footer = ProjectsPageMenuMobileFooter;

export { ProjectsPageMenuMobile };
