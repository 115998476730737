import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

import { OrganizationCollapse } from "../../../organization-collapse/organization-collapse";
import { OrganizationCollapseHeader } from "../../../organization-collapse/organization-collapse-header";
import { useInvestmentAccountContext } from "../../investment-account-context";

export function BankInformationsSection() {
  const { t } = useTranslation();

  const { equitySavingPlan } = useInvestmentAccountContext();

  const collapseItems = [
    {
      label: t(
        "organizations.back_office.organization_page.collapse.bank_informations.bank",
      ),
      value: equitySavingPlan.bank?.name,
    },
    {
      label: t(
        "organizations.back_office.organization_page.collapse.bank_informations.account_number",
      ),
      value: equitySavingPlan.accountNumber,
    },
  ];

  return (
    <OrganizationCollapse items={collapseItems}>
      <OrganizationCollapseHeader
        label={t(
          "organizations.back_office.organization_page.collapse.bank_informations.title",
        )}
      />
    </OrganizationCollapse>
  );
}
