import { DropzoneOptions, FileRejection } from "react-dropzone";
import { useFormContext } from "react-hook-form";

import { Document } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../form";
import { InputConnectedProps } from "../input.type";
import { FileUpload } from "./file-upload";

interface FileUploadConnectedProps extends InputConnectedProps {
  options?: DropzoneOptions;
}

export function FileUploadConnected({
  name,
  label,
  className,
  description,
  required,
  options,
}: FileUploadConnectedProps) {
  const { t } = useTranslation();
  const form = useFormContext();

  if (!form) {
    throw new Error(
      "File upload connected must be used within a Form component",
    );
  }

  const handleOnChange = (documents: Document[]) => {
    form.clearErrors(name);
    form.setValue(name, documents);
  };

  const handleOnError = (files: FileRejection[]) => {
    // Handle error from react-dropzone and set it to the form
    form.setError(name, {
      type: "manual",
      message: t(`form.error.${files[0]?.errors[0].code}`),
    });
  };

  return (
    <FormField
      control={form?.control}
      name={name}
      render={({ field, fieldState }) => (
        <FormItem className={className}>
          {label && <FormLabel required={required}>{label}</FormLabel>}
          <FormControl>
            <FileUpload
              onChange={handleOnChange}
              onDropRejected={handleOnError}
              defaultPdfs={field?.value}
              {...options}
            />
          </FormControl>

          {fieldState?.error ? (
            <FormMessage />
          ) : (
            description && <FormDescription>{description}</FormDescription>
          )}
        </FormItem>
      )}
    />
  );
}
