import React from "react";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Icon } from "../icons/icon";
import { OutClickBox } from "../out-click-box/out-click-box";
import { useToggler } from "../shared/hooks/use-toggler";

type CollapseProps = {
  children: React.ReactNode;
  items: React.ReactNode[];
  defaultExpanded?: boolean;
  headerClassName?: string;
  panelClassName?: string;
  onChange?: (expanded: boolean) => void;
  clickOutsideEnabled?: boolean;
  disableCollapse?: boolean;
};

export function Collapse(props: CollapseProps) {
  const {
    children,
    items,
    headerClassName,
    panelClassName,
    clickOutsideEnabled = true,
    disableCollapse = false,
    defaultExpanded = false,
    onChange,
  } = props;

  const [expanded, toggle, setExpanded] = useToggler(defaultExpanded);

  const handleCollapseChange = () => {
    if (!disableCollapse) {
      toggle();
      onChange && onChange(!expanded);
    }
  };

  const handleOutClick = () => {
    if (!disableCollapse && clickOutsideEnabled) {
      setExpanded(false);
    }
  };

  return (
    <OutClickBox onOutClick={handleOutClick} className="w-full">
      <div onClick={handleCollapseChange} className="flex w-full flex-col">
        <div
          className={cn(
            "border-light-1 flex w-full items-center justify-between rounded-b-lg rounded-t-lg border-x border-y px-4 py-2.5",
            {
              "rounded-b-none": expanded && items.length > 0,
              "cursor-pointer": !disableCollapse,
            },
            headerClassName,
          )}
        >
          {children}
          {!disableCollapse && (
            <Icon
              name="ChevronDown"
              className={cn("transition-transform", { "rotate-180": expanded })}
              primaryColor={themeColors["dark-2"]}
            />
          )}
        </div>
        {expanded && items.length > 0 && (
          <div
            onClick={(event) => event.stopPropagation()}
            className={cn(
              "border-light-1 flex w-full flex-col gap-y-4 rounded-b-lg border-x border-b px-[34px] py-4",
              panelClassName,
            )}
          >
            {items.map((children, index) => (
              <React.Fragment key={index}>{children}</React.Fragment>
            ))}
          </div>
        )}
      </div>
    </OutClickBox>
  );
}
