import {
  OrganizationPerson,
  OrganizationPersonDocument,
} from "@tudigo-monorepo/core-tudigo-api-models";

export function findDocumentOrDefaultOrganizationPersonDocument(
  organizationPerson: OrganizationPerson,
  type: string,
) {
  return (
    organizationPerson.documents.find((doc) => doc.type === type) ??
    ({
      type: type,
      document: {},
      organizationPerson: { id: organizationPerson.id },
    } as OrganizationPersonDocument)
  );
}

export type OrganizationPersonKycDocumentsFormData = {
  identityCardFrontSideFile: OrganizationPersonDocument;
  identityCardBackSideFile: OrganizationPersonDocument;
  passportFrontSideFile: OrganizationPersonDocument;
  proofOfAddress?: OrganizationPersonDocument;
};
