import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { useGetUserClubMembers } from "@tudigo-monorepo/web-tudigo-api-client";

const useGetCurrentClubMember = (clubId?: string) => {
  const { user } = useUserBusiness();
  const userId = user?.id ?? "me";

  const { data: clubMembers, isFetched } = useGetUserClubMembers(
    { pathParams: { userId } },
    { enabled: !!user && !!clubId },
  );

  const clubMember = clubMembers?.data.find(
    (clubMember) =>
      clubMember.user.id === userId && clubMember.club.id === clubId,
  );

  return { clubMember, isFetched };
};

export { useGetCurrentClubMember };
