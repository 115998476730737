import { Link, useNavigate } from "react-router-dom";

import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { useGetWmaCustomersInvestmentsByWmaUserId } from "@tudigo-monorepo/web-tudigo-api-client";
import { TudigoCollection } from "@tudigo-monorepo/web-tudigo-collection";
import {
  Button,
  PageHead,
  Table,
  TableCardList,
  Title,
} from "@tudigo-monorepo/web-tudigo-components";

import { useWmaCustomersInvestmentColumns } from "./hooks/use-wma-customers-investment-columns";

const investmentFields = `id,investor_organization.fields(id,name,logo),project.fields(id,content.fields(name),mechanic),payment_status,global_status,total_amount_in_cents,created_at,payment_method_type`;
const wmaInvestmentsFields = `id,investment.fields(${investmentFields}),wma_customer.fields(customer_user.fields(first_name,last_name))`;

export function WmaInvestmentsPage() {
  const { user } = useUserBusiness();
  const userId = user?.id;
  const columns = useWmaCustomersInvestmentColumns();
  const navigate = useNavigate();

  if (!user) return;

  return (
    <div id="my-investments-page" className="w-full">
      <PageHead title="Souscriptions" withEndLine>
        <div className=" flex w-full flex-col items-center justify-between gap-4 sm:flex-row">
          <div></div>
          <Link to="/wma/investments/create" className="w-full sm:w-fit">
            <Button
              label="Ajouter une souscription"
              variant="tertiary"
              iconRight="Plus"
              className="w-full"
            />
          </Link>
        </div>
      </PageHead>

      <div className="border-light-2 mx-6 border-b" />

      <div className="m-6">
        <Title h3Brand="brand-2" level="h3" className="text-primary mb-6">
          Les souscriptions de vos investisseurs
        </Title>

        <TudigoCollection
          useLocationAsState
          useQueryFn={useGetWmaCustomersInvestmentsByWmaUserId}
          columns={columns}
          pathParams={{
            wmaUserId: userId || "me",
          }}
          fields={wmaInvestmentsFields}
          render={(table, props) => {
            return (
              <>
                <Table
                  table={table}
                  actions={props.actions}
                  className="hidden sm:table"
                  onRowClick={(wmaInvestment) => {
                    navigate(`/wma/investments/edit/${wmaInvestment.id}`);
                  }}
                />
                <TableCardList
                  table={table}
                  className="sm:hidden"
                  onCardClick={(wmaInvestment) => {
                    navigate(`/wma/investments/edit/${wmaInvestment.id}`);
                  }}
                />
              </>
            );
          }}
        />
      </div>
    </div>
  );
}
