import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import {
  Investment,
  ProjectHolderProfile,
} from "@tudigo-monorepo/core-tudigo-api-models";
import {
  NumberSearchCriteria,
  QUERY_KEYS,
} from "@tudigo-monorepo/core-tudigo-utils";

import { CollectionRequestParams, getApiClient } from "../../../index";
import { getQueryListKey } from "../../query-key-factory";

type GetProjectHolderInvestorsParams = CollectionRequestParams<
  { projectHolderProfileId: ProjectHolderProfile["id"] },
  ProjectHolderInvestorsFilters
>;

export type ProjectHolderInvestorsFilters = {
  projectId?: string;
  investorOrganizationId?: string;
  paymentMethodType?: "wire" | "card" | "pea" | "";
  status?: string;
  globalStatus?: string | string[];
  createdAt?: NumberSearchCriteria;
};

export const getProjectHolderInvestors = async (
  params: GetProjectHolderInvestorsParams,
) => {
  const apiClient = getApiClient();
  const { pagination, fields, filters } = params;

  const response =
    await apiClient.apiV1.projectHolderProfiles.getProjectHolderInvestors(
      params.pathParams.projectHolderProfileId,
      {
        ...filters,
        fields: fields,
        offset: pagination?.offset,
        limit: pagination?.limit,
      },
    );

  return response.data;
};

export const useGetProjectHolderInvestors = (
  params: GetProjectHolderInvestorsParams,
  options?: UseQueryOptions<TudigoResponse<Investment[]>, TudigoError>,
) => {
  return useQuery({
    queryKey: getQueryListKey({
      key: QUERY_KEYS.PROJECT_INVESTORS,
      fields: params?.fields,
      filters: params?.filters,
    }),
    queryFn: () => getProjectHolderInvestors(params),
    ...options,
  });
};
