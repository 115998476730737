import { TaxExemption, User } from "@tudigo-monorepo/core-tudigo-api-models";
import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  Icon,
  ListElement,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

type TaxExemptionDocumentProps = {
  user: User | null;
  taxExemption: TaxExemption;
};

export function TaxExemptionDocument(props: TaxExemptionDocumentProps) {
  const { user, taxExemption } = props;

  const { t } = useTranslation();

  const displayDocumentName = (): string => {
    let dateOption: number | undefined = undefined;

    if (taxExemption.taxName === "IR") {
      if (taxExemption.validityStartDate) {
        dateOption = new Date(taxExemption.validityStartDate).getFullYear();
      } else {
        dateOption = new Date().getFullYear();
      }
    }

    return t("my-profile-exemptions.tax-document-signed.upper_text.exemption", {
      taxName: t(`my-profile-exemptions.tax-exemption.${taxExemption.taxName}`),
      date: dateOption,
    });
  };

  const displayOwnerName = (): string | undefined => {
    if (
      user?.individualOrganization?.organizationRepresentative?.firstName &&
      user?.individualOrganization?.organizationRepresentative?.lastName
    ) {
      return `${user.individualOrganization.organizationRepresentative.firstName} ${user.individualOrganization.organizationRepresentative.lastName}`;
    }
  };

  const displaySignatureDate = (): string => {
    if (taxExemption.updatedAt) {
      return t("my-profile-exemptions.tax-document-signed.document-signed-on", {
        date: new Date(taxExemption.updatedAt).toLocaleDateString(),
      });
    }

    return "";
  };

  return (
    <ListElement
      className="w-full"
      toggleActiveOnClick={false}
      startElement={{
        img: { icon: { name: "Document" } },
        upperText: displayDocumentName(),
        lowerText: displayOwnerName(),
        profileCardClassName: "sm:min-w-[200px]",
      }}
      middleElement={{
        upperText: displaySignatureDate(),
        chips: [
          {
            label: t(
              "my-profile-exemptions.tax-document-signed.individual-label",
            ),
            type: "secondary",
          },
        ],
      }}
      endElement={{
        render: () => (
          <div
            className="flex cursor-pointer items-center gap-x-6 p-3"
            onClick={() =>
              window.open(taxExemption.document?.signedUrl || undefined)
            }
          >
            <Typography variant="body3-regular">
              {t("my-profile-exemptions.tax-document-signed.download")}
            </Typography>
            <Icon name="Download" primaryColor={themeColors["dark-2"]} />
          </div>
        ),
      }}
    />
  );
}
