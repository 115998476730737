import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import {
  CreateUserTaxExemptionBody,
  TudigoError,
  TudigoResponse,
} from "@tudigo-monorepo/core-tudigo-api";
import { TaxExemption, User } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient, getQueryListKey } from "../../../";

interface RequestParams {
  userId: User["id"] | "me";
  data: CreateUserTaxExemptionBody;
}

type MutationOptions = UseMutationOptions<
  TudigoResponse<TaxExemption>,
  TudigoError,
  RequestParams
>;

export const createUserTaxExemption = async (params: RequestParams) => {
  const apiClient = getApiClient();

  const response =
    await apiClient.apiV1.userTaxExemptions.createUserTaxExemption(
      params.userId,
      params.data,
    );

  return response.data;
};

export const useCreateUserTaxExemptionMutation = (
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (params: RequestParams) => createUserTaxExemption(params),
    onSuccess: (...props) => {
      const queryKey = getQueryListKey({
        key: QUERY_KEYS.TAX_EXEMPTIONS,
      });

      queryClient.invalidateQueries({ queryKey });

      options?.onSuccess?.(...props);
    },
  });
};
