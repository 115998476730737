import { kebabCase } from "lodash";

import { CMSHeadingBlock } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import {
  ThinLayout,
  Title,
  TitleVariant,
} from "@tudigo-monorepo/web-tudigo-components";

type ProjectHeadingBlockProps = {
  block: CMSHeadingBlock;
  editMode?: boolean;
};

export function ProjectHeadingBlock(props: ProjectHeadingBlockProps) {
  const { block } = props;
  const { title, level } = block.data;

  const titleLevel =
    level != null && level <= 6 ? (`h${level}` as TitleVariant) : "h1";

  const anchor = kebabCase(title || "");

  return (
    <ThinLayout>
      <Title
        level={titleLevel}
        className={cn({ "border-light-1 border-b pb-4": level === 2 })}
      >
        <span id={anchor}>{title}</span>
      </Title>
    </ThinLayout>
  );
}
