import { QueryClient } from "@tanstack/react-query";

import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";
import {
  getProjectById,
  getQueryDetailKey,
} from "@tudigo-monorepo/web-tudigo-api-client";
import { routeLoader } from "@tudigo-monorepo/web-tudigo-security";

const projectPageLoader = ({
  id,
  queryClient,
}: {
  id: string;
  queryClient: QueryClient;
}) => {
  const queries = [
    {
      queryFn: () => getProjectById({ projectId: id }),
      queryKey: getQueryDetailKey({
        key: QUERY_KEYS.PROJECTS,
        id: id,
      }),
    },
  ];

  return routeLoader({
    queryClient,
    queries,
  });
};

export default projectPageLoader;
