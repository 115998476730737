import React from "react";
import { useIntl } from "react-intl";

import { ProjectDocument } from "@tudigo-monorepo/core-tudigo-api-models";
import {
  Avatar,
  Icon,
  ListElementComposable,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

export function ProjectDocumentListElement(props: {
  projectDocument: ProjectDocument;
}) {
  const { projectDocument } = props;
  const intl = useIntl();

  const investmentDocumentName = () => {
    switch (projectDocument.type) {
      case "key_information_document":
        return intl.formatMessage({
          id: "project_document.type.key_information_document",
        });
      case "share_holder_agreement":
        return intl.formatMessage({
          id: "project_document.type.share_holder_agreement",
        });
      case "bond_pact":
        return intl.formatMessage({
          id: "project_document.type.bond_pact",
        });

      default:
        return intl.formatMessage({
          id: "project_document.type.empty",
        });
    }
  };

  const documentCreationDate = projectDocument.createdAt
    ? new Date(projectDocument.createdAt)
    : new Date();

  return (
    <ListElementComposable
      onClick={() => window.open(projectDocument?.document?.signedUrl ?? "")}
      className="hover:border-accent-medium cursor-pointer justify-between"
    >
      <div className="flex items-center gap-3  md:basis-1/5">
        <Avatar icon={{ name: "Document" }} />
        <Typography variant="body3-semibold" className="text-dark-1">
          {projectDocument.document?.originalFilename ??
            documentCreationDate.toLocaleDateString()}
        </Typography>
      </div>

      <div className="m-auto md:basis-1/5">
        <Typography variant="body3-regular">
          {investmentDocumentName()}
        </Typography>
      </div>

      <div className="ml-auto flex gap-x-2">
        <Typography variant="body3-regular" className="text-dark-1">
          {projectDocument.document?.mimeTypeExtension?.toUpperCase() ??
            "Document"}
        </Typography>
        <Icon name="Download" />
      </div>
    </ListElementComposable>
  );
}
