import { useQuery, useQueryClient } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import {
  Investment,
  InvestmentFeed,
} from "@tudigo-monorepo/core-tudigo-api-models";
import {
  NumberSearchCriteria,
  QUERY_KEYS,
} from "@tudigo-monorepo/core-tudigo-utils";

import { CollectionRequestParams, getApiClient, getQueryDetailKey } from "..";

export const useInvalidateInvestmentQueryKey = (
  investmentId: Investment["id"],
  fields?: string,
) => {
  const queryClient = useQueryClient();
  const key = getQueryDetailKey({
    key: QUERY_KEYS.INVESTMENTS,
    id: investmentId,
    fields,
  });

  return async () => {
    await queryClient.refetchQueries({
      queryKey: key,
    });
  };
};

export type UserInvestmentsFilters = {
  projectId?: string;
  investorOrganizationId?: string;
  paymentMethodType?: "wire" | "card" | "pea" | "";
  status?: string;
  globalStatus?: string | string[];
  createdAt?: NumberSearchCriteria;
};

export type UserInvestmentFeedFilters =
  | {
      type?: string;
    }
  | undefined;

export const useGetUserInvestmentFeedQuery = (
  params: CollectionRequestParams<
    { userId: string; projectId: string; filter: string },
    UserInvestmentFeedFilters
  >,
) => {
  const { pagination, fields, filters, pathParams } = params;
  const apiClient = getApiClient();

  return useQuery<TudigoResponse<InvestmentFeed[]>, TudigoError>({
    refetchOnMount: false,
    queryKey: [
      `investment-feed-${pathParams.filter}`,
      pathParams.userId,
      pathParams.projectId,
      fields,
      filters,
    ],
    queryFn: async () => {
      const response = await apiClient.apiV1.investments.getUserInvestmentFeed(
        pathParams.userId,
        pathParams.projectId,
        {
          ...filters,
          filter: pathParams.filter ?? "all",
          fields: fields,
          offset: pagination?.offset,
          limit: pagination?.limit,
        },
      );

      return response.data;
    },
    retry: 0,
  });
};
