import React from "react";

import { ChoicesSliderInput } from "@tudigo-monorepo/core-tudigo-api-models";
import { InputSlider } from "@tudigo-monorepo/web-tudigo-components";

import { getSliderIndicators } from "../../utils/form-inputs";

type SliderInputComponentProps = {
  input: ChoicesSliderInput;
  selectedValue: number | string;
  onChange: (value: number | string) => void;
};

export function ChoicesSliderInputComponent(props: SliderInputComponentProps) {
  const { input, onChange, selectedValue } = props;

  const choices = input.parameters?.choices || [];

  const areAllChoiceValuesNumbers =
    choices.filter((choice) => typeof choice.value !== "number").length === 0;

  const index = choices.findIndex((choice) => choice.value === selectedValue);

  const sliderMin = areAllChoiceValuesNumbers
    ? Math.min(...choices.map((choice) => choice.value as number))
    : 0;

  const sliderMax = areAllChoiceValuesNumbers
    ? Math.max(...choices.map((choice) => choice.value as number))
    : choices.length - 1;

  const handleChange = (
    _: React.ChangeEvent<HTMLInputElement> | null,
    value: number,
  ) => {
    if (areAllChoiceValuesNumbers) {
      onChange(value);
    } else {
      onChange(choices[value].value);
    }
  };

  return (
    <InputSlider
      min={sliderMin}
      max={sliderMax}
      step={1}
      stepMarkers
      value={index}
      valueChip={false}
      indicators={getSliderIndicators(input, sliderMax)}
      onChange={handleChange}
    />
  );
}
