import { CMSHeadingBlock } from "@tudigo-monorepo/core-tudigo-api-models";
import { ThinLayout } from "@tudigo-monorepo/web-tudigo-components";

type HeadingBlockProps = {
  block: CMSHeadingBlock;
  editMode?: boolean;
};

export function HeadingBlock(props: HeadingBlockProps) {
  const { block } = props;

  switch (block.data.level) {
    case 1:
      return (
        <ThinLayout>
          <h1 className="font-brand text-dark-1 text-[27px] font-semibold">
            {block.data.title}
          </h1>
        </ThinLayout>
      );
    case 2:
      return (
        <ThinLayout>
          <h2 className="font-brand text-primary text-lg font-semibold">
            {block.data.title}
          </h2>
        </ThinLayout>
      );
    default:
      return null;
  }
}
