import dayjs from "dayjs";

import "dayjs/locale/fr";

import relativeTime from "dayjs/plugin/relativeTime";

import { getProjectMechanicSettings } from "@tudigo-monorepo/core-tudigo-api-models";
import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import {
  formatCurrency,
  formatLargeCurrency,
} from "@tudigo-monorepo/core-tudigo-utils";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  Icon,
  ProjectProgressBar,
  ProjectTotalAmountCollected,
  Tooltip,
} from "@tudigo-monorepo/web-tudigo-components";

import { InvestButton } from "../../../shared/components/invest-button";
import { useProjectContext } from "../project.context";

dayjs.extend(relativeTime);

function StatsRow({
  label,
  value,
  tooltipDesc,
}: {
  label: string;
  value: string;
  tooltipDesc?: string;
}) {
  if (label === "Cloture dans") {
    return (
      <div className="flex items-center gap-2 border-b pb-1 pt-3 last:border-0">
        <div className="text-dark-2 text-sm">{label}</div>
        <div className="font-brand ml-auto pb-1 text-lg">{value}</div>
      </div>
    );
  }

  return (
    <div className="flex items-center gap-2 border-b pb-1 pt-3 last:border-0">
      <div className="text-dark-2 text-sm">{label}</div>
      <Tooltip description={tooltipDesc}>
        <Icon name="Info" size="XS" primaryColor={themeColors["dark-1"]} />
      </Tooltip>
      <div className="font-brand ml-auto pb-1 text-lg">{value}</div>
    </div>
  );
}

export function BlockProjectInvestCard() {
  const { user } = useUserBusiness();
  const project = useProjectContext();

  dayjs.locale(user?.locale || "fr");

  if (!project) return null;

  const { totalAmountCollectedInCents, goal, scheduledEndDate } = project;

  const mechanics = getProjectMechanicSettings(project);
  const minimumTicketAmountInCents = mechanics?.minimumTicketAmountInCents;

  return (
    <div
      className={`
        shadow-card text-primary flex min-h-[175px] w-full flex-col justify-between
        gap-y-2.5 rounded-xl p-6
      `}
    >
      {project.marketingSettings?.gaugeVisibility === "visible" && (
        <div className="flex flex-col">
          <ProjectTotalAmountCollected
            amount={totalAmountCollectedInCents}
            className="font-obviously text-primary text-2xl"
          />
          <p className="text-dark-2 font-sans text-sm">fonds collectés</p>
          <ProjectProgressBar
            progressMaxGoal={project.fundraisingProgressMaxGoal}
            progressMinGoal={project.fundraisingProgressMinGoal}
            className="px-0 py-2"
          />
        </div>
      )}

      <div>
        {project.marketingSettings?.goalVisibility === "visible" && (
          <StatsRow
            label="Objectifs de la levée"
            value={`${formatLargeCurrency(
              goal?.minInCents,
            )} à ${formatLargeCurrency(goal?.maxInCents)}`}
            tooltipDesc="Montants minimum et maximum pour valider la levée de fonds."
          />
        )}
        <StatsRow
          label="Investisseurs"
          value={(project.totalInvestorsCount ?? "").toString()}
          tooltipDesc="Nombre d’investisseurs qui ont déjà investi dans le projet."
        />

        <StatsRow
          label="Ticket minimum"
          value={formatCurrency(minimumTicketAmountInCents)}
          tooltipDesc="Montant minimum pour investir dans le projet."
        />

        {project?.status === "started" &&
          project?.marketingSettings?.flag === "last_days" && (
            <StatsRow
              label="Cloture dans"
              value={dayjs(scheduledEndDate).toNow(true)}
            />
          )}

        {project?.status === "finished" && (
          <StatsRow label="Statut" value="Terminée" />
        )}
      </div>

      <InvestButton className="flex-1" />
    </div>
  );
}
