import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { getAuthClient } from "@tudigo-monorepo/core-tudigo-api";
import useAuthStore, {
  useImpersonateId,
} from "@tudigo-monorepo/web-tudigo-auth-store";
import { Icon } from "@tudigo-monorepo/web-tudigo-components";

type AuthCallbackPageProps = {
  redirectUri?: string;
};

export function AuthCallbackPage({
  redirectUri = "/dispatch",
}: AuthCallbackPageProps) {
  const authClient = getAuthClient();
  const url = window.location.href;
  const setAuth = useAuthStore((state) => state.setAuth);
  const impersonateId = useImpersonateId();
  const navigate = useNavigate();

  const getTokens = useCallback(async () => {
    const response = await authClient.exchangeForAccessToken(url);

    if (!response) return navigate("/");

    setAuth({
      accessToken: response.access_token,
      refreshToken: response.refresh_token,
      accessTokenExpirationDate: response.expires_in,
      impersonateId: impersonateId,
    });
  }, [authClient, impersonateId, navigate, setAuth, url]);

  useAuthStore.subscribe((newStore, previousStore) => {
    if (newStore.accessToken !== previousStore.accessToken) {
      navigate(redirectUri);
    }
  });

  useEffect(() => {
    getTokens();
  }, [getTokens]);

  return (
    <div className="h-screen">
      <Icon className="m-auto h-full" name="Loading" />
    </div>
  );
}

export default AuthCallbackPage;
