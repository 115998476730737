export const ORGANIZATIONS = {
  TYPE: {
    INDIVIDUAL: "individual",
    COMPANY: "company",
    OPERATOR: "operator",
  },
  INTERNAL_KYC_STATUS: {
    SHOULD_BE_COMPLETED: "should_be_completed",
    SUBMITTED_AND_WAITING_APPROVAL: "submitted_and_waiting_approval",
    REJECTED: "rejected",
    APPROVED: "approved",
    TO_SYNCHRONIZE: "to_synchronize",
  },
};
