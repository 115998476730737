import React, { useState } from "react";

import { WMACustomer } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { Alert } from "@tudigo-monorepo/web-tudigo-components";

import { WmaCustomersAutocomplete } from "./wma-customers-autocomplete";

type WmaCreateInvestmentCustomerSelectorProps = {
  handleCustomerSelect: (customer: WMACustomer) => void;
};

export function WmaCreateInvestmentCustomerSelector(
  props: WmaCreateInvestmentCustomerSelectorProps,
) {
  const { t } = useTranslation();
  const { handleCustomerSelect } = props;

  const [errorCustomerNotConfirmed, setErrorCustomerNotConfirmed] =
    useState(false);

  const handleCustomerSelection = (customer: WMACustomer) => {
    handleCustomerSelect(customer);

    if (customer.customerUser?.investorProfile?.confirmed) {
      setErrorCustomerNotConfirmed(false);
    } else {
      setErrorCustomerNotConfirmed(true);
    }
  };

  const { user } = useUserBusiness();

  return (
    <>
      <WmaCustomersAutocomplete
        wmaUserId={user?.id}
        onSelectCustomer={handleCustomerSelection}
        className="w-[350px]"
        label={t(
          "investments.back_office.create_investment_page.form.users_autocomplete.label",
        )}
        placeholder={t(
          "investments.back_office.create_investment_page.form.users_autocomplete.placeholder",
        )}
        emptyMessage={t(
          "investments.back_office.create_investment_page.form.users_autocomplete.no_data_result",
        )}
      />
      {errorCustomerNotConfirmed && (
        <Alert
          size="F"
          variant="warning"
          icon="Info"
          title="Attention cet utilisateur n'a pas encore confirmé son profil investisseur, vous ne vouvez pas investir pour lui"
        />
      )}
    </>
  );
}
