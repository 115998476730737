import { ReactNode } from "react";
import { PrimitiveType, useIntl } from "react-intl";

export type TFunction = (
  id: string,
  options?: Record<string, PrimitiveType | any>,
) => string;

export type TFunctionNode = (
  id: string,
  options?: Record<string, PrimitiveType | any>,
) => ReactNode;

export function useTranslation() {
  const intl = useIntl();

  const t: TFunction = (
    id: string,
    options?: Record<string, PrimitiveType | any>,
  ) => {
    return intl.formatMessage({ id }, options);
  };

  const tNode: TFunctionNode = (
    id: string,
    options?: Record<string, PrimitiveType | any>,
  ) => {
    return intl.formatMessage(
      { id },
      {
        b: (chunks: any) => <strong>{chunks}</strong>,
        br: <br />,
        ...options,
      },
    );
  };

  return { t, tNode, intl };
}
