import { Navigate, Route, Routes } from "react-router-dom";

import { CreateWmaInvestmentPage } from "../../pages/create-wma-investment-page/create-wma-investment-page";
import { EditWmaInvestmentContent } from "../../pages/edit-wma-investment-page/content/edit-wma-investment-content";
import { EditWmaInvestmentPaymentContent } from "../../pages/edit-wma-investment-page/content/edit-wma-investment-payment-tab/edit-wma-investment-payment-content";
import { EditWmaInvestmentSubscriptionFormContent } from "../../pages/edit-wma-investment-page/content/edit-wma-investment-subscription-form-content";
import { EditWmaInvestmentPage } from "../../pages/edit-wma-investment-page/edit-wma-investment-page";
import { WmaInvestmentsPage } from "../../pages/list-wma-investments-page/wma-investments-page";

export function WmaInvestmentsRoutesWebApp() {
  return (
    <Routes>
      <Route path="/list/:state" element={<WmaInvestmentsPage />} />

      <Route path="/create" element={<CreateWmaInvestmentPage />} />

      <Route
        path={"/edit/:wmaInvestmentId/*"}
        element={<EditWmaInvestmentPage />}
      >
        <Route path="investment" element={<EditWmaInvestmentContent />} />
        <Route
          path="subscription-form"
          element={<EditWmaInvestmentSubscriptionFormContent />}
        />
        <Route path="payment" element={<EditWmaInvestmentPaymentContent />} />
        <Route path="*" element={<EditWmaInvestmentContent />} />
      </Route>

      <Route path="*" element={<Navigate to="list/all" replace />} />
    </Routes>
  );
}
