import React, { useEffect, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";

import { Organization } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { ORGANIZATIONS, QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";
import { PageEnum, useSpaceBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  getQueryDetailKey,
  useGetOrganizationByIdQuery,
} from "@tudigo-monorepo/web-tudigo-api-client";
import { StepObject } from "@tudigo-monorepo/web-tudigo-components";

import { KYCNavigation } from "./shared/components/kyc-navigation";
import { useKycNavigationSteps } from "./utils/kyc-utils";

type KYCContext = {
  organization: Organization;
  setOrganization: (organization: Organization) => void;
  organizationQueryKey: readonly [
    string | number,
    { readonly fields: string | undefined },
  ];
  refetchOrganization: () => void;
};

const organizationPersonFields =
  "id,address,birth_date,birth_location,birth_date,email,first_name,last_name,gender,nationality,phone,position,is_ultimate_beneficial_owner,is_organization_representative,shares_count,documents.fields(id,document,type,pms_file)";

const organizationRequestFields = `id,name,logo,persons,company_information,individual_information,type,organization_representative.fields(${organizationPersonFields}),ultimate_beneficial_owners.fields(${organizationPersonFields}),organization_bank_accounts,documents,pms_account,internal_kyc_status`;

export function KycPage() {
  const organizationId = useParams().organizationId as string;
  const stepFromUrl = useParams()["*"] as string;
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const { currentPagePaths } = useSpaceBusiness();
  const kycPagePath = currentPagePaths[
    PageEnum.ORGANIZATION_KYC_FUNNEL
  ].replace(":organizationId", organizationId);

  const { data: organizationFromApi, refetch: refetchOrganization } =
    useGetOrganizationByIdQuery(organizationId, {
      fields: organizationRequestFields,
    });

  const [organization, setOrganization] = useState<Organization | undefined>(
    organizationFromApi,
  );

  const steps = useKycNavigationSteps(organization);

  useEffect(() => {
    if (organizationFromApi) {
      setOrganization(organizationFromApi);
    }
  }, [organizationFromApi]);

  useEffect(() => {
    if (
      organization &&
      organization.internalKycStatus ===
        ORGANIZATIONS.INTERNAL_KYC_STATUS.SUBMITTED_AND_WAITING_APPROVAL
    ) {
      if (location.pathname !== `${kycPagePath}/waiting-approval`) {
        navigate(`${kycPagePath}/waiting-manual-approval`, {
          replace: true,
        });
      }
    }
  }, [location.pathname, navigate, organization]);

  if (!organization) return null;

  const organizationQueryKey = getQueryDetailKey({
    key: QUERY_KEYS.ORGANIZATION,
    id: organization.id,
    fields: organizationRequestFields,
  });

  const activeStepIndex = steps.findIndex(
    (step) => step.navigateTo === stepFromUrl,
  );
  const backPath = steps[activeStepIndex - 1]?.navigateTo;

  const onBackButtonClick = () => {
    if (backPath) {
      navigate(`${kycPagePath}/${backPath}`, {
        replace: true,
      });
    } else {
      navigate(-1);
    }
  };

  const onSaveAndExit = () => {
    navigate("/dispatch");
  };

  const getCustomEndingStep = (): StepObject[] => {
    if (location.pathname.includes("waiting-manual-approval")) {
      return [
        {
          label: t("kyc_funnel.navigation.stepper.label.waiting_approval"),
          navigateTo: "waiting-approval",
          customStyle: {
            icon: "Timer",
            backgroundClassName: "bg-waiting-super-light",
            iconColor: "waiting",
          },
        },
      ];
    }
    if (location.pathname.includes("waiting-approval")) {
      return [
        {
          label: t("kyc_funnel.navigation.stepper.label.waiting_approval"),
          navigateTo: "waiting-approval",
          customStyle: {
            icon: "Loading",
            backgroundClassName: "bg-waiting-super-light",
            iconColor: "waiting",
          },
        },
      ];
    } else {
      return [];
    }
  };

  return (
    <div>
      <KYCNavigation
        title={t("kyc_funnel.page_title")}
        steps={[...steps, ...getCustomEndingStep()]}
        activeStepIndex={activeStepIndex}
        onBackButtonClick={onBackButtonClick}
        hideButtons={!backPath && activeStepIndex !== 0}
        onSaveButtonClick={onSaveAndExit}
      />
      <div className=" mx-auto mb-20 mt-[104px] w-full overflow-y-auto overflow-x-hidden px-4 py-8 sm:w-2/3 sm:overflow-x-visible sm:px-12 sm:pb-16">
        <Outlet
          context={
            {
              organization,
              setOrganization,
              organizationQueryKey,
              refetchOrganization,
            } satisfies KYCContext
          }
        />
      </div>
    </div>
  );
}

export function useKYCContext() {
  return useOutletContext<KYCContext>();
}
