import { useMemo } from "react";
import { useIntl } from "react-intl";

import { createFilterHelper } from "@tudigo-monorepo/web-tudigo-collection";

export const useCreditorFilters = (userId: string) => {
  const intl = useIntl();

  const filterHelper = createFilterHelper<{ investmentId: string }>();

  return useMemo(
    () => [
      filterHelper.investmentSelect(
        "investmentId",
        intl.formatMessage({ id: "account" }),
        userId,
        { globalStatus: "validated", mechanic: "bonds" },
      ),
    ],
    [filterHelper, intl, userId],
  );
};
