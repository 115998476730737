import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import {
  getResourceFromTudigoResponse,
  TudigoError,
} from "@tudigo-monorepo/core-tudigo-api";
import { Project } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient } from "../../../";
import { getQueryDetailKey } from "../../query-key-factory";

type GetProjectById = {
  projectId: string | undefined;
  query?: { fields?: string };
};

export const getProjectById = async (params: GetProjectById) => {
  const apiClient = getApiClient();

  if (!params.projectId) {
    throw new Error("projectId is required");
  }

  const response = await apiClient.apiV1.projects.getProjectById(
    params?.projectId,
    params?.query,
  );

  return getResourceFromTudigoResponse(response.data);
};

export const useGetProjectById = (
  params: GetProjectById,
  options?: Omit<UseQueryOptions<Project, TudigoError>, "queryKey">,
) =>
  useQuery({
    queryKey: getQueryDetailKey({
      key: QUERY_KEYS.PROJECTS,
      id: params.projectId!,
      fields: params?.query?.fields,
    }),
    queryFn: () => getProjectById(params),
    ...options,
  });
