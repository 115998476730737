import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";

import {
  AssemblyVoteRequestBody,
  getResourceFromTudigoResponse,
  TudigoError,
  TudigoResponse,
  UpdateAssemblyParticipantBody,
} from "@tudigo-monorepo/core-tudigo-api";
import {
  Assembly,
  AssemblyParticipant,
  User,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { NumberSearchCriteria } from "@tudigo-monorepo/core-tudigo-utils";

import { CollectionRequestParams, getApiClient } from "..";

export type UserAssembliesFilters = {
  projectId?: string;
  organizationId?: string;
  createdAt?: NumberSearchCriteria;
};

export type UserAssemblyParticipantsFilters = {
  createdAt?: NumberSearchCriteria;
  status?: string[];
};

export const useGetAssembliesForOrganization = (
  params: CollectionRequestParams<
    { organizationId: string },
    UserAssembliesFilters
  >,
) => {
  const { pagination, fields, filters, pathParams } = params;
  const apiClient = getApiClient();

  return useQuery<TudigoResponse<Assembly[]>, TudigoError>({
    refetchOnMount: false,
    queryKey: [
      `organization-assemblies-${pathParams.organizationId}`,
      pathParams.organizationId,
      fields,
      filters,
    ],
    queryFn: async () => {
      const response =
        await apiClient.apiV1.assemblies.getAssembliesForOrganization(
          pathParams.organizationId,
          {
            ...filters,
            fields: fields,
            offset: pagination?.offset,
            limit: pagination?.limit,
          },
        );

      return response.data;
    },
    retry: 0,
  });
};

export const useGetUserAssemblyParticipationsForOrganization = (
  params: CollectionRequestParams<
    { userId: string; organizationId: string },
    UserAssemblyParticipantsFilters
  >,
) => {
  const { pagination, fields, filters, pathParams } = params;
  const apiClient = getApiClient();

  return useQuery<TudigoResponse<AssemblyParticipant[]>, TudigoError>({
    refetchOnMount: false,
    queryKey: [
      `user-${pathParams.userId}-organization-${pathParams.organizationId}-assembly-participants`,
      pathParams.organizationId,
      fields,
      filters,
    ],
    queryFn: async () => {
      const response =
        await apiClient.apiV1.assemblies.getUserAssemblyParticipationForOrganization(
          pathParams.userId,
          pathParams.organizationId,
          {
            ...filters,
            fields: fields,
            offset: pagination?.offset,
            limit: pagination?.limit,
          },
        );

      return response.data;
    },
    retry: 0,
  });
};

export const useGetAssemblyByIdQuery = (
  assemblyId: Assembly["id"],
  query?: { fields?: string },
  options?: Omit<UseQueryOptions<Assembly, TudigoError>, "queryKey">,
) => {
  const apiClient = getApiClient();

  return useQuery<Assembly, TudigoError>({
    queryKey: [`assembly-${assemblyId}`],
    queryFn: async () => {
      const response = await apiClient.apiV1.assemblies.getAssemblyById(
        assemblyId,
        query,
      );

      return getResourceFromTudigoResponse(response.data);
    },
    ...options,
    retry: 0,
  });
};

export const useGetAssemblyParticipantByIdQuery = (
  assemblyId: Assembly["id"],
  assemblyParticipantId: AssemblyParticipant["id"],
  query?: { fields?: string },
) => {
  const apiClient = getApiClient();

  return useQuery<AssemblyParticipant, TudigoError>({
    queryKey: [`assembly_participant-${assemblyParticipantId}`],
    queryFn: async () => {
      const response =
        await apiClient.apiV1.assemblies.getAssemblyParticipantById(
          assemblyId,
          assemblyParticipantId,
          query,
        );

      return getResourceFromTudigoResponse(response.data);
    },
    retry: 0,
  });
};

export const useChangeAssemblyParticipationMutation = () => {
  const apiClient = getApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      assemblyId,
      participantId,
      data,
      query,
    }: {
      assemblyId: Assembly["id"];
      participantId: AssemblyParticipant["id"];
      data: UpdateAssemblyParticipantBody;
      query?: { fields?: string };
    }) =>
      apiClient.apiV1.assemblies.updateAssemblyParticipant(
        assemblyId,
        participantId,
        data,
        query,
      ),
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries({
        queryKey: [`assembly_participant-${variables.participantId}`],
      });
    },
    onError: () => {},
  });
};

export const useAssemblyVoteRequestMutation = () => {
  const apiClient = getApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      assemblyId,
      data,
      query,
    }: {
      assemblyId: User["id"] | "me";
      data: AssemblyVoteRequestBody;
      query?: { fields?: string };
    }) =>
      apiClient.apiV1.assemblies.requestAssemblyVote(assemblyId, data, query),
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries({
        queryKey: [`assembly-${variables.assemblyId}`],
      });
      queryClient.invalidateQueries({
        queryKey: [
          `assembly_participant-${variables.data.assemblyParticipant.id}`,
        ],
      });
    },
    onError: () => {},
  });
};
