import { useState } from "react";

import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  useSubscribeProjectNotificationsMutation,
  useUnsubscribeProjectNotificationsMutation,
} from "@tudigo-monorepo/web-tudigo-api-client";

import { useVisitorProjectNotificationsSubscription } from "./use-visitor-project-notifications-subscription";

export const useProjectNotificationsSubscription = (projectId: string) => {
  const { user } = useUserBusiness();

  const {
    saveVisitorSubscribedProject,
    removeVisitorSubscribedProject,
    getVisitorSubscribedProjects,
  } = useVisitorProjectNotificationsSubscription();

  const subscribedProjects = user
    ? user.followedProjectLeads ?? []
    : getVisitorSubscribedProjects();

  const currentSubscribedProject = subscribedProjects.find(
    (sub) => projectId === sub.refId,
  );

  const [isSubscribed, setIsSubscribed] = useState(
    Boolean(currentSubscribedProject),
  );

  const { mutate: subscribeProjectMutation, isPending: isSubscribeLoading } =
    useSubscribeProjectNotificationsMutation({
      onSuccess: (res) => {
        setIsSubscribed(true);

        if (!user) {
          saveVisitorSubscribedProject({
            email: res.data.email,
            leadId: res.data.id,
            projectId,
          });
        }
      },
    });

  const {
    mutate: unsubscribeProjectMutation,
    isPending: isUnsubscribeLoading,
  } = useUnsubscribeProjectNotificationsMutation({
    onSuccess: (res) => {
      setIsSubscribed(false);

      if (!user) {
        removeVisitorSubscribedProject({
          leadId: res.data.id,
          projectId,
        });
      }
    },
  });

  const subscribeProject = (email?: string) => {
    subscribeProjectMutation({
      data: { refId: projectId, email },
    });
  };

  const unsubscribeProject = () => {
    if (currentSubscribedProject) {
      unsubscribeProjectMutation({
        leadId: currentSubscribedProject.id,
      });
    }
  };

  return {
    isSubscribed,
    isLoading: isSubscribeLoading || isUnsubscribeLoading,
    subscribeProject,
    unsubscribeProject,
  };
};
