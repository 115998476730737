import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  CreateWmaCustomerRequestBody,
  TudigoError,
} from "@tudigo-monorepo/core-tudigo-api";
import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import {
  FormContextProvider,
  getSmartFormComponents,
} from "@tudigo-monorepo/web-feature-form";
import { useCreateWMACustomerMutation } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  Button,
  Icon,
  IconName,
  PageHead,
  Tabs,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

export function CreateWmaCustomerPage() {
  const navigate = useNavigate();

  const { SmartTextField } =
    getSmartFormComponents<CreateWmaCustomerRequestBody>();

  const [customerFormData, setCustomerFormData] =
    React.useState<CreateWmaCustomerRequestBody>({
      customerUser: {
        email: "",
      },
    });
  const [error, setError] = useState<TudigoError | null>(null);

  const { mutate: createWmaCustomer, isPending: isLoading } =
    useCreateWMACustomerMutation({
      onSuccess: () => {
        toast((content) => (
          <Alert
            icon="Upload"
            close={content.closeToast}
            variant="success"
            size="S"
            title="Succès"
            description="L'invitation a bien été envoyée."
          />
        ));
        navigate("list/all");
      },
      onError: (err) => {
        setError(err);
        toast((content) => (
          <Alert
            icon="Error"
            close={content.closeToast}
            variant="error"
            size="S"
            title="Erreur"
            description="Une erreur est survenue. L'email renseigné ne correspond à aucun utilisateur ou bien une invitation a déjà été envoyée."
          />
        ));
      },
    });

  const handleSubmit = () => {
    createWmaCustomer(customerFormData);
  };

  return (
    <div id="organizations-list-manager-page">
      <PageHead
        title="Création d'un client"
        withEndLine
        levels={[
          {
            iconName: "Group" as IconName,
            callback: () => {
              navigate("list/all");
            },
          },
        ]}
      />
      <Tabs
        tabs={[
          {
            name: "Sélectionner l'utilisateur",
          },
        ]}
        className="px-6"
      />

      <div className="m-6 flex flex-col gap-6">
        <Typography variant="body2">
          Saisissez l’email de votre client, nous lui demanderons ensuite de
          confirmer son rattachement à votre compte. Vous serez informé dès son
          acceptation
        </Typography>
        <div className="border-light-1 flex rounded-lg border p-6">
          <div className="min-w-[250px]">
            <FormContextProvider
              error={error}
              formData={customerFormData}
              setFormData={setCustomerFormData}
            >
              <SmartTextField
                name="customerUser.email"
                label="Email"
                required
                placeholder="Adresse email"
                iconRight={
                  <Icon name="Mail" primaryColor={themeColors["dark-2"]} />
                }
              />
            </FormContextProvider>
          </div>
        </div>
        <Button
          label="Envoyer la demande"
          iconRight="Mail"
          variant="primary"
          className="w-fit"
          disabled={customerFormData.customerUser.email === ""}
          onClick={handleSubmit}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}
