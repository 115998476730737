import React from "react";

import { Modal, Typography } from "@tudigo-monorepo/web-tudigo-components";

type ConfirmationModalProps = {
  showExplanationDialog: boolean;
  setShowExplanationDialog: (show: boolean) => void;
};
export const ExplanationModal = (props: ConfirmationModalProps) => {
  const { showExplanationDialog, setShowExplanationDialog } = props;

  return (
    <Modal
      title="Pourquoi compléter son profil investisseur ?"
      content={
        <Typography variant="caption1-regular">
          Conformément à la règlementation, ce test a pour objet de recueillir
          des informations relatives à votre situation financière, vos objectifs
          d’investissement et à vos connaissances et expériences en matière
          d’investissement. Avant de vous permettre d’avoir pleinement accès aux
          investissements, Tudigo doit évaluer si les services de financement
          participatif sont appropriés au regard de votre profil d’investisseur.
          <br />
          <br />
          Ce test doit être rempli par vos soins d’une façon complète et
          précise, à défaut, la plateforme pourra limiter votre accès aux
          services. Il est de votre responsabilité d’informer Tudigo de tout
          changement relatif aux informations figurant dans le présent
          questionnaire. Votre profil sera ainsi mis à jour en conséquence.
          Conformément à ses obligations légales et règlementaires, Tudigo
          pourra vous contacter de façon périodique afin de mettre à jour votre
          profil. Ce questionnaire ne constitue en aucun cas un engagement de
          souscription de votre part aux offres et services accessibles sur la
          plateforme Tudigo.
          <br />
          <br />
          Vous aurez la possibilité de contacter le service client de Tudigo
          pour toute explication concernant le résultat de l’analyse du test.
          <br />
          <br />
          <strong>Il vous sera demandé de refaire ce test tous les ans.</strong>
        </Typography>
      }
      dismissOnOutsideClick
      isOpen={showExplanationDialog}
      setIsOpen={setShowExplanationDialog}
    />
  );
};
