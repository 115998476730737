import {
  checkIfEquitySavingPlanBankIsOffline,
  isValidEquitySavingPlan,
} from "../../../utils/equity-saving-plans-utils";
import { useInvestmentAccountContext } from "../investment-account-context";
import { UpdateInvestmentAccountModal } from "../update-investment-account/update-investment-account-modal";
import { UpdateInvestmentAccountModalTrigger } from "../update-investment-account/update-investment-account-modal-trigger";
import { BankAccountSection } from "./sections/bank-account-section";
import { BankAdvisorSection } from "./sections/bank-advisor-section";
import { BankInformationsSection } from "./sections/bank-informations-section";

export function InvestmentAccountViewer() {
  const { equitySavingPlan } = useInvestmentAccountContext();

  return (
    <>
      <div className="m-6 flex flex-col gap-y-6">
        <div className="grid grid-cols-4 gap-6 sm:grid-cols-12">
          <div className="col-span-4 flex flex-col gap-y-6 sm:col-span-8">
            <BankInformationsSection />
            {checkIfEquitySavingPlanBankIsOffline(equitySavingPlan) && (
              <BankAdvisorSection />
            )}
          </div>
          <BankAccountSection />
        </div>
        {isValidEquitySavingPlan(equitySavingPlan) && (
          <UpdateInvestmentAccountModalTrigger />
        )}
      </div>
      <UpdateInvestmentAccountModal />
    </>
  );
}
