import {
  isSharesProject,
  Project,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

import { BadgeRebrand } from "../../ui/badge-rebrand/badge-rebrand";

export function ProjectCardRebrandBadges(props: {
  project: Project;
  smallCard?: boolean;
}) {
  const { project, smallCard = false } = props;
  const { t } = useTranslation();

  const getProjectMechanicLabel = () => {
    if (isSharesProject(project)) {
      return t("component.project_card.chips.shares");
    }
    if (project.bondsMechanicSettings?.isConvertible) {
      return t("component.project_card.chips.bonds_convertible");
    }

    return t("component.project_card.chips.bonds");
  };

  const totalExtraCount =
    (project.tags ? project.tags.length - 1 : 0) +
    (project.categories ? project.categories.length : 0);

  return (
    <div className="flex flex-wrap  items-center gap-1.5 font-medium">
      {/* Badge for project mechanic label */}
      <BadgeRebrand variant="primary">{getProjectMechanicLabel()}</BadgeRebrand>

      {/* First tag, if exists */}
      {project.tags && project.tags.length > 0 && (
        <BadgeRebrand key={project.tags[0].name} variant="secondary">
          {project.tags[0].name}
        </BadgeRebrand>
      )}

      {/* Badge for remaining tags and categories, if more than one */}
      {totalExtraCount > 0 && (
        <BadgeRebrand variant="secondary">+{totalExtraCount}</BadgeRebrand>
      )}
    </div>
  );
}
