export function ProjectPresentationLegalInfo() {
  return (
    <div className="text-dark-1 my-6 flex flex-col gap-4 text-sm">
      <p>{`Risques liés à l'investissement`}</p>
      <p>
        {`Tout investissement présente des risques de perte totale ou partielle du
        capital investi. Les dispositifs fiscaux mentionnés ne constituent pas
        des conseils. Chaque investisseur est responsable de consulter un avocat
        spécialisé en fiscalité ou les autorités fiscales pour déterminer s'il
        est éligible aux dispositifs fiscaux présentés. Les estimations données
        ne s’appliquent qu’aux investissements réalisés en tant que personne
        physique résidente fiscale française.`}
      </p>
    </div>
  );
}
