import { useState } from "react";
import { Elements as StripeElementsProvider } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

import { ClubPremiumAdvantageList } from "../../../shared-internal/components/club-premium-advantage-list";
import { useGetClubV2UsingIdPathParams } from "../../../shared-internal/hooks/use-get-club-v2-using-id-path-params";
import { useGetCurrentClubMember } from "../../hooks/use-get-current-club-member";
import { PaymentAmount } from "./payment-amount";
import { PaymentErrorAlert } from "./payment-error-alert";
import { PaymentForm } from "./payment-form";
import { StripeIcon } from "./stripe-icon";

const STRIPE_KEY = String(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);
const stripePromise = loadStripe(STRIPE_KEY);

export function SubscribeClubStep3PaymentPage() {
  const club = useGetClubV2UsingIdPathParams();
  const { clubMember, isFetched } = useGetCurrentClubMember(club?.id);
  const { t } = useTranslation();

  const [isPaymentError, setIsPaymentError] = useState(false);

  return (
    <section className="container mx-auto flex flex-col gap-y-6 p-4 lg:py-10">
      <header className="flex flex-col gap-y-6">
        <div className="border-light-2 flex flex-col gap-y-1.5 border-b pb-4">
          <h1 className="text-primary font-brand text-[27px] font-medium">
            {t("subscribe_club_page.step_3_payment.header.title")}
          </h1>
        </div>
        <PaymentErrorAlert isError={isPaymentError} />
      </header>

      <div className="flex flex-col gap-6 md:flex-row">
        <article className="border-light-1 flex basis-1/2 items-center justify-center rounded-lg border bg-white p-6">
          <ClubPremiumAdvantageList />
        </article>

        {isFetched && (
          <article className="border-light-1 h-full basis-1/2 rounded-lg border bg-white p-6 shadow-lg">
            <PaymentAmount />

            <StripeElementsProvider
              stripe={stripePromise}
              options={{
                clientSecret:
                  clubMember?.subscription?.latestPaymentIntent?.token,
              }}
            >
              <PaymentForm
                isError={isPaymentError}
                onError={setIsPaymentError}
              />
            </StripeElementsProvider>
          </article>
        )}
      </div>

      <footer className="border-light-1 flex w-full flex-col items-center gap-y-1.5 border-t pt-6">
        <p className="text-dark-2 font-sans text-[13px]">
          {t("subscribe_club_page.step_3_payment.footer.secure_payment")}
        </p>
        <StripeIcon />
      </footer>
    </section>
  );
}
