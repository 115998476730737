import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { ThinLayout } from "@tudigo-monorepo/web-tudigo-components";

import { LimitedAccess } from "../../../../shared/components/limited-access";
import { useProjectContext } from "../../project.context";
import { SimulatorBonds } from "./bonds/simulator-bonds";
import { SimulatorShares } from "./shares/simulator-shares";

export function ProjectSimulator() {
  const { isQualifiedOrWma } = useUserBusiness();
  const project = useProjectContext();
  const { t } = useTranslation();

  if (!isQualifiedOrWma) {
    return (
      <ThinLayout>
        <h3 className="text-dark-1 font-semibold">
          {t("page_project.tab_simulator.heading")}
        </h3>
        <LimitedAccess />
      </ThinLayout>
    );
  }

  if (!project) {
    return null;
  }

  return (
    <div>
      <h3 className="text-dark-1 mb-4 font-semibold">
        {t("page_project.tab_simulator.heading")}
      </h3>
      {project.mechanic === "shares" && <SimulatorShares />}
      {project.mechanic === "bonds" && <SimulatorBonds />}

      <ThinLayout className="mt-6 space-y-5">
        <p className="text-dark-1 pb-2 text-sm">
          <h3 className="pb-4">Avertissement :</h3>
          {`
          Les avantages fiscaux sont offerts en contrepartie d'un risque de
          perte totale ou partielle du capital investi et d'une durée de
          détention obligatoire des titres généralement supérieure à la durée de
          l'investissement (risque d'illiquidité). L'investisseur doit conserver
          les titres acquis à minima jusqu'au 31 décembre de la cinquième (5ème)
          année qui suit celle de la souscription et jusqu'au 31 décembre de la
          septième (7ème) année qui suit celle de la souscription en cas de
          remboursement des apports par la société émettrice des actions. Le
          traitement fiscal dépend de la situation individuelle de chaque
          client, et il est susceptible d’être modifié ultérieurement
          `}
        </p>
        <p className="text-dark-1 text-sm">
          <h3 className="pb-4">Risques liés à l’investissement :</h3>
          {`
          Tout investissement présente des risques de perte totale ou partielle
          du capital investi. Les dispositifs fiscaux mentionnés ne constituent
          pas des conseils. Chaque investisseur est responsable de consulter un
          avocat spécialisé en fiscalité ou les autorités fiscales pour
          déterminer s'il est éligible aux dispositifs fiscaux présentés. Les
          estimations données ne s’appliquent qu’aux investissements réalisés en
          tant que personne physique résidente fiscale française.
          `}
        </p>
      </ThinLayout>
    </div>
  );
}
