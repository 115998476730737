import { UserRole } from "@tudigo-monorepo/core-tudigo-api-models";

import { ConnectedNavigation } from "./use-connected-navigation";

export const useInvestorNavigation = (...args: any[]): ConnectedNavigation => {
  return {
    type: UserRole.INVESTOR,
    items: [
      { type: "section", label: "Gerer" },
      {
        type: "link",
        label: "Tableau de bord",
        path: "/my-account/dashboard",
        icon: "Dashboard",
      },
      {
        type: "link",
        label: "Investissements",
        path: "/my-account/my-investments/list/all",
        icon: "Invest",
      },
      {
        type: "link",
        label: "Intérêts",
        path: "/my-account/my-repayments",
        icon: "Money",
      },
      {
        type: "link",
        label: "Clubs",
        path: "/my-account/clubs",
        icon: "Group",
      },
      { type: "link", label: "CGP", path: "/my-account/wma", icon: "UserChat" },
      { type: "section", label: "Investir" },
      {
        type: "link",
        label: "Opportunités",
        path: "/",
        icon: "List",
        end: true,
      },
      { type: "section", label: "Support" },
      {
        type: "link",
        label: "FAQ",
        path: "https://aide.tudigo.co/fr",
        icon: "Faq",
        target: "_blank",
      },
    ],
  };
};
