import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import {
  StepObject,
  Stepper,
  Title,
} from "@tudigo-monorepo/web-tudigo-components";

import { KycNavigationButton } from "./kyc-navigation-button";

type KYCNavigationProps = {
  title: string;
  onBackButtonClick: () => void;
  onSaveButtonClick: () => void;
  steps: string[] | StepObject[];
  activeStepIndex?: number;
  hideButtons?: boolean;
};

export function KYCNavigation(props: KYCNavigationProps) {
  const {
    title,
    onBackButtonClick,
    onSaveButtonClick,
    steps,
    activeStepIndex,
    hideButtons = false,
  } = props;

  return (
    <div
      role="navigation"
      className="dynamic-form-navigation fixed left-0 top-0 z-50 flex h-[104px] w-full flex-col items-center justify-center gap-y-3 border-b bg-white px-6 sm:gap-y-0"
    >
      <Title
        level="h2"
        className="hidden whitespace-nowrap text-center sm:block sm:translate-y-2"
      >
        {title}
      </Title>
      <Title
        level="h5"
        className="block whitespace-nowrap text-center sm:hidden"
      >
        {title}
      </Title>

      <div
        className={cn("flex w-full items-center gap-x-4", {
          "justify-between": !hideButtons,
          "justify-center": hideButtons,
        })}
      >
        <KycNavigationButton
          className={cn({
            hidden: hideButtons,
          })}
          type="back"
          onClick={onBackButtonClick}
        />
        <div
          className={cn("contents sm:block sm:w-[600px] sm:max-w-[600px]", {
            "my-6": hideButtons,
          })}
        >
          <Stepper steps={steps} activeStepIndex={activeStepIndex} />
        </div>
        <KycNavigationButton
          className={cn({
            hidden: hideButtons,
          })}
          type="exit"
          onClick={onSaveButtonClick}
        />
      </div>
    </div>
  );
}
