import { ActionTypes } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import {
  Stepper,
  Title,
  type Step,
} from "@tudigo-monorepo/web-tudigo-components";

import { DynamicFormNavigationItem } from "./components/dynamic-form-navigation-item";
import { useDynamicFormContext } from "./store/dynamic-form-context";

type DynamicFormNavigationProps = {
  redirectPath?: string;
};

export function DynamicFormNavigation(_: DynamicFormNavigationProps) {
  const {
    form,
    sections,
    goToPage,
    currentPageIndex,
    getCurrentSection,
    actionsBinding,
    answers,
  } = useDynamicFormContext();

  const steps: Step[] = sections.map(
    (section, index) => section.name || index.toString(),
  );

  const activeStep: number | null = sections.findIndex(
    (section) => section.id === getCurrentSection()?.id,
  );

  const goToPreviousPage = () => {
    goToPage(currentPageIndex - 1);
  };

  return (
    <div
      role="navigation"
      className="dynamic-form-navigation fixed left-0 top-0 z-20 flex h-[104px] w-full flex-col items-center justify-center gap-y-3 border-b bg-white px-6 sm:gap-y-0"
    >
      {form?.name ? (
        <>
          <Title
            level="h2"
            className="hidden whitespace-nowrap text-center sm:block sm:translate-y-2"
          >
            {form.name}
          </Title>
          <Title
            level="h5"
            className="block whitespace-nowrap text-center sm:hidden"
          >
            {form.name}
          </Title>
        </>
      ) : null}
      <div className="flex w-full items-center justify-between gap-x-4">
        <div
          className={cn({
            "pointer-events-none opacity-0": currentPageIndex === 1,
          })}
        >
          <DynamicFormNavigationItem type="back" onClick={goToPreviousPage} />
        </div>
        <div className="contents sm:block sm:w-[600px] sm:max-w-[600px]">
          <Stepper steps={steps} activeStepIndex={activeStep} />
        </div>
        <DynamicFormNavigationItem
          type="exit"
          onClick={() => {
            actionsBinding &&
              actionsBinding[ActionTypes.EXIT]?.onClick(answers);
          }}
        />
      </div>
    </div>
  );
}
