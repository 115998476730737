import { ClubV2 } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Icon, IconName } from "@tudigo-monorepo/web-tudigo-components";

const InformationRow = ({ label, value }: { label: string; value: string }) => (
  <div className="flex justify-between">
    <div className="text-primary text-xs">{label}</div>
    <div className="text-primary text-xs font-semibold">{value}</div>
  </div>
);

const SocialLink = ({ iconName, url }: { iconName: IconName; url: string }) =>
  url ? (
    <a href={url} target="_blank" rel="noreferrer">
      <Icon name={iconName} />
    </a>
  ) : null;

export const CircleOrganizationCardBlock = ({ club }: { club: ClubV2 }) => {
  const { t } = useTranslation();

  if (!club) {
    return null;
  }

  const { organization } = club;

  if (!organization) {
    return;
  }
  const { companyInformation, socialLinks } = organization;

  const { legalName, legalStatus, registrationNumbers, headOfficeAddress } =
    companyInformation || {};

  return (
    <div className="border-light-1 rounded-lg border bg-white p-6">
      <div className="text-primary font-bold">
        {t("page_club.cms_organization_card_block.title")}
      </div>
      <div className="my-4 flex flex-col gap-4">
        <InformationRow
          label={t("page_club.cms_organization_card_block.legal_name.label")}
          value={legalName ?? organization.name}
        />
        <InformationRow
          label={t("page_club.cms_organization_card_block.legal_status.label")}
          value={
            legalStatus ??
            t("page_club.cms_organization_card_block.undefined.label")
          }
        />
        <InformationRow
          label={t("page_club.cms_organization_card_block.siret.label")}
          value={
            registrationNumbers?.siret ??
            t("page_club.cms_organization_card_block.undefined.label")
          }
        />
        <InformationRow
          label={t(
            "page_club.cms_organization_card_block.head_office_address.label",
          )}
          value={
            headOfficeAddress?.formatted ??
            t("page_club.cms_organization_card_block.undefined.label")
          }
        />
      </div>
      {socialLinks && Object.keys(socialLinks).length > 0 && (
        <div className="flex w-full items-center justify-center gap-3">
          {Object.entries(socialLinks).map(([key, value]) => (
            <SocialLink
              key={key}
              iconName={
                `Social${
                  key.charAt(0).toUpperCase() + key.slice(1)
                }` as IconName
              }
              url={value}
            />
          ))}
        </div>
      )}
    </div>
  );
};
