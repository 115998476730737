import React from "react";

import { Investment } from "@tudigo-monorepo/core-tudigo-api-models";
import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";
import {
  Alert,
  Avatar,
  Icon,
  PageHead,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { useInvestmentFunnelPageContext } from "../../../../../pages/investment-funnel-page";
import { FinalPendingTasksBlock } from "../shared/final-pending-tasks-block";
import { LastNewsBlock } from "../shared/last-news-block";

function requirementsAreFulfilled(ongoingInvestment: Investment) {
  return (
    ongoingInvestment.investorOrganization &&
    ongoingInvestment.investorOrganization.pmsAccount &&
    ongoingInvestment.investorOrganization.pmsAccount.virtualIban
  );
}

export function FinalStepPaymentByCardSuccessfull() {
  const { ongoingInvestment, project } = useInvestmentFunnelPageContext();
  const { t } = useTranslation();

  if (!ongoingInvestment || !requirementsAreFulfilled(ongoingInvestment)) {
    return;
  }

  return (
    <>
      <PageHead
        title={t(
          "investment_funnel.final_page.payment_by_card_successful.page_head.title",
        )}
        className="py-4"
        withEndLine
      />

      <Alert
        size="F"
        variant="success"
        icon="Notification"
        title={t(
          "investment_funnel.final_page.payment_by_card_successful.alert.title",
        )}
        description={t(
          "investment_funnel.final_page.payment_by_card_successful.alert.description",
        )}
        className="mx-6"
      />

      <div className="border-success m-6 flex items-center gap-6 rounded-lg border p-6">
        <Typography variant="body2-semibold">
          {t(
            "investment_funnel.final_page.payment_by_card_successful.investment_detail.amount.label",
          )}
        </Typography>
        <Title level="h4" className="text-success">
          {formatCurrency(ongoingInvestment.totalAmountInCents)}
        </Title>
      </div>

      <FinalPendingTasksBlock
        project={project}
        tasks={[
          {
            avatar: (
              <Avatar variant="green">
                <Icon
                  name="Checked"
                  size="S"
                  primaryColor={themeColors.success}
                  secondaryColor={themeColors.success}
                />
              </Avatar>
            ),
            title: t(
              "investment_funnel.final_page.payment_by_card_successful.final_pending_tasks_block.step_1.title",
            ),
          },
          {
            avatar: (
              <Avatar variant="blue">
                <Typography variant="body2-semibold" className="text-accent">
                  2
                </Typography>
              </Avatar>
            ),
            title: t(
              "investment_funnel.final_page.payment_by_card_successful.final_pending_tasks_block.step_2.title",
            ),
            description: t(
              "investment_funnel.final_page.payment_by_card_successful.final_pending_tasks_block.step_2.description",
            ),
          },
          {
            avatar: (
              <Avatar variant="blue">
                <Typography variant="body2-semibold" className="text-accent">
                  3
                </Typography>
              </Avatar>
            ),
            title: t(
              "investment_funnel.final_page.payment_by_card_successful.final_pending_tasks_block.step_3.title",
            ),
            description: t(
              "investment_funnel.final_page.payment_by_card_successful.final_pending_tasks_block.step_3.description",
            ),
          },
          {
            avatar: (
              <Avatar variant="blue">
                <Typography variant="body2-semibold" className="text-accent">
                  4
                </Typography>
              </Avatar>
            ),
            title: t(
              "investment_funnel.final_page.payment_by_card_successful.final_pending_tasks_block.step_4.title",
            ),
            description: t(
              "investment_funnel.final_page.payment_by_card_successful.final_pending_tasks_block.step_4.description",
            ),
          },
        ]}
      />
      <LastNewsBlock />
    </>
  );
}
