import React from "react";

import { EquitySavingPlan } from "@tudigo-monorepo/core-tudigo-api-models";
import { Checkbox, Typography } from "@tudigo-monorepo/web-tudigo-components";

type WmaCustomerPeaAccountSelectorProps = {
  equitySavingPlan: EquitySavingPlan;
  peaAccountSelected: boolean;
  handleChangePeaAccountCheckbox: (value: boolean) => void;
};

export function WmaCustomerPeaAccountSelector(
  props: WmaCustomerPeaAccountSelectorProps,
) {
  const { peaAccountSelected, handleChangePeaAccountCheckbox } = props;

  return (
    <div className="flex items-center gap-1">
      <Checkbox
        id="pea-checkbox"
        className="relative"
        checked={peaAccountSelected}
        onCheckedChange={(checked) => handleChangePeaAccountCheckbox(!!checked)}
      />
      <label
        htmlFor="pea-checkbox"
        className="mt-0.5 flex w-full items-center justify-between"
      >
        <Typography variant="caption1-regular">
          Investir via le compte PEA ({props.equitySavingPlan?.bank?.name})
        </Typography>
      </label>
    </div>
  );
}
