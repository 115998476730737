import React from "react";
import { toast } from "react-toastify";

import { useSendWMACustomerInvitationLinkMutation } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  Button,
  Chip,
  ChipType,
  ListElement,
} from "@tudigo-monorepo/web-tudigo-components";

import { useEditWmaCustomerPageContext } from "../edit-wma-customer-page";

type ChipStatusVariant = {
  [key: string]: ChipType;
};

export function EditWmaCustomerSelectUserContent() {
  const { customer } = useEditWmaCustomerPageContext();

  const [isSendMailActive, setIsSendMailActive] = React.useState(true);

  const chipStatusVariant: ChipStatusVariant = {
    invited: "waiting",
    rejected: "error",
    accepted: "success",
    canceled: "info",
  };

  const chipLabel = {
    invited: "En attente de validation",
    rejected: "Demande refusée",
    accepted: "Demande acceptée",
    canceled: "Demande annulée",
  };

  const { mutateAsync: sendInvitationMail, isPending } =
    useSendWMACustomerInvitationLinkMutation({
      onSuccess: () => {
        toast((content) => {
          return (
            <Alert
              icon="Checked"
              variant="success"
              size="S"
              close={content.closeToast}
              title="Email envoyé"
            />
          );
        });
        setIsSendMailActive(false);
      },
      onError: () => {
        toast((content) => {
          return (
            <Alert
              icon="Error"
              variant="error"
              size="S"
              close={content.closeToast}
              title="Une erreur est survenue"
            />
          );
        });
        setIsSendMailActive(true);
      },
    });

  const handleSendInvitationMail = async () => {
    await sendInvitationMail({ wmaCustomerId: String(customer.id) });
  };

  return (
    <div className="m-6 flex flex-col gap-6">
      <ListElement
        startElement={{
          upperText: customer.customerUser?.firstName
            ? `${customer.customerUser?.firstName} ${customer.customerUser?.lastName}`
            : "",
          lowerText: customer.customerUser?.email,
          img: {
            icon: { name: "Profile" },
          },
          profileCardClassName: "min-w-[280px] flex justify-start px-3 py-2",
        }}
        middleElement={{
          upperText: "Rattachement du compte client à votre espace CGP",
        }}
        endElement={{
          render: () => {
            return (
              <div className="flex items-center gap-x-8">
                <Chip
                  label={chipLabel[customer.status as keyof typeof chipLabel]}
                  type={
                    chipStatusVariant[
                      customer.status as keyof typeof chipStatusVariant
                    ]
                  }
                  iconLeft="DotChip"
                />
              </div>
            );
          },
        }}
      />
      {customer.status === "invited" && (
        <Button
          label="Renvoyer la demande"
          iconRight="Mail"
          variant="primary"
          className="w-fit"
          disabled={!isSendMailActive}
          onClick={handleSendInvitationMail}
          isLoading={isPending}
        />
      )}
    </div>
  );
}
