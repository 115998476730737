import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import {
  TudigoError,
  TudigoResponse,
  UpdateOrganizationPersonRequestBody,
} from "@tudigo-monorepo/core-tudigo-api";
import { OrganizationPerson } from "@tudigo-monorepo/core-tudigo-api-models";

import { getApiClient } from "../../../";

type UpdateOrganizationPersonParams = {
  organizationPersonId: OrganizationPerson["id"];
  data: UpdateOrganizationPersonRequestBody;
};

export const updateOrganizationPerson = async (
  params: UpdateOrganizationPersonParams,
) => {
  const apiClient = getApiClient();

  const response =
    await apiClient.apiV1.organizationPersons.updateOrganizationPerson(
      params.organizationPersonId,
      params.data,
    );

  return response.data;
};

export const useUpdateOrganizationPerson = (
  options?: UseMutationOptions<
    TudigoResponse<OrganizationPerson>,
    TudigoError,
    UpdateOrganizationPersonParams
  >,
) => {
  return useMutation({
    mutationFn: (params: UpdateOrganizationPersonParams) =>
      updateOrganizationPerson(params),
    ...options,
  });
};
