import { createColumnHelper } from "@tanstack/react-table";

import { ClubMember } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";

import { getClubMemberStatusChip } from "../../shared-internal/utils/club-member-manager";

export const UseClubLeaderMembersColumn = () => {
  const columnHelper = createColumnHelper<ClubMember>();
  const { t } = useTranslation();

  return [
    columnHelper.display({
      header: t("club_leader.page_members.table_members.column.name"),
      cell: (info) => (
        <p className="text-primary text-sm">
          {`${info.row.original.user.firstName} ${info.row.original.user.lastName}`}
        </p>
      ),
    }),
    columnHelper.accessor("joinedAt", {
      header: t("club_leader.page_members.table_members.column.joined_at"),
      cell: (info) => (
        <p className="text-primary text-sm">
          {info.getValue()
            ? new Date(info.getValue()).toLocaleDateString()
            : "-"}
        </p>
      ),
    }),
    columnHelper.accessor("statistics.totalInvestmentsCount", {
      header: t(
        "club_leader.page_members.table_members.column.total_investments",
      ),
      cell: (info) => (
        <p className="text-primary text-sm"> {info.getValue()}</p>
      ),
    }),
    columnHelper.accessor("statistics.totalInvestmentsAmountInCents", {
      header: t(
        "club_leader.page_members.table_members.column.total_amount_invested",
      ),
      cell: (info) => (
        <p className="text-primary text-sm">
          {formatCurrency(info.getValue())}
        </p>
      ),
    }),
    columnHelper.display({
      header: t(
        "club_leader.page_members.table_members.column.membership_type",
      ),
      cell: (info) => (
        <p className="text-primary text-sm">
          {getClubMemberStatusChip(info.row.original.status, t)}
        </p>
      ),
    }),
  ];
};
