import React from "react";
import { Link } from "react-router-dom";

import {
  Chip,
  ChipProps,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

type ArticleCardProps = {
  articleUrl: string;
  articleImageUrl: string;
  articleTitle: string;
  articleDate: string;
  articleTags: ChipProps[];
};

export function ArticleCard(props: ArticleCardProps) {
  const {
    articleUrl,
    articleImageUrl,
    articleTitle,
    articleDate,
    articleTags,
  } = props;

  return (
    <Link
      target="_blank"
      to={articleUrl}
      className="relative flex aspect-square min-w-[275px] flex-1 items-end "
    >
      <img
        src={articleImageUrl}
        alt="placeholder"
        className="z-1 absolute h-full w-full rounded-lg object-cover brightness-75  filter"
      />
      <div className="z-10 m-6 flex flex-1 flex-col gap-2">
        <div className="w-100 flex flex-wrap items-center gap-1">
          {articleTags.map((tag, index) => (
            <Chip key={index} {...tag} />
          ))}
        </div>
        <Typography variant="body3-regular" className="text-white">
          {articleDate}
        </Typography>
        <Title level="h4" className="text-white">
          {articleTitle}
        </Title>
      </div>
    </Link>
  );
}
