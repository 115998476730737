import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { MetricsProjectCard } from "@tudigo-monorepo/web-feature-projects";

import { InvestmentFeedList } from "../components/investment-feed-list";
import { MetricsInvestmentCard } from "../components/metrics-investment-card";
import { useMyInvestmentPageContext } from "../hooks/use-my-investment-page-context";
import { getInvestmentCardColSpanClassName } from "../utils/get-investment-card-colspan-classname";

export function InvestmentInformationContent() {
  const { investments, project } = useMyInvestmentPageContext();

  const { t } = useTranslation();

  return (
    <>
      <div className="bg-light-3 px-6 py-8">
        <div className="grid grid-cols-12 gap-6">
          {investments?.map((investment, index: number) => {
            const colInvestmentCardSpanClassName =
              getInvestmentCardColSpanClassName(index, investments.length);

            return (
              <div
                key={investment.id}
                className={colInvestmentCardSpanClassName}
              >
                <MetricsInvestmentCard index={index} investment={investment} />
              </div>
            );
          })}

          <div
            className={`col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-${
              investments.length % 3 === 1 ? 6 : 12
            } 2xl:col-span-${investments.length % 3 === 1 ? 6 : 12}`}
          >
            <MetricsProjectCard project={project} />
          </div>
        </div>
      </div>

      <div className="m-6">
        <h3 className="text-primary mb-6 font-sans text-lg font-bold">
          {t(
            "my-investments.my_investment_page.information.investment_feed.all.last.title",
          )}
        </h3>
        {investments.length > 0 && investments[0]?.user?.id && (
          <InvestmentFeedList
            project={project}
            userId={investments[0].user.id}
            filter="all"
          />
        )}
      </div>
    </>
  );
}
