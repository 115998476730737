import { WMACustomer } from "@tudigo-monorepo/core-tudigo-api-models";
import { Chip } from "@tudigo-monorepo/web-tudigo-components";

import { useGetWMACustomerInvitationChipStatusProps } from "../hooks/use-wma-customer-invitation-status-utils";

type WmaCustomerInvitationChipStatusProps = {
  wmaCustomer?: WMACustomer;
};

export function WMACustomerInvitationChipStatus({
  wmaCustomer,
}: WmaCustomerInvitationChipStatusProps) {
  const chipStatusProps =
    useGetWMACustomerInvitationChipStatusProps(wmaCustomer);

  return <Chip {...chipStatusProps} />;
}
