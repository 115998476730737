import { useCallback } from "react";
import { toast } from "react-toastify";

import { EquitySavingPlanType } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  useCreateOrganizationEquitySavingPlanMutation,
  useGetOrganizationByIdQuery,
} from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  Modal,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { organizationRequestFields } from "../../../utils/organization-request-fields-utils";
import { OrganizationTypeSelector } from "../../organization-type-selector/organization-type-selector";
import { EquitySavingPlanForm } from "../equity-saving-plan-form";
import { useInvestmentAccountContext } from "../investment-account-context";

const accountTypeTranslateKeys: Record<EquitySavingPlanType, string> = {
  pea: "PEA",
  pea_pme: "PEA-PME",
};

export function UpdateInvestmentAccountModal() {
  const { user } = useUserBusiness();

  const { equitySavingPlan, isModalOpen, setIsModalOpen, setError } =
    useInvestmentAccountContext();

  const { t } = useTranslation();

  const toastError = () => {
    toast((content) => (
      <Alert
        close={content.closeToast}
        icon="Error"
        variant="error"
        title={t("organizations.update_organization.alert.error", {
          accountType: accountTypeTranslateKeys[equitySavingPlan.type],
        })}
      />
    ));
  };

  const { data: individualOrganization } = useGetOrganizationByIdQuery(
    user?.individualOrganization?.id,
    {
      fields: organizationRequestFields,
    },
  );

  const {
    mutate: createEquitySavingPlan,
    isPending: isCreateEquitySavingPlanLoading,
  } = useCreateOrganizationEquitySavingPlanMutation({
    onSuccess: () => {
      setIsModalOpen(false);
      toast((content) => (
        <Alert
          close={content.closeToast}
          icon="Checked"
          variant="success"
          title={t("organizations.update_organization.alert.success", {
            accountType: accountTypeTranslateKeys[equitySavingPlan.type],
          })}
        />
      ));
    },
    onError: (err) => {
      setError(err);
      toastError();
    },
  });

  const handleUpdateInvestmentAccountConfirmation = useCallback(() => {
    if (individualOrganization) {
      createEquitySavingPlan({
        organizationId: individualOrganization.id,
        data: {
          ...equitySavingPlan,
          accountNumber: equitySavingPlan.accountNumber.toString(),
        },
      });
    }
  }, [createEquitySavingPlan, equitySavingPlan, individualOrganization]);

  return (
    <Modal
      title={t("organizations.update_organization.modal.title")}
      confirmLabel={t("organizations.update_organization.modal.confirm_label")}
      isOpen={isModalOpen}
      setIsOpen={() => setIsModalOpen(false)}
      confirmAction={handleUpdateInvestmentAccountConfirmation}
      confirmButtonProps={{
        isLoading: isCreateEquitySavingPlanLoading,
      }}
      className="min-h-[605px] w-[calc(100%-32px)] sm:max-w-[654px]"
    >
      <div className="flex flex-col gap-y-4 p-4">
        <Typography
          variant="body3-regular"
          className="border-b border-dashed pb-4"
        >
          {t("organizations.update_organization.modal.desc")}
        </Typography>
        <div className="w-full sm:w-[251px]">
          <OrganizationTypeSelector
            options={[equitySavingPlan.type]}
            selectedValue={equitySavingPlan.type}
          />
        </div>
        <EquitySavingPlanForm />
      </div>
    </Modal>
  );
}
