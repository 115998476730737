import { Navigate, Route, Routes } from "react-router-dom";

import { ClubPage } from "../club-page";
import { ClubMediaContent } from "../components/club-media-content/club-media-content";
import { ClubModalitiesContent } from "../components/club-modalities-content/club-modalities-content";
import { ClubPresentationContent } from "../components/club-presentation-content/club-presentation-content";
import { ClubProjectsContent } from "../components/club-projects-content/club-projects-content";
import { ClubQaContent } from "../components/club-qa-content/club-qa-content";
import { ClubTeamContent } from "../components/club-team-content/club-team-content";
import { ClubThesisContent } from "../components/club-thesis-content/club-thesis-content";
import { ClubTrackRecordContent } from "../components/club-track-record-content/club-track-record-content";

export function ClubPageRouteWebapp() {
  return (
    <Routes>
      <Route element={<ClubPage />}>
        <Route path="presentation" element={<ClubPresentationContent />} />
        <Route path="thesis" element={<ClubThesisContent />} />
        <Route path="team" element={<ClubTeamContent />} />
        <Route path="track-record" element={<ClubTrackRecordContent />} />
        <Route path="fundraising" element={<ClubProjectsContent />} />
        <Route path="modalities" element={<ClubModalitiesContent />} />
        <Route path="media" element={<ClubMediaContent />} />
        <Route path="qa" element={<ClubQaContent />} />
        <Route path="*" element={<Navigate to="presentation" />} />
      </Route>
    </Routes>
  );
}
