import React from "react";

import { Project } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { AutoComplete } from "@tudigo-monorepo/web-tudigo-components";

type ProjectsAutocompleteProps = {
  projects: Project[];
  selectedProjectId: string | null;
  handleProjectSelect: (projectId: string) => void;
};

export function ProjectsAutocomplete(props: ProjectsAutocompleteProps) {
  const { projects, selectedProjectId, handleProjectSelect } = props;
  const { t } = useTranslation();

  return (
    <AutoComplete
      label={t(
        "investments.back_office.create_investment_page.form.projects_autocomplete.label",
      )}
      placeholder={t(
        "investments.back_office.create_investment_page.form.projects_autocomplete.placeholder",
      )}
      required
      errors={[]}
      items={projects.map((project) => ({
        id: project.id,
        name: project?.content?.name ?? "",
      }))}
      filterFn={(item, value) =>
        item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
      }
      selection={projects
        .map((project) => ({
          id: project.id,
          name: project?.content?.name ?? "",
        }))
        .find((project) => project.id === selectedProjectId)}
      onSelect={(value) => {
        handleProjectSelect(value.id);
      }}
      renderItem={(item: { id: string; name: string }) => (
        <div className="flex flex-row items-center gap-2">
          <div>{item.name}</div>
        </div>
      )}
      getId={(item) => item.id}
    />
  );
}
