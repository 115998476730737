import { createContext, ReactNode, useContext } from "react";

import { News } from "@tudigo-monorepo/core-tudigo-api-models";

const NewsItemContext = createContext<News | undefined>(undefined);

export const useNewsItem = () => {
  const context = useContext(NewsItemContext);
  if (context === undefined) {
    throw new Error("useNewsItem must be used within a NewsItemProvider");
  }

  return context;
};

export const NewsItemProvider = ({
  children,
  news,
}: {
  children: ReactNode;
  news: News;
}) => {
  return (
    <NewsItemContext.Provider value={news}>{children}</NewsItemContext.Provider>
  );
};
