import { Organization } from "@tudigo-monorepo/core-tudigo-api-models";
import { PMS_FILES } from "@tudigo-monorepo/core-tudigo-utils";
import { checkIfBankAccountRequirementsAreMet } from "@tudigo-monorepo/web-feature-kyc";

import { isOrganizationKycApproved } from "../utils/organization-kyc-utils";
import { useOrganizationChipProps } from "./use-organization-chip-props";

export function useOrganizationKycBankStatusChipProps(
  organization?: Organization,
) {
  const { validatedProps, toCompleteProps, rejectedProps } =
    useOrganizationChipProps();

  if (!organization) return;

  const isValidSection = checkIfBankAccountRequirementsAreMet(
    organization.organizationBankAccounts[0],
  );

  if (isOrganizationKycApproved(organization) || isValidSection) {
    return validatedProps;
  }

  const isErrorSection =
    organization.organizationBankAccounts[0]?.pmsFile?.status ===
    PMS_FILES.STATUS.REJECTED;

  return isErrorSection ? rejectedProps : toCompleteProps;
}
