import { useCallback, useDeferredValue, useState } from "react";

import { User } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useGetUsersQuery } from "@tudigo-monorepo/web-tudigo-api-client";

import { Button } from "../old/button";
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
  CommandLoading,
} from "./command";
import { Popover, PopoverContent, PopoverTrigger } from "./popover/popover";

export type UsersAutocompleteProps = {
  label?: string;
  emptyMessage?: string;
  placeholder?: string;
  className?: string;
  user?: User | null;
  onSelectUser: (user: User) => void;
};

export const UsersAutocomplete = (props: UsersAutocompleteProps) => {
  const { label, emptyMessage, placeholder, className, user, onSelectUser } =
    props;

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const deferredSearch = useDeferredValue(search);

  const { data, isLoading } = useGetUsersQuery({
    filters: {
      term: deferredSearch,
      appRole: [
        "admin",
        "wma",
        "investment_officer",
        "investor",
        "project_holder",
      ],
    },
    pathParams: { userId: user?.id ?? "me" },
    pagination: { limit: 10 },
    fields: "id,first_name,last_name,email,app_roles",
  });

  const { t } = useTranslation();

  const handleValueChange = (currentValue: string) => {
    setSearch(currentValue);
  };

  const handleUserChange = (item: User) => {
    setSelectedUser(item);
    onSelectUser(item);
    setOpen(false);
  };

  const getLabel = useCallback(() => {
    return (
      label ?? t("users.back_office.switch_user.modal.users_autocomplete.label")
    );
  }, [label, t]);

  const getTriggerLabel = useCallback(() => {
    if (selectedUser) {
      return `${selectedUser.firstName} ${selectedUser.lastName}`;
    }

    if (placeholder) {
      return placeholder;
    }

    return t(
      "users.back_office.switch_user.modal.users_autocomplete.placeholder",
    );
  }, [selectedUser, placeholder, t]);

  return (
    <div className="flex flex-col gap-0">
      <label>
        <p className="text-dark-1 font-monserrat text-[13px]">
          {getLabel()}
          <span className="text-error ml-1">*</span>
        </p>
      </label>

      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="tertiary"
            aria-expanded={open}
            className="!space-between w-full !justify-between rounded-lg"
            iconRight="ChevronDown"
            label={getTriggerLabel()}
          />
        </PopoverTrigger>
        <PopoverContent className={cn("p-0", className)}>
          <Command shouldFilter={false}>
            <CommandInput
              value={search}
              onValueChange={handleValueChange}
              placeholder={
                placeholder ??
                t(
                  "investments.back_office.create_investment_page.form.users_autocomplete.placeholder",
                )
              }
            />

            {isLoading && (
              <CommandList>
                <CommandLoading className="flex justify-center p-4">
                  {t(
                    "users.back_office.switch_user.modal.users_autocomplete.loading",
                  )}
                </CommandLoading>
              </CommandList>
            )}

            {!isLoading && (
              <CommandList>
                <CommandEmpty>
                  {emptyMessage ??
                    t(
                      "users.back_office.switch_user.modal.users_autocomplete.no_data_result",
                    )}
                </CommandEmpty>

                {data?.users.map((item) => (
                  <CommandItem
                    key={item.id}
                    value={item.id}
                    onSelect={() => handleUserChange(item)}
                  >
                    {item.firstName} {item.lastName} ({item.email})
                  </CommandItem>
                ))}
              </CommandList>
            )}
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};
