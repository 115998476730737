import { FormInput } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { Title, Typography } from "@tudigo-monorepo/web-tudigo-components";

import { useDynamicFormContext } from "../store/dynamic-form-context";

type InputsBlockProps = {
  inputs?: FormInput[] | null;
};

export const InputsBlock = (props: InputsBlockProps) => {
  const { inputs } = props;
  const context = useDynamicFormContext();
  const { inputPlugins } = context;
  if (!inputs || inputs.length === 0) return null;

  const getPluginByType = (type: string) => {
    return inputPlugins?.find((plugin) => plugin.type === type);
  };

  const renderInput = (input: FormInput) => {
    return getPluginByType(input.type!)?.renderingBlock(input, context);
  };

  return (
    <div
      className={cn(
        "dynamic-form-inputs-block grid grid-cols-1 gap-y-4 sm:gap-x-6",
        {
          "sm:grid-cols-2 sm:gap-x-16 sm:gap-y-8": inputs.length >= 3,
        },
      )}
    >
      {inputs.map((input, index) => (
        <div key={index} className="flex flex-col gap-y-4">
          {input.label ? <Title level="h2">{input.label}</Title> : null}
          {input.description ? (
            <Typography variant="body1">{input.description}</Typography>
          ) : null}
          {renderInput(input)}
        </div>
      ))}
    </div>
  );
};
