import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Chip, ShareButton } from "@tudigo-monorepo/web-tudigo-components";

import { useProjectContext } from "../project.context";

export const BlockProjectIntroTitle = () => {
  const project = useProjectContext();
  const { t } = useTranslation();

  if (!project) return null;

  return (
    <header className="mb-4 flex flex-col gap-2">
      <h2 className="font-brand text-3xl uppercase">{project.content?.name}</h2>
      <h3 className="font-semibold">{project.content?.shortDescription}</h3>
      <div className="flex flex-wrap items-center gap-3">
        <Chip label={t(project.mechanic)} type="secondary" />
        {project.tags?.map((tag) => (
          <Chip key={tag.code} label={tag.name} type="secondary" />
        ))}
        <div className="block flex-1 lg:hidden">
          <ShareButton className="ml-auto max-h-8 p-0" />
        </div>
      </div>
    </header>
  );
};
