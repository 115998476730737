import { Navigate, Route, Routes } from "react-router-dom";

import { KycApproved } from "../pages/kyc-page/content/kyc-approved";
import { KycBankAccountInformation } from "../pages/kyc-page/content/kyc-bank-account-information";
import { KycDocuments } from "../pages/kyc-page/content/kyc-documents/kyc-documents";
import { KycOrganizationInformation } from "../pages/kyc-page/content/kyc-organization-information/kyc-organization-information";
import { KycPersonalInformation } from "../pages/kyc-page/content/kyc-personal-information";
import { KycSummary } from "../pages/kyc-page/content/kyc-summary/kyc-summary";
import { KycWaitingApproval } from "../pages/kyc-page/content/kyc-waiting-approval";
import { KycWaitingManualApproval } from "../pages/kyc-page/content/kyc-waiting-manual-approval";
import { KycPage } from "../pages/kyc-page/kyc-page";

export function KYCRoute() {
  return (
    <Routes>
      <Route element={<KycPage />}>
        <Route
          path="personal-information"
          element={<KycPersonalInformation />}
        />
        <Route
          path="legal-information"
          element={<KycOrganizationInformation />}
        />
        <Route
          path="bank-account-information"
          element={<KycBankAccountInformation />}
        />
        <Route path="documents" element={<KycDocuments />} />
        <Route path="summary" element={<KycSummary />} />
        <Route path="waiting-approval" element={<KycWaitingApproval />} />
        <Route
          path="waiting-manual-approval"
          element={<KycWaitingManualApproval />}
        />
        <Route path="done" element={<KycApproved />} />
        <Route
          path="*?"
          element={<Navigate to="personal-information" replace />}
        />
      </Route>
    </Routes>
  );
}
