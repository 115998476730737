import { ChoicesSliderInput } from "@tudigo-monorepo/core-tudigo-api-models";

import { ChoicesSliderInputComponent } from "../components/inputs/choices-slider-input";
import { SelectInputComponent } from "../components/inputs/select-input";
import { fromSliderToSelect } from "../utils/form-inputs";
import { FormInputPlugin } from "./form-input-plugin";

const getDefaultValue = (input: ChoicesSliderInput) => {
  const choices = input.parameters?.choices || [];
  const areAllChoiceValuesNumbers =
    choices.filter((choice) => typeof choice.value !== "number").length === 0;

  if (areAllChoiceValuesNumbers) {
    return Math.min(...choices.map((choice) => choice.value as number));
  } else {
    return choices[0].value;
  }
};

export const FormChoiceSliderInputPlugin: FormInputPlugin<
  "choices_slider",
  ChoicesSliderInput
> = {
  type: "choices_slider",

  getDefaultValue: (input) => {
    return getDefaultValue(input);
  },

  renderingBlock: (input, context) => {
    const { sectionKey, answers, setAnswer } = context;
    if (!sectionKey) return;

    const selectedValue = answers[sectionKey][input.name as string] as
      | number
      | string;

    if (window.innerWidth < 640) {
      const selectInput = fromSliderToSelect(input);
      if (selectInput) {
        return (
          <SelectInputComponent
            input={selectInput}
            selectedValue={selectedValue}
            onChange={(value) => {
              setAnswer(sectionKey, input, value);
            }}
          />
        );
      }
    }

    return (
      <ChoicesSliderInputComponent
        input={input}
        selectedValue={selectedValue ?? getDefaultValue(input)}
        onChange={(value) => {
          setAnswer(sectionKey, input, value);
        }}
      />
    );
  },
  canAutoSubmit: false,
  defaultValueIfNull: true,
};
