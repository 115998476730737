import { PropsWithChildren } from "react";

import { Investment } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  formatCurrency,
  INVESTMENTS,
} from "@tudigo-monorepo/core-tudigo-utils";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  MetricsCard,
  MetricsCardProps,
} from "@tudigo-monorepo/web-tudigo-components";

import { InvestmentQuickActionsMenu } from "../../../shared/components/investment-quick-actions-menu";
import { getInvestmentPaymentMethodTypeTranslateKey } from "../../../shared/utils/investment-utils";

type MetricsInvestmentCardProps = {
  investment: Investment;
  index: number;
};

export function MetricsInvestmentCard(
  props: PropsWithChildren<MetricsInvestmentCardProps>,
) {
  const { investment, index } = props;

  const { isAdmin } = useUserBusiness();

  const { t } = useTranslation();

  const getMetricCardFooterProps = (): MetricsCardProps["footer"] => {
    switch (investment.globalStatus) {
      case INVESTMENTS.GLOBAL_STATUS.VALIDATED:
        return {
          text:
            t(
              `my-investments.my_investment_page.metrics.investment_card.payment_method_information.${investment.paymentMethodType}`,
            ) + new Date(investment.paymentDate as string).toLocaleDateString(),
        };
      case INVESTMENTS.GLOBAL_STATUS.WAITING_FOR_SIGNATURE:
      case INVESTMENTS.GLOBAL_STATUS.WAITING_FOR_PAYMENT:
        return {
          text: `${t(
            "investments.investment_metric_card.footer.text.payment_method.label",
          )}: ${t(getInvestmentPaymentMethodTypeTranslateKey(investment))}`,
          cta: {
            text: t(
              "investments.investment_metric_card.footer.cta_text.finalize",
            ),
          },
        };
      default:
        return undefined;
    }
  };

  const getInvestmentNextStepPage = (): string | undefined => {
    if (isAdmin) {
      return;
    }

    if (
      ["waiting_for_payment", "waiting_for_signature"].includes(
        investment.globalStatus,
      )
    ) {
      return `/projects/${investment.project.id}/invest/${investment.id}`;
    }
  };

  return (
    <MetricsCard
      value={formatCurrency(investment?.totalAmountInCents ?? 0)}
      className="h-full"
      contextualMenu={<InvestmentQuickActionsMenu investment={investment} />}
      heading={{
        firstChip: {
          label: investment?.investorOrganization?.name ?? "Compte à choisir",
        },
        secondChip: {
          label: `#${index + 1}`,
          type: "info",
        },
      }}
      title={t(
        "my-investments.my_investment_page.metrics.investment_card.title",
      )}
      content={{
        sideChip: {
          label: t(`investment.global_status.${investment.globalStatus}`),
          icon: "DotChip",
          type: ["validated", "transfer_funds_failed"].includes(
            investment.globalStatus,
          )
            ? "success"
            : "warning",
          count: false,
        },
      }}
      footer={getMetricCardFooterProps()}
      navigateTo={getInvestmentNextStepPage()}
    />
  );
}
