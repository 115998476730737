import { useNavigate } from "react-router-dom";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { CircleCard } from "@tudigo-monorepo/web-tudigo-components";

export const CircleListingMyCircleContent = () => {
  const { user } = useUserBusiness();
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (!user) {
    return (
      <p className="text-dark-1">
        {t("circles_listing_page.my_circle_tab.not_authenticated_message")}
      </p>
    );
  }

  if (user.myCircles && user.myCircles.length > 0) {
    return (
      <div className="grid grid-cols-1 gap-6 lg:grid-cols-2 xl:grid-cols-3 ">
        {user.myCircles.map((circle) => (
          <CircleCard
            circle={circle}
            key={circle.id}
            onClick={() => navigate(`/clubs-v2/${circle.id}/presentation`)}
          />
        ))}
      </div>
    );
  }

  return <div>{t("circles_listing_page.my_circle_tab.no_result")}</div>;
};
