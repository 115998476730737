import React from "react";

import { Project } from "@tudigo-monorepo/core-tudigo-api-models";
import { formatCurrency, PROJECTS } from "@tudigo-monorepo/core-tudigo-utils";
import {
  Chip,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

export function ProjectMetricsCard(props: { project: Project }) {
  const { project } = props;

  const fundraisingProgress =
    project?.goal?.minInCents &&
    project.totalAmountCollectedInCents > project.goal.minInCents
      ? project.fundraisingProgressMaxGoal
      : project.fundraisingProgressMinGoal;

  const projectStatusLabel = () => {
    switch (project.status) {
      case PROJECTS.STATUS.STARTED:
        return "En cours";
      case PROJECTS.STATUS.FINISHED:
        return "Terminé";
      case PROJECTS.STATUS.PREFINISHED:
        return "Terminé";
      default:
        return "En cours";
    }
  };

  const projectStatusChipType = () => {
    switch (project.status) {
      case PROJECTS.STATUS.STARTED:
        return "waiting";
      case PROJECTS.STATUS.FINISHED:
        return "info";
      case PROJECTS.STATUS.PREFINISHED:
        return "info";
      default:
        return "waiting";
    }
  };

  return (
    <div className="border-light-2 relative z-10 flex flex-col gap-2 rounded-lg border bg-white p-6 drop-shadow-2xl">
      <Chip label={projectStatusLabel()} type={projectStatusChipType()} />
      <Title level="h4">La levée de fonds</Title>
      <div className="flex items-center gap-6">
        <Title>{formatCurrency(project.totalAmountCollectedInCents)}</Title>
        <div className="flex items-center gap-2">
          <Chip label={`${fundraisingProgress}%`} type="info" />
          <Typography variant="body3-regular">de l&apos;objectif</Typography>
        </div>
      </div>
    </div>
  );
}
