import { isArray } from "lodash";

import {
  ActionTypes,
  FormPageAction,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { Button, IconName } from "@tudigo-monorepo/web-tudigo-components";

import {
  DynamicFormContextType,
  useDynamicFormContext,
} from "../store/dynamic-form-context";
import { ActionStateEnum } from "../store/dynamic-form-provider";

type DynamicFormActionsProps = {
  action: FormPageAction;
  className?: string;
};

export function shouldHideSubmitButton(
  action: FormPageAction,
  context: DynamicFormContextType,
): boolean {
  const { currentPage, answers, inputPlugins } = context;

  return !!(
    action.type === ActionTypes.SUBMIT &&
    currentPage.sectionKey &&
    currentPage.inputs?.length === 1 &&
    inputPlugins?.find((plugin) => plugin.type === currentPage.inputs![0].type)
      ?.canAutoSubmit &&
    answers[currentPage.sectionKey][currentPage.inputs![0].name!] === null
  );
}

export function ActionButton(props: DynamicFormActionsProps) {
  const context = useDynamicFormContext();
  const {
    actionsBinding,
    currentPage,
    currentPageIndex,
    answers,
    goToPage,
    submit,
    inputPlugins,
    actionsState,
  } = context;

  const { action, className } = props;

  const actionBinding = actionsBinding?.[action.type];

  if (shouldHideSubmitButton(action, context)) {
    return;
  }

  // if there is a visible condition, and it's not met, we don't want to show the button
  if (
    actionBinding?.visibleCondition &&
    !actionBinding.visibleCondition(context)
  ) {
    return null;
  }

  const isSubmitDisabled = (): boolean | undefined => {
    return !!(
      action.type === ActionTypes.SUBMIT &&
      currentPage.sectionKey &&
      currentPage.inputs?.some((input) => {
        const value = answers[currentPage.sectionKey!][input.name!];

        return (
          ((isArray(value) && value.length === 0) || value == null) &&
          !inputPlugins?.find((plugin) => plugin.type === input.type)
            ?.defaultValueIfNull
        );
      })
    );
  };

  const handleSubmit = () => {
    submit(answers);
    goToPage(currentPageIndex + 1);
  };

  const buttonIcon: IconName | undefined =
    action.type === ActionTypes.SUBMIT || action.type === ActionTypes.CONFIRM
      ? "ChevronRight"
      : undefined;

  if (action.type === ActionTypes.SUBMIT) {
    return (
      <Button
        className={className}
        label={action.label ?? ""}
        onClick={handleSubmit}
        disabled={isSubmitDisabled()}
        isLoading={action.isLoading}
        iconRight={buttonIcon}
      />
    );
  }

  return (
    <Button
      className={className}
      label={action.label ?? ""}
      variant={action.type === ActionTypes.DECLINE ? "ghost" : "primary"}
      onClick={() => {
        if (action?.onClick) {
          action?.onClick(answers);
        } else {
          actionBinding?.onClick(answers);
        }
      }}
      disabled={
        (actionBinding?.visibleCondition &&
          !actionBinding.visibleCondition(context)) ||
        actionsState[action.type] === ActionStateEnum.DISABLED
      }
      isLoading={action.isLoading}
      iconRight={buttonIcon}
    />
  );
}
