import { CMSFrequentlyAskedQuestion } from "@tudigo-monorepo/core-tudigo-api-models";
import { FaqBlock } from "@tudigo-monorepo/web-tudigo-components";

type FrequentlyAskedQuestionBlockProps = {
  block: CMSFrequentlyAskedQuestion;
};
export const FrequentlyAskedQuestionBlock = ({
  block,
}: FrequentlyAskedQuestionBlockProps) => {
  return (
    <FaqBlock
      items={block.data.elements}
      className="border-light-1 rounded-lg border bg-white p-6"
    />
  );
};
