import { Navigate, Route, Routes } from "react-router-dom";

import { ClubLeaderMembersPage } from "../club-leader-members-page";
import { ClubLeaderMembersAllContent } from "../components/club-leader-members-all-content";
import { ClubLeaderMembersPremiumContent } from "../components/club-leader-members-premium-content";

export function ClubLeaderMembersRouteWebapp() {
  return (
    <Routes>
      <Route path="*" element={<ClubLeaderMembersPage />}>
        <Route path="all-members" element={<ClubLeaderMembersAllContent />} />
        <Route
          path="premium-members"
          element={<ClubLeaderMembersPremiumContent />}
        />
        <Route path="*" element={<Navigate to="all-members" />} />
      </Route>
    </Routes>
  );
}
