import React from "react";
import { useNavigate } from "react-router-dom";

import { useCreateConsentMutation } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  ContractConsentForm,
  PageHead,
} from "@tudigo-monorepo/web-tudigo-components";

import { useInvestmentFunnelPageContext } from "../../../pages/investment-funnel-page";
import { ExpandedInvestmentDetailCard } from "../../shared/expanded-investment-detail-card";

export function InvestmentFunnelAgreementAndSecurityContent() {
  const { ongoingInvestment, project, invalidateOngoingInvestment } =
    useInvestmentFunnelPageContext();
  const navigate = useNavigate();

  const { mutateAsync: createConsent, isPending: isLoading } =
    useCreateConsentMutation();

  if (!project || !project.projectAgreementAndSecurityContract) {
    return null;
  }

  const handleSubmit = () => {
    if (!project.projectAgreementAndSecurityContract) {
      return;
    }
    createConsent({
      data: {
        contract: {
          code: project.projectAgreementAndSecurityContract.code,
          version: project.projectAgreementAndSecurityContract?.version,
        },
      },
    }).then(async () => {
      await invalidateOngoingInvestment();
      navigate(`/projects/${project.id}/invest/${ongoingInvestment?.id}`);
    });
  };

  return (
    <div>
      <ExpandedInvestmentDetailCard />
      <PageHead title="Consentement et sécurité" className="py-4" />
      <div className="border-light-2 mb-6 border-b" />
      <ContractConsentForm
        contract={project.projectAgreementAndSecurityContract}
        onConsent={handleSubmit}
        btnIsLoading={isLoading}
        userConsent={
          (ongoingInvestment &&
            ongoingInvestment.projectAgreementAndSecurityConsent) ??
          undefined
        }
      />
    </div>
  );
}
