import { EquitySavingPlanType } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  Modal,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

type ReplaceInvestmentAccountConfirmationModalProps = {
  accountType: EquitySavingPlanType | undefined;
  open: boolean;
  onCancel: (open: boolean) => void;
  onConfirm: () => void;
};

export function ReplaceInvestmentAccountConfirmationModal({
  accountType,
  open,
  onCancel,
  onConfirm,
}: ReplaceInvestmentAccountConfirmationModalProps) {
  const { t } = useTranslation();

  const formattedAccountType =
    (accountType === "pea_pme" ? "PEA-PME" : "PEA") || undefined;

  return (
    <Modal
      isOpen={open}
      setIsOpen={() => onCancel(false)}
      className="min-h-[605px] w-[calc(100%-32px)] sm:max-w-[654px]"
      title={t(
        "my_account.web_app.my_profile_page.organizations.replace_account_confirmation_modal.title",
      )}
      negativeLabel={t(
        "my_account.web_app.my_profile_page.organizations.replace_account_confirmation_modal.cancel",
      )}
      negativeAction={() => onCancel(false)}
      negativeButtonProps={{ variant: "ghost" }}
      confirmLabel={t(
        "my_account.web_app.my_profile_page.organizations.replace_account_confirmation_modal.confirm",
      )}
      confirmAction={onConfirm}
      confirmButtonProps={{ isLoading: false }}
    >
      <div className="flex flex-col gap-y-4 p-4">
        <Title level="h6" h3Brand="brand-2">
          {t(
            "my_account.web_app.my_profile_page.organizations.replace_account_confirmation_modal.subtitle",
            {
              accountType: formattedAccountType,
            },
          )}
        </Title>
        <Typography variant="caption1-regular">
          {t(
            "my_account.web_app.my_profile_page.organizations.replace_account_confirmation_modal.desc",
            {
              accountType: formattedAccountType,
            },
          )}
        </Typography>
      </div>
    </Modal>
  );
}
