import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import {
  getResourceFromTudigoResponse,
  TudigoError,
} from "@tudigo-monorepo/core-tudigo-api";
import { WMAInvestment } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient, getQueryDetailKey } from "../../../";

type GetWmaInvestmentByIdParams = {
  wmaInvestmentId: string | undefined;
  query?: { fields?: string };
};

export const getWmaInvestmentById = async (
  params: GetWmaInvestmentByIdParams,
) => {
  const apiClient = getApiClient();
  const response = await apiClient.apiV1.wmaInvestments.getWmaInvestmentById(
    params!.wmaInvestmentId!,
    params?.query,
  );

  return getResourceFromTudigoResponse(response.data);
};

export const useGetWmaInvestmentById = (
  params: GetWmaInvestmentByIdParams,
  options?: Omit<UseQueryOptions<WMAInvestment, TudigoError>, "queryKey">,
) =>
  useQuery({
    queryKey: getQueryDetailKey({
      key: QUERY_KEYS.INVESTMENTS,
      id: params.wmaInvestmentId!,
      fields: params?.query?.fields,
    }),
    queryFn: () => getWmaInvestmentById(params),
    ...options,
  });
