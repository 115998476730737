import { SharesProject } from "@tudigo-monorepo/core-tudigo-api-models";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";

import { InfoCardItem } from "../../../../../shared/components/info-card-item";

export function SharesProjectModalities(props: { project: SharesProject }) {
  const { project } = props;

  return (
    <>
      <InfoCardItem
        label="Rendement cible"
        value={
          project.sharesMechanicSettings?.targetedYield
            ? `x${
                project.sharesMechanicSettings.targetedYield.minInPercent / 100
              } - x${
                project.sharesMechanicSettings.targetedYield.maxInPercent / 100
              }`
            : "Non défini"
        }
      />
      <InfoCardItem
        label="Defiscalisation"
        value={
          project.taxExemptionMechanics
            ? project.taxExemptionMechanics?.map((tax) => tax).join(", ")
            : "Non défini"
        }
      />
      <InfoCardItem
        label="Ticket minimum"
        value={formatCurrency(
          project.sharesMechanicSettings.minimumTicketAmountInCents,
        )}
      />
    </>
  );
}
