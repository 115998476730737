import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { RepaymentPeriod } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import {
  CollectionRequestParams,
  getApiClient,
  getQueryListKey,
} from "../../../";

type GetProjectDebtorRepaymentsPeriodsParams = {
  projectId: string | undefined;
  queryFields?: string;
};

export const getProjectDebtorRepaymentsPeriods = async (
  params: GetProjectDebtorRepaymentsPeriodsParams,
) => {
  const apiClient = getApiClient();

  if (!params.projectId) {
    throw new Error("projectId is required");
  }

  const response =
    await apiClient.apiV1.repayments.getProjectDebtorRepaymentsPeriods(
      params?.projectId,
      { fields: params?.queryFields },
    );

  return response.data;
};

export const useGetProjectDebtorRepaymentsPeriods = (
  params: CollectionRequestParams<
    GetProjectDebtorRepaymentsPeriodsParams,
    undefined
  >,
  options?: UseQueryOptions<TudigoResponse<RepaymentPeriod[]>, TudigoError>,
) => {
  return useQuery({
    queryKey: getQueryListKey({
      key: QUERY_KEYS.DEBTOR_REPAYMENTS_PERIODS,
      filters: {
        ...params.pathParams,
      },
      fields: params.fields,
    }),
    queryFn: () =>
      getProjectDebtorRepaymentsPeriods({
        projectId: params.pathParams?.projectId,
        queryFields: params.fields,
      }),
    enabled: params.pathParams?.projectId !== "new",
    ...options,
  });
};
