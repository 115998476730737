import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { WMACustomer } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { CollectionRequestParams, getApiClient } from "../../../index";
import { getQueryListKey } from "../../query-key-factory";

type GetWMACustomersInvitationsParams = CollectionRequestParams<
  undefined,
  undefined
>;

export const getWMACustomersInvitations = async (
  params: GetWMACustomersInvitationsParams,
) => {
  const apiClient = getApiClient();
  const { pagination, fields } = params;

  const response =
    await apiClient.apiV1.wmaCustomers.getWMACustomersInvitations({
      fields: fields,
      offset: pagination?.offset,
      limit: pagination?.limit,
    });

  return response.data;
};

export const useGetWMACustomersInvitationsQuery = (
  params: GetWMACustomersInvitationsParams,
  options?: UseQueryOptions<TudigoResponse<WMACustomer[]>, TudigoError>,
) =>
  useQuery({
    queryKey: getQueryListKey({
      key: QUERY_KEYS.WMA_CUSTOMERS,
      fields: params?.fields,
      filters: params?.filters,
    }),
    queryFn: () => getWMACustomersInvitations(params),
    ...options,
  });
