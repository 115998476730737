import { ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { getAuthClient } from "@tudigo-monorepo/core-tudigo-api";
import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { Button, Icon } from "@tudigo-monorepo/web-tudigo-components";

import { TrustpilotMicroStarWidget } from "./trustpilot-micro-star-widget";

function ListItem({ children }: { children: ReactNode }) {
  return (
    <li
      className="flex items-center gap-3
        "
    >
      <Icon
        name="Checked"
        primaryColor={themeColors.success}
        bgColor={themeColors["success-light"]}
        className="rounded-full"
        size="S"
      />
      {children}
    </li>
  );
}

export function LimitedAccess({
  hideGradient = false,
}: {
  hideGradient?: boolean;
}) {
  const { isQualifiedOrWma, isConnected } = useUserBusiness();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const authUrl = getAuthClient().authorizeUrl();

  const addRegisterQueryToUrl = (url: string) => {
    const urlObject = new URL(url);
    urlObject.searchParams.append("register", "1");

    return urlObject.toString();
  };

  const setProjectTargetUrl = () => {
    localStorage.setItem("target-url", pathname);
  };

  const logIn = () => {
    setProjectTargetUrl();
    window.location.replace(authUrl);
  };
  const signup = () => {
    setProjectTargetUrl();
    window.location.replace(addRegisterQueryToUrl(authUrl));
  };
  const getToQualification = () => navigate("/qualification");

  return (
    <article className="border-light-1 text-primary shadow-card relative mt-6 rounded-3xl border bg-white p-8">
      {!hideGradient && (
        <div className="-white pointer-events-none absolute -left-5 right-0 top-[-102px] h-[100px] w-[110%] bg-gradient-to-t from-white to-transparent" />
      )}
      {!isConnected && (
        <h1 className="font-brand text-primary mb-2 text-lg">Inscrivez-vous</h1>
      )}
      {!isQualifiedOrWma && isConnected && (
        <h1 className="font-brand text-primary mb-2 text-lg">
          Complétez votre profil
        </h1>
      )}
      <p className="mb-6 text-sm">
        Les informations détaillées ne peuvent être communiquées qu’aux
        personnes ayant complété leur profil investisseur
      </p>
      <ul className="mb-6 space-y-3 text-sm font-medium text-gray-900">
        <ListItem>Découvrir en détail les opportunités</ListItem>
        <ListItem>Investir dans une startup à impact</ListItem>
        <ListItem>Rejoindre une communauté dynamique</ListItem>
        <ListItem>Faire fructifier votre épargne</ListItem>
      </ul>
      {!isConnected && (
        <div className="mb-6 flex gap-3">
          <Button label={`S'inscrire`} onClick={signup} />
          <Button variant="tertiary" label="Se connecter" onClick={logIn} />
        </div>
      )}
      {!isQualifiedOrWma && isConnected && (
        <div className="mb-6 flex gap-3">
          <Button label="Compléter mon profil" onClick={getToQualification} />
        </div>
      )}
      <footer className="border-light-1 flex justify-end border-t pt-6">
        <TrustpilotMicroStarWidget />
      </footer>
    </article>
  );
}
