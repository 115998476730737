import {
  EquitySavingPlan,
  type Bank,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  FormContextProvider,
  getSmartFormComponents,
} from "@tudigo-monorepo/web-feature-form";
import { Typography } from "@tudigo-monorepo/web-tudigo-components";

import { checkIfEquitySavingPlanBankIsOffline } from "../../utils/equity-saving-plans-utils";
import { BankSelector } from "../bank-selector/bank-selector";
import { BankAdvisorContactFields } from "./bank-advisor-contact-fields";
import { useInvestmentAccountContext } from "./investment-account-context";

type PickBankInformationsFormKeys = Pick<
  EquitySavingPlan,
  "accountNumber" | "iban" | "bic"
>;

export function EquitySavingPlanForm() {
  const { t } = useTranslation();

  const { SmartTextField } =
    getSmartFormComponents<PickBankInformationsFormKeys>();

  const { error, equitySavingPlan, setEquitySavingPlan } =
    useInvestmentAccountContext();

  return (
    <FormContextProvider
      formData={equitySavingPlan}
      setFormData={setEquitySavingPlan}
      error={error}
    >
      <form className="border-light-1 w-full border-t pt-1.5">
        <div className="flex w-full flex-col gap-y-1.5 sm:w-[251px]">
          <Typography variant="body3-medium" className="text-dark-2">
            {t(
              "my_account.web_app.my_profile_page.organizations.equity_saving_plan_form.title",
            )}
          </Typography>
          <BankSelector
            required
            contentClassName="sm:w-[250px]"
            selectedBank={equitySavingPlan.bank}
            onBankChange={(bank) => {
              setEquitySavingPlan({ ...equitySavingPlan, bank: bank as Bank });
            }}
          />
          <SmartTextField
            name="accountNumber"
            label={t(
              "my_account.web_app.my_profile_page.organizations.equity_saving_plan_form.account_number",
            )}
            placeholder="00000000000"
            required
          />
          <SmartTextField
            name="iban"
            label={t(
              "my_account.web_app.my_profile_page.organizations.equity_saving_plan_form.iban",
            )}
            placeholder="FR00 0000 0000 0000 0000 0000 000"
          />
          <SmartTextField
            name="bic"
            label={t(
              "my_account.web_app.my_profile_page.organizations.equity_saving_plan_form.bic",
            )}
            placeholder="00000000"
          />
        </div>
        {checkIfEquitySavingPlanBankIsOffline(equitySavingPlan) && (
          <BankAdvisorContactFields />
        )}
      </form>
    </FormContextProvider>
  );
}
