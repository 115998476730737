import React from "react";
import { useNavigate } from "react-router-dom";

import {
  Answers,
  FormPage,
  InternalKycStatus,
  InvestorProfile,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  useInvalidateConnectedUser,
  useUpdateInvestorProfileMutation,
} from "@tudigo-monorepo/web-tudigo-api-client";
import { gtmTrackEvent } from "@tudigo-monorepo/web-tudigo-tracking";

import { InvestmentCapacitySummaryPage } from "../content/investment-capacity-summary-page";
import { InvestorProfileSummaryPage } from "../content/investor-profile-summary-page";

export const useQualificationSummaryPages = (
  setShowEndDialog: (show: boolean) => void,
  investorProfile?: InvestorProfile,
) => {
  const { user } = useUserBusiness();
  const { mutate: updateInvestorProfile } = useUpdateInvestorProfileMutation();
  const navigate = useNavigate();

  const invalidateUsersQuery = useInvalidateConnectedUser();

  if (investorProfile === undefined) return [];
  if (user === null) return [];

  const onQualificationConfirmed = () => {
    if (
      user.individualOrganization?.internalKycStatus ===
      InternalKycStatus.SHOULD_BE_COMPLETED
    ) {
      invalidateUsersQuery();
      setShowEndDialog(true);
    } else {
      invalidateUsersQuery();
      navigate("/dispatch");
    }
  };

  return [
    {
      inputs: [],
      index: 0,
      id: `summary-page-capacity`,
      title: null,
      actions: [
        {
          type: "submit",
          label: "Voir les résultats",
        },
      ],
      sectionKey: "financial_situation",
      rendering: [
        {
          id: "1",
          type: "react",
          children: (
            <InvestmentCapacitySummaryPage
              key="capacity"
              investorProfile={investorProfile}
            />
          ),
          data: null,
        },
      ],
    },
    {
      inputs: [],
      index: 0,
      id: `summary-page-investor-profile`,
      title: null,
      actions: [
        {
          type: "confirm",
          label: "Sauvegarder mon profil",
          onClick: (answers: Answers) => {
            updateInvestorProfile(
              {
                userId: user?.id ?? "me",
                investorProfile: {
                  qualificationData: answers,
                  confirmed: true,
                  beAdvisedOptIn: true,
                },
              },
              {
                onSuccess: () => {
                  gtmTrackEvent("ui.investor_profile.completed", {
                    email: user?.email,
                  });
                  onQualificationConfirmed();
                },
              },
            );
          },
        },
        {
          type: "decline",
          label: "Ne pas bénéficier de recommandations",
          onClick: (answers: Answers) => {
            updateInvestorProfile(
              {
                userId: user?.id ?? "me",
                investorProfile: {
                  qualificationData: answers,
                  confirmed: true,
                  beAdvisedOptIn: false,
                },
              },
              {
                onSuccess: () => {
                  gtmTrackEvent("ui.investor_profile.completed");
                  onQualificationConfirmed();
                },
              },
            );
          },
        },
      ],
      rendering: [
        {
          type: "react",
          children: (
            <InvestorProfileSummaryPage
              key="investor-profile"
              investorProfile={investorProfile}
            />
          ),
          data: null,
        },
      ],
    },
  ] as FormPage[];
};
