import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Button } from "@tudigo-monorepo/web-tudigo-components";

import { useInvestmentAccountContext } from "../investment-account-context";

export const UpdateInvestmentAccountModalTrigger = () => {
  const { t } = useTranslation();
  const { onModalOpenChange } = useInvestmentAccountContext();

  return (
    <Button
      onClick={() => onModalOpenChange(true)}
      variant="primary"
      className="w-full sm:w-[213px]"
      label={t(
        "my_account.web_app.my_profile_page.organizations.investment_account_page.edit_informations",
      )}
    />
  );
};
