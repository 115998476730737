import React from "react";

import { FaqBlock } from "@tudigo-monorepo/web-tudigo-components";

export function SharesFaqBlock() {
  return (
    <FaqBlock
      className="border-light-1 rounded-lg border p-6"
      items={[
        {
          question: "Quels sont les frais liés à l'investissement ?",
          answer:
            // eslint-disable-next-line no-multi-str
            "Investir sur Tudigo ne comporte aucun frais pour l'investisseur. Tudigo ne se rémunère qu'au travers de frais fixes et d’une commission sur le montant de la levée de fonds supportés par l’entreprise porteuse du projet. Il n’y a donc aucun frais lié à votre prise de participation.  Elle est entièrement gratuite.",
        },
        {
          question: "Quels sont les moyens de paiement sur Tudigo ?",
          answer:
            "Sur Tudigo, vous pouvez participer à une collecte par carte bleue, par virement bancaire ou par virement PEA ou PEA-PME ! Ces modes de paiement sont assurés par notre prestataire bancaire Lemonway : Par carte bancaire : sécurisé en 3D secure. Vous devrez renseigner vos informations comme pour un paiement en e-commerce. Le paiement est instantané et sera directement visible sur la page de la collecte et dans la jauge s'il est réussi. Par virement bancaire : sécurisé, valable uniquement dans la zone SEPA. Tudigo vous fournit un RIB unique qui vous est propre. Une fois le virement réalisé par vos soins, la réception des fonds peut prendre jusqu'à 10 jours et le paiement sera validé à réception des fonds par Lemonway. Par virement PEA ou PEA-PME : pour les projets éligibles, un dossier vous sera transmis que vous devrez déposer auprès de votre établissement bancaire. Pour toute précision sur ce sujet, rendez-vous sur la FAQ du site Tudigo.",
        },
        {
          question: "Comment sont sélectionnés les projets ?",
          answer:
            "Les projets éligibles à une campagne en capital doivent respecter les critères de Tudigo, mettant l'accent sur un impact positif fort, tant sur le plan économique que social et environnemental. Les campagnes en capital sur notre plateforme sont ouvertes pour une levée de fonds minimum de 200 000 € et un plafond de 5 000 000 €. \n" +
            "Nous sélectionnons des projets portés par des entrepreneurs engagés, à différents stades de développement, partageant une expérience solide et une motivation exceptionnelle. Ces entrepreneurs aspirent à impliquer des investisseurs particuliers et professionnels désireux de donner du sens à leur épargne. \n" +
            "Pour plus d’informations, rendez-vous sur la FAQ sur le site Tudigo.",
        },
      ]}
    />
  );
}
