import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient, getQueryListKey } from "../../..";

type UnsubscribeClubParam = {
  clubMemberId: string;
};

export const unsubscribeClub = async (params: UnsubscribeClubParam) => {
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.clubs.unsubscribeClubMembers(params);

  return response.data;
};

export const useUnsubscribeClub = (
  options?: UseMutationOptions<
    TudigoResponse<boolean>,
    TudigoError,
    UnsubscribeClubParam
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params) => unsubscribeClub(params),
    ...options,
    onSuccess: (...params) => {
      queryClient.invalidateQueries({
        queryKey: getQueryListKey({
          key: QUERY_KEYS.MY_CLUB_MEMBERS,
        }),
      });

      options?.onSuccess?.(...params);
    },
  });
};
