import React from "react";
import { Link } from "react-router-dom";

import {
  isComingProject,
  Project,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";

import { ProjectCardMechanicSettings } from "./project-card-mechanic-settings";
import { ProjectCardRebrandBadges } from "./project-card-rebrand-badges";
import { ProjectCardRebrandCover } from "./project-card-rebrand-cover";
import { ProjectCardRebrandFooter } from "./project-card-rebrand-footer";
import { ProjectClubIconRebrand } from "./project-club-icon-rebrand";
import { ProjectFlagRebrand } from "./projet-flag-rebrand";

type ProjectCardProps = {
  project: Project;
  className?: string;
  navigateTo?: string;
  smallCard?: boolean;
  target?: string;
};

interface WrapperProjectCardProps
  extends React.PropsWithChildren,
    ProjectCardProps {}

const WrapperProjectCard = ({
  navigateTo,
  target,
  project,
  className,
  children,
}: WrapperProjectCardProps) => {
  const isProjectPageLink = !!navigateTo && !isComingProject(project);

  const commonProps = {
    className,
  };

  if (isProjectPageLink) {
    return (
      <Link to={navigateTo!} target={target} {...commonProps}>
        {children}
      </Link>
    );
  } else {
    return <div {...commonProps}>{children}</div>;
  }
};
const ProjectCardContent = (props: ProjectCardProps) => {
  const { project, smallCard } = props;
  const { content } = project;
  const { isQualifiedOrWma } = useUserBusiness();

  return (
    <>
      <ProjectCardRebrandCover projectContent={content}>
        <ProjectFlagRebrand
          className="absolute bottom-3.5 left-3 mb-1 inline-flex w-auto"
          project={project}
        />
        {project.club && <ProjectClubIconRebrand club={project.club} />}
      </ProjectCardRebrandCover>

      <div className="flex h-full flex-col justify-between gap-y-6 bg-transparent px-6 py-6">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col items-start">
            {content?.name && (
              <h2 className="text-black-primary mb-2 truncate text-2xl font-semibold">
                {content?.name}
              </h2>
            )}
            <ProjectCardRebrandBadges project={project} smallCard />
          </div>

          {content?.shortDescription && (
            <p className="text-black-900 line-clamp-3 h-[54px] text-sm leading-tight">
              {content?.shortDescription}
            </p>
          )}
          {!smallCard && (
            <div className="bg-black-400 border-black-500 mb-6 h-36 rounded-md border px-4 py-4">
              <ProjectCardMechanicSettings
                project={project}
                isLimitedAccess={!isQualifiedOrWma}
              />
            </div>
          )}
        </div>
        <ProjectCardRebrandFooter project={project} />
      </div>
    </>
  );
};

export function ProjectCardRebrand(props: ProjectCardProps) {
  const { className } = props;

  return (
    <WrapperProjectCard
      {...props}
      className={cn(
        "w-full min-w-[294px]",
        "flex flex-col",
        "overflow-hidden rounded-lg bg-white",
        "border-black-600 border",
        "duration-300 ease-in-out",
        "font-roobert",
        "hover:bg-black-800",
        "hover:shadow-[0_2px_10px_0px_rgba(13,13,12,0.05)]",
        className,
      )}
    >
      <ProjectCardContent {...props} />
    </WrapperProjectCard>
  );
}
