import React from "react";

import {
  getProjectMechanicSettings,
  isBondsProject,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";
import {
  Chip,
  Collapse,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { useInvestmentFunnelPageContext } from "../../pages/investment-funnel-page";

export function ExpandedInvestmentDetailCard() {
  const { project, ongoingInvestment } = useInvestmentFunnelPageContext();

  if (!project || !ongoingInvestment) {
    return null;
  }

  const { minimumTicketAmountInCents, unitPriceInCents } =
    getProjectMechanicSettings(project);

  const collapseContent = (
    <div className="flex flex-col items-center gap-4">
      <Title level="h4">{project.content?.name}</Title>
      <div className="relative h-[138px] w-full">
        <img
          src={project.content?.cover?.url ?? ""}
          alt="project cover"
          className="absolute inset-0 h-full w-full rounded-lg object-cover"
        />
      </div>
      <div className="border-success-medium flex w-full justify-between rounded-lg border p-4">
        <Title level="h4">Montant de l&apos;investissement</Title>
        <Title level="h4" className="text-success">
          {formatCurrency(ongoingInvestment.totalAmountInCents)}
        </Title>
      </div>
      <div className="flex w-full flex-col gap-1">
        <div className="flex justify-between">
          <Typography variant="body3-regular">Ticket minimum</Typography>
          <Typography variant="body3-regular">
            {formatCurrency(minimumTicketAmountInCents)}
          </Typography>
        </div>
        <div className="flex justify-between">
          <Typography variant="body3-regular">
            Prix d&apos;une {isBondsProject(project) ? "obligation" : "action"}
          </Typography>
          <Typography variant="body3-regular">
            {formatCurrency(unitPriceInCents)}
          </Typography>
        </div>
        <div className="flex w-full justify-between">
          <Typography variant="body3-regular">
            Mon nombre d&apos;
            {isBondsProject(project) ? "obligations" : "actions"}
          </Typography>
          <Typography variant="body3-regular">
            {ongoingInvestment.totalAmountInCents /
              ongoingInvestment.unitPriceInCents}
          </Typography>
        </div>
      </div>
    </div>
  );

  return (
    <div className="expanded-investment-detail-card">
      <Collapse items={[collapseContent]} clickOutsideEnabled={false}>
        <div className="flex items-center justify-items-start gap-4 p-1">
          <div className="relative h-[38px] w-[38px] rounded-full">
            <img
              src={project.content?.cover?.url ?? ""}
              alt="project logo"
              className="absolute inset-0 h-full w-full rounded-full object-cover"
            />
          </div>
          <Typography variant="body3-regular">
            {project.content?.name}
          </Typography>
          <Chip
            label={formatCurrency(ongoingInvestment.totalAmountInCents)}
            type="success"
          />
        </div>
      </Collapse>
    </div>
  );
}
