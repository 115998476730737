import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import {
  getResourceFromTudigoResponse,
  TudigoError,
} from "@tudigo-monorepo/core-tudigo-api";
import { ClubV2 } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient, getQueryDetailKey } from "../../../index";

type GetCircleByIdQueryParams = {
  clubId: string;
  fields?: string;
};

export type GetCircleByIdQueryOptions = Omit<
  UseQueryOptions<ClubV2, TudigoError>,
  "queryKey"
>;

export const getClubV2ById = async (params: GetCircleByIdQueryParams) => {
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.clubs.getClubV2ById({
    clubId: params.clubId,
    query: {
      fields: params?.fields ?? "",
    },
  });

  return getResourceFromTudigoResponse(response.data);
};

export const useGetCircleById = (
  params: GetCircleByIdQueryParams,
  options?: GetCircleByIdQueryOptions,
) => {
  const { fields, clubId } = params ?? {};

  return useQuery({
    queryKey: getQueryDetailKey({
      key: QUERY_KEYS.CLUBS,
      id: clubId,
      fields,
    }),
    queryFn: () => getClubV2ById({ clubId, fields }),
    ...options,
  });
};
