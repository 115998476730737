import { useNavigate } from "react-router-dom";

import { Button, Typography } from "@tudigo-monorepo/web-tudigo-components";

export function RetryPage() {
  const navigate = useNavigate();

  const handleClickRetry = () => {
    navigate(-1);
  };

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-4">
      <Typography variant="body1">
        Something went wrong, try to retry
      </Typography>
      <Button
        className="items-center"
        label="Retry"
        onClick={handleClickRetry}
      />
    </div>
  );
}
