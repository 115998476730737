import React, { useEffect, useState } from "react";

import { BondProjectSimulatorQuery } from "@tudigo-monorepo/core-tudigo-api-models";
import { SimulatorBondsRepayments } from "@tudigo-monorepo/web-feature-projects";
import { useGetSimulatorByProject } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { useInvestmentFunnelPageContext } from "../../../../../pages/investment-funnel-page";

type ForecastRepaymentPeriodsSectionProps = {
  totalAmountInCents: number;
};

export function ForecastRepaymentPeriodsSection(
  props: ForecastRepaymentPeriodsSectionProps,
) {
  const { project } = useInvestmentFunnelPageContext();
  const { totalAmountInCents } = props;

  const [simulatorForm, setSimulatorForm] = useState<BondProjectSimulatorQuery>(
    {
      amountInCents: totalAmountInCents,
    },
  );

  useEffect(() => {
    setSimulatorForm({
      amountInCents: totalAmountInCents,
    });
  }, [totalAmountInCents]);

  const { data } = useGetSimulatorByProject(
    {
      projectId: project?.id,
      query: simulatorForm,
    },
    {
      enabled: true,
    },
  );

  if (project.mechanic !== "bonds") {
    return null;
  }

  return (
    <div className="tax-exemption-eligibility my-8">
      <Title level="h2">Échéancier prévisionnel de remboursement</Title>

      <div className="border-light-2 my-4 border-b" />

      <Typography variant="body3-regular">
        Ce document vous permet de visualiser facilement l’échéance à laquelle
        interviendront les remboursements perçus suite à votre investissement.
        Notons que ce document possède un caractère prévisionnel et peut évoluer
        selon la santé financière de l’entreprise dans laquelle vous souhaitez
        investir.
      </Typography>
      {(totalAmountInCents >=
        project.bondsMechanicSettings.minimumTicketAmountInCents && (
        <SimulatorBondsRepayments data={data} />
      )) || (
        <Alert
          size="F"
          variant="info"
          icon="Info"
          description="Le montant de l'investissement est inférieur au montant minimum requis pour afficher l'échéancier de remboursement."
          className="my-4"
        />
      )}
    </div>
  );
}
