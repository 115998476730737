import React from "react";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";
import {
  Button,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { ProjectMetricsCard } from "../../../../components/project-metric-card";
import { useEditWmaInvestmentPageContext } from "../../../../edit-wma-investment-page";

export function EditWmaInvestmentPaymentTabWaitingWireSection() {
  const { investment } = useEditWmaInvestmentPageContext();
  const project = investment.project;
  const { t } = useTranslation();

  const virtualIban = investment?.investorOrganization?.pmsAccount?.virtualIban;

  return (
    <div className="m-6">
      <Title level="h4">Procéder au paiement : Virement</Title>
      <Typography variant="body2">
        Retrouvez-ci dessous le RIB sur lequel votre client doit effectuer le
        virement
      </Typography>
      <div className="wire-detail border-light-1 my-6 rounded-lg border">
        <div className="head p-6">
          <Typography variant="body2">
            {t(
              "investment_funnel.final_page.waiting_wire.instructions.label.rib",
            )}
          </Typography>
        </div>
        <div className="flex flex-col">
          <div className="title border-light-1 w-full border-b"></div>
          <div className="flex flex-col gap-2 p-6">
            <div className="row flex justify-between">
              <Typography variant="body3-regular">
                {t(
                  "investment_funnel.final_page.waiting_wire.instructions.label.project",
                )}
              </Typography>
              <Typography variant="body3-semibold">
                {project.content?.name}
              </Typography>
            </div>
            <div className="row flex justify-between">
              <Typography variant="body3-regular">
                {t(
                  "investment_funnel.final_page.waiting_wire.instructions.label.company_name",
                )}
              </Typography>
              <Typography variant="body3-semibold">
                {project.vehicleOrganization?.name}
              </Typography>
            </div>
            <div className="row flex justify-between">
              <Typography variant="body3-regular">
                {t(
                  "investment_funnel.final_page.waiting_wire.instructions.label.payment_method",
                )}
              </Typography>
              <Typography variant="body3-semibold">Virement</Typography>
            </div>
            <div className="row flex justify-between">
              <Typography variant="body3-regular">
                {t(
                  "investment_funnel.final_page.waiting_wire.instructions.label.amount",
                )}
              </Typography>
              <Typography variant="body3-semibold">
                {formatCurrency(investment.totalAmountInCents)}
              </Typography>
            </div>
            <div className="row flex justify-between">
              <Typography variant="body3-regular">
                {t(
                  "investment_funnel.final_page.waiting_wire.instructions.label.iban",
                )}
              </Typography>
              <Typography variant="body3-semibold">
                {virtualIban?.iban}
              </Typography>
            </div>
            <div className="row flex justify-between">
              <Typography variant="body3-regular">
                {t(
                  "investment_funnel.final_page.waiting_wire.instructions.label.bic",
                )}
              </Typography>
              <Typography variant="body3-semibold">
                {virtualIban?.bic}
              </Typography>
            </div>
          </div>

          {virtualIban?.pdfDocument.signedUrl && (
            <Button
              label={t(
                "investment_funnel.final_page.waiting_wire.instructions.label.download_rib_button",
              )}
              variant="primary"
              iconRight="Download"
              className="mx-6 mb-6 w-fit"
              onClick={() =>
                window.open(virtualIban.pdfDocument.signedUrl as string)
              }
            />
          )}
        </div>
      </div>
      <ProjectMetricsCard project={project} />
    </div>
  );
}
