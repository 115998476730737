import { Organization } from "@tudigo-monorepo/core-tudigo-api-models";
import { PageEnum, useSpaceBusiness } from "@tudigo-monorepo/tudigo-session";
import { OrganizationKycListElement } from "@tudigo-monorepo/web-feature-organizations";
import { useGetUserOrganizationsQuery } from "@tudigo-monorepo/web-tudigo-api-client";
import { TudigoCollection } from "@tudigo-monorepo/web-tudigo-collection";

interface MyProfileOrganizationListProps {
  userId?: string;
  type?: Organization["type"];
}

export function MyProfileOrganizationList(
  props: MyProfileOrganizationListProps,
) {
  const { currentPagePaths } = useSpaceBusiness();

  return (
    <TudigoCollection
      useQueryFn={useGetUserOrganizationsQuery}
      pathParams={{ userId: props.userId ?? "me" }}
      filters={{ type: props.type }}
      fields="id,name,type,internal_kyc_status"
      columns={[]}
      render={(table) => {
        return table
          .getRowModel()
          .rows?.map(({ original: organization }) => (
            <OrganizationKycListElement
              key={organization.id}
              organization={organization}
              navigateTo={currentPagePaths[
                PageEnum.ORGANIZATION_KYC_VIEWER
              ].replace(":organizationId", organization.id)}
            />
          ));
      }}
    />
  );
}
