import { ReactNode } from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import {
  BlogCarousel,
  DiscordCard,
  HighlightsCard,
} from "@tudigo-monorepo/web-tudigo-components";

type PromotionalBlocksProps = {
  className?: string;
  children?: ReactNode;
};

const PromotionalBlocks = ({ className, children }: PromotionalBlocksProps) => {
  return (
    <section
      className={cn("flex w-full flex-col items-center gap-y-6", className)}
    >
      {children}
      <div className="flex w-full flex-col gap-6 sm:flex-row">
        <DiscordCard />
        <HighlightsCard />
      </div>
      <BlogCarousel />
    </section>
  );
};

export { PromotionalBlocks };
