import { Project } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

export function useGetInvestmentPageTabs(project?: Project) {
  const { t } = useTranslation();

  return [
    {
      name: t(
        "my-investments.my_investment_page.tab-navigation.item.label.information",
      ),
      redirectTo: "information",
    },
    {
      name: t(
        "my-investments.my_investment_page.tab-navigation.item.label.news",
      ),
      redirectTo: "news",
    },
    {
      name: t(
        "my-investments.my_investment_page.tab-navigation.item.label.documents",
      ),
      redirectTo: "documents/all",
    },
    {
      name: t(
        "my-investments.my_investment_page.tab-navigation.item.label.assemblies",
      ),
      redirectTo: "assemblies",
    },
    // {
    //   name: t(
    //     "my-investments.my_investment_page.tab-navigation.item.label.statistics",
    //   ),
    //   redirectTo: "statistics",
    // },
    ...(project?.mechanic === "bonds"
      ? [
          {
            name: t(
              "my-investments.my_investment_page.tab-navigation.item.label.repayments",
            ),
            redirectTo: "repayments",
          },
        ]
      : []),
  ];
}
