import { Organization } from "@tudigo-monorepo/core-tudigo-api-models";
import { checkIfOrganizationRepresentativeAddressRequirementsAreMet } from "@tudigo-monorepo/web-feature-kyc";

import { isOrganizationKycApproved } from "../utils/organization-kyc-utils";
import { useOrganizationChipProps } from "./use-organization-chip-props";

export function useOrganizationKycAddressStatusChipProps(
  organization?: Organization,
) {
  const { toCompleteProps } = useOrganizationChipProps();

  if (!organization) return;

  const isValidSection =
    checkIfOrganizationRepresentativeAddressRequirementsAreMet(
      organization.organizationRepresentative,
    );

  if (isOrganizationKycApproved(organization) || isValidSection) return;

  return toCompleteProps;
}
