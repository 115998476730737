import { ReactNode, useLayoutEffect, useRef } from "react";
import { Placement } from "@popperjs/core";
import { usePopper } from "react-popper";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { useToggler } from "../shared/hooks/use-toggler";
import { Title } from "../title";
import { Typography } from "../typography";

type TooltipProps = {
  title?: string;
  description?: string;
  children: ReactNode;
  placement?: Placement;
  childrenClassName?: string;
  tooltipClassname?: string;
};

/**
 * Tooltip component to display additional information on hover.
 * @module Tooltip
 * @param {string} props.title - The title of the tooltip.
 * @param {string} props.description - The description content of the tooltip.
 * @param {ReactNode} props.children - The content to which the tooltip is attached.
 * @param {Placement} [props.placement="auto"] - The placement of the tooltip relative to its children.
 * @param {string} [props.childrenClassName] - Additional class names for the children element.
 * @param {string} [props.tooltipClassname] - Additional class names for the tooltip element.
 * @returns {JSX.Element} - The Tooltip component.
 */
export function Tooltip(props: TooltipProps) {
  const {
    title,
    description,
    children,
    placement = "auto",
    childrenClassName,
    tooltipClassname,
  } = props;

  const [isOpen, , setIsOpen] = useToggler();

  const referenceElement = useRef(null);
  const popperElement = useRef(null);
  const arrowElement = useRef(null);

  const { styles, attributes, update } = usePopper(
    referenceElement.current,
    popperElement.current,
    {
      placement,
      strategy: "absolute",
      modifiers: [
        {
          name: "arrow",
          options: { element: arrowElement.current, padding: 10 },
        },
        { name: "offset", options: { offset: [0, 15] } },
      ],
    },
  );

  useLayoutEffect(() => {
    update?.();
  }, [isOpen, update]);

  return (
    <>
      <div
        onMouseOut={() => setIsOpen(false)}
        onMouseOver={() => setIsOpen(true)}
        ref={referenceElement}
        className={cn("cursor-pointer bg-white ", childrenClassName)}
      >
        {children}
      </div>
      {title ||
        (description && (
          <div
            className={cn(
              "shadow-card z-50 flex w-full max-w-[300px] flex-col gap-2 rounded-lg bg-white p-4",
              tooltipClassname,
              { hidden: !isOpen },
            )}
            style={styles.popper}
            ref={popperElement}
            role="tooltip"
            {...attributes.popper}
          >
            {title && <Title level="h4">{title}</Title>}
            {description && (
              <Typography variant="body2">{description}</Typography>
            )}
            <div
              ref={arrowElement}
              style={styles.arrow}
              className="tooltip_arrow"
            />
          </div>
        ))}
    </>
  );
}
