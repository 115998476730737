import { Navigate, Route, Routes } from "react-router-dom";

import { WmaDashboardContent } from "../../pages/wma/content/wma-dashboard-content";
import { WmaDashboardPage } from "../../pages/wma/wma-dashboard-page";

export function WmaDashboardRoute() {
  return (
    <Routes>
      <Route element={<WmaDashboardPage />}>
        <Route path="" element={<WmaDashboardContent />} />
        <Route path="*" element={<Navigate to="" />} />
      </Route>
    </Routes>
  );
}
