import { useEffect, useMemo, useState } from "react";
import useDebouncedCallback from "@restart/hooks/useDebouncedCallback";
import numeral from "numeral";
import { useLocation } from "react-router-dom";

import {
  BondProjectSimulatorQuery,
  BondsProject,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";
import { useGetSimulatorByProject } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  CurrencyInput,
  InputSlider,
} from "@tudigo-monorepo/web-tudigo-components";
import {
  currentEnvEventsName,
  HubspotEventEnum,
  hubspotTrackEvent,
} from "@tudigo-monorepo/web-tudigo-tracking";

import { InvestButton } from "../../../../../shared/components/invest-button";
import { useProjectContext } from "../../../project.context";
import { SimulatorPlaygroundBlock } from "../components/simulator-playground-block";
import { SimulatorResultsBlock } from "../components/simulator-results-block";
import { SimulatorRoiBlock } from "../components/simulator-roi-block";
import { SimulatorBondsRepayments } from "./simulator-bonds-repayments";

export function SimulatorBonds() {
  const { t } = useTranslation();
  const project = useProjectContext() as BondsProject;
  const location = useLocation();
  const [isReady, setIsReady] = useState(false);

  const [simulatorForm, setSimulatorForm] = useState<BondProjectSimulatorQuery>(
    {
      amountInCents: 100000,
    },
  );

  const setFormValue = (key: string, value: string | number) => {
    setSimulatorForm((prev) => ({ ...prev, [key]: value }));
  };

  const { data: simulatorData } = useGetSimulatorByProject(
    {
      projectId: project?.id,
      query: simulatorForm,
    },
    {
      enabled: isReady,
    },
  );

  useEffect(() => {
    if (project) {
      setSimulatorForm({
        amountInCents:
          location.state?.amountInCents ??
          project.bondsMechanicSettings?.minimumTicketAmountInCents,
      });
      setIsReady(true);
    }
  }, [project]);

  const handleSliderChange = useDebouncedCallback((e) => {
    hubspotTrackEvent(
      currentEnvEventsName[HubspotEventEnum.PROJECT_SIMULATION_DONE],
      {
        project_id: project?.id,
        project_slug: project?.content?.slug,
        amount: e?.target?.value,
      },
    );
    setFormValue("amountInCents", e?.target?.value * 100);
  }, 150);

  const maxValue = project.goal?.maxInCents
    ? project.goal?.maxInCents / 100
    : 2000000;

  const underMinimumErrors = useMemo(() => {
    if (
      simulatorForm?.amountInCents <
      project?.bondsMechanicSettings?.minimumTicketAmountInCents
    ) {
      return [
        `Le montant minimum est de ${formatCurrency(
          project?.bondsMechanicSettings?.minimumTicketAmountInCents,
        )}`,
      ];
    }

    return [];
  }, [
    simulatorForm?.amountInCents,
    project?.bondsMechanicSettings?.minimumTicketAmountInCents,
  ]);

  if (!project) return null;

  return (
    <>
      <div className="flex flex-col overflow-hidden md:flex-row">
        <SimulatorPlaygroundBlock>
          <article>
            <h2 className="text-primary mb-3 font-bold">{`Montant de l'investissement`}</h2>
            <CurrencyInput
              value={simulatorForm?.amountInCents}
              errors={underMinimumErrors}
              onChange={(number) => setFormValue("amountInCents", number)}
            />
            <InputSlider
              valueChip={false}
              min={
                project?.bondsMechanicSettings?.minimumTicketAmountInCents / 100
              }
              max={maxValue}
              step={project?.bondsMechanicSettings?.unitPriceInCents / 100}
              className="-mt-[10px]"
              value={simulatorForm.amountInCents / 100}
              onChange={handleSliderChange}
            />
            <footer className="-mt-[15px] flex justify-between">
              <div>Ticket minimum</div>
              <div>
                {formatCurrency(
                  project?.bondsMechanicSettings?.minimumTicketAmountInCents,
                )}
              </div>
            </footer>
          </article>

          <article className="flex flex-col gap-2">
            <div className="flex justify-between">
              <h2>Type d’obligation</h2>
              <span className="font-semibold">
                {t(
                  `repayment_type.${project.bondsMechanicSettings?.repaymentType}`,
                )}
              </span>
            </div>

            <div className="flex justify-between">
              <h2>Taux d’intérêt annuel brut</h2>
              <span className="font-semibold">
                {numeral(
                  project.bondsMechanicSettings?.annualInterestNoDecimalRate /
                    10000,
                ).format("0%")}
              </span>
            </div>

            <div className="flex justify-between">
              <h2>Durée du prêt</h2>
              <span className="font-semibold">
                {project.bondsMechanicSettings?.durationInMonth} mois
              </span>
            </div>

            <div className="flex justify-between">
              <h2>Remboursement</h2>
              <span className="font-semibold">
                {t(
                  `repayment_frequency.${project.bondsMechanicSettings?.repaymentFrequency}`,
                )}
              </span>
            </div>
          </article>
        </SimulatorPlaygroundBlock>

        <SimulatorResultsBlock>
          <div className="flex justify-between">
            <h2>Montant investi</h2>
            <span className="font-semibold">
              {formatCurrency(simulatorForm.amountInCents)}
            </span>
          </div>
          <hr className="border-light-1 border" />

          <div className="flex justify-between">
            <h2>Montant remboursé</h2>
            <span className="font-semibold">
              {formatCurrency(Number(simulatorData?.repaymentInCents))}
            </span>
          </div>
          <div className="mb-4 flex justify-between">
            <h2>Intérêts perçus</h2>
            <span className="font-semibold">
              {formatCurrency(Number(simulatorData?.interestsInCents))}
            </span>
          </div>

          <SimulatorRoiBlock filters={simulatorForm} />
          <InvestButton className="mt-2" />
        </SimulatorResultsBlock>
      </div>

      <SimulatorBondsRepayments data={simulatorData} />
    </>
  );
}
