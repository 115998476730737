import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import queryString from "query-string";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { PatchOrganizationRequestBody } from "@tudigo-monorepo/core-tudigo-api";
import {
  CompanyInformation,
  OrganizationPerson,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  PageEnum,
  useSpaceBusiness,
  useUserBusiness,
} from "@tudigo-monorepo/tudigo-session";
import { usePatchOrganization } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  Button,
  PageHead,
  Title,
} from "@tudigo-monorepo/web-tudigo-components";

import { useKYCContext } from "../../kyc-page";
import {
  checkIfCompanyInformationRequirementsAreMet,
  checkIfUltimateBeneficialOwnersFormDataRequirementsAreMet,
  getDefaultCompanyInformationModel,
} from "../../utils/kyc-utils";
import { BeneficialOwnersForm } from "./partials/beneficial-owners-form";
import { CompanyInformationForm } from "./partials/company-information-form";

export function KycOrganizationInformation() {
  const { organization, organizationQueryKey } = useKYCContext();
  const { user } = useUserBusiness();
  const userId = user?.id as string;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { currentPagePaths } = useSpaceBusiness();
  const kycPagePath = currentPagePaths[
    PageEnum.ORGANIZATION_KYC_FUNNEL
  ].replace(":organizationId", organization.id);

  const [searchParams] = useSearchParams();
  const investProjectUrl =
    (queryString.parse(searchParams.toString())?.investProjectUrl as string) ??
    "";

  const [companyInformationFormData, setCompanyInformationFormData] =
    useState<CompanyInformation>(
      organization.companyInformation ?? getDefaultCompanyInformationModel(),
    );

  const [beneficialOwnersFormData, setBeneficialOwnersFormData] = useState<
    OrganizationPerson[]
  >(organization.ultimateBeneficialOwners ?? []);

  const {
    mutateAsync: patchOrganization,
    isPending: patchOrganizationIsPending,
  } = usePatchOrganization({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: organizationQueryKey,
      });
      toast((content) => {
        return (
          <Alert
            icon="Checked"
            variant="success"
            size="S"
            close={content.closeToast}
            title={t(
              "kyc_funnel.step_company_information.alert_on_success.title",
            )}
          />
        );
      });
      if (investProjectUrl) {
        return navigate(investProjectUrl);
      }

      navigate(`${kycPagePath}/bank-account-information`, {
        replace: true,
      });
    },
  });

  if (!organization.organizationRepresentative) {
    return null;
  }

  const handleSubmit = () => () => {
    const formatedBeneficialOwners: unknown = beneficialOwnersFormData.map(
      (owner) => {
        const { id, ...rest } = owner;

        const newOwner = id === "" ? rest : { id, ...rest };

        return {
          ...newOwner,
          organization: { id: organization.id },
          user: { id: userId },
        };
      },
    );

    const formatedBodyRequest: PatchOrganizationRequestBody = {
      id: organization.id,
      name: organization.name,
      companyInformation: {
        ...companyInformationFormData,
        capitalInCents: companyInformationFormData.capitalInCents,
      },
      ultimateBeneficialOwners:
        formatedBeneficialOwners as OrganizationPerson[],
    };

    patchOrganization(formatedBodyRequest);
  };

  return (
    <>
      <PageHead
        chipsTop={[
          {
            label: t("kyc_funnel.step_company_information.page.chip.label"),
          },
        ]}
        title={t("kyc_funnel.step_company_information.page.title")}
        subtitle={t("kyc_funnel.step_company_information.page.subtitle")}
        className="py-4"
        withEndLine
      />
      <div className="mx-6">
        <div className="flex flex-col gap-6 py-6">
          <div className="company-information">
            <Title h3Brand="brand-2" level="h3" className="text-primary">
              {t(
                "kyc_funnel.step_company_information.section.company_information.title",
              )}
            </Title>
            <CompanyInformationForm
              companyInformationFormData={companyInformationFormData}
              setCompanyInformationFormData={setCompanyInformationFormData}
            />
          </div>
        </div>

        <div className="beneficial-owners">
          <Title h3Brand="brand-2" level="h3" className="text-primary">
            {t(
              "kyc_funnel.step_company_information.section.ultimate_beneficial_owners.title",
            )}
          </Title>
          <Title level="subtitle">
            {t(
              "kyc_funnel.step_company_information.section.ultimate_beneficial_owners.subtitle",
            )}
          </Title>
          <div className="flex w-full flex-col gap-4 py-6">
            <BeneficialOwnersForm
              beneficialOwnersFormData={beneficialOwnersFormData}
              setBeneficialOwnersFormData={setBeneficialOwnersFormData}
              organizationRepresentative={
                organization.organizationRepresentative
              }
            />
          </div>
        </div>

        <div className="border-light-1 fixed bottom-0 left-0 z-20 flex h-[80px] w-full items-center justify-center gap-x-4 border-t bg-white px-6 sm:h-[104px]">
          <Button
            isLoading={patchOrganizationIsPending}
            disabled={
              !checkIfUltimateBeneficialOwnersFormDataRequirementsAreMet(
                beneficialOwnersFormData,
              ) ||
              !checkIfCompanyInformationRequirementsAreMet(
                companyInformationFormData,
              ) ||
              !organization.name
            }
            className="w-fit px-12"
            variant="primary"
            iconRight="ArrowRight"
            label={t("kyc_funnel.form.button.next.label")}
            onClick={handleSubmit()}
          />
        </div>
      </div>
    </>
  );
}
