import { Navigate, Route, Routes } from "react-router-dom";

import { SpaceEnum, SpaceProvider } from "@tudigo-monorepo/tudigo-session";
import { WmaDashboardRoute } from "@tudigo-monorepo/web-feature-dashboard";
import { InvestmentRouteWebapp } from "@tudigo-monorepo/web-feature-investments";
import { KYCRoute } from "@tudigo-monorepo/web-feature-kyc";
import { MyProfileRoute } from "@tudigo-monorepo/web-feature-my-profile";
import { MySettingsRoute } from "@tudigo-monorepo/web-feature-my-settings";
import { ProjectRoutesWebApp } from "@tudigo-monorepo/web-feature-projects";
import { WmaCustomersRoutesWebApp } from "@tudigo-monorepo/web-feature-wma-customers";
import { WmaInvestmentsRoutesWebApp } from "@tudigo-monorepo/web-feature-wma-investments";
import {
  ConnectedLayout,
  useWmaNavigation,
} from "@tudigo-monorepo/web-tudigo-layout";

export const WmaRoutes = () => {
  return (
    <SpaceProvider currentSpace={SpaceEnum.WMA}>
      <Routes>
        <Route
          path={"organization/:organizationId/kyc/*"}
          element={<KYCRoute />}
        />
        <Route path="projects?/*" element={<ProjectRoutesWebApp />} />
        <Route element={<ConnectedLayout navigation={useWmaNavigation()} />}>
          <Route path="dashboard/*" element={<WmaDashboardRoute />} />
          <Route path="customers/*" element={<WmaCustomersRoutesWebApp />} />
          <Route
            path="investments/*"
            element={<WmaInvestmentsRoutesWebApp />}
          />
          <Route path="projects/*" element={<InvestmentRouteWebapp />} />
          <Route path="profile-and-accounts/*" element={<MyProfileRoute />} />
          <Route path="my-settings/*" element={<MySettingsRoute />} />
          <Route path="*" element={<Navigate to="/wma/dashboard" />} />
        </Route>
      </Routes>
    </SpaceProvider>
  );
};
