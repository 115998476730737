import {
  OrganizationDocument,
  OrganizationPersonDocument,
} from "@tudigo-monorepo/core-tudigo-api-models";

export enum DocumentTypes {
  IDENTITY_CARD = "identity_card",
  IDENTITY_CARD_FRONT_SIDE = "identity_card_front_side",
  IDENTITY_CARD_BACK_SIDE = "identity_card_back_side",
  EUROPEAN_PASSPORT = "european_passport",
  EUROPEAN_PASSPORT_FRONT_SIDE = "european_passport_front_side",
  EUROPEAN_PASSPORT_BACK_SIDE = "european_passport_back_side",
  OUTSIDE_EUROPEAN_PASSPORT = "outside_european_passport",
  OUTSIDE_EUROPEAN_PASSPORT_FRONT_SIDE = "outside_european_passport_front_side",
  OUTSIDE_EUROPEAN_PASSPORT_BACK_SIDE = "outside_european_passport_back_side",
  PROOF_OF_ADDRESS = "proof_of_address",
  PROOF_OF_BANK_ACCOUNT = "proof_of_bank_account",
  RESIDENCE_PERMIT = "residence_permit",
  DRIVING_LICENSE = "driving_license",
  STATUS = "status",
  COMPANY_REGISTRATION = "company_registration",
  UBO_REGISTER = "ubo_register",
  SELFIE = "selfie",
  OTHER = "other",
}

type OrganizationDocumentUnion =
  | OrganizationDocument
  | OrganizationPersonDocument;

export const getDefaultDocumentName = (
  type: OrganizationDocumentUnionType,
): string => {
  switch (type) {
    case DocumentTypes.IDENTITY_CARD:
    case DocumentTypes.IDENTITY_CARD_FRONT_SIDE:
    case DocumentTypes.IDENTITY_CARD_BACK_SIDE:
    case DocumentTypes.EUROPEAN_PASSPORT:
    case DocumentTypes.EUROPEAN_PASSPORT_FRONT_SIDE:
    case DocumentTypes.EUROPEAN_PASSPORT_BACK_SIDE:
    case DocumentTypes.OUTSIDE_EUROPEAN_PASSPORT:
    case DocumentTypes.OUTSIDE_EUROPEAN_PASSPORT_FRONT_SIDE:
    case DocumentTypes.OUTSIDE_EUROPEAN_PASSPORT_BACK_SIDE:
      return "document_identité.pdf";
    case DocumentTypes.PROOF_OF_ADDRESS:
      return "justificatif_de_domicile.pdf";
    case DocumentTypes.PROOF_OF_BANK_ACCOUNT:
      return "rib.pdf";
    case DocumentTypes.RESIDENCE_PERMIT:
      return "titre_de_séjour.pdf";
    case DocumentTypes.DRIVING_LICENSE:
      return "permis_de_conduire.pdf";
    case DocumentTypes.COMPANY_REGISTRATION:
      return "extrait_kbis.pdf";
    case DocumentTypes.UBO_REGISTER:
      return "registre_ubo.pdf";
    case DocumentTypes.STATUS:
      return "statut_juridique.pdf";
    case DocumentTypes.SELFIE:
      return "selfie.pdf";
    case DocumentTypes.OTHER:
    default:
      return "document.pdf";
  }
};

type OrganizationDocumentUnionType = OrganizationDocumentUnion["type"];

const isDocumentEmpty = (
  organizationDocument: OrganizationDocumentUnion,
): boolean => {
  return Object.keys(organizationDocument.document).length === 0;
};

const sanitizeDocumentName = (name: string): string => {
  return name.trim();
};

export const getDocumentName = (
  organizationDocument: OrganizationDocumentUnion,
): string => {
  if (isDocumentEmpty(organizationDocument)) {
    return "";
  }

  const originalFilename = sanitizeDocumentName(
    organizationDocument.document?.originalFilename ?? "",
  );

  if (!originalFilename) {
    return getDefaultDocumentName(organizationDocument.type);
  }

  return originalFilename;
};
