import React from "react";
import { useIntl } from "react-intl";
import { Outlet, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  AssemblyVoteRequestAnswer,
  AssemblyVoteRequestBody,
} from "@tudigo-monorepo/core-tudigo-api";
import {
  Answers,
  Form,
  FormPage,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useAssemblyVoteRequestMutation } from "@tudigo-monorepo/web-tudigo-api-client";
import { Alert } from "@tudigo-monorepo/web-tudigo-components";
import { DynamicForm } from "@tudigo-monorepo/web-tudigo-dynamic-forms";

import { useMyInvestmentAssemblyPageContext } from "../investment-assembly-page/my-investment-assembly-page";

export function MyInvestmentAssemblyVoteFormPage() {
  const { project, assembly, assemblyParticipant } =
    useMyInvestmentAssemblyPageContext();
  const navigate = useNavigate();
  const intl = useIntl();

  const returnToDetailPage = () => {
    navigate(
      `/my-account/my-investments/projects/${project.id}/assemblies/${assembly.id}/assembly-participant/${assemblyParticipant.id}/detail/vote`,
    );
  };

  const pages: FormPage[] | undefined = assembly.topics.map((topic, index) => {
    return {
      id: topic.id ?? "",
      title: `Résolution ${index + 1}`,
      index: index + 1,
      actions: [],
      inputs: [
        {
          id: topic.id ?? "",
          name: topic.id ?? "",
          label: null,
          type: "select",
          position: 1,
          description: null,
          parameters: {
            choices: topic.choices.map((choice) => {
              return {
                label: intl.formatMessage({
                  id: `assembly_vote_form.choice.label.${choice}`,
                }),
                value: choice,
                bullet: null,
              };
            }),
            maxChoices: null,
            initialValue: null,
          },
        },
      ],
      rendering: [
        {
          id: "1",
          type: "expended-paragraph",
          data: {
            title: `Résolution ${index + 1}`,
            content: topic.content,
          },
        },
        {
          id: "2",
          type: "heading",
          data: {
            title: `Résolution ${index + 1}`,
            level: 1,
            caption: null,
          },
        },
        {
          id: "3",
          type: "inputs",
          data: null,
        },
      ],
    };
  });

  const form: Form = {
    id: assembly.id ?? "",
    name: assembly.name,
    locale: "fr",
    status: null,
    lock: true,
    sections: [
      {
        id: assembly.id ?? "",
        name: "Vote" ?? "",
        key: "assembly_vote_answers",
        position: 0,
        pages: pages ?? [],
      },
      {
        id: "signature",
        name: "Signature",
        key: "assembly_vote_signature",
        position: 1,
        pages: [],
      },
    ],
  };

  const { mutate: assemblyVoteRequest, isPending: requestIsLoading } =
    useAssemblyVoteRequestMutation();

  const submitFormVote = (answers: Answers) => {
    const answersFormated: AssemblyVoteRequestAnswer[] = [];
    Object.entries(answers.assembly_vote_answers).forEach(([key, value]) => {
      answersFormated.push({
        assembly_topic: {
          id: key as string,
        },
        choices: [value as string],
      });
    });

    const formatedVoteRequestData: AssemblyVoteRequestBody = {
      assemblyParticipant: {
        id: assemblyParticipant.id,
      },
      answers: answersFormated,
    };

    assemblyVoteRequest(
      {
        assemblyId: assembly.id,
        data: formatedVoteRequestData,
      },
      {
        onSuccess() {
          toast((content) => (
            <Alert
              icon="Upload"
              close={content.closeToast}
              variant="success"
              size="S"
              title={intl.formatMessage({
                id: "assembly_vote_form.alert.success.title",
              })}
              description={intl.formatMessage({
                id: "assembly_vote_form.alert.success.description",
              })}
            />
          ));
          navigate(
            `/my-account/my-investments/projects/${project.id}/assemblies/${assembly.id}/assembly-participant/${assemblyParticipant.id}/vote-signature`,
          );
        },
        onError(error) {
          console.log(error);
        },
      },
    );
  };

  return (
    <div id="assembly-vote-form-page" className="w-full">
      <DynamicForm
        form={form}
        summaryPages={[]}
        actionsBinding={{
          submitVote: {
            onClick: (answers) => {
              submitFormVote(answers);
            },
            visibleCondition: (context) => {
              const { pages, currentPageIndex, answers } = context;

              const allValid = Object.values(
                answers.assembly_vote_answers,
              ).every((value) => value !== null && value !== undefined);

              return (
                currentPageIndex === pages.length &&
                Object.keys(answers.assembly_vote_answers).length ===
                  assembly.topics.length &&
                allValid
              );
            },
          },
          exit: {
            onClick: () => returnToDetailPage(),
          },
        }}
        redirectPath={`/my-account/my-investments/projects/${project.id}/assemblies/${assembly.id}/assembly-participant/${assemblyParticipant.id}`}
        globalActions={[
          {
            type: "submitVote",
            label: "Envoyer mes réponses",
            isLoading: requestIsLoading,
          },
        ]}
      />
      <Outlet />
    </div>
  );
}
