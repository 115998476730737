import { useNavigate, useParams } from "react-router-dom";

import { UserRole } from "@tudigo-monorepo/core-tudigo-api-models";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { ClubProfilePicture } from "@tudigo-monorepo/web-tudigo-components";

import { ConnectedNavigation } from "./use-connected-navigation";

export const useClubLeaderNavigation = (
  ...args: any[]
): ConnectedNavigation => {
  const navigate = useNavigate();
  const pathClubId = useParams().clubId;
  const { clubLeaderClubs } = useUserBusiness();

  if (!clubLeaderClubs || clubLeaderClubs.length === 0) {
    return {
      type: UserRole.CIRCLE_LEADER,
      items: [],
    };
  }

  const clubId = pathClubId ?? clubLeaderClubs[0].id;
  const club = clubLeaderClubs.find((c) => c.id === clubId);

  if (!club) {
    return {
      type: UserRole.CIRCLE_LEADER,
      items: [],
    };
  }

  return {
    type: UserRole.CIRCLE_LEADER,
    items: [
      { type: "section", label: "Gestion club" },
      {
        type: "select",
        label: club.content?.name ?? club.id!,
        onChange: (value: string) => {
          const club = clubLeaderClubs.find((c) => c.id === value);
          if (club) {
            navigate(`/club-leader/${club.id}/dashboard`);
          }
        },
        icon: <ClubProfilePicture club={club} size="XS" />,
        popoverTitle: "Changer de club",
        selectOptions: clubLeaderClubs
          .filter((c) => c.id !== club.id)
          .map((c) => ({
            label: c.content?.name ?? c.id!,
            value: c.id!,
          })),
      },
      {
        type: "link",
        label: "Tableau de bord",
        path: `/club-leader/${club.id}/dashboard`,
        icon: "Dashboard",
      },
      {
        type: "link",
        label: "Membres",
        path: `/club-leader/${club.id}/members`,
        icon: "Group",
      },
      {
        type: "link",
        label: "Levées",
        path: `/club-leader/${club.id}/projects`,
        icon: "Stats",
      },
    ],
  };
};
