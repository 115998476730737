import { CMSHeadingBlock } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { Title, TitleVariant } from "../../title";

export function ContractHeadingBlock(block: CMSHeadingBlock) {
  const { title, level } = block.data;

  const titleLevel =
    level != null && level <= 6 ? (`h${level}` as TitleVariant) : "h1";

  return (
    <Title
      level={titleLevel}
      className={cn({ "border-light-1 border-b pb-4": level === 2 })}
    >
      {title}
    </Title>
  );
}
