import { useQuery } from "@tanstack/react-query";

import {
  getResourceFromTudigoResponse,
  TudigoError,
  TudigoResponse,
} from "@tudigo-monorepo/core-tudigo-api";
import { Organization } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { CollectionRequestParams, getApiClient } from "..";
import { getQueryDetailKey, getQueryListKey } from "./query-key-factory";

export const useGetOrganizationByIdQuery = (
  organizationId: Organization["id"] | undefined,
  query?: { fields?: string },
  options?: { enabled?: boolean },
) => {
  const apiClient = getApiClient();

  return useQuery<Organization, TudigoError>({
    queryKey: getQueryDetailKey({
      key: QUERY_KEYS.ORGANIZATION,
      id: organizationId!,
      fields: query?.fields,
    }),
    queryFn: async () => {
      const response = await apiClient.apiV1.organizations.getOrganizationById(
        organizationId!,
        query,
      );

      return getResourceFromTudigoResponse(response.data);
    },
    retry: 0,
    ...options,
  });
};

type UserOrganizationsQueryPathParams = { userId: string };
type UserOrganizationsQueryFilters = { status?: string; type?: string };

export const useGetUserOrganizationsQuery = (
  params: CollectionRequestParams<
    UserOrganizationsQueryPathParams,
    UserOrganizationsQueryFilters
  >,
) => {
  const { pagination, fields } = params;
  const filters = {
    status: params.filters?.status,
    type: params.filters?.type,
    userId: params.pathParams.userId,
  };
  const apiClient = getApiClient();

  return useQuery<TudigoResponse<Organization[]>, TudigoError>({
    refetchOnMount: false,
    queryKey: getQueryListKey({
      key: QUERY_KEYS.USER_ORGANIZATIONS,
      fields: params.fields,
      filters: filters,
    }),
    queryFn: async () => {
      const response = await apiClient.apiV1.organizations.getUserOrganizations(
        params.pathParams.userId,
        {
          ...filters,
          fields: fields,
          offset: pagination?.offset,
          limit: pagination?.limit,
        },
      );

      return response.data;
    },
  });
};
