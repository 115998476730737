import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { getSignatureRequestSyncById } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  SignatureIframe,
  Stepper,
  Title,
} from "@tudigo-monorepo/web-tudigo-components";
import { DynamicFormNavigationItem } from "@tudigo-monorepo/web-tudigo-dynamic-forms";

import { useMyInvestmentAssemblyPageContext } from "../investment-assembly-page/my-investment-assembly-page";

export function MyInvestmentAssemblyVoteSignaturePage() {
  const { project, assembly, assemblyParticipant } =
    useMyInvestmentAssemblyPageContext();
  const navigate = useNavigate();

  const returnToDetailPage = () => {
    navigate(
      `/my-account/my-investments/projects/${project.id}/assemblies/${assembly.id}/assembly-participant/${assemblyParticipant.id}/detail/vote`,
    );
  };

  const queryClient = useQueryClient();

  const handleSignatureSuccess = async () => {
    await getSignatureRequestSyncById(
      assemblyParticipant.voteSignatureRequest!.id,
    );
    await queryClient.invalidateQueries({
      queryKey: [`assembly_participant-${assemblyParticipant.id}`],
    });

    returnToDetailPage();
  };

  return (
    <div id="assembly-vote-signature-page" className="w-full">
      <div className="absolute left-0 z-40 flex h-dvh w-full flex-col justify-between bg-white">
        <div
          role="navigation"
          className="dynamic-form-navigation fixed left-0 top-0 z-20 flex h-[104px] w-full flex-col items-center justify-center gap-y-3 border-b bg-white px-6 sm:gap-y-0"
        >
          <Title
            level="h2"
            className="hidden whitespace-nowrap text-center sm:block sm:translate-y-2"
          >
            {assembly.name}
          </Title>
          <Title
            level="h5"
            className="block whitespace-nowrap text-center sm:hidden"
          >
            AAA
          </Title>
          <div className="flex w-full items-center justify-between gap-x-4">
            <div
              className={cn({
                "pointer-events-none opacity-0": true,
              })}
            >
              <DynamicFormNavigationItem type="back" onClick={() => {}} />
            </div>
            <div className="contents sm:block sm:w-[600px] sm:max-w-[600px]">
              <Stepper
                steps={[
                  {
                    label: "Vote",
                    navigateTo: `/my-account/my-investments/projects/${project.id}/assemblies/${assembly.id}/assembly-participant/${assemblyParticipant.id}/vote-form`,
                  },
                  { label: "Signature" },
                ]}
                activeStepIndex={1}
              />
            </div>
            <DynamicFormNavigationItem
              type="exit"
              onClick={() => returnToDetailPage()}
            />
          </div>
        </div>
        {assemblyParticipant.voteSignatureRequest?.mySignatureLink && (
          <main className="dynamic-form-sections mx-auto mb-[120px] mt-[104px] h-screen w-full overflow-y-auto overflow-x-hidden px-4 py-8 sm:mb-[104px] sm:w-2/3 sm:overflow-x-visible sm:px-12 sm:pb-16">
            <SignatureIframe
              signatureLink={
                assemblyParticipant.voteSignatureRequest
                  ?.mySignatureLink as string
              }
              onSuccess={() => handleSignatureSuccess()}
            />
          </main>
        )}
      </div>
    </div>
  );
}
