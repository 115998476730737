import { Navigate, Route, Routes } from "react-router-dom";

import { QualificationIntroPage } from "../pages/qualification-intro-page/qualification-intro-page";
import { QualificationPage } from "../pages/qualification-page/qualification-page";

export function QualificationRoute() {
  return (
    <Routes>
      <Route path="intro" element={<QualificationIntroPage />} />
      <Route index element={<QualificationPage />} />
      <Route path="*" element={<Navigate to="/my-account/dashboard" />} />
    </Routes>
  );
}
