import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { ProjectHolderProfile } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient } from "../../..";
import queryKeyFactory from "../../query-key-factory";

type CreateProjectHolderProfileParams = {
  data: ProjectHolderProfile;
};

export const createProjectHolderProfile = async (
  params: CreateProjectHolderProfileParams,
) => {
  const apiClient = getApiClient();

  const response =
    await apiClient.apiV1.projectHolderProfiles.createProjectHolderProfile(
      params.data,
    );

  return response.data;
};

export const useCreateProjectHolderProfile = (
  options?: UseMutationOptions<
    TudigoResponse<ProjectHolderProfile>,
    TudigoError,
    CreateProjectHolderProfileParams
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (params: CreateProjectHolderProfileParams) =>
      createProjectHolderProfile(params),
    onSuccess: async (...props) => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_HOLDER_PROFILES],
      });
      const usersQueryKeys = queryKeyFactory("users");
      await queryClient.invalidateQueries({
        queryKey: usersQueryKeys.detail(props[0].data.id),
      });
      options?.onSuccess?.(...props);
    },
  });
};
