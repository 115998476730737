import React from "react";

import {
  Answers,
  Form,
  FormPage,
  FormPageAction,
} from "@tudigo-monorepo/core-tudigo-api-models";

import { DynamicFormActions } from "./dynamic-form-actions";
import { DynamicFormDebugger } from "./dynamic-form-debugger";
import { DynamicFormNavigation } from "./dynamic-form-navigation";
import { DynamicFormSections } from "./dynamic-form-sections";
import { DynamicFormContextType } from "./store/dynamic-form-context";
import { DynamicFormProvider } from "./store/dynamic-form-provider";

export type DynamicFormActionBinding = {
  [key: string]: {
    onClick: (answers: Answers) => void;
    visibleCondition?: (context: DynamicFormContextType) => boolean;
    isDisabled?: boolean;
  };
};

type DynamicFormProps = {
  form: Form;
  debug?: boolean;
  summaryPages?: FormPage[];
  actionsBinding?: DynamicFormActionBinding;
  redirectPath?: string;
  globalActions?: FormPageAction[];
  answers?: Answers;
  startPage?: number | "resume";
};

export function DynamicForm(props: DynamicFormProps) {
  const {
    form,
    summaryPages,
    debug = false,
    redirectPath,
    globalActions,
    actionsBinding,
    answers,
    startPage,
  } = props;

  return (
    <DynamicFormProvider
      form={form}
      summaryPages={summaryPages}
      actionsBinding={actionsBinding}
      answers={answers}
      startPage={startPage}
    >
      <div className="absolute left-0 z-40 flex h-dvh w-full flex-col justify-between bg-white">
        <DynamicFormNavigation redirectPath={redirectPath} />
        <DynamicFormSections />
        <DynamicFormActions debug={debug} globalActions={globalActions} />
      </div>
      {debug && <DynamicFormDebugger />}
    </DynamicFormProvider>
  );
}
