import { OrganizationPerson } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { ORGANIZATIONS, PMS_FILES } from "@tudigo-monorepo/core-tudigo-utils";
import { Chip, Typography } from "@tudigo-monorepo/web-tudigo-components";

import { useKYCContext } from "../../../kyc-page";
import { KycDocumentViewer } from "../../../shared/components/kyc-document-viewer";
import {
  getOrganizationPersonDocumentsData,
  isOrganizationPersonProofOfAddressRequired,
} from "../../../utils/kyc-utils";

type UboDocumentsSummaryCollapseListElementProps = {
  beneficialOwner: OrganizationPerson;
};

export function IndividualDocumentSummaryCollapseListElement(
  props: UboDocumentsSummaryCollapseListElementProps,
) {
  const { beneficialOwner } = props;
  const { organization } = useKYCContext();
  const { t } = useTranslation();

  const isProofOfAddressRequired =
    isOrganizationPersonProofOfAddressRequired(beneficialOwner);

  const documentsData = getOrganizationPersonDocumentsData(
    beneficialOwner,
    isProofOfAddressRequired,
  );

  return (
    <div className="flex flex-col gap-4">
      <div className={cn("flex flex-col")}>
        <Typography variant="caption1-regular">
          {t(
            "kyc_funnel.step_documents.form.organization_person_document.label.identity_card_front_side",
          )}
        </Typography>
        {documentsData.identityCardFrontSide.document.signedUrl ? (
          <KycDocumentViewer
            doc={documentsData.identityCardFrontSide}
            isError={
              documentsData.mergedIdentityCard.pmsFile?.status ===
              PMS_FILES.STATUS.REJECTED
            }
          />
        ) : (
          <div className="my-2">
            <Chip
              type="info"
              label={t("kyc_funnel.final_summary.file_downloader.empty_file")}
            />
          </div>
        )}
      </div>

      <div className={cn("flex flex-col")}>
        <Typography variant="caption1-regular">
          {t(
            "kyc_funnel.step_documents.form.organization_person_document.label.identity_card_back_side",
          )}
        </Typography>
        {documentsData?.identityCardBackSide?.document?.signedUrl ? (
          <KycDocumentViewer
            doc={documentsData.identityCardBackSide}
            isError={
              documentsData.mergedIdentityCard.pmsFile?.status ===
              PMS_FILES.STATUS.REJECTED
            }
          />
        ) : (
          <div className="my-2">
            <Chip
              type="info"
              label={t("kyc_funnel.final_summary.file_downloader.empty_file")}
            />
          </div>
        )}
      </div>

      <div className={cn("flex flex-col")}>
        <Typography variant="caption1-regular">
          {t(
            "kyc_funnel.step_documents.form.organization_person_document.label.passport_front_side",
          )}
        </Typography>
        {documentsData?.passportFrontSide?.document?.signedUrl ? (
          <KycDocumentViewer
            doc={documentsData.passportFrontSide}
            isError={
              documentsData.mergedPassport.pmsFile?.status ===
              PMS_FILES.STATUS.REJECTED
            }
          />
        ) : (
          <div className="my-2">
            <Chip
              type="info"
              label={t("kyc_funnel.final_summary.file_downloader.empty_file")}
            />
          </div>
        )}
      </div>

      {organization.type === ORGANIZATIONS.TYPE.INDIVIDUAL &&
        isProofOfAddressRequired && (
          <div className="flex flex-col">
            <Typography variant="caption1-regular">
              {t(
                "kyc_funnel.step_documents.individual_company_section.proof_of_address.title",
              )}
            </Typography>
            {documentsData?.proofOfAddress?.document.signedUrl ? (
              <KycDocumentViewer doc={documentsData.proofOfAddress} />
            ) : (
              <div className="my-2">
                <Chip
                  type="info"
                  label={t(
                    "kyc_funnel.final_summary.file_downloader.empty_file",
                  )}
                />
              </div>
            )}
          </div>
        )}
    </div>
  );
}
