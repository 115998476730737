import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Chip } from "@tudigo-monorepo/web-tudigo-components";

export const KycErrorChip = () => {
  const { t } = useTranslation();

  return (
    <Chip type="error" label={t("kyc_funnel.chip.error")} iconLeft="DotChip" />
  );
};
