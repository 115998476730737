import { ReactNode } from "react";

const TaxExemptionBlockHeader = (props: { children: ReactNode }) => {
  return <header className="flex flex-col gap-2">{props.children}</header>;
};

const TaxExemptionBlockTitle = (props: { children: ReactNode }) => {
  return (
    <h3 className="font-montserrat text-primary text-lg font-bold">
      {props.children}
    </h3>
  );
};

const TaxExemptionBlockContent = (props: { children: ReactNode }) => {
  return <main className="flex flex-col gap-y-6">{props.children}</main>;
};

const TaxExemptionBlockText = (props: { children: ReactNode }) => {
  return <p className="font-montserrat text-dark-1">{props.children}</p>;
};

const TaxExemptionBlock = (props: { children: ReactNode }) => {
  return <section className="w-full px-6 py-8">{props.children}</section>;
};

export {
  TaxExemptionBlock,
  TaxExemptionBlockHeader,
  TaxExemptionBlockTitle,
  TaxExemptionBlockContent,
  TaxExemptionBlockText,
};
