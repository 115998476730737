import { Organization } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { checkIfCompanyRegistrationLocationRequirementsAreMet } from "@tudigo-monorepo/web-feature-kyc";

import { useOrganizationKycCompanyStatusChipProps } from "../../../hooks/use-organization-kyc-company-status-chip-props";
import { OrganizationCollapse } from "../../organization-collapse/organization-collapse";
import { OrganizationCollapseHeader } from "../../organization-collapse/organization-collapse-header";

type CompanySectionProps = {
  organization: Organization;
};

export function CompanySection({ organization }: CompanySectionProps) {
  const { t } = useTranslation();

  const { companyInformation } = organization;

  const sectionChipStatus =
    useOrganizationKycCompanyStatusChipProps(organization);

  if (!companyInformation) return null;

  const fullCompanyRegistrationAddress = `${
    companyInformation.registrationLocation?.street1 || ""
  } ${companyInformation.registrationLocation?.postalCode || ""} ${
    companyInformation.registrationLocation?.city || ""
  } ${companyInformation.registrationLocation?.country || ""}`;

  const collapseItems = [
    {
      label: t(
        "organizations.back_office.organization_page.collapse.represented_legal_entity.company_name",
      ),
      value: organization.name,
      chipProps: !organization.name ? sectionChipStatus : undefined,
    },
    {
      label: t(
        "organizations.back_office.organization_page.collapse.represented_legal_entity.siret",
      ),
      value: companyInformation.registrationNumbers?.siret,
      chipProps: !companyInformation.registrationNumbers?.siret
        ? sectionChipStatus
        : undefined,
    },
    {
      label: t(
        "organizations.back_office.organization_page.collapse.represented_legal_entity.legal_status",
      ),
      value: companyInformation.legalStatus,
      chipProps: !companyInformation.legalStatus
        ? sectionChipStatus
        : undefined,
    },
    {
      label: t(
        "organizations.back_office.organization_page.collapse.represented_legal_entity.address",
      ),
      value: fullCompanyRegistrationAddress,
      chipProps: !checkIfCompanyRegistrationLocationRequirementsAreMet(
        companyInformation,
      )
        ? sectionChipStatus
        : undefined,
    },
  ];

  return (
    <OrganizationCollapse items={collapseItems}>
      <OrganizationCollapseHeader
        label={t(
          "organizations.back_office.organization_page.collapse.represented_legal_entity.title",
        )}
        chipProps={sectionChipStatus}
      />
    </OrganizationCollapse>
  );
}
