import { useMutation } from "@tanstack/react-query";

import { CreateConsentRequestBody } from "@tudigo-monorepo/core-tudigo-api";

import { getApiClient } from "..";

export const useCreateConsentMutation = () => {
  const apiClient = getApiClient();

  return useMutation({
    mutationFn: ({ data }: { data: CreateConsentRequestBody }) =>
      apiClient.apiV1.consents.createConsent(data),
    onError: () => {},
  });
};
