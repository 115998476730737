import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { Icon } from "../icons/icon";
import { MenuSimple } from "../menu-navigation";

type MenuSimpleProps = {
  item: MenuSimple;
  reducedDisplay: boolean;
};

export function MenuNavigationSimpleItem(props: MenuSimpleProps) {
  const { item, reducedDisplay } = props;

  return (
    <div
      onClick={item.action}
      className={cn(
        "menu-navigation-item hover:border-accent-medium flex min-h-[43px] items-center gap-3 rounded-lg border border-transparent text-[#6F6A73] transition-all",
        {
          "w-full px-3 py-2": !reducedDisplay,
          "w-[38px] px-[7px] py-2": reducedDisplay,
        },
      )}
    >
      {item.icon ? <Icon name={item.icon} primaryColor="#6F6A73" /> : null}
      <p
        className={cn(
          "whitespace-nowrap opacity-100 transition-opacity duration-300 ease-in-out",
          {
            "opacity-0": reducedDisplay,
          },
        )}
      >
        {item.label}
      </p>
    </div>
  );
}
