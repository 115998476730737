import { Widget as TFWidget } from "@typeform/embed-react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { UserRole } from "@tudigo-monorepo/core-tudigo-api-models";
import {
  useSessionStore,
  useUserBusiness,
} from "@tudigo-monorepo/tudigo-session";
import { Alert } from "@tudigo-monorepo/web-tudigo-components";

export function ProjectHolderDepositForm() {
  const navigate = useNavigate();
  const { user } = useUserBusiness();
  const setSessionRole = useSessionStore((state) => state.setSessionRole);

  const handleSubmit = () => {
    if (user?.projectHolderProfile && user?.state?.hasDoneIntro) {
      setSessionRole(UserRole.PROJECT_HOLDER);

      toast(({ closeToast }) => (
        <Alert
          close={closeToast}
          icon="Timer"
          variant="waiting"
          title="Questionnaire envoyé"
          description="Votre demande va être prise en compte, nos coachs reviendront vers vous"
        />
      ));

      toast.onChange(({ status }) => {
        if (status === "removed") {
          navigate("/dispatch");
        }
      });
    }
  };

  return (
    <TFWidget
      id="ucMQlqZf"
      inlineOnMobile
      className="h-[calc(100dvh-65px)] w-full rounded-md"
      onSubmit={handleSubmit}
      onEndingButtonClick={() => navigate("/dispatch")}
    />
  );
}
