import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  CreateInvestmentAccountModal,
  CreateInvestmentAccountModalTrigger,
  InvestmentAccountProvider,
} from "@tudigo-monorepo/web-feature-organizations";
import { useCreateOrganizationMutation } from "@tudigo-monorepo/web-tudigo-api-client";
import { Alert, Button } from "@tudigo-monorepo/web-tudigo-components";

export function InvestmentFunnelAddAccountsSection() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { mutate: createCompanyOrganization, isPending: isCreateOrgLoading } =
    useCreateOrganizationMutation({
      onSuccess: (res) => {
        if (res.data.type === "company") {
          localStorage.setItem("target-url", location.pathname);

          const redirectUrl = `/organization/${
            res.data.id
          }/kyc/personal-information?${queryString.stringify({
            pathname: location.pathname,
          })}`;

          navigate(redirectUrl);
        }
      },
      onError: () => {
        toast((content) => (
          <Alert
            close={content.closeToast}
            icon="Error"
            variant="error"
            title={t(
              "investment_funnel.add_accounts_section.create_organization.alert_on_error.title",
            )}
          />
        ));
      },
    });

  const handleCreateCompanyOrganization = () => {
    createCompanyOrganization({ type: "company" });
  };

  return (
    <section className="my-6 flex w-full flex-col gap-y-6">
      <div className="separator flex items-center">
        <div className="border-light-1 h-1/2 w-1/12 border-b border-dashed"></div>
        <p className="text-dark-3 mx-4 font-sans text-[13px]">Ou</p>
        <div className="border-light-1 h-1/2 w-full border-b border-dashed"></div>
      </div>

      <div className="flex flex-wrap items-center gap-4">
        <Button
          onClick={handleCreateCompanyOrganization}
          isLoading={isCreateOrgLoading}
          label={t(
            "investment_funnel.add_accounts_section.create_organization.button.label",
          )}
          variant="tertiary"
          iconRight="Plus"
          className="w-full sm:w-[213px]"
        />

        <InvestmentAccountProvider>
          <CreateInvestmentAccountModalTrigger>
            {t(
              "investment_funnel.add_accounts_section.create_equity_saving_plan.button.label",
            )}
          </CreateInvestmentAccountModalTrigger>
          <CreateInvestmentAccountModal
            investProjectUrl={location.pathname}
            accountTypes={["pea", "pea_pme"]}
          />
        </InvestmentAccountProvider>
      </div>
    </section>
  );
}
