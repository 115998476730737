import React from "react";
import { useIntl } from "react-intl";

import { Collapse, Typography } from "@tudigo-monorepo/web-tudigo-components";

type InvestmentCapacityCollapseContentProps = {
  summaryItems: {
    label?: string;
    value?: string;
    type: "item" | "separator";
  }[];
};

export function InvestmentCapacityCollapseContent(
  props: InvestmentCapacityCollapseContentProps,
) {
  const { summaryItems } = props;

  const intl = useIntl();

  const collapseRows = summaryItems.map((item, index) => (
    <React.Fragment key={index}>
      {item.type === "item" ? (
        <div className="flex items-center justify-between">
          <Typography variant="caption2-semibold">{item.label}</Typography>
          <Typography variant="caption1-semibold">{item.value}</Typography>
        </div>
      ) : (
        <span className="bg-light-1 h-px w-full" />
      )}
    </React.Fragment>
  ));

  return (
    <Collapse
      items={collapseRows}
      headerClassName="py-5"
      panelClassName="gap-y-2"
    >
      <Typography variant="body3-regular">
        {intl.formatMessage({
          id: "qualification.summary_page.investment_capacity.collapse.title",
        })}
      </Typography>
    </Collapse>
  );
}
