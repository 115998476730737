import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import {
  Project,
  ProjectDisplayedStatusFilter,
  ProjectNews,
} from "@tudigo-monorepo/core-tudigo-api-models";

import { CollectionRequestParams, getApiClient } from "..";
import queryKeyFactory from "./query-key-factory";

const projectsKeys = queryKeyFactory("projects");
const projectNewsKeys = queryKeyFactory("project-news");

export type ProjectsFilters = {
  status?: ProjectDisplayedStatusFilter | ProjectDisplayedStatusFilter[];
  term?: string;
  slug?: string | string[];
  mechanic?: string | string[];
  clubId?: string;
};

export const useGetProjectsQuery = (
  params: CollectionRequestParams<
    undefined,
    | {
        status?: ProjectDisplayedStatusFilter | ProjectDisplayedStatusFilter[];
        term?: string;
        slug?: string | string[];
        mechanic?: string | string[];
        clubId?: string;
      }
    | undefined
  >,
  options?: Partial<UseQueryOptions<TudigoResponse<Project[]>, TudigoError>>,
) => {
  const { pagination, filters, fields } = params;

  const apiClient = getApiClient();

  return useQuery<TudigoResponse<Project[]>, TudigoError>({
    refetchOnMount: false,
    queryKey: projectsKeys.list(fields, filters),
    queryFn: async () => {
      const response = await apiClient.apiV1.projects.getProjects({
        ...filters,
        fields: fields,
        offset: pagination?.offset,
        limit: pagination?.limit,
        useMasterStorage: true,
      });

      return response.data;
    },
    ...options,
  });
};

export const useGetProjectNewsQuery = (
  params: CollectionRequestParams<{ projectId: string }, { type: string }>,
) => {
  const { pagination, filters, fields, pathParams } = params;
  const apiClient = getApiClient();

  return useQuery<TudigoResponse<ProjectNews[]>, TudigoError>({
    refetchOnMount: false,
    queryKey: projectNewsKeys.list(fields, {
      projectId: pathParams.projectId,
      ...filters,
    }),
    queryFn: async () => {
      const response = await apiClient.apiV1.projects.getProjectNews(
        pathParams.projectId,
        {
          ...filters,
          fields: fields,
          offset: pagination?.offset,
          limit: pagination?.limit,
        },
      );

      return response.data;
    },
  });
};
