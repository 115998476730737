import { JSX } from "react/jsx-runtime";

import { CMSListBlock } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import {
  ThinLayout,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

type ListBlockProps = {
  block: CMSListBlock;
  editMode?: boolean;
};

export function ListBlock(props: ListBlockProps) {
  const { block } = props;
  const { data } = block;
  const { date, content, type } = data;

  let listVariant = "ul";
  switch (type) {
    case "ordered":
      listVariant = "ol";
      break;
    case "unordered":
      listVariant = "ul";
      break;
    default:
      listVariant = "ul";
  }
  const List = listVariant as keyof JSX.IntrinsicElements;

  return (
    <ThinLayout>
      {date && <Title>{date}</Title>}

      <List
        className={cn("pl-4", {
          "list-disc": type === "unordered",
          "list-decimal": type === "ordered",
        })}
      >
        {content.map((listElement, index) => (
          <li key={index}>
            <Typography variant="body2" dangerouslySetInnerHTML>
              {listElement}
            </Typography>
          </li>
        ))}
      </List>
    </ThinLayout>
  );
}
