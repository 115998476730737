import React from "react";

import {
  Organization,
  WMACustomer,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { UserOrganizationAutocomplete } from "@tudigo-monorepo/web-feature-investments";

type WmaCreateInvestmentOrganizationSelectorProps = {
  selectedCustomer: WMACustomer | null;
  selectedOrganization: Organization | null;
  setSelectedOrganization: React.Dispatch<
    React.SetStateAction<Organization | null>
  >;
  handleAutocompleteChange: (
    fieldName: string,
    value: string | null | undefined,
  ) => void;
};

export function WmaCreateInvestmentOrganizationSelector(
  props: WmaCreateInvestmentOrganizationSelectorProps,
) {
  const {
    selectedCustomer,
    selectedOrganization,
    setSelectedOrganization,
    handleAutocompleteChange,
  } = props;

  return (
    <UserOrganizationAutocomplete
      userId={selectedCustomer?.customerUser?.id as string}
      selectedOrganization={selectedOrganization}
      setSelectedOrganization={setSelectedOrganization}
      onChange={(organization: Organization) =>
        handleAutocompleteChange("organizationId", organization.id)
      }
      required
    />
  );
}
