import { ClubV2 } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

import { formatRangeOrSingleValue } from "../../../helpers/format-club-mechanics";

export const CircleModalitiesCardBlock = ({ club }: { club: ClubV2 }) => {
  const { t } = useTranslation();

  if (!club || !club.mechanicSettings) {
    return null;
  }
  const { mechanicSettings } = club;
  const {
    minimumTicketAmountFromInCents,
    minimumTicketAmountToInCents,
    investmentDurationFromInMonth,
    investmentDurationToInMonth,
    entryFeesFromNoDecimalRate,
    entryFeesToNoDecimalRate,
    carriedFromNoDecimalRate,
    carriedToNoDecimalRate,
  } = mechanicSettings;

  return (
    <div className="border-light-1 rounded-lg border bg-white p-6">
      <div className="text-primary font-bold">
        {t("page_club.cms_modalities_card.title")}
      </div>
      <div className="my-4 flex flex-col gap-4">
        <InformationRow
          label={t("page_club.cms_modalities_card.minimum_ticket_amount.label")}
          value={formatRangeOrSingleValue(
            minimumTicketAmountFromInCents,
            minimumTicketAmountToInCents,
            "page_club.cms_modalities_card.minimum_ticket_amount_range",
            "page_club.cms_modalities_card.minimum_ticket_amount_single",
            t,
            true,
          )}
        />
        <InformationRow
          label={t("page_club.cms_modalities_card.investment_duration.label")}
          value={formatRangeOrSingleValue(
            investmentDurationFromInMonth,
            investmentDurationToInMonth,
            "page_club.cms_modalities_card.investment_duration_in_months_range",
            "page_club.cms_modalities_card.investment_duration_in_months_single",
            t,
          )}
        />
        <InformationRow
          label={t("page_club.cms_modalities_card.entry_fees.label")}
          value={formatRangeOrSingleValue(
            entryFeesFromNoDecimalRate / 100,
            entryFeesToNoDecimalRate / 100,
            "page_club.cms_modalities_card.entry_fees_range",
            "page_club.cms_modalities_card.entry_fees_single",
            t,
          )}
        />
        <InformationRow
          label={t("page_club.cms_modalities_card.carried.label")}
          value={formatRangeOrSingleValue(
            carriedFromNoDecimalRate / 100,
            carriedToNoDecimalRate / 100,
            "page_club.cms_modalities_card.carried_range",
            "page_club.cms_modalities_card.carried_single",
            t,
          )}
        />
      </div>
    </div>
  );
};

const InformationRow = ({ label, value }: { label: string; value: string }) => (
  <div className="text-primary flex justify-between text-xs">
    <div>{label}</div>
    <div className="font-semibold">{value}</div>
  </div>
);
