import { Route, Routes } from "react-router-dom";

import { Dispatcher } from "../components/dispatcher";
import { FinalStepContent } from "../content/steps/final-step/final-step-content";
import { InvestmentFunnelAgreementAndSecurityContent } from "../content/steps/step-agreement-and-security/investment-funnel-agreement-and-security-content";
import { InvestmentFunnelSelectInvestmentAmountContent } from "../content/steps/step-select-investment-amount/investment-funnel-select-investment-amount-content";
import { InvestmentFunnelSelectOrganizationContent } from "../content/steps/step-select-organization/investment-funnel-select-organization-content";
import { InvestmentFunnelSelectPaymentMethodContent } from "../content/steps/step-select-payment-method/investment-funnel-select-payment-method-content";
import { InvestmentFunnelSignCommitmentLetterContent } from "../content/steps/step-sign-commitment-letter/investment-funnel-sign-commitment-letter-content";
import { InvestmentFunnelSignSubscriptionFormContent } from "../content/steps/step-sign-subscription-form/investment-funnel-sign-subscription-form-content";
import { InvestmentFunnelPage } from "../pages/investment-funnel-page";

export function InvestmentFunnelRoute() {
  return (
    <Routes>
      <Route path={"/:investmentId/*"} element={<InvestmentFunnelPage />}>
        <Route
          path="select-amount"
          element={<InvestmentFunnelSelectInvestmentAmountContent />}
        />
        <Route
          path="select-organization"
          element={<InvestmentFunnelSelectOrganizationContent />}
        />
        <Route
          path="agreement-and-security"
          element={<InvestmentFunnelAgreementAndSecurityContent />}
        />
        <Route
          path="sign-subscription-form"
          element={<InvestmentFunnelSignSubscriptionFormContent />}
        />
        <Route
          path="select-payment-method"
          element={<InvestmentFunnelSelectPaymentMethodContent />}
        />
        <Route
          path="sign-commitment-letter"
          element={<InvestmentFunnelSignCommitmentLetterContent />}
        />
        <Route path="final-page" element={<FinalStepContent />} />
      </Route>
      <Route path="?/*" element={<Dispatcher />} />
    </Routes>
  );
}
