import { useIntl } from "react-intl";

import { Title } from "@tudigo-monorepo/web-tudigo-components";

import { InvestmentFeedList } from "../components/investment-feed-list";
import { useMyInvestmentPageContext } from "../hooks/use-my-investment-page-context";

export function InvestmentNewsContent() {
  const { project, userId } = useMyInvestmentPageContext();

  const intl = useIntl();

  return (
    <div className="m-6">
      <Title h3Brand="brand-2" className="text-primary mb-6" level="h3">
        {intl.formatMessage({
          id: "my-investments.my_investment_page.news.all_news.title",
        })}
      </Title>
      <InvestmentFeedList project={project} userId={userId} filter="news" />
    </div>
  );
}
