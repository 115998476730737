import React from "react";
import { useParams } from "react-router-dom";

import {
  InvestmentsFilters,
  useGetCircleInvestments,
} from "@tudigo-monorepo/web-tudigo-api-client";
import {
  TudigoCollection,
  TudigoFilters,
} from "@tudigo-monorepo/web-tudigo-collection";
import { Table, TableCardList } from "@tudigo-monorepo/web-tudigo-components";

import { useClubLeaderProjectInvestmentsColumns } from "../hooks/use-club-leader-project-investments-columns";
import { useClubLeaderProjectInvestmentsFilters } from "../hooks/use-club-leader-project-investments-filters";

export const ClubLeaderPageProjectTabInvestorsContent = () => {
  const circleId = useParams().clubId as string;

  const [filters, setFilters] = React.useState<InvestmentsFilters>({
    createdAt: undefined,
    projectId: undefined,
  });

  const columns = useClubLeaderProjectInvestmentsColumns();
  const filterConfiguration = useClubLeaderProjectInvestmentsFilters();
  const queryFilters = {
    ...filters,
  };

  return (
    <div>
      <TudigoFilters
        className="mx-6 mb-6 mt-4"
        useLocationAsState
        filters={filters}
        setFilters={setFilters}
        filtersConfiguration={filterConfiguration}
      />
      <div className="m-6">
        <TudigoCollection
          useLocationAsState
          columns={columns}
          filters={queryFilters}
          useQueryFn={useGetCircleInvestments}
          pathParams={{ circleId }}
          render={(table, props) => {
            return (
              <>
                <Table
                  table={table}
                  actions={props.actions}
                  className="hidden sm:table"
                />
                <TableCardList table={table} className="sm:hidden" />
              </>
            );
          }}
        />
      </div>
    </div>
  );
};
