import { useState } from "react";
import { useIntl } from "react-intl";

import { User } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { SessionAvatar } from "@tudigo-monorepo/tudigo-session";

import { Button } from "../../old/button";
import { Typography } from "../../old/typography";
import { AddComment } from "./add-comment";

type CommentProps = {
  author: string;
  comment: string;
  date: string;
  commentId: string;
  className?: string;
  profileImage: User["profileImage"];
  handleReplyComment: (newReply: string, parentId: string) => void;
};

export const Comment = ({
  author,
  comment,
  date,
  handleReplyComment,
  commentId,
  className,
  profileImage,
}: CommentProps) => {
  const intl = useIntl();

  const [displayReplyInput, setDisplayReplyInput] = useState(false);
  const [newReply, setNewReply] = useState("");

  const handleNewReplyComment = () => {
    handleReplyComment(newReply, commentId);
    setNewReply("");
    setDisplayReplyInput(false);
  };

  return (
    <>
      <div
        className={cn(
          "flex w-full flex-col gap-3 rounded-md border bg-white p-5",
          className,
        )}
      >
        <div className="flex flex-row gap-3">
          <SessionAvatar profileImage={profileImage} />
          <Typography
            variant="caption1-regular"
            className="text-dark-1 self-center"
          >
            {author}
          </Typography>
        </div>
        <Typography variant="caption2-semibold">{date}</Typography>
        <Typography variant="body2">{comment}</Typography>
        <Button
          className="w-fit self-end"
          label="Répondre"
          iconRight="Pen"
          variant="ghost"
          onClick={() => setDisplayReplyInput(!displayReplyInput)}
        />
      </div>
      {displayReplyInput && (
        <AddComment
          className="before:bg-light-1 relative w-11/12 self-end rounded-md border p-5 before:absolute before:bottom-full before:left-[10%] before:h-[26px] before:w-px"
          newComment={newReply}
          handleChangeNewComment={(event) => setNewReply(event.target.value)}
          handleCreateComment={handleNewReplyComment}
          placeholder={intl.formatMessage(
            {
              id: "projects.web_app.questions_answers.reply.label",
            },
            {
              author,
            },
          )}
        />
      )}
    </>
  );
};
