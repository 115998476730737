import React from "react";

import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  Avatar,
  Icon,
  PageHead,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { useInvestmentFunnelPageContext } from "../../../../../pages/investment-funnel-page";
import { FinalPendingTasksBlock } from "../shared/final-pending-tasks-block";
import { LastNewsBlock } from "../shared/last-news-block";

export function FinalStepPeaPaymentProjectClosedWaitingPayment() {
  const { project } = useInvestmentFunnelPageContext();
  const { t } = useTranslation();

  return (
    <>
      <PageHead
        title={t(
          "investment_funnel.final_page.pea_project_closed_waiting_payment.page_head.title",
        )}
        className="py-4"
        withEndLine
        subtitle={t(
          "investment_funnel.final_page.pea_project_closed_waiting_payment.page_head.subtitle",
        )}
      />
      <FinalPendingTasksBlock
        project={project}
        tasks={[
          {
            avatar: (
              <Avatar variant="green">
                <Icon
                  name="Checked"
                  size="S"
                  primaryColor={themeColors.success}
                  secondaryColor={themeColors.success}
                />
              </Avatar>
            ),
            title: t(
              "investment_funnel.final_page.pea_project_closed_waiting_payment.final_pending_tasks_block.step_1.title",
            ),
          },
          {
            avatar: (
              <Avatar variant="blue">
                <Typography variant="body2-semibold" className="text-accent">
                  2
                </Typography>
              </Avatar>
            ),
            title: t(
              "investment_funnel.final_page.pea_project_closed_waiting_payment.final_pending_tasks_block.step_2.title",
            ),
            description: t(
              "investment_funnel.final_page.pea_project_closed_waiting_payment.final_pending_tasks_block.step_2.description",
            ),
          },
          {
            avatar: (
              <Avatar variant="blue">
                <Typography variant="body2-semibold" className="text-accent">
                  3
                </Typography>
              </Avatar>
            ),
            title: t(
              "investment_funnel.final_page.pea_project_closed_waiting_payment.final_pending_tasks_block.step_3.title",
            ),
            description: t(
              "investment_funnel.final_page.pea_project_closed_waiting_payment.final_pending_tasks_block.step_3.description",
            ),
          },
          {
            avatar: (
              <Avatar variant="blue">
                <Typography variant="body2-semibold" className="text-accent">
                  4
                </Typography>
              </Avatar>
            ),
            title: t(
              "investment_funnel.final_page.pea_project_closed_waiting_payment.final_pending_tasks_block.step_4.title",
            ),
            description: t(
              "investment_funnel.final_page.pea_project_closed_waiting_payment.final_pending_tasks_block.step_4.description",
            ),
          },
          {
            avatar: (
              <Avatar variant="blue">
                <Typography variant="body2-semibold" className="text-accent">
                  5
                </Typography>
              </Avatar>
            ),
            title: t(
              "investment_funnel.final_page.pea_project_closed_waiting_payment.final_pending_tasks_block.step_5.title",
            ),
            description: t(
              "investment_funnel.final_page.pea_project_closed_waiting_payment.final_pending_tasks_block.step_5.description",
            ),
          },
        ]}
      />
      <LastNewsBlock />
    </>
  );
}
