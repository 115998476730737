import { useState } from "react";

import { ClubV2 } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useCreateCommentMutation } from "@tudigo-monorepo/web-tudigo-api-client";
import { AddComment } from "@tudigo-monorepo/web-tudigo-components";

type ClubNewCommentSectionProps = {
  club: ClubV2;
};

export const ClubNewCommentSection = (props: ClubNewCommentSectionProps) => {
  const { club } = props;
  const { t } = useTranslation();

  const [newComment, setNewComment] = useState("");

  const { mutate: createComment } = useCreateCommentMutation({
    onSuccess: () => {
      setNewComment("");
    },
  });

  const handleCreateComment = () => {
    createComment({
      club: { id: club.id! },
      value: newComment,
    });
  };

  return (
    <>
      <p className="text-dark-1 font-semibold">
        {t("page_club.qa_content.title")}
      </p>
      <AddComment
        className="items-center border-b pb-5"
        newComment={newComment}
        handleChangeNewComment={(e) => setNewComment(e.target.value)}
        handleCreateComment={handleCreateComment}
        placeholder={t("page_club.qa_content.new_comment_placeholder")}
      />
    </>
  );
};
