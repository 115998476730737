import { Navigate, Route, Routes } from "react-router-dom";

import { InvestorDashboardContent } from "../../pages/investor/content/investor-dashboard-content";
import InvestorDashboardPage from "../../pages/investor/investor-dashboard-page";

export function InvestorDashboardRoute() {
  return (
    <Routes>
      <Route element={<InvestorDashboardPage />}>
        <Route path="" element={<InvestorDashboardContent />} />
        <Route path="*" element={<Navigate to="" />} />
      </Route>
    </Routes>
  );
}
