import { Navigate, Route, Routes } from "react-router-dom";

import { ProjectHolderDashboardContent } from "../../pages/project-holder/content/project-holder-dashboard-content";
import { ProjectHolderDashboardPage } from "../../pages/project-holder/project-holder-dashboard-page";

export function ProjectHolderDashboardRoute() {
  return (
    <Routes>
      <Route element={<ProjectHolderDashboardPage />}>
        <Route
          path="/:projectId?"
          element={<ProjectHolderDashboardContent />}
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
}
