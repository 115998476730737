import React from "react";

import {
  Avatar,
  ButtonRebrand,
  Icon,
  ListElementComposable,
} from "@tudigo-monorepo/web-tudigo-components";

import { NewsItemMenu } from "./components/news-item-menu";
import { useNewsItem } from "./context/news-item-provider";

export function NewsDocument() {
  const news = useNewsItem();

  return (
    <ListElementComposable className="flex w-full  items-center justify-between">
      <div className="w-4/10 flex min-w-40 items-center gap-3 md:basis-1/5">
        <Avatar icon={{ name: "News" }} />
        <h2 className="text-dark-1 text-sm font-semibold">
          {news?.title ?? "Document"}
        </h2>
      </div>
      <p className="m-auto max-h-[300px] truncate text-sm md:basis-1/5">
        {news.description}
      </p>
      {news.document?.signedUrl ? (
        <ButtonRebrand
          variant="ghost"
          className="ml-auto flex gap-x-2"
          onClick={() => window.open(news.document?.signedUrl)}
        >
          <p className="text-dark-1 text-sm">
            {news.document?.mimeTypeExtension?.toUpperCase() ?? "Document"}
          </p>
          <Icon name="Download" />
        </ButtonRebrand>
      ) : (
        <div className="w-16" />
      )}
      <NewsItemMenu />
    </ListElementComposable>
  );
}
