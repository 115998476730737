import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";

import { Project } from "@tudigo-monorepo/core-tudigo-api-models";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { useGetProjectDebtorRepaymentsPeriods } from "@tudigo-monorepo/web-tudigo-api-client";
import { TudigoCollection } from "@tudigo-monorepo/web-tudigo-collection";
import {
  PageHead,
  Table,
  TableCardList,
  Tabs,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { useDebtorRepaymentsTableColumns } from "../shared-internal/hooks/use-debtor-repayments-table-columns";

export function ProjectHolderRepaymentsPage() {
  const intl = useIntl();
  const { user } = useUserBusiness();
  const navigate = useNavigate();
  const { projectId } = useParams();

  const projects = user?.projectHolderProfile?.projects?.filter(
    (p: Project) => {
      return p.mechanic === "bonds";
    },
  );
  const selectedProject = projects?.find((p) => p.id === projectId);

  useEffect(() => {
    if (!projectId && projects && projects.length >= 1) {
      navigate(`/project-holder/repayments/${projects?.[0].id}`, {
        replace: true,
      });
    }
  }, [projectId]);

  return (
    <div id="my-repayments-page" className="w-full">
      <PageHead
        title={intl.formatMessage({
          id: "project_holder.repayments_page.title",
        })}
      />

      <div className="border-light-2 mx-6 border-b" />

      {projects && projects.length >= 1 && (
        <Tabs
          tabs={projects.map((project) => ({
            name: project.content?.name ?? "",
            redirectTo: `/project-holder/repayments/${project.id}`,
          }))}
          className="px-6"
        />
      )}

      <div className="m-6">
        <Title h3Brand="brand-2" level="h3" className="text-primary mb-1.5">
          {intl.formatMessage({ id: "my_repayments_page.table.title" })}
        </Title>
        <Typography variant="body1">
          {intl.formatMessage({
            id: "project_holder.repayments_page.table.description",
          })}
        </Typography>
        <TudigoCollection
          useLocationAsState={false}
          useQueryFn={useGetProjectDebtorRepaymentsPeriods}
          pathParams={{
            projectId: selectedProject?.id,
          }}
          fields="id,due_date,period_index,capital_amount_in_cents,interest_amount_in_cents,penalty_amount_in_cents,total_creditor_taxes,status,total_amount_in_cents"
          columns={useDebtorRepaymentsTableColumns()}
          pagination={{
            pageSize: 500,
            pageIndex: 0,
          }}
          render={(table, props) => {
            return (
              <div className="my-6">
                <Table
                  table={table}
                  actions={props.actions}
                  className="hidden sm:table"
                />
                <TableCardList table={table} className="sm:hidden" />
              </div>
            );
          }}
        />
      </div>
    </div>
  );
}
