import { trim } from "@tudigo-monorepo/core-tudigo-utils";

const evaluateExpression = (expression: string, context: any): boolean => {
  // Handle logical operators
  if (expression.includes(" and ")) {
    return expression
      .split(" and ")
      .every((subExpr) => evaluateExpression(subExpr, context));
  }
  if (expression.includes(" or ")) {
    return expression
      .split(" or ")
      .some((subExpr) => evaluateExpression(subExpr, context));
  }

  // Handle comparison and 'includes' operator
  const match = expression.match(
    /(.+?)\s*(==|!=|>|<|>=|<=|includes)\("(.*)"\)/,
  );
  if (match) {
    const [_, left, operator, right] = match;

    const leftValue = trim(left, ".")
      .split(".")
      .reduce((obj, property) => {
        return obj[property];
      }, context);

    switch (operator) {
      case "==":
        return leftValue === right.replace(/['"]/g, "");
      case "!=":
        return leftValue !== right.replace(/['"]/g, "");
      case ">":
        return leftValue > parseFloat(right);
      case "<":
        return leftValue < parseFloat(right);
      case ">=":
        return leftValue >= parseFloat(right);
      case "<=":
        return leftValue <= parseFloat(right);
      case "includes":
        return (
          Array.isArray(leftValue) &&
          leftValue.includes(right.replace(/['"]/g, ""))
        );
      default:
        throw new Error(`Unsupported operator: ${operator}`);
    }
  }

  throw new Error(`Invalid expression: ${expression}`);
};

export const safeEvaluate = (condition: string, context: any): boolean => {
  return evaluateExpression(condition, context);
};
