import { Investment } from "@tudigo-monorepo/core-tudigo-api-models";
import { TabElement } from "@tudigo-monorepo/web-tudigo-components";

export const getEditWmaInvestmentPageTabs = (
  investment: Investment,
): TabElement[] => {
  const tabs = [
    {
      name: "Investissement",
      redirectTo: "investment",
      badges: {
        created: {
          icon: "Checked",
          asChip: "small",
          variant: "success",
        },
      },
    },
    {
      name: "Bulletin de souscription",
      redirectTo: "subscription-form",
      badges: {
        signed: {
          icon: "Checked",
          asChip: "small",
          variant: "success",
        },
        pending: {
          icon: "Timer",
          asChip: "small",
          variant: "waiting",
        },
      },
    },
    {
      name: "Paiement",
      redirectTo: "payment",
      badges: {
        paid: {
          icon: "Checked",
          asChip: "small",
          variant: "success",
        },
        pending: {
          icon: "Timer",
          asChip: "small",
          variant: "waiting",
        },
        locked: {
          icon: "Cadenas",
          asChip: "small",
          variant: "warning",
        },
      },
    },
  ];

  return tabs.map((tab) => {
    let badge = undefined;
    switch (tab.name) {
      case "Investissement":
        badge = tab.badges?.["created"];
        break;
      case "Bulletin de souscription":
        badge =
          tab.badges?.[
            investment.subscriptionFormStatus === "signed"
              ? "signed"
              : "pending"
          ];
        break;
      case "Paiement":
        badge =
          tab.badges?.[
            investment.subscriptionFormStatus !== "signed"
              ? "locked"
              : investment.paymentStatus === "payed" ||
                  investment.paymentStatus === "amount_received"
                ? "paid"
                : "pending"
          ];
        break;
    }

    return {
      ...tab,
      badge: badge ? badge : undefined,
    };
  }) as TabElement[];
};
