import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import {
  SendWmaCustomerQualificationLinkRequest,
  TudigoError,
  TudigoResponse,
} from "@tudigo-monorepo/core-tudigo-api";
import { WMACustomer } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient } from "../../..";

export const sendWMACustomerQualificationLink = async (
  params: SendWmaCustomerQualificationLinkRequest,
) => {
  const apiClient = getApiClient();

  const response =
    await apiClient.apiV1.wmaCustomers.sendWMACustomerQualificationLink(
      params.wmaCustomerId,
    );

  return response.data;
};

export const useSendWMACustomerQualificationLinkMutation = (
  options?: UseMutationOptions<
    TudigoResponse<WMACustomer>,
    TudigoError,
    SendWmaCustomerQualificationLinkRequest
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (params: SendWmaCustomerQualificationLinkRequest) =>
      sendWMACustomerQualificationLink(params),
    onSuccess: (...props) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.WMA_CUSTOMERS] });
      options?.onSuccess?.(...props);
    },
  });
};
