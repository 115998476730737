import React from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { ASSEMBLY_PARTICIPANTS } from "@tudigo-monorepo/core-tudigo-utils";
import {
  ButtonProps,
  MetricsTableCard,
} from "@tudigo-monorepo/web-tudigo-components";

import { useMyInvestmentAssemblyPageContext } from "../../investment-assembly-page/my-investment-assembly-page";

export function AssemblyVoteCard() {
  const intl = useIntl();
  const navigate = useNavigate();

  const {
    project,
    assembly,
    assemblyParticipant,
    isAssemblyOpenForVoting,
    isAssemblyExpired,
  } = useMyInvestmentAssemblyPageContext();

  if (isAssemblyExpired(assembly)) {
    return (
      <MetricsTableCard
        title={intl.formatMessage({
          id: "assembly_detail.vote_table_card.title",
        })}
        heading={{
          secondChip: {
            label: intl.formatMessage({ id: "assembly.expired_label" }),
            type: "error",
          },
        }}
        rows={
          assemblyParticipant.answers.length > 0
            ? assemblyParticipant.answers?.map((answer) => ({
                label: answer?.assemblyTopic?.title ?? "",
                value: intl.formatMessage({
                  id: `assembly_vote_form.choice.label.${answer.choices[0]}`,
                }),
                valueCustomClassName:
                  answer.choices[0] === "agree"
                    ? "text-green-600"
                    : answer.choices[0] === "disagree"
                      ? "text-orange-500"
                      : "text-black-500",
              }))
            : [
                {
                  label: intl.formatMessage({
                    id: "assembly_detail.vote_table_card.row.empty_answers",
                  }),
                  value: "",
                },
              ]
        }
      />
    );
  }

  const voteActionBtnProps: ButtonProps = {
    label: intl.formatMessage({
      id: "assembly_detail.vote_table_card.vote_btn.label_vote",
    }),
    variant: "primary",
    iconRight: "ArrowRight",
    onClick: () =>
      navigate(
        `/my-account/my-investments/projects/${project.id}/assemblies/${assembly.id}/assembly-participant/${assemblyParticipant.id}/vote-form`,
      ),
  };

  const updateVoteActionBtnProps: ButtonProps = {
    label: intl.formatMessage({
      id: "assembly_detail.vote_table_card.vote_btn.label_update_vote",
    }),
    variant: "tertiary",
    iconRight: "Pen",
    onClick: () =>
      navigate(
        `/my-account/my-investments/projects/${project.id}/assemblies/${assembly.id}/assembly-participant/${assemblyParticipant.id}/vote-form`,
      ),
  };

  const signVoteActionBtnProps: ButtonProps = {
    label: intl.formatMessage({
      id: "assembly_detail.vote_table_card.vote_btn.label_sign_vote",
    }),
    variant: "tertiary",
    iconRight: "ArrowRight",
    onClick: () =>
      navigate(
        `/my-account/my-investments/projects/${project.id}/assemblies/${assembly.id}/assembly-participant/${assemblyParticipant.id}/vote-signature`,
      ),
  };

  const lockedUpcomingActionBtnProps: ButtonProps = {
    label: intl.formatMessage({
      id: "assembly_detail.vote_table_card.vote_btn.status_locked_upcoming.label",
    }),
    variant: "tertiary",
    iconRight: "Timer",
    disabled: true,
  };

  const lockedRepliedActionBtnProps: ButtonProps = {
    label: intl.formatMessage({
      id: "assembly_detail.vote_table_card.vote_btn.status_locked_replied.label",
    }),
    variant: "tertiary",
    iconRight: "Cadenas",
    disabled: true,
  };

  const actionsAvailable = (): ButtonProps[] => {
    if (!isAssemblyOpenForVoting) {
      return [lockedUpcomingActionBtnProps];
    }

    if (
      assemblyParticipant.status === ASSEMBLY_PARTICIPANTS.STATUS.STATUS_REPLIED
    ) {
      return [lockedRepliedActionBtnProps];
    }

    if (
      assemblyParticipant.status ===
      ASSEMBLY_PARTICIPANTS.STATUS.WAITING_VOTE_SIGNATURE
    ) {
      return [updateVoteActionBtnProps, signVoteActionBtnProps];
    }

    return [voteActionBtnProps];
  };

  return (
    <MetricsTableCard
      title={intl.formatMessage({
        id: "assembly_detail.vote_table_card.title",
      })}
      heading={{
        secondChip: {
          label: isAssemblyOpenForVoting(assembly)
            ? intl.formatMessage({
                id: "assembly_detail.vote_table_card.status_chips.assembly_vote_open",
              })
            : intl.formatMessage({
                id: "assembly_detail.vote_table_card.status_chips.assembly_vote_locked",
              }),
          type: isAssemblyOpenForVoting(assembly) ? "success" : "info",
        },
      }}
      rows={
        assemblyParticipant.answers?.map((answer) => ({
          label: answer?.assemblyTopic?.title ?? "",
          value: intl.formatMessage({
            id: `assembly_vote_form.choice.label.${answer.choices[0]}`,
          }),
          valueCustomClassName:
            answer.choices[0] === "agree"
              ? "text-green-600"
              : answer.choices[0] === "disagree"
                ? "text-orange-500"
                : "text-black-500",
        })) ?? null
      }
      footer={{
        btns: actionsAvailable(),
      }}
    />
  );
}
