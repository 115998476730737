import { BondsProject } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

import { InfoCardItem } from "../../../../../shared/components/info-card-item";

export function BondsProjectModalities(props: { project: BondsProject }) {
  const { project } = props;
  const { t } = useTranslation();

  return (
    <>
      <InfoCardItem
        label="Taux d'intérêt"
        value={`${project?.bondsMechanicSettings.annualInterestPercent}%`}
      />
      <InfoCardItem
        label="Durée"
        value={
          project?.bondsMechanicSettings?.durationInMonth
            ? `${project?.bondsMechanicSettings?.durationInMonth / 12} an${
                project?.bondsMechanicSettings?.durationInMonth / 12 > 1
                  ? "s"
                  : ""
              }`
            : "Non défini"
        }
      />
      <InfoCardItem
        label="Typologie d'obligations"
        value={
          project?.bondsMechanicSettings?.repaymentType
            ? t(
                `repayment_type.${project?.bondsMechanicSettings?.repaymentType}`,
              )
            : "Non défini"
        }
      />
      <InfoCardItem
        label="Remboursement"
        value={
          project?.bondsMechanicSettings?.repaymentFrequency
            ? t(
                `repayment_frequency.${project?.bondsMechanicSettings?.repaymentFrequency}`,
              )
            : "Non défini"
        }
      />

      {project?.bondsMechanicSettings?.isConvertible && (
        <>
          <InfoCardItem
            label="Décote en cas de conversion"
            value={`${
              project.bondsMechanicSettings.nonConversionBonusNoDecimalRate /
              100
            } %`}
          />
          <InfoCardItem
            label="Horizon de conversion"
            value={
              project.bondsMechanicSettings.conversionHorizonInMonth
                ? `${project.bondsMechanicSettings.conversionHorizonInMonth} mois`
                : "Non défini"
            }
          />
        </>
      )}
    </>
  );
}
