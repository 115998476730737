import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { User } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient, getQueryDetailKey, getQueryKey } from "../../../";

export type UpdateUserBody = {
  firstName?: User["firstName"];
  lastName?: User["lastName"];
  profileImage?: User["profileImage"];
  phoneNumber?: User["phoneNumber"];
  locale?: User["locale"];
  notificationSettings?: User["notificationSettings"];
};

type RequestParams = {
  userId: User["id"] | "me";
  data: UpdateUserBody;
  query?: { fields?: string };
};

type MutationOptions = UseMutationOptions<
  TudigoResponse<User>,
  TudigoError,
  RequestParams
>;

export const updateUser = async (params: RequestParams) => {
  const apiClient = getApiClient();
  const { userId, data, query } = params;
  const response = await apiClient.apiV1.users.updateUser(userId, data, query);

  return response.data;
};

export const useUpdateUserMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (params: RequestParams) => updateUser(params),
    onSuccess: (...props) => {
      const [, params] = props;

      queryClient.invalidateQueries({
        queryKey: getQueryKey(QUERY_KEYS.USERS),
      });

      queryClient.invalidateQueries({
        queryKey: getQueryDetailKey({
          key: QUERY_KEYS.USERS,
          id: params.userId,
        }),
      });

      queryClient.invalidateQueries({
        queryKey: getQueryDetailKey({
          key: QUERY_KEYS.USERS,
          id: "me",
        }),
      });

      options?.onSuccess?.(...props);
    },
  });
};
