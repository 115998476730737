import React from "react";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { UserTodoList } from "@tudigo-monorepo/web-feature-todos";

export const ClubLeaderDashboardPage = () => {
  const { t } = useTranslation();
  const { user } = useUserBusiness();

  return (
    <div className="w-full">
      <div className="p-6">
        <h1 className="text-primary font-obviously text-[27px] font-[570] leading-[45.9px]">
          {t("club_leader.page_dashboard.title")}
        </h1>
        <p className="text-dark-2">
          {t("club_leader.page_dashboard.subtitle", {
            firstName: user?.firstName,
            lastName: user?.lastName,
          })}
        </p>
      </div>
      <UserTodoList />
    </div>
  );
};
