import { Route, Routes } from "react-router-dom";

import { SpaceEnum, SpaceProvider } from "@tudigo-monorepo/tudigo-session";
import { KYCRoute } from "@tudigo-monorepo/web-feature-kyc";
import { MySettingsRoute } from "@tudigo-monorepo/web-feature-my-settings";
import {
  ConnectedLayout,
  useClubLeaderNavigation,
} from "@tudigo-monorepo/web-tudigo-layout";
import { ClubsV2ClubLeaderRouteWebapp } from "@tudigo-monorepo/web/feature-clubs-v2";

export const ClubLeaderRoutes = () => {
  return (
    <SpaceProvider currentSpace={SpaceEnum.CIRCLE_LEADER}>
      <Routes>
        <Route
          path={"organization/:organizationId/kyc/*"}
          element={<KYCRoute />}
        />
        <Route
          path=":clubId?/*"
          element={<ConnectedLayout navigation={useClubLeaderNavigation()} />}
        >
          <Route path="my-settings/*" element={<MySettingsRoute />} />
          <Route path="*" element={<ClubsV2ClubLeaderRouteWebapp />} />
        </Route>
      </Routes>
    </SpaceProvider>
  );
};
