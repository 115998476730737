/* eslint-disable-next-line */
import { ReactNode } from "react";

export interface FormProps {
  children: ReactNode;
  className?: string;
}

export function Form(props: FormProps) {
  return <form className={props.className}>{props.children}</form>;
}

export default Form;
