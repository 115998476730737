import { IntlShape, useIntl } from "react-intl";

import {
  getProjectEndDate,
  Project,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { formatCurrency, getDate } from "@tudigo-monorepo/core-tudigo-utils";
import {
  MetricsCard,
  MetricsCardProps,
} from "@tudigo-monorepo/web-tudigo-components";

type MetricsProjectCardProps = {
  project: Project;
  className?: string;
};

export function MetricsProjectCard(props: MetricsProjectCardProps) {
  const { project, className } = props;
  const intl = useIntl();

  const metricsProjectCardProps =
    project.status === "started"
      ? getPendingProjectMetricsCardProps(project, intl)
      : getClosedProjectMetricsCardProps(project, intl);

  return (
    <MetricsCard
      {...metricsProjectCardProps}
      className={cn("h-full", className)}
    />
  );
}

function getPendingProjectMetricsCardProps(
  project: Project,
  intl: IntlShape,
): MetricsCardProps {
  const projectFundraisingProgress =
    project.goal?.minInCents ?? 0 < project.totalAmountCollectedInCents
      ? Math.round(project.fundraisingProgressMinGoal)
      : Math.round(project.fundraisingProgressMaxGoal);

  return {
    title: intl.formatMessage({
      id: "component.metrics_project_card.header.title",
    }),
    value: formatCurrency(project.totalAmountCollectedInCents ?? 0),
    heading: {
      firstChip: {
        label: intl.formatMessage({
          id: "component.metrics_project_card.header.status.pending",
        }),
        type: "waiting",
        iconLeft: "DotChip",
      },
    },
    content: {
      sideChip: {
        label: `${projectFundraisingProgress}%`,
        type: projectFundraisingProgress > 100 ? "success" : "waiting",
        count: false,
      },
      sideText: intl.formatMessage({
        id: "component.metrics_project_card.percentage_of_the_goal.chips.side_text",
      }),
      chips: [
        ...(project.tags
          ? project.tags.map((tag) => ({
              label: tag.name,
            }))
          : []),
        {
          label: intl.formatMessage({
            id: `component.project_card.chips.${project.mechanic}`,
          }),
        },
      ],
    },
  };
}

function getClosedProjectMetricsCardProps(
  project: Project,
  intl: IntlShape,
): MetricsCardProps {
  const endDate = getProjectEndDate(project);
  const projectFundraisingProgress =
    project.goal?.minInCents ?? 0 < project.totalAmountCollectedInCents
      ? Math.round(project.fundraisingProgressMinGoal)
      : Math.round(project.fundraisingProgressMaxGoal);

  return {
    title: intl.formatMessage({
      id: "component.metrics_project_card.header.title",
    }),
    value: formatCurrency(project.totalAmountCollectedInCents ?? 0),
    heading: {
      firstChip: {
        label: intl.formatMessage({
          id: "component.metrics_project_card.header.status.closed",
        }),
        type: "info",
        iconLeft: "DotChip",
      },
    },
    content: {
      sideChip: {
        label: `${projectFundraisingProgress}%`,
        type: projectFundraisingProgress > 100 ? "success" : "waiting",
        count: false,
      },
      sideText: intl.formatMessage({
        id: "component.metrics_project_card.percentage_of_the_goal.chips.side_text",
      }),
      chips: [
        {
          label: intl.formatMessage({
            id: `component.project_card.chips.${project.mechanic}`,
          }),
        },
      ],
    },
    footer: {
      text: endDate
        ? intl.formatMessage({
            id: "component.metrics_project_card.footer.closed_project.text",
          }) + getDate(endDate).toLocaleDateString()
        : "",
    },
  };
}
