import { useEffect, useState } from "react";
import {
  matchPath,
  resolvePath,
  useLocation,
  useResolvedPath,
} from "react-router-dom";

import { BadgeProps } from "../badge/badge";
import { DesktopTabs } from "./parts/desktop-tabs";
import { MobileTabs } from "./parts/mobile-tabs";

export type TabElement = {
  name: string;
  badge?: BadgeProps;
  badges?: Record<string, BadgeProps | undefined>;
  badgeOnly?: boolean;
  isSearch?: boolean;
  redirectTo?: string;
  section?: string;
};

type TabProps = {
  tabs: TabElement[];
  className?: string;
  desktopTabsStartCol?: 2;
  onChange?: (tabName: string) => void;
  defaultTabName?: string;
};

export function Tabs(props: TabProps) {
  const {
    className = "",
    desktopTabsStartCol,
    tabs,
    defaultTabName,
    onChange,
  } = props;

  const { pathname } = useLocation();
  const path = useResolvedPath("./");
  const [selectedTab, setSelectedTab] = useState(
    defaultTabName || tabs[0]?.name || "",
  );

  useEffect(
    function defineSelectedTabFromUrlIfNeeded() {
      const tabRetrievedFromUrl = tabs.find(({ redirectTo }) => {
        const tabPath = resolvePath(
          {
            pathname: redirectTo ?? "",
          },
          path.pathname,
        );

        return !!matchPath(tabPath.pathname, pathname);
      });
      if (!tabRetrievedFromUrl) return;
      setSelectedTab(tabRetrievedFromUrl.name);
    },
    [path.pathname, pathname, tabs],
  );

  const handleTabChange = (tabName: string) => {
    setSelectedTab(tabName);
    onChange && onChange(tabName);
  };

  return (
    <div className={className}>
      <MobileTabs
        className="md:hidden"
        tabs={tabs}
        selectedTab={selectedTab}
        handleTabChange={handleTabChange}
      />

      <DesktopTabs
        className="hidden md:flex"
        tabs={tabs}
        desktopTabsStartCol={desktopTabsStartCol}
        selectedTab={selectedTab}
        handleTabChange={handleTabChange}
      />
    </div>
  );
}
