import { MouseEvent, useCallback, useState } from "react";

import { Project } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";

import { Icon } from "../../../old/icons/icon";
import { NotifyProjectModal } from "../../../ui/notify-project-modal";
import { useProjectNotificationsSubscription } from "./use-project-notifications-subscription";

export function ProjectCardRebrandNotify(props: { project: Project }) {
  const { project } = props;

  const { user } = useUserBusiness();
  const { t } = useTranslation();

  const { isSubscribed, isLoading, subscribeProject, unsubscribeProject } =
    useProjectNotificationsSubscription(project.id);

  const [showNotifyModal, setShowNotifyModal] = useState(false);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();

      if (isSubscribed) {
        return unsubscribeProject();
      }

      if (user) {
        subscribeProject();
      } else {
        setShowNotifyModal(true);
      }
    },
    [user, isSubscribed, subscribeProject, unsubscribeProject],
  );

  const handleVisitorConfirmSubscription = (email: string) => {
    subscribeProject(email);
    setShowNotifyModal(false);
  };

  return (
    <>
      <NotifyProjectModal
        project={project}
        isOpen={showNotifyModal}
        onConfirm={handleVisitorConfirmSubscription}
        onClose={() => setShowNotifyModal(false)}
      />

      <button
        onClick={handleClick}
        className="group mx-auto flex w-full cursor-pointer items-center justify-center gap-x-2.5 bg-transparent p-4"
      >
        <div
          className={cn(
            "border-light-1 group-hover:border-dark-2 group-hover:[&:is(.border-accent-medium)]: duration-300 ease-in-out",
          )}
        >
          <Icon
            name={isLoading ? "Loading" : "Notification"}
            primaryColor={
              themeColors[isSubscribed ? "primary-rebrand" : "black-900"]
            }
            size="XS"
          />
        </div>

        <p
          className={cn("font-roobert text-[13px] duration-300 ease-in-out", {
            "text-primary-rebrand": isSubscribed,
            "text-black-900 underline": !isSubscribed,
            "group-hover:text-black-primary": !isSubscribed,
          })}
        >
          {isSubscribed
            ? t("component.project_card.footer.no_longer_notified")
            : t("component.project_card.footer.be_notified_of_launch")}
        </p>
      </button>
    </>
  );
}
