import { Fragment } from "react";
import { useParams } from "react-router-dom";

import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { UserTodoList } from "@tudigo-monorepo/web-feature-todos";
import {
  ClubProfilePicture,
  Tabs,
} from "@tudigo-monorepo/web-tudigo-components";

import { ProjectHolderProjectDetailCard } from "../components/project-holder-project-detail-card";
import { ProjectHolderProjectInvestorsCard } from "../components/project-holder-project-investors-card";

export function ProjectHolderDashboardContent() {
  const { user } = useUserBusiness();
  const { projectId } = useParams();

  const projects = user?.projectHolderProfile?.projects;

  const selectedProject = projects?.find((p) => p.id === projectId);

  const renderMetricCards = () => {
    if (selectedProject) {
      return (
        <>
          <ProjectHolderProjectDetailCard project={selectedProject} />
          <ProjectHolderProjectInvestorsCard project={selectedProject} />
        </>
      );
    }

    return projects?.map((project) => (
      <Fragment key={project.id}>
        <ProjectHolderProjectDetailCard project={project} />
        <ProjectHolderProjectInvestorsCard project={project} />
      </Fragment>
    ));
  };

  return (
    <>
      {projects && projects.length > 1 && (
        <Tabs
          tabs={projects.map((project) => ({
            name: project.content?.name ?? "",
            redirectTo: `/project-holder/dashboard/${project.id}`,
          }))}
          className="px-6"
        />
      )}

      {selectedProject?.club?.id && (
        <div className="bg-light-3 flex snap-x gap-6 overflow-x-scroll px-6 pt-8 lg:grid lg:grid-cols-3">
          <ClubProfilePicture
            club={selectedProject.club}
            size="XS"
            text={`avec ${selectedProject.club.content?.name}`}
          />
        </div>
      )}

      {user?.projectHolderProfile?.projects &&
        user?.projectHolderProfile?.projects.length > 0 && (
          <div className="bg-light-3 flex snap-x gap-6 overflow-x-scroll px-6 py-8 lg:grid lg:grid-cols-3">
            {renderMetricCards()}
          </div>
        )}

      <UserTodoList />
    </>
  );
}
