import {
  Project,
  ProjectDocument,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { Button } from "@tudigo-monorepo/web-tudigo-components";
import {
  currentEnvEventsName,
  HubspotEventEnum,
  hubspotTrackEvent,
} from "@tudigo-monorepo/web-tudigo-tracking";

import { useProjectRequiredDocumentProps } from "../hooks/use-project-required-document-props";

type ProjectRequiredDocumentProps = {
  project: Project | null;
  doc: ProjectDocument;
};

export function ProjectRequiredDocument(props: ProjectRequiredDocumentProps) {
  const { project, doc } = props;
  const buttonProps = useProjectRequiredDocumentProps(project, doc);

  const handleClick = () => {
    hubspotTrackEvent(
      currentEnvEventsName[HubspotEventEnum.PROJECT_DOCUMENT_DOWNLOADED],
      {
        project_id: project?.id,
        project_slug: project?.content?.slug,
        document_type: doc.type,
      },
    );
    window.open(doc.document?.signedUrl, "_blank");
  };

  if (buttonProps.hidden) {
    return null;
  }

  return (
    <Button
      onClick={handleClick}
      iconLeft="Download"
      className="cursor-pointer overflow-hidden"
      labelClassName="truncate"
      disabled={buttonProps.disabled}
      label={buttonProps.label}
    />
  );
}
