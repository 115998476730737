import { useOutletContext } from "react-router-dom";

import { Investment, Project } from "@tudigo-monorepo/core-tudigo-api-models";

export interface MyInvestmentPageContext {
  project: Project;
  investments: Investment[];
  userId: string;
}

export function useMyInvestmentPageContext() {
  return useOutletContext<MyInvestmentPageContext>();
}
