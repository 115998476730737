import { useState, type FormEvent } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useQueryClient } from "@tanstack/react-query";
import { LoaderCircle } from "lucide-react";
import { toast } from "react-toastify";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";
import {
  getQueryListKey,
  useRequestSubscriptionSync,
} from "@tudigo-monorepo/web-tudigo-api-client";
import { Alert, ButtonRebrand } from "@tudigo-monorepo/web-tudigo-components";

import { useGetClubV2UsingIdPathParams } from "../../../shared-internal/hooks/use-get-club-v2-using-id-path-params";
import { useGetCurrentClubMember } from "../../hooks/use-get-current-club-member";
import { useNavigateToSubscribeClubStep } from "../../hooks/use-navigate-to-subscribe-club-step";
import { SubscribeClubStepPage } from "../../models/subscribe-club-step";
import { PaymentErrorAlert } from "./payment-error-alert";

type PaymentFormProps = {
  isError: boolean;
  onError: (error: boolean) => void;
};

function PaymentForm(props: PaymentFormProps) {
  const club = useGetClubV2UsingIdPathParams();
  const { clubMember } = useGetCurrentClubMember(club?.id);
  const subscriptionId = clubMember?.subscription?.id;

  const queryClient = useQueryClient();
  const stripe = useStripe();
  const stripeElements = useElements();

  const navigateToStep = useNavigateToSubscribeClubStep();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const handlePaymentSuccess = () => {
    queryClient
      .invalidateQueries({
        queryKey: getQueryListKey({
          key: QUERY_KEYS.MY_CLUB_MEMBERS,
        }),
      })
      .then(() => {
        props.onError(false);
        setIsLoading(false);

        toast((content) => (
          <Alert
            close={content.closeToast}
            icon="Checked"
            variant="success"
            size="S"
            title={t("subscribe_club_page.step_3_payment.alert.success.title")}
          />
        ));

        navigateToStep(SubscribeClubStepPage.STEP_4_SUCCESS, {
          wait: 3000,
        });
      });
  };

  const handlePaymentError = () => {
    props.onError(true);
    setIsLoading(false);

    toast((content) => (
      <PaymentErrorAlert isError content={content} size="S" />
    ));
  };

  const { mutate: requestSubscriptionSync } = useRequestSubscriptionSync({
    onSuccess: handlePaymentSuccess,
    onError: handlePaymentError,
  });

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !stripeElements) {
      return;
    }

    try {
      setIsLoading(true);

      const result = await stripe.confirmPayment({
        elements: stripeElements,
        redirect: "if_required",
      });

      if (result.error) {
        handlePaymentError();

        return;
      }

      if (!subscriptionId) {
        return;
      }

      requestSubscriptionSync({ subscriptionId });
    } catch (error) {
      console.error("requestSubscriptionSync -> error", error);
      handlePaymentError();
    }
  };

  return (
    <form
      className="flex flex-col items-center gap-y-6"
      onSubmit={handleSubmit}
    >
      <PaymentElement
        className="w-full py-4"
        options={{
          terms: { sepaDebit: "never", card: "never" },
        }}
      />

      {isLoading ? (
        <ButtonRebrand className="bg-black-primary/90 flex w-full items-center gap-x-2 md:w-60">
          <LoaderCircle className="h-5 w-5 animate-spin" />
          {t("subscribe_club_page.step_3_payment.form.ongoing_payment")}
        </ButtonRebrand>
      ) : (
        <ButtonRebrand type="submit" className="w-full md:w-60">
          {t("subscribe_club_page.step_3_payment.form.validate")}
        </ButtonRebrand>
      )}
    </form>
  );
}

export { PaymentForm };
