import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { Icon } from "@tudigo-monorepo/web-tudigo-components";

export function SidebarProjectSecurityAndRequirements() {
  return (
    <div className="flex flex-col gap-4">
      <header className="mb-1 flex gap-3">
        <Icon
          name="Checked"
          primaryColor={themeColors.primary}
          className="h-[28px] w-[28px]"
          bgColor={themeColors["light-3"]}
        />
        <h2 className="text-primary font-brand">Sécurités et engagements</h2>
      </header>

      <ul className="text-dark-1 ml-5 flex list-disc flex-col gap-2 text-sm font-semibold">
        <li>Sélection rigoureuse des projets</li>
        <li>Des chargés d’investissement à votre écoute</li>
        <li>{`Agréée par l'Autorité des Marchés Financiers`}</li>
        <li>Partenaire Lemonway</li>
      </ul>
    </div>
  );
}
