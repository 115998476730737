import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { SubscribeClubStepPage } from "../models/subscribe-club-step";
import { getAvailableClubSubscriptionSteps } from "../utils/get-available-club-subscription-steps";
import { useGetOrCreateClubMember } from "./use-get-or-create-club-member";
import { useGetSubscribeClubStepFromUrl } from "./use-get-subscribe-club-step-from-url";
import { useNavigateToSubscribeClubStep } from "./use-navigate-to-subscribe-club-step";

const useSubscribeClubStepDispatcher = () => {
  const { pathname } = useLocation();
  const currentClubMember = useGetOrCreateClubMember();
  const urlStep = useGetSubscribeClubStepFromUrl();
  const navigateToStep = useNavigateToSubscribeClubStep();

  useEffect(() => {
    if (!currentClubMember) {
      return;
    }

    const availableSteps = getAvailableClubSubscriptionSteps(currentClubMember);
    const lastStep = availableSteps[availableSteps.length - 1]?.page;
    const memberId = currentClubMember.id;

    if (availableSteps.length === 0) {
      return;
    }

    // If no step specify in the url and the last step available is 2, redirect him to step 1
    if (!urlStep && lastStep === SubscribeClubStepPage.STEP_2_SIGN) {
      return navigateToStep(SubscribeClubStepPage.STEP_1_OFFER, {
        baseRoute: `members/${memberId}/steps`,
      });
    }

    // If no step specify in the url, redirect him to the last step
    if (!urlStep) {
      return navigateToStep(lastStep, {
        baseRoute: `members/${memberId}/steps`,
      });
    }

    // If the step in the url is not available, redirect him to the last step
    if (!availableSteps.map(({ page }) => page).includes(urlStep.page)) {
      return navigateToStep(lastStep, {
        baseRoute: `members/${memberId}/steps`,
      });
    }
  }, [currentClubMember, navigateToStep, pathname, urlStep]);
};

export { useSubscribeClubStepDispatcher };
