import { useParams } from "react-router-dom";

import {
  isSharesProject,
  UserRole,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";

import { ConnectedNavigation } from "./use-connected-navigation";

export const useProjectHolderNavigation = (
  ...args: any[]
): ConnectedNavigation => {
  const { projectId } = useParams();
  const { projectOwnerProjects: projects } = useUserBusiness();

  const project =
    projects?.find((project) => project.id === projectId) ?? projects?.[0];

  return {
    type: UserRole.PROJECT_HOLDER,
    items: [
      { type: "section", label: "Gerer" },
      {
        type: "link",
        label: "Tableau de bord",
        path: "/project-holder/dashboard",
        icon: "Dashboard",
      },
      {
        type: "link",
        label: "Investisseurs",
        path: "/project-holder/investors",
        icon: "Group",
      },
      {
        type: "link",
        label: "Intérêts",
        path: "/project-holder/repayments",
        icon: "Money",
        hidden: !!project && isSharesProject(project),
      },
      { type: "section", label: "Support" },
      {
        type: "link",
        label: "FAQ",
        path: "questions-answers",
        icon: "Faq",
        target: "_blank",
      },
    ],
  };
};
