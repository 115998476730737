import { Link } from "react-router-dom";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { Icon } from "../old/icons/icon";

export function LogoutLink(props: { reducedDisplay?: boolean }) {
  return (
    <Link
      to="/logout"
      className={cn("text-dark-2 flex items-center gap-x-3 py-2 pl-3", {
        "w-full px-3": !props.reducedDisplay,
        "w-[38px] px-[7px]": props.reducedDisplay,
      })}
    >
      <Icon name="Logout" />
      {!props.reducedDisplay && (
        <p className="whitespace-nowrap opacity-100 transition-opacity duration-300 ease-in-out">
          Déconnexion
        </p>
      )}
    </Link>
  );
}
