import { useEffect } from "react";

import { InvestorProfile } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useDynamicFormContext } from "@tudigo-monorepo/web-tudigo-dynamic-forms";

import { ActionStateEnum } from "../../../../../../tudigo-dynamic-forms/src/lib/store/dynamic-form-provider";
import { InvestorProfileBannerContent } from "../../../content/investor-profile-banner-content";
import { InvestorProfileCardsContent } from "../../../content/investor-profile-cards-content";
import { InvestorProfileConsentsContent } from "../../../content/investor-profile-consents-content";

export type InvestmentCapacitySummaryPageProps = {
  investorProfile: InvestorProfile;
};

export function InvestorProfileSummaryPage(
  props: InvestmentCapacitySummaryPageProps,
) {
  const { investorProfile } = props;
  const { setActionState } = useDynamicFormContext();
  const { t } = useTranslation();

  useEffect(() => {
    setActionState("confirm", ActionStateEnum.DISABLED);
  }, [setActionState]);

  return (
    <div className="investor-profile-summary-page flex flex-col gap-y-8">
      <h1 className="font-obviously text-primary border-light-1 w-full border-b pb-4 text-[27px] font-semibold">
        {t("qualification.summary_page.investor_profile.title")}
      </h1>

      <InvestorProfileCardsContent
        commitmentProfile={investorProfile.engagementType}
        privilegedSectors={investorProfile.preferredInvestmentSectors ?? []}
        strategy={investorProfile.strategyType}
        investmentCapacity={{
          amount: investorProfile.annualInvestmentCapacity ?? 0,
        }}
      />

      <InvestorProfileBannerContent />

      <InvestorProfileConsentsContent
        onChange={(consents) => {
          setActionState(
            "confirm",
            consents.allConsented
              ? ActionStateEnum.ENABLED
              : ActionStateEnum.DISABLED,
          );
        }}
      />
    </div>
  );
}
