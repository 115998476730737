import {
  ActionTypes,
  FormPageAction,
} from "@tudigo-monorepo/core-tudigo-api-models";

import { useDynamicFormContext } from "./store/dynamic-form-context";
import { ActionButton } from "./utils/form-action";

type DynamicFormActionsProps = {
  debug?: boolean;
  globalActions?: FormPageAction[];
};
export function DynamicFormActions({ globalActions }: DynamicFormActionsProps) {
  const context = useDynamicFormContext();
  const { currentPage } = context;

  const pageActions = currentPage?.actions ?? [];

  const positiveActions = [...pageActions, ...(globalActions ?? [])].filter(
    (action) =>
      action.type.includes(ActionTypes.SUBMIT) ||
      action.type.includes(ActionTypes.CONFIRM),
  );

  const negativeActions = [...pageActions, ...(globalActions ?? [])].filter(
    (action) => action.type.includes(ActionTypes.DECLINE),
  );

  return (
    <div className="border-light-1 fixed bottom-0 z-20 flex w-full flex-col-reverse items-center justify-between gap-x-4 border-t bg-white px-6 py-5 sm:flex-row sm:items-start sm:px-8">
      <div className="relative">
        <div className="start-0 w-full sm:absolute">
          {negativeActions.map((action, index) => (
            <ActionButton key={index} action={action} className="text-nowrap" />
          ))}
        </div>
      </div>
      <div className="flex flex-row items-center">
        {positiveActions.map((action, index) => (
          <ActionButton key={index} action={action} className="text-nowrap" />
        ))}
      </div>
      <div></div>
    </div>
  );
}
