import type { ReactNode } from "react";
import { Bell, Star } from "lucide-react";

import { MembershipStatus } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { ButtonRebrand } from "@tudigo-monorepo/web-tudigo-components";

import { useGetClubV2UsingIdPathParams } from "../../../shared-internal/hooks/use-get-club-v2-using-id-path-params";
import { ClubMembershipModalFollow } from "./modal/club-membership-modal-follow";

type ClubMembershipButtonProps = {
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
};

export const ClubMembershipButton = (props: ClubMembershipButtonProps) => {
  const club = useGetClubV2UsingIdPathParams();

  if (!club) {
    return;
  }

  const myMembership = club.myMembership;
  const isPremiumAvailable = club.configuration?.isPremiumAvailable;

  switch (myMembership?.status) {
    case "following":
      if (isPremiumAvailable) {
        return (
          <ClubMembershipSubscribePremiumButton
            {...props}
            myMembership={myMembership.status}
          />
        );
      } else {
        return <ClubMembershipFollowingButton {...props} />;
      }
    case "subscribed":
    case "active":
      return <ClubMembershipSubscribedButton {...props} />;
    case "draft":
    default:
      if (isPremiumAvailable) {
        return (
          <ClubMembershipSubscribePremiumButton
            {...props}
            myMembership={myMembership?.status}
          />
        );
      } else {
        return (
          <ClubMembershipFollowButton
            {...props}
            myMembership={myMembership?.status}
          />
        );
      }
  }
};
const ClubMembershipSubscribePremiumButton = ({
  myMembership,
  children,
  className,
  onClick,
}: ClubMembershipButtonProps & {
  myMembership?: MembershipStatus;
}) => {
  const { t } = useTranslation();

  return (
    <ClubMembershipModalFollow myMembership={myMembership}>
      <ButtonRebrand
        onClick={onClick}
        className={cn("rounded-full", className)}
        variant="success"
      >
        <Star height={20} className="mr-2 fill-white" />
        {children ||
          t("page_club.club_membership_component.cta.subscribe_premium.label")}
      </ButtonRebrand>
    </ClubMembershipModalFollow>
  );
};
const ClubMembershipSubscribedButton = ({
  children,
  className,
  onClick,
}: ClubMembershipButtonProps) => {
  const { t } = useTranslation();

  return (
    <ButtonRebrand
      onClick={onClick}
      className={cn("text-success rounded-full", className)}
      variant="ghost"
    >
      <Star height={20} className="fill-success mr-2" />
      {children ||
        t("page_club.club_membership_component.cta.subscribed_premium.label")}
    </ButtonRebrand>
  );
};
const ClubMembershipFollowButton = ({
  myMembership,
  children,
  className,
  onClick,
}: ClubMembershipButtonProps & {
  myMembership?: MembershipStatus;
}) => {
  const { t } = useTranslation();

  return (
    <ClubMembershipModalFollow myMembership={myMembership}>
      <ButtonRebrand
        onClick={onClick}
        className={cn("rounded-full", className)}
      >
        <Bell height={20} className="mr-2" />
        {children || t("page_club.club_membership_component.cta.follow.label")}
      </ButtonRebrand>
    </ClubMembershipModalFollow>
  );
};
const ClubMembershipFollowingButton = ({
  children,
  className,
  onClick,
}: ClubMembershipButtonProps) => {
  const { t } = useTranslation();

  return (
    <ButtonRebrand
      onClick={onClick}
      className={cn("rounded-full", className)}
      size="default"
      variant="ghost"
    >
      <Bell height={20} className="fill-dark-2 mr-2" />
      {children || t("page_club.club_membership_component.cta.following.label")}
    </ButtonRebrand>
  );
};
