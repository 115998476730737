import { cn } from "@tudigo-monorepo/core-tudigo-theme";

type ColorsVariant = "primary" | "secondary" | "success";

type ProgressBarProps = {
  progress?: number;
  variant?: ColorsVariant;
};

const ProgressBarRebrand = (props: ProgressBarProps) => {
  const { progress = 0, variant = "primary" } = props;

  const progressColor: Record<ColorsVariant, string> = {
    primary: "bg-black-primary",
    secondary: "bg-black-primary",
    success: "bg-success-medium-rebrand",
  };

  const railColor: Record<ColorsVariant, string> = {
    primary: "bg-light-2",
    secondary: "bg-light-2",
    success: "bg-success-medium-rebrand",
  };

  const width = progress >= 100 ? 100 : progress;

  return (
    <div className={cn("h-[8px] w-full rounded-full", railColor[variant])}>
      <div
        className={cn("h-[8px] rounded-full", progressColor[variant])}
        style={{ width: `${width}%` }}
      />
    </div>
  );
};

export { ProgressBarRebrand };
