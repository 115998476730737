import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";

import { MyProfileOrganizationList } from "../../../shared/components/my-profile-organization-list";

export function ProjectHolderProfileOrganizationsContent() {
  const { user } = useUserBusiness();

  return (
    <div className="m-6">
      <MyProfileOrganizationList
        type="company"
        userId={user?.projectHolderProfile?.id}
      />
    </div>
  );
}
