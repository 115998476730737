import {
  InternalKycStatus,
  Organization,
} from "@tudigo-monorepo/core-tudigo-api-models";

import { useOrganizationChipProps } from "./use-organization-chip-props";

export function useOrganizationKycStatusChipProps(organization?: Organization) {
  const { completedProps, toCompleteProps, submittedProps, rejectedProps } =
    useOrganizationChipProps();

  switch (organization?.internalKycStatus) {
    case InternalKycStatus.APPROVED:
      return completedProps;
    case InternalKycStatus.REJECTED:
      return rejectedProps;
    case InternalKycStatus.SUBMITTED_AND_WAITING_APPROVAL:
      return submittedProps;
    case InternalKycStatus.SHOULD_BE_COMPLETED:
    case InternalKycStatus.TO_SYNCHRONIZE:
    default:
      return toCompleteProps;
  }
}
