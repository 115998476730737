export const INVESTMENTS = {
  GLOBAL_STATUS: {
    WAITING_FOR_SIGNATURE: "waiting_for_signature",
    WAITING_FOR_PAYMENT: "waiting_for_payment",
    VALIDATED: "validated",
    CANCELLED: "cancelled",
  },
  GLOBAL_STATUS_FILTERS: {
    ENGAGED: "engaged",
    INTENDED: "intended",
    PENDING: "pending",
    WAITING_FOR_SIGNATURE: "waiting_for_signature",
    WAITING_FOR_PAYMENT: "waiting_for_payment",
    VALIDATED: "validated",
    CANCELLED: "cancelled",
  },
};
