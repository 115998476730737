import { Organization } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

import { useOrganizationKycAddressStatusChipProps } from "../../../hooks/use-organization-kyc-address-status-chip-props";
import { OrganizationCollapse } from "../../organization-collapse/organization-collapse";
import { OrganizationCollapseHeader } from "../../organization-collapse/organization-collapse-header";

type PersonalAddressSectionProps = {
  organization: Organization;
};

export function PersonalAddressSection({
  organization,
}: PersonalAddressSectionProps) {
  const { t } = useTranslation();

  const { organizationRepresentative: representative } = organization;

  const sectionChipStatus =
    useOrganizationKycAddressStatusChipProps(organization);

  if (!representative?.address) return null;

  const { postalCode, city, street1, country } = representative.address;
  const postalCodeAndCity = postalCode && city ? `${postalCode} ${city}` : "-";

  const collapseItems = [
    {
      label: t(
        "organizations.back_office.organization_page.collapse.fiscal_address.address",
      ),
      value: street1,
      chipProps: !street1 ? sectionChipStatus : undefined,
    },
    {
      label: t(
        "organizations.back_office.organization_page.collapse.fiscal_address.postal_code_and_city",
      ),
      value: postalCodeAndCity,
      chipProps: !(postalCode && city) ? sectionChipStatus : undefined,
    },
    {
      label: t(
        "organizations.back_office.organization_page.collapse.fiscal_address.country",
      ),
      value: country,
      chipProps: !country ? sectionChipStatus : undefined,
    },
  ];

  return (
    <OrganizationCollapse items={collapseItems}>
      <OrganizationCollapseHeader
        label={t(
          "organizations.back_office.organization_page.collapse.fiscal_address.title",
        )}
        chipProps={sectionChipStatus}
      />
    </OrganizationCollapse>
  );
}
