import { Organization } from "@tudigo-monorepo/core-tudigo-api-models";
import { PMS_FILES } from "@tudigo-monorepo/core-tudigo-utils";
import { isOrganizationPersonProofOfAddressRequired } from "@tudigo-monorepo/web-feature-kyc";

export function getOrganizationDocuments(organization: Organization) {
  const idCardOrPassport =
    organization.organizationRepresentative?.documents?.find(
      (doc) =>
        doc.type === PMS_FILES.TYPE.IDENTITY_CARD ||
        doc.type === PMS_FILES.TYPE.EUROPEAN_PASSPORT,
    ) || {
      type: PMS_FILES.TYPE.IDENTITY_CARD,
      document: {},
    };

  const companyRegistration = organization.documents.find(
    (doc) => doc.type === PMS_FILES.TYPE.COMPANY_REGISTRATION,
  ) || {
    type: PMS_FILES.TYPE.COMPANY_REGISTRATION,
    document: {},
  };

  const certifiedStatuses = organization.documents.find(
    (doc) => doc.type === PMS_FILES.TYPE.STATUS,
  ) || {
    type: PMS_FILES.TYPE.STATUS,
    document: {},
  };

  if (organization.type === "company") {
    return [idCardOrPassport, companyRegistration, certifiedStatuses];
  }

  const isProofOfAddressRequired = isOrganizationPersonProofOfAddressRequired(
    organization.organizationRepresentative,
  );

  if (isProofOfAddressRequired) {
    const proofOfAddress =
      organization.organizationRepresentative?.documents?.find(
        (doc) => doc.type === PMS_FILES.TYPE.PROOF_OF_ADDRESS,
      ) || {
        type: PMS_FILES.TYPE.PROOF_OF_ADDRESS,
        document: {},
      };

    return [idCardOrPassport, proofOfAddress];
  }

  return [idCardOrPassport];
}
