import { CMSParagraphBlock } from "@tudigo-monorepo/core-tudigo-api-models";

import { Typography } from "../../typography";

export const ContractParagraphBlock = (block: CMSParagraphBlock) => {
  if (!block.data) return null;

  return (
    <Typography variant="body2" className="my-1" dangerouslySetInnerHTML>
      {block.data.text}
    </Typography>
  );
};
