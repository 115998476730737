import type { Comment as CommentModel } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { dateDiffInDaysFromNow } from "@tudigo-monorepo/core-tudigo-utils";
import { useCreateCommentMutation } from "@tudigo-monorepo/web-tudigo-api-client";
import { Comment } from "@tudigo-monorepo/web-tudigo-components";

type ClubCommentListProps = {
  comments: CommentModel[] | undefined;
  clubId: string;
};

export const ClubCommentList = (props: ClubCommentListProps) => {
  const { comments, clubId } = props;
  const { t } = useTranslation();

  const { mutateAsync: createComment } = useCreateCommentMutation({});

  const handleReplyComment = async (newReply: string, parentId: string) => {
    await createComment({
      club: { id: clubId },
      value: newReply,
      parent: { id: parentId },
    });
  };

  return (
    <>
      {comments?.map((comment) => (
        <>
          <Comment
            key={comment.id}
            author={`${comment.author.firstName} ${comment.author.lastName}`}
            comment={comment.value}
            date={t("projects.web_app.questions_answers.date.label", {
              days: dateDiffInDaysFromNow(new Date(comment.createdAt)),
            })}
            commentId={comment.id}
            handleReplyComment={handleReplyComment}
            profileImage={comment.author.profileImage}
          />

          {comment?.replies?.map((reply) => (
            <Comment
              className="before:bg-light-1 relative w-11/12 self-end rounded-md border p-5 before:absolute before:bottom-full before:left-[7%] before:h-[26px] before:w-px"
              key={reply.id}
              author={`${reply.author.firstName} ${reply.author.lastName}`}
              comment={reply.value}
              date={t("projects.web_app.questions_answers.date.label", {
                days: dateDiffInDaysFromNow(new Date(reply.createdAt)),
              })}
              commentId={comment.id}
              handleReplyComment={handleReplyComment}
              profileImage={reply.author.profileImage}
            />
          ))}
        </>
      ))}
    </>
  );
};
