import { CMSParagraphBlock } from "@tudigo-monorepo/core-tudigo-api-models";

export const ParagraphBlock = (block: CMSParagraphBlock) => {
  return (
    <p
      className="dynamic-form-paragraph-block"
      dangerouslySetInnerHTML={{
        __html: block.data.text.replace(/(?:\r\n|\r|\n)/g, "<br>"),
      }}
    />
  );
};
