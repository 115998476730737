import { Link } from "react-router-dom";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  PageEnum,
  useSpaceBusiness,
  useUserBusiness,
} from "@tudigo-monorepo/tudigo-session";
import { QualificationListElement } from "@tudigo-monorepo/web-feature-qualification";
import { Button, Title } from "@tudigo-monorepo/web-tudigo-components";

import { InvestorProfileCardsContent } from "../../../../../../feature-qualification/src/lib/content/investor-profile-cards-content";

export function InvestorProfileInformationContent() {
  const { user } = useUserBusiness();
  const { currentPagePaths } = useSpaceBusiness();
  const investorProfile = user?.investorProfile;

  const { t } = useTranslation();

  if (!user) {
    return null;
  }

  return (
    <div className="m-6 flex flex-col gap-6">
      <Title h3Brand="brand-2" level="h3" className="text-primary mb-6">
        {t(
          "my_account.web_app.my_profile_page.informations.content.my_investor_profile",
        )}
      </Title>
      <QualificationListElement user={user} />
      {investorProfile?.confirmed && (
        <>
          <InvestorProfileCardsContent
            commitmentProfile={investorProfile.engagementType}
            privilegedSectors={investorProfile.preferredInvestmentSectors ?? []}
            strategy={investorProfile.strategyType}
            investmentCapacity={{
              amount: investorProfile.annualInvestmentCapacity ?? 0,
            }}
          />
          <div>
            <Link to={currentPagePaths[PageEnum.PROJECTS]}>
              <Button
                variant="primary"
                label="Voir les opportunités sur mesure"
              />
            </Link>
          </div>
        </>
      )}
    </div>
  );
}
