import React from "react";
import { motion } from "framer-motion";
import {
  FileAudio,
  File as FileIcon,
  FileImage,
  FileText,
  FileVideo,
  XIcon,
} from "lucide-react";

import {
  Document,
  DocumentExtended,
} from "@tudigo-monorepo/core-tudigo-api-models";

import { ButtonRebrand } from "../../../../rebrand/ui/button-rebrand";

const iconClasses = "text-muted-foreground h-5 w-5";

function getIconForFile(document: DocumentExtended) {
  switch (true) {
    case document?.type?.includes("pdf"):
      return <FileText className={iconClasses} />;
    case document.type?.includes("image"):
      return <FileImage className={iconClasses} />;
    case document?.type?.includes("video"):
      return <FileVideo className={iconClasses} />;
    case document?.type?.includes("audio"):
      return <FileAudio className={iconClasses} />;
    case document?.type?.includes("text"):
      return <FileText className={iconClasses} />;
    default:
      return <FileIcon className={iconClasses} />;
  }
}

export function DocumentCard({
  document,
  onDelete,
}: {
  document: DocumentExtended;
  onDelete: (document: Document) => void;
}) {
  return (
    <motion.article
      onClick={(e) => e.stopPropagation()}
      animate={{ opacity: 1, x: 0 }}
      initial={{ opacity: 0, x: -10 }}
      exit={{ opacity: 0, x: 10 }}
      className={`
      bg-background group
      relative
      flex h-auto items-center justify-between gap-3
      truncate rounded-lg border py-2 pl-4 pr-1 text-sm
      `}
    >
      <div className="flex items-center gap-2 truncate">
        <div>{getIconForFile(document)}</div>
        <h2 className="truncate">{document.originalFilename}</h2>
      </div>

      <ButtonRebrand
        variant="ghost"
        size="sm"
        className="pointer-events-auto p-2"
        onClick={(e) => {
          e.stopPropagation();
          onDelete(document);
        }}
      >
        <XIcon className="h-5 w-5" />
      </ButtonRebrand>
    </motion.article>
  );
}
