import { useEffect, useState } from "react";

import { CreateUserTaxExemptionBody } from "@tudigo-monorepo/core-tudigo-api";
import {
  TaxExemption,
  TaxExemptionName,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { DotsLoader } from "@tudigo-monorepo/web-tudigo-components";

import { InvestorDocumentList } from "../shared/components/investor-documents-list";
import { NewTaxExemptionList } from "../shared/components/new-tax-exemption-list";
import { PendingTaxExemptionList } from "../shared/components/pending-tax-exemption-list";
import { SignedTaxExemptionList } from "../shared/components/signed-tax-exemption-list";
import { TaxExemptionSignaturePanel } from "../shared/components/tax-exemption-signature-panel";
import { TaxExemptionsModals } from "../shared/components/tax-exemptions-modals";
import { useInvestorTaxExemptions } from "../shared/hooks/use-investor-tax-exemptions";
import { isTaxExemptionCSGFormValid } from "../shared/utils/tax-exemptions-utils";

export type TaxExemptionModalName = TaxExemptionName | "CSG-form";

export interface SlidingPanelData {
  isOpen: boolean;
  loadingTaxExemptionName: TaxExemptionName | null;
  taxExemption: TaxExemption | null;
}

export type TaxExemptionCSGFormFields = Pick<
  CreateUserTaxExemptionBody,
  "since" | "state" | "socialProtectionFound" | "situation"
>;

export function InvestorProfileTaxExemptionsContent() {
  const {
    pendingTaxExemptionCSG,
    isLoadingTaxExemptions,
    refetchUserTaxExemptions,
  } = useInvestorTaxExemptions();

  const [currentModalOpen, setCurrentModalOpen] =
    useState<TaxExemptionModalName | null>(null);
  const [slidingPanelData, setSlidingPanelData] = useState<SlidingPanelData>({
    isOpen: false,
    loadingTaxExemptionName: null,
    taxExemption: null,
  });
  const [CSGFormData, setCSGFormData] = useState<TaxExemptionCSGFormFields>({});

  useEffect(() => {
    if (pendingTaxExemptionCSG) {
      setCSGFormData({
        since: pendingTaxExemptionCSG.since ?? undefined,
        state: pendingTaxExemptionCSG.state ?? undefined,
        socialProtectionFound:
          pendingTaxExemptionCSG.socialProtectionFound ?? undefined,
        situation: pendingTaxExemptionCSG.situation ?? undefined,
      });
    }
  }, [pendingTaxExemptionCSG]);

  const resetSlidingPanelData = () => {
    setSlidingPanelData({
      isOpen: false,
      loadingTaxExemptionName: null,
      taxExemption: null,
    });
  };

  const handleSignPendingTaxExemption = (item: TaxExemption) => {
    if (item.signatureRequest) {
      setSlidingPanelData({
        isOpen: true,
        loadingTaxExemptionName: item.status !== "draft" ? item.taxName : null,
        taxExemption: item,
      });
    }
  };

  const handleSignatureChange = (isSuccess: boolean) => {
    resetSlidingPanelData();

    if (isSuccess) {
      refetchUserTaxExemptions();
    }
  };

  return (
    <>
      <div className="m-6 flex flex-col gap-y-6">
        {isLoadingTaxExemptions && (
          <DotsLoader className="my-6 w-full justify-center" />
        )}
        <NewTaxExemptionList
          CSGFormData={CSGFormData}
          slidingPanelData={slidingPanelData}
          setSlidingPanelData={setSlidingPanelData}
          setCurrentModalOpen={setCurrentModalOpen}
        />
        <PendingTaxExemptionList
          canSignCSG={isTaxExemptionCSGFormValid(CSGFormData)}
          onSignPendingTaxExemption={handleSignPendingTaxExemption}
          setCurrentModalOpen={setCurrentModalOpen}
        />
        <SignedTaxExemptionList />
        <InvestorDocumentList />
      </div>

      {currentModalOpen !== null && (
        <TaxExemptionsModals
          currentModalOpen={currentModalOpen}
          CSGFormData={CSGFormData}
          setCSGFormData={setCSGFormData}
          onCloseModal={() => setCurrentModalOpen(null)}
        />
      )}

      {slidingPanelData.isOpen && slidingPanelData.taxExemption && (
        <TaxExemptionSignaturePanel
          isOpen
          onClose={resetSlidingPanelData}
          onSignatureChange={handleSignatureChange}
          taxExemptionName={slidingPanelData.taxExemption?.taxName ?? "IR"}
          signatureLink={
            slidingPanelData.taxExemption?.signatureRequest?.mySignatureLink ??
            null
          }
        />
      )}
    </>
  );
}
