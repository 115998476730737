import { Skeleton } from "@tudigo-monorepo/web-tudigo-components";

export const SkeletonProjectCardRebrand = () => {
  return (
    <div className="flex w-full min-w-[300px] max-w-[360px] flex-col rounded-lg bg-gray-100">
      <Skeleton className="h-[240px] w-full rounded-b-none rounded-t-lg" />
      <div className="flex flex-col p-6">
        <Skeleton className="mb-2 h-8 w-56" />
        <div className="mb-4 flex gap-2">
          <Skeleton className="h-[26px] w-16" />
          <Skeleton className="h-[26px] w-16" />
          <Skeleton className="h-[26px] w-8" />
        </div>
        <Skeleton className="mb-6 h-[54px] w-full" />
        <div className="mb-3 flex justify-between">
          <Skeleton className="h-6 w-20" />
          <Skeleton className="h-6 w-12" />
        </div>
        <Skeleton className="h-3 w-full rounded-3xl" />
      </div>
    </div>
  );
};
