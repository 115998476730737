import {
  OrganizationDocument,
  OrganizationPersonDocument,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { PMS_FILES } from "@tudigo-monorepo/core-tudigo-utils";
import { Icon } from "@tudigo-monorepo/web-tudigo-components";

import { KycErrorChip } from "./kyc-error-chip";

export interface KycDocumentViewerProps {
  doc: OrganizationDocument | OrganizationPersonDocument;
  isError?: boolean;
}

export const KycDocumentViewer = (props: KycDocumentViewerProps) => {
  const { doc, isError } = props;
  const rejected = isError || doc.pmsFile?.status === PMS_FILES.STATUS.REJECTED;

  return (
    <div
      className={cn(
        "border-light-1 my-2 flex items-center justify-between rounded-lg border p-4",
      )}
    >
      <div className="flex items-center gap-4">
        <Icon name="Document" size="S" />
        <a
          href={doc.document.signedUrl}
          rel="noreferrer"
          target="_blank"
          className="text-sm"
        >
          {doc.document.originalFilename}
        </a>
      </div>

      {rejected && <KycErrorChip />}
    </div>
  );
};
