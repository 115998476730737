import {
  InputDate,
  InputDateProps,
} from "@tudigo-monorepo/web-tudigo-components";

import {
  extractValueFromPath,
  SmartFieldCommonProps,
  updateObject,
} from "../../index";
import { useFormContext } from "../form-context/form-context";

type SmartDateFieldProps<T> = SmartFieldCommonProps<T> & {
  popperPlacement?: InputDateProps["popperPlacement"];
  showTimeSelect?: boolean;
};

export function SmartDateField<T>(props: SmartDateFieldProps<T>) {
  const { form, handleFormChange, error } = useFormContext();

  const value = extractValueFromPath(form, props.name as string);

  return (
    <InputDate
      {...props}
      range={false}
      value={value ? new Date(value) : undefined}
      errors={error?.parametersErrors?.[props.name as string] || props.errors}
      onChange={(date?: Date) => {
        handleFormChange({
          ...updateObject(form, props.name as string, date),
        });
      }}
      popperPlacement={props.popperPlacement}
      showTimeSelect={props.showTimeSelect}
    />
  );
}
