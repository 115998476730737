import { useState } from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  Button,
  MenuNavigation,
  SwitchUserModal,
} from "@tudigo-monorepo/web-tudigo-components";

import { useUserBusiness } from "../../hooks/use-user-business";
import { useSpaceBusiness } from "../../providers/space-provider";
import { PageEnum } from "../../types";
import { SessionCard } from "../session-card/session-card";
import { SpaceSwitcher } from "../session-role-switcher/space-switcher";

type SessionPanelProps = {
  onClose: () => void;
};

export function SessionPanel({ onClose }: SessionPanelProps) {
  const { t } = useTranslation();

  const { user, isAdmin } = useUserBusiness();
  const { currentSpace, currentPagePaths, canSwitchSpace } = useSpaceBusiness();

  const [isSwitchUserModalOpen, setIsSwitchUserModalOpen] = useState(false);

  if (!user) {
    return null;
  }

  return (
    <div className="h-full w-full divide-y rounded-none bg-white shadow-2xl sm:h-auto sm:w-[344px] sm:rounded-lg">
      <div className="flex h-[60px] rounded-t-lg px-4 sm:h-[88px] sm:px-6">
        <SessionCard currentSpace={currentSpace} />
        <Button
          onClick={onClose}
          singleIcon="Cross"
          iconColor="dark-2"
          className="border-none bg-white p-0"
        />
      </div>

      <div
        className={cn("px-6 py-4 sm:px-0 sm:py-0", {
          "divide-y": canSwitchSpace || isAdmin,
        })}
      >
        <div className="py-4 sm:px-6">
          <MenuNavigation
            items={[
              {
                label: t("session_panel.accounts"),
                path: currentPagePaths[PageEnum.ACCOUNTS],
                type: "link",
                icon: "Profile",
                action: onClose,
              },
              {
                label: t("session_panel.settings_link"),
                path: currentPagePaths[PageEnum.SETTINGS],
                type: "link",
                icon: "Settings",
                action: onClose,
              },
            ]}
          />
        </div>

        {canSwitchSpace && (
          <div className="flex-col gap-y-3 py-4 last:flex sm:px-6">
            <h5 className="font-montserrat text-dark-3 py-3 text-sm font-semibold">
              {t("session_panel.other_accesses")}
            </h5>
            <SpaceSwitcher onChangeRole={onClose} />
          </div>
        )}

        {isAdmin && window.location.hostname.startsWith("bo") && (
          <div className="py-4 sm:px-6">
            <button
              onClick={() => setIsSwitchUserModalOpen(true)}
              className="font-montserrat text-dark-3 py-3 text-left text-sm font-semibold"
            >
              Switch user
            </button>
            <SwitchUserModal
              isOpen={isSwitchUserModalOpen}
              onClose={() => {
                setIsSwitchUserModalOpen(false);
                onClose();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
