import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Button } from "@tudigo-monorepo/web-tudigo-components";

import {
  TaxExemptionBlock,
  TaxExemptionBlockContent,
  TaxExemptionBlockHeader,
  TaxExemptionBlockText,
  TaxExemptionBlockTitle,
} from "./tax-exemption-block";

interface TaxExemptionBlockCSGProps {
  canSign: boolean;
  onLearnMoreClick: () => void;
  onSignClick: () => void;
  isLoading?: boolean;
  onEditClick?: () => void;
}

export function TaxExemptionBlockCSG(props: TaxExemptionBlockCSGProps) {
  const {
    onLearnMoreClick,
    onSignClick,
    canSign,
    isLoading = false,
    onEditClick,
  } = props;

  const { t } = useTranslation();

  return (
    <TaxExemptionBlock>
      <TaxExemptionBlockHeader>
        <TaxExemptionBlockTitle>
          {t("my-profile-exemptions.tax-document-CSG.title")}
        </TaxExemptionBlockTitle>
      </TaxExemptionBlockHeader>

      <TaxExemptionBlockContent>
        <TaxExemptionBlockText>
          {t("my-profile-exemptions.tax-document-CSG.text")}
        </TaxExemptionBlockText>

        <Button
          onClick={onLearnMoreClick}
          label={t("my-profile-exemptions.tax-document.buttons-learn-more")}
          className="font-montserrat w-fit"
          variant="ghost"
          iconRight="ChevronRight"
        />

        {!canSign && (
          <Button
            onClick={onEditClick}
            label={t("my-profile-exemptions.tax-document.buttons-fill-CSG")}
            className="font-montserrat w-fit"
            variant="primary"
            isLoading={isLoading}
          />
        )}

        {canSign && (
          <div className="flex flex-wrap items-center gap-6">
            <Button
              onClick={onEditClick}
              label={t("my-profile-exemptions.tax-document.buttons-edit-CSG")}
              className="font-montserrat w-fit"
              variant="tertiary"
            />
            <Button
              onClick={onSignClick}
              label={t("my-profile-exemptions.tax-document.buttons-sign-CSG")}
              className="font-montserrat w-fit"
              variant="primary"
              isLoading={isLoading}
              disabled={isLoading}
            />
          </div>
        )}
      </TaxExemptionBlockContent>
    </TaxExemptionBlock>
  );
}
